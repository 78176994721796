import React from "react";
import Button from "../CustomButtons/Button";
import SettingsBackupRestore from "@material-ui/icons/SettingsBackupRestore";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import PlayArrow from "@material-ui/icons/PlayArrow";
import Stop from "@material-ui/icons/Stop";
import './RecorderControls.scss';

export default function RecordControls(props) {
    const {
        recording,
        startingRecord,
        startRecordingAdditionalButtons,
        stoppingRecord,
        recordingUrl,
        toggleRecording,
        playRecording,
        recordAgain,
        startRecordingMessage,
        recordAgainMessage,
        allowedToRecord,
        startRecordingDisabled
    } = props;

    let recordingClass;
    if (recording) {
        recordingClass = 'recording';
    } else if (startingRecord || stoppingRecord) {
        recordingClass = 'recording-pending';
    } else {
        recordingClass = 'not-recording';
    }
    recordingClass += ' record-indicator';

    return (
        <div className="controls recorder-controls" style={{textAlign: 'center'}}>
            {!recording && !startingRecord && !stoppingRecord && !recordingUrl && allowedToRecord && (
                <>
                    <Button
                        className="control-button start-recording-button"
                        onClick={toggleRecording}
                        disabled={startRecordingDisabled}
                    >
                        <FiberManualRecord className={recordingClass}>&nbsp;</FiberManualRecord>
                        {startRecordingMessage ? startRecordingMessage : 'Start Recording'}
                    </Button>
                    {startRecordingAdditionalButtons && (startRecordingAdditionalButtons)}
                </>
            )}
            {!recording && startingRecord && (
                <Button className="control-button starting-recording-button"><FiberManualRecord
                    className={recordingClass}>&nbsp;</FiberManualRecord>Starting
                    recording...</Button>
            )}
            {!recording && stoppingRecord && (
                <Button className="control-button starting-recording-button"><FiberManualRecord
                    className={recordingClass}>&nbsp;</FiberManualRecord>Stopping
                    recording...</Button>
            )}
            {recording && !stoppingRecord && (
                <Button className="control-button stop-recording-button" onClick={toggleRecording}><Stop
                    className={recordingClass}>&nbsp;</Stop>Stop
                    Recording</Button>
            )}
            {!recording && recordingUrl && (
                <>
                    <Button className="control-button play-recording-button" onClick={playRecording}>
                        <PlayArrow/>Play Recording
                    </Button>
                    {allowedToRecord && (
                        <Button className="control-button record-again-button" onClick={recordAgain}>
                            <SettingsBackupRestore/>{recordAgainMessage}
                        </Button>
                    )}
                    {startRecordingAdditionalButtons && (startRecordingAdditionalButtons)}
                </>
            )}
        </div>
    )
}
