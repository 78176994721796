import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {loadIndustriesForSelect} from "../../actions/Industry/forSelect";
import {loadOccupationsForSelect} from "../../actions/Occupation/forSelect";
import {loadSpecialtiesForSelect} from "../../actions/Specialty/forSelect";
import {loadCompanyUsersForSelect} from "../../actions/CompanyUser/forSelect";
import {loadTeamsForSelect} from "../../actions/Team/forSelect";
import {loadCountriesForSelect} from "../../actions/Country/forSelect";
import {loadStatesForSelect} from "../../actions/State/forSelect";
import {list, reset} from "../../actions/Job/list";
import {applyToUrl} from "../../utils/urls";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import List from '@material-ui/core/List';
import Assignment from "@material-ui/icons/Assignment";
import Dialog from "../../views/Components/Dialog/Dialog";
import DialogConfirmButton from "../../views/Components/Dialog/DialogConfirmButton";
import DialogCancelButton from "../../views/Components/Dialog/DialogCancelButton";
import FilterJobsModalForm, {FILTER_JOBS_MODAL_FORM_NAME} from "./FilterJobsModalForm";
import {submit as reduxFormSubmit, reset as reduxFormReset} from "redux-form";

class FilterJobsModal extends React.Component
{
  static propTypes = {
    opened: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    selectJob: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showForm: true,
      showResults: false,
    }
  }

  componentDidMount() {
    this.props.loadIndustriesForSelect();
    this.props.loadOccupationsForSelect();
    this.props.loadSpecialtiesForSelect();
    this.props.loadCompanyUsersForSelect();
    this.props.loadTeamsForSelect();
    this.props.loadCountriesForSelect(this.props.site.country && this.props.site.country.code);
    this.props.loadStatesForSelect();
  }

  onClose = () => {
    this.backToSearch();
    this.props.handleClose(false);
  }

  onSubmit = (values) => {
    for (let i in values) {
      if (values.hasOwnProperty(i) && values[i] === null) {
        delete values[i];
      }
    }

    const url = applyToUrl('/jobs', values);
    this.props.listJobs(url);
    this.setState({
      showForm: false,
      showResults: true,
    });
  }

  backToSearch = () => {
    this.setState({
      showForm: true,
      showResults: false,
    });

    this.props.resetJobs();
  }

  renderContent = () => {
    return (
      <>
        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}
        <FilterJobsModalForm onSubmit={this.onSubmit} visible={this.state.showForm} />
        {this.state.showResults && this.props.foundJobs && this.props.foundJobs['hydra:member'].length === 0 && (
          <p className="mt-4 ml-1">Jobs not Found.</p>
        )}
        <List className="mb-3">
          {this.state.showResults && this.props.foundJobs && this.props.foundJobs['hydra:member'].map(job =>
            (
              <ListItem key={job.id}>
                <ListItemIcon>
                  <Assignment />
                </ListItemIcon>
                <ListItemText primary={job.title} secondary={(
                  <>
                    <span className="float-left">{job.description.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 100)}...</span>
                    <button
                      className="btn btn-primary float-right d-none d-lg-block"
                      style={{margin: '-20px 0 0 0'}}
                      onClick={() => this.props.selectJob(job)}
                    >
                      Select Job
                    </button>
                    <span className="clearfix" />
                    <br className="d-block d-lg-none" />
                    <button
                      className="btn btn-primary d-block d-lg-none"
                      style={{margin: '-5px 0 0 0'}}
                      onClick={() => this.props.selectJob(job)}
                    >
                      Select Job
                    </button>
                  </>
                )} />
              </ListItem>
            )
          )}
        </List>
      </>
    )
  }

  renderActions = () => {
    if (this.state.showResults) {
      return (
        <DialogConfirmButton onClick={this.backToSearch} text={"Back to Search"} />
      );
    }

    return (
      <>
        <DialogConfirmButton onClick={() => this.props.submitForm()} text={"Filter"} />
        <DialogCancelButton text={"Reset"} onClick={() => {
            this.props.resetForm();
        }} />
      </>
    );
  }

  render() {
    let dialogTitle;
    if (this.state.showForm) {
      dialogTitle = 'Filter Jobs';
    } else if (this.state.showResults) {
      dialogTitle = 'Found Jobs';
    }

    return (
      <div>
        <Dialog
          open={this.props.opened}
          onClose={this.onClose}
          dialogProperties={{maxWidth:"md"}}
          title={dialogTitle}
          actionButtons={this.renderActions()}
        >
          {this.renderContent()}
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.industry.forSelect.loading || state.occupation.forSelect.loading || state.specialty.forSelect.loading || state.companyUser.forSelect.loading || state.team.forSelect.loading || state.state.forSelect.loading || state.job.list.loading,
    error: state.industry.forSelect.error || state.occupation.forSelect.error || state.specialty.forSelect.error || state.companyUser.forSelect.error || state.team.forSelect.error || state.state.forSelect.error || state.job.list.error,
    foundJobs: state.job.list.retrieved,
    site: state.siteDetails.siteDetails.retrieved,
  };
};

const mapDispatchToProps = dispatch => ({
  loadIndustriesForSelect:() => dispatch(loadIndustriesForSelect('@id')),
  loadOccupationsForSelect:() => dispatch(loadOccupationsForSelect('@id')),
  loadSpecialtiesForSelect:() => dispatch(loadSpecialtiesForSelect('@id')),
  loadCompanyUsersForSelect:() => dispatch(loadCompanyUsersForSelect()),
  loadTeamsForSelect:() => dispatch(loadTeamsForSelect()),
  loadCountriesForSelect: (countryCode) => dispatch(loadCountriesForSelect('@id', countryCode)),
  loadStatesForSelect:() => dispatch(loadStatesForSelect()),
  listJobs:(values) => dispatch(list(values)),
  resetJobs:() => dispatch(reset()),
  submitForm: () => dispatch(reduxFormSubmit(FILTER_JOBS_MODAL_FORM_NAME)),
  resetForm: () => dispatch(reduxFormReset(FILTER_JOBS_MODAL_FORM_NAME)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterJobsModal);
