import React, {Component} from "react";
import PropTypes from "prop-types";

class PhoneNumber extends Component {
  static propTypes = {
    phone: PropTypes.string,
    formattedPhone: PropTypes.string,
  }

  render() {
    const {phone, formattedPhone} = this.props;
    if (!phone) {
      return '-';
    }

    const visiblePhone = formattedPhone ? formattedPhone : phone;
    return <a href={`callto:${phone}`}>{visiblePhone}</a>;
  }
}

export default PhoneNumber;
