import { combineReducers } from 'redux';

export function grantAccessError(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_ACCESS_GRANT_ERROR':
      return action.error;

    case 'LIVEINTERVIEW_ACCESS_RESET':
      return null;

    default:
      return state;
  }
}

export function grantAccessLoading(state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_ACCESS_GRANT_LOADING':
      return action.loading;

    case 'LIVEINTERVIEW_ACCESS_RESET':
      return false;

    default:
      return state;
  }
}

export function granted(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_ACCESS_GRANT_SUCCESS':
      return action.granted;

    case 'LIVEINTERVIEW_ACCESS_RESET':
      return null;

    default:
      return state;
  }
}

export function accessList(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_ACCESS_LIST_RETRIEVED':
      return action.retrieved;

    case 'LIVEINTERVIEW_ACCESS_RESET':
      return null;

    default:
      return state;
  }
}

export function retrieveListLoading(state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_ACCESS_LIST_LOADING':
      return action.loading;

    case 'LIVEINTERVIEW_ACCESS_RESET':
      return null;

    default:
      return state;
  }
}

export function deleteAccessLoading(state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_ACCESS_DELETE_LOADING':
      return action.loading;

    case 'LIVEINTERVIEW_ACCESS_RESET':
      return false;

    default:
      return state;
  }
}

export function deleteAccessError(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_ACCESS_DELETE_ERROR':
      return action.error;

    case 'LIVEINTERVIEW_ACCESS_RESET':
      return null;

    default:
      return state;
  }
}

export function deleted(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_ACCESS_DELETE_SUCCESS':
      return action.deleted;

    case 'LIVEINTERVIEW_ACCESS_RESET':
      return null;

    default:
      return state;
  }
}

export default combineReducers({
  grantAccessError,
  grantAccessLoading,
  granted,
  accessList,
  retrieveListLoading,
  deleteAccessLoading,
  deleteAccessError,
  deleted,
});
