import {getOpenViduSingleton, getNewOpenVidu} from "./OpenViduInstance";
import {isFirefox, isSafari} from "./userAgent";
import moment from "moment";

const SIP_CLIENT_TYPE = 'SIP';
const SIP_PARTICIPANT_DATA_HEADER = 'x-userDetails';
const BROWSER_PARTICIPANT_KEY = 'userDetails';

export function getUserNameForParticipant(participant) {
  return participant.firstName + ' ' + participant.lastName;
}

function getConnectionDataObject(connectionData) {
  let result;
  if (typeof connectionData === 'string') {
    try {
      //For sip connection data is not valid json object but two objects connected with %/% character
      result = JSON.parse(connectionData.split('%/%')[0]);
    } catch (e) {
      result = {};
    }
  } else if (typeof connectionData === 'object') {
    result = connectionData;
  }

  return result;
}

export function isSipConnection(connectionData) {
  const data = getConnectionDataObject(connectionData);

  return data.hasOwnProperty('clientType') && data.clientType === SIP_CLIENT_TYPE;
}

export function getParticipantFromConnectionData(connectionData) {
  let participant = null;
  const data = getConnectionDataObject(connectionData);
  if (isSipConnection(data)) {
    const headers = data.sipHeaders;
    if (headers && headers[SIP_PARTICIPANT_DATA_HEADER]) {
      try {
        participant = JSON.parse(headers[SIP_PARTICIPANT_DATA_HEADER]);
      } catch (e) {
        console.log('Could not parse sip headers to get participant information', headers[SIP_PARTICIPANT_DATA_HEADER]);
      }
    }
  } else {
    const data = getConnectionDataObject(connectionData);
    if (data[BROWSER_PARTICIPANT_KEY]) {
      participant = data[BROWSER_PARTICIPANT_KEY];
    }
  }

  return participant;
}

export function createVideoPublisher(config = {}) {
  const OV = getOpenViduSingleton();
  return new Promise((resolve, reject) => {
    OV.getDevices().then(devices => {
      const defaultConfig = getDefaultPublisherConfig(devices);
      let publisher = OV.initPublisher(undefined,
        Object.assign(defaultConfig, {
          insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
          mirror: false, // Whether to mirror your local video or not
        }, config),
        (error) => {
          if (error) {
            return reject(error);
          }
          publisher.properties.resolution = getVideoResolutionConstraint(); //Need to explicitly set publisher resolution to be able to use it for camera change
          return resolve(publisher);
        }
      );
    });
  });
}

export function createAudioVideoTestPublisher() {
  const OV = getNewOpenVidu();
  return new Promise((resolve, reject) => {
    const publisher = OV.initPublisher(undefined, {
      audioSource: undefined, // The source of audio. If undefined default microphone
      videoSource: undefined, // The source of video. If undefined default webcam
      publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
      publishVideo: true, // Whether you want to start publishing with your video enabled or not
      resolution: getVideoResolutionConstraintForAudioVideoTest(),
      frameRate: 30, // The frame rate of your video
      insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
      mirror: false, // Whether to mirror your local video or not
    }, (error) => {
        resolve(publisher);
    });
  });
}

function getDefaultPublisherConfig(devices) {
  const videoDevice = devices.find(device => device.kind === 'videoinput');
  const audioDevice = devices.find(device => device.kind === 'audioinput');

  return {
    audioSource: audioDevice ? undefined : false, //do not request audio if there is no appropriate device
    videoSource: videoDevice ? undefined : false, //do not request video if there is no appropriate device
    publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
    publishVideo: true, // Whether you want to start publishing with your video enabled or not
    resolution: getVideoResolutionConstraint(),
    frameRate: 30, // The frame rate of your video
  };
}

function getVideoResolutionConstraintForAudioVideoTest() {
  if (isSafari() || isFirefox()) {
    return '640x480';
  }

  return '853x480';
}

function getVideoResolutionConstraint()
{
  //Hack for Safari added because of this https://stackoverflow.com/a/50841972/1376972
  //Normal resolution should be 16:9
  return isSafari() || isFirefox() ? '320x240' : '360x203';
}

export function isInterviewExpired(liveInterview) {
  if (!liveInterview.scheduledStartTime) {
    return false;
  }

  const scheduledDate = moment(liveInterview.scheduledStartTime);
  const currentDate = moment();

  //TODO: improve expired interview definition
  return currentDate.diff(scheduledDate, 'hours') > 12;
}
