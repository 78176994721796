import {fetch} from "../../utils/dataAccess";
import {interviewReceived} from "./interview";
import {
  getRecordAttemptsForVideoQuestion,
  getSpentThinkTimeForVideoQuestion,
  getTotalTimeSpentForVideoQuestion,
} from "../../utils/onDemandInterview";

export function saveAnswer(answer, question, interview, skipped = false) {
  return async (dispatch) => {
    try {
      dispatch(answerSaving(true));

      let data = {
        interviewCode: interview['interviewCode'],
        knockoutQuestion: question['@id'],
        type: question['type'],
        skipped: skipped,
      };

      if (!skipped) {
        if ('text' === question['type']) {
          data['text'] = answer;
        } else {
          data['knockoutQuestionChoices'] = answer;
        }
      }

      const response = await fetch(`/knockout_answers`, {
        method: 'POST',
        data: data
      });

      const savedAnswer = response.data;
      dispatch(interviewReceived(savedAnswer.oneWayInterview));
      dispatch(answerSaving(false));
    } catch (e) {
      dispatch(answerSaving(false));
    }
  }
}

export function saveVideoAnswer(answer, question, interview, skipped = false) {
  return async (dispatch) => {
    try {
      dispatch(answerSaving(true));

      let recordAttempts = getRecordAttemptsForVideoQuestion(question);
      let data = {
        interviewCode: interview['interviewCode'],
        question: question['@id'],
        skipped: skipped,
        statisticTotalTimeSpent: getTotalTimeSpentForVideoQuestion(question),
        statisticThinkTimeUsedSeconds: getSpentThinkTimeForVideoQuestion(question),
        statisticAnswerRecordingRetriesUsed: recordAttempts === 0 ? 1 : recordAttempts,
      };

      if (!skipped) {
        if (typeof answer === 'object') {
          if (answer['@type'] && answer['@type'] === 'AnswerAudio') {
            data['answerAudio'] = answer['@id'];
          } else {
            data['answerVideo'] = answer['@id'];
          }
        } else {
          data['answerText'] = answer;
        }
      }

      if (answer && answer.thinkTimeNotAnswered) {
        data['thinkTimeNotAnswered'] = true;
      }

      const response = await fetch(`/answers`, {
        method: 'POST',
        data: data
      });

      const savedAnswer = response.data;
      dispatch(interviewReceived(savedAnswer.oneWayInterview));
      dispatch(answerSaving(false));
    } catch (e) {
      if (e.response && e.response.status === 400 && e.response.data) {
        const description = e.response.data['hydra:description'];
        if (description.match(/Item not found for \"\/answer_videos\//)) {
          dispatch(answerError("Answer video was deleted on the server. Please record your answer again"))
        } else if (description.match(/Item not found for \"\/answer_audios\//)) {
          dispatch(answerError("Answer audio was deleted on the server. Please record your answer again"))
        }
      } else {
        dispatch(answerError("An unexpected error happens during answer submitting. Please reload the page and try again."));
      }
      dispatch(answerSaving(false));
    }
  }
}

function answerSaving(saving) {
  return { type: 'ON_DEMAND_INTERVIEW_PAGE_ANSWER_SAVING', saving };
}

export function answerError(message) {
  return { type: 'ON_DEMAND_INTERVIEW_PAGE_ANSWER_ERROR', message };
}
