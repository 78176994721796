import React from 'react';
import {inlinePagination} from "../../utils/entityList";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { list, reset } from "../../actions/OneWayInterviewComment/list";
import { del } from "../../actions/OneWayInterviewComment/delete";
import { connect } from 'react-redux';
import {applyToUrl} from "../../utils/urls";
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import "./Style.scss";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IsGranted from "../IsGranted";
import filtersStorage from '../../utils/filtersStorage';

class List extends React.Component {
  constructor(props) {
    super(props);

    let sortData = filtersStorage.getSort('one_way_interview_comments');
    this.state = {
      sort: null !== sortData ? sortData['createdAt'] : 'desc',
    };
  }

  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    deleted: PropTypes.object,
    oneWayInterview: PropTypes.object,
    setShowForm: PropTypes.func.isRequired,
  };

  componentDidMount() {
    let url = applyToUrl('one_way_interview_comments', {
      'oneWayInterview': this.props.oneWayInterview['@id'],
      'sort[createdAt]': this.state.sort
    });
    this.props.list(url);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  del = (item) => {
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.del(item);
  };

  setSort = (e) => {
    filtersStorage.setSort('one_way_interview_comments', {'createdAt': e.target.value});
    this.setState({sort: e.target.value});

    let url = applyToUrl('one_way_interview_comments', {
      'oneWayInterview': this.props.oneWayInterview['@id'],
      'sort[createdAt]': e.target.value
    });
    this.props.list(url);
  };

  render() {
    if (this.props.deleted) {
      this.componentWillUnmount();
      this.componentDidMount();
    }

    return (
      <div>
        <IsGranted action="create" object={this.props.retrieved}>
          <button type="button" className="btn btn-info mb-3 mr-3 float-left" onClick={() => {this.props.setShowForm(true, null)}}>Add Comment</button>
        </IsGranted>

        <div className="comments-sort">
          <span>Sort By: </span>
          <Select
            name="sort"
            value={this.state.sort}
            onChange={this.setSort}
            className="select-sort"
          >
            <MenuItem value="desc">Newest</MenuItem>
            <MenuItem value="asc">Oldest</MenuItem>
          </Select>
          <div className="clearfix" />
        </div>
        <div className="clearfix" />
        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}

        {this.props.retrieved && this.props.retrieved['hydra:member'].map(item => (
          <Card className="mb-3" key={item['id']}>
            <CardContent>
              <div className="comments-list-row">
                <Typography variant="h5" component="h2">{item.companyUser.firstName} {item.companyUser.lastName}</Typography>
                <div className="mb-3 comment-created-at">
                  <Moment format="MMM D YYYY HH:mm:ss">{item['createdAt']}</Moment>
                </div>
                <Typography component="p" className="mb-3">
                  {item['content']}
                </Typography>
              </div>
              <div className="comment-actions">
                <IsGranted action="edit" object={item}>
                  <button className="btn btn-warning btn-sm" onClick={() => {this.props.setShowForm(true, item)}}>Edit</button>
                </IsGranted>
                <IsGranted action="delete" object={item}>
                  <button onClick={() => {this.del(item)}} className="btn btn-danger btn-sm ml-2">Delete</button>
                </IsGranted>
                <div className="clearfix" />
              </div>
            </CardContent>
          </Card>
        ))}

        <div className="text-center">
          {inlinePagination(this.props.retrieved, this.props.list, {component: 'div'})}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    retrieved: state.onewayinterviewcomment.list.retrieved,
    loading: state.onewayinterviewcomment.list.loading || state.onewayinterviewcomment.del.loading,
    error: state.onewayinterviewcomment.list.error || state.onewayinterviewcomment.del.error,
    deleted: state.onewayinterviewcomment.del.deleted,
  };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: page => dispatch(reset(page)),
  del: page => dispatch(del(page)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);

