import React from "react";
import {Link, Popover, Typography, IconButton, withStyles} from "@material-ui/core";
import Clear from "@material-ui/icons/Clear"
import {connect} from "react-redux";
import classNames from 'classnames';
import PhoneNumber from "../../views/Components/PhoneNumber";

const style = theme => ({
  root: {
    marginBottom: '1rem',
  },
  troubleShootLink: {
    textDecoration: 'underline',
    fontSize: 'medium',
  },
  popoverText: {
    padding: theme.spacing.unit * 4,
    maxWidth: '750px',
  },
  troubleshootList: {
    lineHeight: '30px',
    fontSize: 'medium',
  },
  troubleshootHeader: {
    display: 'flex',
    '& h5': {
      flex: '1 1 auto',
    },
    '& > div': {
      flex: '0 0 auto',
      marginTop: '-1.5em',
      marginRight: '-1.5em',
    }
  },
});

class Troubleshoot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    }
  }

  openPopover = (event) => {
    event.preventDefault();
    this.setState({anchorEl: event.currentTarget});
  };

  closePopover = () => {
    this.setState({anchorEl: null});
  };

  render() {
    const {classes, site} = this.props;
    const {anchorEl} = this.state;
    const open = Boolean(anchorEl);

    return (
      <div className={classNames(classes.root, this.props.className)}>
        <Link
          onClick={this.openPopover}
          className={classes.troubleShootLink}
          href='#'
        >
          Click here to troubleshoot
        </Link>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={this.closePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography className={classes.popoverText} component="div">
            <div className={classes.troubleshootHeader} >
              <h5>How to troubleshoot your camera and microphone</h5>
              <div>
                <IconButton className={classes.closeButton} onClick={this.closePopover}>
                  <Clear />
                </IconButton>
              </div>
            </div>
            <ol className={classes.troubleshootList}>
              <li>Click "Allow" when your browser asks if it can access your camera and microphone.</li>
              <li>Unmute your microphone and verify that your speakers are on.</li>
              <li>Close other programs, browsers or tabs that might be using your camera or microphone.</li>
              <li>Disable browser plug ins or ad blockers. You can also use an incognito or private window.</li>
              <li>Switch to a different device, camera or microphone.</li>
            </ol>
            <i>
              Please refresh your screen if you make changes to your settings like those listed above. If you're still experiencing problems, please contact us
               at <PhoneNumber phone={site.supportPhone} formattedPhone={site.supportPhoneFormatted} /> or email us at <a href={`mailto:${site.supportEmail}`}>{site.supportEmail}</a>
            </i>
          </Typography>
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  site: state.siteDetails.siteDetails.retrieved,
});

Troubleshoot = connect(
  mapStateToProps
)(Troubleshoot);

export default withStyles(style)(Troubleshoot);
