import React, {Component} from "react";
import PropTypes from "prop-types";
import LiveInterviewComposedVideoPlayer from "./LiveInterviewComposedVideoPlayer";
import LiveInterviewPlayer from "./LiveInterviewPlayer";

class LiveInterviewVideo extends Component {
  static propTypes = {
    liveInterview: PropTypes.object.isRequired,
    seekTime: PropTypes.number,
  };

  render() {
    const liveInterview = this.props.liveInterview;

    const hasComposedVideo = !!liveInterview.composedVideo && !!liveInterview.composedVideo.path;
    const composedVideoProcessing = !!liveInterview.composedVideo && liveInterview.composedVideo['status'] === 'processing';
    const hasPreviewImage = composedVideoProcessing && !!liveInterview.composedVideo['previewImage'];
    const hasLiveInterviewVideos = Array.isArray(liveInterview['liveInterviewVideos']) && liveInterview['liveInterviewVideos'].length > 0;

    return (
      <>
        {hasComposedVideo ? (
          <LiveInterviewComposedVideoPlayer composedVideo={liveInterview.composedVideo} seek={this.props.seekTime} />
        ) : (
          composedVideoProcessing ? (
            <div className={'preview-video-container' + (!hasPreviewImage ? ' no-preview-image' : '')}>
              {hasPreviewImage && <img src={liveInterview.composedVideo['previewImage']} alt="Composed video preview." />}
              <div className="preview-video-overlay">
                Video is currently processing, please check back later.
              </div>
            </div>
          ) : (
            hasLiveInterviewVideos ? (
              <>
                <LiveInterviewPlayer interview={liveInterview} iframeStyle={{'height': 'calc(100% - 3.5rem)'}} />
                <div className="alert alert-warning mb-0">
                  There was an error processing this recording. The playback experience may be degraded.
                </div>
              </>
            ) : (
              <div className="preview-video-container no-preview-image">
                <div className="preview-video-overlay">
                  This interview was not recorded.
                </div>
              </div>
            )
          )
        )}
      </>
    );
  }
}

export default LiveInterviewVideo;
