import React from "react";
import ErrorPage from "../Pages/ErrorPage";
import withStyles from "@material-ui/core/styles/withStyles";

const style = {
  root: {
    position: 'fixed',
  }
}

class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, info) {
    this.setState({ error: error });

    // We can send error here to out log server
  }

  render() {
    const {classes} = this.props;
    const {error} = this.state;
    if (error) {
      return <ErrorPage
        className={classes.root}
        errorTitle={'Error'}
        errorDescription={'Something went wrong.'}
      />;
    }

    return this.props.children;
  }
}

AppErrorBoundary = withStyles(style)(AppErrorBoundary);

export default AppErrorBoundary;
