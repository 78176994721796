import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'JOB_ACTIVATE_ERROR', error };
}

export function loading(loading) {
  return { type: 'JOB_ACTIVATE_LOADING', loading };
}

export function success(activated) {
  return { type: 'JOB_ACTIVATE_SUCCESS', activated };
}

export function activate(item) {
  return dispatch => {
    dispatch(loading(true));
    dispatch(error(null));

    return fetch(item['@id'] + '/activate', { method: 'PUT' })
      .then(() => {
        dispatch(loading(false));
        dispatch(success(item));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return { type: 'JOB_ACTIVATE_RESET' };
}
