import React, { Component } from 'react';
import {Field, FieldArray, getFormValues, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {renderInlineRadioButtons, renderTextField} from "../../utils/form";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from '@material-ui/core/InputAdornment';
import {PlanCard} from "../../views/Subscription/PlanCard";
import {connect} from "react-redux";
import {FormPlanLimitations} from "./FormPlanLimitations";
import {PREVIEW_PERIOD_ANNUAL, PREVIEW_PERIOD_MONTHLY} from "../../utils/plan";
import {Link} from "react-router-dom";

class PlanForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
    isNew: PropTypes.bool,
    cancelButtonUrl: PropTypes.string,
  };

  static defaultProps = {
    cancelButtonUrl: 'list',
  };

  constructor(props) {
    super(props);
    const initialValues = props.initialValues;

    this.state = {
      monthlyBillingEnabled: !(initialValues && initialValues.monthlyPrice < 1),
      annualBillingEnabled: !!(initialValues && initialValues.annualPrice),
      previewPeriod: PREVIEW_PERIOD_MONTHLY,
    }
  }

  toggleMonthlyBilling = (e, value) => {
    const newState = {monthlyBillingEnabled: value};
    if (value) {
      newState.previewPeriod = PREVIEW_PERIOD_MONTHLY;
    } else {
      this.props.change('monthlyPrice', null);
      if (this.state.annualBillingEnabled) {
        newState.previewPeriod = PREVIEW_PERIOD_ANNUAL;
      }
    }

    this.setState(newState);
  }

  toggleAnnualBilling = (e, value) => {
    const newState = {annualBillingEnabled: value};
    if (value) {
      newState.previewPeriod = PREVIEW_PERIOD_ANNUAL;
    } else {
      this.props.change('annualPrice', null);
      if (this.state.monthlyBillingEnabled) {
        newState.previewPeriod = PREVIEW_PERIOD_MONTHLY;
      }
    }

    this.setState(newState);
  }

  setPreviewPeriod = (previewPeriod) => {
    if (previewPeriod === PREVIEW_PERIOD_MONTHLY && !this.state.monthlyBillingEnabled) {
      return;
    }

    if (previewPeriod === PREVIEW_PERIOD_ANNUAL && !this.state.annualBillingEnabled) {
      return;
    }

    this.setState({previewPeriod: previewPeriod});
  }

  isPriceChanged = (initValue, currentValue) => {
    initValue = parseFloat(initValue) ? parseFloat(initValue) : null;
    currentValue = parseFloat(currentValue) ? parseFloat(currentValue) : null;

    return initValue !== currentValue;
  }

  render() {
    const initialValues = this.props.initialValues || {};
    const currentValues = this.props.currentValues || {};

    return (
      <form onSubmit={this.props.handleSubmit} className={"admin-plan-form"}>
        <div className={"fields"}>
          <Field
            component={renderTextField}
            name="name"
            type="text"
            placeholder="Enter Name"
            required={true}
            charsCounterMaxValue={50}
            inputProps={{maxLength: 50}}
            htmlRequired={true}
          />
          <Field
            component={renderTextField}
            name="description"
            label={"Tag Line"}
            type="text"
            placeholder="e.g. Perfect Plan for Starters!"
            containerClass={"form-group half-size mr-20"}
            charsCounterMaxValue={45}
            inputProps={{maxLength: 45}}
          />
          <Field
            component={renderTextField}
            name="code"
            type="text"
            placeholder="Enter Code"
            containerClass={"form-group half-size"}
          />

          <div className={"billing-period-checkboxes mt-2"}>
            <p className={"section-label"}>BILLING PERIOD <span className={"text-danger"}>*</span></p>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.monthlyBillingEnabled}
                  onChange={this.toggleMonthlyBilling}
                />
              }
              label={'Monthly'}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.annualBillingEnabled}
                  onChange={this.toggleAnnualBilling}
                />
              }
              label={'Annually'}
            />
          </div>
          <div className={"billing-period-fields mt-2"}>
            <p className={"section-label"}>PRICE <span className={"text-danger"}>*</span></p>
            <Field
              component={renderTextField}
              name="monthlyPrice"
              label={false}
              type="number"
              placeholder="Enter Price"
              required={true}
              htmlRequired={this.state.monthlyBillingEnabled}
              disabled={!this.state.monthlyBillingEnabled}
              inputProps={{autoComplete: 'off'}}
              InputProps={{
                startAdornment: <InputAdornment position="start"> $</InputAdornment>,
                endAdornment: <InputAdornment position="end">USD</InputAdornment>,
              }}
              normalize={v => normalizeMaxLength(v, 8, 2)}
            />
            <Field
              component={renderTextField}
              name="annualPrice"
              label={false}
              type="number"
              placeholder="Enter Price"
              required={true}
              htmlRequired={this.state.annualBillingEnabled}
              disabled={!this.state.annualBillingEnabled}
              inputProps={{autoComplete: 'off'}}
              InputProps={{
                startAdornment: <InputAdornment position="start"> $</InputAdornment>,
                endAdornment: <InputAdornment position="end">USD</InputAdornment>,
              }}
              normalize={v => normalizeMaxLength(v, 8, 2)}
            />
          </div>

          <div className={"clearfix"} />

          {!this.props.isNew && (
            this.isPriceChanged(initialValues.annualPrice, currentValues.annualPrice) ||
            this.isPriceChanged(initialValues.monthlyPrice, currentValues.monthlyPrice)
          ) && (
            <div className={"plan-change-warning"}>
              <i className="fa fa-exclamation-circle" aria-hidden="true" />
              Companies on this plan will be charged the new rate on their next renewal date
            </div>
          )}

          <div className={'mt-3 plan-type'}>
            <p className={"section-label"}>PLAN TYPE</p>
            <Field
              name={'private'}
              component={renderInlineRadioButtons}
              type="checkbox"
              label={null}
              containerClass={"form-group mt-2"}
              choices={[
                {value: "true", label: 'Private'},
                {value: "false", label: 'Public'},
              ]}
              normalize={v => v === 'true'}
            />
          </div>

          <FieldArray
            name={"planLimitations"}
            component={FormPlanLimitations}
            initialValues={initialValues['planLimitations'] || {}}
          />

          <div className={"bottom-buttons"}>
            <Link to={this.props.cancelButtonUrl} className="subscription-button-blue-outlined">
              Cancel
            </Link>
            <button type="submit" className="subscription-button-blue">
              {this.props.isNew ? 'Create Plan' : 'Save Changes'}
            </button>
          </div>
        </div>
        <div className={"plan-preview"}>
          <div className={"plan-preview-label"}>PREVIEW</div>
          <div className={"plan-preview-period"}>
            <button
              type={"button"}
              className={this.state.previewPeriod === PREVIEW_PERIOD_MONTHLY ? 'active' : null}
              onClick={() => this.setPreviewPeriod(PREVIEW_PERIOD_MONTHLY)}
            >
              MONTHLY VIEW
            </button>
            <button
              type={"button"}
              className={this.state.previewPeriod === PREVIEW_PERIOD_ANNUAL ? 'active' : null}
              onClick={() => this.setPreviewPeriod(PREVIEW_PERIOD_ANNUAL)}
            >
              ANNUAL VIEW
            </button>
          </div>

          <PlanCard
            period={this.state.previewPeriod}
            plan={currentValues}
          />
        </div>
        <div className={"clearfix"} />
      </form>
    );
  }
}

PlanForm = reduxForm({
  form: 'plan',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(PlanForm);

const mapStateToProps = state => {
  return {
    currentValues: getFormValues('plan')(state)
  };
};

/* set max charts length for number inputs */
export function normalizeMaxLength(v, maxLength, precision = 0) {
  if (v) {
    if (v.includes('.')) {
      maxLength += 1;

      if (precision > 0) {
        const afterDot = v.substr(v.indexOf('.') + 1);
        if (afterDot.length > precision) {
          v = v.slice(0, -1);
        }
      }
    }

    if (v.length > maxLength) {
      v = v.slice(0, maxLength);

      if (v.substr(v.length - 1) === '.') {
        v = v.slice(0, -1);
      }
    }
  }

  return v;
}

export default connect(
  mapStateToProps
)(PlanForm);
