import React, {Component} from "react";
import nl2br from 'react-nl2br';
import './MessageHistory.scss'
import MessageTime from "./MessageTime";
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';
import classNames from 'classnames';

export default class MessageHistory extends Component {

    render() {
        const {fullscreen} = this.props;
        return (
            <div
                className={classNames('message-history', {'message-history-fullscreen': fullscreen})}
                ref={this.props.messagesRef}
            >
                <MessageTime date={this.props.initialDate ? this.props.initialDate : new Date()} />
                {this.props.messages && this.props.messages.map((message, i) => {
                    return (
                        <div key={'message-' + i} className={`message${message.own && message.type !== 'user_join' && message.type !== 'user_disconnect' ? ' own' : ''}`}>
                            {this.renderMessage(message)}
                        </div>
                    );
                })}
            </div>
        );
    }

    renderMessage(message) {
        switch (message.type) {
            case 'chat_message':
                return (
                    <>
                        <Grow in={true} timeout={800}>
                            <div className='chat-message'>{nl2br(message.body.message)}</div>
                        </Grow>
                        <Fade in={true} timeout={600}>
                            <div className="message-metadata">
                                <MessageTime time={message.body.time} />
                                <span className='author-name'>
                                    {message.body.firstName + ' ' + message.body.lastName}
                                </span>
                            </div>
                        </Fade>
                    </>);
            case 'user_join':
                return (
                    <>
                        <Fade in={true} timeout={500}>
                            <div className="message-metadata user-action">
                                <div className='user-join'>
                                    {message.body.firstName + ' ' + message.body.lastName + ' joined the interview at '}
                                    <MessageTime time={message.body.time} />
                                </div>
                            </div>
                        </Fade>
                    </>
                );
            case 'user_disconnect':
                return (
                    <>
                        <Fade in={true} timeout={500}>
                            <div className="message-metadata user-action">
                                <div className='user-disconnect'>
                                    {message.body.firstName + ' ' + message.body.lastName + ' left the interview at '}
                                    <MessageTime time={message.body.time} />
                                </div>
                            </div>
                        </Fade>
                    </>
                );
        }
    }
}
