import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";

class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  render() {
    return (
      <RenderFilterForm {...this.props} fields={[
        {'name': 'id', 'label': 'ID', 'placeholder': 'ID', 'type': 'number'},
        {'name': 'firstName', 'label': 'First Name', 'placeholder': 'First Name', 'type': 'text'},
        {'name': 'lastName', 'label': 'Last Name', 'placeholder': 'Last Name', 'type': 'text'},
        {'name': 'email', 'label': 'Email', 'placeholder': 'Email', 'type': 'text'},
      ]} />
    );
  }
}

export default FilterForm = reduxForm({
  form: 'filter_super_admins',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);
