import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import { updateTags, reset } from '../../actions/LiveInterview/updateTags';
import TagsForm from "../Tag/TagsForm";

class LiveInterviewTagsForm extends Component {
  static propTypes = {
    object: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.string,
    updated: PropTypes.object,
  };

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const {object} = this.props;
    return (
      <TagsForm
        tags={object.tags}
        handleSave={(tags) => this.props.updateTags(object, {tags: tags})}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = state => ({
  loading: state.liveinterview.updateTags.loading,
  updated: state.liveinterview.updateTags.updated,
  error: state.liveinterview.updateTags.error,
});

const mapDispatchToProps = dispatch => ({
  updateTags: (item, values) => dispatch(updateTags(item, values)),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveInterviewTagsForm);
