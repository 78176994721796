import React, {Component} from "react";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import ReviewCard from "../Review/Card";
import ReviewScaleForm from "./Form";
import {getReviewScales, reset as showReset} from "../../actions/ReviewScale/show";
import {updateReviewScales, reset as updateReset} from "../../actions/ReviewScale/update";
import {connect} from "react-redux";
import {getReviewCriteria} from "../../utils/reviewScales";

const previewReview = {
  averageRating: 4.2,
  criteria1: 5,
  criteria2: 4,
  criteria3: 3,
  criteria4: 5,
  criteria5: 4,
}

class ReviewScaleList extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    this.props.getReviewScales();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.criteria && this.props.criteria) {
      this.updateCriteriaNamesInLocalState(this.props.criteria);
    }
  }

  updateCriteriaNamesInLocalState = (values) => {
    this.setState({criteriaNames: getReviewCriteria(values)})
  }

  render() {
    const {loading, error, criteria, updated, updatedSuccessfully} = this.props;
    const {criteriaNames} = this.state;
    const currentValues = (updated) ? updated : criteria;
    return (
      <div>
        {loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {updatedSuccessfully && (
          <div className="alert alert-success" role="status">
            Review Scales successfully updated.
          </div>
        )}
        {error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {error}
          </div>
        )}
        {criteriaNames && (
          <GridContainer>
            <GridItem sm={12} md={6}>
              <h3>Edit Review Criteria</h3>
              <ReviewScaleForm
                initialValues={{
                  criteria: criteriaNames
                }}
                onChange={(values) => this.updateCriteriaNamesInLocalState(values.criteria)}
                onSubmit={(values) => this.props.updateReviewScales(
                  currentValues,
                  values.criteria
                )}
              />
            </GridItem>
            <GridItem sm={12} md={6}>
              <h3>Preview</h3>
              <ReviewCard
                reviewCriteria={criteriaNames}
                review={previewReview}
              />
            </GridItem>
          </GridContainer>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  error: state.reviewScale.show.error || state.reviewScale.update.error,
  loading: state.reviewScale.show.loading || state.reviewScale.update.loading,
  criteria: state.reviewScale.show.criteria,
  updated: state.reviewScale.update.updated,
  updatedSuccessfully: state.reviewScale.update.success,
});

const mapDispatchToProps = dispatch => ({
  getReviewScales: () => dispatch(getReviewScales()),
  updateReviewScales: (currentValues, newValues) => dispatch(updateReviewScales(currentValues, newValues)),
  reset: () => {
    dispatch(showReset());
    dispatch(updateReset());
  },
});

ReviewScaleList = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewScaleList);

export default ReviewScaleList;
