import React from 'react';
import Dialog from "../../../views/Components/Dialog/Dialog";
import PropTypes from 'prop-types';

function PaymentMethodConfirmDeleteModal({handleClose, handleDelete, paymentMethod}) {
  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        title={"Delete Payment Method"}
        dialogProperties={{subscriptionTheme: true}}
      >
        <div className={"payment-method-delete-modal"}>
          <p>Are you sure you want to delete <strong>{paymentMethod['brand'].toUpperCase()} **** {paymentMethod['lastFour']}</strong>?</p>
          <button onClick={() => handleDelete(paymentMethod)} className={"subscription-modal-button-green"}>YES</button>
          <button onClick={handleClose} className={"subscription-modal-button-red"}>NO</button>
        </div>
      </Dialog>
    </div>
  );
}

PaymentMethodConfirmDeleteModal.propTypes = {
  paymentMethod: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default PaymentMethodConfirmDeleteModal;
