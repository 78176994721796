import React from "react";
import LogRocket from "logrocket";
import PropTypes from "prop-types";
import {areTheSameObjects} from "../../utils/object";
import {OpenViduLogger} from "openvidu-browser/lib/OpenViduInternal/Logger/OpenViduLogger";

const appId = process.env.REACT_APP_LOGROCKET_APP_ID;

class LogRocketTracker extends React.Component {
  static propTypes = {
    uuid: PropTypes.string.isRequired,
    trackData: PropTypes.object,
  };

  componentDidMount() {
    if (appId) {
      LogRocket.init(appId);
      this.connectToOpenViduLogger();
      this.identifyUser(this.props.uuid, this.props.trackData);
    }
  }

  connectToOpenViduLogger = () => {
    const OVlogger = OpenViduLogger.getInstance();

    const logFunctions = ['log','debug', 'info', 'warn', 'error'];
    logFunctions.forEach(functionName => this.connectToOpenViduLoggerFunction(OVlogger, functionName));
  }

  connectToOpenViduLoggerFunction = (logger, functionName) => {
    const originalFunction = logger[functionName];
    logger[functionName] = function() {
      LogRocket[functionName](...arguments);
      originalFunction.apply(logger, arguments);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (appId && !areTheSameObjects(prevProps.trackData, this.props.trackData)) {
      this.identifyUser(this.props.uuid, this.props.trackData);
    }
  }

  componentWillUnmount() {
    try {
      //This function allows stop session recording, but it's undocumented and might stop working at some point
      LogRocket.uninstall();
    } catch (e) {
      console.log("LogRocket uninstall function is no longer available");
    }
  }

  identifyUser = (idKey, data) => {
    LogRocket.identify(idKey, data);
  }

  render() {
    return null;
  }
}

export default LogRocketTracker;
