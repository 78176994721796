import { SubmissionError } from 'redux-form';
import {fetch} from '../../utils/dataAccess';
import {prepareRequestTemplateForSaving} from "../../utils/requestTemplate";
import {sort} from '../Request/util';

export function error(error) {
  return { type: 'REQUESTTEMPLATE_CREATE_ERROR', error };
}

export function loading(loading) {
  return { type: 'REQUESTTEMPLATE_CREATE_LOADING', loading };
}

export function success(created) {
  return { type: 'REQUESTTEMPLATE_CREATE_SUCCESS', created };
}

export function create(values) {
  return dispatch => {
    dispatch(loading(true));
    dispatch(success(null));

    const template = prepareRequestTemplateForSaving(values);
    return fetch('request_templates', { method: 'POST', data: template })
      .then(response => ({retrieved: sort(response.data)}))
      .then(({retrieved}) => {
        dispatch(loading(false));
        dispatch(success(retrieved));

        return retrieved;
      })
      .catch(e => {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw e;
        }

        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(error(null));
    dispatch(success(null));
  };
}
