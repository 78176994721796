import { combineReducers } from 'redux';

export function deactivateError(state = null, action) {
  switch (action.type) {
    case 'COMPANY_DEACTIVATE_ERROR':
      return action.deactivateError;

    case 'COMPANY_DEACTIVATE_RESET':
      return null;

    default:
      return state;
  }
}

export function deactivateLoading(state = false, action) {
  switch (action.type) {
    case 'COMPANY_DEACTIVATE_LOADING':
      return action.deactivateLoading;

    case 'COMPANY_DEACTIVATE_RESET':
      return false;

    default:
      return state;
  }
}

export function deactivated(state = null, action) {
  switch (action.type) {
    case 'COMPANY_DEACTIVATE_SUCCESS':
      return action.deactivated;

    case 'COMPANY_DEACTIVATE_RESET':
      return null;

    default:
      return state;
  }
}

export default combineReducers({
  deactivateError,
  deactivateLoading,
  deactivated,
});
