import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {retrieve, reset} from '../../actions/LiveInterview/show';
import {del} from '../../actions/LiveInterview/delete';
import IsGranted from "../IsGranted";
import {getLiveInterviewUrlByRoomCode} from "../../utils/urls";
import CommentsModal from "../LiveInterviewComment/Modal";
import {Grid} from "@material-ui/core";
import InvitationCard from "../CompanyCandidate/InvitationCard";
import ShareInterviewDialog from "../Access/ShareInterviewDialog";
import {grantAccess} from "./../../actions/LiveInterview/access";
import FlashMessages from "../../views/Components/FlashMessages";
import CompletedVideoChatPreview from "../LiveInterviewPage/CompletedVideoChatPreview";
import OverviewTable from "./OverviewTable";
import FlatCandidateCard from "../CompanyCandidate/FlatCandidateCard";
import {downloadFileFromApi, IRItoID} from "../../utils/dataAccess";
import moment from "moment-timezone";

class LiveInterviewShow extends Component {
  state = {
    openCommentsModal: false,
    openShareDialog: false,
    downloadVideoLoading: false,
  };

  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    deleteError: PropTypes.string,
    deleteLoading: PropTypes.bool.isRequired,
    deleted: PropTypes.object,
    del: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  del = () => {
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.del(this.props.retrieved);
  };

  handleClickOpen = () => {
    this.setState({ openCommentsModal: true });
  };

  handleClose = () => {
    this.setState({ openCommentsModal: false });
  };

  exportAsCSV = (item) => {
    const liveInterviewId = IRItoID(item['@id']);
    const url = `/live_interviews/${liveInterviewId}/export-csv`;
    const customFileName = `live-interview-${liveInterviewId}.csv`;

    downloadFileFromApi(url, customFileName);
  }

  downloadVideo = (item) => {
    if (item && item['composedVideo'] && item['composedVideo']['status'] === 'finished') {
      const liveInterviewId = IRItoID(item['@id']);
      const url = `/live_interviews/${liveInterviewId}/download-video`;

      this.setState({downloadVideoLoading: true});
      downloadFileFromApi(url).finally(() => {
        this.setState({downloadVideoLoading: false});
      })
    }
  }

  render() {
    if (this.props.deleted)
      this.props.history.push({
        pathname: `..`,
        state: {successMessage: 'Live Interview successfully deleted.'}
      });

    let liveInterviewUrl;
    let hasInvitedCandidates = false;
    const item = this.props.retrieved;

    if (item) {
      hasInvitedCandidates = item.candidateInvitations && item.candidateInvitations.length > 0;
      if (!item.completedAt) {
        liveInterviewUrl = getLiveInterviewUrlByRoomCode(item['roomCode'])
      }
    }

    return (
      <div className="live-interview-preview-page">
        <h1>Show &quot;{item && item['title']}&quot;</h1>
        <FlashMessages location={this.props.location} />
        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
            {this.props.error}
          </div>
        )}
        {this.props.deleteError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
            {this.props.deleteError}
          </div>
        )}
        {this.props.granted && (
          <FlashMessages type={"success"} message={"Interview successfully shared."} duration={10000} />
        )}
        {this.props.shareLinkSaved && (
          <FlashMessages type={"success"} message={"Share link successfully saved."} duration={10000} />
        )}

        <div className="grid-buttons live-interview-grid-buttons">
          <Link to={this.props.location.state && this.props.location.state.backToAll ? '../../live-interview-all/' : '..'} className="btn btn-primary">
            Back to list
          </Link>
          {item && (
            <>
              <IsGranted action='edit' object={item}>
                <Link to={`../edit/${encodeURIComponent(item['@id'])}`} className="btn btn-warning">Edit</Link>
              </IsGranted>
              <IsGranted action='list_reviews' object={item}>
                <Link to={`../../live-interview-reviews/${this.props.match.params.id}/reviews`} className="btn btn-info">
                  Reviews
                </Link>
              </IsGranted>
              <IsGranted action='list_comments' object={item}>
                <button type="button" className="btn btn-info" onClick={this.handleClickOpen}>
                  Comments
                </button>
              </IsGranted>
              <IsGranted action="grant_access" object={item}>
                <button onClick={() => this.setState({openShareDialog: true})} className="btn btn-info">
                  Share
                </button>
                <Link to={`../../live-interviews/access/${this.props.match.params.id}`} className="btn btn-info">
                  View Shared Users
                </Link>
              </IsGranted>
              <IsGranted action='export_live_interview' object={item}>
                <button type="button" className="btn btn-info" onClick={() => this.exportAsCSV(item)}>
                  Export as CSV
                </button>
              </IsGranted>

              {item && item['composedVideo'] && item['composedVideo']['status'] === 'finished' && (
                <button type="button" className="btn btn-info" onClick={() => this.downloadVideo(item)}>
                  {this.state.downloadVideoLoading ? (
                    <>Loading ... <i className={"fa fa-spin fa-spinner ml-2"} /></>
                  ) : (
                    'Download Video'
                  )}
                </button>
              )}

              <IsGranted action='delete' object={item}>
                <button onClick={this.del} className="btn btn-danger">
                  Delete
                </button>
              </IsGranted>
            </>
          )}
        </div>

        {this.state.openShareDialog && (
          <ShareInterviewDialog
            handleSubmit={(values) => this.props.grantAccess(item['@id'], values)}
            loading={this.props.grantAccessLoading}
            error={this.props.grantAccessError}
            open={this.state.openShareDialog}
            success={this.props.granted}
            onSuccess={() => this.setState({openShareDialog: false})}
            onClose={() => this.setState({openShareDialog: false})}
            liveInterview={this.props.retrieved}
          />
        )}

        {this.state.openCommentsModal && (
          <CommentsModal liveInterview={this.props.retrieved} handleClose={this.handleClose} />
        )}

        {item && (
          <>
            {item.completedAt && (
              <div className="block-rounded block-live-interview-recording">
                <h3>Live Interview Recording</h3>
                <CompletedVideoChatPreview liveInterview={item} />
              </div>
            )}

            <Grid container spacing={16}>
              <Grid item lg={hasInvitedCandidates ? 6 : 12} md={12} sm={12} xs={12}>
                <div className="block-rounded">
                  <h3>Overview</h3>
                  <OverviewTable item={item} liveInterviewUrl={liveInterviewUrl} />
                </div>
              </Grid>
              {hasInvitedCandidates && (
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <div className="block-rounded block-invited-candidates">
                    <h3>Invited Candidates</h3>
                    {item.candidateInvitations.map((invitation) => {
                      return (
                        <InvitationCard key={invitation['@id']} cardComponent={FlatCandidateCard} invitation={invitation} checkPermissions={true} />
                      )
                    })}
                  </div>
                </Grid>
              )}
            </Grid>
          </>
        )}

      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.liveinterview.show.retrieved,
  error: state.liveinterview.show.error,
  grantAccessError: state.liveinterview.access.grantAccessError,
  loading: state.liveinterview.show.loading,
  grantAccessLoading: state.liveinterview.access.grantAccessLoading,
  granted: state.liveinterview.access.granted,
  eventSource: state.liveinterview.show.eventSource,
  deleteError: state.liveinterview.del.error,
  deleteLoading: state.liveinterview.del.loading,
  deleted: state.liveinterview.del.deleted,
  shareLinkSaved: state.liveinterviewsharelink.update.updated,
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  del: item => dispatch(del(item)),
  grantAccess: (interviewId, values) => dispatch(grantAccess(interviewId, values)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveInterviewShow);
