import React, {Component} from "react";
import Dropzone from "react-dropzone";
import axios from "axios";
import './VideoUploader.scss';
import PropTypes from "prop-types";
import VideoProgressIndicator from "../../views/Components/VideoProgressIndicator";
import {isObject} from "../../utils/object";

export default class VideoUploader extends Component {
    static propTypes = {
        input: PropTypes.object.isRequired,
        accept: PropTypes.array,
        uploadPercents: PropTypes.number, //How much percentage uploading file to server takes in whole process
        waitingResponsePercents: PropTypes.number, //How much percentage waiting final response from server takes in whole process
    }

    static defaultProps = {
        accept: ['video/*'],
        uploadPercents: 45,
        waitingResponsePercents: 1,
    }

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            uploading: false,
            progress: 0,
        };
    }

    getUploadingConfig = () => {
        const {uploadPercents, waitingResponsePercents} = this.props;
        let config = {};
        config.onUploadProgress = (progressEvent) => {
            const uploadPercent = Math.round((progressEvent.loaded / progressEvent.total) * uploadPercents) ;
            this.setState({progress: uploadPercent});
        };

        const downloadPercents = 100 - uploadPercents - waitingResponsePercents;
        config.onDownloadProgress = (progressEvent) => {
            const target = progressEvent.target;
            if (!target || target.status !== 200) {
                return;
            }

            const progressObject = this.getLastChunkFromResponse(target.response);
            if (!progressObject || !progressObject.progress) {
                return;
            }
            const downloadProgress = Math.round((progressObject.progress * downloadPercents) / 100);
            this.setState({progress: uploadPercents + downloadProgress});
        };

        return config;
    }

    uploadVideo = (acceptedFiles, rejectedFiles) => {
        const {accept} = this.props;
        if (acceptedFiles.length > 1) {
            this.setError('Unexpected length of files array: ' + acceptedFiles.length);
            return;
        }

        const file = acceptedFiles.length > 0 ? acceptedFiles[0] : null;

        this.setState({
            uploading: true,
            progress: 0,
        });
        let formData = new FormData();
        formData.append('video', file);

        axios.post(this.props.endpoint, formData, this.getUploadingConfig()).then((response) => {
            this.setState({progress: 100});
            const data = this.getLastChunkFromResponse(response.data);
            if (data && data.error) {
                throw new Error(data.error);
            }
            this.props.input.onChange(data);
        }).catch(error => {
            this.reset();
            if (error && error.response && error.response.data && typeof error.response.data === 'string') {
                this.setError(error.response.data);
            } else {
                this.setError(error.message);
            }
        });
    };

    setError = (error) => {
        this.setState({error: error});
    }

    getLastChunkFromResponse = (response) => {
        if (!response) {
            return null;
        }

        if (isObject((response))) {
            return  response;
        }

        const chunks = response.split('\n');
        if (chunks.length === 0) {
            return null;
        }
        const lastChunk = (chunks[chunks.length - 1].trim().length) ? chunks[chunks.length - 1] : chunks[chunks.length - 2];
        const object = JSON.parse(lastChunk);

        return object ? object : null;
    }

    reset = () => {
        this.props.input.onChange(null);
        this.setState({
            uploading: false,
            error: null,
            progress: 0,
        })
    };

    render() {
        return (
            <div className="video-uploader">
                <div className="dropzone">
                    {this.state.error && (
                        <div className="alert alert-danger" role="alert">
                            <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
                            {this.state.error}
                        </div>
                    )}
                    {this.state.uploading ? (
                        <VideoProgressIndicator
                            className={'video-uploader-progress-indicator'}
                            label={'Uploading file...'}
                            progress={this.state.progress}
                        />
                    ) : (
                        <Dropzone onDrop={this.uploadVideo} multiple={false} accept={this.props.accept}>
                            {({getRootProps, getInputProps}) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p>Drag video file here, or click to select</p>
                                </div>
                            )}
                        </Dropzone>
                    )}
                </div>
            </div>
        );
    }
}
