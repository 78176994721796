import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import {getUserMedia} from "../../utils/liveInterview";
import DialogRoot from "../../views/Components/Dialog/DialogRoot";

class ChangeDeviceComponent extends React.Component {
  static propTypes = {
    streamManager: PropTypes.object.isRequired,
    getCurrentDeviceId: PropTypes.func.isRequired,
    filterDevices: PropTypes.func.isRequired,
    changeDevice: PropTypes.func.isRequired,
    renderDialog: PropTypes.func.isRequired,
    renderButton: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const {streamManager} = this.props;
    this.state = {
      currentDevice: '',
      selectedDevice: '',
      devices: {},
      dialogOpened: false,
      changing: false,
      disabled: !streamManager || !streamManager.stream,
    };
  }

  componentDidMount() {
    const {streamManager, getCurrentDeviceId, filterDevices} = this.props;
    const currentDeviceId = getCurrentDeviceId();

    streamManager.openvidu.getDevices().then(allDevices => {
      const devices = filterDevices(allDevices);
      this.setState({
        currentDevice: currentDeviceId,
        selectedDevice: currentDeviceId,
        devices: devices,
        disabled: Object.values(devices).length === 0,
      });
    });
  }

  onDeviceSelect = async (deviceId) => {
    const {streamManager} = this.props;
    this.setState({changing: true});
    this.props.changeDevice(streamManager, deviceId)
        .finally(this.finishDeviceChanging)
    ;
  };

  openDialog = () => {
    this.setState({dialogOpened: true});
  };

  finishDeviceChanging = () => {
    const {getCurrentDeviceId} = this.props;
    const deviceId = getCurrentDeviceId();
    this.setState({
      changing: false,
      dialogOpened: false,
      selectedDevice: deviceId,
      currentDevice: deviceId,
    });
  };

  renderChangeDeviceDialog = () => {
    const {dialogOpened} = this.state;
    const {renderDialog} = this.props;
    return (
      <DialogRoot
        properties={{
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
          maxWidth: "xs",
        }}
        open={dialogOpened}
      >
        {
          renderDialog(
            this.state.devices,
            this.state.currentDevice,
            this.state.selectedDevice,
            (selectedDevice) => this.setState({selectedDevice: selectedDevice}), //OnDeviceSelect
            this.finishDeviceChanging,
            this.onDeviceSelect,
            () => this.setState({dialogOpened: false})//CloseDialog
          )
        }
      </DialogRoot>
    );
  };

  render() {
    const {disabled, changing} = this.state;
    const {renderButton} = this.props;
    return (
      <>
        {renderButton(
          this.openDialog,
          disabled || changing,
          this.state,
          this.props
        )}
        {this.renderChangeDeviceDialog()}
      </>
    )
  }
}

export default ChangeDeviceComponent;
