import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Form from './Form';
import { save, reset } from '../../actions/OneWayInterviewReview/save';
import { retrieve as retrieveReview } from '../../actions/OneWayInterviewReview/show';
import { retrieve } from '../../actions/OneWayInterview/show';
import { del } from '../../actions/OneWayInterviewReview/delete';
import {getReviewScales, reset as resetReviewScales} from "../../actions/ReviewScale/show";
import {getReviewCriteria} from "../../utils/reviewScales";

class OneWayInterviewReviewEdit extends Component {
  static propTypes = {
    error: PropTypes.string,
    interviewLoading: PropTypes.bool.isRequired,
    reviewLoading: PropTypes.bool.isRequired,
    reviewSaveLoading: PropTypes.bool.isRequired,
    deleteLoading: PropTypes.bool.isRequired,
    interview: PropTypes.object,
    review: PropTypes.object,
    savedReview: PropTypes.object,
    deletedReview: PropTypes.object,
    updateReview: PropTypes.func.isRequired,
    deleteReview: PropTypes.func.isRequired,
  };
  componentDidMount() {
    this.props.getReviewScales();
    this.props.retrieveInterview(decodeURIComponent(this.props.match.params.interview));
    this.props.retrieveReview(decodeURIComponent(this.props.match.params.review));
  }

  componentWillUnmount() {
    this.props.reset();
  }

  del = () => {
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.deleteReview(this.props.review);
  };

  render() {
    if (this.props.savedReview)
      this.props.history.push({
        pathname: `../reviews`,
        state: {successMessage: 'Review successfully updated.'}
      });

    if (this.props.deletedReview)
      this.props.history.push({
        pathname: `../reviews`,
        state: {successMessage: 'Review successfully deleted.'}
      });

    return (
      <div>
        {this.props.interview && (
          <h2 className="mb-3">Edit Review On Demand Interview with&nbsp;
            {this.props.interview.companyCandidate.firstName} {this.props.interview.companyCandidate.lastName}
          </h2>
        )}

        {(this.props.interviewLoading ||
          this.props.reviewLoading ||
          this.props.reviewSaveLoading ||
          this.props.deleteLoading) && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}
        {!this.props.reviewLoading && !this.props.interviewLoading && this.props.review &&
         !this.props.criteriaLoading && this.props.modifiedCriteria && (
          <Form
            onSubmit={this.props.updateReview}
            initialValues={{...{}, ...this.props.review}}
            reviewCriteria={getReviewCriteria(this.props.modifiedCriteria)}
          />
        )}
        {this.props.review && (
          <button onClick={this.del} type="button" className="btn btn-danger mr-3 mb-3">Delete</button>
        )}
        <Link to={`../../../on-demand-interviews/show/${this.props.match.params.interview}`} className="btn btn-primary mr-3 mb-3">
          Back to Interview
        </Link>
        <Link to="../reviews" className="btn btn-primary mb-3">
          Back to All Reviews
        </Link>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const interviewLoading = state.onewayinterview.show.loading;
  const reviewLoading = state.onewayinterviewreview.list.loading;
  const reviewSaveLoading = state.onewayinterviewreview.save.loading;
  const deleteLoading = state.onewayinterviewreview.del.loading;
  const error = state.onewayinterview.show.error || state.onewayinterviewreview.list.error || state.onewayinterviewreview.save.error || state.onewayinterviewreview.del.error;
  const interview = state.onewayinterview.show.retrieved;
  const savedReview = state.onewayinterviewreview.save.saved;
  const deletedReview = state.onewayinterviewreview.del.deleted;
  let review = state.onewayinterviewreview.show.retrieved;

  return {
    interviewLoading,
    reviewLoading,
    reviewSaveLoading,
    deleteLoading,
    error,
    interview,
    savedReview,
    review,
    deletedReview,
    modifiedCriteria: state.reviewScale.show.criteria,
    criteriaLoading: state.reviewScale.show.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  retrieveReview: (id) => dispatch(retrieveReview(id)),
  updateReview: values => dispatch(save(values, values['@id'], 'PUT')),
  deleteReview: review => dispatch(del(review)),
  retrieveInterview: (id) => dispatch(retrieve(id)),
  getReviewScales: () => dispatch(getReviewScales()),
  reset: () => {
    dispatch(reset());
    dispatch(resetReviewScales());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OneWayInterviewReviewEdit);
