import { SubmissionError } from 'redux-form';
import { fetch } from '../../utils/dataAccess';
import { updateSuccess, updateLoading, updateError } from './update';
import { success as deleteSuccess, loading as deleteLoading, error as deleteError} from './delete';

export function error(error) {
  return { type: 'LIVEINTERVIEWCOMMENT_CREATE_ERROR', error };
}

export function loading(loading) {
  return { type: 'LIVEINTERVIEWCOMMENT_CREATE_LOADING', loading };
}

export function success(created) {
  return { type: 'LIVEINTERVIEWCOMMENT_CREATE_SUCCESS', created };
}

export function create(values) {
  return dispatch => {
    dispatch(loading(true));

    return fetch('live_interview_comments', { method: 'POST', data: values })
      .then(response => {
        dispatch(loading(false));
        dispatch(success(response.data));
      })
      .catch(e => {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw e;
        }

        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(error(null));
    dispatch(success(null));

    dispatch(updateLoading(false));
    dispatch(updateError(null));
    dispatch(updateSuccess(null));

    dispatch(deleteLoading(false));
    dispatch(deleteError(null));
    dispatch(deleteSuccess(null));
  };
}
