export function interview (state = null, action) {
  switch (action.type) {
    case 'ON_DEMAND_INTERVIEW_PAGE_INTERVIEW':
      return action.interview;
    case 'ON_DEMAND_INTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}

export function creatingInterview (state = false, action) {
  switch (action.type) {
    case 'ON_DEMAND_INTERVIEW_PAGE_INTERVIEW_CREATING':
      return action.creating;
    case 'ON_DEMAND_INTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}

export function startingInterview (state = false, action) {
  switch (action.type) {
    case 'ON_DEMAND_INTERVIEW_PAGE_INTERVIEW_STARTING':
      return action.starting;
    case 'ON_DEMAND_INTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}
