import React, {Component} from 'react';
import RecorderVideo from "./RecorderVideo";
import RecorderControls from "./RecorderControls";
import PropTypes from "prop-types";
import './VideoRecorderUi.scss';

const defaultButtonLabels = {
  recordAgain: 'Retry Your Answer',
};

class VideoRecorderUi extends Component {
  static propTypes = {
    streamManager: PropTypes.object,
    videoUrl: PropTypes.string,
    videoText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    buttonLabels: PropTypes.object,
    additionalButtons: PropTypes.object,
    className: PropTypes.string,
    allowedToRecord: PropTypes.bool.isRequired,
    startRecordingDisabled: PropTypes.bool.isRequired,
    handleRecordingStart: PropTypes.func.isRequired,
    handleRecordingStop: PropTypes.func.isRequired,
    handleRecordAgain: PropTypes.func.isRequired,
  };

  static defaultProps = {
    buttonLabels : {},
    additionalButtons: {},
    className: '',
    allowedToRecord: true,
    startRecordingDisabled: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      recording: false,
      startingRecord: false,
      stoppingRecord: false,
      recordingWasStarted: false,
    };

    this.video = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.videoUrl !== this.props.videoUrl && this.props.videoUrl) {
      //We update state here and not in stopRecording function after handleRecordingStop is resolved to prevent errors
      //in case this component is unmounted after video is recorded, and this is the case in the most of the time
      this.setState({stoppingRecord: false});
    }
  }

  playRecording = () => {
    if (this.video) {
      this.video.current.play();
    }
  };

  resetUi = () => {
    this.setState({
      recording: false,
      startingRecord: false,
      stoppingRecord: false,
    });
  };

  recordAgain = () => {
    this.resetUi();
    this.props.handleRecordAgain();
  };

  startRecording = () => {
    this.setState({
      recording: false,
      startingRecord: true,
    }, () => {
      this.props.handleRecordingStart()
        .then(() => {
          this.setState({
            recording: true,
            startingRecord: false,
            recordingWasStarted: true,
          });
        })
        .catch((e) => {
          this.setState({
            startingRecord: false,
          });
        });
    });
  };

  stopRecording = () => {
    this.setState({
      recording: false,
      stoppingRecord: true,
    }, () => {
      this.props.handleRecordingStop()
        .catch((e) => {})
    });
  }

  toggleRecording = () => {
    if (this.state.recording) {
      this.stopRecording();
    } else {
      this.startRecording();
    }
  };

  render() {
    const {recording, stoppingRecord, startingRecord, recordingWasStarted} = this.state;
    const {videoUrl, streamManager, additionalButtons, allowedToRecord, startRecordingDisabled, className} = this.props;
    const videoText = recordingWasStarted ? undefined : this.props.videoText;

    const buttonLabels = Object.assign({}, defaultButtonLabels, this.props.buttonLabels);

    return (
      <div className={className}>
        <div className="video-recorder">
          <RecorderVideo
            stoppingRecord={stoppingRecord}
            recordingUrl={videoUrl}
            streamManager={streamManager}
            videoStreamLoaded={!!streamManager}
            videoText={videoText}
            className={'video-recorder-video'}
            recordedVideoRef={this.video}
          />
          <div className={'video-recorder-controls'}>
            {streamManager ? (
              <RecorderControls
                recording={recording}
                startingRecord={startingRecord}
                stoppingRecord={stoppingRecord}
                recordingUrl={videoUrl}
                toggleRecording={this.toggleRecording}
                playRecording={this.playRecording}
                recordAgain={this.recordAgain}
                recordAgainMessage={buttonLabels['recordAgain']}
                allowedToRecord={allowedToRecord}
                startRecordingDisabled={startRecordingDisabled}
                {...additionalButtons}
              />
            ) : (additionalButtons.noVideoButtons)
            }
          </div>
        </div>
      </div>
    );
  }
}

export default VideoRecorderUi;
