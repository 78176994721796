import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Field, formValueSelector, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {renderCheckbox, renderTextField} from "../../utils/form";
import {loadTeamsForSelect} from "../../actions/Team/forSelect";
import SearchableSelect from "../../views/Components/SearchableSelect";

class CompanyUserForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
    isNew: PropTypes.bool,
    showRoleField: PropTypes.bool,
  };

  componentDidMount() {
    const filters = {};
    const initialValues = this.props.initialValues;
    if (initialValues && initialValues.teams) {
      filters.withIds = initialValues.teams;
    }
    this.props.loadTeamsForSelect(filters);
  }

  isTeamFieldsShouldBeShown = () => {
    return this.props.userRole !== 'ROLE_COMPANY_ADMIN';
  };

  render() {
    const roles = [
      {value: 'ROLE_COMPANY_ADMIN', text: 'Company Admin'},
      {value: 'ROLE_COMPANY_EMPLOYEE', text: 'Company Employee'},
      {value: 'ROLE_COMPANY_HIRING_MANAGER', text: 'Company Hiring Manager'},
    ];

    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={renderTextField}
          name="firstName"
          type="text"
          placeholder="First Name"
          required={true}
        />
        <Field
          component={renderTextField}
          name="lastName"
          type="text"
          placeholder="Last Name"
          required={true}
        />
        <Field
          component={renderTextField}
          name="email"
          type="email"
          placeholder="Email"
          required={true}
        />
        <Field
          component={renderCheckbox}
          name="receiveNotifications"
          label="Receive Email Notifications"
        />
        <Field
          component={renderTextField}
          name="phoneNumber"
          type="text"
          placeholder="Phone Number"
          required={true}
        />
        {this.props.isNew && (
          <>
          <Field
            component={renderTextField}
            name="plainPassword"
            type="password"
            placeholder="Password"
            required={true}
          />
          <Field
            component={renderTextField}
            name="confirmPassword"
            type="password"
            label="Confirm Password"
            placeholder="Confirm Password"
            required={true}
          />
          </>
        )}
        {this.props.showRoleField && (
          <Field
            component={SearchableSelect}
            name="role"
            label="Role"
            placeholder="Role"
            required={true}
            options={roles}
          />
        )}
        {this.isTeamFieldsShouldBeShown() && (
          <Field
            name='teams'
            label='Teams'
            placeholder='Teams'
            isMulti={true}
            component={SearchableSelect}
            options={this.props.teams}
          />
        )}
        <button type="submit" className="btn btn-success float-left mr-3">
          Submit
        </button>
      </form>
    );
  }
}

const selector = formValueSelector('companyUser');
const mapStateToProps = state => {
  let teams = state.team.forSelect.retrieved;
  let userRole = selector(state, 'role') || 'ROLE_COMPANY_ADMIN';
  return { teams, userRole };
};

const mapDispatchToProps = dispatch => ({
  loadTeamsForSelect: (filters) => dispatch(loadTeamsForSelect('@id', filters)),
});

const validate = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'This field should not be blank.'
  }

  if (!values.lastName) {
    errors.lastName = 'This field should not be blank.'
  }

  if (!values.email) {
    errors.email = 'This field should not be blank.'
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = 'This field should not be blank.'
  }

  if (values.role === 'ROLE_COMPANY_EMPLOYEE' && (!values.teams || values.teams.length === 0)) {
    errors.teams = 'Please select at least one Team.'
  }

  if (values.plainPassword !== undefined && values.plainPassword !== values.confirmPassword) {
    errors.confirmPassword = 'Passwords are not the same';
  }

  return errors;
};

CompanyUserForm = reduxForm({
  form: 'companyUser',
  validate: validate,
})(CompanyUserForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyUserForm);
