import React, {Component} from "react";
import {retrieve, reset} from "../../actions/CandidateCompany/show";
import {connect} from "react-redux";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {history} from "../../store";
import {withStyles} from "@material-ui/core";
import PhoneNumber from "../../views/Components/PhoneNumber";

const style = {
  logo: {
    display: 'block',
    width: 'auto',
    height: 'auto',
    margin: 'auto',
    maxHeight: '500px',
    maxWidth: '100%',
  },
};

class CandidateCompanyShow extends Component {
  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }
  componentWillUnmount() {
    this.props.reset();
  }
  render() {
    const item = this.props.retrieved;
    const {classes} = this.props;
    return (
      <div>
        {item && (
        <h1>&quot;{item && item['name']}&quot; profile</h1>
        )}

        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}

        <div className="grid-buttons">
          <button
            className="btn btn-primary"
            onClick={() => history.goBack()}
          >
            Back
          </button>
        </div>

        {item && (
          <div style={{ 'overflowX': 'auto' }}>
            <Table className="table table-responsive table-striped table-hover">
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item['logo'] && (
                  <TableRow>
                    <TableCell scope="row">Logo</TableCell>
                    <TableCell><img className={classes.logo} alt={item.name} src={item['logo']['url']} /></TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell scope="row">Name</TableCell>
                  <TableCell>{item['name']}</TableCell>
                </TableRow>
                {item['address'] && (
                  <TableRow>
                    <TableCell scope="row">Address</TableCell>
                    <TableCell>{item['address']}</TableCell>
                  </TableRow>
                )}
                {item['address2'] && (
                  <TableRow>
                    <TableCell scope="row">Address 2</TableCell>
                    <TableCell>{item['address2']}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell scope="row">City</TableCell>
                  <TableCell>{item['city']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">State</TableCell>
                  <TableCell>{item['state'] ? item['state']['name'] : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">ZIP Code</TableCell>
                  <TableCell>{item['zipCode']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Description</TableCell>
                  <TableCell>
                    <p dangerouslySetInnerHTML={{ __html: item['description'] }} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Phone Number</TableCell>
                  <TableCell>
                    <PhoneNumber phone={item['phoneNumber']} formattedPhone={item['formattedPhoneNumber']} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Email Address</TableCell>
                  <TableCell>{item['emailAddress']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Web Site</TableCell>
                  <TableCell>{
                    item['webSite'] ?
                      (<a href={item['webSite']} target="_blank">{item['webSite']}</a>) :
                      '-'
                  }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    );
  }
}

CandidateCompanyShow = withStyles(style)(CandidateCompanyShow);

const mapStateToProps = state => ({
  retrieved: state.candidateCompany.show.retrieved,
  error: state.candidateCompany.show.error,
  loading: state.candidateCompany.show.loading,
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  reset: () => dispatch(reset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateCompanyShow);
