import React, {Component} from "react";
import CheckBox from "@material-ui/icons/CheckBox";
import Done from "@material-ui/icons/Done";
import Close from "@material-ui/icons/Close";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import PropTypes from "prop-types";
import {isObject} from "../../utils/object";

export default class KnockoutQuestionAnswerView extends Component {
    static propTypes = {
        question: PropTypes.object,
        answer: PropTypes.object,
        showCorrect: PropTypes.bool,
    };

    isChoiceSelected = (choice, answer) => {
        if (!answer || !answer.knockoutQuestionChoices) {
            return false;
        }

        return answer.knockoutQuestionChoices.find((answerChoice) => {
            return answerChoice === choice['@id'];
        }) !== undefined;
    };

    isAnswerCorrect = (question, answer) => {
        if (!question || !answer) {
            return true;
        }

        if ('text' === question.type) {
            return true;
        }

        let choices = question.knockoutQuestionChoices;
        if (question.type === 'single') {
            for (let i = 0; i < choices.length; i++) {
                if (choices[i].correct && this.isChoiceSelected(choices[i], answer)) {
                    return true;
                }
            }
            return false;
        }

        for (let i = 0; i < choices.length; i++) {
            const isSelected = this.isChoiceSelected(choices[i], answer);
            if (choices[i].correct && !isSelected) {
                return false;
            }

            if (isSelected && !choices[i].correct) {
                return false;
            }
        }
        return true;
    };

    render() {
        const {question, answer, showCorrect} = this.props;
        const skipped = answer && answer.skipped;
        let isAnswerCorrect = this.isAnswerCorrect(question, answer);
        return (
            <>
                <h4>Question</h4>
                <p  className={skipped ? 'text-secondary' : ''} style={{
                    fontSize: '15px',
                    color: skipped || !showCorrect || isAnswerCorrect ? '' : 'red',
                    fontWeight: skipped || !showCorrect || isAnswerCorrect ? '' : 'bold'
                }}>
                    {question.content}{question.required && (<span className="text-danger"> *</span>)}
                </p>
                <hr />
                <h5>Answer</h5>
                {answer ? (
                    <>
                    {skipped && (
                        <h4 className="text-muted">Skipped</h4>
                    )}
                    {'text' === question.type && !skipped && (
                        <div className="on-demand-text-answer-preview">{answer.text}</div>
                    )}
                    {('single' === question.type || 'multiple' === question.type) && !skipped && question.knockoutQuestionChoices.map((choice, index) => {
                        const isSelected = this.isChoiceSelected(choice, answer);
                        const shouldHaveIcon = showCorrect && (
                            (question.type === 'multiple' && (isSelected || choice.correct)) ||
                            (question.type === 'single' && isSelected)
                        );
                        const isCorrect = (isSelected && choice.correct) || (!isSelected && !choice.correct);
                        return (
                            <p key={index}>
                                {
                                    isSelected ?
                                        question.type === 'multiple' ?
                                            (<CheckBox nativeColor="#f50057"/>) :
                                            (<RadioButtonChecked nativeColor="#f50057"/>)

                                        : question.type === 'multiple' ?
                                        (<CheckBoxOutlineBlank nativeColor="rgba(0, 0, 0, 0.54)"/>) :
                                        (<RadioButtonUnchecked nativeColor="rgba(0, 0, 0, 0.54)"/>)

                                }
                                {choice.content}
                                {shouldHaveIcon &&
                                (<>
                                    {
                                        isCorrect ?
                                            (<Done nativeColor="#41af0a"/>) :
                                            (<Close nativeColor="#ff0000"/>)
                                    }
                                </>)
                                }
                            </p>);
                    })}
                    </>) : ( '-' )
                }
            </>
        );
    }
}
