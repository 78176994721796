import React from "react";
import VideoInterview from "./VideoInterview";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import JobInfo from "../Interview/JobInfo";
import InterviewInfo from "./InterviewInfo";
import {connect} from "react-redux";
import {
  EVENTS_SESSION_CHAT_MESSAGE,
  EVENTS_SESSION_CHAT_USER_JOIN,
  EVENTS_SESSION_CHAT_USER_LEFT,
  EVENTS_SESSION_INTERVIEW_ENDED,
  EVENTS_SESSION_INTERVIEW_LEFT,
  EVENTS_SESSION_INTERVIEW_PARTICIPANT_JOINED,
  EVENTS_SESSION_INTERVIEW_PARTICIPANT_CLOSE_STREAM,
  EVENTS_SESSION_INTERVIEW_SIP_AUDIO_CONNECTED,
  EVENTS_SESSION_INTERVIEW_STARTED,
  EVENTS_SESSION_INTERVIEW_VIDEO_CONNECTION_RECOVERY_FAILED,
  EVENTS_SESSION_INTERVIEW_SIP_AUDIO_DISCONNECTED,
  EVENTS_SESSION_INTERVIEW_VIDEO_SESSION_CONNECTED,
  EVENTS_SESSION_RECORDING_STARTED,
  EVENTS_SESSION_RECORDING_STOPPED,
  EVENTS_SESSION_CHAT_USER_JOINED,
  EVENTS_SESSION_INTERVIEW_PARTICIPANT_START_SPEAKING,
  EVENTS_SESSION_INTERVIEW_PARTICIPANT_STOP_SPEAKING
} from "../../utils/liveInterviewSession";
import {
  addParticipant,
  clearParticipants, joinToInterview, reconnectToInterview,
  closeParticipantStream, setInterviewCompletedAt, setInterviewStartedAt, sipAudio,
  startInterview, setPublisherPreferences, muteAllParticipants, joiningToInterview, initPublisher,
  fullScreenMode, setFocusedParticipantId, setRecordingStatus, updateParticipantSpeakingState
} from "../../actions/LiveInterviewPage/interview";
import {clearChatMessages, handleChatMessage} from "../../actions/LiveInterviewPage/chat";
import AudioVideoTest from "./AudioVideoTest";
import {currentUserHasRole} from "../../utils/auth";
import {history} from "../../store";
import classNames from 'classnames';
import {
  PUBLISHER_SIP_AUDIO_CONNECTED,
  PUBLISHER_SIP_AUDIO_DISCONNECTED,
  isStreamManagerMuted,
  muteStreamManager,
  streamManagerHasAudio,
  unmuteStreamManager, isScreenShareStreamManager,
} from "../../utils/streamManager";

class LiveInterviewContent extends React.Component {
  componentDidMount() {
    if (this.props.session) {
      this.listenSessionEvents(this.props.session);
    }
  }

  componentWillUnmount() {
    const {publisher, session} = this.props;
    if (session) {
      session.removeAllListeners();
    }
    if (publisher) {
      publisher.off('streamPropertyChanged', this.unmuteParticipantsWhenDeviceChanged);
    }
  }

  listenSessionEvents = (session) => {
    const {
      setSipAudio, addParticipant, closeParticipantStream, clearParticipants,
      clearChatMessages, addChatMessage, setFullscreen, setRecording
    } = this.props;
    //Video interview events
    session.on(EVENTS_SESSION_INTERVIEW_STARTED, () => {
      const {testing, startingTest, joiningInterview, joinedInterview, invitation, liveInterview, liveInterviewParticipant, isHost} = this.props;
      if (liveInterview) {
        this.props.setInterviewStartedAt(liveInterview);
      }

      const isCandidate = currentUserHasRole('ROLE_CANDIDATE');

      if (joiningInterview || joinedInterview || testing || startingTest) {
        //No need to autojoin to started interview
        return;
      }
      if ((isCandidate && invitation.status === 'accepted') || !isCandidate && liveInterviewParticipant || isHost) {
        this.joinInterview();
      }
    });
    session.on(EVENTS_SESSION_INTERVIEW_SIP_AUDIO_CONNECTED, (sipAudio) => {
      this.onSipAudioConnected();
      setSipAudio(sipAudio);
    });
    session.on(EVENTS_SESSION_INTERVIEW_SIP_AUDIO_DISCONNECTED, () => {
      const {publisher} = this.props;
      if (streamManagerHasAudio(publisher) && isStreamManagerMuted(publisher)) {
        unmuteStreamManager(publisher);
      }
      this.props.muteAllParticipants(false);
      publisher.emitEvent(PUBLISHER_SIP_AUDIO_DISCONNECTED);
      setSipAudio(null);
    });
    session.on(EVENTS_SESSION_INTERVIEW_PARTICIPANT_JOINED, (participant) => {
      console.log('Participant joined', participant);
      addParticipant(participant);
      if (
        participant &&
        participant.streamManager &&
        isScreenShareStreamManager(participant.streamManager)
      ) {
        setFullscreen();
        this.props.setFocusedParticipantId(participant.id);
      }
    });
    session.on(EVENTS_SESSION_INTERVIEW_PARTICIPANT_CLOSE_STREAM, (userData) => {
      const { focusedParticipantId } = this.props;
      if (userData.id === focusedParticipantId) {
        this.props.setFocusedParticipantId(null);
      }
      closeParticipantStream(userData);
    });
    session.on(EVENTS_SESSION_INTERVIEW_PARTICIPANT_START_SPEAKING, (userData) => {
      this.props.updateParticipantSpeakingState(userData, true);
    });
    session.on(EVENTS_SESSION_INTERVIEW_PARTICIPANT_STOP_SPEAKING, (userData) => {
      this.props.updateParticipantSpeakingState(userData, false);
    });
    session.on(EVENTS_SESSION_INTERVIEW_LEFT, clearParticipants);
    session.on(EVENTS_SESSION_CHAT_USER_JOINED, clearChatMessages);
    session.on(EVENTS_SESSION_INTERVIEW_VIDEO_SESSION_CONNECTED, () => {
      if (this.streamNeedToBePublished()) {
        this.publishStream()
      }
    });
    session.on(EVENTS_SESSION_INTERVIEW_VIDEO_CONNECTION_RECOVERY_FAILED, this.reconnectToInterview);
    session.on(EVENTS_SESSION_INTERVIEW_ENDED, this.handleInterviewEnd);
    session.on(EVENTS_SESSION_RECORDING_STARTED, () => setRecording(true));
    session.on(EVENTS_SESSION_RECORDING_STOPPED, () => setRecording(false));

    //Chat events
    session.on(EVENTS_SESSION_CHAT_USER_JOIN, (message) => addChatMessage('user_join', message));
    session.on(EVENTS_SESSION_CHAT_USER_LEFT, (message) => addChatMessage('user_disconnect', message));
    session.on(EVENTS_SESSION_CHAT_MESSAGE, (message) => addChatMessage('chat_message', message));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.streamNeedToBePublished()) {
      this.publishStream();
    }

    if (this.props.session && !prevProps.session) {
      const {session} = this.props;
      this.props.setRecording(session.recording);
      this.listenSessionEvents(session);
    }

    if (this.props.publisher && !prevProps.publisher) {
      this.props.publisher.on('streamPropertyChanged', this.unmuteParticipantsWhenDeviceChanged);
      if (this.props.sipAudio) {
        this.onSipAudioConnected();
      }
    }

    if (
      prevProps.liveInterview && !prevProps.liveInterview.completedAt &&
      this.props.liveInterview && this.props.liveInterview.completedAt
    ) {
      const isCandidate = currentUserHasRole('ROLE_CANDIDATE');
      if (isCandidate) {
        history.push('/candidate/live-interviews/');
      } else {
        history.push('/company/live-interviews/');
      }
    }
  }

  streamNeedToBePublished = () => {
    const {publisher, joinedToInterview, session} = this.props;
    return joinedToInterview && publisher && session && !publisher.session && session.isReadyToPublishStream();
  }

  onSipAudioConnected = () => {
    const {publisher} = this.props;
    this.props.muteAllParticipants(true);
    if (!publisher) {
      return;
    }
    if (streamManagerHasAudio(publisher) && !isStreamManagerMuted(publisher)) {
      muteStreamManager(publisher);
    }
    publisher.emitEvent(PUBLISHER_SIP_AUDIO_CONNECTED);
  }

  unmuteParticipantsWhenDeviceChanged = (event) => {
    const {sipAudio} = this.props;
    if (
      !sipAudio &&
      event.changedProperty === 'audioActive' &&
      event.reason === 'deviceChanged' &&
      event.newValue !== false
    ) {
      this.props.muteAllParticipants(false);
    }
  }

  startInterview = (config) => {
    const {session, publisherPreferences} = this.props;
    this.props.setPublisherPreferences(Object.assign({}, publisherPreferences, config));
    return this.props.startInterview(session);
  };

  //To allow user join to the interview without mediaStream we split process on two phases
  // 1. Joining to interview and creating the publisher
  // 2. Try to publish media stream to the session
  joinInterview = (config) => {
    const {session, publisherPreferences} = this.props;
    const preferences = Object.assign({}, publisherPreferences, config);
    this.props.setPublisherPreferences(preferences);
    this.props.createPublisher(preferences)

    return this.props.joinToInterview(session);
  };

  publishStream = () => {
    const {session, publisher} = this.props;
    if (session && publisher) {
      session.publishStream(publisher);
    }
  };

  reconnectToInterview = () => {
    const {session, publisher} = this.props;
    return this.props.reconnectToInterview(session, publisher);
  };

  handleInterviewEnd = () => {
    const {liveInterview} = this.props;
    if (!liveInterview) {
      return;
    }
    this.props.setInterviewCompletedAt(liveInterview);
  };

  render() {
    const {testing, startingTest, joinedToInterview, liveInterview, session} = this.props;
    if (joinedToInterview) {
      return <VideoInterview liveInterview={liveInterview} session={session} />
    }
    return (
      <GridContainer className={classNames('interview-page-content-grid-container')}>
        <GridItem xs={12} md={6} lg={8}>
          {(testing || startingTest) && (<AudioVideoTest />)}
          {!testing && (<JobInfo job={liveInterview.job} />)}
        </GridItem>
        <GridItem xs={12} md={6} lg={4} className={'live-interview-page-content-interview-grid-item'}>
          <InterviewInfo
            startInterview={this.startInterview}
            joinInterview={this.joinInterview}
          />
        </GridItem>
      </GridContainer>
    )
  }
}

const mapStateToProps = state => ({
  session: state.liveInterviewPage.session,
  testing: state.liveInterviewPage.testing,
  startingTest: state.liveInterviewPage.startingTest,
  liveInterview: state.liveInterviewPage.liveInterview,
  liveInterviewParticipant: state.liveInterviewPage.liveInterviewParticipant,
  invitation: state.liveInterviewPage.invitation,
  joinedToInterview: state.liveInterviewPage.joinedToInterview,
  joiningToInterview: state.liveInterviewPage.joiningToInterview,
  startingInterview: state.liveInterviewPage.startingInterview,
  publisher: state.liveInterviewPage.publisher,
  publisherPreferences: state.liveInterviewPage.publisherPreferences,
  sipAudio: state.liveInterviewPage.sipAudio,
  focusedParticipantId: state.liveInterviewPage.focusedParticipantId,
  isHost: state.liveInterviewPage.isHost,
  screenSize: state.screen.size,
});

const mapDispatchToProps = dispatch => ({
  setSipAudio: (sipManager) => dispatch(sipAudio(sipManager)),
  addParticipant: (participant) => dispatch(addParticipant(participant)),
  closeParticipantStream: (data) => dispatch(closeParticipantStream(data.id, data.streamType)),
  clearParticipants: () => dispatch(clearParticipants()),
  updateParticipantSpeakingState: (participant, speaking) => dispatch(updateParticipantSpeakingState(participant, speaking)),
  muteAllParticipants: (mute) => dispatch(muteAllParticipants(mute)),
  clearChatMessages: () => dispatch(clearChatMessages()),
  addChatMessage: (type, message) => dispatch(handleChatMessage(type, message)),
  startInterview: (session) => dispatch(startInterview(session)),
  joinToInterview: (session, config) => dispatch(joinToInterview(session, config)),
  setPublisherPreferences: (config) => dispatch(setPublisherPreferences(config)),
  createPublisher: (publisherPreferences) => dispatch(initPublisher(publisherPreferences)),
  reconnectToInterview: (session, publisher) => dispatch(reconnectToInterview(session, publisher)),
  setInterviewStartedAt: (interview) => dispatch(setInterviewStartedAt(interview)),
  setInterviewCompletedAt: (interview) => dispatch(setInterviewCompletedAt(interview)),
  setFullscreen: () => dispatch(fullScreenMode(true)),
  setFocusedParticipantId: (participantId) => dispatch(setFocusedParticipantId(participantId)),
  setRecording: (recording) => dispatch(setRecordingStatus(recording)),
});

LiveInterviewContent = connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveInterviewContent);

export default LiveInterviewContent;
