import {currentUserHasRole, handleAuthentication, login} from "../../utils/auth";
import {fetch} from "../../utils/dataAccess";
import {error as pageError} from "./page";

export function logIn(email, password) {
  return dispatch => {
    dispatch(loggingIn(true));
    dispatch(error(null));
    login(email, password)
      .then(res => {
        handleAuthentication(res.data, false);
        if (currentUserHasRole('ROLE_CANDIDATE')) {
          return dispatch(getCandidateProfile());
        }
        return null;
      }).then(() => {
        dispatch(loggingIn(false));
        dispatch(loggedIn(true));
      })
      .catch(e => {
        dispatch(loggingIn(false));
        dispatch(loggedIn(false));
        dispatch(error(e.message));
      });
  }
}

export function getCandidateProfile() {
  return dispatch => {
    dispatch(candidateProfileLoading(true));
    return fetch('/users/current')
      .then(response => {
        const profile = response.data;
        if (profile['@type'] && profile['@type'].toLowerCase() === 'candidate') {
          dispatch(candidateProfile(profile));
          dispatch(candidateProfileLoading(false));
        }
      })
      .catch(e => {
        dispatch(candidateProfileLoading(false));
        dispatch(pageError(e));
      })
  }
}

export function candidateProfile(candidateProfile) {
  return {type: 'ON_DEMAND_INTERVIEW_PAGE_AUTH_CANDIDATE_PROFILE', candidateProfile};
}

export function loggedIn(loggedIn) {
  return {type: 'ON_DEMAND_INTERVIEW_PAGE_AUTH_LOGGED_IN', loggedIn};
}

function loggingIn(loggingIn) {
  return {type: 'ON_DEMAND_INTERVIEW_PAGE_AUTH_LOGGING_IN', loggingIn};
}

function error(error) {
  return {type: 'ON_DEMAND_INTERVIEW_PAGE_AUTH_ERROR', error};
}

function candidateProfileLoading(loadingStatus) {
  return {type: 'ON_DEMAND_INTERVIEW_PAGE_CANDIDATE_PROFILE_LOADING', loadingStatus}
}
