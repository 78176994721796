import { combineReducers } from 'redux';

export function acceptError(siteSettings = null, action) {
  switch (action.type) {
    case 'SITE_SETTINGS_ACCEPT_ERROR':
      return action.acceptError;

    default:
      return siteSettings;
  }
}

export function acceptLoading(siteSettings = false, action) {
  switch (action.type) {
    case 'SITE_SETTINGS_ACCEPT_LOADING':
      return action.acceptLoading;

    default:
      return siteSettings;
  }
}

export function accepted(siteSettings = null, action) {
  switch (action.type) {
    case 'SITE_SETTINGS_ACCEPT_SUCCESS':
      return action.accepted;

    default:
      return siteSettings;
  }
}

export default combineReducers({
  acceptError,
  acceptLoading,
  accepted,
});
