import React, {Component} from 'react';
import RecorderControls from "../VideoRecorder/RecorderControls";
import {generateRandomString} from "../../utils/string";
import PropTypes from "prop-types";
import Loader from "../Loader/Loader";
import AudioVisualizer from "./AudioVisualizer";
import {isFirefox, isSafari} from "../../utils/userAgent";
import browserRecordingSocket from "../../utils/browserRecordingSocket";
import {fixWebmDuration} from "../../utils/webm/webmDuration";
import {refreshToken} from "../../utils/authTokenRefresh";

const RECORDING_STOPPING_TIMEOUT = 10000;

class AudioRecorder extends Component {
  static propTypes = {
    socketRecordingType: PropTypes.string,
    streamManager: PropTypes.object,
    overlayText: PropTypes.string,
    onRecordingStart: PropTypes.func,
    onRecordingStop: PropTypes.func,
    onRecordingAgain: PropTypes.func,
    onRecorded: PropTypes.func,
    allowedToRecordAnswer: PropTypes.bool,
    recordingUrl: PropTypes.string,
    className: PropTypes.string,
    audioText: PropTypes.string,
    messages: PropTypes.object,
    additionalButtons: PropTypes.object,
    autostartRecording: PropTypes.bool,
    startRecordingDisabled: PropTypes.bool,
  };

  static defaultProps = {
    messages : {
      recordAgain: 'Retry Your Answer',
    },
    additionalButtons: {},
    className: '',
  };

  constructor(props) {
    super(props);

    this.audioElementId = generateRandomString(10) + '_audio_element';
    this.audio = React.createRef();
    this.socket = null;
    this.recordingId = generateRandomString(30);
    this.recordingChunks = [];
    this.stoppingTimeout = null;

    this.state = {
      mediaRecorder: null,
      recordingStartedAt: null,
    };
  }

  componentDidMount() {
    if (this.props.socketRecordingType) {
      this.initRecordingSocket();
    }
  }


  initRecordingSocket = () => {
    this.socket = browserRecordingSocket.initSocket(this.props.socketRecordingType, this.recordingId);
    this.socket.on('recording:result', this.onRecorded);
    this.socket.on('recording:error', this.onError);
    this.socket.on('recording:token-expired', this.onTokenExpired);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.recording && !prevProps.autostartRecording && this.props.autostartRecording) {
      this.startRecording();
    }
  }

  createMediaRecorder = (stream) => {
    const mediaRecorder = new MediaRecorder(stream, this.getMediaRecorderOptions());
    mediaRecorder.onstart = this.onRecordingStart;
    mediaRecorder.ondataavailable = this.onRecordingData;
    mediaRecorder.onstop = this.onRecordingStop;

    return mediaRecorder;
  }

  getMediaRecorderOptions = () => {
    const options = {};
    if (isFirefox()) {
      options['mimeType'] = 'audio/webm;codecs=opus'; //Workaround of issue in Firefox 87
    }
    return options;
  }

  toggleRecording = () => {
    if (this.state.recording) {
      this.stopRecording();
    } else {
      this.startRecording();
    }
  }

  resetAudio = () => {
    this.setState({
      mediaRecorder: null,
      recordingStartedAt: null,
    });
    this.recordingChunks = [];
    if (this.props.onRecordingAgain) {
      this.props.onRecordingAgain();
    }
  }

  playRecording = () => {
    if (this.audio && this.audio.current) {
      this.audio.current.play();
    }
  }

  startRecording = () => {
    if (this.props.startRecordingDisabled) {
      return;
    }

    const {streamManager} = this.props;
    if (!streamManager) {
      return console.error('You should pass stream manager to recorder before start recording');
    }

    const mediaRecorder = this.createMediaRecorder(streamManager.stream.getMediaStream());
    if (!mediaRecorder || mediaRecorder.state !== 'inactive') {
      return console.error('Could not start recording for media recorder');
    }

    const fileName = generateRandomString(15);
    if (this.socket) {
      this.socket.emit('recording:start', fileName);
    }

    mediaRecorder.start(5000);
    this.setState({
      recording: true,
      fileName: fileName,
      mediaRecorder: mediaRecorder,
    });
  }

  stopRecording = () => {
    this.setState({
      recording: false,
      stoppingRecording: true,
    });

    const mediaRecorder = this.state.mediaRecorder;
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.stop();
    }
  }

  onRecordingStart = () => {
    const now = new Date();
    this.setState({recordingStartedAt: now.getTime()});

    if (this.props.onRecordingStart) {
      this.props.onRecordingStart(now);
    }
  }

  onRecordingData = (e) => {
    this.recordingChunks.push(e.data);
    if (this.socket) {
      this.socket.emit(
        'recording:data',
        {
          fileName: this.state.fileName,
          chunkNumber: this.recordingChunks.length,
          data: e.data,
        }
      );
    }
  }

  onRecordingStop = async () => {
    if (this.props.onRecordingStop) {
      this.props.onRecordingStop();
    }

    const {recordingStartedAt} = this.state;
    const duration = (recordingStartedAt ? (Date.now() - recordingStartedAt) : 0);
    if (this.socket) {
      this.stoppingTimeout = setTimeout(() => {
        this.onError('Recording could not be saved, please check your internet connection');
      }, RECORDING_STOPPING_TIMEOUT);

      this.socket.emit(
        'recording:stop',
        {
          fileName: this.state.fileName,
          extension: isSafari() ? 'mp4' : 'ogg',
          duration: duration,
          totalChunks: this.recordingChunks.length
        }, this.props.socketRecordingType
      );
    }
  }

  onRecorded = async (result) => {
    clearTimeout(this.stoppingTimeout);
    const mimeType = this.state.mediaRecorder.mimeType;
    let blob = new Blob(this.recordingChunks, {'type': mimeType});
    try {
      blob = await fixWebmDuration(blob, result.duration);
    } catch (e) {}
    result.localUrl = window.URL.createObjectURL(blob);

    if (this.props.onRecorded) {
      this.props.onRecorded(result);
    }
    this.setState({
      stoppingRecording: false,
      recording: false,
    })
  };

  onTokenExpired = () => {
    if (this.stoppingTimeout) {
      clearTimeout(this.stoppingTimeout);
    }
    refreshToken()
      .then(() => {
        if (this.socket) {
          this.socket.disconnect();
          this.initRecordingSocket();
        } else {
          throw new Error('Socket not initialized');
        }
      })
      .then(this.onRecordingStop)
      .catch(e => {
        this.onError(e.message)
      });
  }

  onError = (errorMessage) => {
    console.error(errorMessage);
    this.resetAudio();
    this.setState({
      stoppingRecording: false,
      recording: false,
    });
    if (this.props.onError) {
      this.props.onError(errorMessage);
    }
  }

  render() {
    const {streamManager, className, additionalButtons, recordingUrl, allowedToRecordAnswer, audioText} = this.props;
    const {mediaRecorder, recording, stoppingRecording} = this.state;
    const showText = !recording && !stoppingRecording && !recordingUrl;
    return (
      <div className={'audio-recorder ' + className}>
        <div className={'audio-recorder-container'}>
          {!streamManager ? (
            <div className="audio-not-loaded audio-recorder-overlay">
              <h4>Please allow access to your microphone to continue</h4>
            </div>
          ) : (
            <>
              {showText && (
                <div className='audio-text-overlay audio-recorder-overlay'>
                  <h4>{audioText ? audioText : 'Press start button to begin audio recording'}</h4>
                </div>
              )}
              {!recordingUrl && stoppingRecording && (
                <div className={'audio-recorder-overlay'}>
                  <Loader/>
                </div>
              )}
              {recording && (
                <div className={'audio-recorder-visualizer'}>
                  <AudioVisualizer
                    stream={mediaRecorder.stream}
                  />
                </div>
              )}
              {recordingUrl && (
                <audio
                  id={this.audioElementId}
                  src={recordingUrl}
                  controls={true}
                  controlsList={"nodownload"}
                  ref={this.audio}
                />
              )}
            </>
          )}
        </div>
        <div className={'audio-recorder-controls'}>
          {streamManager ? (
            <RecorderControls
              recording={recording}
              stoppingRecord={stoppingRecording}
              recordingUrl={recordingUrl}
              toggleRecording={this.toggleRecording}
              playRecording={this.playRecording}
              recordAgain={this.resetAudio}
              recordAgainMessage={this.props.messages.recordAgain}
              startRecordingMessage={'Start Audio Recording'}
              allowedToRecord={allowedToRecordAnswer}
              startRecordingDisabled={this.props.startRecordingDisabled}
              {...additionalButtons}
            />
          ) : (additionalButtons.noVideoButtons)
          }
        </div>
      </div>
    )
  }
}

export default AudioRecorder;
