import React, {Component} from 'react';
import ToggleCamera from "../VideoControl/ToggleCamera";
import VideoDeviceSelect from "../VideoControl/VideoDeviceSelect";
import CameraIcon from "../Icons/FlipCameraIos";
import ToggleMicrophone from "../VideoControl/ToggleMicrophone";
import MicrophoneDeviceSelect from "../VideoControl/MicrophoneDeviceSelect";
import MicrophoneIcon from "@material-ui/icons/Mic";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {
  getStreamManagerAudioDeviceId,
  getStreamManagerVideoDeviceId,
} from "../../utils/streamManager";
import {saveAudioDeviceConfig, saveVideoDeviceConfig} from "../../utils/onDemandInterview";
import classNames from 'classnames';

class ChangeDeviceButtons extends Component {
  deviceChangeListening = false;

  static propTypes = {
    streamManager: PropTypes.object,
    visible: PropTypes.bool,
    videoDevices: PropTypes.object,
    audioDevices: PropTypes.object,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    visible: true,
    disabled: false,
  }

  componentDidMount() {
    if (this.props.streamManager) {
      this.listenDeviceChange(this.props.streamManager);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.deviceChangeListening && this.props.streamManager) {
      this.listenDeviceChange(this.props.streamManager);
    }
  }

  componentWillUnmount() {
    if (this.props.streamManager && this.deviceChangeListening) {
      this.finishDeviceChangeListening(this.props.streamManager);
    }
  }

  onStreamPropertyChanged = (event) => {
    const {streamManager} = this.props;
    const changedProperty = event.changedProperty;
    if (!['videoDevice', 'audioDevice'].includes(changedProperty)) {
      return;
    }
    if (event.changedProperty === 'videoDevice') {
      saveVideoDeviceConfig(getStreamManagerVideoDeviceId(streamManager));
    } else if (event.changedProperty === 'audioDevice') {
      saveAudioDeviceConfig(getStreamManagerAudioDeviceId(streamManager))
    }
  }

  listenDeviceChange = (streamManager) => {
    if (!this.deviceChangeListening) {
      this.deviceChangeListening = true;
      streamManager.on('streamPropertyChanged', this.onStreamPropertyChanged);
    }
  }

  finishDeviceChangeListening = (streamManager) => {
    if (streamManager) {
      streamManager.off('streamPropertyChanged', this.onStreamPropertyChanged);
    }
  }

  renderDeviceButtons = () => {
    const {screenSize, streamManager, disabled} = this.props;
    const smallScreen = !screenSize.md;

    if (!streamManager) {
      return null;
    }

    let buttons = [];
    if (this.showVideoDevicesButton()) {
      buttons.push(smallScreen ? (
        <ToggleCamera
          key={'video-device-toggle'}
          streamManager={streamManager}
          className={'change-camera-button'}
          devices={this.props.videoDevices}
          disabled={disabled}
        />
      ) : (
        <VideoDeviceSelect
          key={'video-device-select'}
          streamManager={streamManager}
          buttonClass={'change-camera-button'}
          buttonText={null}
          icon={<CameraIcon />}
          disabled={disabled}
        />));
    }

    if (this.showAudioDevicesButton()) {
      buttons.push(smallScreen ? (
        <ToggleMicrophone
          key={'audio-device-toggle'}
          streamManager={streamManager}
          className={'change-microphone-button'}
          devices={this.props.audioDevices}
          disabled={disabled}
        />
      ) : (
        <MicrophoneDeviceSelect
          key={'audio-device-select'}
          streamManager={streamManager}
          buttonClass={'change-microphone-button'}
          buttonText={null}
          icon={<MicrophoneIcon />}
          disabled={disabled}
        />
      ));
    }

    return buttons;
  }

  showVideoDevicesButton = () => {
    const {videoDevices} = this.props;
    return videoDevices && Object.keys(videoDevices).length > 1
  }

  showAudioDevicesButton = () => {
    const {audioDevices} = this.props;
    return audioDevices && Object.keys(audioDevices).length > 1
  }

  render() {
    const {visible} = this.props;
    return (
      <div className={classNames(
        'change-device-buttons-container', {
          'change-device-buttons-container-hidden': !visible,
          'change-device-buttons-container-with-video': this.showVideoDevicesButton(),
          'change-device-buttons-container-with-audio': this.showAudioDevicesButton(),
        }
      )}>
        {this.renderDeviceButtons()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  screenSize: state.screen.size,
});

ChangeDeviceButtons = connect(
  mapStateToProps
)(ChangeDeviceButtons);

export default ChangeDeviceButtons;
