//Material UI breakpoints
const breakPoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920
};

function checkBreakpoints() {
  const result = {};
  for (const key in breakPoints) {
    result[key] = window.matchMedia(`(min-width:${breakPoints[key]}px)`).matches;
  }

  return result;
}

export {
  checkBreakpoints,
}
