import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {renderLinks} from "../../utils/entityList";
import DateTimeWithTimezone from "../DateTime/DateTimeWithTimezone";
import {downloadFileFromApi} from "../../utils/dataAccess";
import {getLiveInterviewCalendarUrl, getLiveInterviewUrlByRoomCode} from "../../utils/urls";
import {CopyToClipboard} from "../CopyToClipboard/CopyToClipboard";
import IconButton from "@material-ui/core/IconButton";
import CopyContent from "../Icons/CopyContent";
import ExpandableJob from "../Job/ExpandableJob";
import Table from "@material-ui/core/Table";
import React from "react";
import PropTypes from "prop-types";

export default class OverviewTable extends React.Component {
  static propTypes = {
    liveInterview: PropTypes.object.isRequired,
  }

  render = () => {
    const { liveInterview } = this.props;

    const liveInterviewUrl = !liveInterview.completedAt ? getLiveInterviewUrlByRoomCode(liveInterview['roomCode']) : '';

    return (
      <Table className="table table-overview">
        <TableBody>
          <TableRow>
            <TableCell scope="row">Title:</TableCell>
            <TableCell>{liveInterview['title']}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell scope="row">Company:</TableCell>
            <TableCell>{renderLinks('./../companies', liveInterview['job']['company'], (company) => company['name'])}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Creator:</TableCell>
            <TableCell>{liveInterview['companyUser']['firstName']} {liveInterview['companyUser']['lastName']}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell scope="row">Scheduled Start Time:</TableCell>
            <TableCell>
              <DateTimeWithTimezone time={liveInterview.scheduledStartTime} timezone={liveInterview.timezone} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell scope="row">Calendar:</TableCell>
            <TableCell>
              <a href={'#'} onClick={() => downloadFileFromApi(getLiveInterviewCalendarUrl(liveInterview['id']))} >
                Download ICS file
              </a>
            </TableCell>
          </TableRow>
          {liveInterviewUrl && (
            <TableRow>
              <TableCell>
                Join Link:
              </TableCell>
              <TableCell>
                <a href={liveInterviewUrl} target="_blank" style={{'overflow': 'hidden', 'textOverflow': 'ellipsis'}}>
                  {liveInterviewUrl}
                </a>
                <CopyToClipboard text={liveInterviewUrl}>
                  <IconButton style={{'height': '1em'}}>
                    <CopyContent style={{'height': '0.6em'}}>
                      Copy To Clipboard
                    </CopyContent>
                  </IconButton>
                </CopyToClipboard>
              </TableCell>
            </TableRow>
          )}
          {liveInterview.startedAt && (
            <TableRow>
              <TableCell scope="row">Started At</TableCell>
              <TableCell>
                <DateTimeWithTimezone time={liveInterview.startedAt} timezone={liveInterview.timezone} />
              </TableCell>
            </TableRow>)
          }
          {liveInterview.completedAt && (
            <TableRow>
              <TableCell scope="row">Completed At</TableCell>
              <TableCell>
                <DateTimeWithTimezone time={liveInterview.completedAt} timezone={liveInterview.timezone} />
              </TableCell>
            </TableRow>
          )}
          {liveInterview.companyUserInvitations && liveInterview.companyUserInvitations.length > 0 && (
            <TableRow>
              <TableCell scope="row">Invited Users</TableCell>
              <TableCell>
                {liveInterview.companyUserInvitations.map((invitation, index) => {
                  const companyUser = invitation.companyUser;
                  return (
                    <span key={index}>
                      {companyUser.firstName} {companyUser.lastName}
                      {index !== (liveInterview.companyUserInvitations.length - 1) && (', ')}
                    </span>
                  );
                })}
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell scope="row">Job:</TableCell>
            <TableCell>
              <ExpandableJob job={liveInterview['job']}/>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}
