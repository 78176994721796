import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { retrieve, reset } from '../../actions/InvitationInterviewStatus/show';
import { del } from '../../actions/InvitationInterviewStatus/delete';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import IsGranted from "../IsGranted";
import FlashMessages from "../../views/Components/FlashMessages";
import {IRItoID} from "../../utils/dataAccess";

class InvitationInterviewStatusShow extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    deleteError: PropTypes.string,
    deleteLoading: PropTypes.bool.isRequired,
    deleted: PropTypes.object,
    del: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  del = () => {
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.del(this.props.retrieved);
  };

  render() {
    if (this.props.deleted)
      this.props.history.push({
        pathname: `..`,
        state: {successMessage: 'Invitation Interview Status successfully deleted.'}
      });

    const item = this.props.retrieved;

    return (
      <div>
        <h1>Show On Demand Interview Status &quot;{item && IRItoID(item['@id'])}&quot;</h1>
        <FlashMessages location={this.props.location} />

        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}
        {this.props.deleteError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.deleteError}
          </div>
        )}

        <Link to=".." className="btn btn-primary float-left mr-3">
          Back to list
        </Link>
        {item && (
          <IsGranted action="edit" object={item}>
          <Link to={`../edit/${encodeURIComponent(item['@id'])}`}>
            <button className="btn btn-warning float-left mr-3">Edit</button>
          </Link>
          </IsGranted>
        )}
        {item && (
          <IsGranted action="delete" object={item}>
            <button onClick={this.del} className="btn btn-danger float-left mr-3">
              Delete
            </button>
          </IsGranted>
        )}

        {item && (
          <Table className="table table-responsive table-striped table-hover">
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell scope="row">Displayed Interview Status</TableCell>
                <TableCell>{item['displayedInterviewStatus']}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">Displayed Interview Progressbar Status</TableCell>
                <TableCell>{item['displayedInterviewProgressbarStatus']}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">Job Status</TableCell>
                <TableCell>{item['jobStatus']}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">Request Status</TableCell>
                <TableCell>{item['requestStatus']}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">Invitation Status</TableCell>
                <TableCell>{item['invitationStatus']}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">Invitation Expired</TableCell>
                <TableCell>{item['invitationExpired'] ? 'Yes' : 'No'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">Invitation Archived</TableCell>
                <TableCell>{item['invitationArchived'] ? 'Yes' : 'No'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">Interview Started</TableCell>
                <TableCell>{item['interviewStarted'] ? 'Yes' : 'No'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">Interview Completed</TableCell>
                <TableCell>{item['interviewCompleted'] ? 'Yes' : 'No'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}

      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.invitationInterviewStatus.show.retrieved,
  error: state.invitationInterviewStatus.show.error,
  loading: state.invitationInterviewStatus.show.loading,
  eventSource: state.invitationInterviewStatus.show.eventSource,
  deleteError: state.invitationInterviewStatus.del.error,
  deleteLoading: state.invitationInterviewStatus.del.loading,
  deleted: state.invitationInterviewStatus.del.deleted
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  del: item => dispatch(del(item)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitationInterviewStatusShow);
