import React, {Component} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {SortableTableCells} from "../../utils/filterForm";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {pagination, renderLinks} from "../../utils/entityList";
import IsGranted from "../IsGranted";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {CopyToClipboard} from "../CopyToClipboard/CopyToClipboard";
import IconButton from "@material-ui/core/IconButton";
import CopyContent from "../Icons/CopyContent";
import {getLiveInterviewUrlByRoomCode} from "../../utils/urls";
import Rating from 'material-ui-rating'
import TableFooter from "@material-ui/core/TableFooter";
import {isGranted} from "../../utils/permissions";
import DateTimeUserTimeZone from "../DateTime/DateTimeUserTimezone";

export default class LiveInterviewListTable extends Component {
    static propTypes = {
        retrieved: PropTypes.object,
        type: PropTypes.string.isRequired,
        sort: PropTypes.func.isRequired,
        sortData: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.isScheduled = props.type === 'scheduled';
    }

    render() {
        let liveInterviewUrl;
        return (<><div style={{'overflowX': 'auto'}}><Table className="table table-responsive table-striped table-hover mb-0">
            <TableHead>
                <TableRow>
                    <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                        'id': 'ID',
                        'title': 'Title',
                        'job.title': 'Job',
                        'companyUser.firstName': 'User',
                    }} />
                    <TableCell>Candidate</TableCell>
                    {this.isScheduled ? (
                        <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                            'roomCode': 'Join Link',
                            'scheduledStartTime': 'Scheduled Date and Time',
                        }} />
                    ): (
                        <>
                            <TableCell>Recording Duration</TableCell>
                            <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                                'totalAverageRating': 'Total Average Rating',
                                'startedAt': 'Start Date And Time',
                                'completedAt': 'Completion Date and Time',
                            }} />
                        </>
                    )}
                    <TableCell className="align-middle" colSpan={1}/>
                </TableRow>
            </TableHead>
            <TableBody>
                {this.props.retrieved &&
                this.props.retrieved['hydra:member'].map(item => {
                    if (this.isScheduled) {
                        liveInterviewUrl = getLiveInterviewUrlByRoomCode(item['roomCode']);
                    }
                    return (
                        <TableRow key={item['@id']}>
                            {this.isScheduled ? (
                                <>
                                    <TableCell className="align-middle" scope="row">
                                        {renderLinks('live-interview-schedules', item, (liveInterview) => liveInterview['id'], true)}
                                    </TableCell>
                                    <TableCell className="align-middle">
                                        {renderLinks('live-interview-schedules', item, (liveInterview) => liveInterview['title'], true)}
                                    </TableCell>
                                </>
                            ) : (
                                <>
                                    <TableCell className="align-middle" scope="row">
                                        {renderLinks('live-interviews', item, (liveInterview) => liveInterview['id'], true)}
                                    </TableCell>
                                    <TableCell className="align-middle">
                                        {renderLinks('live-interviews', item, (liveInterview) => liveInterview['title'], true)}
                                    </TableCell>
                                </>
                            )}
                            <TableCell className="align-middle">{renderLinks('jobs', item['job'], (job) => job['title'], true)}</TableCell>
                            <TableCell className="align-middle">
                                {renderLinks('users', item['companyUser'], (companyUser) => companyUser.firstName + ' ' + companyUser.lastName, true)}
                            </TableCell>
                            <TableCell className="align-middle">
                                {item['candidateInvitations'] && item['candidateInvitations'].length > 0 ? (
                                    item['candidateInvitations'].map((invitation, index) => {
                                        let name = `${invitation['fullName']}`;
                                        if (invitation.companyCandidate && isGranted('show', invitation.companyCandidate)) {
                                            name = <Link to={`./../candidates/show/${encodeURIComponent(invitation.companyCandidate['@id'])}`}>{name}</Link>
                                        }

                                        return (
                                            <span key={index}>
                                                {name}
                                                {index < item['candidateInvitations'].length-1 && ', '}
                                            </span>
                                        )
                                    })
                                ) : '-'}
                            </TableCell>
                            {this.isScheduled ? (
                                <>
                                    <TableCell className="align-middle">
                                        <a href={liveInterviewUrl} target="_blank">{liveInterviewUrl}</a>
                                        <CopyToClipboard text={liveInterviewUrl}>
                                            <IconButton style={{'height': '1em'}}>
                                                <CopyContent style={{'height': '0.6em'}}>
                                                    Copy To Clipboard
                                                </CopyContent>
                                            </IconButton>
                                        </CopyToClipboard>
                                    </TableCell>
                                    <TableCell className="align-middle">
                                        <DateTimeUserTimeZone time={item.scheduledStartTime} />
                                    </TableCell>
                                </>
                            ) : (
                                <>
                                    <TableCell className="align-middle">{item.totalDuration}</TableCell>
                                    <TableCell className="reviews-list-rating-container">
                                        {item['totalAverageRating'] && (
                                          <div>
                                              <Rating
                                                value={item['totalAverageRating']}
                                                max={5}
                                                className="total-interview-rating"
                                                readOnly={true}
                                              />
                                              <span className="total-interview-rating-info"> - {item['totalAverageRating']}</span>
                                          </div>
                                        )}
                                    </TableCell>
                                    <TableCell className="align-middle">
                                        <DateTimeUserTimeZone time={item.startedAt} />
                                    </TableCell>
                                    <TableCell className="align-middle">
                                        <DateTimeUserTimeZone time={item.completedAt} />
                                    </TableCell>
                                </>
                            )}
                            <TableCell className="align-middle">
                                <IsGranted action='show' object={item}>
                                    <Link to={`show/${encodeURIComponent(item['@id'])}`}>
                                        <span className="fa fa-eye" aria-hidden="true"/>
                                        <span className="sr-only">Show</span>
                                    </Link>
                                </IsGranted>
                            </TableCell>
                            <TableCell className="align-middle">
                                <IsGranted action='edit' object={item}>
                                    <Link to={`edit/${encodeURIComponent(item['@id'])}`}>
                                        <span className="fa fa-pencil" aria-hidden="true"/>
                                        <span className="sr-only">Edit</span>
                                    </Link>
                                </IsGranted>
                            </TableCell>
                        </TableRow>
                    )
                }
                )}
            </TableBody>
        </Table></div>
        <Table className="table table-responsive no-border">
          <TableFooter>
            <TableRow>
              {pagination(this.props.retrieved, this.props.page)}
            </TableRow>
          </TableFooter>
        </Table></>);
    }
}
