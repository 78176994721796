import React, {Component} from "react";
import moment from "moment-timezone";
import Moment from 'react-moment';
import './MessageHistory.scss'

export default class MessageTime extends Component {

    render() {
        const { ...params } = this.props;

        return (
            <>
                {params.time ? (
                    <span className='message-time'>
                        {moment.tz(params.time, moment.tz.guess()).format(Moment.timeOnlyFormat)}
                    </span>
                ) : (
                    <div className="chat-date">
                        {moment.tz(params.date, moment.tz.guess()).format('dddd D MMMM YYYY')}
                    </div>
                )}
            </>
        );
    }
}
