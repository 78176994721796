import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import Place from "@material-ui/icons/Place";
import LockOutlined from "@material-ui/icons/LockOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import InputAdornment from "@material-ui/core/InputAdornment";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Smartphone from "@material-ui/icons/Smartphone";
import Button from "components/CustomButtons/Button.jsx";
import {renderTextField} from "utils/form";
import FormControl from "@material-ui/core/FormControl";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import {renderFromHelper} from "../../utils/form";
import {connect} from "react-redux";
import RegistrationSiteSettings from "../Candidate/RegistrationSiteSettings";

const style = theme => ({
    formControl: {
        paddingTop: "27px",
    },
    termsFormHelp: {
        marginTop: "-25px",
    },
    center: {
        textAlign: "center",
    },
    checkboxFormHelp: {
        marginTop: "-25px",
    },
});

class RegistrationForm extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            termsAccepted: false,
        };
    }

    handleTermsAccepted = (termsAccepted) => {
        this.setState({
            'termsAccepted': termsAccepted,
        });
    };

    renderAcceptTermsCheckbox = ({props, input, meta: {touched, error}}) => {
        const { classes } = this.props;
        return (
        <div className="form-group">
            <FormControlLabel
                classes={{
                    label: classes.checkboxLabel
                }}
                control={
                    <Checkbox
                        checked={!!input.value}
                        onChange={ (event, value) => {
                            this.handleTermsAccepted(value);
                            return input.onChange(event, value);
                        }}
                    />
                }
                label={
                    <span>
                        I agree to the{" "}
                        <a target="_blank" href={this.props.site && this.props.site.termsOfServicesUrl ? this.props.site.termsOfServicesUrl : '#'}>terms and conditions</a>.
                    </span>
                }
            />
            <div className={classes.termsFormHelp}>
            {renderFromHelper(touched, error)}
            </div>
        </div>
        )
    };

    render() {
        const { classes, formControlProps } = this.props;
        let formControlClasses;
        if (formControlProps !== undefined) {
            formControlClasses = classNames(
                formControlProps.className,
                classes.formControl
            );
        } else {
            formControlClasses = classes.formControl;
        }

        return (
            <form name='registration' onSubmit={this.props.handleSubmit}>
                <FormControl fullWidth={true} {...formControlProps} className={formControlClasses}>
                    <Field
                        component={renderTextField}
                        name="companyName"
                        type="text"
                        placeholder="Company Name"
                        required={true}
                        label={false}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <Place className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
                <FormControl fullWidth={true} {...formControlProps} className={formControlClasses}>
                    <Field
                        component={renderTextField}
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        required={true}
                        label={false}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <Face className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
                <FormControl fullWidth={true} {...formControlProps} className={formControlClasses}>
                    <Field
                        component={renderTextField}
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                        required={true}
                        label={false}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <Face className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
                <FormControl fullWidth={true} {...formControlProps} className={formControlClasses}>
                    <Field
                        component={renderTextField}
                        name="email"
                        type="email"
                        placeholder="Email"
                        required={true}
                        label={false}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <Email className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
                <FormControl fullWidth={true} {...formControlProps} className={formControlClasses}>
                    <Field
                        component={renderTextField}
                        name="phoneNumber"
                        type="text"
                        placeholder="Phone Number"
                        required={true}
                        label={false}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <Smartphone className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
                <FormControl fullWidth={true} {...formControlProps} className={formControlClasses}>
                    <Field
                        component={renderTextField}
                        name="password"
                        type="password"
                        placeholder="Password"
                        required={true}
                        label={false}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <LockOutlined className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
                <FormControl fullWidth={true} {...formControlProps} className={formControlClasses}>
                    <Field
                        component={renderTextField}
                        name="repeatPassword"
                        type="password"
                        placeholder="Repeat Password"
                        required={true}
                        label={false}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <LockOutlined className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
                <FormControl fullWidth={true}>
                    <RegistrationSiteSettings classes={this.props.classes} termsAccepted={this.handleTermsAccepted} />
                </FormControl>
                <div className={classes.center}>
                    <Button type="submit" round color="primary" disabled={!this.state.termsAccepted}>
                        Get started
                    </Button>
                </div>
            </form>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.companyName) {
        errors.companyName = 'Please enter a company name';
    }
    if (!values.firstName) {
        errors.firstName = 'Please enter a first name';
    }
    if (!values.lastName) {
        errors.lastName = 'Please enter a last name';
    }
    if (!values.email) {
        errors.email = 'Please enter an email';
    }
    if (!values.phoneNumber) {
        errors.phoneNumber = 'Please enter a phone number';
    }
    if (!values.password) {
        errors.password = 'Please enter a password';
    }
    if (!values.repeatPassword) {
        errors.repeatPassword = 'Please repeat password';
    } else if (values.password !== values.repeatPassword) {
        errors.repeatPassword = 'Password and repeat password should be the same';
    }
    if (!values.termsAccepted) {
        errors.termsAccepted = 'Please accept terms';
    }

    return errors;
};

RegistrationForm = withStyles(style)(RegistrationForm);

RegistrationForm = reduxForm({
    form: 'registration',
    validate
})(RegistrationForm);

const mapStateToProps = (state) => ({
    site: state.siteDetails.siteDetails.retrieved,
});

export default connect(
    mapStateToProps)
(RegistrationForm);
