import {
  fetchCurrentSiteDetailsFromApi,
  getCurrentSiteDetailsFromStorage,
  storeCurrentSiteDetails
} from "./currentSiteDetails";
import {updateSiteDetails} from "../actions/SiteDetails/loadSiteDetails";

let siteFetchingFlag = false;

export function applySiteDetailsVersionListener(axios, store) {
  axios.interceptors.response.use((response) => {
    checkSiteDetailsVersion(response, store);
    return response;
  }, (error) => {
    checkSiteDetailsVersion(error.response, store);
    return Promise.reject(error);
  });
}

function checkSiteDetailsVersion(response, store) {
  const actualVersion = response.headers['site-details-version']
  const siteDetails = getCurrentSiteDetailsFromStorage();
  if (actualVersion && siteDetails && parseInt(actualVersion) !== parseInt(siteDetails['site-details-version']) && !siteFetchingFlag) {
    siteFetchingFlag = true;
    store.dispatch(siteDetailsSilentLoading(true));
    fetchCurrentSiteDetailsFromApi().then(siteDetails => {
      storeCurrentSiteDetails(siteDetails);
      store.dispatch(updateSiteDetails(siteDetails));
    }).catch(error => {})
      .finally(() => {
        store.dispatch(siteDetailsSilentLoading(false));
        siteFetchingFlag = false;
      })
  }
}

function siteDetailsSilentLoading(loading) {
  return {type: 'SITE_DETAILS_SILENT_LOADING', loading: loading};
}
