import React from "react";

export function Tabs({planSelectUrl, step = 1, isUpgrade = true}) {
  return (
    <div className={"partner-subscription-tabs"}>
      <a href={planSelectUrl}>{isUpgrade ? 'UPGRADE PLAN' : 'PURCHASE PLAN'}</a>
      <i className={"fa fa-chevron-right" + (step > 1 ? ' active' : '')} aria-hidden="true" />
      <span className={(step > 1 ? ' active' : '')}>PAYMENT METHOD</span>
      <i className={"fa fa-chevron-right" + (step === 3 ? ' active' : '')} aria-hidden="true" />
      <span className={(step === 3 ? ' active' : '')}>DONE</span>
    </div>
  );
}

