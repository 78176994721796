import React, {Component} from "react";
import {Field} from "redux-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import {renderFromHelper} from "../../utils/form";
import {connect} from "react-redux";
import {currentSiteSettings} from "../../actions/SiteSettings/currentSiteSettings";
import {TYPE_URL} from "../SiteSettings/Form";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";


class RegistrationSiteSettings extends Component {
    static propTypes = {
        loading: PropTypes.bool,
        termsAccepted: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            termsOfServiceAccepted: false,
            privacyPolicyAccepted: false,
            termsOfServiceDialogOpen: false,
            privacyPolicyDialogOpen: false,
        };
    }

    componentDidMount() {
        this.props.currentSiteSettings();
    }

    handleTermsOfServiceAccepted = (accepted) => {
        this.setState({
            termsOfServiceAccepted: accepted,
        });

        if (typeof this.props.termsAccepted === 'function') {
            this.props.termsAccepted(this.state.privacyPolicyAccepted && accepted);
        }
    };

    handlePrivacyPolicyAccepted = (accepted) => {
        this.setState({
            privacyPolicyAccepted: accepted,
        });

        if (typeof this.props.termsAccepted === 'function') {
            this.props.termsAccepted(this.state.termsOfServiceAccepted && accepted);
        }
    }

    toggleTermsOfServiceModal = () => {
        this.setState({
            termsOfServiceDialogOpen: !this.state.termsOfServiceDialogOpen,
        });
    }

    togglePrivacyPolicyModal = () => {
        this.setState({
            privacyPolicyDialogOpen: !this.state.privacyPolicyDialogOpen,
        });
    }

    renderCheckbox = ({label, input, handleChange, meta: {touched, error}}) => {
        const { classes} = this.props;
        return (
        <div className={classes.formGroup !== undefined ? classes.formGroup : "form-group"}>
            <FormControlLabel
                classes={{
                    label: classes.checkboxLabel
                }}
                control={
                    <Checkbox
                        checked={!!input.value}
                        onChange={ (event, value) => {
                            if (handleChange) {
                                handleChange(value);
                            }
                            return input.onChange(event, value);
                        }}
                    />
                }
                label={label}
            />
            <div className={classes.checkboxFormHelp}>
            {renderFromHelper(touched, error)}
            </div>
        </div>
        )
    };

    render() {
        const siteSettings = this.props.currentSiteSettingsRetrieved;

        return (
            <>
                {this.props.loading && (
                    <div className={"alert alert-info"}>Loading...</div>
                )}

                {siteSettings && (
                    <>
                        <Field
                            component={this.renderCheckbox}
                            name="termsOfServiceAccepted"
                            handleChange={this.handleTermsOfServiceAccepted}
                            label={
                                <span>I have read and agree to the {siteSettings.termsOfServiceType === TYPE_URL ? (
                                    <a target="_blank" href={siteSettings.termsOfServiceUrl}>Terms of Service</a>
                                ) : (
                                    <a href={""} onClick={(e) => {
                                        e.preventDefault();
                                        this.toggleTermsOfServiceModal();
                                    }}>Terms of Service</a>
                                )}.
                                </span>
                            }
                        />
                        <Dialog
                            onClose={this.toggleTermsOfServiceModal}
                            open={this.state.termsOfServiceDialogOpen}
                            fullWidth={true}
                            maxWidth="md"
                        >
                            <DialogTitle className={"registration-site-settings-title"}>
                                Terms of Service <i className={'fa fa-close'} onClick={this.toggleTermsOfServiceModal} />
                            </DialogTitle>
                            <DialogContent>
                                <div className={"ql-editor"} dangerouslySetInnerHTML={{__html: siteSettings.termsOfServiceContent}} />
                            </DialogContent>
                        </Dialog>

                        <Field
                            component={this.renderCheckbox}
                            name="privacyPolicyAccepted"
                            handleChange={this.handlePrivacyPolicyAccepted}
                            label={
                                <span>I have read and agree to the {siteSettings.privacyPolicyType === TYPE_URL ? (
                                    <a target="_blank" href={siteSettings.privacyPolicyUrl}>Privacy Policy</a>
                                ) : (
                                    <a href={""} onClick={(e) => {
                                        e.preventDefault();
                                        this.togglePrivacyPolicyModal();
                                    }}>Privacy Policy</a>
                                )}.
                                </span>
                            }
                        />
                        <Dialog
                            onClose={this.togglePrivacyPolicyModal}
                            open={this.state.privacyPolicyDialogOpen}
                            fullWidth={true}
                            maxWidth="md"
                        >
                            <DialogTitle className={"registration-site-settings-title"}>
                                Privacy Policy <i className={'fa fa-close'} onClick={this.togglePrivacyPolicyModal} />
                            </DialogTitle>
                            <DialogContent>
                                <div className={"ql-editor"} dangerouslySetInnerHTML={{__html: siteSettings.privacyPolicyContent}} />
                            </DialogContent>
                        </Dialog>
                    </>
                )}
            </>
        );
    }
}




const mapStateToProps = (state) => ({
    currentSiteSettingsRetrieved: state.siteSettings.currentSiteSettings.retrieved,
    loading: state.siteSettings.currentSiteSettings.loading,
});

const mapDispatchToProps = (dispatch) => ({
    currentSiteSettings: () => dispatch(currentSiteSettings()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps)
(RegistrationSiteSettings);
