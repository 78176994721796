export const LIMITATION_JOBS = 'jobs';
export const LIMITATION_USERS = 'users';
export const LIMITATION_STORAGE = 'storage';

export const PREVIEW_PERIOD_MONTHLY = 'monthly';
export const PREVIEW_PERIOD_ANNUAL = 'annual';

export const planLimitationLabels = {};
planLimitationLabels[LIMITATION_USERS]   = {label: 'Users', placeholder: 'Number of Users'};
planLimitationLabels[LIMITATION_JOBS]    = {label: 'Job Postings', placeholder: 'Number of Job Postings'};
planLimitationLabels[LIMITATION_STORAGE] = {label: 'Video Storage', placeholder: 'GB of Video Storage'};

export function formatPlanPrice(price) {
  if (!price) {
    return '$0.00';
  }

  let formattedPrice =  '$' +  parseFloat(price).toLocaleString();

  let cents = formattedPrice.toString().indexOf('.') > -1 ? formattedPrice.substr(formattedPrice.toString().indexOf('.') + 1) : '';
  if (cents.length === 1 && cents !== '0') {
    formattedPrice += '0';
  }

  return formattedPrice;
}

export function formatPrice(price) {
  price = parseFloat(price).toFixed(2);
  price = parseFloat(price).toLocaleString();
  const cents = price.toString().indexOf('.') > -1 ? price.substr(price.toString().indexOf('.') + 1) : '';

  if (cents.length === 0) {
    price += '.00';
  } else if (cents.length === 1) {
    price += '0';
  }

  if (price.charAt(0) === '-') {
    return '-$ ' + price.substring(1);
  }

  return '$ ' + price;
}
