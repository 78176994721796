import { SubmissionError } from 'redux-form';
import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'SITE_SETTINGS_CREATE_ERROR', error };
}

export function loading(loading) {
  return { type: 'SITE_SETTINGS_CREATE_LOADING', loading };
}

export function success(created) {
  return { type: 'SITE_SETTINGS_CREATE_SUCCESS', created };
}

export function create(item, values) {
  return dispatch => {
    dispatch(loading(true));

    return fetch('site_settings', { method: 'POST', data: values })
      .then(response => {
        dispatch(loading(false));
        dispatch(success(response.data));
      })
      .catch(e => {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw e;
        }

        dispatch(error(e.message));
      });
  };
}
