import {combineReducers} from "redux";

function messages(state = [], action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_CHAT_ADD_MESSAGE':
      return [...state, action.message];
    case 'LIVEINTERVIEW_PAGE_CLEAR_CHAT_MESSAGES':
    case 'LIVEINTERVIEW_PAGE_RESET':
      return [];
    default:
      return state;
  }
}

export default combineReducers({ messages });
