import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {retrieve, reset} from '../../actions/LiveInterview/show';
import {getLiveInterviewUrlByRoomCode} from "../../utils/urls";
import CompletedVideoChatPreview from "../LiveInterviewPage/CompletedVideoChatPreview";
import OverviewTable from "../CandidateLiveInterview/OverviewTable";

class CandidateLiveInterviewShow extends Component {
  state = {
    openCommentsModal: false,
  };

  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const item = this.props.retrieved;

    return (
      <div className="live-interview-preview-page">
        <h1>Show &quot;{item && item['title']}&quot;</h1>

        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}

        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
            {this.props.error}
          </div>
        )}

        <div className="grid-buttons">
          <Link to=".." className="btn btn-primary">
            Back to list
          </Link>
        </div>

        {item && (
          <>
            {item.completedAt && (
              <div className="block-rounded block-live-interview-recording">
                <h3>Live Interview Recording</h3>
                <CompletedVideoChatPreview liveInterview={item} />
              </div>
            )}

            <div className="block-rounded">
              <h3>Overview</h3>
              <OverviewTable liveInterview={item} />
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.liveinterview.show.retrieved,
  error: state.liveinterview.show.error,
  loading: state.liveinterview.show.loading,
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateLiveInterviewShow);
