import React, {Component} from "react";
import {reset, retrieve, update} from "../../actions/UserProfile/update";
import {connect} from "react-redux";
import Form from './Form';
import {getSwitchedUsersHistory, saveAuthData, updateCurrentSwitchUserInHistory} from "../../utils/auth";
import FlashMessages from "../../views/Components/FlashMessages";

class UserProfileUpdate extends Component {
    componentDidMount() {
        this.props.retrieve();
    }

    componentWillUnmount() {
        this.props.reset();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.auth && !prevProps.auth) {
            saveAuthData(this.props.auth);
        }
    }

    update = (values) => {
      return this.props.update(values, () => {
        updateCurrentSwitchUserInHistory(values);

        const history = getSwitchedUsersHistory();
        if (history && typeof history[0] === 'object') {
          this.props.history.push({
            pathname: `user-profile`,
            state: {successMessage: 'Profile successfully updated.'}
          });
        }
      });
    }

    render() {
        const user = this.props.user;

        return (
            <div>
                <h1>Edit Profile</h1>
                <FlashMessages location={this.props.location} />
                {this.props.updated && (
                  <FlashMessages message={"Profile successfully updated."} type={"success"} duration={1000000} />
                )}
                {this.props.loading && (
                    <div className="alert alert-info" role="status">
                        Loading...
                    </div>
                )}
                {this.props.error && (
                    <div className="alert alert-danger" role="alert">
                        <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
                        {this.props.error}
                    </div>
                )}

                {user && (
                    <Form initialValues={user} onSubmit={this.update}/>
                )}
            </div>
        );
    };
}

const mapStateToProps = state => ({
    loading: state.userProfile.update.loading,
    error: state.userProfile.update.error,
    user: state.userProfile.update.retrieved,
    updated: state.userProfile.update.updated,
    auth: state.userProfile.update.auth,
});

const mapDispatchToProps = dispatch => ({
    retrieve: id => dispatch(retrieve()),
    update: (values, callback) => dispatch(update(values, callback)),
    reset: () => dispatch(reset())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserProfileUpdate);
