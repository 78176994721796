//Import webrtc shim, no further action with this variable is required.
import adapter from "webrtc-adapter";

import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import './../../views/Pages/css/InterviewPage.scss';
import './../../views/Pages/css/LiveInterviewPage.scss';

// core components
import liveInterviewPageStyle from "../../assets/jss/material-dashboard-pro-react/views/liveInterviewPageStyle.jsx";
import {connect} from "react-redux";
import {initSession, reset} from "../../actions/LiveInterviewPage/interview";
import ErrorPage from "../../views/Pages/ErrorPage";
import LiveInterviewHeader from "./LiveInterviewHeader";
import LiveInterviewContent from "./LiveInterviewContent";
import LiveInterviewFooter from "./LiveInterviewFooter";
import {getUserId, isAuthenticated} from "../../utils/auth";
import {removeSessionInstance} from "../../utils/liveInterviewSession";
import login from "../../assets/img/inorc_light_bg.jpg";
import SiteSettingsBox, {showSiteSettingsBox} from "../../views/Pages/SiteSettingsBox";
import storage from "../../utils/storage";
import LogRocketTracker from "../Logging/LogRocketTracker";

class LiveInterviewPage extends React.Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  componentDidMount() {
    window.addEventListener('unload', this.leaveSession);
    this.checkAndInitSession();
  }

  componentWillUnmount() {
    this.leaveSession();
  }

  getTrackData = () => {
    const {liveInterview, invitation, loggedIn, liveInterviewParticipant} = this.props;
    const trackData = {};
    if (liveInterview) {
      trackData['room_code'] = liveInterview.roomCode;
    }

    if (invitation) {
      trackData['invitation_code'] = invitation.invitationCode;
    }

    if (loggedIn) {
      trackData['user_id'] = getUserId();
      trackData['user_email'] = storage.get('originalUserEmail');
      trackData['user_original_id'] = storage.get('originalUserId');
    }

    if (liveInterviewParticipant) {
      trackData['participant_id'] = liveInterviewParticipant.id;
    }

    return trackData;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((
        !prevProps.loggedIn && this.props.loggedIn ||
        !prevProps.liveInterviewParticipant && this.props.liveInterviewParticipant
      ) && !this.props.session) {
      this.checkAndInitSession();
    }
  }

  checkAndInitSession = () => {
    const {initSession, liveInterview, liveInterviewParticipant, session, loggedIn} = this.props;
    if (loggedIn && liveInterviewParticipant && !session) {
      console.log('LiveInterviewPage Init Session');
      initSession(liveInterview, liveInterviewParticipant);
    }
  }

  leaveSession = () => {
    const {liveInterview, session} = this.props;
    if (session) {
      session.endSession();
    }

    this.props.reset();
    if (liveInterview) {
      removeSessionInstance(liveInterview.id);
    }
  };

  renderError = (error) => {
    return (
      <ErrorPage errorTitle={error.title} errorSubTitle={error.subTitle}
                 errorDescription={error.description}/>
    );
  };

  render() {
    const {loading, liveInterview, fullscreenEnabled, testing, joinedToInterview, error} = this.props;

    const pageAttributes = {
      className: classNames('live-interview-page', 'interview-page', {
        'live-interview-page-loading': loading,
        'live-interview-page-error': error,
        'live-interview-page-fullscreen': fullscreenEnabled,
        'live-interview-page-testing': testing,
        'live-interview-page-joined': joinedToInterview,
      }),
      style: {
        backgroundImage: "url(" + login + ")",
        zIndex: 1030,
      }
    };

    if (error && error.title) {
      return (
        <div {...pageAttributes}>
          {this.renderError(error)}
        </div>
      )
    }

    if (loading) {
      return (
        <div {...pageAttributes}>
          <div className="alert alert-info" style={{textAlign: 'center'}} role="status">Loading...</div>
        </div>
      )
    }

    const isLoggedIn = isAuthenticated() || this.props.loggedIn;
    return (
      <div {...pageAttributes}>
        {liveInterview && (
          <>
            <LogRocketTracker uuid={'live-interview-' + liveInterview.id} trackData={this.getTrackData()}/>
            <LiveInterviewHeader />
            <div className={classNames('live-interview-page-content', 'interview-page-content')}>
              {this.props.error && (
                <div className="alert alert-danger">{this.props.error}</div>
              )}
              <LiveInterviewContent />
            </div>
            <LiveInterviewFooter />
            {isLoggedIn && showSiteSettingsBox() && (
              <SiteSettingsBox />
            )}
          </>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  error: state.liveInterviewPage.error,
  loading: state.liveInterviewPage.loading,
  session: state.liveInterviewPage.session,
  liveInterview: state.liveInterviewPage.liveInterview,
  invitation: state.liveInterviewPage.invitation,
  liveInterviewParticipant: state.liveInterviewPage.liveInterviewParticipant,
  loggedIn: state.liveInterviewPage.auth.loggedIn,
  testing: state.liveInterviewPage.testing,
  joinedToInterview: state.liveInterviewPage.joinedToInterview,
  fullscreenEnabled: state.liveInterviewPage.fullscreen,
});

const mapDispatchToProps = dispatch => ({
  reset: () => dispatch(reset()),
  initSession: (liveInterview, userRoles) => dispatch(initSession(liveInterview, userRoles)),
});

LiveInterviewPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveInterviewPage);

export default withStyles(liveInterviewPageStyle)(LiveInterviewPage);
