import React from "react";
import PropTypes from "prop-types";

function TransactionIcon({status}) {
  switch (status) {
    case 'success':
      return (
        <div className={"transaction-icon first-circle success"}>
          <div className={"second-circle success"}>
            <i className={"fa fa-check-circle"} />
          </div>
        </div>
      );
    case 'failed':
    case 'canceled':
      return (
        <div className={"transaction-icon first-circle failed"}>
          <div className={"second-circle failed"}>
            <i className={"fa fa-times"} />
          </div>
        </div>
      );
    case 'pending':
      return (
        <div className={"transaction-icon first-circle pending"}>
          <div className={"second-circle pending"}>
            <i className={"fa fa-question"} />
          </div>
        </div>
      )
  }

  return '';
}

TransactionIcon.propTypes = {
  status: PropTypes.string.isRequired,
};

export default TransactionIcon;
