import React from 'react'
import Button from "components/CustomButtons/Button.jsx";
import CircularProgress from '@material-ui/core/CircularProgress'
import {withStyles} from "@material-ui/core";

const style = (theme) => ({
  wrapper: {
    margin: theme.spacing,
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

const LoadingButton = (props) => {
  const {
    classes,
    children,
    loading,
    loadingText,
    ...rest
  } = props;
  return (
    <div className={classes.wrapper}>
      <Button
        variant="contained"
        disabled={loading}
        {...rest}
      >
        {!loading ? (children) : (loadingText || 'Loading...')}
      </Button>
      {loading && (
        <CircularProgress
          className={classes.buttonProgress}
          size={20}
        />
      )}
    </div>
  )
};

export default withStyles(style)(LoadingButton);

