import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { retrieve, reset } from '../../actions/Company/show';
import { del } from '../../actions/Company/delete';
import Moment from 'react-moment';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {handleSwitchUser} from "../SwitchUser/SwitchUser";
import IsGranted from "../IsGranted";
import withStyles from "@material-ui/core/styles/withStyles";
import {currentUserHasRole} from "../../utils/auth";
import {renderLinks} from "../../utils/entityList";
import FlashMessages from "../../views/Components/FlashMessages";
import PhoneNumber from "../../views/Components/PhoneNumber";

const style = {
  image: {
    display: 'block',
    width: 'auto',
    height: 'auto',
    maxHeight: '500px',
    maxWidth: '100%',
  },
};

class CompanyShow extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    deleteError: PropTypes.string,
    deleteLoading: PropTypes.bool.isRequired,
    deleted: PropTypes.object,
    del: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  del = () => {
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.del(this.props.retrieved);
  };

  render() {
    if (this.props.deleted)
      this.props.history.push({
        pathname: `..`,
        state: {successMessage: 'Company successfully deleted.'}
      });

    const item = this.props.retrieved;
    const {classes} = this.props;

    return (
      <div>
        <h1>Show &quot;{item && item['name']}&quot;</h1>

        <FlashMessages location={this.props.location} />
        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}
        {this.props.deleteError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.deleteError}
          </div>
        )}

        <div className="grid-buttons">
          <Link to=".." className="btn btn-primary">
            Back to list
          </Link>
          {item && (
            <IsGranted action="edit" object={item}>
            <Link to={`../edit/${encodeURIComponent(item['@id'])}`} className="btn btn-warning">Edit</Link>
            </IsGranted>
          )}
          {item && item['companyAdmin'] && (
            <IsGranted action="impersonate" object={item['companyAdmin']}>
            <button className="btn btn-info" onClick={() => handleSwitchUser(item['companyAdmin']['id'])}>Impersonate</button>
            </IsGranted>
          )}
          {item && (
            <IsGranted action="delete" object={item}>
            <button onClick={this.del} className="btn btn-danger">
              Delete
            </button>
            </IsGranted>
          )}
        </div>

        {/* @TODO remove false, when subscription system will be done */false && currentUserHasRole('ROLE_PARTNER_ADMIN') && (
          <div className={"company-subscription-tabs"}>
            <p className={"company-subscription-tabs-details"}>Details</p>
            <Link to={"../subscription/" + this.props.match.params.id}>Subscription</Link>
          </div>
        )}

        {item && (
          <div style={{'overflowX': 'auto'}}>
            <Table className="table table-responsive table-striped table-hover">
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell scope="row">Name</TableCell>
                  <TableCell>{item['name']}</TableCell>
                </TableRow>
                {currentUserHasRole('ROLE_SUPER_ADMIN') && (
                  <TableRow>
                    <TableCell scope="row">Partner</TableCell>
                    <TableCell>{renderLinks('../partners', item['partner'])}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell scope="row">Status</TableCell>
                  <TableCell style={{textTransform: 'capitalize'}}>{item['status']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Address</TableCell>
                  <TableCell>{item['address']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Address 2</TableCell>
                  <TableCell>{item['address2']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">City</TableCell>
                  <TableCell>{item['city']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">State</TableCell>
                  <TableCell>{item['state'] ? item['state']['name'] : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Country</TableCell>
                  <TableCell>{item['country'] ? item['country']['name'] : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">ZIP Code</TableCell>
                  <TableCell>{item['zipCode']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Home Page Slogan</TableCell>
                  <TableCell>{item['whiteLabelHomePageSlogan']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Description</TableCell>
                  <TableCell>{item['description']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Phone Number</TableCell>
                  <TableCell>
                    <PhoneNumber phone={item['phoneNumber']} formattedPhone={item['formattedPhoneNumber']} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Email Address</TableCell>
                  <TableCell>{item['emailAddress']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Web Site</TableCell>
                  <TableCell>{item['webSite']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Logo</TableCell>
                  <TableCell>
                    {item['logo'] && (
                      <img className={classes.image} src={item['logo']['url']}/>
                    )}
                    {!item['logo'] && (
                      '-'
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Allow Candidates to View On Demand Interviews</TableCell>
                  <TableCell>{item['allowCandidateToViewOneWayInterviews'] ? 'Yes' : 'No'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Allow Candidates to View Live Interviews</TableCell>
                  <TableCell>{item['allowCandidateToViewLiveInterviews'] ? 'Yes' : 'No'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Created At</TableCell>
                  <TableCell>{item['createdAt'] && (<Moment>{item['createdAt']}</Moment>)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Updated At</TableCell>
                  <TableCell>{item['updatedAt'] && (<Moment>{item['updatedAt']}</Moment>)}</TableCell>
                </TableRow>
                {item['whiteLabelEnabled'] && (
                  <>
                    <TableRow>
                      <TableCell scope="row">Site Domain</TableCell>
                      <TableCell>
                        {item['whiteLabelDomain'] ? (item['whiteLabelDomain']) : ('-')}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">Site From Email</TableCell>
                      <TableCell>
                        {item['whiteLabelFromEmail'] ? (item['whiteLabelFromEmail']) : ('-')}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">Site Email</TableCell>
                      <TableCell>
                        {item['whiteLabelEmailAddress'] ? (item['whiteLabelEmailAddress']) : ('-')}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">Privacy Email</TableCell>
                      <TableCell>
                        {item['whiteLabelPrivacyEmail'] ? (item['whiteLabelPrivacyEmail']) : ('-')}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">Site Favicon</TableCell>
                      <TableCell>
                        {item['whiteLabelFavicon'] ? (
                          <img className={classes.image} src={item['whiteLabelFavicon']['url']}/>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </div>
        )}

      </div>
    );
  }

  renderLinks = (type, items) => {
    if (Array.isArray(items)) {
      return items.map((item, i) => (
        <div key={i}>{this.renderLinks(type, item)}</div>
      ));
    }

    return (
      <Link to={`../${type}/show/${encodeURIComponent(items)}`}>{items}</Link>
    );
  };
}

CompanyShow = withStyles(style)(CompanyShow);

const mapStateToProps = state => ({
  retrieved: state.company.show.retrieved,
  error: state.company.show.error,
  loading: state.company.show.loading,
  eventSource: state.company.show.eventSource,
  deleteError: state.company.del.error,
  deleteLoading: state.company.del.loading,
  deleted: state.company.del.deleted
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  del: item => dispatch(del(item)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyShow);
