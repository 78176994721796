import React from "react";
import ChangePasswordForm from "components/User/ChangePasswordForm.jsx";
import {changePassword} from "../../actions/User/changePassword";
import {connect} from "react-redux";
import FlashMessages from "../../views/Components/FlashMessages";

class UserChangePassword extends React.Component {
    render() {
        return (
            <div>
                <h1>Change Password</h1>

                {this.props.changed && (
                    <FlashMessages message={"Password successfully changed"} type={"success"} duration={10000} />
                )}
                {this.props.loading && (
                    <div className="alert alert-info" role="status">
                        Loading...
                    </div>
                )}
                {this.props.error && (
                    <div className="alert alert-danger" role="alert">
                        <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
                        {this.props.error}
                    </div>
                )}
                <ChangePasswordForm onSubmit={this.props.changePassword}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { changed, error, loading } = state.user.changePassword;
    return { changed, error, loading };
};

const mapDispatchToProps = dispatch => ({
    changePassword: values => dispatch(changePassword(values))
});

export default UserChangePassword = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserChangePassword);
