import React, {Component} from "react";

export default class UserTyping extends Component {

    render() {
        return (
            <div className="dots-wrapper">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
            </div>
        );
    }
}
