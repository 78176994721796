import React from "react";
import Dialog from "../../views/Components/Dialog/Dialog";

class DeclineInvitationDialog extends React.Component  {
  render() {
    const {open, handleClose, declineInvitation} = this.props;
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        className={'interview-page-decline-invitation-dialog'}
        title={'Declining Invitation'}
        confirmButtonText={'Yes'}
        onConfirm={declineInvitation}
        closeButtonText={'No'}
      >
        Are you sure you want to decline this interview? You will not be able to modify your response.
      </Dialog>
    )
  }
}

export default DeclineInvitationDialog;
