import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {retrieve, reset} from '../../actions/CandidateOneWayInterview/show';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Moment from 'react-moment';
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import KnockoutQuestionAnswerView from "../KnockoutQuestion/KnockoutQuestionAnswerView";
import VideoQuestionAnswerView from "../VideoQuestionPlayer/VideoQuestionAnswerView";
import GridContainer from "../Grid/GridContainer";
import {renderLinks} from "../../utils/entityList";
import ExpandableJob from "../Job/ExpandableJob";
import {getScreeningQuestionAnswerPairs, getVideoQuestionAnswerPairs} from "../../utils/onDemandInterview";

class CandidateOneWayInterviewShow extends Component {

  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const item = this.props.retrieved;
    const screeningQuestionAnswerPairs = getScreeningQuestionAnswerPairs(item);
    const videoQuestionAnswerPairs = getVideoQuestionAnswerPairs(item);

    return (
      <div>
        <h1>Show &quot;{item && `${item.request.title}`}&quot;</h1>

        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
            {this.props.error}
          </div>
        )}

        <div className="grid-buttons">
          <Link to=".." className="btn btn-primary">
            Back to list
          </Link>
        </div>

        {item && (
          <>
            <div style={{'overflowX': 'auto'}}>
              <Table className="table table-responsive table-striped">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "20%" }}>Field</TableCell>
                    <TableCell style={{ width: "80%" }}>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell scope="row">Started At</TableCell>
                    <TableCell>{item['startedAt'] ? <Moment>{item['startedAt']}</Moment> : 'Not started'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Completed At</TableCell>
                    <TableCell>{item['completedAt'] ? <Moment>{item['completedAt']}</Moment> : 'Not completed'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Company</TableCell>
                    <TableCell>{renderLinks('./../companies', item['request']['company'], (company) => company['name'])}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Job</TableCell>
                    <TableCell>
                      <ExpandableJob job={item['request']['job']}/>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <GridContainer>
              {screeningQuestionAnswerPairs.length > 0 && (
                <>
                  <GridItem xs={12}><h2>Questions</h2></GridItem>
                  {screeningQuestionAnswerPairs.map(({question, answer}, index) => (
                    <GridItem lg={4} md={6} xs={12} style={{marginBottom: '20px'}} key={'knockout-question-answer-view-' + index}>
                      <Card style={{height: '100%', margin: 0}}>
                        <CardBody>
                          <KnockoutQuestionAnswerView question={question} answer={answer} showCorrect={false}/>
                        </CardBody>
                      </Card>
                    </GridItem>
                  ))}
                </>
              )}
              {videoQuestionAnswerPairs.length > 0 && (
                <>
                  <GridItem xs={12}><h2>Video Questions</h2></GridItem>
                  {videoQuestionAnswerPairs.map(({question, answer}, index) => (
                    <GridItem lg={4} md={6} xs={12} key={'video-question-answer-view-' + index} style={{marginBottom: '20px'}}>
                      <Card style={{height: '100%', margin: 0}}>
                        <CardBody>
                          <VideoQuestionAnswerView question={question} answer={answer}/>
                        </CardBody>
                      </Card>
                    </GridItem>
                  ))}
                </>
              )}
            </GridContainer>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    retrieved: state.candidateonewayinterview.show.retrieved,
    error: state.candidateonewayinterview.show.error,
    loading: state.candidateonewayinterview.show.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  reset: () => dispatch(reset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateOneWayInterviewShow);
