import React from "react";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import PhoneNumber from "../../views/Components/PhoneNumber";

const style = {
  root: {
    padding: '1em',
    paddingTop: '0',
  },
  companyInfoBlock: {
    marginBottom: '1em',
  },
  companyInfoHeader: {
    fontSize: 'large',
    fontWeight: 'bold',
  },
  companyInfoContent: {
    display: 'block',
  },
};

class VideoInterviewCompanyInfo extends React.Component {
  render() {
    const {classes, liveInterview: {job: {company}}} = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.companyInfoBlock}>
          <span className={classes.companyInfoHeader}>Company:</span>
          <span className={classes.companyInfoContent}>{company.name}</span>
        </div>
        <div className={classes.companyInfoBlock}>
          <span className={classes.companyInfoHeader}>Address:</span>
          {company.address && (<span className={classes.companyInfoContent}>{company.address}</span>)}
          {company.address2 && (<span className={classes.companyInfoContent}>{company.address2}</span>)}
          <span className={classes.companyInfoContent}>
            {company.city}, {company.state && (company.state.code)} {company.zipCode}
          </span>
        </div>
        <div className={classes.companyInfoBlock}>
          <span className={classes.companyInfoHeader}>Phone:</span>
          <span className={classes.companyInfoContent}>
            <PhoneNumber phone={company.phoneNumber}/>
          </span>
        </div>
        <div className={classes.companyInfoBlock}>
          <span className={classes.companyInfoHeader}>Website:</span>
          <span className={classes.companyInfoContent}><a href={company.webSite}>{company.webSite}</a></span>
        </div>
        <div className={classes.companyInfoBlock}>
          <span className={classes.companyInfoContent} dangerouslySetInnerHTML={{__html: company.description }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  liveInterview: state.liveInterviewPage.liveInterview,
});

const mapDispatchToProps = dispatch => ({
});

VideoInterviewCompanyInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoInterviewCompanyInfo);


VideoInterviewCompanyInfo = withStyles(style)(VideoInterviewCompanyInfo);

export default VideoInterviewCompanyInfo;
