import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Troubleshoot from "./Troubleshoot";
import Button from "../CustomButtons/Button";
import Stop from "@material-ui/icons/Stop";
import TestVideoElement from "./TestVideoElement";
import {streamManagerHasStream} from "../../utils/streamManager";
import classNames from 'classnames';
import VideoTestRecorder from "./VideoTestRecorder";
import {clearLocalUrl} from "../../utils/localUrl";

const style = {
  root: {
    padding: '2em',
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  videoContainer: {
    position: 'relative',
    width: '100%',
    flexGrow: 1,
  },
  alignCenter: {
    textAlign: 'center',
  },
  stopRecordingIcon: {
    backgroundColor: 'red',
    borderRadius: '18px',
  },
};

class AudioVideoTest extends React.Component {
  static propTypes = {
    streamManager: PropTypes.object,
    onTestingStart: PropTypes.func,
    onTestingEnd: PropTypes.func,
    autoStart: PropTypes.bool,
    generalButtonClass: PropTypes.string,
    stopRecordingButtonClass: PropTypes.string,
    startingRecordingButtonClass: PropTypes.string,
    testAgainButtonClass: PropTypes.string,
    endTestingButtonClass: PropTypes.string,
    allowLocalRecording: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    autoStart: false,
    generalButtonClass: 'audio-video-test-button',
    stopRecordingButtonClass: 'stop-recording-button',
    startingRecordingButtonClass: 'starting-recording-button',
    testAgainButtonClass: 'test-again-button',
    endTestingButtonClass: 'end-testing-button',
    allowLocalRecording: false,
  }

  static defaultTestingState = {
    startingRecord: false,
    recording: false,
    stoppingRecord: false,
    recordedVideo: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      testingStarted: this.props.autoStart,
      ...AudioVideoTest.defaultTestingState
    };

    this.recorder = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.recordedVideo && prevState.recordedVideo !== this.state.recordedVideo) {
      clearLocalUrl(prevState.recordedVideo.localUrl);
    }
  }

  componentWillUnmount() {
    if (this.state.recordedVideo) {
      clearLocalUrl(this.state.recordedVideo.localUrl);
    }
  }

  testAgain = () => {
    if (this.recorder && this.recorder.current) {
      this.setState({...AudioVideoTest.defaultTestingState})
      this.recorder.current.resetVideo();
      this.recorder.current.startRecording();
    }
  };

  stopRecording = () => {
    if (this.recorder && this.recorder.current) {
      this.recorder.current.stopRecording();
    }
  }

  startTesting = () => {
    this.setState({
      testingStarted: true,
      ...AudioVideoTest.defaultTestingState,
    });
    if (this.props.onTestingStart) {
      this.props.onTestingStart();
    }
  };

  endTesting = () => {
    this.setState({
      testingStarted: false
    });
    if (this.props.onTestingEnd) {
      this.props.onTestingEnd();
    }
  };

  renderControls = () => {
    const {testingStarted, startingRecord, recording, stoppingRecord} = this.state;
    const {
      streamManager, classes, generalButtonClass, stopRecordingButtonClass,
      startingRecordingButtonClass, testAgainButtonClass, endTestingButtonClass
    } = this.props;
    return (
      <div className="buttons-container">
        {testingStarted ? (
          <>
            {(recording || stoppingRecord) && (
              <Button onClick={this.stopRecording} className={classNames(generalButtonClass, stopRecordingButtonClass)} disabled={stoppingRecord}>
                <Stop className={classes.stopRecordingIcon}>&nbsp;</Stop>
                {stoppingRecord ? 'Stopping ...' : 'Stop Test'}
              </Button>
            )}
            {startingRecord && (
              <Button disabled={true} className={classNames(generalButtonClass, startingRecordingButtonClass)} >
                Starting ...
              </Button>
            )}
            {(!recording && !startingRecord && !stoppingRecord) && (
              <>
                {streamManagerHasStream(streamManager) && (
                  <Button onClick={this.testAgain} className={classNames(generalButtonClass, testAgainButtonClass)}>
                    Test Again
                  </Button>
                )}
                <Button onClick={this.endTesting} className={classNames(generalButtonClass, endTestingButtonClass)}>
                  Exit Testing
                </Button>
              </>
            )}
          </>
        ) : (
          <Button
            color="danger"
            onClick={this.startTesting}
            disabled={!streamManagerHasStream(streamManager)}
            className={'audio-video-test-button start-testing-button'}
          >
            Test Audio/Video
          </Button>
        )}
      </div>
    );
  };

  render() {
    const {streamManager, classes, allowLocalRecording} = this.props;
    const {testingStarted, recordedVideo} = this.state;
    let recordingUrl;
    if (recordedVideo) {
      recordingUrl = recordedVideo.localUrl ? recordedVideo.localUrl : recordedVideo.url;
    }
    return (
      <div className={classNames(classes.root, 'audio-video-test-component')}>
        <div className={classNames(classes.videoContainer, 'audio-video-test-component-container')}>
          {testingStarted && (
            <VideoTestRecorder
              ref={this.recorder}
              streamManager={streamManager}
              controlsState={this.state.controlsState}
              recordingUrl={recordingUrl}
              onRecordingStarting={() => this.setState({startingRecord: true, recording: false})}
              onRecordingStarted={() => this.setState({startingRecord: false, recording: true})}
              onRecordingStopping={() => this.setState({recording: false, stoppingRecord: true})}
              onRecorded={(video) => this.setState({stoppingRecord: false, recordedVideo: video})}
              allowLocalRecording={allowLocalRecording}
              testingState={{
                starting: this.state.startingRecord,
                stopping: this.state.stoppingRecord,
              }}
            />
          )}
          <TestVideoElement
            streamManager={streamManager}
          />
        </div>
        <div className={classNames(classes.alignCenter, 'audio-video-test-controls')}>
          {this.renderControls()}
          <Troubleshoot className={'audio-video-test-troubleshoot'} />
        </div>
      </div>
    );
  }
}

AudioVideoTest = withStyles(style)(AudioVideoTest);

export default AudioVideoTest;

