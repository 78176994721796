import React, {Component} from "react";
import PropTypes from "prop-types";
import {VIDEO_ENTRYPOINT} from "../../config/entrypoint";
import CombinedVideoRecorder from "../VideoRecorder/CombinedVideoRecorder";
import Loader from "../Loader/Loader";
import TimeElapsedDisplay from "../TimeElapsedDisplay/TimeElapsedDisplay";
import {BigPlayButton} from "video-react";
import './VideoTestRecorder.scss';
import VideoPlayer from "../video/VideoPlayer";

class VideoTestRecorder extends Component {
  static propTypes = {
    streamManager: PropTypes.object.isRequired,
    testingState: PropTypes.shape({
      starting: PropTypes.bool.isRequired,
      stopping: PropTypes.bool.isRequired,
    }).isRequired,
    allowLocalRecording: PropTypes.bool.isRequired,
    recordingUrl: PropTypes.string,
    onRecordingStarting: PropTypes.func,
    onRecordingStarted: PropTypes.func,
    onRecordingStopping: PropTypes.func,
    onRecorded: PropTypes.func,
    recordingSeconds: PropTypes.number.isRequired,
  };

  static defaultProps = {
    recordingSeconds: 10,
    allowLocalRecording: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      recordingTime: null,
    };

    this.recorder = React.createRef();
  }

  componentDidMount() {
    this.startRecording();
  }

  startRecording = () => {
    const {starting} = this.props.testingState;
    if (this.recorder && this.recorder.current && !starting) {
      this.recorder.current.startRecording();
    }
  }

  stopRecording = () => {
    const {stopping} = this.props.testingState;
    if (this.recorder && this.recorder.current && !stopping) {
      this.setState({recordingTime: null});
      this.recorder.current.stopRecording();
    }
  }

  resetVideo = () => {
    if (this.recorder && this.recorder.current) {
      this.recorder.current.resetVideo();
    }
  }

  onRecordingStarting = () => {
    if (this.props.onRecordingStarting) {
      this.props.onRecordingStarting();
    }
  }

  onRecordingStarted = () => {
    const recordingTime = new Date();
    recordingTime.setSeconds(recordingTime.getSeconds() + this.props.recordingSeconds);
    this.setState({recordingTime: recordingTime});

    if (this.props.onRecordingStarted) {
      this.props.onRecordingStarted();
    }
  }

  onRecorderStopping = () => {
    if (this.props.onRecordingStopping) {
      this.props.onRecordingStopping();
    }
  }

  onRecorded = (video) => {
    if (this.props.onRecorded) {
      this.props.onRecorded(video);
    }
  }

  render() {
    const {recordingTime} = this.state;
    const {streamManager, recordingUrl, allowLocalRecording, testingState: {starting, stopping}} = this.props;
    return (
      <div className={'video-test-recorder'}>
        <CombinedVideoRecorder
          streamManager={streamManager}
          ref={this.recorder}
          showUi={false}
          onRecordingStarting={this.onRecordingStarting}
          onRecordingStarted={this.onRecordingStarted}
          onRecordingStopping={this.onRecorderStopping}
          onRecorded={this.onRecorded}
          allowLocalRecording={allowLocalRecording}
          openviduRecorderConfig={{
            getSessionUrl: VIDEO_ENTRYPOINT + '/audio-video-test/get-session',
            startRecordingUrl: VIDEO_ENTRYPOINT + '/audio-video-test/start-recording',
            stopRecordingUrl: VIDEO_ENTRYPOINT + '/audio-video-test/stop-recording',
          }}
        />
        {starting && (
        <div className={"video-test-recorder-starting-overlay"}>
          <div className={"video-test-recorder-starting-content"}>
            <div className="d-none d-lg-block">
              <Loader/>
            </div>
            <span>Test will start in a couple of seconds. Please speak clearly to test your microphone.</span>
          </div>
        </div>
        )}
        {recordingTime && (
          <TimeElapsedDisplay
            startTime={this.state.recordingTime}
            running={!!recordingTime}
            withHours={false}
            isNegative={true}
            onCountedDown={this.stopRecording}
            containerClass={'time-elapsed-display video-test-recorder-timer'}
          />
        )}
        {stopping && !recordingUrl && (
          <div className={"placeholder video-test-recorder-stopping-loader"}>
            <Loader/>
          </div>
        )}
        {recordingUrl && (
          <VideoPlayer
            className={'video-test-recorder-player'}
            src={recordingUrl}
          >
            <BigPlayButton position="center" />
          </VideoPlayer>
        )}
      </div>
    );
  }
}

export default VideoTestRecorder;
