import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";

class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  render() {
    return (
      <RenderFilterForm {...this.props} fields={[
        {'name': 'id', 'label': 'ID', 'placeholder': 'ID', 'type': 'number'},
        {'name': 'title', 'label': 'Title', 'placeholder': 'Title', 'type': 'text'},
        {'name': 'completedAt', 'label': 'Completed Date', 'placeholder': 'Completed Date', 'type': 'datesRange', 'past': true},
      ]} />
    );
  }
}

export default reduxForm({
  form: 'filter_candidate_live_interview',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);
