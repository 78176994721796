import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";
import {loadOccupationsForSelect} from "../../actions/Occupation/forSelect";
import {loadTeamsForSelect} from "../../actions/Team/forSelect";
import {loadStatesForSelect} from "../../actions/State/forSelect";
import {loadIndustriesForSelect} from "../../actions/Industry/forSelect";
import {loadSpecialtiesForSelect} from "../../actions/Specialty/forSelect";
import {loadCountriesForSelect} from "../../actions/Country/forSelect";

class FilterForm extends Component {
  state = {
    industry:  null,
    occupation: null,
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.initialValues !== this.props.initialValues) {
      const values = nextProps.initialValues;
      this.setState({
        industry: null !== values && ('professionMappings___industry' in values) ? values['professionMappings___industry'] : null,
        occupation: null !== values && ('professionMappings___occupation' in values) ? values['professionMappings___occupation'] : null,
        country: values && ('country' in values) ? values['country'] : null,
      });
    }
  }

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  handleIndustrySelect = (e, value, oldValue) => {
    if (value !== oldValue) {
      this.props.change('professionMappings___occupation', null);
      this.props.change('professionMappings___specialty', null);

      this.setState({
        industry: value,
        occupation: null,
      });
    }
  };

  handleOccupationSelect = (e, value, oldValue) => {
    if (value !== oldValue) {
      this.props.change('professionMappings___specialty', null);

      this.setState({occupation: value});
    }
  };

  handleCountrySelect = (e, value, oldValue) => {
    if (value !== oldValue) {
      this.props.change('state', null);

      this.setState({country: value});
    }
  };

  onFirstExpand = () => {
    this.props.loadTeamsForSelect();
    this.props.loadCountriesForSelect(this.props.site.country && this.props.site.country.code);
    this.props.loadStatesForSelect();
    this.props.loadIndustriesForSelect();
    this.props.loadOccupationsForSelect();
    this.props.loadSpecialtiesForSelect();
  }

  render() {
    let countryPlaceholder = this.props.countriesLoading ? 'Loading...' : 'Country';
    let statePlaceholder = this.props.statesLoading ? 'Loading...' : 'Select Country First';
    let industryPlaceholder = this.props.industriesLoading ? 'Loading...' : 'Industries';
    let occupationPlaceholder = this.props.occupationsLoading ? 'Loading...' : 'Select Industry First';
    let specialtyPlaceholder = this.props.specialtiesLoading ? 'Loading...' : 'Select Occupation First';
    let teamPlaceholder = this.props.teamsLoading ? 'Loading...' : 'Team';

    let occupationOptions = [];
    let specialtyOptions = [];
    let stateOptions = [];

    if (this.state.industry !== null) {
      occupationPlaceholder = 'Occupation';
      if (this.props.occupations) {
        this.props.occupations.map(occupation => {
          if (occupation.industry === this.state.industry) {
            occupationOptions.push(occupation);
          }
        });
      }
    }
    if (this.state.occupation !== null) {
      specialtyPlaceholder = 'Specialty';
      if (this.props.specialties) {
        this.props.specialties.map(specialty => {
          if (specialty.occupation === this.state.occupation) {
            specialtyOptions.push(specialty);
          }
        });
      }
    }

    if (this.state.country !== null) {
      statePlaceholder = 'State';
      if (this.props.states) {
        this.props.states.map(state => {
          if (state.country === this.state.country) {
            stateOptions.push(state);
          }
        });
      }
    }

    const statuses = [
      {value: 'active', text: 'Active'},
      {value: 'archived', text: 'Archived'},
    ];

    return (
      <>
        {!this.props.parentLoading && this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        <RenderFilterForm {...this.props} onFirstExpand={this.onFirstExpand} fields={[
          {'name': 'id', 'label': 'ID', 'placeholder': 'ID', 'type': 'number'},
          {'name': 'title', 'label': 'Title', 'placeholder': 'Title', 'type': 'text'},
          {'name': 'status', 'label': 'Status', 'placeholder': 'Status', 'type': 'selectSearch', 'options': statuses},
          {'name': 'team', 'label': 'Team', 'placeholder': teamPlaceholder, 'type': 'selectSearch', 'options': this.props.teams},
          {'name': 'city', 'label': 'City', 'placeholder': 'City', 'type': 'text'},
          {'name': 'country', 'label': 'Country', 'placeholder': countryPlaceholder, 'type': 'selectSearch', 'options': this.props.countries, 'onChange': this.handleCountrySelect},
          {'name': 'state', 'label': 'State', 'placeholder': statePlaceholder, 'type': 'selectSearch', 'options': stateOptions, 'isDisabled': !this.state.country},
          {'name': 'professionMappings___industry', 'label': 'Industry', 'placeholder': industryPlaceholder, 'type': 'selectSearch', 'onChange': this.handleIndustrySelect, 'options': this.props.industries},
          {'name': 'professionMappings___occupation', 'label': 'Occupation', 'placeholder': occupationPlaceholder, 'type': 'selectSearch', 'isDisabled': !this.state.industry, 'onChange': this.handleOccupationSelect, 'options': occupationOptions},
          {'name': 'professionMappings___specialty', 'label': 'Specialty', 'placeholder': specialtyPlaceholder, 'type': 'selectSearch', 'isDisabled': !this.state.occupation, 'options': specialtyOptions},
        ]} />
      </>
    );
  }
}

const mapStateToProps = state => {
  let teams = state.team.forSelect.retrieved;
  let states = state.state.forSelect.retrieved;
  let industries = state.industry.forSelect.retrieved;
  let occupations = state.occupation.forSelect.retrieved;
  let specialties = state.specialty.forSelect.retrieved;
  let countries = state.country.forSelect.retrieved;
  let site =  state.siteDetails.siteDetails.retrieved;
  let loading = state.team.forSelect.loading || state.state.forSelect.loading || state.industry.forSelect.loading || state.occupation.forSelect.loading || state.specialty.forSelect.loading || state.country.forSelect.loading;
  let teamsLoading = state.team.forSelect.loading;
  let countriesLoading = state.country.forSelect.loading;
  let statesLoading = state.state.forSelect.loading;
  let industriesLoading = state.industry.forSelect.loading;
  let occupationsLoading = state.occupation.forSelect.loading;
  let specialtiesLoading = state.specialty.forSelect.loading;

  return { teams, countries, states, industries, occupations, specialties, site, loading, teamsLoading, countriesLoading, statesLoading, industriesLoading, occupationsLoading, specialtiesLoading };
};

const mapDispatchToProps = dispatch => ({
  loadOccupationsForSelect: () => dispatch(loadOccupationsForSelect('@id')),
  loadTeamsForSelect: () => dispatch(loadTeamsForSelect()),
  loadCountriesForSelect: (countryCode) => dispatch(loadCountriesForSelect('@id', countryCode)),
  loadStatesForSelect: () => dispatch(loadStatesForSelect()),
  loadIndustriesForSelect: () => dispatch(loadIndustriesForSelect('@id')),
  loadSpecialtiesForSelect: () => dispatch(loadSpecialtiesForSelect('@id')),
});

FilterForm = reduxForm({
  form: 'filter_job',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterForm);
