import React, { Component } from 'react';
import './Loader.css'

export default class Loader extends Component {
    static defaultProps = {
        size: 'la-2x',
        animation: 'la-timer',
    };
    render() {
        return <div className={`loader ${this.props.animation} ${this.props.size}`}>
            <div></div>
        </div>;
    }

}
