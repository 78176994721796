import React, {Component} from 'react';
import PropTypes from "prop-types";
import VideoRecorderUi from "./VideoRecorderUi";
import {generateRandomString} from "../../utils/string";
import {fixWebmDuration} from "../../utils/webm/webmDuration";
import browserRecordingSocket from "../../utils/browserRecordingSocket";
import {refreshToken} from "../../utils/authTokenRefresh";

const RECORDING_STOPPING_TIMEOUT = 10000;

class BrowserVideoRecorder extends Component {
  static propTypes = {
    socketRecordingType: PropTypes.string,
    storeVideoInMemory: PropTypes.bool,
    showUi: PropTypes.bool.isRequired,
    allowedToRecord: PropTypes.bool,
    startRecordingDisabled: PropTypes.bool,
    streamManager: PropTypes.object,
    onRecordingStarting: PropTypes.func,
    onRecordingStarted: PropTypes.func,
    onRecordingStopping: PropTypes.func,
    onRecorded: PropTypes.func,
    onVideoReset: PropTypes.func,
    onError: PropTypes.func,
    className: PropTypes.string,
    videoText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    initialVideoUrl: PropTypes.string,
    buttonLabels: PropTypes.object,
    additionalButtons: PropTypes.object,
  };

  static defaultProps = {
    storeVideoInMemory: true,
    showUi: true,
    buttonLabels: {},
    additionalButtons: {},
    allowedToRecord: true,
    className: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      videoUrl: null,
      startTime: null,
      fileName: null,
    }
    this.socket = null;
    this.recordingChunks = [];
    this.recordingId = generateRandomString(30);
    this.stoppingTimeout = null;
    this.ui = React.createRef();
  }

  componentDidMount() {
    this.mounted = true;
    if (this.props.streamManager) {
      this.addStreamManagerListeners(this.props.streamManager);
    }

    if (this.props.socketRecordingType) {
      this.initRecordingSocket();
    }
  }

  initRecordingSocket = () => {
    this.socket = browserRecordingSocket.initSocket(this.props.socketRecordingType, this.recordingId);
    this.socket.on('recording:result', this.onRecorded);
    this.socket.on('recording:error', this.onError);
    this.socket.on('recording:token-expired', this.onTokenExpired);
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.initialVideoUrl && this.props.initialVideoUrl && !this.state.videoUrl) {
      this.setInitialVideoUrl();
    }

    if (!prevProps.streamManager && this.props.streamManager) {
      this.addStreamManagerListeners(this.props.streamManager);
    }
  }

  setInitialVideoUrl = () => {
    if (this.props.initialVideoUrl) {
      this.setState({videoUrl: this.props.initialVideoUrl});
    }
  }

  addStreamManagerListeners = (streamManager) => {
    streamManager.on('streamPropertyChanged', (event) => {
      if (event.changedProperty === 'videoDevice' || event.changedProperty === 'audioDevice') {
        this.setState({mediaRecorder: null});
      }
    });
  }

  startRecording = () => {
    return new Promise(async (resolve, reject) => {
      const {streamManager} = this.props;
      if (!streamManager) {
        return reject(new Error('You should pass stream manager to recorder before start recording'));
      }

      if (this.socket && !this.socket.connected) {
        return reject(new Error('Could not start recording, please check your internet connection'));
      }

      try {
        if (this.props.onRecordingStarting) {
          this.props.onRecordingStarting();
        }

        let mediaRecorder = this.state.mediaRecorder;
        if (!mediaRecorder) {
          mediaRecorder = await this.initMediaRecorder(streamManager);
        }

        if (!mediaRecorder || mediaRecorder.state !== 'inactive') {
          return reject(new Error('Could not start recording for media recorder'));
        }

        if (this.props.onRecordingStarting) {
          this.props.onRecordingStarting();
        }

        const fileName = generateRandomString(15);
        this.setState({fileName: fileName});
        if (this.socket) {
          this.socket.emit('recording:start', fileName);
        }

        mediaRecorder.start(1000);
        resolve(true);
      } catch (e) {
        reject(e);
      }
    }).catch(e => {
      if (this.props.onError) {
        this.props.onError(e.message);
      }
      throw(e);
    });
  }

  startRecordingWithUi = () => {
    if (!this.props.showUi || !this.ui || !this.ui.current) {
      return false;
    }
    this.ui.current.startRecording();

    return true;
  }

  resetVideoWithUi = () => {
    this.resetVideo();
    if (this.props.showUi && this.ui && this.ui.current) {
      this.ui.current.resetUi();
    }
  }

  stopRecording = () => {
    return new Promise((resolve, reject) => {
      const mediaRecorder = this.state.mediaRecorder;
      if (!mediaRecorder || mediaRecorder.state !== 'recording') {
        return reject('Could not stop recording for media recorder');
      }

      if (this.props.onRecordingStopping) {
        this.props.onRecordingStopping();
      }
      mediaRecorder.stop();
      resolve(true);
    })
  }

  stopRecordingWithUi = () => {
    if (!this.props.showUi || !this.ui || !this.ui.current) {
      return false;
    }
    this.ui.current.stopRecording();

    return true;
  }

  initMediaRecorder = (streamManager) => {
    const mediaRecorder = new MediaRecorder(streamManager.stream.getMediaStream(), this.getMediaRecorderOptions());
    mediaRecorder.onstart = this.onRecordingStart;
    mediaRecorder.ondataavailable = this.onRecordingData;
    mediaRecorder.onstop = this.onRecordingStop;

    this.setState({mediaRecorder});

    return mediaRecorder;
  }

  getMediaRecorderOptions = () => {
    const options = {
      videoBitsPerSecond: 2500000, //In safari there is no max bitrate by default
    };
    const mimeType = this.getSupportedVideoMimeType();
    if (mimeType) {
      options['mimeType'] = mimeType;
    }
    return options;
  }

  getSupportedVideoMimeType = () => {
    if (typeof MediaRecorder.isTypeSupported !== 'function') {
      return null;
    }

    const videoTypes = ["video/mp4", "video/webm"];
    const videoCodecs = ["h264", "h.264"];

    for (let videoKey in videoTypes) {
      const type = videoTypes[videoKey];
      for (let codecKey in videoCodecs) {
        const codec = videoCodecs[codecKey];
        const variations = [
          `${type};codecs=${codec}`,
          `${type};codecs:${codec}`,
          `${type};codecs=${codec.toUpperCase()}`,
          `${type};codecs:${codec.toUpperCase()}`,
        ]
        for (let variationKey in variations) {
          const variation = variations[variationKey];
          if(MediaRecorder.isTypeSupported(variation)) {
            return variation;
          }
        }
      }
    }

    for (let videoKey in videoTypes) {
      const type = videoTypes[videoKey];
      if(MediaRecorder.isTypeSupported(type)) {
        return type;
      }
    }

    return null;
  }

  onRecordingStart = () => {
    if (this.props.onRecordingStarted) {
      this.props.onRecordingStarted();
    }
    this.setState({startTime: new Date()})
  }

  onRecordingData = (e) => {
    if (this.props.storeVideoInMemory) {
      this.recordingChunks.push(e.data);
    } else {
      this.recordingChunks.push({});
    }

    if (this.socket) {
      this.socket.emit(
        'recording:data',
        {
          fileName: this.state.fileName,
          chunkNumber: this.recordingChunks.length,
          data: e.data,
        }
      );
    }
  }

  onRecordingStop = async () => {
    const {startTime} = this.state;
    const duration = (startTime ? (Date.now() - startTime) : 0) ;
    const mimeType = this.state.mediaRecorder.mimeType;
    const extension = mimeType.search(/mp4/gi) !== -1 ? 'mp4' : 'webm'

    if (this.socket) {
      this.stoppingTimeout = setTimeout(() => {
        this.resetVideoWithUi();
        this.onError('Recording could not be saved, please check your internet connection');
      }, RECORDING_STOPPING_TIMEOUT);

      this.socket.emit(
        'recording:stop',
        {
          fileName: this.state.fileName,
          extension: extension,
          duration: duration,
          totalChunks: this.recordingChunks.length
        }
      );
    } else {
      this.onRecorded({duration: duration});
    }
  }

  onRecorded = async (result) => {
    clearTimeout(this.stoppingTimeout);
    if (this.props.storeVideoInMemory) {
      const mimeType = this.state.mediaRecorder.mimeType;
      let blob = new Blob(this.recordingChunks, {'type': mimeType});
      try {
        blob = await fixWebmDuration(blob, result.duration);
      } catch (e) {}
      result.localUrl = window.URL.createObjectURL(blob)
      this.recordingChunks = [];
    }

    if (this.props.onRecorded && this.mounted) {
      this.props.onRecorded(result);
    }
  };

  onTokenExpired = () => {
    if (this.stoppingTimeout) {
      clearTimeout(this.stoppingTimeout);
    }
    refreshToken()
      .then(() => {
        if (this.socket) {
          this.socket.disconnect();
          this.initRecordingSocket();
        } else {
          throw new Error('Socket not initialized');
        }
      })
      .then(this.onRecordingStop)
      .catch(e => {
        this.onError(e.message)
      });
  }

  onError = (errorMessage) => {
    if(this.props.onError) {
      this.props.onError(errorMessage);
    }
  }

  resetVideo = () => {
    this.setState({videoUrl: null, startTime: null, fileName: null});
    this.recordingChunks = [];
    if (this.props.onVideoReset) {
      this.props.onVideoReset();
    }
  }

  render() {
    const {
      streamManager, buttonLabels, additionalButtons, className, showUi, videoText, allowedToRecord, startRecordingDisabled
    } = this.props;
    return showUi ? (<VideoRecorderUi
      ref={this.ui}
      handleRecordingStart={this.startRecording}
      handleRecordingStop={this.stopRecording}
      handleRecordAgain={this.resetVideo}
      videoUrl={this.state.videoUrl}
      className={className}
      buttonLabels={buttonLabels}
      additionalButtons={additionalButtons}
      streamManager={streamManager}
      videoText={videoText}
      allowedToRecord={allowedToRecord}
      startRecordingDisabled={startRecordingDisabled}
    />) : (null);
  }
};

export default BrowserVideoRecorder;
