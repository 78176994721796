import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Form from './Form';
import { updateError, retrieve, update, reset } from '../../actions/Request/update';
import { del } from '../../actions/Request/delete';
import {loadJobsForSelect} from "../../actions/Job/forSelect";
import {reset as resetRequestTemplate} from '../../actions/RequestTemplate/create';

class RequestUpdate extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    retrieveLoading: PropTypes.bool.isRequired,
    retrieveError: PropTypes.string,
    updateLoading: PropTypes.bool.isRequired,
    updateError: PropTypes.string,
    deleteLoading: PropTypes.bool.isRequired,
    deleteError: PropTypes.string,
    updated: PropTypes.object,
    deleted: PropTypes.object,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    del: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  state = {templateNeverLoaded: true};

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.retrieved && this.props.retrieved) {
      const filters = {
        status: 'active',
        withId: this.props.retrieved.job
      };
      this.props.loadJobsForSelect(filters);
    }

    if (this.state.templateNeverLoaded && !prevProps.loadedTemplate && this.props.loadedTemplate) {
      this.setState({templateNeverLoaded: false});
    }
  }

  del = () => {
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.del(this.props.retrieved);
  };

  render() {
    if (this.props.deleted)
      this.props.history.push({
        pathname: `..`,
        state: {successMessage: 'On-Demand Interview successfully deleted.'}
      });

    if (this.props.updated)
      this.props.history.push({
        pathname: `..`,
        state: {successMessage: 'On-Demand Interview successfully updated.'}
      });

    const item = this.props.updated ? this.props.updated : this.props.retrieved;
    let initialValues = this.props.loadedTemplate;
    if (this.state.templateNeverLoaded) {
      initialValues = item;
    }
    if (initialValues && item) {
      initialValues.title = item.title;
      initialValues.job = item.job;
    }

    return (
      <div>
        <h1>Edit &quot;{item && item['title']}&quot;</h1>

        {(this.props.retrieveLoading ||
          this.props.updateLoading ||
          this.props.saveAsTemplateLoading ||
          this.props.templateLoading ||
          this.props.deleteLoading) && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
            {this.props.error}
          </div>
        )}

        {item && (
          <Form
            onSubmit={values => this.props.update(item, values)}
            jobs={this.props.jobs}
            initialValues={initialValues}
            errorDispatch={this.props.errorDispatch}
            templateLoaded={!!this.props.loadedTemplate}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  error: state.request.update.updateError || state.request.update.retrieveError || state.request.del.error || state.job.forSelect.error || state.requesttemplate.forSelect.error || state.request.loadTemplate.error || state.request.saveAsTemplate.error,
  retrieved: state.request.update.retrieved,
  retrieveLoading: state.request.update.retrieveLoading || state.job.forSelect.loading || false,
  updateLoading: state.request.update.updateLoading,
  deleteLoading: state.request.del.loading,
  templateLoading: state.requesttemplate.forSelect.loading || state.request.loadTemplate.loading,
  saveAsTemplateLoading: state.request.saveAsTemplate.loading,
  eventSource: state.request.update.eventSource,
  created: state.request.create.created,
  deleted: state.request.del.deleted,
  updated: state.request.update.updated,
  jobs: state.job.forSelect.retrieved,
  loadedTemplate: state.request.loadTemplate.request,
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  update: (item, values) => dispatch(update(item, values)),
  del: item => dispatch(del(item)),
  reset: eventSource => dispatch(reset(eventSource)),
  resetRequestTemplate: () => dispatch(resetRequestTemplate()),
  loadJobsForSelect: (filters) => dispatch(loadJobsForSelect(filters)),
  errorDispatch: (message) => dispatch(updateError(message)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestUpdate);
