import {
  fetch,
  normalize,
  extractHubURL,
  mercureSubscribe as subscribe
} from '../../utils/dataAccess';
import { success as deleteSuccess } from './delete';
import filterStorage from '../../utils/filtersStorage';
import {filterAction, sortAction} from "../../utils/filterAndSortUtils";

export function error(error) {
  return { type: 'TEAM_LIST_ERROR', error };
}

export function loading(loading) {
  return { type: 'TEAM_LIST_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'TEAM_LIST_SUCCESS', retrieved };
}

export function list(page = 'teams') {
  return dispatch => {
    dispatch(loading(true));
    dispatch(error(''));

    fetch(page)
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        retrieved = normalize(retrieved);

        dispatch(loading(false));
        dispatch(success(retrieved));

        if (hubURL && retrieved['hydra:member'].length)
          dispatch(
            mercureSubscribe(
              hubURL,
              retrieved['hydra:member'].map(i => i['@id'])
            )
          );
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'TEAM_LIST_RESET' });
    dispatch(deleteSuccess(null));
  };
}

export function mercureSubscribe(hubURL, topics) {
  return dispatch => {
    const eventSource = subscribe(hubURL, topics);
    dispatch(mercureOpen(eventSource));
    eventSource.addEventListener('message', event =>
      dispatch(mercureMessage(normalize(JSON.parse(event.data))))
    );
  };
}

export function mercureOpen(eventSource) {
  return { type: 'TEAM_LIST_MERCURE_OPEN', eventSource };
}

export function mercureMessage(retrieved) {
  return dispatch => {
    if (1 === Object.keys(retrieved).length) {
      dispatch({ type: 'TEAM_LIST_MERCURE_DELETED', retrieved });
      return;
    }

    dispatch({ type: 'TEAM_LIST_MERCURE_MESSAGE', retrieved });
  };
}

export function filter(filtersData) {
  return filterAction('TEAM_LIST_FILTER', 'company_team', filtersData)
}

export function sort(sortData) {
  return sortAction('TEAM_LIST_SORT','company_team', sortData)
}
