import {createVideoPublisher} from "../../utils/liveInterview";
import {videoPublisher, error} from "./interview";

export function startTesting() {
  return dispatch => {
    dispatch(startingTest(true));
  };
}

export function testingStarted() {
  return dispatch => {
    dispatch(setTesting(true));
    dispatch(startingTest(false));
  };
}

export function endTesting() {
  return dispatch => {
    dispatch(setTesting(false));
  }
}

function startingTest(starting) {
  return { type: 'LIVEINTERVIEW_PAGE_STARTING_TEST', starting }
}

function setTesting(testing) {
  return { type: 'LIVEINTERVIEW_PAGE_TESTING', testing };
}
