import { SubmissionError } from 'redux-form';
import {
  fetch,
  extractHubURL,
  normalize,
} from '../../utils/dataAccess';
import { success as createSuccess } from './create';

export function updateError(updateError) {
  return { type: 'SITE_SETTINGS_UPDATE_ERROR', updateError };
}

export function updateLoading(updateLoading) {
  return { type: 'SITE_SETTINGS_UPDATE_LOADING', updateLoading };
}

export function updateSuccess(updated) {
  return { type: 'SITE_SETTINGS_UPDATE_SUCCESS', updated };
}

export function update(item, values) {
  return dispatch => {
    dispatch(updateError(null));
    dispatch(createSuccess(null));
    dispatch(updateLoading(true));

    return fetch(item['@id'], {
      method: 'PUT',
      data: values,
    })
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        retrieved = normalize(retrieved);

        dispatch(updateLoading(false));
        dispatch(updateSuccess(retrieved));
      })
      .catch(e => {
        dispatch(updateLoading(false));

        if (e instanceof SubmissionError) {
          dispatch(updateError(e.errors._error));
          throw e;
        }

        dispatch(updateError(e.message));
      });
  };
}

