import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";
import {loadJobsForSelect} from "../../actions/Job/forSelect";
import {loadTeamsForSelect} from "../../actions/Team/forSelect";
import {loadTagsForSelect} from "../../actions/Tag/forSelect";

class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      jobsFilterIsModalOpen: false,
    }
  }

  onFirstExpand = () => {
    this.props.loadJobsForSelect();
    this.props.loadTagsForSelect();
  }

  jobsFilterToggleModal = (opened) => {
    this.setState({jobsFilterIsModalOpen: opened});
  }

  selectJob = (job, fieldName = '@id') => {
    this.props.change('job', job[fieldName]);
    this.jobsFilterToggleModal(false);
  }

  render() {
    let teamsPlaceholder = this.props.teamsLoading ? 'Loading...' : 'Team';
    let jobsPlaceholder = this.props.jobsLoading ? 'Loading...' : 'Job';
    let tagsPlaceholder = this.props.tagsLoading ? 'Loading...' : 'Tag';

    const averageRatingMinimumChoices = [
      {text: '5 Stars', value: '5'},
      {text: '4 Stars & Up', value: '4'},
      {text: '3 Stars & Up', value: '3'},
      {text: '2 Stars & Up', value: '2'},
      {text: '1 Star & Up', value: '1'},
    ];

    return (
      <>
        {!this.props.parentLoading && this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        <RenderFilterForm {...this.props} onFirstExpand={this.onFirstExpand} fields={[
          {'name': 'id', 'label': 'ID', 'placeholder': 'ID', 'type': 'number'},
          {'name': 'title', 'label': 'Title', 'placeholder': 'Title', 'type': 'text'},
          {'name': 'job', 'label': 'Job', 'placeholder': jobsPlaceholder, 'type': 'jobSelectSearch', 'options': this.props.jobs,
            'jobsFilterSelectJob': this.selectJob,
            'jobsFilterToggleModal': this.jobsFilterToggleModal,
            'jobsFilterIsModalOpen': this.state.jobsFilterIsModalOpen,
          },
          {'name': 'job___team', 'label': 'Team', 'placeholder': teamsPlaceholder, 'type': 'selectSearch', 'options': this.props.teams},
          {'name': 'totalAverageRating__gte__', 'label': 'Average Rating Minimum', 'placeholder': 'Average Rating Minimum', 'type': 'selectSearch', 'options': averageRatingMinimumChoices},
          {'name': 'tags', 'label': 'Tags', 'placeholder': tagsPlaceholder, 'type': 'selectSearch', 'options': this.props.tags},
          {'name': 'completedAt', 'label': 'Completed Date', 'type': 'datesRange', 'past': true},
        ]} />
      </>
    );
  }
}

const mapStateToProps = state => {
  let teams = state.team.forSelect.retrieved;
  let jobs = state.job.forSelect.retrieved;
  let tags = state.tag.forSelect.retrieved;
  let loading = state.team.forSelect.loading ||  state.job.forSelect.loading || state.tag.forSelect.loading;
  let teamsLoading = state.team.forSelect.loading;
  let jobsLoading = state.job.forSelect.loading;
  let tagsLoading = state.tag.forSelect.loading;

  return { teams, jobs, tags, loading, teamsLoading, jobsLoading, tagsLoading };
};

const mapDispatchToProps = dispatch => ({
  loadTeamsForSelect: () => dispatch(loadTeamsForSelect()),
  loadJobsForSelect: () => dispatch(loadJobsForSelect()),
  loadTagsForSelect: () => dispatch(loadTagsForSelect()),
});

FilterForm = reduxForm({
  form: 'filter_company_live_interview',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterForm);
