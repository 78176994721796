import {
  fetch,
  extractHubURL,
  normalize,
  mercureSubscribe as subscribe
} from '../../utils/dataAccess';
import {sort} from './util';
import {success as deleteSuccess} from "./delete";
import {isVideoNeedToReEncode} from "../../utils/videoEntity";
import {MERCURE_ENTRYPOINT} from "../../config/entrypoint";

export function error(error) {
  return { type: 'REQUEST_SHOW_ERROR', error };
}

export function loading(loading) {
  return { type: 'REQUEST_SHOW_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'REQUEST_SHOW_SUCCESS', retrieved };
}

export function retrieve(id) {
  return dispatch => {
    dispatch(loading(true));

    return fetch(id)
      .then(response =>response.data)
      .then(retrieved => {
        sort(retrieved);
        dispatch(loading(false));
        dispatch(success(retrieved));

        dispatch(mercureSubscribe(retrieved));
      })
      .catch(e => {
        const message = e.response && e.response.status === 404 ? 'This interview does not exist' : e.message;
        dispatch(loading(false));
        dispatch(error(message));
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'REQUEST_SHOW_RESET' });
    dispatch(error(null));
    dispatch(loading(false));
    dispatch(deleteSuccess(null));
  };
}

export function mercureSubscribe(request) {
  let topics = [];
  if (
    request['requestIntroVideo'] &&
    request['requestIntroVideo']['@id'] &&
    isVideoNeedToReEncode(request['requestIntroVideo'])
  ) {
    topics.push(request['requestIntroVideo']['@id']);
  }

  if (
    request['requestOutroVideo'] &&
    request['requestOutroVideo']['@id'] &&
    isVideoNeedToReEncode(request['requestOutroVideo'])
  ) {
    topics.push(request['requestOutroVideo']['@id']);
  }

  if (request['questions']) {
    request['questions'].forEach(question => {
      if (question && question['questionVideo'] && isVideoNeedToReEncode(question['questionVideo'])) {
        topics.push(question['questionVideo']['@id']);
      }
    })
  }

  return dispatch => {
    if (topics.length) {
      const eventSource = subscribe(new URL(MERCURE_ENTRYPOINT), topics);
      dispatch(mercureOpen(eventSource));
      eventSource.addEventListener('message', event =>
        dispatch(mercureMessage(normalize(JSON.parse(event.data))))
      );
    }
  };
}

export function mercureOpen(eventSource) {
  return { type: 'REQUEST_SHOW_MERCURE_OPEN', eventSource };
}

export function mercureMessage(retrieved) {
  return dispatch => {
    if (1 === Object.keys(retrieved).length) {
      dispatch({ type: 'REQUEST_SHOW_MERCURE_DELETED', retrieved });
      return;
    }

    if (retrieved && retrieved['@type'] === 'RequestIntroVideo') {
      dispatch({ type: 'REQUEST_INTROVIDEO_SHOW_MERCURE_MESSAGE', retrieved });
      return
    }

    if (retrieved && retrieved['@type'] === 'RequestOutroVideo') {
      dispatch({ type: 'REQUEST_OUTROVIDEO_SHOW_MERCURE_MESSAGE', retrieved });
      return
    }

    if (retrieved && retrieved['@type'] === 'QuestionVideo') {
      dispatch({ type: 'REQUEST_QUESTION_VIDEO_SHOW_MERCURE_MESSAGE', retrieved });
      return
    }

    dispatch({ type: 'REQUEST_SHOW_MERCURE_MESSAGE', retrieved });
  };
}
