import {
  fetch,
  extractHubURL,
} from '../../utils/dataAccess';
import { success as deleteSuccess } from './delete';
import {filterAction, sortAction} from "../../utils/filterAndSortUtils";

export function error(error) {
  return { type: 'LIVEINTERVIEW_SCHEDULE_LIST_ERROR', error };
}

export function loading(loading) {
  return { type: 'LIVEINTERVIEW_SCHEDULE_LIST_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'LIVEINTERVIEW_SCHEDULE_LIST_SUCCESS', retrieved };
}

export function list(page = 'live_interviews') {
  return dispatch => {
    dispatch(loading(true));
    dispatch(error(''));

    fetch(page)
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {

        dispatch(loading(false));
        dispatch(success(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch({ type: 'LIVEINTERVIEW_SCHEDULE_LIST_RESET' });
    dispatch(deleteSuccess(null));
  };
}

export function filter(filtersDataScheduled) {
  return filterAction('LIVEINTERVIEW_SCHEDULE_LIST_FILTER', 'company_live_interview_schedule', filtersDataScheduled)
}

export function sort(sortDataScheduled) {
  return sortAction('LIVEINTERVIEW_SCHEDULE_LIST_SORT','company_live_interview_schedule', sortDataScheduled)
}
