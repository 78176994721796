import { SubmissionError } from 'redux-form';
import {
  fetch,
  extractHubURL,
  normalize
} from '../../utils/dataAccess';

export function updateError(updateError) {
  return { type: 'COMPANY_USER_UPDATE_ROLE_ERROR', updateError };
}

export function updateLoading(updateLoading) {
  return { type: 'COMPANY_USER_UPDATE_ROLE_LOADING', updateLoading };
}

export function updateSuccess(updated) {
  return { type: 'COMPANY_USER_UPDATE_ROLE_SUCCESS', updated };
}

export function updateRole(values, callback) {
  return dispatch => {
    dispatch(updateError(null));
    dispatch(updateLoading(true));

    return fetch('/company/company-user-role-update', {
      method: 'PUT',
      data: values
    })
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        retrieved = normalize(retrieved);

        dispatch(updateLoading(false));
        dispatch(updateSuccess(retrieved));
        return callback(retrieved);
      })
      .catch(e => {
        dispatch(updateLoading(false));

        if (e instanceof SubmissionError) {
          dispatch(updateError(e.errors._error));
          throw e;
        }

        dispatch(updateError(e.message));
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'COMPANY_USER_UPDATE_ROLE_RESET' });
  };
}
