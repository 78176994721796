import React from "react";
import RecordingIcon from '@material-ui/icons/FiberManualRecord';
import withStyles from "@material-ui/core/styles/withStyles";
import {dangerColor} from "../../assets/jss/material-dashboard-pro-react";
import PropTypes from "prop-types";

const style = {
  '@keyframes icon-blink': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    }
  },
  recordingIcon: {
    animation: 'icon-blink 2s infinite',
  },
  root: {
    color: dangerColor[0],
    textAlign: 'center',
    display: 'flex',
  }
}

class RecordingIndicator extends React.Component {
  static propTypes = {
    text: PropTypes.string,
  };

  static defaultProps = {
    text: 'Recording',
  };

  render() {
    const {classes, text} = this.props;
    return (
      <span className={classes.root}>
        <RecordingIcon className={classes.recordingIcon} />
        <span>{text}</span>
      </span>
    );
  }
}

RecordingIndicator = withStyles(style)(RecordingIndicator);

export default RecordingIndicator;
