import React from "react";
import FormCard from "views/Components/FormCard.jsx";
import PasswordResetForm from "components/ForgotPassword/PasswordResetForm.jsx";
import {resetPassword, reset} from "actions/ForgotPassword/passwordReset";
import {connect} from "react-redux";
import {reset as resetForm} from 'redux-form';
import {currentUserRoles, handleAuthentication, isAuthenticated} from "../../utils/auth";
import {resolveDashboardUrl} from "../../utils/dashboardUrlResolver";

class PasswordResetPage extends React.Component {

    componentWillMount() {
        if (isAuthenticated()) {
            this.props.history.push(resolveDashboardUrl(currentUserRoles()));
        }
    }

    componentWillUnmount() {
        this.props.reset();
    }

    handleSubmit = (values) => {
        let token = this.props.match.params.token;
        return this.props.requestPasswordRecovery(token, values);
    };

    handleSuccessSubmit = (result, dispatch, props) => {
        dispatch(resetForm('password_reset'));
        if (this.props.success && this.props.success.token) {
            handleAuthentication(this.props.success);
        }
    };

    render() {
        return (
            <FormCard header="Reset Password">
                {this.props.loading && (
                    <div className="alert alert-info" role="status">
                        Loading...
                    </div>
                )}
                {this.props.success && this.props.success.token && (
                    <div className="alert alert-success" role="status">
                        Password was successfully reset
                    </div>
                )}
                {this.props.error && (
                    <div className="alert alert-danger" role="alert">
                        <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
                        {this.props.error}
                    </div>
                )}
                <PasswordResetForm
                    onSubmit={this.handleSubmit}
                    onSubmitSuccess={this.handleSuccessSubmit}
                />
            </FormCard>
        );
    }
}

const mapStateToProps = state => {
    const { success, error, loading } = state.forgotPassword.passwordReset;
    return { success, error, loading };
};

const mapDispatchToProps = dispatch => ({
    requestPasswordRecovery: (token, values) => dispatch(resetPassword(token, values)),
    reset: () => dispatch(reset()),
});

export default PasswordResetPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(PasswordResetPage);
