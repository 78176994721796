import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {retrieve, reset} from '../../actions/CandidateOneWayInterviewInvitation/show';
import {TableCell, TableHead, TableRow, Table, TableBody} from "@material-ui/core";
import InvitationInterviewLink from "./InvitationInterviewLink";
import Moment from "react-moment";
import {renderLinks} from "../../utils/entityList";
import ExpandableJob from "../Job/ExpandableJob";

class CandidateOneWayInterviewInvitationShow extends Component {

  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const item = this.props.retrieved;

    return (
      <div>
        <h1>Show &quot;{item && `Invitation to ${item.request.job.title}`}&quot;</h1>

        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
            {this.props.error}
          </div>
        )}

        <div className="grid-buttons">
          <Link to=".." className="btn btn-primary">
            Back to list
          </Link>
        </div>

        {item && (
          <>
            <div style={{'overflowX': 'auto'}}>
              <Table className="table table-responsive table-striped">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "20%" }}>Field</TableCell>
                    <TableCell style={{ width: "80%" }}>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell scope="row">Company</TableCell>
                    <TableCell>{renderLinks('./../companies', item['request']['company'], (company) => company['name'])}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Sent At</TableCell>
                    <TableCell>{item['createdAt'] && (<Moment>{item['createdAt']}</Moment>)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Interview</TableCell>
                    <TableCell>
                      {item && (<InvitationInterviewLink invitation={item} />)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Job</TableCell>
                    <TableCell>
                      <ExpandableJob job={item['request']['job']}/>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    retrieved: state.candidateonewayinverviewinvitation.show.retrieved,
    error: state.candidateonewayinverviewinvitation.show.error,
    loading: state.candidateonewayinverviewinvitation.show.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  reset: () => dispatch(reset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateOneWayInterviewInvitationShow);
