export function isVideoNeedToReEncode(videoEntity, source = 'uploaded') {
  return videoEntity && videoEntity.needToReEncode && videoEntity.videoSource === source
}

export function copyVideoEntity(entity) {
  return {
    needToReEncode: entity.needToReEncode,
    reEncodingProgress: entity.reEncodingProgress,
    videoOrigin: entity['@id'],
    videoSource: entity.videoSource,
    videoUrl: entity.videoUrl,
  };
}
