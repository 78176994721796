import {
  cleanMediaDeviceName,
  getAudioDevice,
  getAudioTrack,
  getVideoDevice,
  getVideoTrack,
} from "./webRtc";
import {StreamPropertyChangedEvent} from "openvidu-browser";
import {getOpenViduSingleton} from "./OpenViduInstance";

export const DEFAULT_RESOLUTION = '1280x720';

export const PUBLISHER_SIP_AUDIO_CONNECTED = 'publisher_stream_audio_connected';
export const PUBLISHER_SIP_AUDIO_DISCONNECTED = 'publisher_stream_audio_disconnected';

export function streamManagerHasAudio(streamManager) {
  return streamManager && (!streamManager.accessDenied && streamManager.stream.hasAudio);
}

export function streamManagerHasStream(streamManager) {
  return streamManager && (!streamManager.accessDenied && streamManager.stream);
}

export function streamManagerHasMediaStream(streamManager) {
  return streamManagerHasStream(streamManager) && streamManager.stream.mediaStream;
}

export function streamManagerHasVideo(streamManager) {
  return streamManagerHasStream(streamManager) && streamManager.stream.hasVideo;
}

export function isStreamManagerMuted(streamManager) {
  return !streamManager.stream.audioActive;
}

export function muteStreamManager(streamManager) {
  return streamManager.publishAudio(false);
}

export function unmuteStreamManager(streamManager) {
  return streamManager.publishAudio(true);
}

export function toggleAudioForStreamManager(streamManager) {
  const isMute = isStreamManagerMuted(streamManager);
  if (isMute) {
    unmuteStreamManager(streamManager);
  } else {
    muteStreamManager(streamManager);
  }
}

export function getStreamManagerVideoSourceName(publisher) {
  if (!publisher || !publisher.properties || !publisher.properties.videoSource) {
    return null;
  }
  const videoSource = publisher.properties.videoSource;

  return videoSource.label ? cleanMediaDeviceName(videoSource.label) : null;
}

export function getStreamManagerVideoDevice(publisher) {
  const mediaStream = publisher && publisher.stream ? publisher.stream.mediaStream : null;
  if (!mediaStream) {
    return null;
  }
  return getVideoDevice(mediaStream);
}

export function getStreamManagerVideoDeviceId(publisher) {
  const device = getStreamManagerVideoDevice(publisher);
  return device ? device.deviceId : null;
}

export function getStreamManagerAudioSourceName(publisher) {
  if (!publisher || !publisher.properties || !publisher.properties.audioSource) {
    return null;
  }
  const audioSource = publisher.properties.audioSource;

  return audioSource.label ? cleanMediaDeviceName(audioSource.label) : null;
}

export function getStreamManagerAudioDevice(publisher) {
  const mediaStream = publisher && publisher.stream ? publisher.stream.mediaStream : null;
  if (!mediaStream) {
    return null;
  }
  return getAudioDevice(mediaStream);
}

export function getStreamManagerAudioDeviceId(publisher) {
  const device = getStreamManagerAudioDevice(publisher)
  return device ? device.deviceId : null;
}

export function isScreenShareStreamManager(streamManager) {
  const stream = streamManager ? streamManager.stream : null;
  return isScreenShareStream(stream);
}

export function isScreenShareStream(stream) {
  return stream ? stream.typeOfVideo === 'SCREEN' : false;
}

export function changeVideoDevice(streamManager, deviceId) {
  const streamManagerConfig = getStreamManagerConfig(streamManager);
  const mediaConfig = {...streamManagerConfig, ...{videoSource: deviceId}};

  return new Promise(async (resolve, reject) => {
    let newMediaStream = undefined;
    try {
      if (streamManagerHasMediaStream(streamManager)) {
        streamManager.stream.mediaStream.getTracks().forEach(track => track.stop());
      }
      const OV = streamManager.openvidu;
      newMediaStream = await OV.getUserMedia(mediaConfig);
    } catch (e) {
      console.log('Failed to get new media stream', e);
    }

    if (!newMediaStream || !getVideoTrack(newMediaStream)) {
      return reject(false);
    }

    updateStreamManagerByNewMediaStream(streamManager, newMediaStream)
      .then(() => resolve(streamManager))
      .catch((e) => reject(e))
  });
}

export function changeAudioDevice(streamManager, deviceId) {
  const streamManagerConfig = getStreamManagerConfig(streamManager);
  const mediaConfig = {...streamManagerConfig, ...{audioSource: deviceId}};

  return new Promise(async (resolve, reject) => {
    let newMediaStream = undefined;
    try {
      if (streamManagerHasMediaStream(streamManager)) {
        streamManager.stream.mediaStream.getTracks().forEach(track => track.stop());
      }
      const OV = streamManager.openvidu;
      newMediaStream = await OV.getUserMedia(mediaConfig);
    } catch (e) {
      console.log('Failed to get new media stream', e);
    }

    if (!newMediaStream || !getAudioTrack(newMediaStream)) {
      return reject(false);
    }

    updateStreamManagerByNewMediaStream(streamManager, newMediaStream)
      .then(() => resolve(streamManager))
      .catch((e) => reject(e))
  });
}

export function getStreamManagerConfig(streamManager) {
  const mediaStream = streamManager.stream.mediaStream;
  const config = {...streamManager.properties};

  if (mediaStream) {
    const videoDevice = getVideoDevice(mediaStream);
    if (videoDevice) {
      config.videoSource = videoDevice.deviceId;
    }

    const audioDevice= getAudioDevice(mediaStream);
    if (audioDevice) {
      config.audioSource = audioDevice.deviceId;
    }
  }

  return config;
}

export function closeStreamManager(streamManager) {
  if (!streamManager) {
    return false;
  }

  if (streamManager.session) {
    streamManager.session.disconnect();
  }
  if (streamManager.stream) {
    streamManager.stream.disposeMediaStream();
  }

  if (streamManager.openvidu) {
    const OV = streamManager.openvidu;
    const publisherIndex = OV.publishers.indexOf(streamManager);
    if (publisherIndex !== -1) {
      OV.publishers.splice(publisherIndex, 1);
    }
  }
}

export function getUserMedia(config = {}) {
  return new Promise((resolve, reject) => {
    const OV = getOpenViduSingleton();
    OV.getUserMedia(config)
      .then(mediaStream => resolve(mediaStream))
      .catch(async (error) => {
        if (error.name === 'DEVICE_ACCESS_DENIED') {
          try {
            const mediaStream = await OV.getUserMedia({...config, ...{videoSource: false}});
            return resolve(mediaStream);
          } catch (e) {
            try {
              const mediaStream = await OV.getUserMedia({...config, ...{audioSource: false}});
              return resolve(mediaStream);
            } catch (e) {
              return reject(e);
            }
          }
        }
        return reject(error);
      });
  });
}

export async function updateStreamManagerByNewMediaStream(streamManager, mediaStream) {
  const streamManagerConfig = getStreamManagerConfig(streamManager);
  mediaStream.getTracks().forEach(track =>
    track.enabled = (track.kind === 'video' ?
        streamManager.stream.videoActive || !streamManagerConfig.videoSource : //Need to show new video track if original stream didn't have it
        streamManager.stream.audioActive || !streamManagerConfig.audioSource //Need to show new audio track if original stream didn't have it
    )
  );

  await updateWebRtcStream(streamManager, mediaStream);
  await updateLocalMediaStream(streamManager, mediaStream);
}

async function updateWebRtcStream(streamManager, mediaStream) {
  const stream = streamManager.stream;

  const peerConnection = stream && stream.getWebRtcPeer() ? stream.getRTCPeerConnection() : null;
  const senders = (peerConnection ? peerConnection.getSenders() : null);

  const videoSender = (senders || []).find((sender) => sender.track.kind === 'video');
  const videoTrack  = getVideoTrack(mediaStream);

  const audioSender = (senders || []).find((sender) => sender.track.kind === 'audio');
  const audioTrack  = getAudioTrack(mediaStream);

  if (videoSender && videoTrack) {
    await videoSender.replaceTrack(videoTrack);
  }

  if (audioSender && audioTrack) {
    await audioSender.replaceTrack(audioTrack);
  }
}

async function updateLocalMediaStream(streamManager, mediaStream) {
  const stream = streamManager.stream;
  const originalMediaStream = stream.mediaStream;

  const videoTrack = getVideoTrack(mediaStream);
  const originalVideoTrack = getVideoTrack(originalMediaStream);
  const newVideoTrackAdded = (videoTrack && !originalVideoTrack);
  const originalVideoDevice = getVideoDevice(originalMediaStream);
  const originalVideoDeviceId = originalVideoDevice ? originalVideoDevice.deviceId : null;
  const newVideoDevice = getVideoDevice(mediaStream);
  const newVideoDeviceId = newVideoDevice ? newVideoDevice.deviceId : null;
  const videoDeviceChanged = newVideoDeviceId !== originalVideoDeviceId;

  const audioTrack = getAudioTrack(mediaStream);
  const originalAudioTrack = getAudioTrack(originalMediaStream);
  const newAudioTrackAdded = (audioTrack && !originalAudioTrack);
  const originalAudioDevice = getAudioDevice(originalMediaStream);
  const originalAudioDeviceId = originalAudioDevice ? originalAudioDevice.deviceId : null;
  const newAudioDevice = getAudioDevice(mediaStream);
  const newAudioDeviceId = newAudioDevice ? newAudioDevice.deviceId : null;
  const audioDeviceChanged = newAudioDeviceId !== originalAudioDeviceId;

  const newTrackAdded = newVideoTrackAdded || newAudioTrackAdded;

  if (streamManager.session && newTrackAdded) {
    streamManager.session.unpublish(streamManager);
  }

  stream.setMediaStream(mediaStream);
  streamManager.updateMediaStream(mediaStream);

  streamManager.properties.videoSource = videoTrack;
  stream.hasVideo = !!videoTrack;
  stream.videoActive = videoTrack && videoTrack.enabled;

  streamManager.properties.audioSource = audioTrack;
  stream.hasAudio = !!audioTrack;
  stream.audioActive = audioTrack && audioTrack.enabled;

  if (streamManager.session && newTrackAdded) {
    streamManager.session.publish(streamManager);
  }

  if (videoDeviceChanged) {
    streamManager.emitEvent('streamPropertyChanged', [
      new StreamPropertyChangedEvent(streamManager, streamManager.stream, 'videoDevice', newVideoDeviceId, originalVideoDeviceId, 'deviceChanged')
    ]);
  }
  if (newVideoTrackAdded) {
    streamManager.emitEvent('streamPropertyChanged', [
      new StreamPropertyChangedEvent(streamManager, streamManager.stream, 'videoActive', stream.videoActive, !stream.videoActive, 'deviceChanged')
    ]);
  }

  if (audioDeviceChanged) {
    streamManager.emitEvent('streamPropertyChanged', [
      new StreamPropertyChangedEvent(streamManager, streamManager.stream, 'audioDevice', newAudioDeviceId, originalAudioDeviceId, 'deviceChanged')
    ]);
  }
  if (newAudioTrackAdded) {
    streamManager.emitEvent('streamPropertyChanged', [
      new StreamPropertyChangedEvent(streamManager, streamManager.stream, 'audioActive', stream.audioActive, !stream.audioActive, 'deviceChanged')
    ]);
  }
};

export function createStreamManager(openVidu, config = {}) {
  const defaultConfig = {
    publishAudio: true,
    publishVideo: true,
    resolution: DEFAULT_RESOLUTION,
    frameRate: 30,
    mirror: false,
  };
  const mergedConfig = Object.assign({}, defaultConfig, config);

  return new Promise((resolve, reject) => {
    const publisher = openVidu.initPublisher(
      undefined, mergedConfig,
      (error) => {
        if (error === undefined) {
          publisher.properties.resolution = mergedConfig.resolution;
          resolve(publisher);
        } else {
          reject(error);
        }
      }
    );
  })
}

export function getVideoResolution(streamManager) {
  const defaultResolutionParts = DEFAULT_RESOLUTION.split('x');
  const requestedResolutionParts = streamManager.properties.resolution.split('x');
  let resolution = {
    width: requestedResolutionParts && requestedResolutionParts.length === 2 ? requestedResolutionParts[0] : defaultResolutionParts[0],
    height: requestedResolutionParts && requestedResolutionParts.length === 2 ? requestedResolutionParts[1] : defaultResolutionParts[1],
  };

  if (streamManager && streamManager.stream && streamManager.stream.getMediaStream()) {
    const mediaStream = streamManager.stream.getMediaStream();
    const videoTracks = mediaStream.getVideoTracks();
    const trackSettings = videoTracks.length > 0 ? videoTracks[0].getSettings() : null;
    if (trackSettings) {
      resolution.width = trackSettings.width;
      resolution.height = trackSettings.height;
    }
  }

  return resolution;
}
