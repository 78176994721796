import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import {renderTextField} from "../../utils/form";

class CountryForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={renderTextField}
          name="name"
          type="text"
          placeholder="Name"
          required={true}
        />
        <Field
          component={renderTextField}
          name="code"
          type="text"
          placeholder="Code"
          required={true}
        />

        <button type="submit" className="btn btn-success float-left mr-3">
          Submit
        </button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'country',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(CountryForm);
