export function toHHMMSS(seconds) {
  const parts = [];

  const hours = Math.floor(seconds / 3600);
  if (hours > 0) {
    parts.push(String(hours).padStart(2, '0'));
  }

  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  parts.push(String(minutes).padStart(2, '0'));

  parts.push(String(seconds % 60).padStart(2, '0'));

  return parts.join(':');
}
