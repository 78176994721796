import React, {Component} from "react";
import KnockoutQuestionPreview from "../KnockoutQuestion/KnockoutQuestionPreview";
import VideoQuestionView from "../VideoQuestionPlayer/VideoQuestionView";
import CardBody from "../Card/CardBody";
import Card from "../Card/Card";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import JobBar from "../JobBar/JobBar";

export default class RequestPreview extends Component {
    render() {
        const {knockoutQuestions, videoQuestions} = this.props;
        return (
            <GridContainer>
                {this.props.jobIRI && (
                    <GridItem xs={12}>
                        <JobBar jobIRI={this.props.jobIRI} withDescription/>
                    </GridItem>)
                }
                {this.props.job && (
                    <GridItem xs={12}>
                        <JobBar job={this.props.job} withDescription/>
                    </GridItem>)
                }
                {knockoutQuestions && knockoutQuestions.length > 0 && (
                    <GridItem xs={12}>
                        <h3>Screening Questions</h3>
                    </GridItem>
                )}
                {knockoutQuestions && this.props.knockoutQuestions.map((knockoutQuestion, index) => {
                    return (
                        <GridItem lg={4} md={6} xs={12}
                                  style={{marginBottom: '20px'}}
                                  key={'knockout-question-preview-' + index}
                        >
                            <Card style={{height: '100%', margin: 0}}>
                                <CardBody>
                                    <KnockoutQuestionPreview
                                        knockoutQuestion={knockoutQuestion}/>
                                </CardBody>
                            </Card>
                        </GridItem>
                    )
                })}
                {videoQuestions && videoQuestions.length > 0 && (
                    <GridItem xs={12}>
                        <h3>Video Questions</h3>
                    </GridItem>)
                }
                {videoQuestions && videoQuestions.map((videoQuestion, index) => {
                    return (
                        <GridItem lg={4} md={6} xs={12}
                                  key={'video-question-view-' + index}
                                  style={{marginBottom: '20px'}}
                        >
                            <Card style={{height: '100%', margin: 0}}>
                                <CardBody>
                                    <VideoQuestionView videoQuestion={videoQuestion}/>
                                </CardBody>
                            </Card>
                        </GridItem>

                    );
                })}
            </GridContainer>
        );
    }
}
