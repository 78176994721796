import React from "react";
import {connect} from "react-redux";
import {retrieve, reset} from "actions/OneWayInterviewShareLink/byCode"
import {OneWayInterviewAnswers} from "../../components/OneWayInterview/OneWayInterviewAnswers";
import TextField from "@material-ui/core/TextField";
import Dialog from "../Components/Dialog/Dialog";

class OneWayInterviewPreview extends React.Component
{
  state = {
    password: '',
  }

  retrieveWithPassword = () => {
    if (this.state.password)
      this.props.retrieve(this.props.match.params.token, this.state.password);
  }

  componentDidMount() {
    this.props.retrieve(this.props.match.params.token);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const item = this.props.retrieved ? this.props.retrieved['oneWayInterview'] : null;

    return (<div className={"iframe-on-demand-interview"}>
        <div className={"iframe-on-demand-interview-header"}>
          {item && (
            <div className={'share-link-preview-interview-title'}>
              <h3>Show "{item['companyCandidate']['firstName']} {item['companyCandidate']['lastName']} ({item['request']['title']})"</h3>
            </div>
          )}
          <img src={this.props.site.logo} />
        </div>

        {!this.props.passwordRequired && this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}

        {this.props.noLongerAvailable && (
          <h3 className={'text-center'} style={{marginTop: '100px'}}>{this.props.noLongerAvailable}</h3>
        )}

        {item && (
          <OneWayInterviewAnswers item={item} />
        )}
        {(this.props.passwordRequired) && (
          <Dialog
            open={true}
            title={"Password Required"}
          >
            {this.props.passwordError && (
              <div className={"alert alert-danger"}>{this.props.passwordError}</div>
            )}
            {this.props.loading && (
              <div className="alert alert-info" role="status">
                Loading...
              </div>
            )}
            <div className={"share-link-password-container"}>
              <TextField
                label={'Password'}
                placeholder={'Password'}
                variant="outlined"
                type="password"
                value={this.state.password}
                onChange={(e) => {this.setState({password: e.target.value})}}
                onKeyPress={e => {e.key === 'Enter' && this.retrieveWithPassword(e)}}
                className={"width-300"}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <br />
              <button disabled={!this.state.password} className={"btn btn-success btn-lg mt-3"} onClick={this.retrieveWithPassword}>Submit</button>
            </div>
          </Dialog>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.onewayinterviewsharelink.byCode.retrieved,
  loading: state.onewayinterviewsharelink.byCode.loading,
  error: state.onewayinterviewsharelink.byCode.error,
  passwordError: state.onewayinterviewsharelink.byCode.passwordError,
  passwordRequired: state.onewayinterviewsharelink.byCode.passwordRequired,
  noLongerAvailable: state.onewayinterviewsharelink.byCode.noLongerAvailable,
  site: state.siteDetails.siteDetails.retrieved,
});

const mapDispatchToProps = dispatch => ({
  retrieve: (token, password) => dispatch(retrieve(token, password)),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OneWayInterviewPreview);
