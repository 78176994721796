import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import {renderCheckbox} from "../../utils/form";

class Settings extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
    keyword: PropTypes.string.isRequired,
    fieldPrefix: PropTypes.string.isRequired,
  };

  render() {
    return (
      <form>
        <h4 className={'mt-2 mb-3'}>Partner Settings</h4>
        <div className={"mb-3"}>
          <Field
            component={renderCheckbox}
            name={"displayDefaultContent"}
            label={"Display default content (libraries, categories, etc.)"}
            disabled={true}
          />
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'partner',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(Settings);

