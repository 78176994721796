import React, {Component} from "react";
import {FieldArray} from "redux-form";
import Button from "../CustomButtons/Button";
import VideoQuestion from "./VideoQuestion";
import VideoQuestionBankSearch from "./VideoQuestionBankSearch";
import LibraryAdd from '@material-ui/icons/LibraryAdd';
import Clear from '@material-ui/icons/Clear';
import './RequestVideoQuestions.scss'
import QuestionNumber from "./QuestionNumber";
import PropTypes from "prop-types";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const getDroppableQuestionsStyle = () => ({
    position: "relative"
});

const getDraggedDom = (draggableId) => {
    const queryAttr = "data-rbd-drag-handle-draggable-id";
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
};

const isEmpty = (obj) => {
    for (let key in obj) {
        return false;
    }

    return true;
};

export default class RequestVideoQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            placeholderProps: {}
        };
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragUpdate = this.onDragUpdate.bind(this);
    }

    static defaultProps = {
        questionsFieldName: "questions",
    };

    static propTypes = {
        updateVideoQuestionSettings: PropTypes.func.isRequired,
    };

    addQuestion = (questions) => {
        return () => {
            questions.push({});
        }
    };

    removeQuestion = (questions, index) => {
        return () => {
            questions.remove(index)
        }
    };

    onDragStart = (e) => {
        const draggedDOM = getDraggedDom(e.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = e.source.index;
        let clientY = parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) + [...draggedDOM.parentNode.children]
                .slice(0, sourceIndex)
                .reduce((total, curr) => {
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    return total + curr.clientHeight + marginBottom;
                }, 0);

        const placeholderProps = {
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            )
        }

        this.setState({
            placeholderProps
        });
    };

    onDragUpdate = (e) => {
        if (!e.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(e.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = e.destination.index;
        const sourceIndex = e.source.index;

        const childrenArray = [...draggedDOM.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1)
        ];

        let clientY = parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) + updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr.clientHeight + marginBottom;
            }, 0);

        const placeholderProps = {
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            )
        };

        this.setState({
            placeholderProps
        });
    };

    onDragEnd = (result) => {
        this.setState({placeholderProps : {}});
        if (!result.destination || result.source.index === result.destination.index) {
            return;
        }

        this.props.swapQuestions(result.source.index, result.destination.index);
    };

    renderQuestionsDraggable = ({
        fields: questions,
        meta: { error },
        provided: provided,
        snapshot: snapshot }) => (
            <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getDroppableQuestionsStyle()}
            >
                {questions.map((fieldName, index) => {
                    const question = this.props.videoQuestions[index];
                    if (!question) {
                        return null;
                    }
                    return (
                        <Draggable
                            draggableId={'video-question-' + index}
                            index={index}
                            key={'video-question-' + index}
                        >
                            {(provided) => (
                                <div className="video-question"
                                    key={index}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                >
                                    <VideoQuestion name={fieldName}
                                        index={index}
                                        prefix={(<QuestionNumber>{index + 1}.</QuestionNumber>)}
                                        currentValue={question}
                                        change={this.props.change}
                                        updateVideoQuestionSettings={this.props.updateVideoQuestionSettings}
                                        isDroppable={(questions.length > 1) ? true : false}
                                    >
                                        <Button size="sm" color="danger" onClick={this.removeQuestion(questions, index)}>
                                            <Clear>Remove Question</Clear>
                                        </Button>
                                    </VideoQuestion>
                                </div>
                            )}
                        </Draggable>
                    );
                })}

                {provided.placeholder}
                {!isEmpty(this.state.placeholderProps) && snapshot.isDraggingOver && (
                    <div className="ghost-placeholder"
                        style={{
                            top: this.state.placeholderProps.clientY,
                            left: this.state.placeholderProps.clientX,
                            height: this.state.placeholderProps.clientHeight,
                            width: this.state.placeholderProps.clientWidth
                        }}
                    />
                )}

                <div className="add-new-question" style={{ marginTop: "20px" }}>
                    {error && <div className="error">{error}</div>}
                    <Button color="success" onClick={this.addQuestion(questions)}>
                        <LibraryAdd />
                        Add New Question
                    </Button>
                </div>
            </div>
    );


    renderQuestions = ({fields: questions, meta: {error}}) => {
        return (
            <>
                {questions.map((fieldName, index) => {
                    const question = this.props.videoQuestions[index];
                    if (!question) {
                        return null;
                    }
                    return  (
                    <div className="video-question" key={index}>
                        <VideoQuestion name={fieldName}
                                       index={index}
                                       prefix={(<QuestionNumber>{index + 1}.</QuestionNumber>)}
                                       currentValue={question}
                                       change={this.props.change}
                                       updateVideoQuestionSettings={this.props.updateVideoQuestionSettings}
                                       isDroppable={(questions.length > 1) ? true : false}
                        >
                            <Button size="sm" color="danger" onClick={this.removeQuestion(questions, index)}>
                                <Clear>Remove Question</Clear>
                            </Button>
                        </VideoQuestion>
                    </div>
                    )
                })}
                <div className="add-new-question" style={{marginTop: "20px"}}>
                    {error && <div className="error">{error}</div>}
                    <Button color="success" onClick={this.addQuestion(questions)}>
                        <LibraryAdd/>
                        Add New Question
                    </Button>
                </div>
            </>
        );
    };

    render() {
        return (
            <div className="request-video-questions">
                <div className='question-bank-search column'>
                    <VideoQuestionBankSearch
                        onQuestionAdd={this.props.addQuestionFromBank}/>
                </div>
                <div className='video-questions column'>
                    <h2>Video Questions</h2>
                    {(this.props.videoQuestions.length > 1) && (
                       <div className="tips">
                           Tips: You can rearrange questions orders by drag-n-drop them
                       </div>
                   )}
                   {this.props.videoQuestions.length > 1 ? (
                       <DragDropContext
                           onDragEnd={this.onDragEnd}
                           onDragStart={this.onDragStart}
                           onDragUpdate={this.onDragUpdate}
                       >
                           <Droppable droppableId="video-questions-droppable">
                               {(provided, snapshot) => (
                                   <FieldArray name={this.props.questionsFieldName}
                                       component={this.renderQuestionsDraggable}
                                       videoQuestions={this.props.videoQuestions}
                                       provided={provided}
                                       snapshot={snapshot}
                                   />
                               )}
                           </Droppable>
                       </DragDropContext>
                    ) : (
                            <FieldArray name={this.props.questionsFieldName}
                                component={this.renderQuestions}
                                videoQuestions={this.props.videoQuestions}
                            />
                        )}
                </div>
            </div>
        );
    }
}
