import React, {Component} from "react";
import UserTyping from "./UserTyping";
import Fade from '@material-ui/core/Fade';
import classNames from 'classnames';

export default class UsersTyping extends Component {

    render() {
        const {fullscreen} = this.props;
        return (
            <Fade in={this.props.usersTyping && this.props.usersTyping.length > 0} timeout={600}>
                <div className={classNames('typing-wrapper', {'message-typing-fullscreen': fullscreen})}>
                    <UserTyping />
                    {this.props.usersTyping.map((user, i, arr) => {
                        return (
                            <span key={'user_typing-' + user.userId} className='author-name'>
                                {user.userName + (arr.length > 1 && arr.length - 1  !== i ? ',': '')}
                            </span>
                        )
                    })}
                </div>
            </Fade>
        );
    }
}