import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Form from './Form';
import { getCurrentCompany, updateCurrentCompany, reset } from "../../actions/Company/currentCompany";
import FlashMessages from "../../views/Components/FlashMessages";

class CompanyUpdate extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    updated: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    getCurrentCompany: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.getCurrentCompany();
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {
    const {retrieved, updated, loading, error} = this.props;

    return (
      <div>
        <h1>Edit &quot;{retrieved && retrieved['name']}&quot;</h1>

        {updated && (
          <FlashMessages type={"success"} message={"Company Profile successfully updated."} duration={10000} />
        )}
        {loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {!updated && retrieved && !retrieved.profileComplete && (
          <div className="alert alert-warning" role="status">
            Please, fill up company profile to be able to use the system.
          </div>
        )}
        {error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {error}
          </div>
        )}

        {retrieved && (
          <Form
            onSubmit={values => this.props.updateCurrentCompany(values)}
            initialValues={retrieved}
            fileUrl={(updated && updated.logo) ? updated.logo.url: null}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.company.currentCompany.retrieved,
  error: state.company.currentCompany.error,
  loading: state.company.currentCompany.loading,
  updated: state.company.currentCompany.updated,
  eventSource: state.company.update.eventSource,
});

const mapDispatchToProps = dispatch => ({
  getCurrentCompany: () => dispatch(getCurrentCompany()),
  updateCurrentCompany: (values) => dispatch(updateCurrentCompany(values)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyUpdate);
