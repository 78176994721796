import React from "react";
import PropTypes from "prop-types";

class AudioElement extends React.Component {
  static propTypes = {
    streamManager: PropTypes.object.isRequired,
    className: PropTypes.string,
    muted: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
  }

  componentDidMount() {
    this.setMediaStream();
    this.props.streamManager.stream.ee.on('mediastream-updated', () => {
      this.setMediaStream();
    });
  }

  setMediaStream = () => {
    const audio = this.audioRef.current;
    const stream = this.props.streamManager.stream;
    audio.srcObject = stream.getMediaStream();
  }

  render() {
    return (
        <audio className={this.props.className} controls={false} autoPlay={true} ref={this.audioRef} muted={this.props.muted}/>
    )
  }
}

export default AudioElement;
