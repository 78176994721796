import React, {Component} from "react";
import {renderTextField} from "../../utils/form";
import {Field, reduxForm} from "redux-form";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";

import './Form.scss';

class ReviewScaleForm extends Component {
  renderCriteriaRow = (number) => {
    return (
    <>
      <GridItem xs={12} lg={3}>
        <span className={'review-scale-form-label'}>Criteria {number}</span>
      </GridItem>
      <GridItem xs={12} lg={9}>
        <Field
          component={renderTextField}
          name={`[criteria][${number}][name]`}
          type="text"
          placeholder={`Criteria ${number}`}
          label={null}
          required={true}
        />
      </GridItem>
    </>
    );
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <GridContainer>
          {[1,2,3,4,5].map((number) => this.renderCriteriaRow(number))}
          <GridItem sm={12}>
            <p className={'text-muted'}>
              <em>Changes will apply to all previous and future reviews</em>
            </p>
          </GridItem>
          <GridItem sm={12}>
            <button type="submit" className="btn btn-success float-left mr-3">
              Save
            </button>
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}

ReviewScaleForm = reduxForm({
  form: 'review_scale_form',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(ReviewScaleForm);

export default ReviewScaleForm;
