import React from "react";
import {endInterview} from "../../actions/LiveInterviewPage/interview";
import {connect} from "react-redux";
import Dialog from "../../views/Components/Dialog/Dialog";

class EndInterviewDialog extends React.Component {
  endInterview = () => {
    const {session} = this.props;
    return this.props.endInterview(session);
  }

  render() {
    const {showDialog, closeDialog, endingInterview} = this.props;
    return (
      <Dialog
        open={showDialog}
        onClose={closeDialog}
        title={"End Interview"}
        closeButtonText={"No"}
        confirmButtonText={!endingInterview ? "Yes" : "Ending..."}
        confirmButtonDisabled={endingInterview}
        onConfirm={this.endInterview}
      >
        Are you sure you want to end this interview? This will end the interview for all participants, and you won’t be able to rejoin the interview.
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  session: state.liveInterviewPage.session,
  endingInterview: state.liveInterviewPage.endingInterview,
});

const mapDispatchToProps = dispatch => ({
  endInterview: (session) => dispatch(endInterview(session))
});

EndInterviewDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(EndInterviewDialog);

export default EndInterviewDialog;
