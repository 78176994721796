import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import "./PaymentMethod.scss";
import {PaymentMethodCard} from "../../views/Subscription/PaymentMethodCard";
import {AddPaymentMethodCard} from "../../views/Subscription/AddPaymentMethodCard";
import NotFound from "../../views/Pages/NotFound";
import {CustomSwitch} from "../../views/Components/CusomSwitch";
import { enableAutoRenew, reset as enableAutoRenewReset } from '../../actions/Subscription/enableAutoRenew';
import { disableAutoRenew, reset as disableAutoRenewReset } from '../../actions/Subscription/disableAutoRenew';
import { create as createOrder, reset as resetCreatedOrder } from '../../actions/Order/create';
import {list as subscriptionList, reset as subscriptionReset} from '../../actions/Subscription/list';
import {list, reset} from '../../actions/PaymentMethod/list';
import {del} from '../../actions/PaymentMethod/delete';
import {setAsDefault} from '../../actions/PaymentMethod/setAsDefault';
import {getCompanySubscriptionUrl} from "./Subscription";
import {Tabs} from "./Tabs";
import {IRItoID} from "../../utils/dataAccess";
import {applyToUrl} from "../../utils/urls";
import {Link} from "react-router-dom";
import PaymentFailModal from "./Modal/PaymentFailModal";
import PaymentMethodConfirmDeleteModal from "./Modal/PaymentMethodConfirmDeleteModal";
import AutoPayTurnOffModal from "./Modal/AutoPayTurnOffModal";
import UpgradeSummarySidebar from "./Components/UpgradeSummarySidebar";
import {
  calculateUpgradeSummary,
  reset as resetCalculateUpgradeSummary
} from "../../actions/Subscription/upgradeSummary";

class PaymentMethodSelect extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  state = {
    selectedPaymentMethodId: null,
    noChargeSelected: false,
    failedOrder: null,
    paymentMethodToDelete: null,
    showDisableAutopayModal: false,
  };

  fetchPaymentMethodsForCompany = () => {
    const companyId = IRItoID(decodeURIComponent(this.props.match.params.id));
    const page = applyToUrl('payment_methods', {'company___id': companyId});

    this.props.list(page);
  }

  componentDidMount() {
    this.fetchPaymentMethodsForCompany();
    this.props.subscriptionList(getCompanySubscriptionUrl(this.props.match.params.id));

    const locationState = this.props.location.state;
    if (locationState && locationState.plan && locationState.period) {
      const companyId = IRItoID(decodeURIComponent(this.props.match.params.id));
      const {plan, period} = this.props.location.state;
      this.props.calculateUpgradeSummary(IRItoID(plan['@id']), period, companyId);
    }
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
    this.props.subscriptionReset(this.props.eventSource);
    this.props.enableAutoRenewReset(this.props.eventSource);
    this.props.disableAutoRenewReset(this.props.eventSource);
    this.props.resetCreatedOrder(this.props.eventSource);
    this.props.resetCalculateUpgradeSummary(this.props.eventSource);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.retrieved !== this.props.retrieved) {
      if (this.props.retrieved) {
        if (this.props.retrieved['hydra:member'].length < 1) {
          this.setState({noChargeSelected: true});
        } else {
          this.props.retrieved['hydra:member'].map(paymentMethod => {
            if (paymentMethod['isDefault']) {
              this.onSelectPaymentMethod(paymentMethod);
            }
          })
        }
      }
    }
  }

  onSelectPaymentMethod = (paymentMethod) => {
    this.setState({
      selectedPaymentMethodId: paymentMethod['@id'],
      noChargeSelected: false
    });
  }

  onDeletePaymentMethod = (paymentMethod) => {
    if (this.state.paymentMethodToDelete) {
      this.setState({paymentMethodToDelete: null});
      this.props.del(paymentMethod, () => this.fetchPaymentMethodsForCompany());

      return;
    }

    this.setState({paymentMethodToDelete: paymentMethod});
  }

  closeDeletePaymentMethodConfirmModal = () => {
    this.setState({paymentMethodToDelete: null});
  }

  onSetAsDefaultPaymentMethod = (paymentMethod) => {
    this.props.setAsDefault(paymentMethod, () => this.fetchPaymentMethodsForCompany());
  }

  toggleNoChargeOption = () => {
    this.setState({
      noChargeSelected: !this.state.noChargeSelected,
      selectedPaymentMethodId: null,
    });
  }

  toggleAutoPay = (subscription) => {
    const callback = () => this.props.subscriptionList(getCompanySubscriptionUrl(this.props.match.params.id));
    const hasPaymentMethods = this.props.retrieved && this.props.retrieved['hydra:member'].length > 0;

    if (subscription['autoRenew'] && !this.state.showDisableAutopayModal) {
      this.setState({showDisableAutopayModal: true});
      return;
    }

    if (subscription['autoRenew'] && hasPaymentMethods) {
      this.props.disableAutoRenew(subscription, callback);
      this.setState({showDisableAutopayModal: false});
    } else {
      this.props.enableAutoRenew(subscription, callback);
    }
  }

  purchase = () => {
    if (!this.state.selectedPaymentMethodId && !this.state.noChargeSelected) {
      return;
    }

    const values = {
      paymentMethod: this.state.selectedPaymentMethodId,
      withoutPayment: this.state.noChargeSelected,
      company: decodeURIComponent(this.props.match.params.id),
      orderPlan: this.props.location.state.plan['@id'],
      orderPeriod: this.props.location.state.period,
    };

    this.props.createOrder(values);
  };

  render() {
    if (!this.props.location.state || !this.props.location.state.plan) {
      return <NotFound />;
    }

    let orderFailed = false;
    const subscription = this.props.subscription;
    const hasPaymentMethods = this.props.retrieved && this.props.retrieved['hydra:member'].length > 0;
    const {backToSubscription} = this.props.location.state;

    if (this.props.createdOrder) {
      let order = this.props.createdOrder;
      let transaction = order['transactions'].length > 0 && order['transactions'][order['transactions'].length - 1];
      orderFailed = !order.withoutPayment && ['failed', 'canceled'].includes(transaction['status']);
    }

    if (this.props.createdOrder && !orderFailed) {
      this.props.history.push({
        pathname: `../..${encodeURIComponent(this.props.createdOrder['@id'])}/payment-success`,
        state: {
          isUpgrade: !!subscription && subscription['active'],
        }
      });
    }

    return (
      <>
        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}
      <div className={"partner-company-payment-method-select-container"}>
        <Tabs
          planSelectUrl={ `/partner-admin/companies/subscription/${this.props.match.params.id}/plan-select`}
          step={2}
          isUpgrade={!!subscription && subscription['active']}
        />
        <h2>Select Payment Method</h2>
        <div className={"partner-company-payment-methods-list"}>
          {this.props.retrieved && this.props.retrieved['hydra:member'].map((paymentMethod, i) => (
            <PaymentMethodCard
              key={i}
              paymentMethod={paymentMethod}
              onClick={this.onSelectPaymentMethod}
              onDelete={this.onDeletePaymentMethod}
              onSetAsDefaultPaymentMethod={this.onSetAsDefaultPaymentMethod}
              selected={this.state.selectedPaymentMethodId === paymentMethod['@id']}
            />
          ))}
          <AddPaymentMethodCard onClick={() => {
            this.props.history.push({
              pathname: 'payment-methods/add',
              state: this.props.location.state,
            })
          }} />

          <div className={"clearfix"} />
          {subscription && hasPaymentMethods && (
            <div className={"enable-autopay"}>
              <span className={"float-left"}>Enable autopay</span>
              <CustomSwitch
                color={"primary"}
                value={subscription['autoRenew']}
                onClick={() => this.toggleAutoPay(subscription)}
                disabled={this.props.loading}
              />
              {this.props.loading && (
                <i className={"fa fa-spin fa-spinner ml-1"} />
              )}
              <div className={"clearfix"} />
              {this.state.showDisableAutopayModal && (
                <AutoPayTurnOffModal
                  handleClose={() => this.setState({showDisableAutopayModal: false})}
                  handleAccept={() => this.toggleAutoPay(subscription)}
                  subscription={subscription}
                />
              )}
            </div>
          )}
        </div>

        <div className={"no-charge" + (this.state.noChargeSelected ? ' selected' : '')} onClick={this.toggleNoChargeOption}>
          {this.state.noChargeSelected && (
            <i className={"fa fa-check-circle"} />
          )}
          Change plan with no charge for the current billing period
        </div>
        <div className={"buttons"}>
          <Link to={backToSubscription ? `../${this.props.match.params.id}` : "plan-select"} className={"subscription-button-blue-outlined float-left mr-3"}>Cancel</Link>
          <button className={"subscription-button-blue" + (!this.state.selectedPaymentMethodId && !this.state.noChargeSelected ? ' disabled' : '')}
                  onClick={this.purchase}
          >
            {this.state.noChargeSelected ? 'Proceed' : 'Purchase'}
          </button>
        </div>
      </div>

        <UpgradeSummarySidebar
          plan={this.props.location.state.plan}
          period={this.props.location.state.period}
          upgradeSummary={this.props.upgradeSummary}
          noChargeSelected={this.state.noChargeSelected}
        />

        {orderFailed && (
          <PaymentFailModal
            handleClose={() => this.props.resetCreatedOrder(this.props.eventSource)}
          />
        )}
        {this.state.paymentMethodToDelete && (
          <PaymentMethodConfirmDeleteModal
            handleClose={this.closeDeletePaymentMethodConfirmModal}
            handleDelete={this.onDeletePaymentMethod}
            paymentMethod={this.state.paymentMethodToDelete}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const loading = state.paymentmethod.list.loading ||
    state.subscription.list.loading ||
    state.paymentmethod.setAsDefault.loading ||
    state.paymentmethod.del.loading ||
    state.order.create.loading;
  const error =
    state.paymentmethod.list.error ||
    state.subscription.list.error ||
    state.paymentmethod.setAsDefault.error ||
    state.paymentmethod.del.error ||
    state.order.create.error;

  const eventSource = state.subscription.list.eventSource;
  const retrieved = state.paymentmethod.list.retrieved;
  const setAsDefaultResult = state.paymentmethod.setAsDefault.result;
  const upgradeSummaryLoading = state.subscription.upgradeSummary.loading;
  const upgradeSummary = state.subscription.upgradeSummary.retrieved;

  let subscription;
  if (
    state.subscription.list.retrieved &&
    Array.isArray(state.subscription.list.retrieved['hydra:member']) &&
    state.subscription.list.retrieved['hydra:member'].length > 0
  ) {
    subscription = state.subscription.list.retrieved['hydra:member'][0];
  }

  const createdOrder = state.order.create.created;

  return { retrieved, loading, error, eventSource, subscription, setAsDefaultResult, createdOrder, upgradeSummary, upgradeSummaryLoading };
};

const mapDispatchToProps = dispatch => ({
  subscriptionList: page => dispatch(subscriptionList(page)),
  subscriptionReset: eventSource => dispatch(subscriptionReset(eventSource)),
  enableAutoRenew: (subscription, callback) => dispatch(enableAutoRenew(subscription, callback)),
  enableAutoRenewReset: eventSource => dispatch(enableAutoRenewReset(eventSource)),
  disableAutoRenew: (subscription, callback) => dispatch(disableAutoRenew(subscription, callback)),
  disableAutoRenewReset: eventSource => dispatch(disableAutoRenewReset(eventSource)),
  calculateUpgradeSummary: (newPlan, newPeriod, companyId) => dispatch(calculateUpgradeSummary(newPlan, newPeriod, companyId)),
  resetCalculateUpgradeSummary: eventSource => dispatch(resetCalculateUpgradeSummary(eventSource)),
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource)),
  del: (item, callback) => dispatch(del(item, callback)),
  setAsDefault: (item, callback) => dispatch(setAsDefault(item, callback)),
  createOrder: (values) => dispatch(createOrder(values)),
  resetCreatedOrder: (eventSource) => dispatch(resetCreatedOrder(eventSource)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentMethodSelect);
