import React from "react";
import PropTypes from "prop-types";
import VideoOnIcon from '@material-ui/icons/Videocam';
import VideoOffIcon from '@material-ui/icons/VideocamOff';
import {IconButton} from "@material-ui/core";
import {StreamPropertyChangedEvent} from "openvidu-browser";

class VideoControl extends React.Component {
  static propTypes = {
    streamManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const {streamManager} = this.props;
    this.state = {
      turnedOff: !streamManager.stream || !streamManager.stream.videoActive,
    };
  }

  componentDidMount() {
    const {streamManager} = this.props;
    streamManager.ee.on('streamPropertyChanged', this.listenStreamPropertyChange);
  }

  componentWillUnmount() {
    const {streamManager} = this.props;
    streamManager.ee.off('streamPropertyChanged', this.listenStreamPropertyChange);
  }

  listenStreamPropertyChange = (event) => {
    if (event.changedProperty === 'videoActive') {
      this.setState({turnedOff: !event.newValue});
    }
  };

  toggleVideo = () => {
    const {streamManager} = this.props;
    streamManager.publishVideo(this.state.turnedOff);
    if (!streamManager.session) {
      //Need to manually emmit stream change event if there is no session for publisher
      const value = streamManager.stream.videoActive;
      streamManager.emitEvent('streamPropertyChanged', [
        new StreamPropertyChangedEvent(streamManager, streamManager.stream, 'videoActive', value, !value, 'publishVideo')
      ]);
    }
    this.setState({
      turnedOff: !this.state.turnedOff,
    })
  };

  render() {
    const {turnedOff} = this.state;
    const {streamManager, buttonClass} = this.props;
    const disabled = streamManager.accessDenied || (streamManager.stream && !streamManager.stream.hasVideo);
    return (
      <IconButton onClick={this.toggleVideo} disabled={disabled} className={buttonClass}>
        {turnedOff ? <VideoOffIcon /> : <VideoOnIcon /> }
        <span className='video-control-label'>{turnedOff ? 'Start Video' : 'Stop Video'}</span>
      </IconButton>
    )
  }
}

export default VideoControl;
