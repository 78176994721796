import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { list, reset, filter, sort } from '../../actions/Transaction/list';
import {handleListUpdate, pagination} from "../../utils/entityList";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import filtersStorage from '../../utils/filtersStorage';
import FilterForm from "./FilterForm";
import {SortableTableCells} from "../../utils/filterForm";
import TableFooter from "@material-ui/core/TableFooter";
import {getRoutePage} from "../../utils/routes";
import FlashMessages from "../../views/Components/FlashMessages";
import moment from "moment";
import {PaymentMethodLogo} from "../../views/Subscription/PaymentMethodCard";
import "./Transactions.scss";
import {firstLetterToUppercase} from "../../utils/firstLetterToUpperCase";
import TransactionViewModal from "./TransactionViewModal";

class TransactionList extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    filter: PropTypes.func.isRequired,
    filtersData: PropTypes.object,
    sort: PropTypes.func.isRequired,
    sortData: PropTypes.object
  };

  state = {
    showTransaction: null,
  };

  componentDidMount() {
    const storageName = this.getFiltersName();
    const sort = filtersStorage.getSort(storageName);
    let filters = filtersStorage.get(storageName, null);
    if (this.getDefaultFilters()) {
      filters = {...filters, ...this.getDefaultFilters()};
    }

    if (filters !== null || sort !== null) {
      this.props.filter(filters, storageName);
      this.props.sort(sort);

      return true;
    } else {
      handleListUpdate('transactions', this.props);
    }
  }

  componentDidUpdate(prevProps) {
    handleListUpdate('transactions', this.props, prevProps);
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  setShowTransaction = (transaction) => {
    this.setState({showTransaction: transaction});
  }

  getDefaultFilters = () => {
    if (this.props.match.params.company) {
      return {
        'company___id': decodeURIComponent(this.props.match.params.company),
      }
    }

    return null;
  }

  getFiltersName = () => {
    let filtersName = 'transactions';
    if (this.props.match.params.company) {
      filtersName += decodeURIComponent(this.props.match.params.company);
    }

    return filtersName;
  }

  filterReset = () => {
    this.props.filter(this.getDefaultFilters(), this.getFiltersName());
  }

  render() {
    return (
      <div className={"transactions-list"}>
        <FlashMessages location={this.props.location} />
        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}

        <FilterForm
          initialValues={this.props.filtersData}
          onSubmit={this.props.filter}
          resetFilter={this.filterReset}
        />

        <div style={{'overflowX': 'auto'}}>
          <Table className="table table-responsive table-striped table-hover mb-0">
            <TableHead>
              <TableRow>
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                  'createdAt': 'DATE',
                }} />
                <TableCell>DETAILS</TableCell>
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                  'amount': 'AMOUNT',
                  'status': 'STATUS',
                }} />
                <TableCell>PAYMENT METHOD</TableCell>
                <TableCell>RECEIPT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.retrieved &&
                this.props.retrieved['hydra:member'].map(transaction => (
                  <TableRow key={transaction['id']}>
                    <TableCell scope="row">{moment(transaction['createdAt']).format("MM/DD/YYYY")}</TableCell>
                    <TableCell>{transaction['description']}</TableCell>
                    <TableCell>$ {transaction['amount']}</TableCell>
                    <TableCell>
                      <div className={"transaction-status " + transaction['status']}>
                        {firstLetterToUppercase(transaction['status'])}
                      </div>
                    </TableCell>
                    <TableCell>
                      {transaction['brand'] && transaction['lastFour'] && (
                        <>
                          <PaymentMethodLogo brand={transaction['brand']} /> ending in {transaction['lastFour']}
                        </>
                      )}
                    </TableCell>
                    <TableCell><button className={'transaction-view'} onClick={() => this.setShowTransaction(transaction)}>View</button></TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>

        <Table className="table table-responsive no-border">
          <TableFooter>
            <TableRow>
              {pagination(this.props.retrieved, getRoutePage(this.props.match))}
            </TableRow>
          </TableFooter>
        </Table>

        {this.state.showTransaction && (
          <TransactionViewModal
            transaction={this.state.showTransaction}
            handleClose={() => this.setShowTransaction(null)}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    retrieved,
    loading,
    error,
    eventSource,
    filtersData,
    sortData,
  } = state.transaction.list;
  return { retrieved, loading, error, eventSource, filtersData, sortData };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource)),
  filter: (filtersData, filtersName) => dispatch(filter(filtersData, filtersName)),
  sort: (sortData) => dispatch(sort(sortData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionList);
