import Button from "../CustomButtons/Button";
import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import wizardStyle from "../../assets/jss/material-dashboard-pro-react/components/wizardStyle";
import cx from "classnames";
import {history} from "../../store";

class FormWizard extends Component {
    constructor(props) {
        super(props);

        this.ulRef = React.createRef();

        this.state = {
            currentStep: 0,
            movingTabStyle: {
                transition: "transform 0s"
            },
            nextButton: this.props.steps.length > 1 ? true : false,
            movingTabHeight: 0,
        };
    }
    componentDidMount() {
        this.refreshAnimation(0);
        window.addEventListener("resize", this.updateWidth);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWidth);
    }
    updateWidth = () => {
        this.refreshAnimation(this.state.currentStep);
    };
    nextStep = () => {
        this.switchToStep(this.state.currentStep + 1);
    };
    switchToStep = (key) => {
        this.setState({
            currentStep: key,
            nextButton: this.props.steps.length > key + 1,
        });
        if (this.props.scrollTopOnStepChange) {
            this.scrollTop();
        }
        this.refreshAnimation(key);
    };

    static defaultProps = {
        color: "rose",
        title: "Here should go your title",
        subtitle: "And this would be your subtitle",
        nextButtonClasses: "",
        nextButtonText: "Next",
        scrollTopOnStepChange : true,
    };

    scrollTop = () => {
        const mainPanel = document.querySelector('.dashboard-main-panel');
        if (mainPanel) {
            mainPanel.scrollTo(0,0);
        }
    };

    addMovingTabHeight() {
        const childrens = this.ulRef.current.children;
        let maxHeight = childrens[0].clientHeight;
        [...childrens].forEach(item => {
            if (item.clientHeight > maxHeight) maxHeight = item.clientHeight;
        });
        this.setState({movingTabHeight: maxHeight});
    }

    refreshAnimation(index) {
        var total = this.props.steps.length;
        var li_width = 100 / total;
        var total_steps = this.props.steps.length;
        var move_distance = this.refs.wizard.children[0].offsetWidth / total_steps;
        var index_temp = index;
        var vertical_level = 0;

        var mobile_device = window.innerWidth < 600 && total > 3;

        if (mobile_device) {
            move_distance = this.refs.wizard.children[0].offsetWidth / 2;
            index_temp = index % 2;
            li_width = 50;
        }

        this.setState({width: li_width + "%"});

        var step_width = move_distance;
        move_distance = move_distance * index_temp;

        var current = index + 1;

        if (current === 1 || (mobile_device === true && index % 2 === 0)) {
            move_distance -= 1;
        } else if (
            current === total_steps ||
            (mobile_device === true && index % 2 === 1)
        ) {
            move_distance += 1;
        }

        if (mobile_device) {
            vertical_level = parseInt(index / 2, 10);
            if (window.innerWidth < 425 && total > 3) {
                vertical_level = vertical_level * 56;
            } else {
                vertical_level = vertical_level * 38;
            }
        }

        setTimeout(() => {
            this.addMovingTabHeight();
            var movingTabStyle = {
                width: step_width,
                height: this.state.movingTabHeight,
                transform:
                    "translate3d(" + move_distance + "px, " + vertical_level + "px, 0)",
                transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)"
            };
            this.setState({movingTabStyle: movingTabStyle});
        }, 100);
    }

    render() {
        const {classes, color, steps, AdditionalButtons} = this.props;
        return (
            <div className={classes.wizardContainer} ref="wizard">
                <div className={classes.container}>
                    <div className={classes.wizardNavigation}>
                        <ul className={classes.nav} ref={this.ulRef}>
                            {steps.map((step, key) => {
                                return (
                                    <li
                                        className={classes.steps}
                                        key={key}
                                        style={{width: this.state.width}}
                                    >
                                        <a
                                            className={classes.stepsAnchor}
                                            href={"#" + key}
                                            onClick={e => {
                                                e.preventDefault();
                                                this.switchToStep(key);
                                            }}
                                        >
                                            {step.stepName}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                        <div
                            className={classes.movingTab + " " + classes[color]}
                            style={this.state.movingTabStyle}

                        >
                            {steps[this.state.currentStep].stepName}
                        </div>
                    </div>
                    <div className={classes.content}>
                        {steps.map((prop, key) => {
                            const stepContentClasses = cx({
                                [classes.stepContentActive]: this.state.currentStep === key,
                                [classes.stepContent]: this.state.currentStep !== key
                            });
                            return (
                                <div className={stepContentClasses} key={key}>
                                    {prop.content}
                                </div>
                            );
                        })}
                    </div>
                    <div className={classes.footer}>
                        <div className={classes.right}>
                            {this.props.cancelButtonUrl && (
                                <Button className={'mr-2'} type={"button"} onClick={() => {
                                    history.push(this.props.cancelButtonUrl);
                                }}>
                                    Cancel
                                </Button>
                            )}
                            <Button color="success" className={'mr-2'} type={"submit"}>
                                Save and Exit
                            </Button>
                            {this.state.nextButton ? (
                                <Button
                                    color="rose"
                                    className={this.props.nextButtonClasses}
                                    onClick={this.nextStep}
                                >
                                    {this.props.nextButtonText}
                                </Button>
                            ) : null}
                        </div>
                        <div className={classes.clearfix}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(wizardStyle)(FormWizard);
