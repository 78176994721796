import {Field, reduxForm} from "redux-form";
import SearchableSelect from "../../views/Components/SearchableSelect";
import Button from "../CustomButtons/Button";
import {Paper} from "@material-ui/core";
import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const style = {
  paper: {
    padding: '1em',
    minWidth: '300px',
  }
};

class SaveAsTemplateTeamDialogue extends Component {
  render() {
    const {teams, classes} = this.props;
    return (
      <Paper className={classes.paper}>
        <form onSubmit={this.props.handleSubmit}>
          <Field
            label="Interview Template Team"
            name="team"
            placeholder='Select Team'
            component={SearchableSelect}
            options={teams}
            required={true}
            menuPlacement='auto'
          />
          <Button color="success" fullWidth onClick={this.props.handleSubmit}>
            Save For Team
          </Button>
        </form>
      </Paper>
    );
  }
}

SaveAsTemplateTeamDialogue = reduxForm({
  form: 'save_request_template_team',
  validate: (values) => {
    const errors = {};
    if (!values.team) {
      errors.team = 'Please, select team';
    }
    return errors;
  }
})(SaveAsTemplateTeamDialogue);

export default withStyles(style)(SaveAsTemplateTeamDialogue);
