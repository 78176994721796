import React, {Component} from "react";
import moment from "moment";
import Moment from "react-moment";
import PropTypes from "prop-types";

export default class DateTimeUserTimeZone extends Component {
  static propTypes = {
    time: PropTypes.string.isRequired,
  };

  render() {
    const {time} = this.props;
    const dt = moment.tz(time, moment.tz.guess());
    return (
      <>
        {dt.format(Moment.timeFormat)}
      </>
    )
  }
}
