export function handleChatMessage(type, rawMessage) {
  const message = {
    userId: rawMessage.userId,
    type: type,
    body: rawMessage,
    unread: rawMessage.unread || true,
    own: rawMessage.own,
  };

  return {type: 'LIVEINTERVIEW_PAGE_CHAT_ADD_MESSAGE', message}
}

export function clearChatMessages() {
  return {type: 'LIVEINTERVIEW_PAGE_CLEAR_CHAT_MESSAGES'}
}
