import { combineReducers } from 'redux';

export function error(state = null, action) {
  switch (action.type) {
    case 'REQUEST_SHOW_ERROR':
      return action.error;

    case 'REQUEST_SHOW_MERCURE_DELETED':
      return `${action.retrieved['@id']} has been deleted by another user.`;

    case 'REQUEST_SHOW_RESET':
      return null;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'REQUEST_SHOW_LOADING':
      return action.loading;

    case 'REQUEST_SHOW_RESET':
      return false;

    default:
      return state;
  }
}

export function retrieved(state = null, action) {
  switch (action.type) {
    case 'REQUEST_SHOW_SUCCESS':
    case 'REQUEST_SHOW_MERCURE_MESSAGE':
      return action.retrieved;
    case 'REQUEST_INTROVIDEO_SHOW_MERCURE_MESSAGE':
      return {...state, requestIntroVideo: action.retrieved};
    case 'REQUEST_OUTROVIDEO_SHOW_MERCURE_MESSAGE':
      return {...state, requestOutroVideo: action.retrieved};
    case 'REQUEST_QUESTION_VIDEO_SHOW_MERCURE_MESSAGE':
      const retrieved = action.retrieved;
      const questions = state.questions.map(question => {
        if (question && question.questionVideo && question.questionVideo['@id'] === retrieved['@id']) {
          return {...question, questionVideo: retrieved};
        } else {
          return question;
        }
      });
      return {...state, questions: questions};
    case 'REQUEST_SHOW_RESET':
      return null;

    default:
      return state;
  }
}

export function eventSource(state = null, action) {
  switch (action.type) {
    case 'REQUEST_SHOW_MERCURE_OPEN':
      return action.eventSource;

    case 'REQUEST_SHOW_RESET':
      return null;

    default:
      return state;
  }
}

export default combineReducers({ error, loading, retrieved, eventSource });
