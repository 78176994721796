import React, {Component} from "react";
import PropTypes from "prop-types";
import { firstLetterToUppercase } from "../../utils/firstLetterToUpperCase";

class JobSalary extends Component {
  static propTypes = {
    job: PropTypes.object.isRequired,
  };

  render() {
    const {job} = this.props;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    const salaryType = firstLetterToUppercase(job.salaryType);

    const salaryTypeTemplate = salaryType ? `, ${salaryType}` : '';

    if (job.minSalary && job.maxSalary) {
      return <span>{formatter.format(job.minSalary)} - {formatter.format(job.maxSalary)}{salaryTypeTemplate}</span>
    } else if (job.minSalary) {
      return <span>{formatter.format(job.minSalary)}{salaryTypeTemplate}</span>
    } else if (job.maxSalary) {
      return <span>{formatter.format(job.maxSalary)}{salaryTypeTemplate}</span>
    } else if (salaryType) {
      return <span>{salaryType}</span>
    }

    return null
  }
}

export default JobSalary;
