import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";
import {loadPartnersForSelect} from "../../actions/Partner/forSelect";
import {currentUserHasRole} from "../../utils/auth";

class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  componentDidMount() {
    if (currentUserHasRole('ROLE_SUPER_ADMIN')) {
      this.props.loadPartnersForSelect();
    }
  }

  render() {
    const fields = [
      {'name': 'id', 'label': 'ID', 'placeholder': 'ID', 'type': 'number'},
      {'name': 'firstName', 'label': 'First Name', 'placeholder': 'Last Name', 'type': 'text'},
      {'name': 'lastName', 'label': 'Last Name', 'placeholder': 'Last Name', 'type': 'text'},
      {'name': 'email', 'label': 'Email Address', 'placeholder': 'Email Address', 'type': 'text'},
    ];

    if (currentUserHasRole('ROLE_SUPER_ADMIN')) {
      fields.push({'name': 'partner', 'label': 'Partner', 'placeholder': 'Partner', 'type': 'selectSearch', 'options': this.props.partners});
    }

    return (
      <RenderFilterForm {...this.props} fields={fields} />
    );
  }
}

const mapStateToProps = state => {
  let partners = state.partner.forSelect.retrieved;

  return { partners };
};

const mapDispatchToProps = dispatch => ({
  loadPartnersForSelect: () => dispatch(loadPartnersForSelect()),
});

FilterForm = reduxForm({
  form: 'tickets',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterForm);
