import { combineReducers } from 'redux';
import list from './list';
import create from './create';
import update from './update';
import del from './delete';
import show from './show';
import saveAsTemplate from './saveAsTemplate';
import loadTemplate from './loadTemplate';
import forSelect from './forSelect';

export default combineReducers({
  list,
  create,
  update,
  del,
  show,
  saveAsTemplate,
  loadTemplate,
  forSelect
});
