import React, {Component} from "react";
import Card from "@material-ui/core/Card";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import EmailIcon from '@material-ui/icons/Email';
import SmartPhoneIcon from '@material-ui/icons/Smartphone';
import WorkIcon from '@material-ui/icons/Work';
import FolderIcon from '@material-ui/icons/FolderShared';
import GlobeIcon from '@material-ui/icons/Language';
import ProfessionMappingView from "../../views/Components/ProfessionMappingView";
import PropTypes from "prop-types";
import {CopyToClipboard} from "../CopyToClipboard/CopyToClipboard";
import IconButton from "@material-ui/core/IconButton";
import CopyContent from "../Icons/CopyContent";
import Typography from "@material-ui/core/Typography";
import PhoneNumber from "../../views/Components/PhoneNumber";
import CandidateLink from "./CandidateLink";

class CandidateCard extends Component {
  static propTypes = {
    companyCandidate: PropTypes.object.isRequired,
    checkPermissions: PropTypes.bool
  };

  static defaultProps = {
    checkPermissions: false,
  };

  render() {
    const {companyCandidate, link} = this.props;
    return (
      <Card>
          <List>
            {(companyCandidate.firstName || companyCandidate.lastName) && (
            <ListItem>
              <ListItemIcon>
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText>
                <CandidateLink candidate={companyCandidate} checkPermissions={this.props.checkPermissions} />
              </ListItemText>
            </ListItem>
            )}
            {companyCandidate.email && (
            <ListItem>
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText primary={companyCandidate.email} />
            </ListItem>
            )}
            {companyCandidate.phoneNumber && (
            <ListItem>
              <ListItemIcon>
                <SmartPhoneIcon />
              </ListItemIcon>
              <ListItemText primary={
                <PhoneNumber phone={companyCandidate.phoneNumber} formattedPhone={companyCandidate.formattedPhoneNumber} />
              } />
            </ListItem>
            )}
            {companyCandidate.professionMappings && companyCandidate.professionMappings.length > 0 && (
            <ListItem>
              <ListItemIcon>
                <WorkIcon />
              </ListItemIcon>
              <ListItemText primary={companyCandidate.professionMappings.map(
                (mapping, index) => <ProfessionMappingView key={index} mapping={mapping}/>
              )} />
            </ListItem>
            )}
            {companyCandidate.resume && (
              <ListItem>
                <ListItemIcon>
                  <FolderIcon />
                </ListItemIcon>
                <ListItemText primary={<a href={companyCandidate.resume.file.url}>Download Resume</a>} />
              </ListItem>
            )}
            {link && (
              <ListItem>
                <ListItemIcon>
                  <GlobeIcon />
                </ListItemIcon>
                <ListItemText primary={
                  <Typography component="span" color='textSecondary'>
                    {link}
                    <CopyToClipboard text={link}>
                      <IconButton style={{padding: '0', height: '1em', outline: "none"}}>
                        <CopyContent style={{height: '0.6em'}}>
                          Copy To Clipboard
                        </CopyContent>
                      </IconButton>
                    </CopyToClipboard>
                </Typography>} />
              </ListItem>
            )}
          </List>
      </Card>
    );
  }
}

export default CandidateCard;
