import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Checkbox from "@material-ui/core/Checkbox";
import DialogTitle from "@material-ui/core/DialogTitle";
import 'assets/css/react-datepicker-customization.css';
import DialogConfirmButton from "../views/Components/Dialog/DialogConfirmButton";

const style = {
  info: {
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.8',
    },
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    margin: '3px 0 0 0',
  },
  dates: {
    float: 'left',
    fontSize: '12px',
    fontWeight: 'bold',
    margin: '0 0 0 2px',
  },
  calendarIcon: {
    margin: '8px',
  },
  calendar: {
    float: 'left',
  },
  options: {
    float: 'left',
    margin: '0 0 0 10px',
    width: '230px',
    '& > label': {
      marginBottom: '0',
      '& > span': {
        '& > strong': {
          borderBottom: '1px dashed #000',
        },
        '& > strong:hover': {
          textShadow: '2px 2px 5px rgba(206,89,55,0.64)',
        },
        color: '#555',
        cursor: 'pointer',
        '& > i': {
          padding: '0 0 0 8px',
          marginTop: '-3px',
        },
        '& > i:hover': {
          opacity: '0.75',
        }
      },
    },
    '& > label:last-child': {
      marginLeft: '28px',
    }
  },
  activePart: {
    textShadow: '2px 2px 5px rgba(206,89,55,0.64)',
  },
  checkbox: {
    padding: '2px',
  },
  closeIcon: {
    cursor: 'pointer',
    color: 'darkgray',
    fontSize: '18px',
  },
  okButton: {
    position: 'absolute',
    right: '25px',
    bottom: '27px',
  }
};

class FilterDatesRange extends React.Component
{
  static format = 'MM/DD/YYYY';

  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    change: PropTypes.func.isRequired,
    past: PropTypes.bool.isRequired,
  };

  state = {
    open: false,
    initialStartDate: null,
    initialEndDate: null,
    startDate: null,
    endDate: null,
    appliedStartDate: null,
    appliedEndDate: null,
    selectedOption: null,
    activePart: 'start',
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const name = nextProps.name;
    const startDateField = name + '__after__';
    const endDateField = name + '__before__';
    const initialStartDate = nextProps.initialValues ? nextProps.initialValues[startDateField] : null;
    const initialEndDate = nextProps.initialValues ? nextProps.initialValues[endDateField] : null;

    let derivedState = null;
    if (initialStartDate !== prevState.initialStartDate) {
      derivedState = {
        startDate: initialStartDate ? moment(initialStartDate)._d : null,
        initialStartDate: initialStartDate,
        appliedStartDate: initialStartDate,
      };
    }

    if (initialEndDate !== prevState.initialEndDate) {
      if (null === derivedState) {
        derivedState = {};
      }
      derivedState['endDate'] = initialEndDate ? moment(initialEndDate)._d : null;
      derivedState['initialEndDate'] = initialEndDate;
      derivedState['appliedEndDate'] = initialEndDate;
    }

    return derivedState;
  }

  onSelect = (value) => {
    let state;
    if (this.state.activePart === 'start') {
      state = {startDate: value, activePart: 'end'};
    } else {
      state = {endDate: value, activePart: 'start'};
    }
    state['selectedOption'] = 'custom';

    this.setState(state);
  };

  removeValue = (activePart) => {
    let state;
    if (activePart === 'start') {
      state = {startDate: null};
    } else {
      state = {endDate: null};
    }

    this.setState(state);
  }

  setActivePart = (activePart) => {
    if (activePart !== 'start' && activePart !== 'end') {
      throw new Error();
    }

    this.setState({activePart: activePart});
  }

  selectOption = (option) => {
    let startDate = new Date();
    let endDate = new Date();

    switch (option) {
      case 'prev_month':
        startDate.setDate(new Date().getDate() - 30);
        break;
      case 'prev_week':
        startDate.setDate(new Date().getDate() - 7);
        break;
      case 'next_week':
        endDate.setDate(new Date().getDate() + 7);
        break;
      case 'next_month':
        endDate.setDate(new Date().getDate() + 30);
        break;
      case 'custom':
        startDate = null;
        endDate = null;
        break;
    }

    this.setState({
      startDate: startDate,
      endDate: endDate,
      selectedOption: option,
      activePart: 'start',
    });
  }

  toggleCalendar = () => {
    console.log('Toggle', this.state.open);
    let newState = {open: !this.state.open,};

    if (this.state.open) {
      newState['startDate'] = this.state.appliedStartDate;
      newState['endDate'] = this.state.appliedEndDate;
    }

    this.setState(newState);
  }

  apply = () => {
    this.props.change(this.props.name + '__after__', this.state.startDate ? moment(new Date(this.state.startDate).toISOString()) : null);
    this.props.change(this.props.name + '__before__', this.state.endDate ? moment(new Date(this.state.endDate).toISOString()) : null);

    this.setState({
      appliedStartDate: this.state.startDate,
      appliedEndDate: this.state.endDate,
      activePart: null !== this.state.endDate ? 'start' : this.state.activePart,
      open: false,
    });
  }

  render() {
    const startDate = (this.state.startDate ? moment(new Date(this.state.startDate).toISOString()).format(FilterDatesRange.format) : FilterDatesRange.format);
    const endDate = (this.state.endDate ? moment(new Date(this.state.endDate).toISOString()).format(FilterDatesRange.format) : FilterDatesRange.format);
    const appliedStartDate = (this.state.appliedStartDate ? moment(new Date(this.state.appliedStartDate).toISOString()).format(FilterDatesRange.format) : FilterDatesRange.format);
    const appliedEndDate = (this.state.appliedEndDate ? moment(new Date(this.state.appliedEndDate).toISOString()).format(FilterDatesRange.format) : FilterDatesRange.format);
    const classes = this.props.classes;
    const initialShownDate = new Date();
    if (this.props.past) {
      initialShownDate.setMonth(initialShownDate.getMonth() - 1);
    }

    return (
      <div className={'col-md-3'}>
        <div>
          <p className={classes.label}>{this.props.label}</p>
          <div className={classes.info} onClick={this.toggleCalendar}>
            <p className={classes.dates}>{appliedStartDate} <br/> {appliedEndDate}</p>
            <i className={'fa fa-calendar ' + classes.calendarIcon} />
          </div>
        </div>
          <Dialog
            onClose={this.toggleCalendar}
            open={this.state.open}
            maxWidth="md"
          >
            <DialogTitle >{this.props.label} <i className={"fa fa-close float-right " + classes.closeIcon} onClick={this.toggleCalendar} /></DialogTitle>
            <DialogContent>
              <div className={classes.calendar}>
                <DatePicker
                  minDate={this.state.activePart === 'end' && this.state.startDate ? this.state.startDate : null}
                  maxDate={this.state.activePart === 'start' && this.state.endDate ? this.state.endDate : null}
                  open={this.state.open}
                  monthsShown={2}
                  onSelect={this.onSelect}
                  dateFormat={FilterDatesRange.format}
                  selected={this.state.startDate || this.state.endDate}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  inline={true}
                  fixedHeight
                  openToDate={initialShownDate}
                  focusSelectedMonth={true}
                />
              </div>
              <div className={classes.options}>
                <Checkbox className={classes.checkbox} checked={this.state.selectedOption === 'prev_month'} onClick={() => this.selectOption('prev_month')} />
                <label>Past 30 Days</label>
                <br />
                <Checkbox className={classes.checkbox} checked={this.state.selectedOption === 'prev_week'} onClick={() => this.selectOption('prev_week')} />
                <label>Past 7 Days</label>
                <br />
                <Checkbox className={classes.checkbox} checked={this.state.selectedOption === 'today'} onClick={() => this.selectOption('today')} />
                <label>Today</label>
                <br />
                {!this.props.past && (
                  <>
                    <Checkbox className={classes.checkbox} checked={this.state.selectedOption === 'next_week'} onClick={() => this.selectOption('next_week')} />
                    <label>Next 7 Days</label>
                    <br />
                    <Checkbox className={classes.checkbox} checked={this.state.selectedOption === 'next_month'} onClick={() => this.selectOption('next_month')} />
                    <label>Next 30 Days</label>
                    <br />
                  </>
                )}
                <Checkbox className={classes.checkbox} checked={this.state.selectedOption === 'custom'} onClick={() => this.selectOption('custom')} />
                <label>Custom</label>
                <br />
                <label>Between:<br />
                  <span>
                    <strong onClick={() => this.setActivePart('start')} className={this.state.activePart === 'start' ? classes.activePart : ''}>{startDate}</strong>
                    {this.state.startDate && (<i className={"fa fa-close text-danger"} onClick={() => this.removeValue('start')} />)}
                  </span>
                  <br />And<br />
                  <span>
                    <strong onClick={() => this.setActivePart('end')} className={this.state.activePart === 'end' ? classes.activePart : ''}>{endDate}</strong>
                    {this.state.endDate && (<i className={"fa fa-close text-danger"} onClick={() => this.removeValue('end')} />)}
                  </span>
                </label>
              </div>
              <DialogConfirmButton className={classes.okButton} onClick={this.apply} text={"OK"} />
            </DialogContent>
          </Dialog>
      </div>
    );
  }
}

export default withStyles(style)(FilterDatesRange);
