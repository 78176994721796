import axios from "axios";
import {VIDEO_ENTRYPOINT} from "../config/entrypoint";
import EventEmitter from "eventemitter3";
import {connectToSocket} from "./liveInterviewSocket";
import {
  getParticipantFromConnectionData,
  getUserNameForParticipant,
  isSipConnection
} from "./liveInterview";
import {getOpenViduSingleton, getNewOpenVidu} from "./OpenViduInstance";
import {isScreenShareStream} from "./streamManager";
import moment from "moment";
import {PlatformUtils} from "openvidu-browser/lib/OpenViduInternal/Utils/Platform";

const GET_TOKEN_URL = VIDEO_ENTRYPOINT + '/live-interview/get-token';

const CLIENT_TYPE_WEBRTC = 'webRtc';
const CLIENT_TYPE_SIP = 'sip';

const SESSION_LOG_TIME_INTERVAL = 15 * 1000

const EVENTS_SESSION_INIT_SUCCESS = 'session_init_success';
const EVENTS_SESSION_INIT_FAIL = 'session_init_fail';
const EVENTS_SESSION_INTERVIEW_STARTED = 'session_interview_started';
const EVENTS_SESSION_INTERVIEW_START_FAIL = 'session_interview_start_fail';
const EVENTS_SESSION_INTERVIEW_ENDED = 'session_interview_ended';
const EVENTS_SESSION_INTERVIEW_END_FAIL = 'session_interview_end_fail';
const EVENTS_SESSION_INTERVIEW_JOINING = 'session_interview_joining';
const EVENTS_SESSION_INTERVIEW_JOINED = 'session_interview_joined';
const EVENTS_SESSION_INTERVIEW_JOIN_FAIL = 'session_interview_join_fail';
const EVENTS_SESSION_INTERVIEW_LEFT = 'session_interview_left';
const EVENTS_SESSION_INTERVIEW_PARTICIPANT_JOINED = 'session_interview_participant_joined';
const EVENTS_SESSION_INTERVIEW_PARTICIPANT_CLOSE_STREAM = 'session_interview_participant_close_stream';
const EVENTS_SESSION_INTERVIEW_PARTICIPANT_START_SPEAKING = 'session_interview_participant_stop_speaking';
const EVENTS_SESSION_INTERVIEW_PARTICIPANT_STOP_SPEAKING = 'session_interview_participant_start_speaking';
const EVENTS_SESSION_INTERVIEW_VIDEO_SESSION_CONNECTED = 'session_interview_video_session_connected';
const EVENTS_SESSION_INTERVIEW_VIDEO_CONNECTION_RECOVERY_FAILED = 'session_interview_video_connection_recovery_failed';
const EVENTS_SESSION_INTERVIEW_SIP_AUDIO_CONNECTED = 'session_interview_sip_audio_connected';
const EVENTS_SESSION_INTERVIEW_SIP_AUDIO_DISCONNECTED = 'session_interview_sip_audio_disconnected';
const EVENTS_SESSION_CHAT_MESSAGE = 'session_chat_message';
const EVENTS_SESSION_CHAT_USER_TYPING = 'session_chat_user_typing';
const EVENTS_SESSION_CHAT_USER_NOT_TYPING = 'session_chat_user_not_typing';
const EVENTS_SESSION_CHAT_USER_JOIN = 'session_chat_user_join';
const EVENTS_SESSION_CHAT_USER_JOINED = 'session_chat_user_joined';
const EVENTS_SESSION_CHAT_USER_LEFT = 'session_chat_user_left';
const EVENTS_SESSION_RECORDING_STARTED = 'session_recording_started';
const EVENTS_SESSION_RECORDING_STOPPED = 'session_recording_stopped';

const instances = {};

class LiveInterviewSession {
  constructor(liveInterviewId) {
    this.initialized = false;
    this.eventEmitter = new EventEmitter();
    this.liveInterviewId = liveInterviewId;
    this.videoSession = null;
    this.screenShareSession = null;
    this.socket = null;
    this.openViduToken = null;
    this.liveInterveiwParticipant = {};
    this.recording = false;
    this.sessionLogInterval = null;
  }

  _requestSession = async () => {
    const response = await axios.post(GET_TOKEN_URL, {interviewId: this.liveInterviewId});
    if (response.data.openViduToken === undefined) {
      throw new Error("Unexpected data received from the server");
    }

    return response;
  }

  _initVideoSession = async () => {
    const response = await this._requestSession();

    const platform = PlatformUtils.getInstance();
    const requiresDomInsertion = platform.isIonicIos() || platform.isIOSWithSafari();
    // Ionic iOS and Safari iOS supposedly require the video element to actually exist inside the DOM
    if (requiresDomInsertion) {
      window.addEventListener('orientationchange', this._fixMissingVideoReference);
    }

    this.openViduToken = response.data.openViduToken;
    this.videoSession = getOpenViduSingleton().initSession();
    this._addVideoSessionListeners(this.videoSession);
  };

  _fixMissingVideoReference = () => {
    //Logic taken from OpenVidu/Publisher.ts:650
    if (!this.videoSession) {
      return;
    }
    const OV = this.videoSession.openvidu;
    if (!OV) {
      return;
    }
    const publisher = OV.publishers && OV.publishers.length > 0 ? OV.publishers[0] : null;
    if (!publisher || !publisher.videoReference) {
      return;
    }

    const videoReferenceNotInDOM = !document.body.contains(publisher.videoReference);
    if (videoReferenceNotInDOM) {
      document.body.appendChild(publisher.videoReference);
    }
  }

  _initSocket = async () => {
    this.socket = await connectToSocket(this.liveInterviewId);
    this._addSocketListeners(this.socket);
  };

  initSession = async () => {
    const initialized = this.initialized;
    try {
      if (!initialized) {
        this.initialized = true;
        await this._initSocket();
        await this._initVideoSession();
      }
      const inits = {
        socket: this.socket,
        videoSession: this.videoSession,
      };

      if (!initialized) {
        this.eventEmitter.emit(EVENTS_SESSION_INIT_SUCCESS, inits);
      }

      return inits;
    } catch (e) {
      this.eventEmitter.emit(EVENTS_SESSION_INIT_FAIL, e);
    }
  };

  setLiveInterviewParticipant = (liveInterviewParticipant) => {
    this.liveInterveiwParticipant = liveInterviewParticipant;
  }

  _addVideoSessionListeners = (videoSession) => {
    videoSession.on('streamCreated', (event) => {
      const stream = event.stream;
      const subscriber = this.videoSession.subscribe(stream, undefined, {
        subscribeToAudio: true,
        subscribeToVideo: true,
      });
      const connectionData = event.stream.connection.data;
      const participant = getParticipantFromConnectionData(connectionData);
      const clientType = isSipConnection(connectionData) ? CLIENT_TYPE_SIP : CLIENT_TYPE_WEBRTC;
      const ownStream = this.liveInterveiwParticipant.userId === participant.userId;

      if (clientType === CLIENT_TYPE_SIP && ownStream) { //Own sip stream
        this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_SIP_AUDIO_CONNECTED, subscriber);
      } else if (isScreenShareStream(stream)) {
        if (!ownStream) { //Do not add any participant for own shared screen
          this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_PARTICIPANT_JOINED, {
            id: participant.userId + '_screen',
            name: getUserNameForParticipant(participant) + ' (Shared Screen)',
            userType: participant.type,
            streamManager: subscriber,
            speaking: false,
            lastSpeakTime: null,
          });
        }
      } else {
        this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_PARTICIPANT_JOINED, {
            id: participant.userId,
            name: getUserNameForParticipant(participant),
            userType: participant.type,
            [clientType === CLIENT_TYPE_SIP ? 'sipAudio' : 'streamManager']: subscriber,
            speaking: false,
            lastSpeakTime: null,
          });
      }

      subscriber.ee.on('streamPropertyChanged', (event) => {
        if (event.changedProperty !== 'audioActive' || !event.newValue) {
          return;
        }
        subscriber.subscribeToAudio(true);
      });
    });

    videoSession.on('streamDestroyed', (event) => {
      const stream = event.stream;
      const connectionData = stream.connection.data;
      const participant = getParticipantFromConnectionData(connectionData);
      const clientType = isSipConnection(connectionData) ? CLIENT_TYPE_SIP : CLIENT_TYPE_WEBRTC;

      if (clientType === CLIENT_TYPE_SIP && this.liveInterveiwParticipant.userId === participant.userId) {
        this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_SIP_AUDIO_DISCONNECTED)
      }
      if (isScreenShareStream(stream)) {
        this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_PARTICIPANT_CLOSE_STREAM, {
          id: participant.userId + '_screen',
          streamType: clientType,
        });
      } else {
        this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_PARTICIPANT_CLOSE_STREAM, {
          id: participant.userId,
          streamType: clientType,
        });
      }
    });

    videoSession.on('sessionDisconnected', (event) => {
      if (event && (
          event.reason === 'forceDisconnectByServer' || event.reason === 'networkDisconnect'
      )) {
        return this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_VIDEO_CONNECTION_RECOVERY_FAILED);
      }

      if (event && event.reason === 'sessionClosedByServer') {
        return this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_ENDED);
      }
    });

    videoSession.on('publisherStartSpeaking', (event) => {
      const participant = getParticipantFromConnectionData(event.connection.data);
      return this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_PARTICIPANT_START_SPEAKING, participant);
    });

    videoSession.on('publisherStopSpeaking', (event) => {
      const participant = getParticipantFromConnectionData(event.connection.data);
      return this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_PARTICIPANT_STOP_SPEAKING, participant);
    })

    videoSession.on('recordingStarted', (event) => {
      this.recording = true;
      return this.eventEmitter.emit(EVENTS_SESSION_RECORDING_STARTED);
    });

    videoSession.on('recordingStopped', (event) => {
      this.recording = false;
      return this.eventEmitter.emit(EVENTS_SESSION_RECORDING_STOPPED);
    });

    this.sessionLogInterval = setInterval(() => this._logSessionState(videoSession), SESSION_LOG_TIME_INTERVAL);
  };

  _addSocketListeners = (socket) => {
    socket.on('socket:error', (message) => this.eventEmitter.emit(EVENTS_SESSION_INIT_FAIL, new Error(message)));
    socket.on('interview:started', () => this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_STARTED));
    socket.on('interview:ended', () => this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_ENDED));
    socket.on('interview:video:left', (userId) => this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_PARTICIPANT_CLOSE_STREAM, {
      id: userId,
      streamType: 'webRtc',
    }));
    socket.on('interview:video:joined', (participant) => {
      this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_PARTICIPANT_JOINED, {
        id: participant.userId,
        name: getUserNameForParticipant(participant),
        userType: participant.type,
        streamManager: undefined,
        speaking: false,
        lastSpeakTime: null,
      });
    });
    socket.on('interview:chat:join', (message) => {
      this.eventEmitter.emit(EVENTS_SESSION_CHAT_USER_JOIN, message);
    });
    socket.on('interview:chat:joined', () => this.eventEmitter.emit(EVENTS_SESSION_CHAT_USER_JOINED));
    socket.on('interview:chat:left', (message) => {
      this.eventEmitter.emit(EVENTS_SESSION_CHAT_USER_LEFT, message);
    });
    socket.on('interview:chat:message', (message) => {
      message.own = (message.userId === this.liveInterveiwParticipant.userId);
      this.eventEmitter.emit(EVENTS_SESSION_CHAT_MESSAGE, message);
    });
    socket.on('interview:chat:typing', (message) => {
      this.eventEmitter.emit(EVENTS_SESSION_CHAT_USER_TYPING, message);
    });
    socket.on('interview:chat:not_typing', (message) => {
      this.eventEmitter.emit(EVENTS_SESSION_CHAT_USER_NOT_TYPING, message);
    })
    socket.on('interview:recording:started', () => {
      this.eventEmitter.emit(EVENTS_SESSION_RECORDING_STARTED);
    })
  };

  _logSessionInfo = (videoSession) => {
    const info = {
      capabilities: {
        publish: videoSession.capabilities?.publish,
        subscribe: videoSession.capabilities?.subscribe,
      },
      connection: {
        connectionId: videoSession.connection?.connectionId,
        creationTime: videoSession.connection?.creationTime,
        data: videoSession.connection?.data,
      },
      options: {
        sessionId: videoSession.options?.sessionId,
        participantId: videoSession.options?.participantId,
      }
    };

    console.debug(`[${moment().format('YYYY-MM-DD HH:mm:ss')}] Session info:`,info);
  }

  _logSessionState = (videoSession) => {
    const state = {
      participants: videoSession.streamManagers.map((streamManager) => {
        let participant = {
          remote: streamManager.remote,
          stream: {
            audioActive: streamManager.stream?.audioActive,
            videoActive: streamManager.stream?.videoActive,
            hasAudio: streamManager.stream?.hasAudio,
            hasVideo: streamManager.stream?.hasVideo,
          },
          tracks: streamManager.stream?.getMediaStream()?.getTracks().map(track => {
            return {
              label: track.label,
              kind: track.kind,
              enabled: track.enabled,
              muted: track.muted,
            };
          })
        }
        if (participant.remote) {
          participant.properties = {
            subscribeToAudio: streamManager.properties?.subscribeToAudio,
            subscribeToVideo: streamManager.properties?.subscribeToVideo,
          }
        } else {
          participant.properties = {
            publishAudio: streamManager.properties?.publishAudio,
            publishVideo: streamManager.properties?.publishVideo,
          }
        }
        return participant;
      }),
    };
    console.debug(`[${moment().format('YYYY-MM-DD HH:mm:ss')}] Session state:`, state);
  }

  endSession = () => {
    //No need to disconnect video session as we do this on backend
    if (this.socket) {
      this.socket.disconnect();
    }

    window.removeEventListener('orientationchange', this._fixMissingVideoReference);

    if (this.sessionLogInterval) {
      clearInterval(this.sessionLogInterval);
      this.sessionLogInterval = null;
    }
  };

  startInterview = () => {
    return new Promise((resolve, reject) => {
      try {
        this.socket.emit('interview:start', async (error) => {
          if (error) {
            this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_START_FAIL, error);
            return reject(error);
          }
          this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_STARTED);
          resolve();
        })
      } catch (error) {
        this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_START_FAIL, error);
        return reject(error);
      }
    });
  };

  joinInterview = (publisher) => {
    return new Promise((resolve, reject) => {
      this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_JOINING);
      this.socket.emit('interview:video:join', async (error) => {
        if (error) {
          this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_JOIN_FAIL, error);
          return reject(error);
        }
        if (this.videoSession) {
          this.videoSession.connect(this.openViduToken)
            .then(() => {
              if (publisher) {
                this.publishStream(publisher);
              }
              this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_VIDEO_SESSION_CONNECTED);
            })
            .catch((e) => console.log('Video session error', e))
        }
        this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_JOINED);
        resolve();
      });
    });
  };

  isReadyToPublishStream = () => {
    return !!this.videoSession && !!this.videoSession.connection;
  }

  publishStream = (publisher) => {
    if (this.isReadyToPublishStream() && publisher && publisher.accessAllowed) {
      return this.videoSession
              .publish(publisher)
              .then(() => this._logSessionInfo(this.videoSession));
    }
    return new Promise(resolve => resolve());
  };

  shareScreen = async (publisher) => {
    const response = await this._requestSession();
    const token = response.data.openViduToken;

    const OV = getNewOpenVidu();
    this.screenShareSession = OV.initSession();

    this.screenShareSession.connect(token).then(() => {
      this.screenShareSession.publish(publisher);
    });
  };

  stopScreenSharing = () => {
    if (this.screenShareSession && this.screenShareSession.connection) {
      this.screenShareSession.disconnect();
    }
  }

  leaveInterview = () => {
    return new Promise((resolve, reject) => {
      if (this.videoSession) {
        this.videoSession.leave(true, 'leaving interview');
      }
      if (this.socket) {
        this.socket.emit('interview:video:leave');
      }
      if (this.sessionLogInterval) {
        clearInterval(this.sessionLogInterval);
        this.sessionLogInterval = null;
      }
      this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_LEFT);
      resolve();
    });
  };

  reconnectToInterview = (publisher) => {
    if (this.videoSession && publisher) {
      this.videoSession.unpublish(publisher);
    }
    return this.leaveInterview()
      .then(() => this._initVideoSession())
      .then(() => this.joinInterview(publisher))
    ;
  };

  endInterview = () => {
    return new Promise((resolve, reject) => {
      try {
        this.socket.emit('interview:end', async (error) => {
          if (error) {
            this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_END_FAIL, error);
            return reject(error);
          }
          this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_ENDED);
          resolve();
        })
      } catch (error) {
        this.eventEmitter.emit(EVENTS_SESSION_INTERVIEW_END_FAIL, error);
        return reject(error);
      }
    });
  };

  sendChatMessage = (message) => {
    this.socket.emit('interview:chat:message', message);
  };

  sendChatUserTyping = () => {
    this.socket.emit('interview:chat:typing');
  };

  on = (event, handler) => {
    this.eventEmitter.on(event, handler);
  };

  off = (event, handler) => {
    this.eventEmitter.off(event, handler);
  };

  removeAllListeners = () => {
    this.eventEmitter.removeAllListeners();
  };
}

function getSessionInstance(liveInterviewId) {
  if (!instances[liveInterviewId]) {
    instances[liveInterviewId] = new LiveInterviewSession(liveInterviewId);
  }
  return instances[liveInterviewId];
}

function removeSessionInstance(liveInterviewId) {
  if (instances[liveInterviewId]) {
    delete instances[liveInterviewId];
  }
}

export {
  getSessionInstance,
  removeSessionInstance,
  EVENTS_SESSION_INIT_SUCCESS,
  EVENTS_SESSION_INIT_FAIL,
  EVENTS_SESSION_INTERVIEW_STARTED,
  EVENTS_SESSION_INTERVIEW_START_FAIL,
  EVENTS_SESSION_INTERVIEW_ENDED,
  EVENTS_SESSION_INTERVIEW_JOINING,
  EVENTS_SESSION_INTERVIEW_JOINED,
  EVENTS_SESSION_INTERVIEW_LEFT,
  EVENTS_SESSION_INTERVIEW_PARTICIPANT_JOINED,
  EVENTS_SESSION_INTERVIEW_PARTICIPANT_CLOSE_STREAM,
  EVENTS_SESSION_INTERVIEW_PARTICIPANT_START_SPEAKING,
  EVENTS_SESSION_INTERVIEW_PARTICIPANT_STOP_SPEAKING,
  EVENTS_SESSION_INTERVIEW_VIDEO_SESSION_CONNECTED,
  EVENTS_SESSION_INTERVIEW_VIDEO_CONNECTION_RECOVERY_FAILED,
  EVENTS_SESSION_INTERVIEW_SIP_AUDIO_CONNECTED,
  EVENTS_SESSION_INTERVIEW_SIP_AUDIO_DISCONNECTED,
  EVENTS_SESSION_RECORDING_STARTED,
  EVENTS_SESSION_RECORDING_STOPPED,
  EVENTS_SESSION_CHAT_MESSAGE,
  EVENTS_SESSION_CHAT_USER_TYPING,
  EVENTS_SESSION_CHAT_USER_NOT_TYPING,
  EVENTS_SESSION_CHAT_USER_JOIN,
  EVENTS_SESSION_CHAT_USER_JOINED,
  EVENTS_SESSION_CHAT_USER_LEFT,
};
