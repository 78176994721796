import React from "react";
import PropTypes from "prop-types";
import StopScreenShareIcon from "@material-ui/icons/StopScreenShare";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import {IconButton} from "@material-ui/core";
import {isScreenShareStreamManager} from "../../utils/streamManager";
import {getOpenViduSingleton} from "../../utils/OpenViduInstance";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import Button from "components/CustomButtons/Button.jsx";

class ScreenShareControl extends React.Component {
  static propTypes = {
    streamManager: PropTypes.object,
    onScreenShared: PropTypes.func,
    onScreenStopped: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      'sharing': false,
      isShowModalAlert: false,
    }
  }

  isScreenShared = () => {
    return isScreenShareStreamManager(this.props.streamManager);
  }

  toggleShareScreen = () => {
    if (this.isScreenShared()) {
      this.stopScreenSharing(this.props.streamManager);
    } else {
      if (!this.props.sessionContainSharedScreen) {
        this.startScreenSharing();
      } else {
        this.toggleShowModal();
      }
    }
  }

  toggleShowModal = () => {
    this.setState({
      isShowModalAlert: !this.state.isShowModalAlert,
    });
  }

  startScreenSharing = () => {
    const OV = getOpenViduSingleton();
    let publisher = OV.initPublisher(null, { videoSource: "screen", audioSource: false });

    publisher.once('accessAllowed', (event) => {
      console.log('screen share access allowed');
      if (this.props.onScreenShared) {
        this.props.onScreenShared(publisher);
      }
      publisher.stream.getMediaStream().getVideoTracks()[0].addEventListener('ended', () => {
        this.stopScreenSharing();
      });
    });

    publisher.once('accessDenied', (event) => {
      console.warn('ScreenShare: Access Denied');
    });
  }

  stopScreenSharing = () => {
    const {streamManager} = this.props;
    if (this.props.onScreenStopped) {
      this.props.onScreenStopped(streamManager);
    }
  }

  render() {
    const {buttonClass} = this.props;
    const screenShared = this.isScreenShared();
    const disabled = this.state.sharing;
    const showModal = this.state.isShowModalAlert;
    return (
      <>
        <IconButton onClick={this.toggleShareScreen} className={buttonClass}>
          {screenShared && !disabled ? <StopScreenShareIcon /> : <ScreenShareIcon /> }
          <span className='video-control-label'>{screenShared && !disabled ? 'Stop Sharing' : 'Share Screen'}</span>
        </IconButton>

        <Dialog
          onClose={() => this.toggleShowModal()}
          open={showModal}
          maxWidth="sm"
        >
          <DialogTitle><i className="fa fa-close float-right comments-modal-close" onClick={() => this.toggleShowModal()} /></DialogTitle>
          <DialogContent>
            <div className={"text-center"}>
              <p className="mt-3">You cannot start screen share while another participant is sharing.</p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button className="mr-3" color="info" onClick={() => this.toggleShowModal()}>Ok</Button>
            </DialogActions>
          </Dialog>
      </>
    )
  }
}

export default ScreenShareControl;
