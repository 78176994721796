import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {list, reset, filter, sort} from '../../actions/LiveInterview/scheduleList';
import filtersStorage from '../../utils/filtersStorage';
import LiveInterviewListTable from "./LiveInterviewListTable";
import ScheduleFilterForm from "./ScheduleFilterForm";
import IsGranted from "../IsGranted";
import {Link} from "react-router-dom";
import {handleListUpdate} from "../../utils/entityList";
import {getRoutePage} from "../../utils/routes";
import FlashMessages from "../../views/Components/FlashMessages";

const defaultFilters = {
  'completedAt__exists__': false,
};

class LiveInterviewSchedule extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    filter: PropTypes.func.isRequired,
    filtersData: PropTypes.object,
    sort: PropTypes.func.isRequired,
    sortData: PropTypes.object
  };

  componentDidMount() {
    const storageName = 'company_live_interview_schedule';
    this.props.filter(filtersStorage.get(storageName, defaultFilters));
    this.props.sort(filtersStorage.getSort(storageName));
  }

  componentDidUpdate(prevProps) {
    handleListUpdate('live_interviews', this.props, prevProps);
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {
    return (
      <div>
        <FlashMessages location={this.props.location} />
        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}
        <IsGranted action='create' object={this.props.retrieved}>
          <p>
            <Link to="create" className="btn btn-primary">
              Schedule New Interview
            </Link>
          </p>
        </IsGranted>
        <ScheduleFilterForm
          initialValues={this.props.filtersData}
          defaultValues={defaultFilters}
          onSubmit={this.props.filter}
          resetFilter={this.props.filterReset}
          parentLoading={this.props.loading}
        />
        <LiveInterviewListTable
          type="scheduled"
          sort={this.props.sort}
          sortData={this.props.sortData}
          retrieved={this.props.retrieved}
          page={getRoutePage(this.props.match)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    retrieved,
    loading,
    error,
    eventSource,
    filtersData,
    sortData,
  } = state.liveinterview.scheduleList;
  return { retrieved, loading, error, eventSource, filtersData, sortData };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource)),
  filter: (filtersData) => dispatch(filter(filtersData)),
  filterReset: () => dispatch(filter(defaultFilters)),
  sort: (sortData) => dispatch(sort(sortData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveInterviewSchedule);
