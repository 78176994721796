import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import { list, reset, filter, sort } from '../../actions/Request/list';
import {handleListUpdate, pagination, renderLinks} from "../../utils/entityList";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Moment from 'react-moment';
import {getRequestUrlByRoomCode} from "../../utils/urls";
import {CopyToClipboard} from "../CopyToClipboard/CopyToClipboard";
import CopyContent from "../Icons/CopyContent";
import IconButton from "@material-ui/core/IconButton";
import filtersStorage from '../../utils/filtersStorage';
import FilterForm from "./FilterForm";
import {SortableTableCells} from "../../utils/filterForm";
import IsGranted from "../IsGranted";
import TableFooter from "@material-ui/core/TableFooter";
import {getRoutePage} from "../../utils/routes";
import { del, success as deleteSuccess } from '../../actions/Request/delete';
// import Dialog from '@material-ui/core/Dialog';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";
import Tooltip from '@material-ui/core/Tooltip';
import AfterCreateInterviewDialog from "./AfterCreateInterviewDialog";
import FlashMessages from "../../views/Components/FlashMessages";

class RequestList extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    filter: PropTypes.func.isRequired,
    filtersData: PropTypes.object,
    sort: PropTypes.func.isRequired,
    sortData: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      flashMessage: null,
      createdInterview: props.location.state ? props.location.state.createdInterview : null,
    };

    props.history.replace({pathname: props.location.pathname});
  }

  handleCloseModal = () => {
    this.setState({createdInterview: null});
  }

  componentDidMount() {
    const storageName = 'company_request';
    const filters = filtersStorage.get(storageName, null);
    const sort = filtersStorage.getSort(storageName);
    if (filters !== null || sort !== null) {
      this.props.filter(filters);
      this.props.sort(sort);
    } else {
      handleListUpdate('requests', this.props);
    }
  }

  componentDidUpdate(prevProps) {
    handleListUpdate('requests', this.props, prevProps);

    if (this.props.deleted && !prevProps.deleted) {
      this.setState({flashMessage: 'On-Demand Interview successfully deleted.'});
    }
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  del = (e, item) => {
    e.preventDefault();
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.del(item);
  };

  render() {
    if (this.props.deleted) {
      this.props.reset();
      handleListUpdate('requests', this.props);
    }

    return (
      <div>
        <FlashMessages location={this.props.location} />
        {this.state.flashMessage && (
          <FlashMessages message={this.state.flashMessage} type={"success"} duration={10000} />
        )}
        {(this.props.loading || this.props.deleteLoading) && (
          <div className="alert alert-info">Loading...</div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}
        {this.props.deleteError && (
          <div className="alert alert-danger">{this.props.deleteError}</div>
        )}

        <IsGranted action='create' object={this.props.retrieved}>
        <p>
          <Link to="create" className="btn btn-primary">
            Create
          </Link>
        </p>
        </IsGranted>

        <FilterForm
          initialValues={this.props.filtersData}
          onSubmit={this.props.filter}
          resetFilter={this.props.filterReset}
          parentLoading={this.props.loading}
        />

        <div style={{'overflowX': 'auto'}}>
          <Table className="table table-responsive table-striped table-hover mb-0">
            <TableHead>
              <TableRow>
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                  'id': 'ID',
                  'title': 'Interview Title',
                  'job.title': 'Job Title',
                  'companyUser.firstName': 'Created By',
                  'roomCode': 'Invite Link',
                  'updatedAt': 'Updated At',
                  'expiresAt': 'Expires At',
                }} />
                <TableCell colSpan={4} className="text-center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.retrieved &&
              this.props.retrieved['hydra:member'].map(item => {
                let requestUrl = getRequestUrlByRoomCode(item['roomCode']);
                return (
                  <TableRow key={item['@id']}>
                    <TableCell scope="row">
                      {renderLinks('requests', item, (request) => request['id'], true)}
                    </TableCell>
                    <TableCell>
                      {renderLinks('requests', item, (request) => request['title'], true)}
                    </TableCell>
                    <TableCell>
                      {renderLinks('jobs', item['job'], (job) => job.title)}
                    </TableCell>
                    <TableCell>
                      {renderLinks('users', item['companyUser'], (companyUser) => companyUser.firstName + ' ' + companyUser.lastName)}
                    </TableCell>
                    <TableCell>
                      {requestUrl}
                      <CopyToClipboard text={requestUrl}>
                        <IconButton style={{'height': '1em'}}>
                          <CopyContent style={{'height': '0.6em'}}>
                            Copy To Clipboard
                          </CopyContent>
                        </IconButton>
                      </CopyToClipboard>
                    </TableCell>
                    <TableCell>{item['updatedAt'] && (<Moment>{item['updatedAt']}</Moment>)}</TableCell>
                    <TableCell>{item['expiresAt'] && (<Moment>{item['expiresAt']}</Moment>)}</TableCell>
                    <TableCell>
                      <Link to={`/company/invitations/send/${encodeURIComponent(item['@id'])}`}>
                        <span className="fa fa-paper-plane" aria-hidden="true" title="Invite"/>
                        <span className="sr-only">Invite</span>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <IsGranted action='show' object={item}>
                      <Link to={`show/${encodeURIComponent(item['@id'])}`}>
                        <span className="fa fa-eye" aria-hidden="true" title="Show"/>
                        <span className="sr-only">Show</span>
                      </Link>
                      </IsGranted>
                    </TableCell>
                    <TableCell>
                      <IsGranted action='edit' object={item}>
                      <Link to={`edit/${encodeURIComponent(item['@id'])}`}>
                        <span className="fa fa-pencil" aria-hidden="true" title="Edit" />
                        <span className="sr-only">Edit</span>
                      </Link>
                      </IsGranted>
                    </TableCell>
                    <TableCell>
                      <IsGranted action='delete' object={item}>
                        <Link to="#" onClick={e => this.del(e, item)}>
                          <span className="fa fa-trash" aria-hidden="true" title="Delete" />
                          <span className="sr-only">Delete</span>
                        </Link>
                      </IsGranted>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>

        <Table className="table table-responsive no-border">
          <TableFooter>
            <TableRow>
              {pagination(this.props.retrieved, getRoutePage(this.props.match))}
            </TableRow>
          </TableFooter>
        </Table>

        {this.state.createdInterview && (
          <AfterCreateInterviewDialog interview={this.state.createdInterview} onClose={this.handleCloseModal} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    retrieved,
    loading,
    error,
    eventSource,
    filtersData,
    sortData,
  } = state.request.list;

  return {
    retrieved,
    loading,
    error,
    eventSource,
    filtersData,
    sortData,
    deleteError: state.request.del.error,
    deleteLoading: state.request.del.loading,
    deleted: state.request.del.deleted
  };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource)),
  filter: (filtersData) => dispatch(filter(filtersData)),
  filterReset: () => dispatch(filter(null)),
  sort: (sortData) => dispatch(sort(sortData)),
  del: item => dispatch(del(item)),
  resetDelete: () => dispatch(deleteSuccess(null)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestList);
