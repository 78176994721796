import {STEP_INFO} from "../../components/OnDemandInterviewPage/OnDemandInterviewSteps";

export function request (state = null, action) {
  switch (action.type) {
    case 'ON_DEMAND_INTERVIEW_PAGE_REQUEST':
      return action.request;
    case 'ON_DEMAND_INTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}

export function invitation (state = null, action) {
  switch (action.type) {
    case 'ON_DEMAND_INTERVIEW_PAGE_INVITATION':
      return action.invitation;
    case 'ON_DEMAND_INTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}

export function step (state = STEP_INFO, action) {
  switch (action.type) {
    case 'ON_DEMAND_INTERVIEW_PAGE_STEP':
      return action.step;
    case 'ON_DEMAND_INTERVIEW_PAGE_RESET':
      return STEP_INFO;
    default:
      return state;
  }
}

export function thinkTimeStarted(state = false, action) {
  switch (action.type) {
    case 'ON_DEMAND_INTERVIEW_PAGE_THINK_TIME_STARTED':
      return action.thinkTimeStarted;
    case 'ON_DEMAND_INTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

export function loading (state = false, action) {
  switch (action.type) {
    case 'ON_DEMAND_INTERVIEW_PAGE_LOADING':
      return action.loading;
    case 'ON_DEMAND_INTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

export function error(state = null, action) {
  switch (action.type) {
    case 'ON_DEMAND_INTERVIEW_PAGE_ERROR':
      return action.error;
    case 'ON_DEMAND_INTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}
