import React from "react";
import {checkBreakpoints} from "../utils/screen";
import {screenSize} from "../actions/Screen/size";
import {connect} from "react-redux";

class ScreenSize extends React.Component {
  componentDidMount() {
    this.calculateScreenSize();
    window.addEventListener('resize', this.calculateScreenSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateScreenSize);
  }

  calculateScreenSize = () => {
    const sizes = checkBreakpoints();
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    this.props.updateScreenSize(sizes);
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = dispatch => ({
  updateScreenSize: sizes => dispatch(screenSize(sizes)),
});

ScreenSize = connect(
  undefined,
  mapDispatchToProps
)(ScreenSize);

export default ScreenSize;
