import React, {Component} from 'react';
import VideoQuestionPlayer from "../VideoQuestionPlayer/VideoQuestionPlayer";
import PropTypes from "prop-types";
import classNames from "classnames";
import HideIcon from "@material-ui/icons/ExpandLess";
import ShowIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import {connect} from "react-redux";
import {
  clearStorageForQuestion,
  setStartTotalTimeForVideoQuestion,
  thinkTimeIsEnabledForQuestion
} from "../../utils/onDemandInterview";

const LONG_TITLE_LENGTH = 120;

class VideoQuestion extends Component {
  static propTypes = {
    videoQuestion: PropTypes.object.isRequired,
    onQuestionStateChange: PropTypes.func,
    playIsDisabled: PropTypes.bool,
    thinkTimeText: PropTypes.string,
    showQuestionTitle: PropTypes.bool,
    onVideoStarted: PropTypes.func,
    onVideoEnded: PropTypes.func,
  };

  static defaultState = {
    showVideo: true,
    showFullTitle: false,
  }

  state = VideoQuestion.defaultState;

  onDemandInterviewResetListener = (message) => {
    if (message.data.hasOwnProperty('type') && message.data.type === 'onDemandInterviewReset') {
      clearStorageForQuestion(this.props.videoQuestion);
    }
  }

  componentDidMount() {
    window.addEventListener('message', this.onDemandInterviewResetListener);
    setStartTotalTimeForVideoQuestion(this.props.videoQuestion);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onDemandInterviewResetListener)
  }

  toggleVideoVisibility = () => {
    const showVideo = !this.state.showVideo;
    this.changeState({showVideo: showVideo});
  }

  toggleTitleVisibility = (e) => {
    e.preventDefault();
    const showFullTitle = !this.state.showFullTitle;
    this.changeState({showFullTitle: showFullTitle});
  }

  changeState = (newState) => {
    this.setState(newState, () => {
      if (this.props.onQuestionStateChange) {
        this.props.onQuestionStateChange(this.state);
      }
    });
  }

  render() {
    const {videoQuestion, screenSize} = this.props;
    const {showVideo} = this.state;
    const smallScreen = !screenSize.md;
    const longTitle = videoQuestion.title && videoQuestion.title.length > LONG_TITLE_LENGTH;
    const thinkTimeIsEnabled = thinkTimeIsEnabledForQuestion(videoQuestion);

    return (
      <Card className={'on-demand-interview-page-video-question-card'}>
        <CardBody className="card-body video-question-card-body">
          <div className="video-card-header">
            <h4 onClick={this.toggleVideoVisibility}>Question {videoQuestion.required && (<span className="text-danger"> *</span>)}</h4>
            {smallScreen && (<IconButton className='video-question-toggle-video-button' onClick={this.toggleVideoVisibility}>
              {showVideo ? <HideIcon /> : <ShowIcon />}
            </IconButton>)}
            {this.props.showQuestionTitle && (
              <div className="question-text-block">
                {smallScreen && longTitle ? (
                  <>
                    <p className={classNames('question-short-title', {
                      'hide-title': this.state.showFullTitle
                    })}>
                      {videoQuestion.title.substr(0, LONG_TITLE_LENGTH)} <a href='#' onClick={this.toggleTitleVisibility}>See more</a>
                    </p>
                    <p className={classNames('question-full-title', {
                      'hide-title': !this.state.showFullTitle
                    })}>
                      {videoQuestion.title} <a href='#' onClick={this.toggleTitleVisibility}>See less</a>
                    </p>
                  </>
                ) : (
                  <p className="question-title">{videoQuestion.title}</p>
                )}
              </div>
            )}
          </div>
          <div className={classNames("question-video-block", {
            'question-video-block-hidden': smallScreen && !showVideo,
            'question-video-think-time': thinkTimeIsEnabled
          })}>
            <VideoQuestionPlayer
              showPlayButtonOnVideo={smallScreen}
              src={videoQuestion.questionVideo.videoUrl}
              thinkTimeText={this.props.thinkTimeText}
              onVideoStarted={this.props.onVideoStarted}
              onVideoEnded={this.props.onVideoEnded}
              playIsDisabled={this.props.playIsDisabled}
              playsInline={this.props.playsInline ? true : false}
            />
          </div>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  screenSize: state.screen.size,
});

VideoQuestion = connect(
  mapStateToProps
)(VideoQuestion);

export default VideoQuestion;
