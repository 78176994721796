const questionBankFilterFormStyle = theme => ({
    expansionPanel: {
        '-webkitBoxShadow': 'none',
        '-mozBoxShadow': 'none',
        boxShadow: 'none',
        marginBottom: '8px',
    },
    expansionPanelSummary: {
        padding: 0,
        margin: 0,
    },
    expansionPanelSummaryContent: {
        margin: 0,
    },
    expansionPanelSummaryExpanded: {
        margin: '0 !important',
        minHeight: '0 !important',
    },
    expansionPanelDetails: {
        padding: '8px',
    },
});

export default questionBankFilterFormStyle;
