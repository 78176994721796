import PropTypes from 'prop-types';
import React from 'react';
import GridContainer from "../Grid/GridContainer.jsx";
import GridItem from "../Grid/GridItem.jsx";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import Checkbox from "@material-ui/core/Checkbox"
import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@material-ui/core/Radio"
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

export default class KnockoutQuestion extends React.Component {

    state = {textareaValue: ''};

    constructor(props) {
        super(props);
        if (props.getValue) {
            props.getValue = this.getValue.bind(this);
        }
        this.form = React.createRef();
    }

    QUESTION_TYPE_SINGLE = 'single';
    QUESTION_TYPE_MULTIPLE = 'multiple';
    QUESTION_TYPE_TEXT = 'text';

    handleChange = () => {
        if (this.props.onChange) {
            this.props.onChange(new FormData(this.form.current).getAll('selected'));
        }
    };

    handleTextEnter = (e) => {
        let text = e.target.value;
        this.setState({textareaValue: text});
        if (this.props.onChange) {
            if ('' === text) {
                text = undefined;
            }

            this.props.onChange(text);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.question.type === this.QUESTION_TYPE_TEXT && this.props.question.id !== prevProps.question.id) {
            this.setState({textareaValue: ''});
        }
    }

    render() {
        let question = this.props.question;
        return (
            <GridContainer className={'knockout-questions-grid-container'}>
                <GridItem xs={12}>
                    <Card>
                        <CardBody>
                            <form ref={this.form}>
                                <FormControl component="fieldset" fullWidth={true}>
                                    <h5>{question.content}{question.required && (<span className="text-danger"> *</span>)}</h5>
                                    {question.type === this.QUESTION_TYPE_SINGLE && (
                                        <RadioGroup name="selected" onChange={this.handleChange}>
                                            {question.knockoutQuestionChoices.map(choice => (
                                                <FormControlLabel
                                                    key={`ko-choice-${choice.id}`}
                                                    control={<Radio/>}
                                                    value={choice['@id'].toString()}
                                                    label={choice.content}
                                                />
                                            ))}
                                        </RadioGroup>
                                    )}
                                    {question.type === this.QUESTION_TYPE_MULTIPLE && (
                                        <>
                                            {question.knockoutQuestionChoices.map(choice => (
                                                <FormControlLabel
                                                    key={`ko-choice-${choice.id}`}
                                                    control={<Checkbox
                                                        onChange={this.handleChange}
                                                        name="selected"
                                                        value={choice['@id'].toString()}
                                                    />}
                                                    label={choice.content}
                                                />
                                            ))}
                                        </>
                                    )}
                                    {question.type === this.QUESTION_TYPE_TEXT && (
                                        <textarea
                                            rows={8}
                                            cols={45}
                                            name="selected"
                                            placeholder="Text"
                                            className={'mt-3'}
                                            maxLength={10000}
                                            onChange={this.handleTextEnter}
                                            value={this.state.textareaValue}
                                        />
                                    )}
                                </FormControl>
                            </form>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }

}

KnockoutQuestion.propTypes = {
    question: PropTypes.object.isRequired,
    onChange: PropTypes.func,
};
