import React, {Component} from "react";
import VideoRecorderUi from "./VideoRecorderUi";
import PropTypes from "prop-types";
import axios from "axios";
import {getVideoResolution} from "../../utils/streamManager";

const RECORDING_START_INDICATION_DELAY = 500; //milliseconds. To avoid cutting off first words of the recording.

const SESSION_RECORDING_STATE_STARTING  = 'recording_starting';
const SESSION_RECORDING_STATE_STARTED   = 'recording_started';
const SESSION_RECORDING_STATE_STOPPING  = 'recording_stopping';
const SESSION_RECORDING_STATE_STOPPED   = 'recording_stopped';

class OpenviduVideoRecorder extends Component {
  static propTypes = {
    showUi: PropTypes.bool.isRequired,
    getSessionUrl: PropTypes.string.isRequired,
    startRecordingUrl: PropTypes.string.isRequired,
    stopRecordingUrl: PropTypes.string.isRequired,
    allowedToRecord: PropTypes.bool,
    startRecordingDisabled: PropTypes.bool,
    streamManager: PropTypes.object,
    onRecordingStarting: PropTypes.func,
    onRecordingStarted: PropTypes.func,
    onRecordingStopping: PropTypes.func,
    onRecorded: PropTypes.func,
    onVideoReset: PropTypes.func,
    onError: PropTypes.func,
    className: PropTypes.string,
    videoText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    initialVideoUrl: PropTypes.string,
    buttonLabels: PropTypes.object,
    additionalButtons: PropTypes.object,
  };

  static defaultProps = {
    showUi: true,
    buttonLabels: {},
    additionalButtons: {},
    allowedToRecord: true,
    className: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      videoUrl: null,
      sessionRecordingState: SESSION_RECORDING_STATE_STOPPED,
    }
    this.ui = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.initialVideoUrl && this.props.initialVideoUrl && !this.state.videoUrl) {
      this.setInitialVideoUrl();
    }
  }

  setInitialVideoUrl = () => {
    if (this.props.initialVideoUrl) {
      this.setState({videoUrl: this.props.initialVideoUrl});
    }
  }

  startRecording = () => {
    return new Promise(async (resolve, reject) => {
      const {streamManager} = this.props;
      if (!streamManager) {
        return reject('You should pass stream manager to recorder before start recording');
      }
      if ([SESSION_RECORDING_STATE_STARTING, SESSION_RECORDING_STATE_STARTED].includes(this.state.sessionRecordingState)) {
        return reject('Recording already started or in starting state');
      }

      this.setState({sessionRecordingState: SESSION_RECORDING_STATE_STARTING});
      try {
        if (this.props.onRecordingStarting) {
          this.props.onRecordingStarting();
        }
        if (!streamManager.session || !streamManager.session.sessionKey) {
          await this.initSession(streamManager);
        }

        streamManager.session.on('recordingStarted', () => {
          streamManager.off('recordingStarted');
          setTimeout(() => {
            if (this.props.onRecordingStarted) {
              this.props.onRecordingStarted();
            }
            resolve(true);
          }, RECORDING_START_INDICATION_DELAY);
        });

        await axios.post(this.props.startRecordingUrl, {
          sessionKey: streamManager.session.sessionKey,
          resolution: getVideoResolution(streamManager),
        });
      } catch (e) {
        console.error('Could not start recording', e);
        this.setState({sessionRecordingState: SESSION_RECORDING_STATE_STOPPED});
        reject(e);
      }
    }).catch(e => console.log('Failed to start recording', e));
  }

  startRecordingWithUi = () => {
    if (!this.props.showUi || !this.ui || !this.ui.current) {
      return false;
    }
    this.ui.current.startRecording();

    return true;
  }

  stopRecording = () => {
    return new Promise(async (resolve, reject) => {
      const {streamManager} = this.props;
      if ([SESSION_RECORDING_STATE_STOPPING, SESSION_RECORDING_STATE_STOPPED].includes(this.state.sessionRecordingState)) {
        return reject('Recording already stopped or in stopping state');
      }
      this.setState({sessionRecordingState: SESSION_RECORDING_STATE_STOPPING});
      try {
        if (this.props.onRecordingStopping) {
          this.props.onRecordingStopping();
        }
        const session = streamManager.session;
        const response = await axios.post(this.props.stopRecordingUrl, {sessionKey: session.sessionKey});
          if (this.props.onRecorded) {
            this.props.onRecorded(response.data);
          }
          resolve(response.data);
      } catch (e) {
        console.error('Could not stop recording', e);
        this.setState({sessionRecordingState: SESSION_RECORDING_STATE_STOPPED});
        reject(e)
      }
    });
  }

  stopRecordingWithUi = () => {
    if (!this.props.showUi || !this.ui || !this.ui.current) {
      return false;
    }
    this.ui.current.stopRecording();

    return true;
  }

  resetVideo = () => {
    this.setState({videoUrl: null});
    if (this.props.onVideoReset) {
      this.props.onVideoReset();
    }
  }

  initSession = async (streamManager) => {
    const response = await axios.post(this.props.getSessionUrl);
    if (!response.data || response.data.openViduToken === undefined || response.data.sessionKey === undefined) {
      throw Error('Unexpected data received from the server: ' + JSON.stringify(response.data));
    }

    const OV = streamManager.openvidu;
    const session = OV.initSession();

    await session.connect(response.data.openViduToken);
    await session.publish(streamManager);
    session.sessionKey = response.data.sessionKey;

    session.on('recordingStarted', () => {
      this.setState({sessionRecordingStarted: SESSION_RECORDING_STATE_STARTED});
    });

    session.on('recordingStopped', () => {
      this.setState({sessionRecordingStarted: SESSION_RECORDING_STATE_STOPPED});
    });

    return true;
  }

  render() {
    const {
      streamManager, buttonLabels, additionalButtons, className, showUi, videoText, allowedToRecord, startRecordingDisabled
    } = this.props;
    return showUi ? (<VideoRecorderUi
        ref={this.ui}
        handleRecordingStart={this.startRecording}
        handleRecordingStop={this.stopRecording}
        handleRecordAgain={this.resetVideo}
        videoUrl={this.state.videoUrl}
        className={className}
        buttonLabels={buttonLabels}
        additionalButtons={additionalButtons}
        streamManager={streamManager}
        videoText={videoText}
        allowedToRecord={allowedToRecord}
        startRecordingDisabled={startRecordingDisabled}
      />) : (null);
  }
}

export default OpenviduVideoRecorder;
