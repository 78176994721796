import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";
import {loadPartnersForSelect} from "../../actions/Partner/forSelect";
import {currentUserHasRole} from "../../utils/auth";

class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  render() {
    const fields = [
      {'name': 'id', 'label': 'ID', 'placeholder': 'ID', 'type': 'number'},
      {'name': 'toAddress', 'label': 'To Address', 'placeholder': 'To Address', 'type': 'text'},
      {'name': 'fromAddress', 'label': 'From Address', 'placeholder': 'From Address', 'type': 'text'},
      {'name': 'createdAt', 'label': 'Created Date', 'placeholder': 'Created Date', 'type': 'datesRange', 'past': true},
    ];

    if (currentUserHasRole('ROLE_SUPER_ADMIN')) {
      fields.push({'name': 'partner', 'label': 'Partner', 'placeholder': 'Partner', 'type': 'selectSearch', 'options': this.props.partners});
    }

    return (
      <RenderFilterForm {...this.props} fields={fields} />
    );
  }
}

const mapStateToProps = state => {
  let partners = state.partner.forSelect.retrieved;

  return { partners };
};

const mapDispatchToProps = dispatch => ({
  loadPartnersForSelect: () => dispatch(loadPartnersForSelect()),
});

FilterForm = reduxForm({
  form: 'filter_emails',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterForm);
