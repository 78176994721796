import React from "react";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import PhoneNumber from "../../views/Components/PhoneNumber";

const style = {
  root: {
    padding: '1em',
    paddingTop: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '5vw',
    fontWeight: '500',
    width: '100%',
  },
};

class VideoInterviewHelpInfo extends React.Component {
  render() {
    const {classes, site} = this.props;
    return (
      <div className={classes.root}>
        <div>
          Call <PhoneNumber phone={site.supportPhone} formattedPhone={site.supportPhoneFormatted} /> or email <a href={`mailto:${site.supportEmail}`}>{site.supportEmail}</a> for help.
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  site: state.siteDetails.siteDetails.retrieved,
});

VideoInterviewHelpInfo = connect(mapStateToProps)(VideoInterviewHelpInfo);

VideoInterviewHelpInfo = withStyles(style)(VideoInterviewHelpInfo);

export default VideoInterviewHelpInfo;
