import React, {Component} from "react";
import Button from "../../../components/CustomButtons/Button";
import PropTypes from "prop-types";

class DialogConfirmButton extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
  }

  static defaultProps = {
    disabled: false,
  }

  render() {
    const {text, onClick, disabled, className} = this.props;

    return (
      <Button
        variant="contained"
        type="submit"
        color="info"
        onClick={onClick}
        disabled={disabled}
        className={className}
      >
        {text}
      </Button>
    )
  }
}

export default DialogConfirmButton;
