import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {list, reset, filter, sort} from '../../actions/Plan/list';
import {handleListUpdate, pagination, renderLinks} from "../../utils/entityList";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import filtersStorage from '../../utils/filtersStorage';
import FilterForm from "./FilterForm";
import {SortableTableCells} from "../../utils/filterForm";
import IsGranted from "../IsGranted";
import TableFooter from "@material-ui/core/TableFooter";
import {getRoutePage} from "../../utils/routes";
import FlashMessages from "../../views/Components/FlashMessages";
import {formatPlanPrice, planLimitationLabels} from "../../utils/plan";
import "./Plan.scss";
import Tooltip from "@material-ui/core/Tooltip";
import {activate, reset as activateReset} from "../../actions/Plan/activate";
import {deactivate, reset as deactivateReset} from "../../actions/Plan/deactivate";
import ActivateDeactivatePlanDialog from "./ActivateDeactivatePlanDialog";
import {CustomSwitch} from "../../views/Components/CusomSwitch";

class PlanList extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    filter: PropTypes.func.isRequired,
    filtersData: PropTypes.object,
    sort: PropTypes.func.isRequired,
    sortData: PropTypes.object
  };

  state = {
    changeStatusPlanModal: null,
    enabled: true,
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const storageName = 'plans';
    const filters = filtersStorage.get(storageName, null);
    const sort = filtersStorage.getSort(storageName);
    if (filters !== null || sort !== null) {
      this.props.filter(filters);
      this.props.sort(sort);
    } else {
      handleListUpdate('plans', this.props);
    }
  }

  componentDidUpdate(prevProps) {
    handleListUpdate('plans', this.props, prevProps);
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
    this.props.activateReset();
    this.props.deactivateReset();
  }

  updatePlanStatus = (plan) => {
    plan['status'] = plan['status'] === 'active' ? 'inactive' : 'active';
    this.toggleModal();

    this.props.activateReset();
    this.props.deactivateReset();

    plan['status'] === 'active' ?
      this.props.activate(plan) :
      this.props.deactivate(plan);
  }

  toggleModal = (user = null) => {
    this.setState({changeStatusPlanModal: user});
  }

  render() {
    return (
      <div>
        <FlashMessages location={this.props.location} />
        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}
        <IsGranted action="create" object={this.props.retrieved}>
          <Link to="create" className="subscription-button-blue float-right">
            Create New Plan
          </Link>
        </IsGranted>
        <h3 className={"admin-plan-form-header"}>Plan List</h3>

        <FilterForm
          initialValues={this.props.filtersData}
          onSubmit={this.props.filter}
          resetFilter={this.props.filterReset}
        />

        <ActivateDeactivatePlanDialog
          open={this.state.changeStatusPlanModal !== null}
          onClose={() => this.toggleModal()}
          onConfirm={() => this.updatePlanStatus(this.state.changeStatusPlanModal)}
          plan={this.state.changeStatusPlanModal}
        />

        <div style={{'overflowX': 'auto'}} className={"admin-plan-list"}>
          <Table className="table table-responsive table-striped table-hover mb-0">
            <TableHead>
              <TableRow>
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                  'id': 'ID',
                  'name': 'Plan Name',
                  'status': 'Status',
                  'code': 'Plan Code',
                  'private': 'Type',
                  'monthlyPrice': 'Price',
                }} />
                <TableCell>Features</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.retrieved &&
              this.props.retrieved['hydra:member'].map(item => (
                <TableRow key={item['@id']}>
                  <TableCell scope="row">
                    {item['id']}
                  </TableCell>
                  <TableCell>{item['name']}</TableCell>
                  <TableCell>{item['status']}</TableCell>
                  <TableCell>{item['code']}</TableCell>
                  <TableCell>{item['private'] ? 'Private' : 'Public'}</TableCell>
                  <TableCell>
                    {item['monthlyPrice'] && (<>{formatPlanPrice(item['monthlyPrice'])} / Month<br/></>)}
                    {item['annualPrice'] && (<>{formatPlanPrice(item['annualPrice'])} / Year</>)}
                  </TableCell>
                  <TableCell className={"pr-2"}>
                    {item['planLimitations'].map(limitation => (
                      <span key={limitation['id']}>
                        <span className={"limitation"}>
                          {planLimitationLabels[limitation['key']]['label']}: {limitation['unlimited'] ? 'Unlimited' : limitation['quantity']}
                          {limitation['hidden'] ? (<span className={"plan-limitation-hidden"}> (Hidden)</span>) : ''}
                        </span>
                        <br/>
                      </span>
                    ))}
                  </TableCell>
                  <TableCell>
                    <IsGranted action="edit" object={item}>
                      <Link to={`edit/${encodeURIComponent(item['@id'])}`} className={"float-left"}>
                        <i className="fa fa-pencil-square-o fa-lg" aria-hidden="true" />
                        <span className="sr-only">Edit</span>
                      </Link>
                    </IsGranted>

                    <Tooltip
                      title={item['status'] === 'active' ? 'Deactivate' : 'Activate'}
                      placement={"left"}
                      disableTouchListener={true}
                      disableFocusListener={true}
                    >
                      <CustomSwitch
                        className={'float-left ml-3'}
                        value={item['status'] === 'active'}
                        onClick={() => this.toggleModal(item)}
                        color="primary"
                        disabled={this.props.activateLoading || this.props.loading}
                        small={true}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <Table className="table table-responsive no-border">
          <TableFooter>
            <TableRow>
              {pagination(this.props.retrieved, getRoutePage(this.props.match))}
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    retrieved,
    eventSource,
    filtersData,
    sortData,
  } = state.plan.list;

  const loading = state.plan.list.loading || state.plan.activate.activateLoading || state.plan.deactivate.deactivateLoading;
  const error = state.plan.list.error || state.plan.activate.activateError || state.plan.deactivate.deactivateError;
  const activated = state.plan.activate.activated;
  const deactivated = state.plan.deactivate.deactivated;

  return { retrieved, loading, error, eventSource, filtersData, sortData, activated, deactivated };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource)),
  filter: (filtersData) => dispatch(filter(filtersData)),
  filterReset: () => dispatch(filter(null)),
  sort: (sortData) => dispatch(sort(sortData)),
  activate: (plan) => dispatch(activate(plan)),
  deactivate: (plan) => dispatch(deactivate(plan)),
  activateReset: (plan) => dispatch(activateReset(plan)),
  deactivateReset: (plan) => dispatch(deactivateReset(plan)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanList);
