import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Link } from 'react-router-dom';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle";
import {connect} from "react-redux";

function Footer({ ...props }) {
  const { classes, fluid, white, site } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white
    });
  var block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white
  });
  let siteDetails = props.siteDetails;

  return (
      <footer className={classes.footer}>
          <div className={container}>
              <p className={classes.copyright}>
                  &copy; {1900 + new Date().getYear()}{" "}
                  <Link to={`/`} className={anchor}>
                      {site.name}
                  </Link>
              </p>
              {siteDetails && (
                  <div className={classes.links}>
                      <List className={classes.list}>
                          <ListItem className={classes.inlineBlock}>
                              <a href={siteDetails.termsOfServiceUrl ? siteDetails.termsOfServiceUrl : `/terms-of-service`}>Terms of Service</a>
                          </ListItem>
                          <ListItem className={classes.inlineBlock}>
                              <a href={siteDetails.privacyPolicyUrl ? siteDetails.privacyPolicyUrl : `/privacy-policy`}>Privacy Policy</a>
                          </ListItem>
                      </List>
                  </div>
              )}
          </div>
      </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool
};

const mapStateToProps = state => ({
    site: state.siteDetails.siteDetails.retrieved,
});

Footer = connect(
    mapStateToProps
)(Footer);

export default withStyles(footerStyle)(Footer);
