import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {filter, list, reset, sort} from '../../actions/LiveInterview/list';
import filtersStorage from '../../utils/filtersStorage';
import FilterForm from "./FilterForm";
import LiveInterviewListTable from "./LiveInterviewListTable";
import {handleListUpdate} from "../../utils/entityList";
import {getRoutePage} from "../../utils/routes";
import FlashMessages from "../../views/Components/FlashMessages";
import {downloadFileFromApi} from "../../utils/dataAccess";
import {applyToUrl} from "../../utils/urls";
import IsGranted from "../IsGranted";

const defaultFilters = {
  'completedAt__exists__': true,
};

class LiveInterviewList extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    filter: PropTypes.func.isRequired,
    filtersData: PropTypes.object,
    sort: PropTypes.func.isRequired,
    sortData: PropTypes.object
  };

  componentDidMount() {
    const storageName = 'company_live_interview';
    this.props.filter(filtersStorage.get(storageName, defaultFilters));
    this.props.sort(filtersStorage.getSort(storageName));
  }

  componentDidUpdate(prevProps) {
    handleListUpdate('live_interviews', this.props, prevProps);
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  exportAsCSV = () => {
    const url = applyToUrl('/live_interviews/export-csv', this.props.filtersData);

    downloadFileFromApi(url, 'live-interviews.csv');
  }

  render() {
    return (
      <div>
        <FlashMessages location={this.props.location} />
        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}

        <IsGranted action="export_live_interviews" object={this.props.retrieved}>
          <button onClick={this.exportAsCSV} className={'btn btn-primary btn-md mb-3'}>
            Export as CSV
          </button>
        </IsGranted>

        <FilterForm
          defaultValues={defaultFilters}
          initialValues={this.props.filtersData}
          onSubmit={this.props.filter}
          resetFilter={this.props.filterReset}
          parentLoading={this.props.loading}
        />
      <LiveInterviewListTable
        type="completed"
        sort={this.props.sort}
        sortData={this.props.sortData}
        retrieved={this.props.retrieved}
        page={getRoutePage(this.props.match)}
      />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    retrieved,
    loading,
    error,
    eventSource,
    filtersData,
    sortData,
  } = state.liveinterview.list;
  return { retrieved, loading, error, eventSource, filtersData, sortData };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource)),
  filter: (filtersData) => dispatch(filter(filtersData)),
  filterReset: () => dispatch(filter(defaultFilters)),
  sort: (sortData) => dispatch(sort(sortData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveInterviewList);
