import React, {Component} from 'react';
import {Field, FieldArray, formValueSelector, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {renderTextField} from "../../utils/form";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import {MERCURE_ENTRYPOINT, VIDEO_ENTRYPOINT} from "../../config/entrypoint";
import {loadPresentersForSelect} from "../../actions/Presenter/forSelect";
import {connect} from "react-redux";
import ProfessionMapping from "../../views/Components/ProfessionMapping";
import SearchableSelect from "../../views/Components/SearchableSelect";
import {loadTeamsForSelect} from "../../actions/Team/forSelect";
import {loadQuestionBankCategoriesForSelect} from "../../actions/QuestionBankCategory/forSelect";
import {currentUserHasRole} from "../../utils/auth";
import Switch from '@material-ui/core/Switch'
import VideoQuestionTimeLimitOption from "../Request/VideoQuestionTimeLimitOption";
import VideoField from "../../views/Components/VideoField";
import {mercureSubscribe as subscribe, normalize} from "../../utils/dataAccess";
import {isVideoNeedToReEncode} from "../../utils/videoEntity";

class QuestionBankForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.videoEventSource = null;
    this.state = {
      showMaxAnswerRecordingRetries: !!(props.initialValues && props.initialValues.maxAnswerRecordingRetries),
    };
    if (props.initialValues && props.initialValues['questionBankVideo']) {
      this.subscribeOnVideoChanges(props.initialValues.questionBankVideo);
    }
  }

  componentDidMount() {
    if (currentUserHasRole('ROLE_COMPANY_USER')) {
      const teamFilters = {};
      const initialValues = this.props.initialValues;
      if (initialValues && initialValues.team) {
        teamFilters.withId = initialValues.team;
      }
      this.props.loadTeamsForSelect(teamFilters);
    }
    this.props.loadCategories();
    this.props.loadPresentersForSelect();
  }

  componentWillUnmount() {
    this.closeEventSource();
  }

  closeEventSource = () => {
    if (this.videoEventSource) {
      this.videoEventSource.close();
      this.videoEventSource = null;
    }
  }

  onVideoChange = (video) => {
    this.closeEventSource();
    if (video) {
      this.subscribeOnVideoChanges(video);
    }
  }

  subscribeOnVideoChanges = (video) => {
    if (!video || typeof video !== 'object' || !video['@id']) {
      return;
    }

    if (!isVideoNeedToReEncode(video)) {
      return;
    }

    this.videoEventSource = subscribe(new URL(MERCURE_ENTRYPOINT), [video['@id']]);
    this.videoEventSource.addEventListener('message', event => {
        const video = normalize(JSON.parse(event.data));
        this.props.change('questionBankVideo', video);
    });
  }

  render() {
    const thinkTimeIsEnabled = this.props.thinkTimeLimitMinutes > 0 || this.props.thinkTimeLimitSeconds > 0;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <GridContainer>
          <GridItem xs={12}>
            <Field
              component={renderTextField}
              name="title"
              type="text"
              label="Title"
              placeholder="Title"
              required={true}
            />
          </GridItem>

          <p className={"video-question-settings"} style={{margin: '10px 0 0 20px'}}>Setting for Video and/or Audio</p>

          <GridItem xs={12} key={0}>
            <VideoQuestionTimeLimitOption
              label={"Think Time"}
              change={this.props.change}
              minValue={1}
              fieldNameMinutes={"thinkTimeLimitMinutes"}
              fieldNameSeconds={"thinkTimeLimitSeconds"}
              currentValueMinutes={this.props.thinkTimeLimitMinutes}
              currentValueSeconds={this.props.thinkTimeLimitSeconds}
              onEnable={() => {
                this.props.change('maxAnswerRecordingRetries', 1);
                this.setState({showMaxAnswerRecordingRetries: true});
              }}
            />
          </GridItem>
          <GridItem xs={12} key={1}>
            <div className={"float-left"}>
              <Switch
                checked={this.state.showMaxAnswerRecordingRetries}
                onChange={(e, value) => {
                  this.setState({showMaxAnswerRecordingRetries: value});
                  this.props.change('maxAnswerRecordingRetries', value ? 3 : null);
                }}
                color="primary"
                disabled={thinkTimeIsEnabled}
              />
            </div>
            <label className={"float-left mt-3" + (this.state.showMaxAnswerRecordingRetries ? ' text-dark' : '')}>Recording Attempts{this.state.showMaxAnswerRecordingRetries && (': ')}</label>
            {this.state.showMaxAnswerRecordingRetries && (
              <div className={"float-left ml-2"} style={{width: 50}}>
                <Field
                  component={renderTextField}
                  name="maxAnswerRecordingRetries"
                  type="number"
                  inputProps={{min: 1, max: 1000, step: 1, style: {textAlign: 'center'}}}
                  label={false}
                  placeholder={""}
                  normalize={v => v ? parseInt(v) : null}
                  disabled={thinkTimeIsEnabled}
                />
              </div>
            )}
          </GridItem>
          <GridItem xs={12} key={3}>
            <VideoQuestionTimeLimitOption
              label={"Time Limit for Response"}
              change={this.props.change}
              fieldNameMinutes={"answerTimeLimitMinutes"}
              fieldNameSeconds={"answerTimeLimitSeconds"}
              currentValueMinutes={this.props.answerTimeLimitMinutes}
              currentValueSeconds={this.props.answerTimeLimitSeconds}
            />
          </GridItem>
          {this.props.teams && (
            <GridItem xs={12}>
              <Field
                component={SearchableSelect}
                name="team"
                label="Team"
                placeholder="Team"
                normalize={val => val === "" ? null : val}
                options={this.props.teams}
              />
            </GridItem>)}
          <GridItem xs={12}>
            <Field
              name="questionBankCategories"
              label="Categories"
              placeholder="Categories"
              isMulti={true}
              required={true}
              component={SearchableSelect}
              options={this.props.categories}
            >
            </Field>
            <Field
              component={SearchableSelect}
              name="presenter"
              label="Presenter"
              placeholder="Select Presenter"
              normalize={val => val === "" ? null : val}
              options={this.props.presenters ? this.props.presenters : []}
            />
            <FieldArray
              label="Profession Mappings"
              name="professionMappings"
              component={ProfessionMapping}
            />
          </GridItem>
          <GridItem xs={12}>
            <Field
              component={VideoField}
              name="questionBankVideo"
              required={true}
              uploadingEndpoint={VIDEO_ENTRYPOINT + '/question-bank/upload'}
              recordingEndpoint='question-bank'
              recordingSocketType='question-bank-video'
              onChange={this.onVideoChange}
            />
          </GridItem>
        </GridContainer>
        <button type="submit" className="btn btn-success float-left mr-3">
          Submit
        </button>
      </form>
    );
  }
}

const selector = formValueSelector('questionBank');

const validate = values => {
  const errors = {};
  if (!values.title) {
    errors.title = 'Please titled a video question';
  }
  if (!values.questionBankCategories || values.questionBankCategories.length === 0) {
    errors.questionBankCategories = 'Please select one or more categories';
  }
  if (!values.questionBankVideo) {
    errors.videoUploadField = 'Please upload or record a video';
  }

  return errors;
};

const mapStateToProps = state => ({
    presenter: selector(state, 'presenter'),
    answerTimeLimitSeconds: selector(state, 'answerTimeLimitSeconds'),
    answerTimeLimitMinutes: selector(state, 'answerTimeLimitMinutes'),
    thinkTimeLimitMinutes: selector(state, 'thinkTimeLimitMinutes'),
    thinkTimeLimitSeconds: selector(state, 'thinkTimeLimitSeconds'),
    presenters: state.presenter.forSelect.retrieved,
    teams: state.team.forSelect.retrieved,
    categories: state.questionbankcategory.forSelect.retrieved,
});

const mapDispatchToProps = dispatch => ({
    loadPresentersForSelect: () => dispatch(loadPresentersForSelect('@id')),
    loadTeamsForSelect: (filters) => dispatch(loadTeamsForSelect('@id', filters)),
    loadCategories: () => dispatch(loadQuestionBankCategoriesForSelect('@id'))
});

QuestionBankForm = reduxForm({
  form: 'questionBank',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(QuestionBankForm);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuestionBankForm);
