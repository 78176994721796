import React from "react";
import {Redirect} from "react-router-dom";
import {removeAuthData} from "../../utils/auth";
import {connect} from "react-redux";
import {resetStore} from "../../actions/Root";
import {loadSiteDetails} from "../../actions/SiteDetails/loadSiteDetails";
import filtersStorage from "../../utils/filtersStorage";

class Logout extends React.Component {
    componentDidMount() {
        removeAuthData();
        filtersStorage.clear();
        this.props.resetState();
        this.props.loadSiteDetails();
    }

    render() {
        return <Redirect to="/login" />;
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    resetState: () => dispatch(resetStore()),
    loadSiteDetails: () => dispatch(loadSiteDetails())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Logout);
