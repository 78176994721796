import { combineReducers } from 'redux';
import list from './list';
import create from './create';
import update from './update';
import del from './delete';
import archive from './archive';
import activate from './activate';
import show from './show';
import forSelect from './forSelect';
import viewPage from "./viewPage";

export default combineReducers({ list, create, update, del, archive, activate, show, forSelect, viewPage });
