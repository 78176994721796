import React, {Component} from 'react';
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {saveAudioDeviceConfig, saveVideoDeviceConfig} from "../../utils/onDemandInterview";
import AudioVideoTestComponent from "../VideoTest/AudioVideoTest";
import {
  changeAudioDevice,
  changeVideoDevice,
  getStreamManagerAudioDeviceId,
  getStreamManagerVideoDeviceId
} from "../../utils/streamManager";
import InputLabel from "@material-ui/core/InputLabel";
import PropTypes from "prop-types";

class AudioVideoTest extends Component {
  state = {
    selectedAudioDevice: 0,
    selectedVideoDevice: 0,
    changingDevice: false,
  };

  static propTypes = {
    streamManager: PropTypes.object,
    audioDevices: PropTypes.object,
    videoDevices: PropTypes.object,
    onTestingStart: PropTypes.func,
    onTestingEnd: PropTypes.func,
    testingStarted: PropTypes.bool,
  };

  static defaultProps = {
    audioDevices: {},
    videoDevices: {},
    testingStarted: false,
  };

  componentDidMount() {
    const {streamManager} = this.props;
    if (streamManager) {
      this.updateCurrentSelectedDevices();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.streamManager && this.props.streamManager) {
      this.updateCurrentSelectedDevices();
    }
  }

  updateCurrentSelectedDevices = () => {
    const {streamManager} = this.props;
    if (streamManager) {
      const videoDeviceId = getStreamManagerVideoDeviceId(streamManager);
      const audioDeviceId = getStreamManagerAudioDeviceId(streamManager);
      this.setState({
        selectedAudioDevice: audioDeviceId,
        selectedVideoDevice: videoDeviceId,
      });
      saveVideoDeviceConfig(videoDeviceId);
      saveAudioDeviceConfig(audioDeviceId);
    }
  };

  onVideoDeviceChange = (e) => {
    const {streamManager} = this.props;
    const deviceId = e.target.value;
    this.setState({changingDevice: true});
    changeVideoDevice(streamManager, deviceId)
      .then(() => {
        saveVideoDeviceConfig(deviceId);
        this.setState({
          selectedVideoDevice: getStreamManagerVideoDeviceId(streamManager),
        });
      })
      .catch(e => this.handleAccessDenied(e))
      .finally(() => this.setState({
        changingDevice: false,
      }));
  }

  onAudioDeviceChange = (e) => {
    const {streamManager} = this.props;
    const deviceId = e.target.value;
    this.setState({changingDevice: true});
    changeAudioDevice(streamManager, deviceId)
      .then(() => {
        saveAudioDeviceConfig(deviceId);
        this.setState({
          selectedAudioDevice: getStreamManagerAudioDeviceId(streamManager),
        });
      })
      .catch(e => this.handleAccessDenied(e))
      .finally(() => this.setState({
        changingDevice: false,
      }));
  }

  handleAccessDenied = (e) => {
    const {streamManager} = this.props;
    if (streamManager) {
      streamManager.emitEvent('accessDenied', [e]);
      streamManager.accessDenied = true;
      streamManager.accessAllowed = false;
      this.closeStreamManager();
    }

    this.setState({
      streamManager: streamManager,
      audioDevices: {},
      selectedAudioDevice: 0,
      videoDevices: {},
      selectedVideoDevice: 0,
    });
  }

  render() {
    const {testingStarted} = this.props;
    const {selectedAudioDevice, selectedVideoDevice, changingDevice} = this.state;
    const videoDevices = Object.values(this.props.videoDevices);
    const audioDevices = Object.values(this.props.audioDevices);
    return (
      <GridContainer className={"on-demand-interview-page-test-component-grid"}>
        <GridItem sm={8} xs={12} className="on-demand-interview-page-test-video-container">
          <div className={"on-demand-interview-page-audio-video-test-component-container"}>
            <AudioVideoTestComponent
              streamManager={this.props.streamManager}
              autoStart={false}
              onTestingStart={this.props.onTestingStart}
              onTestingEnd={this.props.onTestingEnd}
              allowLocalRecording={true}
            />
          </div>
        </GridItem>
        <GridItem sm={4} xs={12} className="">
          <div className='on-demand-interview-page-test-device-select-container'>
            <InputLabel htmlFor={'camera_select'}>Choose your camera:</InputLabel><br/>
            <Select
              id={'camera_select'}
              name="camera"
              value={selectedVideoDevice}
              onChange={this.onVideoDeviceChange}
              disabled={testingStarted || changingDevice || videoDevices.length === 0}
            >
              {videoDevices.length === 0 ? (
                <MenuItem key={'no_video_devices'} value={0}>No Video Devices</MenuItem>
              ) : (
                videoDevices.map(({id, label}) => (
                  <MenuItem key={id} value={id}>{label}</MenuItem>
                ))
              )}
            </Select>
          </div>
          <div className='on-demand-interview-page-test-device-select-container'>
            <InputLabel htmlFor={'microphone_select'}>Choose your audio device:</InputLabel><br/>
            <Select
              id={'microphone_select'}
              name="microphone"
              value={selectedAudioDevice}
              onChange={this.onAudioDeviceChange}
              disabled={testingStarted || changingDevice || audioDevices.length === 0}
            >
              {audioDevices.length === 0 ? (
                <MenuItem key={'no_audio_device'} value={0}>No Audio Devices</MenuItem>
              ) : (
                audioDevices.map(({id, label}) => (
                  <MenuItem key={id} value={id}>{label}</MenuItem>
                ))
              )}
            </Select>
          </div>
        </GridItem>
      </GridContainer>
    );
  }
}

export default AudioVideoTest;
