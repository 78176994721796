import React, {Component} from 'react';
import Button from "../CustomButtons/Button";
import GridItem from "../Grid/GridItem";
import PropTypes from "prop-types";

class VideoQuestionsTextIntro extends Component {
  static propTypes = {
    onSkip: PropTypes.func.isRequired,
  };

  render() {
    const {onSkip} = this.props;
    return (
      <GridItem xs={12} style={{textAlign: "center"}} className={'on-demand-interview-page-video-question-text-intro'}>
        <p style={{textAlign: "center", marginTop: "1rem"}}>
          After you continue, you will be presented with a series of video questions.
          To record your answer, click on "Start Recording" button and wait for the countdown to appear at the top.
          When you are done recording, click "Stop Recording".
        </p>
        <Button onClick={onSkip} className={'start-video-question-button'}>
          Next
        </Button>
      </GridItem>
    );
  }
}

export default VideoQuestionsTextIntro;
