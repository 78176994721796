import {combineReducers} from "redux";

function loggedIn(state = null, action) {
  switch (action.type) {
    case 'ON_DEMAND_INTERVIEW_PAGE_AUTH_LOGGED_IN':
      return action.loggedIn;
    case 'ON_DEMAND_INTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}

function loggingIn(state = false, action) {
  switch (action.type) {
    case 'ON_DEMAND_INTERVIEW_PAGE_AUTH_LOGGING_IN':
      return action.loggingIn;
    case 'ON_DEMAND_INTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

function candidateProfile(state = null, action) {
  switch (action.type) {
    case 'ON_DEMAND_INTERVIEW_PAGE_AUTH_CANDIDATE_PROFILE':
      return action.candidateProfile;
    case 'ON_DEMAND_INTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}

function error(state = null, action) {
  switch (action.type) {
    case 'ON_DEMAND_INTERVIEW_PAGE_AUTH_ERROR':
      return action.error;
    case 'ON_DEMAND_INTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}

function candidateProfileLoading(state = false, action) {
  switch (action.type) {
    case 'ON_DEMAND_INTERVIEW_PAGE_CANDIDATE_PROFILE_LOADING':
      return action.loadingStatus;
    default:
      return state;
  }
}

export default combineReducers({
  candidateProfile, candidateProfileLoading, loggedIn, loggingIn, error
});
