import {DEFAULT_NAMES} from "../../components/Review/DefaultCriteriaNames";
import {isEmptyObject} from "../../utils/object";
import {fetch} from "../../utils/dataAccess";
import {SubmissionError} from "redux-form";

export function updateReviewScales(currentValues, newValues) {
  return async dispatch => {
    let updateValues = {};
    dispatch(error(null));
    dispatch(success(false));
    dispatch(loading(true));
    Object.keys(DEFAULT_NAMES).forEach(criteriaNumber => {
      const newValue = newValues[criteriaNumber];
      const newName = (newValue) ? newValue.name : null;
      const currentValue = currentValues[criteriaNumber];
      const currentName = (currentValue) ? currentValue.name : null;
      const defaultName = DEFAULT_NAMES[criteriaNumber];

      if (
        newName !== currentName && (
          newName !== defaultName || currentName
        )
      ) {
        updateValues[criteriaNumber] = {name: newName};
      }
    });

    if (!isEmptyObject(updateValues)) {
      try {
        const response = await fetch(
          'review_scales',
          {
            method: 'PUT',
            data: {
              criteria: updateValues
            }
          }
        )
        const reviewScales = response.data;
        if (reviewScales) {
          dispatch(updated(reviewScales.criteria));
        }
      } catch (e) {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw e;
        } else {
          dispatch(error(e.message));
        }
        return;
      }
    }
    dispatch(success(true));
    dispatch(loading(false));
  };
}

function error(error) {
  return { type: 'REVIEW_SCALES_UPDATE_ERROR', error };
}

function loading(loading) {
  return { type: 'REVIEW_SCALES_UPDATE_LOADING', loading };
}

function success(success) {
  return { type: 'REVIEW_SCALES_UPDATE_SUCCESS', success };
}

function updated(updated) {
  return { type: 'REVIEW_SCALES_UPDATE_UPDATED', updated };
}

export function reset() {
  return { type: 'REVIEW_SCALES_UPDATE_RESET' };
}
