import React, { Component } from 'react';
import {Field, FieldArray, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {renderCheckbox, renderTextField} from "../../utils/form";
import ProfessionMapping from "../../views/Components/ProfessionMapping";
import CandidateResumeField from "./CandidateResumeField";

class UserProfileForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={renderTextField}
          name="firstName"
          type="text"
          placeholder="First Name"
          required={true}
        />
        <Field
          component={renderTextField}
          name="lastName"
          type="text"
          placeholder="Last Name"
          required={true}
        />
        <Field
          component={renderTextField}
          name="email"
          type="text"
          placeholder="Email"
          required={true}
        />
        <Field
          component={renderCheckbox}
          name="receiveNotifications"
          label="Receive Email Notifications"
        />
        <Field
          component={renderTextField}
          name="phoneNumber"
          label="Mobile Number"
          type="text"
          placeholder="Mobile Number"
          required={true}
        />
        <FieldArray
          label="Profession"
          name="professionMappings"
          component={ProfessionMapping}
          required={true}
          mappingCardTitle='Profession'
          addMappingText='Add Profession'
        />
        <CandidateResumeField />
        <button type="submit" className="btn btn-success">
          Submit
        </button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'candidateProfileForm'
})(UserProfileForm);
