import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { list, reset, filter, sort } from '../../actions/InvitationInterviewStatus/list';
import {handleListUpdate, pagination, renderLinks} from "../../utils/entityList";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import filtersStorage from '../../utils/filtersStorage';
import FilterForm from "./FilterForm";
import {SortableTableCells} from "../../utils/filterForm";
import IsGranted from "../IsGranted";
import TableFooter from "@material-ui/core/TableFooter";
import {getRoutePage} from "../../utils/routes";
import FlashMessages from "../../views/Components/FlashMessages";

class InvitationInterviewStatusList extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    filter: PropTypes.func.isRequired,
    filtersData: PropTypes.object,
    sort: PropTypes.func.isRequired,
    sortData: PropTypes.object
  };

  componentDidMount() {
    const storageName = 'invitation_interview_statuses';
    const filters = filtersStorage.get(storageName, null);
    const sort = filtersStorage.getSort(storageName);
    if (filters !== null || sort !== null) {
      this.props.filter(filters);
      this.props.sort(sort);
    } else {
      handleListUpdate('invitation_interview_statuses', this.props);
    }
  }

  componentDidUpdate(prevProps) {
    handleListUpdate('invitation_interview_statuses', this.props, prevProps);
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {
    return (
      <div>
        <FlashMessages location={this.props.location} />
        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}

        <IsGranted action="create" object={this.props.retrieved}>
        <p>
          <Link to="create" className="btn btn-primary">
            Create
          </Link>
        </p>
        </IsGranted>

        <FilterForm
          initialValues={this.props.filtersData}
          onSubmit={this.props.filter}
          resetFilter={this.props.filterReset}
        />

        <Table className="table table-responsive table-striped table-hover">
          <TableHead>
            <TableRow>
              <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                'id': 'ID',
                'displayedInterviewStatus': 'Displayed Interview Status',
                'displayedInterviewProgressbarStatus': 'Displayed Interview Progressbar Status',
                'jobStatus': 'Job Status',
                'requestStatus': 'Request Status',
                'invitationStatus': 'Invitation Status',
                'invitationExpired': 'Invitation Expired',
                'invitationArchived': 'Invitation Archived',
                'interviewStarted': 'Interview Started',
                'interviewCompleted': 'Interview Completed',
              }} />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.retrieved &&
              this.props.retrieved['hydra:member'].map(item => (
                <TableRow key={item['@id']}>
                  <TableCell scope="row">
                  {renderLinks('invitation_interview_statuses', item, (invitationInterviewStatus) => invitationInterviewStatus['id'], true)}
                  </TableCell>
                  <TableCell>{item['displayedInterviewStatus']}</TableCell>
                  <TableCell>{item['displayedInterviewProgressbarStatus']}</TableCell>
                  <TableCell>{item['jobStatus']}</TableCell>
                  <TableCell>{item['requestStatus']}</TableCell>
                  <TableCell>{item['invitationStatus']}</TableCell>
                  <TableCell>{item['invitationExpired'] ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{item['invitationArchived'] ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{item['interviewStarted'] ? 'Yes' : 'No' }</TableCell>
                  <TableCell>{item['interviewCompleted'] ? 'Yes' : 'No'}</TableCell>
                  <TableCell>
                    <IsGranted action="show" object={item}>
                    <Link to={`show/${encodeURIComponent(item['@id'])}`}>
                      <span className="fa fa-eye" aria-hidden="true" />
                      <span className="sr-only">Show</span>
                    </Link>
                    </IsGranted>
                  </TableCell>
                  <TableCell>
                    <IsGranted action="edit" object={item}>
                    <Link to={`edit/${encodeURIComponent(item['@id'])}`}>
                      <span className="fa fa-pencil" aria-hidden="true" />
                      <span className="sr-only">Edit</span>
                    </Link>
                    </IsGranted>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              {pagination(this.props.retrieved, getRoutePage(this.props.match))}
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    retrieved,
    loading,
    error,
    eventSource,
    filtersData,
    sortData,
  } = state.invitationInterviewStatus.list;
  return { retrieved, loading, error, eventSource, filtersData, sortData };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource)),
  filter: (filtersData) => dispatch(filter(filtersData)),
  filterReset: () => dispatch(filter(null)),
  sort: (sortData) => dispatch(sort(sortData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitationInterviewStatusList);
