import {formatPrice, PREVIEW_PERIOD_ANNUAL} from "../../../utils/plan";
import {generateLimitationLabel} from "../../../views/Subscription/PlanCard";
import React from "react";

function UpgradeSummarySidebar({plan, period, noChargeSelected, upgradeSummary}) {
  let proratedAdjustment = 0;
  let subtotal = 0;
  let tax = 0;
  let total = 0;

  if (!noChargeSelected && upgradeSummary) {
    proratedAdjustment = upgradeSummary['prorated_adjustment'];
    subtotal = upgradeSummary['subtotal'];
    tax = upgradeSummary['tax'];
    total = upgradeSummary['total'];
  }

  let planPrice = formatPrice(0);
  if (!noChargeSelected) {
    planPrice = period === PREVIEW_PERIOD_ANNUAL ? formatPrice(plan['annualPrice']) : formatPrice(plan['monthlyPrice']);
  }

  return (
    <div className={"partner-company-payment-method-select-upgrade-summary"}>
      <h4>Upgrade Summary</h4>
      <hr />
      {upgradeSummary ? (
        <>
          <div className={"plan-name"}>{plan['name']} (bill {period === PREVIEW_PERIOD_ANNUAL ? 'annually' : 'monthly'}) <span>{planPrice}</span></div>
          <div className={"plan-card-limitations"}>
            {plan['planLimitations'].map((planLimitation, i) => (
              <div className={"plan-card-limitation"} key={i}>
                <i className={"fa fa-check"} />
                <span>{generateLimitationLabel(planLimitation)}</span>
              </div>
            ))}
          </div>
          {!!proratedAdjustment && (
            <>
              <div className={"price-details"}>Prorated Adjustment <span>{formatPrice(proratedAdjustment)}</span></div>
              <div className={"price-details"}>Subtotal <span>{formatPrice(subtotal)}</span></div>
            </>
          )}
          <div className={"price-details"}>Tax (10%) <span>{formatPrice(tax)}</span></div>
          <hr />
          <div className={"price-details total"}>Amount Due Today <span>{formatPrice(total)}</span></div>
          <div className={"price-details next-payment-date"}>Your next renewal date will be {upgradeSummary['next_payment_date']} </div>
        </>
      ): (
        <>Loading...</>
      )}
    </div>
  );
}

export default UpgradeSummarySidebar;
