import { combineReducers } from 'redux';

export function error(state = null, action) {
  switch (action.type) {
    case 'REQUESTTEMPLATE_SHOW_ERROR':
      return action.error;

    case 'REQUESTTEMPLATE_SHOW_MERCURE_DELETED':
      return `${action.retrieved['@id']} has been deleted by another user.`;

    case 'REQUESTTEMPLATE_SHOW_RESET':
      return null;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'REQUESTTEMPLATE_SHOW_LOADING':
      return action.loading;

    case 'REQUESTTEMPLATE_SHOW_RESET':
      return false;

    default:
      return state;
  }
}

export function retrieved(state = null, action) {
  switch (action.type) {
    case 'REQUESTTEMPLATE_SHOW_SUCCESS':
    case 'REQUESTTEMPLATE_SHOW_MERCURE_MESSAGE':
      return action.retrieved;

    case 'REQUESTTEMPLATE_SHOW_RESET':
      return null;
    case 'REQUESTTEMPLATE_INTROVIDEO_SHOW_MERCURE_MESSAGE':
      return {
        ...state,
        request: {
          ...state.request,
          requestIntroVideo: action.retrieved,
        }
      };
    case 'REQUESTTEMPLATE_OUTROVIDEO_SHOW_MERCURE_MESSAGE':
      return {
        ...state,
        request: {
          ...state.request,
          requestOutroVideo: action.retrieved,
        }
      };
    case 'REQUESTTEMPLATE_QUESTION_VIDEO_SHOW_MERCURE_MESSAGE':
      const retrieved = action.retrieved;
      const questions = state.request.questions.map(question => {
        if (question && question.questionVideo && question.questionVideo['videoOrigin'] === retrieved['videoOrigin']) {
          return {...question, questionVideo: retrieved};
        } else {
          return question;
        }
      });
      return {
        ...state,
        request: {
          ...state.request,
          questions: questions,
        }
      };
    default:
      return state;
  }
}

export function eventSource(state = null, action) {
  switch (action.type) {
    case 'REQUESTTEMPLATE_SHOW_MERCURE_OPEN':
      return action.eventSource;

    case 'REQUESTTEMPLATE_SHOW_RESET':
      return null;

    default:
      return state;
  }
}

export default combineReducers({ error, loading, retrieved, eventSource });
