import React, {Component} from "react";
import PropTypes from "prop-types";
import {
  DialogContent as MaterialDialogContent,
  DialogContentText as MaterialDialogTextContent
} from "@material-ui/core";

const defaultProperties = {

};

class DialogContent extends Component {
  static propTypes = {
    id: PropTypes.string,
    content: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    properties: PropTypes.object,
    className: PropTypes.string,
  };

  static defaultProps = {
    properties: {},
  }

  render() {
    const {id, className, properties, children} = this.props;
    const showDialogContent = !!children;
    return (
      showDialogContent ? (
        <MaterialDialogContent
          id={id}
          className={'io-dialog-content ' + className}
          {...Object.assign({}, defaultProperties, properties)}
        >
          {typeof children === 'string' ? (
            <MaterialDialogTextContent>
              {children}
            </MaterialDialogTextContent>
          ) : (children)}
        </MaterialDialogContent>
      ) : null
    );
  }
}

export default DialogContent;
