import React from "react";
import { Card, createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
import {renderCheckbox, renderFromHelper} from "../../utils/form";
import Button from "../CustomButtons/Button";
import {NoteAdd, Clear} from "@material-ui/icons"
import IconButton from "@material-ui/core/IconButton";
import CardHeader from "@material-ui/core/CardHeader";
import {Field} from "redux-form";
import SearchableSelect from "../../views/Components/SearchableSelect";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import {loadCompanyUsersForSelect, reset} from "../../actions/CompanyUser/forSelect";
import {connect} from "react-redux";

const redAsterisks = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "red",
        "&$error": "red",
      }
    }
  }
});

const styles = {
  card: {
    marginBottom: '1em',
    overflow: 'visible',
  },
  cardHeader: {
    paddingBottom: '10px',
  },
  cardTitle: {
    padding: '12px',
  },
  cardAction: {
    width: '48px',
  },
  gridItem: {
    lineHeight: '20px',
  },
  checkbox: {
    padding: '8px',
  },
  addButton: {
    marginBottom: '1em',
  },
  selectContainer: {
    marginBottom: 0,
  }
};

class TeamCompanyUsers extends React.Component {
  componentDidMount() {
    this.props.retrieveCompanyUsers();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  getUsersForSelect = (fieldUser) => {
    const { companyUsers, fields } = this.props;
    const selectedUsers = fields.getAll().map((teamCompanyUser) => teamCompanyUser.companyUser);
    return companyUsers ? companyUsers.filter(({value}) => {
      return value === fieldUser || !selectedUsers.includes(value);
    }): [];
  };

  renderFields = (name, teamCompanyUser) => {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={9} className={classes.gridItem}>
          <Field
            label={null}
            containerClass={classes.selectContainer}
            component={SearchableSelect}
            name={`${name}.companyUser`}
            placeholder="Select User"
            options={this.getUsersForSelect(teamCompanyUser.companyUser)}
          />
        </GridItem>
        <GridItem xs={3} className={classes.gridItem}>
          <Field
            component={renderCheckbox}
            name={`${name}.isManager`}
            type="checkbox"
            label="Team Manager"
            classes={{
              checkbox: {
                root: classes.checkbox
              }
            }}
          />
        </GridItem>
      </GridContainer>
    )
  };

  render() {
    const { classes, fields, label, required, meta: {error} } = this.props;
    return (
      <div>
        { label && (
          <>
            <MuiThemeProvider theme={redAsterisks}>
              <InputLabel required={required}>{label}</InputLabel><br/>
            </MuiThemeProvider>
          </>
        )}
        {fields.map((name, index) => {
          const teamCompanyUser = fields.get(index);
          return (
            <Card key={index} className={classes.card}>
              <CardHeader
                disableTypography={true}
                className={classes.cardHeader}
                classes={{
                  action: classes.cardAction,
                }}
                subheader={this.renderFields(name, teamCompanyUser)}
                action={
                  (required && parseInt(index) !== 0) ? (
                    <IconButton
                      onClick={() => fields.remove(index)}
                    ><Clear>Remove User</Clear></IconButton>
                  ) : (<></>)
                }
              />
            </Card>
          )
        })}
        {renderFromHelper(true, error)}
        <Button className={classes.addButton} color="success" onClick={() => fields.push({})}>
          <NoteAdd/> Add User
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companyUsers: state.companyUser.forSelect.retrieved,
});

const mapDispatchToProps = (dispatch) => ({
  retrieveCompanyUsers: () => dispatch(loadCompanyUsersForSelect()),
  reset: () => dispatch(reset()),
});

TeamCompanyUsers = connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamCompanyUsers);

export default withStyles(styles)(TeamCompanyUsers);
