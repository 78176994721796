import {combineReducers} from 'redux';

export function retrieved(state = false, action) {
    switch (action.type) {
        case 'COMPANY_USERS_FOR_SELECT_RETRIEVED':
            return action.retrieved;
        case 'COMPANY_USERS_FOR_SELECT_RESET':
            return false;
        default:
            return state;
    }
}

export function loading(state = false, action) {
    switch (action.type) {
        case 'COMPANY_USERS_FOR_SELECT_LOADING':
            return action.loading;
        case 'COMPANY_USERS_FOR_SELECT_RESET':
            return false;
        default:
            return state;
    }
}

export function error(state = null, action) {
    switch (action.type) {
        case 'COMPANY_USERS_SELECT_ERROR':
            return action.error;
        case 'COMPANY_USERS_FOR_SELECT_RESET':
            return null;
        default:
            return state;
    }
}

export default combineReducers({retrieved, error, loading});
