import React from "react";
import "./Style.scss";

export function AddPaymentMethodCard({onClick}) {
  return (
    <div className={"add-payment-method"} onClick={onClick}>
      <p>Add new payment method</p>
      <div className={"plus"}>+</div>
    </div>
  );
}
