import React from 'react';
import Dialog from "../../../views/Components/Dialog/Dialog";
import PropTypes from 'prop-types';
import TransactionIcon from "../../../views/Subscription/TransactionIcon";

function PaymentFailModal({handleClose}) {
  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        title={"Your Payment Failed"}
        dialogProperties={{subscriptionTheme: true}}
      >
        <div className={"payment-failed-modal"}>
          <TransactionIcon status={"failed"} />
          <p>There was an issue charging your card. Please use a different card or try again.</p>
          <button onClick={handleClose} className={"subscription-modal-button-green"}>OK</button>
        </div>
      </Dialog>
    </div>
  );
}

PaymentFailModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default PaymentFailModal;
