import { SubmissionError } from 'redux-form';
import {fetch, normalizeAttributes} from '../../utils/dataAccess';
import {setEndOfDay, setQuestionAndChoicePositions} from "./util";
import {saveAsTemplate} from "./saveAsTemplate";
import { reset as resetJobsForSelect } from "./../Job/forSelect";

export function error(error) {
  return { type: 'REQUEST_CREATE_ERROR', error };
}

export function loading(loading) {
  return { type: 'REQUEST_CREATE_LOADING', loading };
}

export function success(created) {
  return { type: 'REQUEST_CREATE_SUCCESS', created };
}

export function create(values) {
  if (values.submitType && values.submitType === 'saveAsTemplate') {
    return dispatch => {
      dispatch(saveAsTemplate( values))
    }
  }

  values = normalizeAttributes(values, ['requestIntroVideo', 'requestOutroVideo']);
  values['expiresAt'] = setEndOfDay(values['expiresAt']);

  return dispatch => {
    dispatch(loading(true));
    setQuestionAndChoicePositions(values);
    return fetch('requests', { method: 'POST', data: values })
      .then(response => {
        dispatch(loading(false));
        dispatch(success(response.data));
      })
      .catch(e => {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw e;
        }

        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(error(null));
    dispatch(success(null));
    dispatch(resetJobsForSelect());
  };
}
