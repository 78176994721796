import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {filter, list, reset, sort} from '../../actions/CandidateOneWayInterviewInvitation/list';
import filtersStorage from '../../utils/filtersStorage';
import FilterForm from "./FilterForm";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {SortableTableCells} from "../../utils/filterForm";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {handleListUpdate, pagination, renderLinks} from "../../utils/entityList";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import TableFooter from "@material-ui/core/TableFooter";
import Table from "@material-ui/core/Table";
import InvitationInterviewLink from "./InvitationInterviewLink";
import {getRoutePage} from "../../utils/routes";

const defaultFilters = {
  'completedAt__exists__': true,
};

class CandidateOneWayInterviewInvitationList extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    filter: PropTypes.func.isRequired,
    filtersData: PropTypes.object,
    sort: PropTypes.func.isRequired,
    sortData: PropTypes.object
  };

  componentDidMount() {
    const storageName = 'candidate_one_way_interview_invitation';
    this.props.filter(filtersStorage.get(storageName, defaultFilters));
    this.props.sort(filtersStorage.getSort(storageName));
  }

  componentDidUpdate(prevProps) {
    handleListUpdate('invitations', this.props, prevProps);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    return (
      <div>
        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}
        <FilterForm
          defaultValues={defaultFilters}
          initialValues={this.props.filtersData}
          onSubmit={this.props.filter}
          resetFilter={this.props.filterReset}
        />

        <div style={{'overflowX': 'auto'}}>
          <Table className="table table-responsive table-striped table-hover mb-0">
            <TableHead>
              <TableRow>
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                  'id': 'ID',
                  'request.company.name': 'Company',
                  'request.job.title': 'Job',
                  'request.resumeRequired': 'Resume Required',
                }}/>
                <TableCell>Interview</TableCell>
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                  'createdAt': 'Sent At',
                }}/>
                <TableCell colSpan={1} />
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.retrieved &&
              this.props.retrieved['hydra:member'].map(item => {
                const request = item['request'];
                return (
                  <TableRow key={item['@id']}>
                    <TableCell scope="row">
                      {renderLinks('invitations', item, (invitation) => invitation['id'])}
                    </TableCell>
                    <TableCell>{renderLinks('companies', request['company'], (company) => company['name'])}</TableCell>
                    <TableCell>{request['job']['title']}</TableCell>
                    <TableCell>{request['resumeRequired'] ? 'Yes' : 'No'}</TableCell>
                    <TableCell>
                      <InvitationInterviewLink invitation={item} />
                    </TableCell>
                    <TableCell>{item['createdAt'] && (<Moment>{item['createdAt']}</Moment>)}</TableCell>
                    <TableCell>
                      <Link to={`show/${encodeURIComponent(item['@id'])}`}>
                        <span className="fa fa-eye" aria-hidden="true" />
                        <span className="sr-only">Show</span>
                      </Link>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>

        <Table className="table table-responsive no-border">
          <TableFooter>
            <TableRow>
              {pagination(this.props.retrieved, getRoutePage(this.props.match))}
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    retrieved,
    loading,
    error,
    filtersData,
    sortData,
  } = state.candidateonewayinverviewinvitation.list;
  return { retrieved, loading, error, filtersData, sortData };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: () => dispatch(reset()),
  filter: (filtersData) => dispatch(filter(filtersData)),
  filterReset: () => dispatch(filter(defaultFilters)),
  sort: (sortData) => dispatch(sort(sortData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateOneWayInterviewInvitationList);
