import React, { Component } from 'react';
import {connect} from "react-redux";
import OnDemandInterviewPage from "../../components/OnDemandInterviewPage/OnDemandInterviewPage";
import {initializeByRoomCode} from "../../actions/OnDemandInterviewPage/page";

class OnDemandInterviewByCodePage extends Component {
  componentDidMount() {
    const roomCode = this.props.match.params.roomid;
    this.props.initializeByRoomCode(roomCode);
  }

  render() {
    let pageUrl = new URL(window.location.href);
    let includeLoginButton = pageUrl.searchParams.get('includeLoginButton') !== 'false';
    return (
      <OnDemandInterviewPage name={'By Code'} includeLoginButton={includeLoginButton}/>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  initializeByRoomCode: (roomCode) => dispatch(initializeByRoomCode(roomCode)),
});

OnDemandInterviewByCodePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(OnDemandInterviewByCodePage);

export default OnDemandInterviewByCodePage;
