import React from "react";
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import withStyles from "@material-ui/core/styles/withStyles";
import {generatePath, withRouter} from "react-router";

const actionsStyle = {
  root: {
    flexShrink: 0,
    marginLeft: '20px',
  },
};

class Pagination extends React.Component {
  static defaultProps = {
    pageParamName: 'page',
    rowsPerPageOptions: [],
    rowsPerPage: 30, //Default value for API platform
  };

  handleChangePage = (event, page) => {
    const { match, history, location } = this.props;
    const newBrowserUrl = generatePath(match.path, {page: (page > 0 ? page + 1 : null)});
    if (newBrowserUrl !== location.path) {
      history.push(newBrowserUrl);
    }
  };

  render() {
    const {match, page, pageParamName, staticContext, ...custom} = this.props;
    return (
      <TablePagination
        page={page > 0 ? page - 1 : 0}
        onChangePage={this.handleChangePage}
        {...custom}
        ActionsComponent={PaginationActions}
      />
    );
  }
}

class PaginationActions extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    const {count, rowsPerPage} = this.props;
    this.props.onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  render() {
    const { page, count, rowsPerPage, classes } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          style={{outline: 'none'}}
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          style={{outline: 'none'}}
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          style={{outline: 'none'}}
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          style={{outline: 'none'}}
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      </div>
    );
  };

}

Pagination = withRouter(Pagination);
PaginationActions = withStyles(actionsStyle)(PaginationActions);

export default Pagination;
