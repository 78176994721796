import { combineReducers } from 'redux';

export function error(state = null, action) {
  switch (action.type) {
    case 'TEAM_COMPANY_USER_MANAGE_DELETE_ERROR':
      return action.manageDeletedError;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'TEAM_COMPANY_USER_MANAGE_DELETE_LOADING':
      return action.manageDeletedLoading;

    default:
      return state;
  }
}

export function saved(state = false, action) {
  switch (action.type) {
    case 'TEAM_COMPANY_USER_MANAGE_DELETE_SAVED':
      return action.manageDeletedSaved;

    default:
      return state;
  }
}


export default combineReducers({
  error,
  loading,
  saved,
});
