import { combineReducers } from 'redux';
import list from './list';
import create from './create';
import update from './update';
import del from './delete';
import show from './show';
import forSelect from './forSelect';
import updateRole from './updateRole';
import activate from './activate';
import deactivate from './deactivate';

export default combineReducers({ list, create, update, del, show, forSelect, updateRole, activate, deactivate});
