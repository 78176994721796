import {getRequestUrlByRoomCode} from "../../utils/urls";
import {Link} from "react-router-dom";
import React, {Component} from "react";
import PropTypes from "prop-types";

class InvitationInterviewLink extends Component {
  static propTypes = {
    invitation: PropTypes.object.isRequired,
  };

  render() {
    const {invitation} = this.props;
    const startUrl = getRequestUrlByRoomCode(invitation['request']['roomCode']);

    if (!invitation['oneWayInterview']) {
      return (
        <a href={startUrl} target="_blank">Start</a>
      );
    }
    if (invitation['oneWayInterview'] && !invitation['oneWayInterview']['completedAt']) {
      return (
        <a href={startUrl} target="_blank">Continue</a>
      );
    }

    return (
      <Link to={`/candidate/on-demand-interviews/show/${encodeURIComponent(invitation['oneWayInterview']['@id'])}`}>View</Link>
    );
  }
};

export default InvitationInterviewLink;
