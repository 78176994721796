import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { list, reset, filter, sort } from '../../actions/QuestionBank/list';
import {handleListUpdate, pagination, renderLinks} from "../../utils/entityList";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {currentUserHasRole} from "../../utils/auth";
import filtersStorage from '../../utils/filtersStorage';
import FilterForm from "./FilterForm";
import {SortableTableCells} from "../../utils/filterForm";
import IsGranted from "../IsGranted";
import ProfessionMappingView from "../../views/Components/ProfessionMappingView";
import TableFooter from "@material-ui/core/TableFooter";
import {getRoutePage} from "../../utils/routes";
import FlashMessages from "../../views/Components/FlashMessages";
import withStyles from "@material-ui/core/styles/withStyles";
import EntityVideoPlayer from "../../views/Components/EntityVideoPlayer";

const style = {
  videoContainer: {
    minWidth: "200px",
    minHeight: "100px",
  },
  progressIndicator: {
    height: "112.5px",
    fontSize: "15px",
  },
};

class QuestionBankList extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    filter: PropTypes.func.isRequired,
    filtersData: PropTypes.object,
    sort: PropTypes.func.isRequired,
    sortData: PropTypes.object,
  };

  componentDidMount() {
    const storageName = 'question_bank';
    const filters = filtersStorage.get(storageName, null);
    const sort = filtersStorage.getSort(storageName);
    if (filters !== null || sort !== null) {
      this.props.filter(filters);
      this.props.sort(sort);
    } else {
      handleListUpdate('question_banks', this.props);
    }
  }

  componentDidUpdate(prevProps) {
    handleListUpdate('question_banks', this.props, prevProps);
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {
    const {classes} = this.props;
    return (
      <div>
        <FlashMessages location={this.props.location} />
        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}

        <IsGranted action='create' object={this.props.retrieved}>
        <p>
          <Link to="create" className="btn btn-primary">
            Create
          </Link>
        </p>
        </IsGranted>

        <FilterForm
          initialValues={this.props.filtersData}
          onSubmit={this.props.filter}
          resetFilter={this.props.filterReset}
        />

        <div style={{'overflowX': 'auto'}}>
          <Table className="table table-responsive table-striped table-hover">
            <TableHead>
              <TableRow>
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{ 'id': 'ID' }} />
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{ 'title': 'Question Statement' }} />
                <TableCell>Video</TableCell>
                <TableCell>Categories</TableCell>
                {(!currentUserHasRole('ROLE_SUPER_ADMIN') && !currentUserHasRole('ROLE_PARTNER_ADMIN')) && (
                  <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{ 'team___name': 'Team' }} />
                )}
                {(!currentUserHasRole('ROLE_SUPER_ADMIN') && !currentUserHasRole('ROLE_PARTNER_ADMIN')) && (
                  <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{ 'companyUser___firstName': 'User' }} />
                )}
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                  'presenter___name': 'Presenter',
                }} />
                <TableCell>Profession Mapping</TableCell>
                <TableCell colSpan={2} />
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.retrieved &&
                this.props.retrieved['hydra:member'].map(item => (
                  <TableRow key={item['@id']}>
                    <TableCell scope="row">
                      {renderLinks('question-banks', item, (questionBank) => questionBank['id'], true)}
                    </TableCell>
                    <TableCell>
                      {renderLinks('question-banks', item, (questionBank) => questionBank['title'], true)}
                    </TableCell>
                    <TableCell>
                      <div className={classes.videoContainer}>
                        <EntityVideoPlayer entity={item.questionBankVideo} progressIndicatorClassName={classes.progressIndicator} />
                      </div>
                    </TableCell>
                    <TableCell>
                      {item.questionBankCategories && item.questionBankCategories.length > 0 && item.questionBankCategories.map(
                        (category, index) => (
                          <span key={index}>
                            {renderLinks('question_bank_categories', category)}
                            {index < item.questionBankCategories.length - 1 && ', '}
                          </span>
                        )
                      )}
                    </TableCell>
                    {(!currentUserHasRole('ROLE_SUPER_ADMIN') && !currentUserHasRole('ROLE_PARTNER_ADMIN')) && (
                      <TableCell>
                        {renderLinks('teams', item['team'])}
                      </TableCell>
                    )}
                    {(!currentUserHasRole('ROLE_SUPER_ADMIN') && !currentUserHasRole('ROLE_PARTNER_ADMIN')) && (
                      <TableCell>
                        {renderLinks('users', item['companyUser'], (companyUser) => companyUser.firstName + ' ' + companyUser.lastName)}
                      </TableCell>
                    )}
                    <TableCell>{renderLinks('presenters', item['presenter'])}</TableCell>
                    <TableCell>
                      {item.professionMappings && item.professionMappings.length > 0 && item.professionMappings.map(
                        (mapping, index) => <ProfessionMappingView key={index} mapping={mapping} />
                      )}
                    </TableCell>
                    <TableCell>
                      <IsGranted action='show' object={item}>
                        <Link to={`show/${encodeURIComponent(item['@id'])}`}>
                          <span className="fa fa-eye" aria-hidden="true" />
                          <span className="sr-only">Show</span>
                        </Link>
                      </IsGranted>
                    </TableCell>
                    <TableCell>
                      <IsGranted action='edit' object={item}>
                        <Link to={`edit/${encodeURIComponent(item['@id'])}`}>
                          <span className="fa fa-pencil" aria-hidden="true" />
                          <span className="sr-only">Edit</span>
                        </Link>
                      </IsGranted>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                {pagination(this.props.retrieved, getRoutePage(this.props.match))}
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </div>
    );
  }


}

const mapStateToProps = state => {
  const {
    retrieved,
    loading,
    error,
    eventSource,
    filtersData,
    sortData,
  } = state.questionbank.list;
  return { retrieved, loading, error, eventSource, filtersData, sortData };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource)),
  filter: (filtersData) => dispatch(filter(filtersData)),
  filterReset: () => dispatch(filter(null)),
  sort: (sortData) => dispatch(sort(sortData)),
});

QuestionBankList = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionBankList);

QuestionBankList = withStyles(style)(QuestionBankList);

export default QuestionBankList;
