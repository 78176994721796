import React, {Component} from "react";
import EntityVideoPlayer from "./EntityVideoPlayer";
import Button from "../../components/CustomButtons/Button";
import PlayArrow from "@material-ui/icons/PlayArrow";
import {isVideoNeedToReEncode} from "../../utils/videoEntity";
import PropTypes from "prop-types";
import "./EntityVideoPlayerWithControl.scss";

class EntityVideoPlayerWithControl  extends Component {
  static propTypes = {
    entity: PropTypes.object.isRequired,
    progressIndicatorClassName: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.player = React.createRef();
  }

  playVideo = () => {
    if (!this.player || !this.player.current) {
      return;
    }

    this.player.current.seek(0);
    this.player.current.play();
  };

  render() {
    const {entity, progressIndicatorClassName, children} = this.props;
    const readyToPlay = !isVideoNeedToReEncode(entity);
    return (
      <div className={'entity-video-player'}>
        <EntityVideoPlayer
          progressIndicatorClassName={progressIndicatorClassName}
          entity={entity}
          ref={this.player}
        />
        <div className="controls">
          {readyToPlay && (
            <Button className="control-button" onClick={this.playVideo} >
              <PlayArrow/>Play
            </Button>
          )}
          {children}
        </div>
      </div>
    );
  }
}

export default EntityVideoPlayerWithControl;
