import { title, whiteColor } from "assets/jss/material-dashboard-pro-react.jsx";

const dashboardErrorPageStyles = theme => ({
  contentCenter: {
  },
  title: {
    ...title,
  },
  subTitle: {
    fontSize: "2.25rem",
    marginTop: "0",
    marginBottom: "8px"
  },
  description: {
    fontSize: "1.125rem",
    marginTop: "0",
    marginBottom: "8px"
  }
});

export default dashboardErrorPageStyles;
