import React from "react";
import DashboardErrorPage from "./DashboardErrorPage";

export default class DashboardNotFound extends React.Component {
    render() {
        return (
            <DashboardErrorPage errorTitle="Not Found"
                       errorDescription="The page you are trying to access does not exist"
            />
        );
    }
}
