import {showSnack} from "react-redux-snackbar";
import {loadRequestTemplatesForSelect} from "../RequestTemplate/forSelect";
import {create} from "../RequestTemplate/create";
import {SubmissionError} from "redux-form";
import moment from "moment";
import Moment from "react-moment";

export function saveAsTemplateError(error) {
  return {type: 'REQUEST_SAVE_AS_TEMPLATE_ERROR', error};
}

export function saveAsTemplateLoading(loading) {
  return {type: 'REQUEST_SAVE_AS_TEMPLATE_LOADING', loading};
}

export function saveAsTemplateSuccess(saved) {
  return {type: 'REQUEST_SAVE_AS_TEMPLATE_SUCCESS', saved};
}

export function saveAsTemplate(values) {
  return (dispatch, getState) => {
    let templateTitle;
    if (values.title) {
      templateTitle = 'Request ' + values.title;
    } else {
      templateTitle = 'Request ' + moment().format(Moment.timeFormat);
    }
    const team = values.team || null;
    delete values.team;

    dispatch(saveAsTemplateError(null));
    dispatch(saveAsTemplateSuccess(null));
    dispatch(saveAsTemplateLoading(true));
    dispatch(create({
      'title': templateTitle,
      'team': team,
      'request': values,
    }))
    .then(() => {
      const template = getState().requesttemplate.create.created;
      if (template) {
        dispatch(saveAsTemplateLoading(false));
        dispatch(saveAsTemplateSuccess(template));
        dispatch(loadRequestTemplatesForSelect());

        dispatch(showSnack('Saved as Template', {
          label: `Request was saved as a new template "${templateTitle}". You can continue editing it.`,
          timeout: 15000,
          button: {label: 'Hide'}
        }));
      } else {
        dispatch(saveAsTemplateLoading(false));
        dispatch(saveAsTemplateError(getState().requesttemplate.create.error));
      }
    }).catch((e) => {
      dispatch(saveAsTemplateLoading(false));
      if (e instanceof SubmissionError) {
        dispatch(saveAsTemplateError(e.errors._error));
      } else {
        dispatch(saveAsTemplateError(e.message));
      }
    });
  };
}
