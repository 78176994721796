import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import {retrieve, reset} from '../../actions/OneWayInterview/show';
import {del} from '../../actions/OneWayInterview/delete';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {renderLinks} from "../../utils/entityList";
import Moment from 'react-moment';
import IsGranted from "../IsGranted";
import CommentsModal from '../OneWayInterviewComment/Modal';
import Rating from 'material-ui-rating'
import OneWayInterviewTagsForm from "./OneWayInterviewTagsForm";
import CandidateCard from "../CompanyCandidate/CandidateCard";
import {isGranted} from "../../utils/permissions";
import ShareInterviewDialog from "../Access/ShareInterviewDialog";
import {grantAccess} from "../../actions/OneWayInterview/access";
import {OneWayInterviewAnswers} from "./OneWayInterviewAnswers";
import {reset as resetShareLinkSaved} from "../../actions/OneWayInterviewShareLink/update";
import FlashMessages from "../../views/Components/FlashMessages";
import "./Style.scss";

class OneWayInterviewShow extends Component {
  state = {
    openCommentsModal: false,
    openShareDialog: false,
  };

  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    deleteError: PropTypes.string,
    deleteLoading: PropTypes.bool.isRequired,
    deleted: PropTypes.object,
    del: PropTypes.func.isRequired
  };

  handleClickOpen = () => {
    this.setState({ openCommentsModal: true });
  };

  handleClose = () => {
    this.setState({ openCommentsModal: false });
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
    this.props.resetShareLinkSaved(this.props.eventSource);
  }

  del = () => {
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.del(this.props.retrieved);
  };

  render() {
    if (this.props.deleted)
      this.props.history.push({
        pathname: `..`,
        state: {successMessage: 'On-Demand Interview successfully deleted.'}
      });

    const item = this.props.retrieved;

    return (
      <div>
        <h1>Show &quot;{item && `${item.companyCandidate.firstName} ${item.companyCandidate.lastName} (${item.request.title})`}&quot;</h1>

        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
            {this.props.error}
          </div>
        )}
        {this.props.deleteError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
            {this.props.deleteError}
          </div>
        )}
        {this.props.granted && (
          <FlashMessages message={"Interview successfully shared."} type={"success"} duration={10000} />
        )}
        {this.props.shareLinkSaved && (
          <FlashMessages message={"Share link successfully saved."} type={"success"} duration={10000} />
        )}

        <div className="grid-buttons">
          <Link to=".." className="btn btn-primary">
            Back to list
          </Link>

          {item && (
            <>
            <IsGranted action='list_reviews' object={item}>
              <Link to={`../../on-demand-interview-reviews/${this.props.match.params.id}/reviews`} className="btn btn-info">
                Reviews
              </Link>
            </IsGranted>
            <IsGranted action='list_comments' object={item}>
              <button type="button" className="btn btn-info" onClick={this.handleClickOpen}>
                Comments
              </button>
            </IsGranted>
            <IsGranted action='delete' object={item}>
              <button onClick={this.del} className="btn btn-danger">
                Delete
              </button>
            </IsGranted>
            <IsGranted action="grant_access" object={item}>
              <button onClick={() => this.setState({openShareDialog: true})} className="btn btn-info">
                Share
              </button>
              <Link to={`../../on-demand-interviews/access/${this.props.match.params.id}`} className="btn btn-info">
                View Shared Users
              </Link>
            </IsGranted>
            </>
          )}
        </div>
        {this.state.openShareDialog && (
          <ShareInterviewDialog
            handleSubmit={(values) => this.props.grantAccess(item['@id'], values)}
            loading={this.props.grantAccessLoading}
            error={this.props.grantAccessError}
            open={this.state.openShareDialog}
            success={this.props.granted}
            onSuccess={() => this.setState({openShareDialog: false})}
            onClose={() => this.setState({openShareDialog: false})}
            oneWayInterview={this.props.retrieved}
          />
        )}
        {this.state.openCommentsModal && (
          <CommentsModal oneWayInterview={this.props.retrieved} handleClose={this.handleClose} />
        )}

        {item && (
          <>
            <Table className="table table-striped table-hover">
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell scope="row">On Demand Interview</TableCell>
                  <TableCell>{renderLinks('../requests', item['request'], (item) => item.title, true)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Started At</TableCell>
                  <TableCell>{item['startedAt'] ? <Moment>{item['startedAt']}</Moment> : 'Not started'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Completed At</TableCell>
                  <TableCell>{item['completedAt'] ? <Moment>{item['completedAt']}</Moment> : 'Not completed'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row" className="align-middle">Total Average Rating</TableCell>
                  <TableCell className="clearfix">
                    {item['totalAverageRating'] && (
                      <div>
                        <Rating
                          value={parseFloat(item['totalAverageRating'])}
                          max={5}
                          className="total-interview-rating"
                          readOnly={true}
                        />
                        <span className="total-interview-rating-info">{item['totalAverageRating']} - </span>
                      </div>
                    )}
                    <Link to={`../../on-demand-interview-reviews/${this.props.match.params.id}/reviews`} className="float-left" style={{ margin: '11px 0 0 0' }}>View Reviews</Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Candidate</TableCell>
                  <TableCell className="one-way-interview-responsive-card">
                    <CandidateCard companyCandidate={item['companyCandidate']} checkPermissions={true} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Tags</TableCell>
                  <TableCell style={{ 'width': '70%' }}>
                    {isGranted('update_tags', item) ? (
                      <OneWayInterviewTagsForm
                        object={item}
                      />
                    ) : (
                      item.tags.length > 0 ? item.tags.map(tag => tag.name).join(', ') : '-'
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <OneWayInterviewAnswers item={item} />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    retrieved: state.onewayinterview.show.retrieved,
    error: state.onewayinterview.show.error,
    grantAccessError: state.onewayinterview.access.grantAccessError,
    loading: state.onewayinterview.show.loading,
    grantAccessLoading: state.onewayinterview.access.grantAccessLoading,
    granted: state.onewayinterview.access.granted,
    eventSource: state.onewayinterview.show.eventSource,
    deleteError: state.onewayinterview.del.error,
    deleteLoading: state.onewayinterview.del.loading,
    deleted: state.onewayinterview.del.deleted,
    shareLinkSaved: state.onewayinterviewsharelink.update.updated,
  };
};

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  del: item => dispatch(del(item)),
  grantAccess: (interviewId, values) => dispatch(grantAccess(interviewId, values)),
  reset: eventSource => dispatch(reset(eventSource)),
  resetShareLinkSaved: eventSource => dispatch(resetShareLinkSaved(eventSource)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OneWayInterviewShow);
