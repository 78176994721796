import { combineReducers } from 'redux';

function loading(state = false, action) {
    switch (action.type) {
        case 'USER_PROFILE_UPDATE_LOADING':
            return action.loading;

        case 'USER_PROFILE_UPDATE_RESET':
            return false;

        default:
            return state;
    }
}

function error(state = null, action) {
    switch (action.type) {
        case 'USER_PROFILE_UPDATE_ERROR':
            return action.error;

        case 'USER_PROFILE_UPDATE_RESET':
            return null;

        default:
            return state;
    }
}

function retrieved(state = null, action) {
    switch (action.type) {
        case 'USER_PROFILE_UPDATE_RETRIEVE_SUCCESS':
            return action.retrieved;

        case 'USER_PROFILE_UPDATE_RESET':
            return null;

        default:
            return state;
    }
}

export function updated(state = null, action) {
    switch (action.type) {
        case 'USER_PROFILE_UPDATE_SUCCESS':
            return action.updated;

        case 'USER_PROFILE_UPDATE_RESET':
            return null;

        default:
            return state;
    }
}

export function auth(state = null, action) {
    switch (action.type) {
        case 'USER_PROFILE_UPDATE_AUTH_SUCCESS':
            return action.auth;
        case 'USER_PROFILE_UPDATE_AUTH_RESET':
        case 'USER_PROFILE_UPDATE_RESET':
            return null;

        default:
            return state;
    }
}

export default combineReducers({
    loading, retrieved, error, updated, auth
});
