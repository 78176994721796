import storage from "./storage";
import {getUserId} from "./auth";

const DATA_STORAGE_KEY = 'site-filters';
const FILTERS_STORAGE_KEY = 'filters';
const SORTING_STORAGE_KEY = 'sorting';

class FiltersStorage {
  _getUsersKey () {
    const userId = getUserId();
    return userId ? userId : 'anon';
  }

  _setStorageData(dataType, value) {
    let storageData = storage.get(DATA_STORAGE_KEY);
    const userKey = this._getUsersKey();
    if (!storageData) {
      storageData = {};
    }
    if (!storageData[userKey]) {
      storageData[userKey] = {};
    }

    storageData[userKey][dataType] = value;
    storage.set(DATA_STORAGE_KEY, storageData);
  }

  _getStorageData(dataType) {
    const storageData = storage.get(DATA_STORAGE_KEY);
    const userKey = this._getUsersKey();
    if (storageData && storageData[userKey] && storageData[userKey][dataType]) {
      return storageData[userKey][dataType];
    }

    return {};
  }

  set (name, value) {
    let filters = this._getStorageData(FILTERS_STORAGE_KEY);
    filters[name] = value;
    this._setStorageData(FILTERS_STORAGE_KEY, filters);
  };

  get (name, defaults = {}) {
    let filters = this._getStorageData(FILTERS_STORAGE_KEY);
    const value = filters[name] || null;
    if (null !== value) {
      return {...defaults, ...value};
    }

    return defaults;
  };

  remove (name) {
    let filters = this._getStorageData(FILTERS_STORAGE_KEY);
    delete filters[name];
    this._setStorageData(FILTERS_STORAGE_KEY, filters);
  };

  getSort (name, defaults = {}) {
    let sorting = this._getStorageData(SORTING_STORAGE_KEY);
    const value = sorting[name] || null;
    if (null !== sorting) {
      return {...defaults, ...value};
    }

    return defaults;
  };

  setSort(name, value) {
    let sort = this._getStorageData(SORTING_STORAGE_KEY);
    sort[name] = value;
    this._setStorageData(SORTING_STORAGE_KEY, sort);
  }

  removeSort(name) {
    let sort = this._getStorageData(SORTING_STORAGE_KEY);
    delete sort[name];
    this._setStorageData(SORTING_STORAGE_KEY, sort);
  }

  clear () {
    storage.remove(DATA_STORAGE_KEY);
  }

  resetKeyForAllFilters(key, value = undefined) {
    let data = storage.get(DATA_STORAGE_KEY);
    Object.keys(data).forEach(userKey => {
      let userFilters = data[userKey][FILTERS_STORAGE_KEY] || {};
      Object.keys(userFilters).forEach(filterName => {
        let filterValues = userFilters[filterName];
        if (filterValues && filterValues[key] && (value === undefined || filterValues[key] === value)) {
          delete filterValues[key];
          if (Object.keys(filterValues).length > 0) {
            userFilters[filterName] = filterValues;
          } else {
            delete userFilters[filterName];
          }
        }
      })
      data[userKey][FILTERS_STORAGE_KEY] = userFilters
    })
    storage.set(DATA_STORAGE_KEY, data);
  }
}

export default new FiltersStorage();
