import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import {renderTextField} from "../../utils/form";
import {loadPartnersForSelect} from "../../actions/Partner/forSelect";
import {connect} from "react-redux";
import {currentUserHasRole} from "../../utils/auth";
import SearchableSelect from "../../views/Components/SearchableSelect";

class PartnerAdminForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  componentDidMount() {
    if (currentUserHasRole('ROLE_SUPER_ADMIN')) {
      this.props.loadPartnersForSelect();
    }
  }

  render() {
    const {partners} = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        {partners && partners.length > 0 && (
        <Field
          component={SearchableSelect}
          name="partner"
          type="text"
          label="Partner"
          placeholder="The Partner to which this PartnerAdmin belongs."
          required={true}
          options={partners}
        />
        )}
        <Field
          component={renderTextField}
          name="firstName"
          type="text"
          placeholder="First Name"
          required={true}
        />
        <Field
          component={renderTextField}
          name="lastName"
          type="text"
          placeholder="Last Name"
          required={true}
        />
        <Field
          component={renderTextField}
          name="email"
          type="text"
          placeholder="Email"
          required={true}
        />
        <Field
          component={renderTextField}
          name="phoneNumber"
          type="text"
          placeholder="Phone Number"
          required={true}
        />
        <Field
          component={renderTextField}
          name="plainPassword"
          type="password"
          placeholder="Password"
          required={this.props.isNew}
        />
        <Field
          component={renderTextField}
          name="confirmPassword"
          type="password"
          label="Confirm Password"
          placeholder="Confirm Password"
          required={this.props.isNew}
        />

        <button type="submit" className="btn btn-success float-left mr-3">
          Submit
        </button>
      </form>
    );
  }
}

const validate = values => {
    const errors = {};

    if (!values['@id']) {
      if (!values.plainPassword) {
        errors.plainPassword = 'Please enter new password';
      }
      if (!values.confirmPassword) {
        errors.confirmPassword = 'Please repeat password';
      } else if (values.plainPassword !== values.confirmPassword) {
        errors.confirmPassword = 'New password and repeat password should be the same';
      }
    } else {
      if ((values.plainPassword || values.confirmPassword) && values.plainPassword !== values.confirmPassword) {
        errors.confirmPassword = 'Passwords are not the same';
      }
    }

    return errors;
};

PartnerAdminForm = reduxForm({
  form: 'partneradmin',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: validate,
})(PartnerAdminForm);

const mapStateToProps = state => ({
    partners: state.partner.forSelect.retrieved,
});

const mapDispatchToProps = dispatch => ({
    loadPartnersForSelect: () => dispatch(loadPartnersForSelect('@id')),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PartnerAdminForm);
