import { SubmissionError } from 'redux-form';
import { fetch } from '../../utils/dataAccess';

export function grantAccessError(error) {
  return { type: 'LIVEINTERVIEW_ACCESS_GRANT_ERROR', error };
}

export function grantAccessloading(loading) {
  return { type: 'LIVEINTERVIEW_ACCESS_GRANT_LOADING', loading };
}

export function accessGrantSuccess(granted) {
  return { type: 'LIVEINTERVIEW_ACCESS_GRANT_SUCCESS', granted };
}

export function grantAccess(interviewId, values) {
  return dispatch => {
    dispatch(grantAccessError(null));
    dispatch(grantAccessloading(true));
    dispatch(accessGrantSuccess(null));

    let endpoint = interviewId + '/access';
    return fetch(endpoint, {
      method: 'POST',
      data: values
    })
      .then((response) => {
        dispatch(grantAccessloading(false));
        dispatch(accessGrantSuccess(response.data));
      })
      .catch(e => {
        dispatch(grantAccessloading(false));
        if (e instanceof SubmissionError) {
          dispatch(grantAccessError(e.errors._error));
          throw e;
        }
        dispatch(grantAccessError(e.message));
      });
  };
}

export function retrieveListLoading(loading) {
  return { type: 'LIVEINTERVIEW_ACCESS_LIST_LOADING', loading };
}

export function accessListRetrieved(retrieved) {
  return { type: 'LIVEINTERVIEW_ACCESS_LIST_RETRIEVED', retrieved };
}

export function retrieveListError(error) {
  return { type: 'LIVEINTERVIEW_ACCESS_LIST_ERROR', error };
}

export function retrieveAccessList(interviewId) {
  return dispatch => {
    dispatch(retrieveListLoading(true));
    dispatch(retrieveListError(null));

    fetch(interviewId + '/access')
      .then(response => response.data)
      .then(interview => {
        dispatch(retrieveListLoading(false));
        dispatch(accessListRetrieved(interview.accesses));
      })
      .catch(e => {
        dispatch(retrieveListLoading(false));
        dispatch(retrieveListError(e.message));
      });
  };
}

export function deleteAccessLoading(loading) {
  return { type: 'LIVEINTERVIEW_ACCESS_DELETE_LOADING', loading };
}

export function deleteAccessError(error) {
  return { type: 'LIVEINTERVIEW_ACCESS_DELETE_ERROR', error };
}

export function accessDeletedSuccess(deleted) {
  return { type: 'LIVEINTERVIEW_ACCESS_DELETE_SUCCESS', deleted };
}

export function deleteAccess(interviewId, userId) {
  return dispatch => {
    dispatch(deleteAccessError(null));
    dispatch(deleteAccessLoading(true));
    dispatch(accessDeletedSuccess(null));

    let endpoint = interviewId + '/access';
    return fetch(endpoint, {
      method: 'POST',
      data: {
        user: userId,
        permissions: [],
      }
    })
      .then((response) => {
        dispatch(deleteAccessLoading(false));
        dispatch(accessDeletedSuccess(response.data));
      })
      .catch(e => {
        dispatch(deleteAccessLoading(false));
        if (e instanceof SubmissionError) {
          dispatch(deleteAccessError(e.errors._error));
          throw e;
        }
        dispatch(deleteAccessError(e.message));
      });
  };
}

export function reset() {
  return { type: 'LIVEINTERVIEW_ACCESS_RESET' };
}
