import OpenViduVideoComponent from "../video/OpenViduVideoComponent";
import {withStyles} from "@material-ui/core/styles";
import React from "react";
import {blackColor, grayColor, whiteColor} from "../../assets/jss/material-dashboard-pro-react";
import MutedIcon from '@material-ui/icons/MicOff';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import classNames from 'classnames';
import {isVideoTrackMuted} from "../../utils/webRtc";
import AudioElement from "./AudioElement";
import {isScreenShareStreamManager} from "../../utils/streamManager";
import PinIcon from "../../components/Icons/PinIcon";

const style = theme => ({
  videoWrapper: {
    width: 'inherit',
    height: 'inherit',
    [theme.breakpoints.down("sm")]: {
      zIndex: '98',
    },
  },
  videoHidden: {
    visibility: 'hidden',
  },
  videoDisplayNone: {
    display: 'none',
  },
  videoPlaceholder: {
    backgroundColor: blackColor,
    color: whiteColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  videoPlaceholderFullscreen: {
    fontSize: 'large',
  },
  hiddenVideoPlaceholder: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%!important',
    height: '100%!important',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  noVideoPlaceholder: {
    padding: '5vw',
    height: '100%',
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  noVideoPlaceholderFullscreen: {
    padding: 0,
  },
  mainVideo: {
    fontSize: 'initial',
    width: '100vw',
    height: '100vh',
    [theme.breakpoints.down("sm")]: {
      height: 'calc(90vh - 150px)'
    },
  },
  mainVideoParticipantsListNotHidden: {
    height: 'calc(100vh - 120px)'
  },
  caption: {
    fontWeight: 'bold',
    margin: 0,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    color: whiteColor,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  fullscreenCaption: {
    padding: '2px',
    paddingLeft: '.5em',
  },
  captionUserType: {
    textTransform: 'uppercase',
    color: grayColor[9],
    fontWeight: 'normal',
  },
  captionUserTypeFullscreen: {
    fontSize: 'xx-small',
    color: 'white',
  },
  captionUserNameFullscreen: {
    fontSize: 'smaller',
  },
  placeholderUserType: {
    textTransform: 'uppercase',
    color: grayColor[9],
    fontSize: 'small',
  },
  placeholderUserTypeFullscreen: {
    fontSize: 'x-small',
  },
  muteIndicator: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    zIndex: 100,
    display: 'flex',
  },
  muteIndicatorFullscreen: {
    top: '.5rem',
    right: '.5rem',
  },
  muteMainIndicatorFullscreen: {
    right: '8rem',
    [theme.breakpoints.down("sm")]: {
      right: '0',
    }
  },
  pinIcon: {
    position: 'absolute',
    bottom: '.5rem',
    right: '.5rem',
    zIndex: 100,
    display: 'flex',
    color: 'white',
    cursor: 'pointer',
  },
  pinIconMainVideo: {
    right: '5rem',
    bottom: '2rem',
    transform: 'rotate(-45deg)',
  },
  pinIconPinned: {
    color: 'green',
    transform: 'rotate(0deg)',
  },
  sipAudioIcon: {
    color: 'green',
  },
});

class VideoElement extends React.Component {
  constructor(props) {
    super(props);
    const {streamManager} = this.props;
    this.listeningStreamEvents = false;
    this.listeningMediaStreamEvents = false;
    if (streamManager) {
      this.listenEvents(streamManager);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.streamManager && this.props.streamManager) {
      this.listenStreamEvents(this.props.streamManager);
    }
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  listenEvents = (streamManager) => {
    this.listenStreamEvents(streamManager);
    if (streamManager.stream && streamManager.stream.mediaStream) {
      this.listenMediaStreamEvents(streamManager.stream.mediaStream);
    }
  }

  listenStreamEvents = (streamManager) => {
    if (this.listeningStreamEvents) {
      return;
    }
    streamManager.on('streamPropertyChanged', this.listenStreamPropertyChange);

    if (streamManager.stream) {
      streamManager.stream.ee.on('mediastream-updated', this.listenMediaStreamUpdate);
    }
    this.listeningStreamEvents = true;
  };

  listenMediaStreamEvents = (mediaStream) => {
    if (this.listeningMediaStreamEvents) {
      return;
    }

    mediaStream.getVideoTracks().forEach(track => {
      track.addEventListener('unmute', this.updateComponent)
    })
  }

  removeEventListeners = () => {
    const streamManager = this.props.streamManager;
    if (!streamManager) {
      return;
    }
    streamManager.off('streamPropertyChanged', this.listenStreamPropertyChange);

    if (!streamManager.stream) {
      return;
    }
    streamManager.stream.ee.off('mediastream-updated', this.listenMediaStreamUpdate);

    if (!streamManager.stream.mediaStream) {
      return;
    }

    streamManager.stream.mediaStream.getVideoTracks().forEach(track => {
      track.removeEventListener('unmute', this.updateComponent)
    });
  }

  updateComponent = () => {
    this.forceUpdate();
  }

  listenStreamPropertyChange = (event) => {
    if (event.changedProperty === 'videoActive' ||
      event.changedProperty === 'audioActive'
    ) {
      this.forceUpdate();
    }
  }

  listenMediaStreamUpdate = () => {
    if (this.props.streamManager.stream.mediaStream) {
      this.listeningMediaStreamEvents = false;
      this.listenMediaStreamEvents(this.props.streamManager.stream.mediaStream)
    }
    this.forceUpdate();
  };

  forceUpdateOnTrackMute = () => {
    if (this.forceUpdateOnVideoTrackMute) {
      this.forceUpdate();
    }
  }

  renderMuteIndicator = (hasAudioStream, hasActiveAudioStream, sipAudio, mainVideo) => {
    const {classes, fullscreen} = this.props;
    return (
      !hasAudioStream || !hasActiveAudioStream || sipAudio ? (
        <span
          className={classNames(classes.muteIndicator, 'top-right-icon', {
            [classes.muteIndicatorFullscreen]: fullscreen && !mainVideo,
            [classes.muteMainIndicatorFullscreen]: fullscreen && mainVideo,
          })}
        >
          {sipAudio ? (
            <PhoneInTalkIcon
              fontSize={fullscreen && !mainVideo ? 'default' : 'large'}
              className={classes.sipAudioIcon}
            />
          ) : (
            <MutedIcon
              fontSize={fullscreen && !mainVideo ? 'default' : 'large'}
              color={'error'}
            />
          )}
        </span>
    ) : null);
  }

  pinHandler = (e, pinned, id) => {
    e.stopPropagation();
    this.props.pinUnPin(pinned, id);
  }

  render() {
    const {streamManager, sipAudio, name, userType, classes, mainVideo, ownVideo, fullscreen, pinned, id, isMobile} = this.props;
    const hasStream = streamManager && streamManager.stream && (streamManager.stream.mediaStream || streamManager.remote);
    const hasVideoStream = hasStream && streamManager.stream.hasVideo;
    const hasAudioStream = hasStream && streamManager.stream.hasAudio;
    const videoStreamMuted = hasVideoStream && streamManager.stream.mediaStream && isVideoTrackMuted(streamManager.stream.mediaStream);
    const hasActiveVideoStream = hasVideoStream && streamManager.stream.videoActive;
    const hasActiveAudioStream = hasAudioStream && streamManager.stream.audioActive;
    const placeholderText = (hasStream ? 'No Video' : 'Please allow access to your camera and microphone to start your video.');
    const videoClass = classNames(
      classes.videoWrapper,
      this.props.className,
      {
        [classes.videoHidden]: !hasActiveVideoStream,
        [classes.mainVideo]: mainVideo,
        [classes.mainVideoParticipantsListNotHidden]: mainVideo && !this.props.hideFullscreenVideoList,
      }
    );
    const placeholderClass = classNames('no-video', 'placeholder-font', classes.videoPlaceholder, {
      [classes.hiddenVideoPlaceholder]: hasVideoStream,
      [classes.noVideoPlaceholder]: !hasVideoStream,
      [classes.noVideoPlaceholderFullscreen]: !hasVideoStream && fullscreen && !mainVideo,
      [classes.videoPlaceholderFullscreen]: fullscreen && !mainVideo,
    });
    const pinIconClass = classNames(classes.pinIcon, {
      [classes.pinIconMainVideo]: mainVideo,
      [classes.pinIconPinned]: pinned,
    });
    return (
      <>
        {(hasVideoStream && !videoStreamMuted) && (
          <>
            <OpenViduVideoComponent
              className={hasVideoStream ? videoClass : classes.videoDisplayNone}
              streamManager={streamManager}
              videoLoaded={true}
              muted={this.props.muted}
            />
            {name && hasActiveVideoStream && (
              <p className={
                classNames(classes.caption, 'video-element-caption', {
                  [classes.fullscreenCaption]: fullscreen,
                  'main-video-fullscreen-caption': fullscreen && mainVideo,
              })}>
                {userType && (<span className={classNames(classes.captionUserType, 'video-element-user-type', {[classes.captionUserTypeFullscreen]: fullscreen})}>{userType}</span>)}
                <span className={classNames('video-element-user-name', {[classes.captionUserNameFullscreen]: fullscreen})}>{name}</span>
              </p>
            )}
            {hasVideoStream && !isScreenShareStreamManager(streamManager) && (this.renderMuteIndicator(hasAudioStream, hasActiveAudioStream, sipAudio, mainVideo))}
            {fullscreen && !isMobile && (<PinIcon className={pinIconClass} onClick={(e) => this.pinHandler(e, pinned, id)} />)}
          </>
        )}
        {(!hasStream || !hasActiveVideoStream || videoStreamMuted) && (
          <div className={placeholderClass}>
            {name ? (
              <>
                {userType && (<span className={
                  classNames(classes.placeholderUserType, {[classes.placeholderUserTypeFullscreen]: fullscreen && !mainVideo})
                }>{userType}</span>)}
                <span>{name}</span>
              </>
            ): (
              <span>{placeholderText}</span>
            )}
            {hasAudioStream && streamManager.remote && (!hasVideoStream || videoStreamMuted) && (
              <AudioElement streamManager={streamManager} className={classes.displayNone} muted={this.props.muted} />
            )}
            {this.renderMuteIndicator(hasAudioStream, hasActiveAudioStream, sipAudio, mainVideo)}
          </div>
        )}
        {sipAudio && !ownVideo && (
          <AudioElement streamManager={sipAudio} className={classes.displayNone} muted={this.props.muted} />
        )}
      </>
    )
  }
}

export default withStyles(style)(VideoElement)
