import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Form from './Form';
import { create, reset } from '../../actions/Plan/create';
import {LIMITATION_USERS, LIMITATION_JOBS, LIMITATION_STORAGE} from "../../utils/plan";

class PlanCreate extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    created: PropTypes.object,
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    if (this.props.created)
      this.props.history.push({
        pathname: `list`,
        state: {successMessage: 'Plan successfully created.'}
      });

    return (
      <div>

        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}

        <Link to={"list"} className={"btn btn-primary"}>Back to Plan List</Link>
        <h3 className={"admin-plan-form-header"}>Create Plan</h3>

        <Form
          onSubmit={this.props.create}
          isNew={true}
          initialValues={{
            private: false,
            planLimitations: [
              {key: LIMITATION_USERS},
              {key: LIMITATION_JOBS},
              {key: LIMITATION_STORAGE},
            ]
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { created, error, loading } = state.plan.create;
  return { created, error, loading };
};

const mapDispatchToProps = dispatch => ({
  create: values => dispatch(create(values)),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanCreate);
