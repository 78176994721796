import React from "react";
import VideoGrid from "./VideoGrid";
import {connect} from "react-redux";
import {addParticipant} from "../../actions/LiveInterviewPage/interview";
import VideoInterviewMenu from "./VideoInterviewMenu";
import withStyles from "@material-ui/core/styles/withStyles";
import {isIphone, isMobile} from "../../utils/userAgent";
import FullScreenControl from "./FullScreenControl";
import classNames from 'classnames';
import VideoInterviewTopPanel from "./VideoInterviewTopPanel";
import VideoInterviewBottomPanel from "./VideoInterviewBottomPanel";
import {isScreenShareStreamManager} from "../../utils/streamManager";
import {getUserNameForParticipant} from "../../utils/liveInterview";

const style= theme => ({
  root: {
    padding: '1em',
    display: 'flex',
    height: '100%',
    [theme.breakpoints.down("sm")]: {
      height: '100vh',
    },
  },
  rootFullscreen: {
    padding: 0,
  },
  gridContainer: {
    flexBasis: '90%',
    flexShrink: 1,
    position: 'relative',
  },
  gridContainerFullscreen: {
    flexBasis: '100%',
  },
});

class VideoInterview extends React.Component {

  getInterviewParticipants = () => {
    const { publisher, sipAudio, participants, liveInterview, liveInterviewParticipant } = this.props;
    const ownVideo = {
      id: liveInterviewParticipant.userId,
      streamManager: publisher,
      sipAudio: sipAudio,
      name: getUserNameForParticipant(liveInterviewParticipant),
      userType: liveInterviewParticipant.type,
      ownVideo: true,
    };

    let interviewParticipants = [];
    if (!isIphone()) {
      interviewParticipants = [ownVideo, ...participants];
    } else if (participants && participants.length > 0) {
      interviewParticipants = [ownVideo, this.getSecondParticipantForIphone()];
    } else {
      interviewParticipants = [ownVideo];
    }

    const companyUserType = (liveInterview && liveInterview.job && liveInterview.job.company) ?
      liveInterview.job.company.name : 'Company User';

    return interviewParticipants.map(participant => {
      if (participant.userType && participant.userType.toLowerCase() !== 'candidate') {
        participant.userType = companyUserType;
      }
      return participant;
    });
  };

  getSecondParticipantForIphone = () => {
    const { participants, focusedParticipantId } = this.props;
    let focusedParticipant;
    if (focusedParticipantId) {
      focusedParticipant = participants.find(participant => participant.id === focusedParticipantId);
      if (focusedParticipant) {
        return focusedParticipant;
      }
    }

    const speakingParticipant = participants.find(participant => participant.speaking);
    if (speakingParticipant) {
      return speakingParticipant;
    }

    const spokeParticipants = participants.filter(participant => participant.lastSpeakTime !== null);
    if (spokeParticipants.length > 0) {
      spokeParticipants.sort((participant1, participant2) => {
        const speakTime1 = participant1.lastSpeakTime ? participant1.lastSpeakTime.getTime() : 0;
        const speakTime2 = participant2.lastSpeakTime ? participant2.lastSpeakTime.getTime() : 0;
        return speakTime1 > speakTime2 ? -1 : speakTime1 < speakTime2 ? 1 : 0;
      });
      return spokeParticipants[0];
    }

    return participants[0];
  }

  participantSharedScreen = (participants) => {
    const sharedScreen = participants.find(
      participant => participant && isScreenShareStreamManager(participant.streamManager)
    );
    return !!sharedScreen;
  }

  componentWillUnmount() {
    const {publisher} = this.props;

    if (publisher && publisher.stream) {
      publisher.stream.disposeMediaStream();
    }
  }

  render() {
    const {classes, reconnecting, fullscreenEnabled, screenSize, bottomPanelActive, participantsMuted} = this.props;
    const participants = this.getInterviewParticipants();
    const smallScreen = !screenSize.md;
    return (
      <div className={classNames(
        'live-interview-video', classes.root,
        {
          [classes.rootFullscreen]: fullscreenEnabled,
          'live-interview-video-with-active-bottom-panel': smallScreen && bottomPanelActive,
        }
      )}>
        {smallScreen && (<VideoInterviewTopPanel />)}
        <div className={classNames('live-interview-video-grid-container', classes.gridContainer, {[classes.gridContainerFullscreen]: fullscreenEnabled})}>
          {reconnecting && (<div className="alert alert-info live-interview-reconnecting" role="status">Reconnecting...</div>)}
          <VideoGrid
            participants={participants}
            muteParticipants={participantsMuted}
            isMobile={isMobile()}
          />
          <FullScreenControl />
        </div>
        {smallScreen ? (
          <VideoInterviewBottomPanel />
        ) : (
          <VideoInterviewMenu sessionContainSharedScreen={this.participantSharedScreen(participants)}/>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  publisher: state.liveInterviewPage.publisher,
  sipAudio: state.liveInterviewPage.sipAudio,
  participants: state.liveInterviewPage.participants,
  focusedParticipantId: state.liveInterviewPage.focusedParticipantId,
  session: state.liveInterviewPage.session,
  reconnecting: state.liveInterviewPage.reconnectingToInterview,
  liveInterview: state.liveInterviewPage.liveInterview,
  liveInterviewParticipant: state.liveInterviewPage.liveInterviewParticipant,
  fullscreenEnabled: state.liveInterviewPage.fullscreen,
  bottomPanelActive: state.liveInterviewPage.bottomPanelActive,
  screenSize: state.screen.size,
  participantsMuted: state.liveInterviewPage.participantsMuted,
});

const mapDispatchToProps = dispatch => ({
  addParticipant: (participant) => dispatch(addParticipant(participant)),
});

VideoInterview = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoInterview);

VideoInterview = withStyles(style)(VideoInterview);

export default VideoInterview;
