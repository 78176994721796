import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Menu from "@material-ui/icons/Menu";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";
import Desktop from "@material-ui/icons/DesktopWindows";
import LockOpen from "@material-ui/icons/LockOpen";

// core components
import Button from "components/CustomButtons/Button";

import authNavbarStyle from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle.jsx";
import {resolveDashboardUrl} from "../../utils/dashboardUrlResolver";
import {currentUserRoles, isAuthenticated} from "../../utils/auth";

class AuthNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.setState({ open: false });
    }
  }
  render() {
    const { classes, color, brandText, logo, site } = this.props;
    const appBarClasses = cx({
      [" " + classes[color]]: color
    });
    const isLogoSvg = (logo && logo.split('.').pop() === 'svg') ? true: false;
    const mobileLogoClasses = classes.navbarLogo + 
    " " + cx({
      [classes.svgLogo]: isLogoSvg
    });
    const isAuth = isAuthenticated();
    const isPartnerSite = site.type === 'partner';
    var list = (
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <NavLink
            to={isAuth ? resolveDashboardUrl(currentUserRoles()) : '/login' }
            className={cx(classes.navLink, {
              [classes.navLinkActive]: this.activeRoute(isAuth ? '/logout' : '/login')
            })}
          >
            {isAuth ? (<Desktop className={classes.listItemIcon} />) : (<Fingerprint className={classes.listItemIcon} />)}
            <ListItemText
              primary={isAuth ? 'Dashboard' : 'Login'}
              disableTypography={true}
              className={classes.listItemText}
            />
          </NavLink>
        </ListItem>
      </List>
    );
    return (
      <AppBar position="static" className={classes.appBar + appBarClasses + ' ' + classes.light}> 
        <Toolbar className={classes.container}>
          <Hidden smDown>
            <div className={classes.flex}>
              {logo && (
                <Button href="/" color="transparent">
                  <img className={classes.navbarLogo} src={logo} />
                </Button>
              )}
              {!logo &&(
                <Button href="/" className={classes.title} color="transparent">
                  {brandText}
                </Button>
              )}
            </div>
          </Hidden>
          <Hidden mdUp>
            <Button
              className={classes.sidebarButton}
              color="transparent"
              justIcon
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
            >
              <Menu />
            </Button>
          </Hidden>
          <Hidden mdUp>
            
              {logo && (
                <a href="/" className={classes.navbarLogoButton}>
                  <img className={ mobileLogoClasses } src={logo} />
                </a>
              )}
              {!logo &&(
                <div>
                <Button href="/" className={classes.title} color="transparent">
                  {brandText}
                </Button>
                </div>
              )}
            
          </Hidden>
          <Hidden smDown>{list}</Hidden>
          <Hidden mdUp>
            <Hidden mdUp>
              <Drawer
                variant="temporary"
                anchor={"left"}
                open={this.state.open}
                classes={{
                  paper: classes.drawerPaper + ' ' + classes.light
                }}
                onClose={this.handleDrawerToggle}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              >
                {list}
              </Drawer>
            </Hidden>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

AuthNavbar.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string
};

export default withStyles(authNavbarStyle)(AuthNavbar);
