export function invitation (state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_INVITATION':
      const invitation = action.invitation;
      if (invitation && invitation.liveInterview) {
        delete invitation.liveInterview;
      }
      return invitation;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}

export function acceptingInvitation (state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_INVITATION_ACCEPTING':
      return action.accepting;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

export function acceptedInvitation (state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_INVITATION_ACCEPTED':
      return action.accepted;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

export function decliningInvitation (state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_INVITATION_DECLINING':
      return action.declining;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

export function requestingNewTime(state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_INVITATION_REQUESTING_NEW_TIME':
      return action.requestingNewTime;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

export function newTimeRequested(state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_INVITATION_NEW_TIME_REQUESTED':
      return action.newTimeRequested;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}
