import React, {Component} from 'react'
import FileUploadComponent from "./FileUploadComponent";

class InlineFileUpload extends Component{
  onChange = (file) => {
    const { input: { onChange} } = this.props;
    if (file) {
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = function (evt) {
        onChange({
          'fileName': file.name,
          'fileContent': evt.target.result,
        });
      };
    } else {
      this.clearValue();
    }
  };

  clearValue = () => {
    const { input: { onChange} } = this.props;
    onChange(null)
  };

  render(){
    const {input: {value, name}, meta: {touched, error}, ...props} = this.props;

    return(
      <FileUploadComponent
        name={name}
        value={value}
        error={touched ? error : null}
        onChange={this.onChange}
        {...props}
      />
    )
  }
}

export default InlineFileUpload;
