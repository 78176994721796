import React from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LiveInterviewVideo from "../LiveInterview/LiveInterviewVideo";
import LiveInterviewChatHistory from "./LiveInterviewChatHistory";

export default class CompletedVideoChatPreview extends React.Component {
  static propTypes = {
    liveInterview: PropTypes.object.isRequired,
  };

  state = {
    seekTime: 0,
  }

  render = () => {
    let hasChat = false;
    let onChatMessageTimeClick = null;

    const liveInterview = this.props.liveInterview;

    if (liveInterview) {
      const hasComposedVideo = !!liveInterview.composedVideo;
      const composedVideoReady = hasComposedVideo && liveInterview.composedVideo.path;

      hasChat = this.props.liveInterview.messages && this.props.liveInterview.messages.length > 0;

      if (composedVideoReady && hasChat) {
        onChatMessageTimeClick = (time) => {
          if (time === this.state.seekTime) {
            time += 0.001;
          }
          this.setState({seekTime: time});
        }
      }
    }

    return (
      <div className={classNames('video-chat-container', {'no-chat': !hasChat})}>
        <div className="video-container">
          <LiveInterviewVideo liveInterview={this.props.liveInterview} seekTime={this.state.seekTime} />
        </div>
        {hasChat && (
          <div className="chat-container">
            <div className="chat-wrapper">
              <LiveInterviewChatHistory
                startedAt={this.props.liveInterview.startedAt}
                messages={this.props.liveInterview.messages}
                onChatMessageTimeClick={onChatMessageTimeClick}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
