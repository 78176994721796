import React from 'react';
import candidateSettingsImg from "../../assets/img/candidate_settings.png";

class CandidateSettings extends React.Component {
    static defaultProps = {
        keyword: 'Candidate Settings',
        fieldPrefix: 'candidateSettings',
    }

    render() {
        return (
            <>
                <img src={candidateSettingsImg} alt={this.props.keyword} style={{ width: 'auto', maxWidth: '100%'}} />
            </>
        );
    }
}

export default CandidateSettings;
