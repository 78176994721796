import React, {Component} from "react";
import PropTypes from "prop-types";
import {CopyToClipboard} from "../CopyToClipboard/CopyToClipboard";
import IconButton from "@material-ui/core/IconButton";
import CopyContent from "../Icons/CopyContent";
import PhoneNumber from "../../views/Components/PhoneNumber";
import CandidateLink from "./CandidateLink";

export default class FlatCandidateCard extends Component {
  static propTypes = {
    index: PropTypes.number,
    companyCandidate: PropTypes.object.isRequired,
    checkPermissions: PropTypes.bool
  };

  static defaultProps = {
    checkPermissions: false,
  };

  render() {
    const {companyCandidate, link} = this.props;

    return (
      <div className="block-candidate-card">
        {(companyCandidate.firstName || companyCandidate.lastName) && (
          <div className="block-candidate-name">
            <CandidateLink candidate={companyCandidate} checkPermissions={this.props.checkPermissions} />
          </div>
        )}
        {companyCandidate.email && (
          <div className="block-candidate-email">
            {companyCandidate.email}
          </div>
        )}
        {companyCandidate.phoneNumber && (
          <div className="block-candidate-phone">
            <PhoneNumber phone={companyCandidate.phoneNumber} formattedPhone={companyCandidate.formattedPhoneNumber} />
          </div>
        )}
        {link && (
          <div className="block-candidate-invitation-link">
            {link}
            <CopyToClipboard text={link}>
              <IconButton style={{padding: '0', height: '1em', outline: 'none', marginLeft: '5px'}}>
                <CopyContent style={{height: '0.6em'}}>
                  Copy To Clipboard
                </CopyContent>
              </IconButton>
            </CopyToClipboard>
          </div>
        )}
      </div>
    );
  }
}
