import React, {Component} from "react";
import {Field} from "redux-form";
import {renderCheckbox, renderInlineRadioButtons, renderTextField} from "../../utils/form";
import Button from "../CustomButtons/Button";
import {VIDEO_ENTRYPOINT} from "../../config/entrypoint";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import './VideoQuestion.scss'
import CardBody from "../Card/CardBody";
import Card from "../Card/Card";
import FormLabel from "@material-ui/core/FormLabel";
import PropTypes from "prop-types";
import Switch from '@material-ui/core/Switch'
import VideoQuestionTimeLimitOption from "./VideoQuestionTimeLimitOption";
import VideoField from "../../views/Components/VideoField";

export default class VideoQuestion extends Component {
    static propTypes = {
        updateVideoQuestionSettings: PropTypes.func.isRequired,
        index: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.onMaxAnswerRecordingRetriesChange = this.onMaxAnswerRecordingRetriesChange.bind(this);
    }

    toggleExpanded = (expanded) => {
        this.props.change(`${this.props.name}.expanded`, !expanded);
    };

    onMaxAnswerRecordingRetriesChange = (e, value) => {
        this.props.change(`${this.props.name}.maxAnswerRecordingRetries`, value ? 3 : null);
    };

    renderButtons = (expanded) => (
        <div className='buttons'>
            <Button size="sm" className='button' onClick={this.toggleExpanded.bind(this, expanded)}>
                {expanded ? (
                    <ExpandLess>Collapse</ExpandLess>
                ) : (
                    <ExpandMore>View</ExpandMore>
                )}
            </Button>
            {this.props.children}
        </div>
    );

    render() {
        const {
            isDroppable,
            currentValue: {
                maxAnswerRecordingRetries,
                thinkTimeLimitMinutes,
                thinkTimeLimitSeconds,
                thinkTimeIsEnabled = !!(thinkTimeLimitSeconds || thinkTimeLimitMinutes),
                showMaxAnswerRecordingRetries = !!maxAnswerRecordingRetries,
                '@id': id,
                expanded = !id,
            }
        } = this.props;

        return (
            <div className={(isDroppable ? 'droppable' : undefined)}>
                {!expanded ?
                    (<>
                        {this.props.prefix}
                        {this.props.currentValue.title}
                        {this.renderButtons(expanded)}
                    </>) : (
                        <Card className="editor-card">
                            <CardBody className="editor-card-body">
                                <div className='prefix-and-buttons'>
                                    {this.props.prefix}
                                    {this.renderButtons(expanded)}
                                </div>
                                <div className="video-question-title">
                                    <Field
                                        name={`${this.props.name}.title`}
                                        component={renderTextField}
                                        label="Question Statement"
                                        required={true}
                                        fullWidth
                                    />
                                </div>
                                <Field
                                    name={`${this.props.name}.required`}
                                    component={renderInlineRadioButtons}
                                    containerClass={"form-group mt-2"}
                                    type="checkbox"
                                    label="Mark as Required"
                                    required={true}
                                    choices={[
                                        {value: "true", label: 'Yes'},
                                        {value: "false", label: 'No'},
                                    ]}
                                    normalize={v => v === 'true'}
                                    onChange={(e) => this.props.updateVideoQuestionSettings(this.props.index, 'required', e.target.value === 'true')}
                                />
                                <FormLabel className="mt-3">
                                    How can Candidates respond to this question? Check all that apply.
                                    <span style={{color: 'red'}}> *</span>
                                </FormLabel>
                                <div className="clearfix" />
                                <Field
                                    component={renderCheckbox}
                                    name={`${this.props.name}.allowVideoAnswer`}
                                    label="Video"
                                    type="checkbox"
                                    containerClass={"float-left"}
                                    onChange={(e, value) => {
                                        this.props.updateVideoQuestionSettings(this.props.index, 'allowVideoAnswer', value);
                                    }}
                                />
                                <Field
                                    component={renderCheckbox}
                                    name={`${this.props.name}.allowAudioAnswer`}
                                    label="Audio"
                                    type="checkbox"
                                    containerClass={"float-left"}
                                    onChange={(e, value) => {
                                        this.props.updateVideoQuestionSettings(this.props.index, 'allowAudioAnswer', value);
                                    }}
                                />
                                <Field
                                    component={renderCheckbox}
                                    name={`${this.props.name}.allowTextAnswer`}
                                    label="Text"
                                    type="checkbox"
                                    disabled={thinkTimeIsEnabled}
                                    onChange={(e, value) => this.props.updateVideoQuestionSettings(this.props.index, 'allowTextAnswer', value)}
                                />

                                {(this.props.currentValue.allowVideoAnswer || this.props.currentValue.allowAudioAnswer) && (
                                    <>
                                        <p className={"video-question-settings"} style={{margin: '10px 0 0 7px'}}>Setting for Video and/or Audio</p>
                                        <VideoQuestionTimeLimitOption
                                            label={"Think Time"}
                                            change={this.props.change}
                                            minValue={1}
                                            fieldNameMinutes={`${this.props.name}.thinkTimeLimitMinutes`}
                                            fieldNameSeconds={`${this.props.name}.thinkTimeLimitSeconds`}
                                            currentValueMinutes={this.props.currentValue.thinkTimeLimitMinutes}
                                            currentValueSeconds={this.props.currentValue.thinkTimeLimitSeconds}
                                            onEnable={() => {
                                                this.props.change(`${this.props.name}.allowTextAnswer`, false);
                                                this.props.change(`${this.props.name}.maxAnswerRecordingRetries`, 1);

                                            }}
                                        />
                                        <div className={"clearfix"}>
                                            <div className={"float-left"}>
                                                <Switch
                                                    checked={(showMaxAnswerRecordingRetries)}
                                                    onChange={this.onMaxAnswerRecordingRetriesChange}
                                                    color="primary"
                                                    disabled={thinkTimeIsEnabled}
                                                />
                                            </div>
                                            <label className={"float-left mt-3" + (showMaxAnswerRecordingRetries ? ' text-dark' : '')}>Limit Re-records{showMaxAnswerRecordingRetries && (': ')}</label>
                                            {showMaxAnswerRecordingRetries && (
                                                <div className={"float-left ml-2 time-limits"} style={{width: 50}}>
                                                    <Field
                                                        component={renderTextField}
                                                        name={`${this.props.name}.maxAnswerRecordingRetries`}
                                                        type="number"
                                                        inputProps={{min: 1, max: 1000, step: 1, style: {textAlign: 'center'}}}
                                                        label={false}
                                                        placeholder={""}
                                                        normalize={v => parseInt(v)}
                                                        disabled={thinkTimeIsEnabled}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <VideoQuestionTimeLimitOption
                                            label={"Time Limit for Response"}
                                            change={this.props.change}
                                            fieldNameMinutes={`${this.props.name}.answerTimeLimitMinutes`}
                                            fieldNameSeconds={`${this.props.name}.answerTimeLimitSeconds`}
                                            currentValueMinutes={this.props.currentValue.answerTimeLimitMinutes}
                                            currentValueSeconds={this.props.currentValue.answerTimeLimitSeconds}
                                        />
                                    </>
                                )}
                                <Field
                                    component={VideoField}
                                    name={`${this.props.name}.questionVideo`}
                                    required={true}
                                    uploadingEndpoint={VIDEO_ENTRYPOINT + '/question-video/upload'}
                                    recordingEndpoint='question-video'
                                    recordingSocketType='question-video'
                                />
                            </CardBody>
                        </Card>
                    )}
            </div>
        );
    }
}
