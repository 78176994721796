import React, {Component} from "react";
import InputLabel from "@material-ui/core/InputLabel";
import NoteAdd from "@material-ui/icons/NoteAdd";
import {renderFromHelper} from "../../utils/form";
import Button from "../CustomButtons/Button";
import {Field} from "redux-form";
import {loadCompanyUsersForSelect} from "../../actions/CompanyUser/forSelect";
import SearchableSelect from "../../views/Components/SearchableSelect";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  label: {
    fontSize: 'x-large',
    fontWeight: '500',
  },
  selectCompanyUserFieldWrapper: {
    paddingBottom: '10px',
  }
};

class CompanyUserInvitations extends Component {
  constructor(props) {
    super(props);
    this.checkIsCompanyUserDisabled = this.checkIsCompanyUserDisabled.bind(this);
    this.disableUser = this.disableUser.bind(this);
    this.unDisableUser = this.unDisableUser.bind(this);
    this.state = {disabledUsers: []};
  }

  static defaultProps = {
    allowAdd: true,
    allowDelete: true,
    allowEdit: true,
  };

  componentDidMount() {
    this.props.fields.getAll().forEach(
      (invitation) => this.disableUser(invitation['companyUser'])
    );
    this.props.loadCompanyUsersForSelect();
  }

  checkIsCompanyUserDisabled = (companyUsers) => {
    return (companyUsers || []).map((companyUser) => {
      companyUser.isDisabled = this.state.disabledUsers.indexOf(companyUser.value) !== -1;
      return companyUser;
    })
  }

  disableUser = (userId) => {
    this.setState((state) => {
      const disabledUsers = state.disabledUsers;
      disabledUsers.push(userId);
      return {disabledUsers}
    });
  }

  unDisableUser = (userId) => {
    const disabled = this.state.disabledUsers.filter((disabledUser) => disabledUser !== userId);
    this.setState({disabledUsers: disabled});
  }

  render() {
    const {classes, fields, label, required, allowAdd, allowDelete, allowEdit, meta: {error}} = this.props;
    const companyUsers = this.checkIsCompanyUserDisabled(this.props.companyUsers);
    return (
      <div className='mb-4'>
        { label && (
          <>
          <InputLabel
            classes={{root: classes.label}}
            className='mb-0'
            required={required}>
              {label}
          </InputLabel><br/>
          </>
        )}
        {fields.map((name, index) => {
          const invitation = fields.get(index);
          return (
            <div key={index} className='mt-3'>
              <Field
                component={SearchableSelect}
                containerClass={"col-md-10 float-left pl-0 " + classes.selectCompanyUserFieldWrapper}
                label={null}
                name={name + '[companyUser]'}
                placeholder="Select Company User"
                options={companyUsers}
                isDisabled={!(allowEdit || !invitation['@id'])}
                onOptionValueChange={value => {
                  if (value) {
                    this.disableUser(value.value);
                  } else {
                    if (this.props.currentValues[index]['companyUser']) {
                      this.unDisableUser(this.props.currentValues[index]['companyUser']);
                    }
                  }
                }}
              />
              {allowDelete && (
              <div className="col-md-2 float-left pl-0">
                <button type='button' className='btn btn-danger' onClick={() => {
                  this.unDisableUser(invitation.companyUser);
                  fields.remove(index);
                }}>Remove</button>
              </div>
              )}
              <div className="clearfix" />
            </div>
          )
        })}
        {renderFromHelper(true, error)}
        {allowAdd && (
          <Button className='mt-3' color="info" onClick={() => fields.push({})}>
            <NoteAdd/>Invite Company User
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  companyUsers: state.companyUser.forSelect.retrieved,
});
const mapDispatchToProps = dispatch => ({
  loadCompanyUsersForSelect: () => dispatch(loadCompanyUsersForSelect('@id')),
});

CompanyUserInvitations = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyUserInvitations);

export default withStyles(styles)(CompanyUserInvitations);
