import React, {Component} from "react";
import { Player } from "video-react";
import PropTypes from "prop-types";

class VideoPlayer extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    aspectRatio: PropTypes.string,
    preload: PropTypes.string,
    playsInline: PropTypes.bool,
  }

  static defaultProps = {
    aspectRatio: '16:9',
    preload: 'auto',
    playsInline: true,
  };

  getFixedVideoSrc = (src) => {
    if (!src || src.startsWith('blob:')) {
      return src;
    }

    //A special hack for mobile safari to display video thumbnail
    return `${src}#t=0.001`;
  }

  render() {
    const {children, innerRef, src, ...otherProps} = this.props;
    return (
      <Player
        preload='auto'
          ref={innerRef}
        {...otherProps}
        src={this.getFixedVideoSrc(src)}
      >
        {children}
      </Player>
    )
  }
}

export default React.forwardRef(
  (props, ref) => (
      <VideoPlayer
        innerRef={ref} {...props}
      />
    )
);
