import React from "react";
import {connect} from "react-redux";
import {endTesting, testingStarted} from "../../actions/LiveInterviewPage/testing";
import AudioVideoTestComponent from "../VideoTest/AudioVideoTest";
import {closeStreamManager} from "../../utils/streamManager";
import {createAudioVideoTestPublisher} from "../../utils/liveInterview";

class AudioVideoTest extends React.Component {
  state = {
    streamManager: null,
  }

  componentDidMount() {
    createAudioVideoTestPublisher().then(publisher => {
      this.props.testingStarted();
      this.setState({streamManager: publisher})
    });
  }

  componentWillUnmount() {
    if (this.state.streamManager) {
      closeStreamManager(this.state.streamManager);
    }
  }

  render() {
    const {streamManager} = this.state;
    const {endTesting} = this.props;
    return (
      streamManager && (
        <AudioVideoTestComponent
          streamManager={streamManager}
          onTestingEnd={endTesting}
          autoStart={true}
        />
      )
    );
  }
}

const mapStateToProps = state => ({
  streamManager: state.liveInterviewPage.publisher,
});

const mapDispatchToProps = dispatch => ({
  testingStarted: () => dispatch(testingStarted()),
  endTesting: () => dispatch(endTesting()),
});

AudioVideoTest = connect(
  mapStateToProps,
  mapDispatchToProps
)(AudioVideoTest);

export default AudioVideoTest;
