import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import classnames from 'classnames';
import {renderLinks} from "../../utils/entityList";
import DateTimeUserTimeZoneWithTimezone from "../DateTime/DateTimeUserTimezoneWithTimezone";
import {isGranted} from "../../utils/permissions";
import {downloadFileFromApi} from "../../utils/dataAccess";
import {getLiveInterviewCalendarUrl} from "../../utils/urls";
import {CopyToClipboard} from "../CopyToClipboard/CopyToClipboard";
import IconButton from "@material-ui/core/IconButton";
import CopyContent from "../Icons/CopyContent";
import {Link} from "react-router-dom";
import IsGranted from "../IsGranted";
import LiveInterviewTagsForm from "./LiveInterviewTagsForm";
import Table from "@material-ui/core/Table";
import React from "react";
import Rating from "material-ui-rating";

export default class OverviewTable extends React.Component {
  render() {
    const { item, liveInterviewUrl } = this.props;

    console.log('Item:', item);

    return (
      <Table className="table table-overview">
        <TableBody>
          <TableRow>
            <TableCell scope="row">Title:</TableCell>
            <TableCell>{item['title']}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell scope="row">Job:</TableCell>
            <TableCell>{renderLinks('../jobs', item['job'], (job) => job['title'], true)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Creator:</TableCell>
            <TableCell>
              {renderLinks('../users', item['companyUser'], (companyUser) => companyUser.firstName + ' ' + companyUser.lastName, true)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Invited Company Users:</TableCell>
            <TableCell>
              {item.candidateInvitations && item.companyUserInvitations.length === 0 && (
                '-'
              )}
              {item.companyUserInvitations && (item.companyUserInvitations.map(
                (invitation, index) => {
                  const companyUser = invitation.companyUser;
                  return (
                    <span key={'001'+index}>
                                  {renderLinks('../users', companyUser, (companyUser) => companyUser.firstName + ' ' + companyUser.lastName, true)}
                      {index !== (item.companyUserInvitations.length - 1) && (', ')}
                                </span>
                  );
                }
              ))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell scope="row">Recording Enabled:</TableCell>
            <TableCell>{item['recordingEnabled'] ? 'Yes' : 'No'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell scope="row">Scheduled Start Time:</TableCell>
            <TableCell>
              <DateTimeUserTimeZoneWithTimezone time={item.scheduledStartTime}/>
            </TableCell>
          </TableRow>
          {isGranted('get_calendar', item) && (
            <TableRow>
              <TableCell scope="row">Calendar:</TableCell>
              <TableCell>
                <a href={'#'} onClick={() => downloadFileFromApi(getLiveInterviewCalendarUrl(item['id']))}>
                  Download ICS file
                </a>
              </TableCell>
            </TableRow>
          )}
          {!item.completedAt && (
            <TableRow>
              <TableCell>
                Join Link:
              </TableCell>
              <TableCell className="cell-overflow">
                <a href={liveInterviewUrl} target="_blank" style={{'overflow': 'hidden', 'textOverflow': 'ellipsis'}}>
                  {liveInterviewUrl}
                </a>
                <CopyToClipboard text={liveInterviewUrl}>
                  <IconButton style={{'height': '1em', 'outline': 'none'}}>
                    <CopyContent style={{'height': '0.6em'}}>
                      Copy To Clipboard
                    </CopyContent>
                  </IconButton>
                </CopyToClipboard>
              </TableCell>
            </TableRow>
          )}
          {item.startedAt && (
            <TableRow>
              <TableCell scope="row">Started At:</TableCell>
              <TableCell>
                <DateTimeUserTimeZoneWithTimezone time={item.startedAt}/>
              </TableCell>
            </TableRow>)
          }
          {item.completedAt && (
            <>
              <TableRow>
                <TableCell scope="row">Completed At:</TableCell>
                <TableCell>
                  <DateTimeUserTimeZoneWithTimezone time={item.completedAt}/>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row" className={classnames({'align-middle': item['totalAverageRating']})}>Total Average Rating:</TableCell>
                <TableCell className="clearfix">
                  {item['totalAverageRating'] && (
                    <div>
                      <Rating
                        value={parseFloat(item['totalAverageRating'])}
                        max={5}
                        className="total-interview-rating"
                        readOnly={true}
                      />
                      <span className="total-interview-rating-info">{item['totalAverageRating']} - </span>
                    </div>
                  )}
                  <Link to={`../../live-interview-reviews/${encodeURIComponent(item['@id'])}/reviews`} className="float-left" style={item['totalAverageRating'] ? { margin: '11px 0 0 0' } : {}}>View Reviews</Link>
                </TableCell>
              </TableRow>
            </>
          )}
          <IsGranted action="update_tags" object={item}>
            <TableRow>
              <TableCell scope="row">Tags:</TableCell>
              <TableCell>
                {isGranted('update_tags', item) ? (
                  <LiveInterviewTagsForm object={item}/>
                ) : (
                  item.tags.length > 0 ? item.tags.map(tag => tag.name).join(', ') : '-'
                )}
              </TableCell>
            </TableRow>
          </IsGranted>
        </TableBody>
      </Table>
    );
  }
}
