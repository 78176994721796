import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {history} from "../../store";
import InvitationLoginForm from "./InvitationLoginForm";
import {connect} from "react-redux";
import {checkIfAccountExists, loginAfterRegistration, loginInvitedUser} from "../../actions/LiveInterviewPage/auth";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import RegistrationForm from "../Candidate/RegistrationForm";
import {register} from "../../actions/Candidate/register";
import InvitationEmailForm from "./InvitationEmailForm";
import {initializeByRoomCode} from "../../actions/LiveInterviewPage/interview";
import {currentUserRoles} from "../../utils/auth";
import {withRouter} from "react-router";
import Dialog from "../../views/Components/Dialog/Dialog";

const style = {
  dialogTitle: {
    textAlign: 'center',
    '& h2': {
      marginRight: '2rem',
      transition: '0.3s',
    }
  },
  dialogAction: {
    textAlign: "center",
  },
  formTitle: {
    marginBottom: '.8em',
  },
  closeButton: {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
  },
}

const submitButtonProps = {
  variant: "contained",
  color: "info",
}

class AcceptInvitationDialog extends React.Component {
  state = {
    anonymousUserEmail: null,
    headerSize: '2.6em'
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !prevProps.invitation && this.props.invitation && this.props.loggedIn ||
      this.props.invitation && !prevProps.loggedIn && this.props.loggedIn
    ) {
      this.props.handleCloseDialog();
    }

    if (!prevProps.registered && this.props.registered) {
      this.props.loginAfterRegistration(this.props.registered);
    }

    if (!prevProps.loggedIn && this.props.loggedIn) {
      this.props.initializeByRoomCode(this.props.liveInterview.roomCode);
    }
  }

  handleGoToAccount = () => {
    history.push('/candidate/view-profile');
  };

  handleScroll = (e) => {
    const wWidth = document.documentElement.clientWidth;
    if (wWidth <= 820 && this.state.headerSize !== '1.2em') {
      const hiddenContentHeight = e.currentTarget.scrollHeight - e.currentTarget.offsetHeight;
      const percentScrolled = e.currentTarget.scrollTop * 100 / hiddenContentHeight;
      if (percentScrolled >= 50) {
        this.setState({headerSize: '1.2em'});
      }
    }
  };

  renderInfoContent = (name) => {
    const { classes } = this.props;
    return (
      <>
      <p>Congratulations, {name}! Now it’s time to prepare for your interview.</p>
      <ol>
        <li>
          <b>Check your inbox.</b> Save the confirmation email so you can find it when you’re ready to join
          the interview. You can also add the event to your calendar using the attachment we sent you.
        </li>
        <li>
          <b>Update your account.</b> Make sure your profile is up-to-date with your latest contact details
          and a current CV or resume.
        </li>
        <li>
          <b>On the day of the interview:</b>
          <ul>
            <li>Log in at least 15 minutes prior to the interview start time.</li>
            <li>Test your video settings.</li>
            <li>Join the interview.</li>
          </ul>
        </li>
      </ol>
      <p className={classes.dialogAction}>
        <button type="button" className="btn btn-info mr-2" onClick={this.props.handleCloseDialog}>
          Back to Interview
        </button>
        <button type="button" className="btn btn-info ml-2" onClick={this.handleGoToAccount}>
          Go to Account
        </button>
      </p>
      </>
    )
  };

  renderLoginContent = (name, email) => {
    const { classes, error, loggingIn } = this.props;
    return (
      <>
        <p>Congratulations, {name}! Now it’s time to prepare for your interview.</p>
        <ol>
          <li>
            <b>Check your inbox.</b> Save the confirmation email so you can find it when you’re ready to join
            the interview. You can also add the event to your calendar using the attachment we sent you.
          </li>
          <li>
            <b>Log into your account.</b> Make sure your profile is up-to-date with your latest contact details
            and a current CV or resume.
          </li>
          <li>
            <b>On the day of the interview:</b>
            <ul>
              <li>Log in at least 15 minutes prior to the interview start time.</li>
              <li>Test your video settings.</li>
              <li>Join the interview.</li>
            </ul>
          </li>
        </ol>
        <div className={classes.dialogAction}>
          <h3 className={classes.formTitle}>We found an account for you.</h3>
          {error && (
            <div className="alert alert-danger" role="alert">
              <span className="fa fa-exclamation-triangle" aria-hidden="true" /> {error}
            </div>
          )}
          <GridContainer justify="center">
            <GridItem xs={12} sm={8} md={5}>
              <InvitationLoginForm
                initialValues={{email: email}}
                onSubmit={(values) => {
                  this.props.login(values.email, values.password)
                }}
                loading={loggingIn}
              />
            </GridItem>
          </GridContainer>
        </div>
      </>
    )
  };

  renderRegisterContent = (email, firstName, lastName, phoneNumber) => {
    const {registering} = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={8}>
        <p>Congratulations, {firstName}! Now it’s time to prepare for your interview.</p>
        <ol>
          <li>
            <b>Create your account.</b> Send your latest contact details and a current CV or resume to the recruiter
            using the form on the right. This will be saved for future interviews.
          </li>
          <li>
            <b>Check your inbox.</b> Save the confirmation email so you can find it when you’re ready to join the
            interview. You can also add the event to your calendar using the attachment  we sent you.
          </li>
          <li>
            <b>On the day of the interview:</b>
            <ul>
              <li>Log in at least 15 minutes prior to the interview start time.</li>
              <li>Test your video settings.</li>
              <li>Join the interview.</li>
            </ul>
          </li>
        </ol>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <RegistrationForm
            initialValues={{
              firstName: firstName,
              lastName: lastName,
              phoneNumber: phoneNumber,
              email: email,
              freeResumeReview: true,
              professionMapping: {},
            }}
            submitButtonText='Create Account'
            submitButtonProps={submitButtonProps}
            onSubmit={this.props.register}
            loading={registering}
          />
        </GridItem>
      </GridContainer>
    )
  }

  renderAnonymousContent = () => {
    const {
      classes, checkIfAccountExists, accountExists,
      checkingAccountExists, error, registering, loggingIn} = this.props;
    const {anonymousUserEmail} = this.state;
    const accountChecked = anonymousUserEmail && !checkingAccountExists;
    const twoColumns = accountChecked && !accountExists;
    return (
      <GridContainer>
        <GridItem xs={12} sm={twoColumns ? 6 : 12} md={twoColumns ? 8 : 12}>
          <p>Congratulations! Now it’s time to prepare for your interview.</p>
          <ol>
            <li>
              <b>Log in or create an account.</b> Make sure your profile is up-to-date with your latest contact
              details and a current CV or resume.
            </li>
            <li>
              <b>Check your inbox.</b> Save the confirmation email so you can find it when you’re ready to join
              the interview. You can also add the event to your calendar using the attachment we sent you.
            </li>
            <li>
              <b>On the day of the interview:</b>
              <ul>
                <li>Log in at least 15 minutes prior to the interview start time.</li>
                <li>Test your video settings.</li>
                <li>Join the interview.</li>
              </ul>
            </li>
          </ol>
          {!accountChecked && (
            <div className={classes.dialogAction}>
              <h3 className={classes.formTitle}>Enter your email to get started.</h3>
              <GridContainer justify="center">
                <GridItem xs={12} sm={8} md={5}>
                  <InvitationEmailForm
                    checking={checkingAccountExists}
                    onSubmit={(values) => {
                      checkIfAccountExists(values.email);
                      this.setState({anonymousUserEmail: values.email})
                    }}
                  />
                </GridItem>
              </GridContainer>
            </div>
          )}
          {accountChecked && accountExists && (
            <div className={classes.dialogAction}>
              <h3 className={classes.formTitle}>We found an account for you.</h3>
              {error && (
                <div className="alert alert-danger" role="alert">
                  <span className="fa fa-exclamation-triangle" aria-hidden="true" /> {error}
                </div>
              )}
              <GridContainer justify="center">
                <GridItem xs={12} sm={8} md={5}>
                  <InvitationLoginForm
                    initialValues={{email: anonymousUserEmail}}
                    onSubmit={(values) => {
                      this.props.login(values.email, values.password)
                    }}
                    loading={loggingIn}
                  />
                </GridItem>
              </GridContainer>
            </div>
          )}
        </GridItem>
        {twoColumns && (
        <GridItem xs={12} sm={6} md={4}>
          <RegistrationForm
            initialValues={{
              email: anonymousUserEmail,
              freeResumeReview: true,
              professionMapping: {},
            }}
            hideEmail={true}
            submitButtonText='Create Account'
            submitButtonProps={submitButtonProps}
            onSubmit={this.props.register}
            loading={registering}
          />
        </GridItem>
        )}
      </GridContainer>
    );
  }

  renderDialogContent = (invitation) => {
    const {loggedIn, accountExists} = this.props;
    if (loggedIn && invitation) {
      return this.renderInfoContent(invitation.firstName);
    }

    if (accountExists && invitation) {
      return this.renderLoginContent(invitation.firstName, invitation.email);
    }

    if (invitation) {
      return this.renderRegisterContent(invitation.email, invitation.firstName, invitation.lastName, invitation.phoneNumber);
    }

    return this.renderAnonymousContent();
  };

  render() {
    const { classes, invitation, screenSize } = this.props;
    return (
      <Dialog
        open={this.props.dialogOpened}
        onClose={this.props.handleCloseDialog}
        closeButtonText={null}
        dialogProperties={{maxWidth: "xl", fullScreen: !screenSize.md}}
        contentProperties={{onScroll: this.handleScroll.bind(this)}}
        title={"You Have Accepted This Interview"}
      >
        {this.renderDialogContent(invitation)}
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  loggedIn: state.liveInterviewPage.auth.loggedIn,
  loggingIn: state.liveInterviewPage.auth.loggingIn,
  registering: state.candidate.register.loading,
  registered: state.candidate.register.registered,
  accountExists: state.liveInterviewPage.auth.accountExists,
  checkingAccountExists: state.liveInterviewPage.auth.checkingAccountExists,
  error: state.liveInterviewPage.auth.error,
  screenSize: state.screen.size,
});

const mapDispatchToProps = dispatch => ({
  login: (email, password) => dispatch(loginInvitedUser(email, password)),
  register: values => dispatch(register(values)),
  loginAfterRegistration: data => dispatch(loginAfterRegistration(data)),
  checkIfAccountExists: (email) => dispatch(checkIfAccountExists(false, {email})),
  initializeByRoomCode: (roomCode) => dispatch(initializeByRoomCode(roomCode, currentUserRoles())),
});

AcceptInvitationDialog = connect(mapStateToProps, mapDispatchToProps)(AcceptInvitationDialog);

AcceptInvitationDialog = withStyles(style)(AcceptInvitationDialog);

AcceptInvitationDialog = withRouter(AcceptInvitationDialog);

export default AcceptInvitationDialog;
