import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { retrieve, reset } from '../../actions/Partner/show';
import { del } from '../../actions/Partner/delete';
import Moment from 'react-moment';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {handleSwitchUser} from "../SwitchUser/SwitchUser";
import withStyles from "@material-ui/core/styles/withStyles";
import IsGranted from "../IsGranted";
import {IRItoID} from "../../utils/dataAccess";
import {firstLetterToUppercase} from "../../utils/firstLetterToUpperCase";
import FlashMessages from "../../views/Components/FlashMessages";

const style = {
  image: {
    display: 'block',
    width: 'auto',
    height: 'auto',
    maxHeight: '500px',
    maxWidth: '100%',
  },
};

class PartnerShow extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    deleteError: PropTypes.string,
    deleteLoading: PropTypes.bool.isRequired,
    deleted: PropTypes.object,
    del: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  del = () => {
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.del(this.props.retrieved);
  };

  render() {
    const classes = this.props.classes;
    const item = this.props.retrieved;

    if (this.props.deleted)
      this.props.history.push({
        pathname: `..`,
        state: {successMessage: 'Partner successfully deleted.'}
      });

    return (
      <div>

        <h1>Partner &quot;{item && item['name']}&quot;</h1>

        <FlashMessages location={this.props.location} />
        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}
        {this.props.deleteError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.deleteError}
          </div>
        )}

        <div className="grid-buttons">
          <Link to=".." className="btn btn-primary">
            Back to list
          </Link>
          {item && (
            <IsGranted action="edit" object={item}>
            <Link to={`../edit/${encodeURIComponent(item['@id'])}`} className="btn btn-warning">Edit</Link>
            </IsGranted>
          )}
          {item && (
            <Link to={`../../partner-admins/`} className="btn btn-success">Partner Admins</Link>
          )}
          {item && item['partnerAdmin'] && (
              <IsGranted action="impersonate" object={item['partnerAdmin']}>
              <button className="btn btn-info" onClick={() => handleSwitchUser(IRItoID(item['partnerAdmin']['@id']))}>Impersonate</button>
              </IsGranted>
          )}
          {item && (
            <IsGranted action="delete" object={item}>
            <button onClick={this.del} className="btn btn-danger">
              Delete
            </button>
            </IsGranted>
          )}
        </div>

        {item && (
          <div style={{'overflowX': 'auto'}}>
            <Table className="table table-responsive table-striped table-hover">
              <TableHead>
                <TableRow>
                  <TableCell width="20%">Field</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell scope="row">Name</TableCell>
                  <TableCell>{item['name']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Status</TableCell>
                  <TableCell>{firstLetterToUppercase(item['status'])}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Grace Period Days</TableCell>
                  <TableCell>{item['gracePeriodDays']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Trial Days</TableCell>
                  <TableCell>{item['trialDays']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Home Page Slogan</TableCell>
                  <TableCell>{item['homePageSlogan']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Description</TableCell>
                  <TableCell>{item['description']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Domain</TableCell>
                  <TableCell>{item['domain']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">From Email</TableCell>
                  <TableCell>{item['fromEmail']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Sales Email</TableCell>
                  <TableCell>{item['salesEmail']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Sales Phone</TableCell>
                  <TableCell>{item['salesPhone']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Support Email</TableCell>
                  <TableCell>{item['supportEmail']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Privacy Email</TableCell>
                  <TableCell>{item['privacyEmail']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Support Phone</TableCell>
                  <TableCell>{item['supportPhone']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Accounting Email</TableCell>
                  <TableCell>{item['accountingEmail']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Accounting Phone</TableCell>
                  <TableCell>{item['accountingPhone']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">State</TableCell>
                  <TableCell>{item['state'] ? item['state']['name'] : '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Country</TableCell>
                  <TableCell>{item['country'] ? item['country']['name'] : '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Address</TableCell>
                  <TableCell>{`${item.address} ${item.city}, ${item.state ? item.state.code : ''} ${item.zipCode}`}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Primary Color</TableCell>
                  <TableCell>{item['primaryColor']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Accent Color</TableCell>
                  <TableCell>{item['accentColor']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Email Header Color</TableCell>
                  <TableCell>{item['emailHeaderColor']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Logo</TableCell>
                  <TableCell>
                    {item['logo'] && (
                        <img className={classes.image} src={item['logo']['url']}/>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Inverse Logo</TableCell>
                  <TableCell>
                    {item['inverseLogo'] && (
                        <img className={classes.image} src={item['inverseLogo']['url']}/>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Email Logo</TableCell>
                  <TableCell>
                    {item['emailLogo'] && (
                        <img className={classes.image} src={item['emailLogo']['url']}/>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Favicon</TableCell>
                  <TableCell>
                    {item['favicon'] && (
                      <img className={classes.image} src={item['favicon']['url']}/>
                    )}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Resume Review Sales URL</TableCell>
                  <TableCell>{item['resumeReviewSalesUrl']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Resume Rewriting Sales URL</TableCell>
                  <TableCell>{item['resumeRewritingSalesUrl']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Interview Training Sales URL</TableCell>
                  <TableCell>{item['interviewTrainingSalesUrl']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Resume Distribution Sales URL</TableCell>
                  <TableCell>{item['resumeDistributionSalesUrl']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Allow Discover Users By API</TableCell>
                  <TableCell>
                    {item['partnerUsersDiscoverable'] ? "Yes" : "No" }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Company Registration Enabled</TableCell>
                  <TableCell>
                    {item['companyRegistrationEnabled'] ? "Yes" : "No" }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Created At</TableCell>
                  <TableCell>{item['createdAt'] && (<Moment>{item['createdAt']}</Moment>)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Updated At</TableCell>
                  <TableCell>{item['updatedAt'] && (<Moment>{item['updatedAt']}</Moment>)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Date Format</TableCell>
                  <TableCell>{item['dateFormat']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Enable Free Resume Review Checkbox</TableCell>
                  <TableCell>{item['enableFreeResumeReviewCheckbox'] ? 'Yes' : 'No'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Display default content (libraries, categories, etc.)</TableCell>
                  <TableCell>{item['displayDefaultContent'] ? 'Yes' : 'No'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Braintree Merchant ID</TableCell>
                  <TableCell>{item['braintreeMerchantId']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Braintree Merchant Public Key</TableCell>
                  <TableCell>{item['braintreeMerchantPublicKey']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Braintree Merchant Private Key</TableCell>
                  <TableCell>{item['braintreeMerchantPrivateKey']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Braintree Client Tokenization Key</TableCell>
                  <TableCell>{item['braintreeClientTokenizationKey']}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    );
  }

  renderLinks = (type, items) => {
    if (Array.isArray(items)) {
      return items.map((item, i) => (
        <div key={i}>{this.renderLinks(type, item)}</div>
      ));
    }

    return (
      <Link to={`../../${type}/show/${encodeURIComponent(items)}`}>{items}</Link>
    );
  };
}

PartnerShow = withStyles(style)(PartnerShow);

const mapStateToProps = state => ({
  retrieved: state.partner.show.retrieved,
  error: state.partner.show.error,
  loading: state.partner.show.loading,
  eventSource: state.partner.show.eventSource,
  deleteError: state.partner.del.error,
  deleteLoading: state.partner.del.loading,
  deleted: state.partner.del.deleted
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  del: item => dispatch(del(item)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerShow);
