import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";
import {loadCountriesForSelect} from "../../actions/Country/forSelect";

class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  componentDidMount() {
    this.props.loadCountriesForSelect(this.props.site.country && this.props.site.country.code);
  }

  render() {
    return (
      <RenderFilterForm {...this.props} fields={[
        {'name': 'id', 'label': 'ID', 'placeholder': 'ID', 'type': 'number'},
        {'name': 'name', 'label': 'Name', 'placeholder': 'Name', 'type': 'text'},
        {'name': 'country', 'label': 'Country', 'placeholder': 'Country', 'type': 'selectSearch', 'options': this.props.countries},
      ]} />
    );
  }
}

const mapStateToProps = state => {
  let countries = state.country.forSelect.retrieved;
  let site = state.siteDetails.siteDetails.retrieved;

  return { countries, site };
};

const mapDispatchToProps = dispatch => ({
  loadCountriesForSelect: (countryCode) => dispatch(loadCountriesForSelect('@id', countryCode)),
});

FilterForm = reduxForm({
  form: 'filter_states',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterForm);
