import React from "react";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";
import VideoRecorder from "../../components/VideoRecorder/VideoRecorder";
import VideoUploader from "../../components/VideoUploader/VideoUploader";
import Button from "../../components/CustomButtons/Button";
import './VideoField.scss';
import {isVideoNeedToReEncode} from "../../utils/videoEntity";
import classNames from "classnames";
import EntityVideoPlayerWithControl from "./EntityVideoPlayerWithControl";

const MODE_UPLOAD = 'upload';
const MODE_RECORDING = 'recording';

class VideoField extends React.Component {
  static propTypes = {
    input: PropTypes.object.isRequired,
    uploadingEndpoint: PropTypes.string,
    recordingEndpoint: PropTypes.string,
    recordingSocketType: PropTypes.string,
    initialMode: PropTypes.string,
    autoHideModes: PropTypes.bool,
    uploadLabel: PropTypes.string,
    recordLabel: PropTypes.string,
    additionalModes: PropTypes.array,
  };

  static defaultProps = {
    uploadLabel: 'Upload',
    recordLabel: 'Record',
    additionalModes: [],
    autoHideModes: true,
  };

  constructor(props) {
    super(props);

    this.player = React.createRef();
    this.state = {
      mode: this.getInitialMode(props),
    }
  }

  getInitialMode = (props) => {
    let initMode = null;
    if (props.initialMode) {
      initMode = props.initialMode;
    } else {
      const modes = this.getAvailableModes(props);
      if (modes && modes.length > 0) {
        initMode = modes[0].value;
      }
    }

    return initMode;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.initialMode && prevProps.initialMode !== this.props.initialMode) {
      this.setState({mode: this.props.initialMode});
    }
  }

  changeMode = (mode) => {
    this.setState({mode: mode});
    this.props.input.onChange(null);
    this.props.additionalModes.forEach(additionalMode => {
      if (additionalMode.onChange && typeof additionalMode.onChange === 'function') {
        additionalMode.onChange(mode);
      }
    })
  }

  getAvailableModes = (props) => {
    let modes = [];
    if (props.uploadingEndpoint) {
      modes.push({
        value: MODE_UPLOAD,
        label: props.uploadLabel
      })
    }

    if (props.recordingEndpoint || props.recordingSocketType) {
      modes.push({
        value: MODE_RECORDING,
        label: props.recordLabel
      })
    }

    if (props.additionalModes && props.additionalModes.length > 0) {
      props.additionalModes.forEach(mode => {
        modes.push({
          value: mode.value,
          label: mode.label,
        })
      })
    }

    return modes;
  }

  renderVideo = () => {
    const {input} = this.props;
    const video = input ? input.value : {};
    const readyToPlay = !isVideoNeedToReEncode(video);

    return (
      <div className={classNames('video-player', {'not-ready-to-play': !readyToPlay})}>
        <EntityVideoPlayerWithControl
          entity={video}
          progressIndicatorClassName={'video-field-progress-indicator'}
        >
          <Button
            className="control-button"
            onClick={() => input.onChange(null)}
          >
            Remove Video
          </Button>
        </EntityVideoPlayerWithControl>
      </div>
    );
  }

  playVideo = () => {
    if (!this.player || !this.player.current) {
      return;
    }

    this.player.current.seek(0);
    this.player.current.play();
  };

  renderFieldElement = () => {
    const {input} = this.props;
    const {mode} = this.state;
    if (mode === MODE_UPLOAD) {
      const {uploadingEndpoint} = this.props;
      return (
        <VideoUploader
          input={input}
          endpoint={uploadingEndpoint}
        />
      );
    }

    if (mode === MODE_RECORDING) {
      const {recordingEndpoint, recordingSocketType} = this.props;
      return (
        <VideoRecorder
          onRecorded={input.onChange}
          endpoint={recordingEndpoint}
          socketRecordingType={recordingSocketType}
        />
      );
    }

    const customMode = (this.props.additionalModes && this.props.additionalModes.find(additionalMode => additionalMode.value === mode));
    if (customMode && customMode.component) {
      if (typeof customMode.component === 'function') {
        return customMode.component();
      } else {
        return customMode.component;
      }
    }

    return null;
  }

  render() {
    const {input, meta: {touched, error}, autoHideModes} = this.props;
    const video = input ? input.value : null;
    const modes = this.getAvailableModes(this.props);
    return (
      <div className="form-group">
        <div>
          {(!video || !autoHideModes) && modes.length > 1 && (
            modes.map(item =>
              <FormControlLabel
                key={item.value}
                checked={this.state.mode === item.value}
                control={<Radio onChange={() => this.changeMode(item.value)} />}
                label={item.label}
                labelPlacement="end"
              />
            )
          )}
        </div>
        {touched && error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
            {error}
          </div>
        )}
        <div className={"video-field"}>
          {video ? this.renderVideo() : this.renderFieldElement()}
        </div>
      </div>
    );
  }
}

export default VideoField;
