import {fetchJSON} from "../../utils/dataAccess";

export function loadRequestTemplatesForSelect() {
  return dispatch => {
    dispatch(loading(true));
    fetchJSON('/request_templates?pagination=false').then((jobs) => {
      dispatch(retrieved(jobs['hydra:member'].map(
        function (requestTemplate) {
          return {
            value: requestTemplate['@id'],
            label: requestTemplate.title,
          }
        }
      )));
      dispatch(loading(false));
    }).catch(e => {
      dispatch(loading(false));
      dispatch(error(e.message));
    })
  };
}

export function loading(loading) {
  return {type: 'REQUEST_TEMPLATE_FOR_SELECT_LOADING', loading};
}

export function error(error) {
  return {type: 'REQUEST_TEMPLATE_FOR_SELECT_ERROR', error};
}

export function retrieved(retrieved) {
  return {type: 'REQUEST_TEMPLATE_FOR_SELECT_RETRIEVED', retrieved};
}
