import {OpenVidu} from "openvidu-browser";
let openViduInstance;

export function getOpenViduSingleton() {
  if (!openViduInstance) {
    openViduInstance = new OpenVidu();
    setUpOpenVidu(openViduInstance);
  }
  return openViduInstance;
}

export function getNewOpenVidu() {
  const openVidu = new OpenVidu();
  setUpOpenVidu(openVidu);
  return openVidu;
}

function setUpOpenVidu(openViduInstance) {
  const SEND_WAIT_TIME = 300;

  const originalStartWs = openViduInstance.startWs;
  const startWsWithRetry = function(onConnectSucces) {
    originalStartWs.call(openViduInstance, onConnectSucces);
    const client = this.jsonRpcClient;
    const originalSend = client.send;

    const sendWithRetry = function(method, params, callback) {
      const retryFunc = function(attempt) {
        try {
          originalSend.call(client, method, params, callback);
        } catch (e) {
          if (e instanceof DOMException && e.name === 'InvalidStateError' && attempt <= 3) {
            setTimeout(() => retryFunc(attempt++), SEND_WAIT_TIME);
          } else {
            throw e;
          }
        }
      };
      retryFunc(1);
    };

    sendWithRetry.bind(client);
    client.send = sendWithRetry;
  };

  startWsWithRetry.bind(openViduInstance);
  openViduInstance.startWs = startWsWithRetry;
}
