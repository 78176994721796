import React, { Component } from 'react';
import { connect } from 'react-redux';
import { companyStatistic, reset } from '../../actions/CompanyStatistic/companyStatistic';
import SearchableSelect from "react-select";
import './dashboard.scss';
import {CustomChart} from "./CustomChart";
import {CustomPie} from "./CustomPie";
import Datetime from "react-datetime";
import CustomButton from "../CustomButtons/Button";
import {Boxes} from "./Boxes";

export const STATISTIC_FILTER_TODAY = 'today';
export const STATISTIC_FILTER_THIS_WEEK = 'this_week';
export const STATISTIC_FILTER_THIS_MONTH = 'this_month';
export const STATISTIC_FILTER_THIS_QUARTER = 'this_quarter';
export const STATISTIC_FILTER_THIS_YEAR = 'this_year';
export const STATISTIC_FILTER_LAST_YEAR = 'last_year';
export const STATISTIC_FILTER_CUSTOM = 'custom';

const ON_DEMAND_LIGHT_COLOR = '#c096e8';
const ON_DEMAND_DARK_COLOR = '#9b4be7';
const LIVE_INTERVIEW_LIGHT_COLOR = '#8fbff1';
const LIVE_INTERVIEW_DARK_COLOR = '#3c92d2';

class CompanyDashboard extends Component {
  static propTypes = {};

  state = {
    stickFilter: false,
    period: {label: 'This Year', value: STATISTIC_FILTER_THIS_YEAR},
    from: null,
    to: null,
  };

  constructor(props) {
    super(props);
    this.chartsBox = React.createRef();
  }

  componentDidMount() {
    this.props.companyStatistic();
    window.addEventListener("resize", this.updateChartBoxSizes);
    window.addEventListener("scroll", this.onScroll, true);
  }

  componentWillUnmount() {
    this.props.reset();
    window.removeEventListener("resize", this.updateChartBoxSizes);
    window.removeEventListener("scroll", this.onScroll);
  }

  updateChartBoxSizes = () => {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.forceUpdate();
    }, 200);
  }

  onScroll = (e) => {
    clearTimeout(this.scrollTimeoutId);
    this.scrollTimeoutId = setTimeout(() => {
      if (
        this.chartsBox.current &&
        this.state.stickFilter !== (e.target.scrollTop > (this.chartsBox.current.offsetTop - 85)) &&
        e.target.classList && e.target.classList.contains('dashboard-main-panel')
      ) {
        this.setState({
          stickFilter: e.target.scrollTop > (this.chartsBox.current.offsetTop - 85)
        });
      }
    }, 30);
  }

  selectPeriod = (period) => {
    this.setState({period: period});

    if (STATISTIC_FILTER_CUSTOM !== period.value) {
      this.setState({
        from: null,
        to: null,
      });

      this.props.companyStatistic({
        period: period.value,
      });
    }
  };

  applyCustomFilters = () => {
    this.props.companyStatistic({
      period: this.state.period.value,
      from: this.state.from ? this.state.from.toDate() : null,
      to: this.state.to ? this.state.to.toDate() : null,
    });
  }

  resetCustomFilters = () => {
    this.selectPeriod({label: 'This Year', value: STATISTIC_FILTER_THIS_YEAR});
  }

  render() {
    const item = this.props.companyStatisticData;
    const chartsData = item ? item['_charts'] : null;
    const piesData = item ? item['_pies'] : null;
    const boxWidth = this.chartsBox.current ? this.chartsBox.current.clientWidth : null;

    const filterOptions = [
      {label: 'Today', value: STATISTIC_FILTER_TODAY},
      {label: 'This Week', value: STATISTIC_FILTER_THIS_WEEK},
      {label: 'This Month', value: STATISTIC_FILTER_THIS_MONTH},
      {label: 'This Quarter', value: STATISTIC_FILTER_THIS_QUARTER},
      {label: 'This Year', value: STATISTIC_FILTER_THIS_YEAR},
      {label: 'Last Year', value: STATISTIC_FILTER_LAST_YEAR},
      {label: 'Custom', value: STATISTIC_FILTER_CUSTOM},
    ];

    return (
      <div className={'company-dashboard'}>
        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}

        <Boxes item={item} />

        <div className={"dashboard-charts"} ref={this.chartsBox} style={this.state.stickFilter ? {marginTop: 58} : null}>
          <div className={'date-range' + (this.state.stickFilter ? ' fixed' : '')}>
            <label htmlFor={'dashboard-period'} className={'float-left mt-2 mr-2'}>Date:</label>
            <SearchableSelect
              id={'dashboard-period'}
              label="Load Template"
              placeholder={'Select Period'}
              options={filterOptions}
              onChange={this.selectPeriod}
              value={this.state.period}
            />

            {this.state.period.value === STATISTIC_FILTER_CUSTOM && (
              <div className={'dashboard-period-custom'}>
                <Datetime
                  key={"from"}
                  className={"period-custom-from"}
                  inputProps={{placeholder: 'From'}}
                  timeFormat={false}
                  isValidDate={d => {
                    return null === this.state.to || d.isSameOrBefore(this.state.to, 'day');
                  }}
                  onChange={v => {
                    const value = (typeof v === 'object' && '_isAMomentObject' in v) ? v : null;
                    this.setState({from: value})
                  }}
                />
                <Datetime
                  key={"to"}
                  className={"period-custom-from"}
                  inputProps={{placeholder: 'To'}}
                  timeFormat={false}
                  onChange={v => {
                    const value = (typeof v === 'object' && '_isAMomentObject' in v) ? v : null;
                    this.setState({to: value})
                  }}
                  isValidDate={d => {
                    return null === this.state.from || d.isSameOrAfter(this.state.from, 'day');
                  }}
                />
                <CustomButton size={'sm'} color="rose" className={'mr-2'} onClick={this.applyCustomFilters}>Apply</CustomButton>
                <CustomButton size={'sm'} color="white" onClick={this.resetCustomFilters}>Reset</CustomButton>
              </div>
            )}
            {this.props.loading && (
              <i className={'fa fa-spinner fa-spin'} />
            )}
            <div className={'clearfix'} />
          </div>

          {chartsData && (
            <>
              <CustomPie
                title={'On Demand'}
                circleText={'Invites'}
                boxWidth={this.chartsBox.current ? this.chartsBox.current.clientWidth : null}
                data={[
                  { name: 'Incomplete', value: piesData['onDemandInvitations']['invitations'], 'fill': ON_DEMAND_LIGHT_COLOR },
                  { name: 'Completed', value: piesData['onDemandInvitations']['completed'], 'fill': ON_DEMAND_DARK_COLOR },
                ]}
              />
              <CustomPie
                title={'Live Interviews'}
                circleText={'Invites'}
                boxWidth={this.chartsBox.current ? this.chartsBox.current.clientWidth : null}
                data={[
                  { name: 'Incomplete', value: piesData['liveInterviewInvitations']['invitations'], 'fill': LIVE_INTERVIEW_LIGHT_COLOR },
                  { name: 'Completed', value: piesData['liveInterviewInvitations']['completed'], 'fill': LIVE_INTERVIEW_DARK_COLOR },
                ]}
              />

              <CustomChart
                title={'On Demand'}
                lines={[
                  {'title': 'Invitations', 'color': ON_DEMAND_LIGHT_COLOR},
                  {'title': 'Completed Interviews', 'color': ON_DEMAND_DARK_COLOR},
                ]}
                data={chartsData['onDemandInvitations']}
                boxWidth={boxWidth}
              />
              <CustomChart
                title={'Live Interviews'}
                lines={[
                  {'title': 'Invitations', 'color': LIVE_INTERVIEW_LIGHT_COLOR},
                  {'title': 'Completed Interviews', 'color': LIVE_INTERVIEW_DARK_COLOR},
                ]}
                data={chartsData['liveInterviewInvitations']}
                boxWidth={boxWidth}
              />

              <CustomChart
                title={'On Demand'}
                lines={[
                  {'title': 'Minutes Used', 'color': ON_DEMAND_DARK_COLOR},
                  {'title': 'Average Minutes', 'color': ON_DEMAND_LIGHT_COLOR},
                ]}
                data={chartsData['onDemandMinutesUsed']}
                averageCalculationLabel={'Average Minutes'}
                boxWidth={boxWidth}
                disableRounding={true}
              />
              <CustomChart
                title={'Live Interviews'}
                lines={[
                  {'title': 'Minutes Used', 'color': LIVE_INTERVIEW_DARK_COLOR},
                  {'title': 'Average Minutes per Interview', 'color': LIVE_INTERVIEW_LIGHT_COLOR},
                ]}
                averageCalculationLabel={'Average Minutes per Interview'}
                data={chartsData['liveInterviewMinutesUsed']}
                boxWidth={boxWidth}
                disableRounding={true}
              />

              <CustomChart
                title={'All Interviews'}
                lines={[
                  {'title': 'Total Invites', 'color': LIVE_INTERVIEW_LIGHT_COLOR},
                  {'title': 'Total Completed', 'color': LIVE_INTERVIEW_DARK_COLOR},
                ]}
                data={chartsData['allInvitations']}
                boxWidth={boxWidth}
              />
              <CustomChart
                title={'All Interviews'}
                lines={[
                  {'title': 'Total Minutes Used', 'color': ON_DEMAND_DARK_COLOR},
                ]}
                data={chartsData['allMinutesUsed']}
                boxWidth={boxWidth}
              />
              <div className={"clearfix"} />
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  companyStatisticData: state.companyStatistic.companyStatistic.retrieved,
  error: state.companyStatistic.companyStatistic.error,
  loading: state.companyStatistic.companyStatistic.loading,
});

const mapDispatchToProps = dispatch => ({
  companyStatistic: (filters) => dispatch(companyStatistic(filters)),
  reset: () => dispatch(reset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyDashboard);
