import { SubmissionError } from 'redux-form';
import {
  fetch,
  extractHubURL,
} from '../../utils/dataAccess';

export function disableAutoRenewError(error) {
  return { type: 'SUBSCRIPTION_DISABLE_AUTO_RENEW_ERROR', error };
}

export function disableAutoRenewLoading(loading) {
  return { type: 'SUBSCRIPTION_DISABLE_AUTO_RENEW_LOADING', loading };
}

export function disableAutoRenewSuccess(success) {
  return { type: 'SUBSCRIPTION_DISABLE_AUTO_RENEW_SUCCESS', success };
}

export function disableAutoRenew(subscription, callback) {
  return dispatch => {
    dispatch(disableAutoRenewError(null));
    dispatch(disableAutoRenewSuccess(null));
    dispatch(disableAutoRenewLoading(true));

    return fetch((subscription['@id'] + '/disable-auto-renew'), {
      method: 'PUT',
    })
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        dispatch(disableAutoRenewLoading(false));
        dispatch(disableAutoRenewSuccess(retrieved));
      })
      .catch(e => {
        dispatch(disableAutoRenewLoading(false));

        if (e instanceof SubmissionError) {
          dispatch(disableAutoRenewError(e.errors._error));
          throw e;
        }

        dispatch(disableAutoRenewError(e.message));
      }).finally(e => {
        callback();
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'SUBSCRIPTION_DISABLE_AUTO_RENEW_RESET' });
  };
}
