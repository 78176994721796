import React from "react";
import {connect} from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import {generateRandomString} from "../../utils/string";
import Tooltip from '@material-ui/core/Tooltip';
import {create, reset as resetCreate} from "actions/OneWayInterviewShareLink/create";
import {update, reset as resetUpdate} from "actions/OneWayInterviewShareLink/update";
import {del, reset as resetDelete} from "actions/OneWayInterviewShareLink/delete"
import {list, reset as resetList} from "actions/OneWayInterviewShareLink/list"
import {applyToUrl} from "../../utils/urls";
import Dialog from "../../views/Components/Dialog/Dialog";

export const MAX_ALLOWED_VIEWS = 50;

class OneWayInterviewShareLinkDialog extends React.Component {
  state = {
    password: '',
    requirePassword: false,
    clipboardTooltipOpen: false,
  }

  componentDidMount() {
    this.applyPassword();
    this.props.resetUpdate();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.retrieved) {
      this.applyPassword();
    }
  }

  componentWillUnmount() {
    this.props.resetList();
    this.props.resetCreate();
    this.props.resetDelete();
  }

  applyPassword = () => {
    const item = this.currentItem();
    if (item && item['password']) {
      this.setState({
        password: item['password'],
        requirePassword: true,
      })
    }
  }

  retrieve = () => {
    const url = applyToUrl('one_way_interview_share_links', {'oneWayInterview': this.props.oneWayInterview['@id']});
    this.props.list(url);
  }

  reset = (withList = true) => {
    if (withList) {
      this.props.resetList();
    }
    this.props.resetCreate();
    this.props.resetUpdate();
    this.props.resetDelete();
  }

  viewsExceeded = () => {
    const item = this.currentItem();

    return item && item['views'] >= MAX_ALLOWED_VIEWS;
  }

  currentItem = () => {
    return this.props.retrieved && this.props.retrieved['hydra:member'][0];
  }

  copyLink = (e) => {
    e.preventDefault();

    let input = document.querySelector('.link-input input');
    input.focus();
    input.select();
    input.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy");

    this.setState({clipboardTooltipOpen: true});
    setTimeout(() => {this.setState({clipboardTooltipOpen: false})}, 3330);
  }

  removeLink = (e) => {
    e.preventDefault();
    if (window.confirm('Are you sure you want to delete this item?')) {
      this.reset();
      this.props.del(this.currentItem());
    }
  }

  generatePassword = (e) => {
    e.preventDefault();

    this.setState({password: generateRandomString(10)});
  }

  save = () => {
    if (this.props.loading)
      return;

    let item = this.currentItem();
    let data = {
      'password': this.state.password,
      'oneWayInterview': this.props.oneWayInterview['@id'],
    };

    this.reset(false);

    item ?
      this.props.update(item, data, () => this.props.onClose()) :
      this.props.create(data, () => this.retrieve());
  }

  regenerate = () => {
    if (this.viewsExceeded() && !this.props.loading) {
      let item = this.currentItem();
      let data = {
        'regenerate': true,
        'oneWayInterview': this.props.oneWayInterview['@id'],
      };

      this.reset();
      this.props.update(item, data, () => this.retrieve());
    }
  }

  renderDialogContent = () => {
    const {error, loading, deleted, created, updated} = this.props;
    const item = this.currentItem();
    const link = window.location.origin + '/share/on-demand-interview/' + (item && item['token']);

    return (
      <>
        {loading && (
          <div className="alert alert-info" role="alert">
            Loading...
          </div>
        )}
        {deleted && (
          <div className="alert alert-success" role="alert">
            <i className="fa fa-check" aria-hidden="true" /> Share link successfully deleted.
          </div>
        )}
        {created && (
          <div className="alert alert-success" role="alert">
            <i className="fa fa-check" aria-hidden="true" /> Share link successfully created.
          </div>
        )}
        {updated && (
          <div className="alert alert-success" role="alert">
            <i className="fa fa-check" aria-hidden="true" /> Share link successfully updated.
          </div>
        )}
        {error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" /> {error}
          </div>
        )}
        {!this.viewsExceeded() && (
          <div className="alert alert-warning" role="alert">
            <b>For users who don't have an account.</b>
            <br />
            <span>Link will expire after {MAX_ALLOWED_VIEWS} views. Users can view the interview but will not be able to add reviews, comments or tags.</span>
          </div>
        )}
        {this.viewsExceeded() && (
          <div className="alert alert-warning" role="alert">This link has reached {MAX_ALLOWED_VIEWS} views and is no longer valid.</div>
        )}
        {!item && !loading && (
          <button className={"btn btn-primary btn-lg generate-link-button"} onClick={this.save}>Generate Link</button>
        )}

        {item && (
          <>
            <div className={"link-container"}>
              <TextField
                label={'Link'}
                placeholder={'Link'}
                variant="outlined"
                value={link}
                className="link-input"
                readOnly={true}
              />
              {!this.viewsExceeded() && (
                <>
                  <button className={'btn btn-danger'} onClick={this.removeLink}>Remove</button>
                  <Tooltip open={this.state.clipboardTooltipOpen} title="Copied to your clipboard" placement={"top"}>
                    <button className={'btn btn-link'} onClick={this.copyLink}>Copy link</button>
                  </Tooltip>
                </>
              )}
              {this.viewsExceeded() && (
                <button className={'btn btn-info ml-3'} onClick={this.regenerate}>Regenerate</button>
              )}
              <div className={"clearfix"} />
            </div>
            <div>
              <p className="views-count">{item['views']} out of {MAX_ALLOWED_VIEWS} views.</p>
              <h4 className={"mt-1 mb-0"}>Security</h4>
              <p className={'mb-0'}>
                <Checkbox
                  id={'share-link-password-checkbox'}
                  className={'pt-2 pr-2'}
                  checked={this.state.requirePassword}
                  onChange={(e, v) => {this.setState({requirePassword: v, password: ''})}}
                />
                <label className={"share-link-password-checkbox-container"} htmlFor={"share-link-password-checkbox"}>Require password to view the link</label>
              </p>
              {this.state.requirePassword && (
                <>
                  <TextField
                    label={'Password'}
                    placeholder={'Password'}
                    variant="outlined"
                    value={this.state.password}
                    onChange={(e, v) => {this.setState({password: e.target.value})}}
                    onKeyPress={(e) => {e.key === 'Enter' && !this.viewsExceeded() && this.save()}}
                    className={"password-input"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <button className={'btn btn-warning ml-3 generate-password'} onClick={this.generatePassword}>Generate</button>
                </>
              )}
            </div>
          </>
        )}
      </>
    );
  }

  render() {
    const { onClose } = this.props;
    const showSaveButton = !this.viewsExceeded() && this.currentItem();

    return (
      <div>
        <Dialog
          open={true}
          title={"Public Share Link"}
          onClose={onClose}
          contentClass={"interview-share-link-dialog"}
          closeButtonText={"Close"}
          confirmButtonText={showSaveButton ? "Save" : undefined}
          onConfirm={showSaveButton ? this.save : undefined}
        >
          {this.renderDialogContent()}
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  retrieved: state.onewayinterviewsharelink.list.retrieved,
  created: state.onewayinterviewsharelink.create.created,
  updated: state.onewayinterviewsharelink.update.updated,
  deleted: state.onewayinterviewsharelink.del.deleted,
  loading: state.onewayinterviewsharelink.list.loading || state.onewayinterviewsharelink.create.loading || state.onewayinterviewsharelink.update.updateLoading || state.onewayinterviewsharelink.del.loading,
  error: state.onewayinterviewsharelink.list.error || state.onewayinterviewsharelink.create.error || state.onewayinterviewsharelink.update.updateError || state.onewayinterviewsharelink.del.error,
});

const mapDispatchToProps = dispatch => ({
  list: (page) => dispatch(list(page)),
  create: (values, callback) => dispatch(create(values, callback)),
  update: (item, values, callback) => dispatch(update(item, values, callback)),
  del: (item) => dispatch(del(item)),
  resetList: () => dispatch(resetList()),
  resetCreate: () => dispatch(resetCreate()),
  resetUpdate: () => dispatch(resetUpdate()),
  resetDelete: () => dispatch(resetDelete()),
});

export default OneWayInterviewShareLinkDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(OneWayInterviewShareLinkDialog);
