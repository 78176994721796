import { SubmissionError } from 'redux-form';
import {fetch} from '../../utils/dataAccess';

export function error(error) {
  return { type: 'SEND_INVITATION_ERROR', error };
}

export function loading(loading) {
  return { type: 'SEND_INVITATION_LOADING', loading };
}

export function success(created) {
  return { type: 'SEND_INVITATION_SUCCESS', created };
}

export function create(data) {
  data.invitations = (data.invitations).map((item) => {
    for (let key in item) {
      if (key !== 'expiresAt' && typeof item[key] === 'string') {
        item[key] = item[key].trim();
      }
    }
    return item;
  });
  return dispatch => {
    dispatch(loading(true));
    let newInvitations = data.invitations.filter(i => !i['@id']);

    return fetch('on_demand_interview/invitations', {method: 'POST', data: {...data, ...{invitations: newInvitations}} })
      .then(retrieved => {
        dispatch(loading(false));
        dispatch(success(retrieved))
      })
      .catch(e => {
        dispatch(loading(false));
        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw e;
        }

        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(error(null));
    dispatch(success(null));
  };
}
