import React, {Component} from "react";
import {BigPlayButton, ControlBar} from "video-react";
import PlayArrow from "@material-ui/icons/PlayArrow";
import Button from "components/CustomButtons/Button.jsx";
import PropTypes from 'prop-types';
import "./VideoQuestionPlayer.scss";
import VideoPlayer from "../video/VideoPlayer";

export default class VideoQuestionPlayer extends Component {
    static propTypes = {
        showPlayButtonOnVideo: PropTypes.bool,
        thinkTimeText: PropTypes.string,
        onVideoStarted: PropTypes.func,
        onVideoEnded: PropTypes.func,
        playIsDisabled: PropTypes.bool,
    };

    static defaultProps = {
        showPlayButtonOnVideo: false,
        playIsDisabled: false,
    }

    constructor(props) {
        super(props);
        this.player = React.createRef();
    }

    playQuestion = () => {
        if (this.props.playIsDisabled) {
            return;
        }

        this.player.current.seek(0);
        this.player.current.play();
        if (typeof this.props.onVideoStarted === 'function') {
            this.props.onVideoStarted();
        }
    };

    render() {
        const {showPlayButtonOnVideo} = this.props;
        return (
            <>
                <div className={showPlayButtonOnVideo ? "question-player-wrapper" : "question-player-wrapper non-clicked"} onClick={showPlayButtonOnVideo ? this.playQuestion : null}>
                    {this.props.thinkTimeText && (
                        <div className={"video-text"}>
                            {this.props.thinkTimeText}
                            {showPlayButtonOnVideo && (
                                <div className="controls video-questions">
                                    <Button className="control-button" onClick={this.playQuestion} disabled={this.props.playIsDisabled}>
                                        <PlayArrow/>Play
                                    </Button>
                                    {this.props.children}
                                </div>
                            )}
                        </div>
                    )}
                    <VideoPlayer
                        src={this.props.src}
                        ref={this.player}
                        className="question-video"
                        onEnded={this.props.onVideoEnded}
                            playsInline={this.props.playsInline ? true : false}
                    >
                        <BigPlayButton
                            position='center'
                            className={!showPlayButtonOnVideo ? 'big-play-button-hide' : undefined}
                        />
                        <ControlBar disableCompletely={true}
                        />
                    </VideoPlayer>
                </div>
                {!showPlayButtonOnVideo && (
                    <div className="controls video-questions">
                        <Button className="control-button" onClick={this.playQuestion} disabled={this.props.playIsDisabled}>
                            <PlayArrow/>Play
                        </Button>
                        {this.props.children}
                    </div>
                )}
            </>
        );
    }
}
