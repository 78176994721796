import React, {Component} from "react";
import {LinearProgress} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

const style = {
  container: {
    width: '90%',
    height: '30px',
    position: 'relative',
  },
  indicator: {
    height: '100%',
    borderRadius: '5px',
    position: 'relative',
  },
  indicatorColor: {
    backgroundColor: '#007bff',
    mixBlendMode: 'screen',
    zIndex: '4',
  },
  backgroundColor: {
    backgroundColor: 'white',
  },
  value: {
    position: 'absolute',
    top: 0,
    left: 'calc(50% - 20px)',
    width: '40px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    mixBlendMode: 'difference',
    color: 'white',
    zIndex: '3',
  },
  blackDrop: {
    zIndex: '2',
    height: '100%',
    position: 'absolute',
    background: 'black',
    top: '0',
    transition: 'all .4s linear',
  },
  bar: {
    borderRadius: '5px 0 0 5px',
  },
}

class LabeledProgressIndicator extends Component {
  static propTypes = {
    value: PropTypes.number,
  }

  static defaultProps = {
    value: 0,
  }

  render() {
    const {classes, value} = this.props;
    return (
      <div className={classes.container + ' labeled-progress-indicator-container'}>
        <LinearProgress
          classes={{
            bar: classes.bar,
            root: classes.indicator,
            colorPrimary: classes.backgroundColor,
            barColorPrimary: classes.indicatorColor,
          }}
          variant="determinate"
          value={value}
        />
        <div className={classes.blackDrop} style={{width: `${value}%`}}></div>
        <span className={classes.value + ' progress-value'}>{value}%</span>
      </div>
    )
  }
}

LabeledProgressIndicator = withStyles(style)(LabeledProgressIndicator);

export default LabeledProgressIndicator;
