import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";
import {loadCompaniesForSelect} from "../../actions/Company/forSelect";


class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  componentDidMount() {
    this.props.loadCompaniesForSelect();
  }

  render() {
    const interviewStatusChoices = [
      {text: 'All', value: '__all__'},
      {text: 'Complete', value: 'true'},
      {text: 'Incomplete', value: 'false'}
    ];

    return (
      <RenderFilterForm {...this.props} fields={[
        {'name': 'request___company', 'label': 'Company', 'placeholder': 'Company', 'type': 'selectSearch', 'options': this.props.companies},
        {'name': 'oneWayInterview___completedAt__exists__', 'label': 'Interview Status', 'placeholder': 'Interview Status', 'type': 'selectSearch', 'options': interviewStatusChoices},
      ]} />
    );
  }
}

const mapStateToProps = state => ({
  companies: state.company.forSelect.retrieved,
});

const mapDispatchToProps = dispatch => ({
  loadCompaniesForSelect: () => dispatch(loadCompaniesForSelect('@id')),
});

FilterForm = reduxForm({
  form: 'filter_candidate_one_way_interview_invitation',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterForm);
