import React from "react";
import "../../assets/scss/custom-switch.scss";


export function CustomSwitch({
   onClick,
   value = true,
   disabled = false,
   className = '',
   small = false
}) {
  function onClickHandler () {
    if (!disabled) {
      onClick(!value);
    }
  }

  let mainClass = "custom-switcher";
  mainClass += (value ? ' on' : ' off');
  mainClass += (disabled ? ' disabled' : '');
  mainClass += (className ? (' ' + className) : '');
  mainClass += (small ? ' small' : '');

  return (
    <div className={mainClass} onClick={onClickHandler}>
      <div className={'custom-switcher-text-on'}>ON</div>
      <div className={'custom-switcher-circle'} />
      <div className={'custom-switcher-text-off'}>OFF</div>
    </div>
  );
}
