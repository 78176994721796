import React, {Component} from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {renderTextField} from "../../utils/form";
import SearchableSelect from "../../views/Components/SearchableSelect";
import {connect} from "react-redux";
import PropTypes from "prop-types";

export const FILTER_JOBS_MODAL_FORM_NAME = 'job_filter_filter';

class FilterJobsModalForm extends Component {
  static propTypes = {
    visible: PropTypes.bool,
  };

  handleCountrySelect = (e, value, oldValue) => {
    if (value !== oldValue) {
      this.props.change('state', null);
    }
  };

  handleIndustrySelect = (e, value, oldValue) => {
    if (value !== oldValue) {
      this.props.change('professionMappings___occupation', null);
      this.props.change('professionMappings___specialty', null);
    }
  };

  handleOccupationSelect = (e, value, oldValue) => {
    if (value !== oldValue) {
      this.props.change('professionMappings___specialty', null);
    }
  };

  render() {
    const {
      companyUsers, teams, industries, occupations, specialties, countries, states,
      selectedIndustry, selectedOccupation, selectedCountry, visible
    } = this.props;

    let occupationPlaceholder = 'Select Industry First';
    let specialtyPlaceholder = 'Select Occupation First';
    let statePlaceholder = 'Select Country First';
    let occupationOptions = [];
    let specialtyOptions = [];
    let stateOptions = [];

    if (selectedIndustry !== null) {
      occupationPlaceholder = 'Occupation';
      if (occupations) {
        occupations.map(occupation => {
          if (occupation.industry === selectedIndustry) {
            occupationOptions.push(occupation);
          }
        });
      }
    }

    if (selectedOccupation !== null) {
      specialtyPlaceholder = 'Specialty';
      if (specialties) {
        specialties.map(specialty => {
          if (specialty.occupation === selectedOccupation) {
            specialtyOptions.push(specialty);
          }
        });
      }
    }

    if (selectedCountry !== null) {
      statePlaceholder = 'State';
      if (states) {
        states.map(state => {
          if (state.country === selectedCountry) {
            stateOptions.push(state);
          }
        });
      }
    }

    const statuses = [
      {value: 'active', text: 'Active'},
      {value: 'archived', text: 'Archived'},
    ];

    return visible && (
      <form name={FILTER_JOBS_MODAL_FORM_NAME} onSubmit={this.props.handleSubmit}>
        <Field
          component={renderTextField}
          name="id"
          type="number"
          label="ID"
          placeholder="ID"
          inputProps={{min: 1}}
          containerClass="form-group col-md-4 float-left"
          required={false}
        />
        <Field
          component={renderTextField}
          name="title"
          type="text"
          placeholder="Job Title"
          containerClass="form-group col-md-4 float-left"
          required={false}
        />
        <Field
          component={SearchableSelect}
          name="companyUser"
          placeholder="Posted By"
          containerClass="form-group col-md-4 float-left"
          required={false}
          options={companyUsers}
        />
        <Field
          component={renderTextField}
          name="city"
          type="text"
          placeholder="City"
          containerClass="form-group col-md-4 float-left"
          required={false}
        />
        <Field
          component={SearchableSelect}
          name="country"
          label="Country"
          containerClass="form-group col-md-4 float-left"
          placeholder={countries ? 'Select Country' : 'Loading...'}
          options={countries ? countries : []}
          required={false}
          onChange={this.handleCountrySelect}
        />
        <Field
          component={SearchableSelect}
          name="state"
          label="State"
          containerClass="form-group col-md-4 float-left"
          placeholder={statePlaceholder}
          options={stateOptions}
          required={false}
          isDisabled={!selectedCountry}
        />
        <Field
          component={SearchableSelect}
          name="status"
          placeholder="Status"
          containerClass="form-group col-md-4 float-left"
          required={false}
          options={statuses}
        />
        <Field
          component={SearchableSelect}
          name="team"
          placeholder="Team"
          containerClass="form-group col-md-6 float-left"
          required={false}
          options={teams}
        />
        <Field
          component={SearchableSelect}
          name="hiringManagers"
          placeholder="Hiring Manager"
          containerClass="form-group col-md-6 float-left"
          required={false}
          options={Array.isArray(companyUsers) ? companyUsers.filter(user => {
            return user.value.indexOf('hiring_manager') > -1;
          }) : []}
        />
        <Field
          component={SearchableSelect}
          name="professionMappings___industry"
          label="Industry"
          placeholder="Industry"
          containerClass="form-group col-md-4 float-left"
          required={false}
          options={industries}
          onChange={this.handleIndustrySelect}
        />
        <Field
          component={SearchableSelect}
          name="professionMappings___occupation"
          label="Occupation"
          placeholder={occupationPlaceholder}
          containerClass="form-group col-md-4 float-left"
          required={false}
          options={occupationOptions}
          onChange={this.handleOccupationSelect}
          isDisabled={!selectedIndustry}
        />
        <Field
          component={SearchableSelect}
          name="professionMappings___specialty"
          label="Specialty"
          placeholder={specialtyPlaceholder}
          containerClass="form-group col-md-4 float-left"
          required={false}
          options={specialtyOptions}
          isDisabled={!selectedOccupation}
        />
      </form>
    )
  }
}

FilterJobsModalForm = reduxForm({
  form: FILTER_JOBS_MODAL_FORM_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterJobsModalForm);

const selector = formValueSelector(FILTER_JOBS_MODAL_FORM_NAME);
const mapStateToProps = state => ({
  selectedIndustry: selector(state, 'industry'),
  selectedOccupation: selector(state, 'occupation'),
  selectedCountry: selector(state, 'country'),
  industries: state.industry.forSelect.retrieved,
  occupations: state.occupation.forSelect.retrieved,
  specialties: state.specialty.forSelect.retrieved,
  companyUsers: state.companyUser.forSelect.retrieved,
  teams: state.team.forSelect.retrieved,
  countries: state.country.forSelect.retrieved,
  states: state.state.forSelect.retrieved,
});
FilterJobsModalForm = connect(
  mapStateToProps
)(FilterJobsModalForm);

export default FilterJobsModalForm;
