import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";
import {firstLetterToUppercase} from "../../utils/firstLetterToUpperCase";
import {currentUserHasRole} from "../../utils/auth";
import {loadCompaniesForSelect} from "../../actions/Company/forSelect";

const TRANSACTION_STATUS_PENDING = 'pending';
const TRANSACTION_STATUS_FAILED = 'failed';
const TRANSACTION_STATUS_SUCCESS = 'success';
const TRANSACTION_STATUS_CANCELED = 'canceled';

class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  componentDidMount() {
    if (currentUserHasRole('ROLE_PARTNER_ADMIN')) {
      this.props.loadCompaniesForSelect();
    }
  }

  render() {
    const statuses = [
      {value: TRANSACTION_STATUS_CANCELED, text: firstLetterToUppercase(TRANSACTION_STATUS_CANCELED)},
      {value: TRANSACTION_STATUS_PENDING,  text: firstLetterToUppercase(TRANSACTION_STATUS_PENDING)},
      {value: TRANSACTION_STATUS_FAILED,   text: firstLetterToUppercase(TRANSACTION_STATUS_FAILED)},
      {value: TRANSACTION_STATUS_SUCCESS,  text: firstLetterToUppercase(TRANSACTION_STATUS_SUCCESS)},
    ];

    const filterFormFields = [
      {'name': 'amount__gte__', 'label': 'Amount From', 'placeholder': 'Amount From', 'type': 'number'},
      {'name': 'amount__lte__', 'label': 'Amount To', 'placeholder': 'Amount To', 'type': 'number'},
      {'name': 'status', 'label': 'Status', 'placeholder': 'Status', 'type': 'selectSearch', 'options': statuses},
      {'name': 'createdAt', 'label': 'Date', 'placeholder': 'Date', 'type': 'datesRange', 'past': false},
    ];

    if (currentUserHasRole('ROLE_PARTNER_ADMIN')) {
      filterFormFields.unshift({'name': 'company___id', 'label': 'Company', 'placeholder': 'Company', 'type': 'selectSearch', 'options': this.props.companies});
    }

    return (
      <RenderFilterForm {...this.props} fields={filterFormFields} />
    );
  }
}

const mapStateToProps = state => {
  return {
    companies: state.company.forSelect.retrieved,
  };
};

const mapDispatchToProps = dispatch => ({
  loadCompaniesForSelect: () => dispatch(loadCompaniesForSelect('@id')),
});

FilterForm = reduxForm({
  form: 'filter_countries',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterForm);
