import {resolveDashboardUrl} from "./dashboardUrlResolver";
import {history} from './../store';
import refreshTokenAndReattemptRequest from "./authTokenRefresh";
import storage from "./storage";
import {fetch} from "./dataAccess";
import {ENTRYPOINT} from './../config/entrypoint';
import jwtDecode from "jwt-decode";
import {clearInterviewCodes} from "./onDemandInterview";
import {handleSwitchUser} from "../components/SwitchUser/SwitchUser";

export function currentUserRoles() {
  const roles = storage.get('roles');
  if (!roles) {
    return [];
  }

  if (!Array.isArray(roles)) {
    throw new Error('Roles stored in local storage should be an array');
  }

  return roles;
}

export function currentUserHasRole(role) {
  let roles = currentUserRoles();
  for (let i = 0; i < roles.length; i++) {
    if (roles[i] === role) {
      return true;
    }
  }

  return false;
}

export function getSwitchedUsersHistory() {
    const history = storage.get('switchedUsersHistory');
    if (!history) {
        return [];
    }

    if (!Array.isArray(history)) {
        throw new Error('Switched Users History stored in local storage should be an array');
    }

    return history;
}

export function setSwitchedUsersHistory(switchedUsersHistory) {
  storage.set('switchedUsersHistory', switchedUsersHistory);
}

export function updateCurrentSwitchUserInHistory(values) {
  const history = getSwitchedUsersHistory();
  if (history && typeof history[0] === 'object') {
    if (storage.get('X-Switch-User')) {
      history[0].email = values.email;
      setSwitchedUsersHistory(history);
      handleSwitchUser(values.email, false);
    }
  }
}

export function isAuthenticated() {
  return !!getAuthToken();
}

export function saveAuthData(data, reinitializeSwitchUsersHistory = false) {
  storage.set('authToken', data.token);
  storage.set('refreshToken', data.refreshToken);
  storage.set('roles', data.roles);
  storage.set('originalUserEmail', data.email);
  storage.set('originalUserId', data.userId);
  storage.set('userId', data.userId);
  storage.set('site-settings-accepted-at', data['site-settings-accepted-at']);

  if (reinitializeSwitchUsersHistory) {
    setSwitchedUsersHistory([{
      'email'     : data.email,
      'username'  : data.username,
      'name'      : data.name,
      'role_name' : data.role_name,
      'original'  : true,
    }]);
  }
}

export function removeAuthData() {
  storage.remove('authToken');
  storage.remove('refreshToken');
  storage.remove('roles');
  storage.remove('originalUserEmail');
  storage.remove('originalUserId');
  storage.remove('switchedUsersHistory');
  storage.remove('X-Switch-User');
  storage.remove('userId');
  storage.remove('site-settings-accepted-at');
  clearInterviewCodes();
}

export function getUserId() {
  const userId = storage.get('userId');
  return userId ? parseInt(userId) : null;
}

export function getAuthToken() {
  return storage.get('authToken');
}

export function getRefreshToken() {
  return storage.get('refreshToken');
}

export function isAuthTokenExpired(token, delta = 5) {
  const payload = jwtDecode(token);
  if (payload && payload.exp) {
    return payload.exp - delta < Math.round(Date.now() / 1000);
  }
  return false;
}

export function handleAuthentication(data, redirectAfterLogin = true) {
  if (data.token === undefined) {
    throw new Error('Unexpected server response, token undefined');
  }
  if (data.roles === undefined) {
    throw new Error('Unexpected server response, roles undefined');
  }
  if (!Array.isArray(data.roles)) {
    throw new Error('Unexpected server response, roles is not an array');
  }
  saveAuthData(data, true);
  storage.remove('X-Switch-User');

  if (!redirectAfterLogin) {
    return;
  }

  let afterLoginUrl = storage.get('afterLoginUrl');
  if (afterLoginUrl === null) {
    afterLoginUrl = resolveDashboardUrl(data.roles);
  } else {
    storage.remove('afterLoginUrl');
  }
  history.push(afterLoginUrl);
}

export function applyAxiosAuth(axios) {
  axios.interceptors.request.use(function (config) {
    let authToken = getAuthToken();
    if (authToken !== null) {
      config.headers.Authorization = 'Bearer ' + authToken;
    }

    let xSwitchUser = storage.get('X-Switch-User');
    if (null !== xSwitchUser) {
      config.headers['X-Switch-User'] = xSwitchUser;
    }

    return config;
  });

  axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          return refreshTokenAndReattemptRequest(error, axios);
        }
        return Promise.reject(error)
      }
  )
}

export function login(email, password) {
  const submitData = {...getLoginOrRegisterDataFromStorage(), email, password};

  return fetch(ENTRYPOINT + '/login_check', {
    data: submitData,
    method: 'POST',
  }).then(res => {
    if (res.status === 200) {
      clearLoginOrRegisterStorage();
      return res;
    } else {
      throw new Error(res.error);
    }
  }).catch(error => {
    const message = error.response.hasOwnProperty('data') && error.response.data.hasOwnProperty('message') ?
      error.response.data.message :
      'Error logging in please try again';

    throw new Error(message);
  })
}

export function register(registrationValues) {
  return fetch('/register/candidate', {
    method: 'POST',
    data: {...getLoginOrRegisterDataFromStorage(), ...registrationValues}
  }).then(response => {
    clearLoginOrRegisterStorage();
    return response;
  });
}

function getLoginOrRegisterDataFromStorage() {
  const data = {};
  if (storage.get('liveInterviewRoomCode')) {
    data['liveInterviewRoomCode'] = storage.get('liveInterviewRoomCode');
  }

  return data;
}

function clearLoginOrRegisterStorage() {
  storage.remove('liveInterviewRoomCode');
}
