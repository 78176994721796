import { combineReducers } from 'redux';


export function error(state = null, action) {
  switch (action.type) {
    case 'ONEWAYINTERVIEW_TAGS_UPDATE_ERROR':
      return action.error;

    case 'ONEWAYINTERVIEW_TAGS_UPDATE_RESET':
      return null;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'ONEWAYINTERVIEW_TAGS_UPDATE_LOADING':
      return action.loading;

    case 'ONEWAYINTERVIEW_TAGS_UPDATE_RESET':
      return false;

    default:
      return state;
  }
}

export function updated(state = null, action) {
  switch (action.type) {
    case 'ONEWAYINTERVIEW_TAGS_UPDATE_SUCCESS':
      return action.updated;

    case 'ONEWAYINTERVIEW_TAGS_UPDATE_RESET':
      return null;

    default:
      return state;
  }
}


export default combineReducers({
  error,
  loading,
  updated,
});
