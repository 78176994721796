import React, {Component} from "react";
import PropTypes from 'prop-types';
import PhoneNumber from "../../views/Components/PhoneNumber";
import {connect} from "react-redux";
import Dialog from "../../views/Components/Dialog/Dialog";

class HelpDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  };

  render() {
    const {site, onClose} = this.props;
    return (
      <Dialog
        open={true}
        className={'on-demand-interview-page-help-dialog'}
        onClose={onClose}
        title={'Questions?'}
        contentClass={'on-demand-interview-page-help-dialog-content'}
        closeButtonText={'Close'}
      >
        Call <PhoneNumber phone={site.supportPhone} formattedPhone={site.supportPhoneFormatted} /> or email <a href={`mailto:${site.supportEmail}`}>{site.supportEmail}</a> for help.
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  site: state.siteDetails.siteDetails.retrieved,
});

HelpDialog = connect(mapStateToProps)(HelpDialog);

export default HelpDialog;
