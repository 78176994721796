import { combineReducers } from 'redux';

export function disableAutoRenewError(state = null, action) {
  switch (action.type) {
    case 'SUBSCRIPTION_DISABLE_AUTO_RENEW_ERROR':
      return action.error;

    case 'SUBSCRIPTION_DISABLE_AUTO_RENEW_RESET':
      return null;

    default:
      return state;
  }
}

export function disableAutoRenewLoading(state = false, action) {
  switch (action.type) {
    case 'SUBSCRIPTION_DISABLE_AUTO_RENEW_LOADING':
      return action.loading;

    case 'SUBSCRIPTION_DISABLE_AUTO_RENEW_RESET':
      return false;

    default:
      return state;
  }
}

export function disableAutoRenewSuccess(state = null, action) {
  switch (action.type) {
    case 'SUBSCRIPTION_DISABLE_AUTO_RENEW_SUCCESS':
      return action.success;

    case 'SUBSCRIPTION_DISABLE_AUTO_RENEW_RESET':
      return null;

    default:
      return state;
  }
}

export function eventSource(state = null, action) {
  switch (action.type) {
    case 'SUBSCRIPTION_DISABLE_AUTO_RENEW_MERCURE_OPEN':
      return action.eventSource;

    case 'SUBSCRIPTION_DISABLE_AUTO_RENEW_RESET':
      return null;

    default:
      return state;
  }
}

export default combineReducers({
  disableAutoRenewSuccess,
  disableAutoRenewError,
  disableAutoRenewLoading,
  eventSource,
});
