import React, {Component} from 'react';
import {connect} from "react-redux";
import classNames from 'classnames';
import DateTimeWithTimezone from "../DateTime/DateTimeWithTimezone";
import {
  calculateQuestionsCount,
  getVideoQuestionsCount,
  isInterviewExpired,
  isInvitationAccepted,
  isInvitationDeclined,
  videoResponseIsRequired
} from "../../utils/onDemandInterview";
import InterviewInfoActions from "./InterviewInfoActions";
import moment from "moment";
import PhoneNumber from "../../views/Components/PhoneNumber";

class InterviewInfo extends Component {
  renderInvitationStatus = () => {
    const {invitation, request} = this.props;
    const expired = isInterviewExpired(request, invitation);

    if (expired) {
      return 'Expired';
    } else if (!invitation) {
      return 'Pending';
    } else if (isInvitationAccepted(invitation)) {
      return 'Accepted';
    } else if (isInvitationDeclined(invitation)) {
      return 'Declined';
    } else {
      return 'Pending';
    }
  };

  render() {
    const {request, invitation, screenSize} = this.props;
    const company = request.job.company;
    const host = request.companyUser;
    const timezone = moment.tz.guess();
    const smallScreen = !screenSize.md;

    return (
      <div className={'interview-page-interview-info'}>
        <div className={'interview-page-interview-info-title'}>On Demand Interview Invitation</div>
        <div>
          <div className={'interview-page-interview-info-container'}>
            <div className={'interview-page-interview-info-block'}>
              <span className={'interview-page-interview-info-block-title'}>COMPANY</span>
              <span className={'interview-page-interview-info-block-content'}>{company.name}</span>
            </div>
            {smallScreen && (
              <div className={'interview-page-interview-info-block'}>
                <span className={'interview-page-interview-info-block-title'}>JOB TITLE</span>
                <span className={'interview-page-interview-info-block-content'}>
                  {request.job.title}<br/>
                  <span className={'interview-page-interview-info-block-content-job-scroll'}>
                    <a href='#interview-page-job-location'>(Scroll for details)</a>
                  </span>
                </span>
              </div>
            )}
            <div className={'interview-page-interview-info-block'}>
              <span className={'interview-page-interview-info-block-title'}>HOST</span>
              <span className={'interview-page-interview-info-block-content'}>{host.firstName} {host.lastName}</span>
              <span className={'interview-page-interview-info-block-content'}>
                <a href={'mailto:' + host.email}>{host.email}</a>
              </span>
              <span className={'interview-page-interview-info-block-content'}>
                <PhoneNumber phone={'tel:' + host.phoneNumber} formattedPhone={host.formattedPhoneNumber} />
              </span>
            </div>
            {request.expiresAt && (
            <div className={classNames('interview-page-interview-info-block',{
              'interview-page-interview-expired': isInterviewExpired(request, invitation),
            })}>
              <span className={'interview-page-interview-info-block-title'}>EXPIRES AT</span>
              <span className={'interview-page-interview-info-block-content'}>
                <DateTimeWithTimezone time={request.expiresAt} timezone={timezone}/>
              </span>
            </div>
            )}
            <div className={'interview-page-interview-info-block'}>
              <span className={'interview-page-interview-info-block-title'}>INVITATION STATUS</span>
              <span className={'interview-page-interview-info-block-content'}>{this.renderInvitationStatus()}</span>
            </div>
            <div className={'interview-page-interview-info-block'}>
              <span className={'interview-page-interview-info-block-title'}>NUMBER OF QUESTIONS</span>
              <span className={'interview-page-interview-info-block-content'}>{calculateQuestionsCount(request)}</span>
              {getVideoQuestionsCount(request) > 0 && (
                <span>{videoResponseIsRequired(request) ? 'Video responses are required.' : 'Video responses are optional.'}</span>
              )}
            </div>
          </div>
          <InterviewInfoActions />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  request: state.onDemandInterviewPage.request,
  invitation: state.onDemandInterviewPage.invitation,
  screenSize: state.screen.size,
});

InterviewInfo = connect(
  mapStateToProps
)(InterviewInfo);

export default InterviewInfo;
