import React, { Component } from 'react';
import {history} from "../../store";
import {Link} from "react-router-dom";
import {applyToUrl} from "../../utils/urls";

export class Boxes extends Component
{
  toList = (e, url, filters) => {
    e.preventDefault();

    url = '/company/' + url + '/';
    url = applyToUrl(url, filters);

    if (e.button === 1) {
      window.open(url, '_blank');
    } else {
      history.push(url)
    }
  }

  render() {
    const quantityPlaceholder = '';
    const {item} = this.props;

    return (
      <div className={"top-boxes"}>
        <div key={1} className={'top-box'}>
          <p className={'top-box-title'}>Active Users<br/> {item ? item['users']['active'] : quantityPlaceholder}</p>
          <ul>
            <li><span>Admins</span><span className={'space'} /> <a onMouseDown={e => {this.toList(e,'users', {role: "company-admin"})}} href={''}>{item ? item['users']['companyAdmins'] : quantityPlaceholder}</a></li>
            <li><span>Employees</span><span className={'space'} /><a onMouseDown={e => {this.toList(e,'users', {role: "company-employee"})}} href={''}>{item ? item['users']['companyEmployees'] : quantityPlaceholder}</a></li>
            <li><span>Hiring Managers</span><span className={'space'} /><a onMouseDown={e => {this.toList(e,'users', {role: "company-hiring-manager"})}} href={''}>{item ? item['users']['companyHiringManagers'] : quantityPlaceholder}</a></li>
            <li><span>Candidates</span><span className={'space'} /><Link to={'candidates/'}>{item ? item['users']['candidates'] : quantityPlaceholder}</Link></li>
          </ul>
        </div>
        <div key={2} className={'top-box'}>
          <p className={'top-box-title'}>Total Jobs<br/> {item ? item['jobs']['total'] : quantityPlaceholder}</p>
          <ul>
            <li><span>Active</span><span className={'space'} /><a onMouseDown={e => {this.toList(e,'jobs', {status: "active"})}} href={''}>{item ? item['jobs']['active'] : quantityPlaceholder}</a></li>
            <li><span>Archived</span><span className={'space'} /><a onMouseDown={e => {this.toList(e,'jobs', {status: "archived"})}} href={''}>{item ? item['jobs']['archived'] : quantityPlaceholder}</a></li>
          </ul>
        </div>
        <div key={3} className={'top-box'}>
          <p className={'top-box-title'}>Total Invitations<br/> {item ? item['invitations']['total'] : quantityPlaceholder}</p>
          <ul>
            <li><span>On Demand</span><span className={'space'} /><Link to={'invitations/'}>{item ? item['invitations']['onDemandInvitations'] : quantityPlaceholder}</Link></li>
            <li><span>Live Interviews</span><span className={'space'} /><Link to={'live-interview-invitations/'}>{item ? item['invitations']['liveInterviewInvitations'] : quantityPlaceholder}</Link></li>
          </ul>
        </div>
        <div key={4} className={'top-box'}>
          <p className={'top-box-title'}>Completed Interviews<br/> {item ? item['completed']['total'] : quantityPlaceholder}</p>
          <ul>
            <li><span>On Demand</span><span className={'space'} /><Link to={'on-demand-interviews/'}>{item ? item['completed']['onDemandInterviews'] : quantityPlaceholder}</Link></li>
            <li><span>Live Interviews</span><span className={'space'} /><Link to={'live-interviews/'}>{item ? item['completed']['liveInterviews'] : quantityPlaceholder}</Link></li>
          </ul>
        </div>
        <div key={5} className={'top-box'}>
          <p className={'top-box-title'}>Total Minutes<br/> {item ? item['minutes']['totalMinutesRecorded'] : quantityPlaceholder}</p>
          <ul>
            <li><span>On Demand</span><span className={'space'} /><span>{item ? item['minutes']['oneWayInterviewMinutesRecorded'] : quantityPlaceholder}</span></li>
            <li><span>Live Interviews</span><span className={'space'} /><span>{item ? item['minutes']['liveInterviewMinutesRecorded'] : quantityPlaceholder}</span></li>
          </ul>
        </div>
        <div className={"clearfix"} />
      </div>
    );
  }
}
