import React from "react";
import {connect} from "react-redux";
import InterviewPageFooter from "../Interview/InterviewPageFooter";

class LiveInterviewFooter extends React.Component {
  render() {
    const {site} = this.props;
    return (
      <InterviewPageFooter site={site} />
    )
  }
}

const mapStateToProps = state => ({
  site: state.siteDetails.siteDetails.retrieved,
});

LiveInterviewFooter = connect(
  mapStateToProps
)(LiveInterviewFooter);

export default LiveInterviewFooter;
