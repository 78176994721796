import React, {Component} from "react";
import SendMessageChat from "../Icons/SendMessageChat";
import classNames from 'classnames';
import PropTypes from "prop-types";

export default class MessageEntryForm extends Component {
    TYPING_TIMEOUT = 4000; //every 4 seconds let server know the user is still typing
    typingTimeout;
    state = {message: '', typing: false};

    static propTypes = {
        disabled: PropTypes.bool,
    };

    handleMessageChange = (e) => {
        this.setState({
            message: e.target.value,
            textareaHeight: e.currentTarget.scrollHeight
        });
        this.handleTyping();
    };

    handleTyping = () => {
        const {session} = this.props;
        if (!this.state.typing) {
            this.setState({typing: true});
            session.sendChatUserTyping();
            clearTimeout(this.typingTimeout); // probably shouldn't be necessary
            this.typingTimeout = setTimeout(() => {
                this.setState({
                    typing: false,
                });
            }, this.TYPING_TIMEOUT);
        }
    };

    sendMessage = () => {
        const {session, disabled} = this.props;
        const {message} = this.state;
        const regex = /^\s+$/;
        if (disabled) {
            return;
        }
        if (message && !regex.test(message)) {
            session.sendChatMessage(message);
        }
        this.setState({message: '', textareaHeight: ''});
    };

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.sendMessage();
        }
    };

    render() {
        const {fullscreen, disabled} = this.props;
        const textareaStyle = {
            height: this.state.textareaHeight
        };

        return (
            <div className={classNames('message-window', {'message-window-fullscreen': fullscreen})} style={textareaStyle} >
                <textarea
                    className="message-textarea"
                    disabled={disabled}
                    onChange={this.handleMessageChange}
                    onKeyPress={this.handleKeyPress}
                    value={this.state.message} placeholder="Type a message here"/>
                <div className={classNames(
                    'message-submit', {
                        'disabled': disabled || !this.state.message
                    })}
                    onClick={this.sendMessage}
                >
                    <SendMessageChat viewBox='0 0 27 27.01'/>
                </div>
            </div>
        );
    }
}
