import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import {renderTextField} from "../../utils/form";
import {connect} from "react-redux";
import {loadCountriesForSelect} from "../../actions/Country/forSelect";
import SearchableSelect from "../../views/Components/SearchableSelect";

class StateForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  componentDidMount() {
    this.props.loadCountriesForSelect(this.props.site.country && this.props.site.country.code);
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={renderTextField}
          name="name"
          type="text"
          placeholder="Name"
          required={true}
        />
        <Field
          component={renderTextField}
          name="code"
          type="text"
          placeholder="Code"
          required={true}
        />
        <Field
          component={SearchableSelect}
          name="country"
          label="Country"
          placeholder={this.props.countries ? 'Select Country' : 'Loading...'}
          options={this.props.countries ? this.props.countries : []}
          required={true}
        />
        <button type="submit" className="btn btn-success float-left mr-3">
          Submit
        </button>
      </form>
    );
  }
}

const mapStateToProps = state => {
  let countries = state.country.forSelect.retrieved;
  let site =  state.siteDetails.siteDetails.retrieved;

  return { countries, site };
};

const mapDispatchToProps = dispatch => ({
  loadCountriesForSelect: (countryCode) => dispatch(loadCountriesForSelect('@id', countryCode)),
});

StateForm = reduxForm({
  form: 'state',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(StateForm);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StateForm);

