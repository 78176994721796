import React from "react";
import { Redirect } from 'react-router-dom';
import {resolveDashboardUrl} from "../../utils/dashboardUrlResolver";
import {currentUserRoles} from "../../utils/auth";

class HomePage extends React.Component {
    render() {
        return <Redirect to={resolveDashboardUrl(currentUserRoles())} />;
    }
}

export default HomePage
