import React from "react";
import PropTypes from "prop-types";
import JobSalary from "../Job/JobSalary";
import {Typography} from "@material-ui/core";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import {jobLocation} from "../../utils/jobLocation";

class JobInfo extends React.Component {
  static propTypes = {
    job: PropTypes.object.isRequired,
  };

  renderJobInfoList = () => {
    const {job} = this.props;
    const industries = [];
    if (job.professionMappings) {
      job.professionMappings.forEach((mapping) => {
        const parts = [];
        if (mapping.industry) {
          parts.push(mapping.industry.name);
        }
        if (mapping.occupation) {
          parts.push(mapping.occupation.name);
        }
        if (mapping.specialty) {
          parts.push(mapping.specialty.name);
        }
        industries.push(parts.join(', '));
      });
    }

    return (
      <ul className={'interview-page-job-info-list'}>
        {industries.length > 0 && (<li><b>Industry</b> {industries.join(' / ')}</li>)}
        {(job.employmentType && !!(job.employmentType).trim()) && (
          <li><b>Employment Type</b> {job.employmentType}</li>
        )}
        {(job.minSalary || job.maxSalary || job.salaryType) && (<li><b>Salary</b> <JobSalary job={job} /></li>)}
        <li><b>Visa Sponsorship</b> {job.visaSponsorship ? 'YES' : 'NO'}</li>
        <li><b>Relocation</b> {job.relocation ? 'YES' : 'NO'}</li>
      </ul>
    )
  };

  render() {
    const {job} = this.props;

    return (
      <GridContainer className={'interview-page-job-info'}>
        <GridItem xs={12} className={'interview-page-job-header'}>
          <h5 id="interview-page-job-location">{jobLocation(job)}</h5>
          <Typography variant="h2" component="h1" className={'interview-page-job-title'}>
            {job.title}
          </Typography>
        </GridItem>
        <GridItem xs={12} md={4}>
          {this.renderJobInfoList()}
        </GridItem>
        <GridItem xs={12} md={8}>
          <p className={'interview-page-job-description ql-editor'} dangerouslySetInnerHTML={{__html: job.description }} />
        </GridItem>
      </GridContainer>
    )
  }
}

export default JobInfo;
