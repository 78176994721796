import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";
import {loadJobsForSelect, reset as resetJobsForSelect} from "../../actions/Job/forSelect";
import {loadTeamsForSelect} from "../../actions/Team/forSelect";

class ScheduleFilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      jobsFilterIsModalOpen: false,
    }
  }

  onFirstExpand = () => {
    this.props.loadJobsForSelect();

    /* fetching this data is triggered in FilterJobsModal */
    // this.props.loadTeamsForSelect();
  }

  componentWillUnmount() {
    this.props.resetEntities();
  }

  jobsFilterToggleModal = (opened) => {
    this.setState({jobsFilterIsModalOpen: opened});
  }

  selectJob = (job, fieldName = '@id') => {
    this.props.change('job', job[fieldName]);
    this.jobsFilterToggleModal(false);
  }

  render() {
    let teamsPlaceholder = this.props.teamsLoading ? 'Loading...' : 'Team';
    let jobsPlaceholder = this.props.jobsLoading ? 'Loading...' : 'Job';

    return (
      <>
        {!this.props.parentLoading && this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        <RenderFilterForm {...this.props} onFirstExpand={this.onFirstExpand} fields={[
          {'name': 'id', 'label': 'ID', 'placeholder': 'ID', 'type': 'number'},
          {'name': 'title', 'label': 'Title', 'placeholder': 'Title', 'type': 'text'},
          {'name': 'job', 'label': 'Job', 'placeholder': jobsPlaceholder, 'type': 'jobSelectSearch', 'options': this.props.jobs,
            'jobsFilterSelectJob': this.selectJob,
            'jobsFilterToggleModal': this.jobsFilterToggleModal,
            'jobsFilterIsModalOpen': this.state.jobsFilterIsModalOpen,
          },
          {'name': 'job___team', 'label': 'Team', 'placeholder': teamsPlaceholder, 'type': 'selectSearch', 'options': this.props.teams},
          {'name': 'scheduledStartTime', 'label': 'Scheduled Date', 'placeholder': 'Scheduled Date', 'type': 'datesRange'},
        ]} />
      </>
    );
  }
}

const mapStateToProps = state => {
  let teams = state.team.forSelect.retrieved;
  let jobs = state.job.forSelect.retrieved;
  let loading = state.team.forSelect.loading || state.job.forSelect.loading;
  let teamsLoading = state.team.forSelect.loading;
  let jobsLoading = state.job.forSelect.loading;

  return { teams, jobs, loading, teamsLoading, jobsLoading };
};

const mapDispatchToProps = dispatch => ({
  loadTeamsForSelect: () => dispatch(loadTeamsForSelect()),
  loadJobsForSelect: () => dispatch(loadJobsForSelect()),
  resetEntities: () => {
    dispatch(resetJobsForSelect());
  },
});

ScheduleFilterForm = reduxForm({
  form: 'filter_company_live_interview_schedule',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(ScheduleFilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleFilterForm);
