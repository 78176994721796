import React, {Component} from "react";
import PropTypes from "prop-types";
import {DialogTitle as MaterialDialogTitle} from "@material-ui/core";
import "./DialogTitle.scss";

class DialogTitle extends Component {
  static propTypes = {
    id: PropTypes.string,
    onClose: PropTypes.func,
  };

  render() {
    const {id, children, onClose} = this.props;
    const showDialogTitle = !!children || !!onClose;

    return (
      showDialogTitle ? (
        <MaterialDialogTitle id={id} className={'io-dialog-title'}>
          {children}
          {onClose && (
            <i className="fa fa-close float-right io-dialog-title-close" onClick={onClose} />
          )}
        </MaterialDialogTitle>
      ) : null
    )
  }
}

export default DialogTitle;
