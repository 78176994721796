import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class OpenViduVideoComponent extends Component {
  static propTypes = {
    streamManager: PropTypes.object,
    videoLoaded: PropTypes.bool,
    muted: PropTypes.bool,
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
  }

  componentDidUpdate() {
    this.addVideoElementToStreamManager();
  }

  componentDidMount() {
    this.addVideoElementToStreamManager();
  }

  addVideoElementToStreamManager() {
    if (this.props && !!this.videoRef && this.props.streamManager) {
      this.props.streamManager.addVideoElement(this.videoRef.current);
    }
  }

  render() {
    const {className} = this.props;
    return (
      <div className={className ? className : ''}>
        {!this.props.videoLoaded && (
          <div className="placeholder video-not-loaded-placeholder">
            <h4>Please allow access to your camera and microphone to continue</h4>
          </div>
        )}
        {this.props.videoLoaded && this.props.videoText && (
          <div style={{position: 'absolute', padding: '0 15%', opacity: '0.7'}} className='placeholder'>
            <h4>{this.props.videoText}</h4>
          </div>
        )}
        <video hidden={!this.props.videoLoaded} autoPlay={true} ref={this.videoRef} muted={this.props.muted}/>
      </div>
    )
  }

}
