import {fetchJSON} from "../../utils/dataAccess";

export function loadPresentersForSelect() {
  return dispatch => {
    dispatch(loading(true));
    fetchJSON('/presenters?pagination=false').then((presenters) => {
      dispatch(presentersLoaded(presenters['hydra:member'].map(
        function (presenter) {
          return {
            value: presenter['@id'],
            text: presenter.name,
          }
        }
      )));
      dispatch(loading(false));
    }).catch(e => {
      dispatch(loading(false));
      dispatch(error(e.message));
    })
  };
}

export function loading(loading) {
  return {type: 'PRESENTERS_FOR_SELECT_LOADING', loading};
}

export function error(error) {
  return {type: 'PRESENTERS_FOR_SELECT_ERROR', error};
}

export function presentersLoaded(retrieved) {
  return {type: 'PRESENTERS_FOR_SELECT_RETRIEVED', retrieved};
}
