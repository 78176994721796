import {combineReducers} from "redux";
import {testing, startingTest} from "./testing";
import {
  loading, liveInterview, liveInterviewParticipant, liveInterviewParticipantCreated, creatingLiveInterviewParticipant,
  joiningToInterview, joinedToInterview, startingInterview, reconnectingToInterview, endingInterview,
  isHost, session, publisher, publisherPreferences, sipAudio, sharedScreen, focusedParticipantId, participants,
  participantsMuted, fullscreen, bottomPanelActive, error, recording
} from "./interveiw";
import {
  invitation, acceptingInvitation, acceptedInvitation, decliningInvitation, requestingNewTime, newTimeRequested,
} from "./invitation"
import chat from './chat';
import auth from './auth';

export default combineReducers({
  testing, startingTest, loading, liveInterview, liveInterviewParticipant, creatingLiveInterviewParticipant,
  liveInterviewParticipantCreated, joiningToInterview, startingInterview, joinedToInterview, endingInterview,
  reconnectingToInterview, isHost, publisher, publisherPreferences, sipAudio, sharedScreen, participants, focusedParticipantId,
  participantsMuted, session, fullscreen, bottomPanelActive, error, recording, chat, auth, invitation, acceptingInvitation,
  acceptedInvitation, decliningInvitation, requestingNewTime, newTimeRequested,
});
