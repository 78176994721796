import {combineReducers} from 'redux';

export function error(state = null, action) {
  switch (action.type) {
    case 'QUESTIONBANK_CREATE_ERROR':
      return action.error;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'QUESTIONBANK_CREATE_LOADING':
      return action.loading;

    default:
      return state;
  }
}

export function categories(state = null, action) {
  if (action.type !== 'QUESTIONBANK_CATEGORIES_LOADED') {
    return state;
  }

  return action.categories;
}

export function created(state = null, action) {
  switch (action.type) {
    case 'QUESTIONBANK_CREATE_SUCCESS':
      return action.created;

    default:
      return state;
  }
}

export default combineReducers({
  error,
  loading,
  categories,
  created,
});
