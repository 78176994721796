import React, {Component} from "react";
import InputLabel from "@material-ui/core/InputLabel";
import {renderFromHelper} from "../../utils/form";
import Button from "../CustomButtons/Button";
import NoteAdd from "@material-ui/icons/NoteAdd";
import {connect} from "react-redux";
import {loadCompanyCandidatesForSelect} from "../../actions/CompanyCandidate/forSelect";
import CandidateInvitation from "./CandidateInvitation";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  label: {
    fontSize: 'x-large',
    fontWeight: '500',
  }
};

class CandidateInvitations extends Component {
  static defaultProps = {
    allowAdd: true,
    allowDelete: true,
    allowEdit: true,
  };

  render() {
    const {classes, fields, label, required, allowAdd, allowDelete, allowEdit, meta: {error}} = this.props;
    return (
      <div className='mb-4'>
        {label && (
          <>
          <InputLabel
            classes={{root: classes.label}}
            className='mb-4'
            required={required}>{label}
          </InputLabel><br/>
          </>
        )}
        {fields.map((name, index) => {
          const invitation = fields.get(index);
          return (
            <div key={index}>
              <div className="col-md-12 float-left pl-0 pr-0">
                <CandidateInvitation
                  allowEdit={allowEdit}
                  allowDelete={allowDelete}
                  onDelete={() => {
                    fields.remove(index);
                  }}
                  name={name}
                  invitation={(invitation || {})}
                  change={this.props.change}
                />
                <div className="clearfix" />
              </div>
            </div>
          );
        })}
        {renderFromHelper(true, error)}
        {allowAdd && (
          <Button color="info" onClick={() => fields.push({})}>
            <NoteAdd/>Invite Candidate
          </Button>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(CandidateInvitations);
