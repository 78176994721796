import React from "react";
import {connect} from "react-redux";
import {retrieve, reset} from "actions/OneWayInterviewWatchToken/show"
import {OneWayInterviewAnswers} from "../../components/OneWayInterview/OneWayInterviewAnswers";

class OneWayInterviewPreview extends React.Component
{
  componentDidMount() {
    this.props.retrieve(this.props.match.params.token);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const item = this.props.retrieved ? this.props.retrieved['oneWayInterview'] : null;

    return (<div className={"iframe-on-demand-interview"}>
        <div className={"iframe-on-demand-interview-header"}>
          {item && (
            <div className={"iframe-on-demand-interview-candidate"}>
              <p className={"iframe-on-demand-interview-label"}>You are watching interview for:</p>
              <p className={"iframe-on-demand-interview-full-name"}>
                {item['companyCandidate']['firstName']} {item['companyCandidate']['lastName']}
              </p>
            </div>
          )}
          <img src={this.props.site.logo} />
        </div>

        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}

        {item && (
          <OneWayInterviewAnswers item={item} />
        )}
    </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.onewayinterviewwatchtoken.show.retrieved,
  loading: state.onewayinterviewwatchtoken.show.loading,
  error: state.onewayinterviewwatchtoken.show.error,
  site: state.siteDetails.siteDetails.retrieved,
});

const mapDispatchToProps = dispatch => ({
  retrieve: (token) => dispatch(retrieve(token)),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OneWayInterviewPreview);
