import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from "../../views/Components/Dialog/Dialog";

class ActivateDeactivatePlanDialog extends Component{
  static propTypes = {
    open: PropTypes.bool,
    plan: PropTypes.object,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
  };

  render() {
    const {open, plan, onClose, onConfirm} = this.props;
    const activate = plan && plan['status'] === 'inactive';
    return (
      <Dialog
        open={open}
        onClose={onClose}
        title={(activate ? 'Activate' : 'Deactivate') + " Plan"}
        confirmButtonText={"Yes"}
        onConfirm={onConfirm}
        closeButtonText={"No"}
      >
        <div className={"text-center"}>
          Are you sure you want to {activate ? 'activate' : 'deactivate'} this Plan?
        </div>
      </Dialog>
    )
  }
}

export default ActivateDeactivatePlanDialog;
