import {error} from "./page";
import {fetch} from "../../utils/dataAccess";

export function acceptInvitation(invitation) {
  return async (dispatch) => {
    try {
      dispatch(invitationAccepting(true));
      const response = await fetch(`/invitations/accept/${invitation.invitationCode}`, { method: 'POST' });
      const updatedInvitation = response.data;
      dispatch(invitationReceived(updatedInvitation));
      dispatch(invitationAccepting(false));
    } catch (e) {
      dispatch(error(e.message));
      dispatch(invitationAccepting(false));
    }
  };
}

export function declineInvitation(invitation) {
  return async (dispatch) => {
    try {
      dispatch(invitationDeclining(true));
      const response = await fetch(`/invitations/decline/${invitation.invitationCode}`, { method: 'POST' })
      const updatedInvitation = response.data;
      dispatch(invitationReceived(updatedInvitation));
      dispatch(invitationDeclining(false));
    } catch (e) {
      dispatch(error(e.message));
      dispatch(invitationDeclining(false));
    }
  };
}

export function invitationReceived(invitation) {
  return { type: 'ON_DEMAND_INTERVIEW_PAGE_INVITATION', invitation };
}

function invitationAccepting(accepting) {
  return { type: 'ON_DEMAND_INTERVIEW_PAGE_INVITATION_ACCEPTING', accepting };
}

function invitationDeclining(declining) {
  return { type: 'ON_DEMAND_INTERVIEW_PAGE_INVITATION_DECLINING', declining };
}
