import React, {Component} from 'react';
import {Field, FieldArray, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {renderCheckbox, renderTextField} from "../../utils/form";
import KnockoutQuestionChoicesEditor from "../KnockoutQuestion/KnockoutQuestionChoicesEditor";
import FormLabel from "@material-ui/core/FormLabel";
import GridItem from "../Grid/GridItem";
import ProfessionMapping from "../../views/Components/ProfessionMapping";
import SearchableSelect from "../../views/Components/SearchableSelect";
import {loadTeamsForSelect, reset as resetTeams} from "../../actions/Team/forSelect";
import {connect} from "react-redux";
import {loadCategories, reset as resetCategories} from "../../actions/KnockoutQuestionBank/categories";
import {currentUserHasRole} from "../../utils/auth";

class KnockoutQuestionBankForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  state = {
    type: null,
  }

  componentWillMount() {
    if (currentUserHasRole('ROLE_COMPANY_USER')) {
      const teamFilters = {};
      const initialValues = this.props.initialValues;
      if (initialValues && initialValues.team) {
        teamFilters.withId = initialValues.team;
      }
      this.props.loadTeamsForSelect(teamFilters);
    }
    this.props.loadCategories();

    if (this.props.initialValues) {
      this.setState({type: this.props.initialValues.type});
    }
  }

  componentWillUnmount() {
    this.props.resetEntities(this.props.eventSource);
  }

  onSelectType = (e, value) => {
    if (value === 'text') {
      this.props.change('knockoutQuestionChoices', []);
    }

    this.setState({type: value});
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={renderTextField}
          name="content"
          type="text"
          placeholder="Question"
          required={true}
        />
        <Field
          component={SearchableSelect}
          name="type"
          label="Question Type"
          placeholder="Select Question Type"
          required={true}
          onChange={this.onSelectType}
          options={[
            {
              text: "Multiple Choice (One Answer)",
              value: 'single',
            },
            {
              text: "Multiple Choice (Many Answers)",
              value: "multiple",
            },
            {
              text: "Text",
              value: "text",
            }
          ]}
        />
        {this.props.teams && (
          <Field
            component={SearchableSelect}
            name="team"
            label="Team"
            placeholder="Select Team"
            normalize={val => val === "" ? null : val}
            options={this.props.teams}
          />
        )}
        <Field
          name="knockoutQuestionBankCategories"
          label="Categories"
          placeholder="Categories"
          isMulti={true}
          component={SearchableSelect}
          options={this.props.categories}
          required={true}
        >
        </Field>
        <FieldArray
          label="Profession Mappings"
          name="professionMappings"
          component={ProfessionMapping}
        />
        {this.state.type !== 'text' && (
          <>
          <FormLabel>Choices:</FormLabel>
          <GridItem sm={8} sm-offset={1}>
            <KnockoutQuestionChoicesEditor name="knockoutQuestionChoices"/>
          </GridItem>
          </>
         )}
        <button type="submit" className="btn btn-success float-left mr-3" onClick={this.props.handleSubmit}>
          Submit
        </button>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  teams: state.team.forSelect.retrieved,
  categories: state.knockoutquestionbank.categories.retrieved,
});

const mapDispatchToProps = dispatch => ({
  loadTeamsForSelect: (teamFilters) => dispatch(loadTeamsForSelect('@id', teamFilters)),
  loadCategories: () => dispatch(loadCategories()),
  resetEntities: () => {
    dispatch(resetTeams());
    dispatch(resetCategories());
  }
});

KnockoutQuestionBankForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(KnockoutQuestionBankForm);

export default reduxForm({
  form: 'knockoutQuestionBank',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(KnockoutQuestionBankForm);
