import React from "react";
import {renderFromHelper} from "../../utils/form";
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import 'assets/css/quill-theme-customization.css';
import InputLabel from "@material-ui/core/InputLabel";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import PropTypes from "prop-types";

const redAsterisks = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "red",
        "&$error": "red",
      }
    }
  }
});

let Link = Quill.import('formats/link');
class MyLink extends Link {
  static create(value) {
    let node = super.create(value);
    value = this.sanitize(value);
    node.setAttribute('href', value);

    node.removeAttribute('target');

    return node;
  }
}

Quill.register(MyLink);

class RichTextEditor extends React.Component {
  static propTypes = {
    emailContentEditor: PropTypes.bool,
  };

  static defaultProps = {
    toolbarOptions: [
      ['bold', 'italic', 'underline', 'link'],
      [{size: []}],
      [{'header': [1, 2, 3, 4, 5, 6]}],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{'indent': '-1'}, {'indent': '+1'}],
      [{'align': []}],
      ['clean'] // remove formatting button
    ]
  };

  componentWillMount() {
    this.componentId = (this.props.id) ?
      this.props.id :
      'rich_text_editor_' + Math.random().toString(36).substring(5);

    if (this.props.emailContentEditor) {
      let BlockBlot = Quill.import('blots/block');
      class CustomBlockBlot extends BlockBlot {
        static create(value) {
          const node = super.create(value);
          node.setAttribute('style', 'margin: 0px;');
          return node;
        }
      }
      Quill.register(CustomBlockBlot);

      let Inline = Quill.import('blots/inline');
      class Span extends Inline {
        static create(value) {
          let node = super.create(value);
          if (value) {
            node.setAttribute('class', value);
          }
          return node;
        }

        static formats(domNode) {
          return domNode.getAttribute("class");
        }

        format(name, value) {
          if (name !== this.statics.blotName || !value) return super.format(name, value);
          if (value){
            this.domNode.setAttribute('class', value);
          }
        }
      }
      Span.blotName = 'span';
      Span.tagName = 'span';
      Quill.register(Span);
    }
  }

  onChange = (value) => {
    const { input: { onChange} } = this.props;
    onChange(value);
  };

  renderLabel = () => {
    let {label, required} = this.props;
    const { input} = this.props;
    if (label === undefined) {
      label = input.name.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase();
      });
    }

    if (label !== null) {
      return <>
          <MuiThemeProvider theme={redAsterisks}>
             <InputLabel htmlFor={this.componentId} required={required}>{label}</InputLabel><br/>
          </MuiThemeProvider>
      </>
    } else {
      return (null);
    }
  };

  render() {
    const {input, placeholder, toolbarOptions, onChangeSelection, meta: {touched, error}} = this.props;

    return (
      <div className="form-group">
        {this.renderLabel()}
        <ReactQuill
          id={this.componentId}
          value={input.value}
          onChange={this.onChange}
          onChangeSelection={onChangeSelection}
          placeholder={placeholder}
          modules={{
            toolbar: toolbarOptions
          }}
        />
        <div>{renderFromHelper(touched, error)}</div>
      </div>
    )
  };
}

export default RichTextEditor;
