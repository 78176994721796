import { combineReducers } from 'redux';

export function error(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_ARCHIVE_ERROR':
      return action.error;
    case 'LIVEINTERVIEW_ARCHIVE_RESET':
      return null;
    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_ARCHIVE_LOADING':
      return action.loading;
    case 'LIVEINTERVIEW_ARCHIVE_RESET':
      return false;
    default:
      return state;
  }
}

export function archived(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_ARCHIVE_SUCCESS':
      return action.archived;
    case 'LIVEINTERVIEW_ARCHIVE_RESET':
      return null;
    default:
      return state;
  }
}

export default combineReducers({ error, loading, archived });
