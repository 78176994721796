import { SubmissionError } from 'redux-form';
import {
  fetch,
  extractHubURL,
  normalize,
  mercureSubscribe as subscribe, normalizeExcept, normalizeAttributes
} from '../../utils/dataAccess';
import { success as createSuccess } from './create';
import { loading, error } from './delete';
import {success as deleteSuccess} from "./delete";
import { reset as resetJobsForSelect } from "./../Job/forSelect";
import moment from "moment-timezone";

export function retrieveError(retrieveError) {
  return { type: 'LIVEINTERVIEW_UPDATE_RETRIEVE_ERROR', retrieveError };
}

export function retrieveLoading(retrieveLoading) {
  return { type: 'LIVEINTERVIEW_UPDATE_RETRIEVE_LOADING', retrieveLoading };
}

export function retrieveSuccess(retrieved) {
  return { type: 'LIVEINTERVIEW_UPDATE_RETRIEVE_SUCCESS', retrieved };
}

export function retrieve(id) {
  return dispatch => {
    dispatch(retrieveLoading(true));

    return fetch(id)
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        retrieved = normalizeExcept(retrieved, ['companyUserInvitations', 'candidateInvitations']);
        (retrieved.companyUserInvitations || []).forEach((invitation) => {
          normalizeAttributes(invitation, ['companyUser']);
        });
        (retrieved.candidateInvitations || []).forEach((invitation) => {
          normalizeAttributes(invitation, ['companyCandidate']);
        });

        dispatch(retrieveLoading(false));
        dispatch(retrieveSuccess(retrieved));

        if (hubURL) dispatch(mercureSubscribe(hubURL, retrieved['@id']));
      })
      .catch(e => {
        dispatch(retrieveLoading(false));
        dispatch(retrieveError(e.message));
      });
  };
}

export function updateError(updateError) {
  return { type: 'LIVEINTERVIEW_UPDATE_UPDATE_ERROR', updateError };
}

export function updateLoading(updateLoading) {
  return { type: 'LIVEINTERVIEW_UPDATE_UPDATE_LOADING', updateLoading };
}

export function updateSuccess(updated) {
  return { type: 'LIVEINTERVIEW_UPDATE_UPDATE_SUCCESS', updated };
}

export function update(item, values) {
  return dispatch => {
    dispatch(updateError(null));
    dispatch(createSuccess(null));
    dispatch(updateLoading(true));

    updateScheduledStartTimeToTimezoneForLiveInterview(values, item);

    return fetch(item['@id'], {
      method: 'PUT',

      data: values
    })
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        retrieved = normalize(retrieved);

        dispatch(updateLoading(false));
        dispatch(updateSuccess(retrieved));

        if (hubURL) dispatch(mercureSubscribe(hubURL, retrieved['@id']));
      })
      .catch(e => {
        dispatch(updateLoading(false));

        if (e instanceof SubmissionError) {
          let errorText = e.errors._error.replace(/candidateInvitations\[\d+]\./g, '');
          dispatch(updateError(errorText));
          throw e;
        }

        dispatch(updateError(e.message));
      });
  };
}

export function updateScheduledStartTimeToTimezoneForLiveInterview(values, item = null) {
  if (typeof values.scheduledStartTime === 'string') {
    values.scheduledStartTime = moment.tz(values.scheduledStartTime, item.timezone);
  }

  let stringTime = values.scheduledStartTime.format();
  if (Math.abs(values.scheduledStartTime.utcOffset()) !== 0) {
    /* convert date to string without timezone offset */
    stringTime = stringTime.slice(0, -6);
  }

  values.scheduledStartTime = moment.tz(stringTime, values.timezone);

  return values;
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'LIVEINTERVIEW_UPDATE_RESET' });
    dispatch(error(null));
    dispatch(loading(false));
    dispatch(createSuccess(null));
    dispatch(deleteSuccess(null));
    dispatch(updateSuccess(null));
    dispatch(resetJobsForSelect());
  };
}

export function mercureSubscribe(hubURL, topic) {
  return dispatch => {
    const eventSource = subscribe(hubURL, [topic]);
    dispatch(mercureOpen(eventSource));
    eventSource.addEventListener('message', event =>
      dispatch(mercureMessage(normalize(JSON.parse(event.data))))
    );
  };
}

export function mercureOpen(eventSource) {
  return { type: 'LIVEINTERVIEW_UPDATE_MERCURE_OPEN', eventSource };
}

export function mercureMessage(retrieved) {
  return dispatch => {
    if (1 === Object.keys(retrieved).length) {
      dispatch({ type: 'LIVEINTERVIEW_UPDATE_MERCURE_DELETED', retrieved });
      return;
    }

    dispatch({ type: 'LIVEINTERVIEW_UPDATE_MERCURE_MESSAGE', retrieved });
  };
}
