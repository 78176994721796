import {ENTRYPOINT, VIDEO_ENTRYPOINT} from "../../config/entrypoint";
import React, {Component} from "react";
import CombinedVideoRecorder from "./CombinedVideoRecorder";
import PropTypes from "prop-types";

class AnswerRecorder extends Component {
  static propTypes = {
    allowedToRecord: PropTypes.bool.isRequired,
    startRecordingDisabled: PropTypes.bool,
    streamManager: PropTypes.object,
    recordingUrl: PropTypes.string,
    onRecordingStarted: PropTypes.func,
    onRecordingStopping: PropTypes.func,
    onRecorded: PropTypes.func,
    onVideoReset: PropTypes.func,
    onError: PropTypes.func,
    videoText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    videoUrl: PropTypes.string,
    buttonLabels: PropTypes.object,
    additionalButtons: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.recorder = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.autostartRecording && this.props.autostartRecording) {
      this.startRecording();
    }
  }

  startRecording = () => {
    if (this.recorder && this.recorder.current) {
      this.recorder.current.startRecordingWithUi();
    }
  }

  stopRecording = () => {
    if (this.recorder && this.recorder.current) {
      this.recorder.current.stopRecordingWithUi();
    }
  }

  render() {
    const {
      streamManager, onRecordingStarted, onRecordingStopping, onVideoReset, videoText, allowedToRecord, startRecordingDisabled,
      onError, videoUrl, buttonLabels, additionalButtons, onRecorded
    } = this.props;
    return (
      <CombinedVideoRecorder
        ref={this.recorder}
        allowLocalRecording={true}
        streamManager={streamManager}
        className={'answer-recorder'}
        onRecordingStarted={onRecordingStarted}
        onRecordingStopping={onRecordingStopping}
        onRecorded={onRecorded}
        onVideoReset={onVideoReset}
        onError={onError}
        videoText={videoText}
        initialVideoUrl={videoUrl}
        allowedToRecord={allowedToRecord}
        startRecordingDisabled={startRecordingDisabled}
        buttonLabels={buttonLabels}
        additionalButtons={additionalButtons}
        browserRecorderConfig={{
          socketRecordingType: 'on-demand-interview-video-answer',
          storeVideoInMemory: false,
        }}
        openviduRecorderConfig={{
          getSessionUrl: VIDEO_ENTRYPOINT + '/answer/get-session',
          startRecordingUrl: VIDEO_ENTRYPOINT + '/answer/start-recording',
          stopRecordingUrl: VIDEO_ENTRYPOINT + '/answer/stop-recording',
        }}
      />
    );
  }
}

export default AnswerRecorder;
