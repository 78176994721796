import { SubmissionError } from 'redux-form';
import {
  fetch,
  extractHubURL,
  normalize
} from '../../utils/dataAccess';

export function deactivateError(deactivateError) {
  return { type: 'COMPANY_USER_DEACTIVATE_ERROR', deactivateError };
}

export function deactivateLoading(deactivateLoading) {
  return { type: 'COMPANY_USER_DEACTIVATE_LOADING', deactivateLoading };
}

export function deactivateSuccess(deactivated) {
  return { type: 'COMPANY_USER_DEACTIVATE_SUCCESS', deactivated };
}

export function deactivate(companyUser, callback) {
  return dispatch => {
    dispatch(deactivateError(null));
    dispatch(deactivateLoading(true));

    return fetch('/company_users/' + companyUser['id'] + '/deactivate', {
      method: 'PUT',
    })
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        retrieved = normalize(retrieved);

        dispatch(deactivateLoading(false));
        dispatch(deactivateSuccess(retrieved));

        if (typeof callback === 'function') {
          callback();
        }
      })
      .catch(e => {
        dispatch(deactivateLoading(false));

        if (e instanceof SubmissionError) {
          dispatch(deactivateError(e.errors._error));
          throw e;
        }

        dispatch(deactivateError(e.message));

        if (typeof callback === 'function') {
          callback();
        }
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'COMPANY_USER_DEACTIVATE_RESET' });
  };
}
