import React from "react";
import {BottomNavigation, BottomNavigationAction} from "@material-ui/core";
import InterviewIcon from '@material-ui/icons/Portrait';
import ChatIcon from '@material-ui/icons/Chat';
import JobIcon from '@material-ui/icons/CardTravel';
import BusinessIcon from '@material-ui/icons/Business';
import ProfileIcon from '@material-ui/icons/AccountBox';
import HelpIcon from '@material-ui/icons/Help';
import {currentUserHasRole} from "../../utils/auth";
import withStyles from "@material-ui/core/styles/withStyles";
import {bottomPanelActive} from "../../actions/LiveInterviewPage/interview";
import {connect} from "react-redux";
import LiveInterviewChat from "./LiveInterviewChat";
import VideoInterviewJobInfo from "./VideoInterviewJobInfo";
import VideoInterviewProfileInfo from "./VideoInterviewProfileInfo";
import VideoInterviewCompanyInfo from "./VideoInterviewCompanyInfo";
import Badge from "@material-ui/core/Badge";
import {EVENTS_SESSION_CHAT_MESSAGE} from "../../utils/liveInterviewSession";
import VideoInterviewHelpInfo from "./VideoInterviewHelpInfo";

const style = {
  navigation: {
    '& button': {
      outline: 'none !important',
    }
  },
  activePanel: {
    flexGrow: 1,
    padding: '1rem',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  panelHeader: {
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  panelContent: {
    flexGrow: 1,
    overflowY: 'auto',
    height: 0,
    display: 'flex',
  },
  actionButton: {
    minWidth: 'auto',
  },
}

class VideoInterviewBottomPanel extends React.Component {
  state = {
    activePanel: 'interview',
    unreadMessages: 0,
  }

  componentDidMount() {
    const { session } = this.props;
    session.on(EVENTS_SESSION_CHAT_MESSAGE, (message) => {
      if (this.state.activePanel !== 'chat' && !message.read && !message.own) {
        this.setState((prevState) => ({unreadMessages: prevState.unreadMessages + 1}));
      }
    })
  }

  togglePanel = (event, value) => {
    const bottomPanelActive = value !== 'interview';
    this.setState((prevState) => (
      {
        bottomPanelActive,
        activePanel: value,
        unreadMessages: (value === 'chat') ? 0 : prevState.unreadMessages,
      }
    ));
    this.props.bottomPanelActive(bottomPanelActive);
  };

  renderPanelContent = () => {
    const {classes} = this.props;
    const {activePanel} = this.state;
    return (
      <div className={classes.activePanel}>
        <h2 className={classes.panelHeader}>{activePanel}</h2>
        <div className={classes.panelContent}>
          {activePanel === 'chat' && (<LiveInterviewChat />)}
          {activePanel === 'job' && (<VideoInterviewJobInfo />)}
          {activePanel === 'company' && (<VideoInterviewCompanyInfo />)}
          {activePanel === 'candidate' && (<VideoInterviewProfileInfo />)}
          {activePanel === 'help' && (<VideoInterviewHelpInfo />)}
        </div>
      </div>
    )
  }

  render() {
    const {classes} = this.props;
    const {activePanel, bottomPanelActive} = this.state;
    const isCandidate = currentUserHasRole('ROLE_CANDIDATE');
    return (
      <div className={'live-interview-video-bottom-panel'}>
        <BottomNavigation
          value={activePanel}
          onChange={this.togglePanel}
          showLabels
          className={classes.navigation}
        >
          <BottomNavigationAction label="Interview" value="interview" icon={<InterviewIcon />} className={classes.actionButton} />
          <BottomNavigationAction label="Chat" value="chat" className={classes.actionButton} icon={
            <Badge color="secondary" badgeContent={this.state.unreadMessages}>
              <ChatIcon />
            </Badge>
          } />
          <BottomNavigationAction label="Job" value="job" icon={<JobIcon />} className={classes.actionButton} />
          {isCandidate ?
            (<BottomNavigationAction label="Company" value="company" icon={<BusinessIcon/>} className={classes.actionButton} />) :
            (<BottomNavigationAction label="Candidate" value="candidate" icon={<ProfileIcon/>} className={classes.actionButton} />)
          }
          <BottomNavigationAction label="Help" value="help" icon={<HelpIcon/>} className={classes.actionButton} />
        </BottomNavigation>
        {bottomPanelActive && (this.renderPanelContent())}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  session: state.liveInterviewPage.session,
})

const mapDispatchToProps = dispatch => ({
  bottomPanelActive: (active) => dispatch(bottomPanelActive(active)),
});

VideoInterviewBottomPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoInterviewBottomPanel);

VideoInterviewBottomPanel = withStyles(style)(VideoInterviewBottomPanel);

export default VideoInterviewBottomPanel;
