import React from "react";
import FormCard from "views/Components/FormCard.jsx";
import PasswordRecoveryForm from "components/ForgotPassword/PasswordRecoveryForm.jsx";
import Typography from '@material-ui/core/Typography';
import {requestPasswordRecovery, reset} from "actions/ForgotPassword/passwordRecovery";
import {connect} from "react-redux";
import {isAuthenticated, currentUserRoles} from "../../utils/auth";
import {resolveDashboardUrl} from "../../utils/dashboardUrlResolver";
import {LoginFormName} from "../../components/Login/LoginFormSettings";
import {formValueSelector} from "redux-form";

class PasswordRecoveryPage extends React.Component {
    componentWillMount() {
        if (isAuthenticated()) {
            this.props.history.push(resolveDashboardUrl(currentUserRoles()));
        }
    }

    componentWillUnmount() {
        this.props.reset();
    }

    render() {
        return (
            <FormCard header="Password Recovery">
                {this.props.loading && (
                    <div className="alert alert-info" role="status">
                        Loading...
                    </div>
                )}
                {this.props.sent && (
                    <div className="alert alert-success" role="status">
                        An email has been sent to you with instructions for resetting your password.
                    </div>
                )}
                {this.props.error && (
                  <div className="alert alert-danger" role="status">
                      {this.props.error}
                  </div>
                )}
                {!this.props.sent && (
                    <div>
                        <Typography variant="inherit" color="textSecondary" component="p">
                            Enter the email associated with your account and we'll send you instructions to reset your password.
                        </Typography>
                        <PasswordRecoveryForm
                            initialValues={{email: this.props.loginEmail}}
                            onSubmit={this.props.requestPasswordRecovery}
                        />
                    </div>
                )}
            </FormCard>
        );
    }
}

const selector = formValueSelector(LoginFormName);
const mapStateToProps = state => {
    const { sent, loading, error } = state.forgotPassword.passwordRecovery;
    return {
        sent,
        loading,
        error,
        loginEmail: selector(state, 'email'),
    };
};

const mapDispatchToProps = dispatch => ({
    requestPasswordRecovery: values => dispatch(requestPasswordRecovery(values)),
    reset: () => dispatch(reset())
});

export default PasswordRecoveryPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(PasswordRecoveryPage);
