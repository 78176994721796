import React, { Component } from 'react';
import {Field, formValueSelector, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {renderTextField} from "../../utils/form";
import SearchableSelect from "../../views/Components/SearchableSelect";
import "./Form.scss";
import braintree from "braintree-web";
import {create, reset} from "../../actions/PaymentMethod/create";
import {loadCountriesForSelect} from "../../actions/Country/forSelect";
import {loadStatesForSelect} from "../../actions/State/forSelect";
import {connect} from "react-redux";

class PaymentMethodCreate extends Component {
  hostedFieldsInstance = null;

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  state = {error: null};

  componentDidMount() {
    this.initializeBraintreeHostedFields();

    this.props.loadCountriesForSelect(this.props.site.country && this.props.site.country.code);
    this.props.loadStatesForSelect();
  }

  initializeBraintreeHostedFields = () => {
    braintree.client.create({
      authorization: this.props.site['braintreeClientTokenizationKey'],
    }, (clientErr, clientInstance) => {
      if (clientErr) {
        this.setState({error: clientErr.message});
        return;
      }

      const options = {
        client: clientInstance,
        styles: {
          input: {
            'font-size': '16px',
          },
          'input::placeholder': {
            'font-size': '15px',
            'font-family': 'Roboto, Helvetica, Arial, sans-serif',
            'font-weight': '400',
            'opacity': '0.42',
            'color': '#000',
          },
        },
        fields: {
          cardholderName: {
            container: '#cardholder-name',
            placeholder: 'Enter Name on Card'
          },
          number: {
            container: '#card-number',
            placeholder: 'Enter Card Number'
          },
          expirationDate: {
            container: '#expiration-date',
            placeholder: '12/' + (new Date().getFullYear()),
          },
          cvv: {
            container: '#cvv',
            placeholder: '123'
          },
        },
      };

      braintree.hostedFields.create(options, (hostedFieldsErr, hostedFieldsInstance) => {
        if (hostedFieldsErr) {
          this.setState({error: clientErr});
          return;
        }

        this.hostedFieldsInstance = hostedFieldsInstance;
      });
    });
  }

  onSubmit = (e) => {
    e.persist();
    e.preventDefault();
    const self = this;

    this.hostedFieldsInstance.tokenize((tokenizeErr, payload) => {
      if (tokenizeErr) {
        this.setState({error: tokenizeErr.message});
      } else {
        this.setState({error: null});

        self.props.change('nonce', payload.nonce);
        self.props.change('lastFour', payload.details.lastFour);
        self.props.change('brand', payload.details.cardType);
        self.props.handleSubmit(e);
      }
    });
  }

  render() {
    let states = [];
    if (this.props.selectedCountry !== null) {
      if (this.props.states) {
        this.props.states.map(state => {
          if (state.country === this.props.selectedCountry) {
            states.push(state);
          }
        });
      }
    }

    if (this.props.site && !this.props.site['braintreeClientTokenizationKey']) {
      return (
        <h3 className={"text-danger text-center"}>Error: "client tokenization key" is not set!</h3>
      );
    }

    return (
      <>
        {this.props.error || this.state.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error || this.state.error}
          </div>
        )}

      <form onSubmit={this.onSubmit} className={"add-payment-method-form"}>
        <h4>Credit Card Information</h4>

        <label htmlFor="cardholder-name" className={"credit-card-field-label"}>Name on Card</label>
        <div id="cardholder-name" />
        <label htmlFor="card-number" className={"credit-card-field-label"}>Card Number</label>
        <div id="card-number" />
        <label htmlFor="expiration-date" className={"credit-card-field-label"}>Expiration Date</label>
        <div id="expiration-date" />
        <label htmlFor="cvv" className={"credit-card-field-label"}>CVV <i className={"fa fa-question-circle-o"} /></label>
        <div id="cvv" />
        <div id="checkout-message" />

        <div className={"clearfix"} />

        <hr className={"mt-0"} />
        <h4>Billing Address</h4>
        <Field
          component={SearchableSelect}
          name="country"
          label="Country"
          placeholder={this.props.countries ? 'Select Country' : 'Loading...'}
          options={this.props.countries ? this.props.countries : []}
          required={true}
        />
        <Field
          component={renderTextField}
          name="address"
          label={"Street Address"}
          type="text"
          placeholder={"Street Address"}
          required={true}
        />
        <Field
          component={renderTextField}
          name="address2"
          label={"Street Address 2 (Optional)"}
          type="text"
          placeholder={"Street Address"}
          required={false}
        />

        <Field
          component={renderTextField}
          name="city"
          label={"City"}
          type="text"
          placeholder={"City"}
          required={true}
          containerClass="form-group col-md-6 float-left pl-0"
        />
        <Field
          component={SearchableSelect}
          name="state"
          label="State"
          containerClass="form-group col-md-6 float-left pr-0"
          placeholder={states.length > 0 ? 'Select State' : 'Select Country First'}
          options={states || []}
        />
        <Field
          component={renderTextField}
          name="zipCode"
          label={"Zip Code"}
          type="text"
          placeholder={"Zip Code"}
          required={true}
        />

        <button type="submit" className="btn btn-success float-left mr-3 mt-3">
          Submit
        </button>
      </form>
      </>
    );
  }
}

const selector = formValueSelector('payment_method');
const mapStateToProps = state => {
  const countries = state.country.forSelect.retrieved;
  const countriesLoading = state.country.forSelect.loading;
  const states = state.state.forSelect.retrieved;
  const statesLoading = state.state.forSelect.loading;
  const error = state.country.forSelect.error || state.state.forSelect.error;
  const selectedCountry = selector(state, 'country');
  const site =  state.siteDetails.siteDetails.retrieved;

  return { countries, countriesLoading, states, statesLoading, error, selectedCountry, site };
};

const mapDispatchToProps = dispatch => ({
  create: values => dispatch(create(values)),
  reset: () => dispatch(reset()),
  loadCountriesForSelect: (countryCode) => dispatch(loadCountriesForSelect('@id', countryCode)),
  loadStatesForSelect: () => dispatch(loadStatesForSelect('@id')),
});

PaymentMethodCreate = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentMethodCreate);

export default reduxForm({
  form: 'payment_method',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(PaymentMethodCreate);
