import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {fullScreenMode} from "../../actions/LiveInterviewPage/interview";
import {connect} from "react-redux";
import Fullscreen from "@material-ui/icons/Fullscreen";
import FullscreenExit from "@material-ui/icons/FullscreenExit";
import {IconButton} from "@material-ui/core";

const style = {
  button: {
    position: 'absolute',
    bottom: '1em',
    right: '1em',
    color: 'white',
    background: 'rgba(111,111,111,.5)',
    borderRadius: 0,
    padding: 0,
    zIndex: 100,
    "&:hover": {
      backgroundColor: 'rgba(111,111,111, 0.8)',
    },
  },
};

class FullScreenControl extends React.Component {
  render() {
    const {classes, fullscreenEnabled, setFullscreen} = this.props;

    return (
      <IconButton
        className={classes.button}
        style={{
          outline: 'none',
        }}
        onClick={() => setFullscreen(!this.props.fullscreenEnabled)}
        title={fullscreenEnabled ? ('Disable Fullscreen mode') : ('Enable Fullscreen mode') }
      >
        {fullscreenEnabled ? (<FullscreenExit fontSize="large" />) : (<Fullscreen fontSize="large" />) }
      </IconButton>
    );
  }
}

const mapStateToProps = state => ({
  fullscreenEnabled: state.liveInterviewPage.fullscreen,
});

const mapDispatchToProps = dispatch => ({
  setFullscreen: (enabled) => dispatch(fullScreenMode(enabled)),
});

FullScreenControl = connect(
  mapStateToProps,
  mapDispatchToProps
)(FullScreenControl);

FullScreenControl = withStyles(style)(FullScreenControl);

export default FullScreenControl;
