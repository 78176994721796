import {fetch} from "../../utils/dataAccess";
import {invitationReceived} from "./invitation";
import {interviewReceived} from "./interview";
import {loadInterviewCode} from "../../utils/onDemandInterview";

export function initializeByRoomCode(roomCode) {
  return async dispatch => {
    dispatch(loading(true));
    try {
      const response = await fetch('request/' + roomCode, {
        params: {
          'interviewCode': loadInterviewCode(roomCode),
        }
      });
      const request = response.data;
      const invitation = request.invitations && request.invitations.length > 0 ? request.invitations[0]: null;
      const interview = request.interviews && request.interviews.length > 0 ? request.interviews[0]: null;
      await dispatch(initialize(request, invitation, interview));
    } catch (e) {
      if (e.response && e.response.status === 404) {
        dispatch(error({
          title: 'Not found',
          subTitle: 'Interview with code ' + roomCode + ' not found',
          description: 'Please double check the interview invitation that you received',
        }));
      } else {
        dispatch(error(e));
      }
    }
    dispatch(loading(false));
  }
}

export function initializeByInvitationCode(invitationCode) {
  return async dispatch => {
    dispatch(loading(true));
    try {
      const response = await fetch('invitations/open/' + invitationCode, {method: 'POST'});
      const invitation = response.data;
      const request = invitation.request;
      const interview = invitation.oneWayInterview;
      await dispatch(initialize(request, invitation, interview));
    } catch (e) {
      if (e.response && e.response.status === 404) {
        dispatch(error({
          title: 'Not found',
          subTitle: 'Invitation with code ' + invitationCode + ' not found',
          description: 'Please double check the interview invitation that you received',
        }));
      } else if (e.response && e.response.status === 403) {
        dispatch(error({
          title: 'Access Denied',
          subTitle: e.response.data['hydra:description'],
        }));
      } else {
        dispatch(error(e));
      }
    }
    dispatch(loading(false));
  }
}

function initialize(request, invitation, interview) {
  return (dispatch) => {
    try {
      delete request.invitations;
      delete request.interview;
      dispatch(requestReceived(request));
      if (invitation) {
        delete invitation.request;
        dispatch(invitationReceived(invitation));
      }
      if (interview) {
        delete interview.request;
        dispatch(interviewReceived(interview));
      }
    } catch (e) {
      dispatch(error(e));
    }
  };
}

function requestReceived(request) {
  return { type: 'ON_DEMAND_INTERVIEW_PAGE_REQUEST', request };
}

function loading(loading) {
  return { type: 'ON_DEMAND_INTERVIEW_PAGE_LOADING', loading };
}

export function setStep(step) {
  return { type: 'ON_DEMAND_INTERVIEW_PAGE_STEP', step}
}

export function setThinkTimeStarted(thinkTimeStarted) {
  return { type: 'ON_DEMAND_INTERVIEW_PAGE_THINK_TIME_STARTED', thinkTimeStarted}
}

export function error(error) {
  return {type: 'ON_DEMAND_INTERVIEW_PAGE_ERROR', error};
}

export function reset() {
  return {type: 'ON_DEMAND_INTERVIEW_PAGE_RESET'};
}
