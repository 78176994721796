import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";
import {loadJobsForSelect} from "../../actions/Job/forSelect";
import {loadTeamsForSelect} from "../../actions/Team/forSelect";
import {loadRequestsForSelect} from "../../actions/Request/forSelect";
import {loadTagsForSelect} from "../../actions/Tag/forSelect";
import {currentUserHasRole} from "../../utils/auth";
import {loadIndustriesForSelect} from "../../actions/Industry/forSelect";
import {loadOccupationsForSelect} from "../../actions/Occupation/forSelect";
import {loadSpecialtiesForSelect} from "../../actions/Specialty/forSelect";

class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      jobsFilterIsModalOpen: false,
      industry:  null,
      occupation: null,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.initialValues !== this.props.initialValues) {
      const values = nextProps.initialValues;
      this.setState({
        industry: null !== values && ('request___job___professionMappings___industry' in values) ? values['request___job___professionMappings___industry'] : null,
        occupation: null !== values && ('request___job___professionMappings___occupation' in values) ? values['request___job___professionMappings___occupation'] : null,
      });
    }
  }

  handleIndustrySelect = (e, value, oldValue) => {
    if (value !== oldValue) {
      this.props.change('request___job___professionMappings___occupation', null);
      this.props.change('request___job___professionMappings___specialty', null);

      this.setState({
        industry: value,
        occupation: null,
      });
    }
  };

  handleOccupationSelect = (e, value, oldValue) => {
    if (value !== oldValue) {
      this.props.change('request___job___professionMappings___specialty', null);

      this.setState({occupation: value});
    }
  };

  onFirstExpand = () => {
    this.props.loadJobsForSelect({status: 'active'});
    this.props.loadTagsForSelect();
    if (currentUserHasRole('ROLE_COMPANY_ADMIN') || currentUserHasRole('ROLE_COMPANY_EMPLOYEE')) {
      this.props.loadRequestsForSelect();
    }
  }

  jobsFilterToggleModal = (opened) => {
    this.setState({jobsFilterIsModalOpen: opened});
  }

  selectJob = (job, fieldName = '@id') => {
    this.props.change('request___job', job[fieldName]);
    this.jobsFilterToggleModal(false);
  }

  render() {
    let occupationOptions = [];
    let specialtyOptions = [];

    let industryPlaceholder = this.props.industriesLoading ? 'Loading...' : 'Industries';
    let occupationPlaceholder = this.props.occupationsLoading ? 'Loading...' : 'Select Industry First';
    let specialtyPlaceholder = this.props.specialtiesLoading ? 'Loading...' : 'Select Occupation First';
    let teamPlaceholder = this.props.teamsLoading ? 'Loading...' : 'Team';
    let jobPlaceholder = this.props.jobsLoading ? 'Loading...' : 'Job';
    let requestPlaceholder = this.props.requestsLoading ? 'Loading...' : 'Interview';
    let tagPlaceholder = this.props.tagsLoading ? 'Loading...' : 'Tag';

    if (this.state.industry !== null) {
      occupationPlaceholder = 'Occupation';
      if (this.props.occupations) {
        this.props.occupations.map(occupation => {
          if (occupation.industry === this.state.industry) {
            occupationOptions.push(occupation);
          }
        });
      }
    }
    if (this.state.occupation !== null) {
      specialtyPlaceholder = 'Specialty';
      if (this.props.specialties) {
        this.props.specialties.map(specialty => {
          if (specialty.occupation === this.state.occupation) {
            specialtyOptions.push(specialty);
          }
        });
      }
    }

    const averageRatingMinimumChoices = [
      {text: '5 Stars', value: '5'},
      {text: '4 Stars & Up', value: '4'},
      {text: '3 Stars & Up', value: '3'},
      {text: '2 Stars & Up', value: '2'},
      {text: '1 Star & Up', value: '1'},
    ];
    const knockedOutChoices = [
      {text: 'Yes', value: 'false'},
      {text: 'No',  value: 'true'},
    ];
    const interviewStatusChoices = [
      {text: 'All', value: '__all__'},
      {text: 'Complete', value: 'true'},
      {text: 'Incomplete', value: 'false'}
    ];
    const invitationInterviewStatusChoices = [
      {value: 'pending', text: 'Pending'},
      {value: 'opened', text: 'Opened'},
      {value: 'accepted', text: 'Accepted'},
      {value: 'declined', text: 'Declined'},
      {value: 'complete', text: 'Complete'},
      {value: 'incomplete', text: 'Incomplete'},
      {value: 'expired', text: 'Expired'},
      {value: 'in_progress', text: 'In Progress'},
    ];

    const archivedStatuses = [
      {text: 'Yes', value: 'true'},
      {text: 'No', value: 'false'}
    ];

    const fields = [
      {'name': 'id', 'label': 'ID', 'placeholder': 'ID', 'type': 'number'},
      {'name': 'companyCandidate___fullName', 'label': 'Candidate', 'placeholder': 'Candidate', 'type': 'text'},
      {'name': 'companyCandidate___email', 'label': 'Candidate Email', 'placeholder': 'Candidate Email', 'type': 'text'},
      {'name': 'archivedAt__exists__', 'label': 'Archived', 'type': 'selectSearch', 'placeholder': 'Archived', 'options': archivedStatuses},
      {'name': 'request___job___team', 'label': 'Team', 'placeholder': teamPlaceholder, 'type': 'selectSearch', 'options': this.props.teams},
      {'name': 'request___job', 'label': 'Job', 'placeholder': jobPlaceholder, 'type': 'jobSelectSearch', 'options': this.props.jobs,
        'jobsFilterSelectJob': this.selectJob,
        'jobsFilterToggleModal': this.jobsFilterToggleModal,
        'jobsFilterIsModalOpen': this.state.jobsFilterIsModalOpen,
      },
    ];
    if (currentUserHasRole('ROLE_COMPANY_ADMIN') || currentUserHasRole('ROLE_COMPANY_EMPLOYEE')) {
      fields.push({'name': 'request', 'label': 'Interview', 'placeholder': requestPlaceholder, 'type': 'selectSearch', 'options': this.props.requests});
    }
    fields.push(
      {'name': 'totalAverageRating__gte__', 'label': 'Average Rating Minimum', 'placeholder': 'Average Rating Minimum', 'type': 'selectSearch', 'options': averageRatingMinimumChoices},
      {'name': 'tags', 'label': 'Tags', 'placeholder': tagPlaceholder, 'type': 'selectSearch', 'options': this.props.tags},
      {'name': 'request___job___professionMappings___industry', 'label': 'Industry', 'placeholder': industryPlaceholder, 'type': 'selectSearch', 'onChange': this.handleIndustrySelect, 'options': this.props.industries},
      {'name': 'request___job___professionMappings___occupation', 'label': 'Occupation', 'placeholder': occupationPlaceholder, 'type': 'selectSearch', 'isDisabled': !this.state.industry, 'onChange': this.handleOccupationSelect, 'options': occupationOptions},
      {'name': 'request___job___professionMappings___specialty', 'label': 'Specialty', 'placeholder': specialtyPlaceholder, 'type': 'selectSearch', 'isDisabled': !this.state.occupation, 'options': specialtyOptions},
      {'name': 'knockedOut', 'label': 'Passed Screening Questions', 'placeholder': 'Select One', 'type': 'selectSearch', 'options': knockedOutChoices},
      {'name': 'completedAt__exists__', 'label': 'Interview Status', 'placeholder': 'Interview Status', 'type': 'selectSearch', 'options': interviewStatusChoices},
      {'name': 'invitation___interviewStatus', 'label': 'Invitation Interview Status', 'placeholder': 'Invitation Interview Status', 'type': 'selectSearch', 'options': invitationInterviewStatusChoices},
      {'name': 'completedAt', 'label': 'Completed Date', 'placeholder': 'Completed Date', 'type': 'datesRange', 'past': true},
    );

    return (
      <>
        {!this.props.parentLoading && this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        <RenderFilterForm {...this.props} fields={fields} onFirstExpand={this.onFirstExpand} />
      </>
      );
  }
}

const mapStateToProps = state => {
  let teams = state.team.forSelect.retrieved;
  let jobs = state.job.forSelect.retrieved;
  let requests = state.request.forSelect.retrieved;
  let tags = state.tag.forSelect.retrieved;
  let industries = state.industry.forSelect.retrieved;
  let occupations = state.occupation.forSelect.retrieved;
  let specialties = state.specialty.forSelect.retrieved;
  let loading = state.team.forSelect.loading || state.job.forSelect.loading || state.request.forSelect.loading || state.tag.forSelect.loading || state.industry.forSelect.loading || state.occupation.forSelect.loading || state.specialty.forSelect.loading;

  let teamsLoading = state.team.forSelect.loading;
  let jobsLoading = state.job.forSelect.loading;
  let requestsLoading = state.request.forSelect.loading;
  let tagsLoading = state.tag.forSelect.loading;
  let industriesLoading = state.industry.forSelect.loading;
  let occupationsLoading = state.occupation.forSelect.loading;
  let specialtiesLoading = state.specialty.forSelect.loading;

  return {
    teams,
    jobs,
    requests,
    tags,
    industries,
    occupations,
    specialties,
    loading,
    teamsLoading,
    jobsLoading,
    requestsLoading,
    tagsLoading,
    industriesLoading,
    occupationsLoading,
    specialtiesLoading
  };
};

const mapDispatchToProps = dispatch => ({
  loadTeamsForSelect: () => dispatch(loadTeamsForSelect()),
  loadJobsForSelect: (filters) => dispatch(loadJobsForSelect(filters)),
  loadRequestsForSelect: () => dispatch(loadRequestsForSelect()),
  loadTagsForSelect: () => dispatch(loadTagsForSelect()),
  loadIndustriesForSelect: () => dispatch(loadIndustriesForSelect('@id')),
  loadOccupationsForSelect: () => dispatch(loadOccupationsForSelect('@id')),
  loadSpecialtiesForSelect: () => dispatch(loadSpecialtiesForSelect('@id')),
});

FilterForm = reduxForm({
  form: 'filter_company_one_way_interview',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterForm);
