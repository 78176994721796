import {fetch} from "../../utils/dataAccess";
import {jobLocation} from "../../utils/jobLocation";

export function loadJobsForSelect(filters = {}) {
  return dispatch => {
    dispatch(loading(true));
    fetch('/jobs', {
      method: 'GET',
      params: {
        pagination: false,
        ...filters
      }
    })
    .then(response => response.data)
    .then(jobs => {
      dispatch(jobsLoaded(jobs['hydra:member'].map(
        function (job) {
          const jobId = job.jobNumber ? job.jobNumber : job.id;
          return {
            value: job['@id'],
            text: `${job.title} - (${jobId}) - ${jobLocation(job)}`,
          }
        }
      )));
      dispatch(loading(false));
    }).catch(e => {
      dispatch(loading(false));
      dispatch(error(e.message));
    })
  };
}

export function loading(loading) {
  return {type: 'JOB_FOR_SELECT_LOADING', loading};
}

export function error(error) {
  return {type: 'JOB_FOR_SELECT_ERROR', error};
}

export function jobsLoaded(retrieved) {
  return {type: 'JOB_FOR_SELECT_RETRIEVED', retrieved};
}

export function reset() {
  return {type: 'JOB_FOR_SELECT_RESET'};
}
