import React, {Component} from "react";
import PropTypes from "prop-types";
import {Field} from "redux-form";
import {renderAutocomplete, renderTextField} from "../../utils/form";
import {Card, CardHeader, CardContent} from "@material-ui/core";
import { list, reset } from "../../actions/CompanyCandidate/list";
import { connect } from 'react-redux';
import {applyToUrl} from "../../utils/urls";
import MenuItem from '@material-ui/core/MenuItem';
import SearchableSelect from "../../views/Components/SearchableSelect";
import {SALUTATION_CHOICES} from "../Invitation/SendInvitationsForm";

class CandidateInvitation extends Component {
  static defaultProps = {
    allowEdit: true,
  };

  static timeoutId;

  static propTypes = {
    name: PropTypes.string.isRequired,
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    searchCandidates: PropTypes.func.isRequired,
  };

  onSuggestionsFetchRequested = ({value}) => {
    clearTimeout(CandidateInvitation.timeoutId);

    CandidateInvitation.timeoutId = setTimeout(() => {
      let searchValue = value.trim();
      if (searchValue.length > 0) {
        this.props.reset();
        this.props.searchCandidates(applyToUrl('company_candidates/select', {'firstName': searchValue}));
      } else {
        this.props.reset();
      }
    }, 300);
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    return (
      <MenuItem selected={isHighlighted} component="div" value={suggestion['@id']}>
        {suggestion.firstName} {suggestion.lastName} ({suggestion.email})
      </MenuItem>
    );
  };

  getSuggestionValue = (suggestion) => {
    return suggestion.firstName;
  };

  onSuggestionSelected = (event, {suggestion}) => {
    this.props.change(this.props.name + '[lastName]', suggestion.lastName);
    this.props.change(this.props.name + '[email]', suggestion.email);
    this.props.change(this.props.name + '[phoneNumber]', suggestion.phoneNumber);

    event.preventDefault();
  };

  render() {
    const {invitation, name, allowDelete, allowEdit, onDelete} = this.props;
    const showDeleteButton = allowDelete || !invitation['@id'];
    const disabled = !(allowEdit || !invitation['@id']);

    return (
      <Card className='mb-4' style={{overflow: "visible"}}>
        <CardHeader
          disableTypography={true}
          subheader={
            <>
              <Field
                component={SearchableSelect}
                containerClass='form-group col-md-4 float-left'
                label='Salutation'
                name={`${name}[salutation]`}
                placeholder="Salutation"
                isDisabled={disabled}
                options={SALUTATION_CHOICES}
              />
              <Field
                component={renderAutocomplete}
                containerClass='form-group col-md-4 float-left'
                name={`${name}[firstName]`}
                label='First Name'
                placeholder="First Name"
                required={!disabled}
                suggestions={this.props.retrieved ? this.props.retrieved['hydra:member'] : []}
                disabled={disabled}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                renderSuggestion={this.renderSuggestion}
                getSuggestionValue={this.getSuggestionValue}
                onSuggestionSelected={this.onSuggestionSelected}
                loading={this.props.loading}
              />
              <Field
                component={renderTextField}
                containerClass='form-group col-md-4 float-left'
                label='Last Name'
                name={`${name}[lastName]`}
                type="text"
                placeholder="Last Name"
                disabled={disabled}
              />
            </>
          }
        />
        <CardContent>
          <Field
            component={renderTextField}
            containerClass={`form-group col-md-4 float-left`}
            label='Email'
            name={`${name}[email]`}
            type="email"
            placeholder="Email"
            required={!disabled}
            disabled={disabled}
          />
          <Field
            component={renderTextField}
            containerClass={`form-group col-md-4 float-left`}
            label='Phone Number'
            name={`${name}[phoneNumber]`}
            type="text"
            placeholder="Phone Number"
            disabled={disabled}
          />
          {showDeleteButton && (
            <div className='col-md-2 float-left mt-4 mb-3'>
              <button type='button' className='btn btn-danger' onClick={onDelete}>Remove</button>
            </div>
          )}
          <div className="clearfix" />
        </CardContent>
      </Card>
    );
  };
}

const mapStateToProps = state => {
  const {
    retrieved,
    loading,
    error,
  } = state.companyCandidate.list;

  return { retrieved, loading, error };
};

const mapDispatchToProps = dispatch => ({
  searchCandidates: page => dispatch(list(page)),
  reset: () => dispatch(reset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateInvitation);
