import { combineReducers } from 'redux';

export function enableAutoRenewError(state = null, action) {
  switch (action.type) {
    case 'SUBSCRIPTION_ENABLE_AUTO_RENEW_ERROR':
      return action.error;

    case 'SUBSCRIPTION_ENABLE_AUTO_RENEW_RESET':
      return null;

    default:
      return state;
  }
}

export function enableAutoRenewLoading(state = false, action) {
  switch (action.type) {
    case 'SUBSCRIPTION_ENABLE_AUTO_RENEW_LOADING':
      return action.loading;

    case 'SUBSCRIPTION_ENABLE_AUTO_RENEW_RESET':
      return false;

    default:
      return state;
  }
}

export function enableAutoRenewSuccess(state = null, action) {
  switch (action.type) {
    case 'SUBSCRIPTION_ENABLE_AUTO_RENEW_SUCCESS':
      return action.success;

    case 'SUBSCRIPTION_ENABLE_AUTO_RENEW_RESET':
      return null;

    default:
      return state;
  }
}

export function eventSource(state = null, action) {
  switch (action.type) {
    case 'SUBSCRIPTION_ENABLE_AUTO_RENEW_MERCURE_OPEN':
      return action.eventSource;

    case 'SUBSCRIPTION_ENABLE_AUTO_RENEW_RESET':
      return null;

    default:
      return state;
  }
}

export default combineReducers({
  enableAutoRenewSuccess,
  enableAutoRenewError,
  enableAutoRenewLoading,
  eventSource,
});
