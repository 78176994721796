import React, { Component } from 'react';
import {Field, reduxForm, FieldArray} from 'redux-form';
import {renderTextField} from "../../utils/form";
import TeamCompanyUsers from "./TeamCompanyUsers";

class TeamForm extends Component {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={renderTextField}
          name="name"
          type="text"
          placeholder="Name"
          required={true}
        />
        <FieldArray
          label="Company Users"
          name="teamCompanyUsers"
          component={TeamCompanyUsers}
          required={true}
        />
        <button type="submit" className="btn btn-success float-left mr-3">
          Submit
        </button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'team',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(TeamForm);
