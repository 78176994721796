import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";
import {loadJobsForSelect} from "../../actions/Job/forSelect";
import {loadTeamsForSelect} from "../../actions/Team/forSelect";
import {loadCompanyUsersForSelect} from "../../actions/CompanyUser/forSelect";

class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      jobsFilterIsModalOpen: false,
    }
  }

  onFirstExpand = () => {
    this.props.loadJobsForSelect();
  }

  jobsFilterToggleModal = (opened) => {
    this.setState({jobsFilterIsModalOpen: opened});
  }

  selectJob = (job, fieldName = '@id') => {
    this.props.change('request___job', job[fieldName]);
    this.jobsFilterToggleModal(false);
  }

  render() {
    const companyUserPlaceholder = this.props.companyUsersLoading ? 'Loading...' : 'Company User';
    const teamPlaceholder = this.props.companyUsersLoading ? 'Loading...' : 'Team';
    const requestPlaceholder = this.props.requestsLoading ? 'Loading...' : 'Interview Title';
    const jobPlaceholder = this.props.jobsLoading ? 'Loading...' : 'Job';

    const statuses = [
      {value: 'pending', text: 'Pending'},
      {value: 'opened', text: 'Opened'},
      {value: 'accepted', text: 'Accepted'},
      {value: 'declined', text: 'Declined'},
      {value: 'complete', text: 'Complete'},
      {value: 'incomplete', text: 'Incomplete'},
      {value: 'expired', text: 'Expired'},
      {value: 'in_progress', text: 'In Progress'},
    ];

    return (
      <>
        {!this.props.parentLoading && this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        <RenderFilterForm {...this.props} onFirstExpand={this.onFirstExpand} fields={[
          {'name': 'request', 'label': 'Interview Title', 'placeholder': requestPlaceholder, 'type': 'selectSearch', 'options': this.props.requests},
          {'name': 'request___job', 'label': 'Job', 'placeholder': jobPlaceholder, 'type': 'jobSelectSearch', 'options': this.props.jobs,
            'jobsFilterSelectJob': this.selectJob,
            'jobsFilterToggleModal': this.jobsFilterToggleModal,
            'jobsFilterIsModalOpen': this.state.jobsFilterIsModalOpen,
          },
          {'name': 'status', 'label': 'Status', 'placeholder': 'Status', 'type': 'selectSearch', 'options': statuses},
          {'name': 'interviewStatus', 'label': 'Interview Status', 'placeholder': 'Interview Status', 'type': 'selectSearch', 'options': statuses},
          {'name': 'request___job___team', 'label': 'Team', 'placeholder': teamPlaceholder, 'type': 'selectSearch', 'options': this.props.teams},
          {'name': 'request___job___companyUser', 'label': 'Company User', 'placeholder': companyUserPlaceholder, 'type': 'selectSearch', 'options': this.props.companyUsers},
          {'name': 'firstName', 'label': 'Candidate First Name', 'placeholder': 'Candidate First Name', 'type': 'text'},
          {'name': 'lastName', 'label': 'Candidate Last Name', 'placeholder': 'Candidate Last Name', 'type': 'text'},
          {'name': 'email', 'label': 'Candidate Email', 'placeholder': 'Candidate Email', 'type': 'text'},
          {'name': 'createdAt', 'label': 'Created Date', 'placeholder': 'Created Date', 'type': 'datesRange', 'past': true},
        ]} />
      </>
    );
  }
}

const mapStateToProps = state => ({
  companyUsers: state.companyUser.forSelect.retrieved,
  requests: state.request.forSelect.retrieved,
  teams: state.team.forSelect.retrieved,
  jobs: state.job.forSelect.retrieved,
  companyUsersLoading: state.companyUser.forSelect.loading,
  requestsLoading: state.request.forSelect.loading,
  teamsLoading: state.team.forSelect.loading,
  jobsLoading: state.job.forSelect.loading,
  loading: state.companyUser.forSelect.loading || state.request.forSelect.loading || state.team.forSelect.loading || state.job.forSelect.loading,
});

const mapDispatchToProps = dispatch => ({
  loadCompanyUsersForSelect: () => dispatch(loadCompanyUsersForSelect()),
  loadTeamsForSelect: () => dispatch(loadTeamsForSelect()),
  loadJobsForSelect: () => dispatch(loadJobsForSelect()),
});

FilterForm = reduxForm({
  form: 'company_invitation',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterForm);
