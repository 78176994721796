import {combineReducers} from "redux";

function size(state = {}, action) {
  switch (action.type) {
    case 'SCREEN_SIZE':
      return action.sizes;
    default:
      return state;
  }
}

export default combineReducers({ size });
