import React, { Component } from 'react';
import InterviewLayout from "./InterviewLayout";

class LiveInterviewLayout extends Component {
  render() {
    return (
      <InterviewLayout interviewType='live-interview' />
    );
  }
}

export default LiveInterviewLayout;
