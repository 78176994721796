import React, {Component} from 'react';
import {renderTextField} from "../../utils/form";
import {Field, reduxForm} from "redux-form";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
import FormControl from "@material-ui/core/FormControl";
import withStyles from "@material-ui/core/styles/withStyles";

const style = {
  formControl: {
    marginBottom: '1em',
  },
  nextButton: {
    display: 'block',
    minWidth: '80%',
    margin: '0 auto',
  }
}

class InvitationEmailForm extends Component {
  render() {
    const {classes, checking} = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <FormControl fullWidth={true} className={classes.formControl}>
          <Field
            component={renderTextField}
            name="email"
            type="email"
            placeholder="Email"
            required={true}
            label={false}
            InputProps={{startAdornment: (<InputAdornment position="start"><Email /></InputAdornment>)}}
          />
        </FormControl>
        <button type="submit" className={"btn btn-info " + classes.nextButton} disabled={checking}>
          {!checking ? ('Next') : ('Loading...')}
        </button>
      </form>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Please enter an email';
  }

  return errors;
};

InvitationEmailForm = reduxForm({
  form: 'invitation_email_form',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(InvitationEmailForm);

InvitationEmailForm = withStyles(style)(InvitationEmailForm);

export default InvitationEmailForm;
