import React, {Component} from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import VideoPlayerWithPlaybackRate from "../video/VideoPlayerWithPlaybackRate";

const style = {
  root: {
    '& .video-react': {
      background: 'none',
    }
  }
};

class LiveInterviewComposedVideoPlayer extends Component {
  static propTypes = {
    composedVideo: PropTypes.object.isRequired,
    seek: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.player = React.createRef();
  }

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.root}>
        <VideoPlayerWithPlaybackRate src={this.props.composedVideo.path} seek={this.props.seek} />
      </div>
    );
  }
}

LiveInterviewComposedVideoPlayer = withStyles(style)(LiveInterviewComposedVideoPlayer);

export default LiveInterviewComposedVideoPlayer;
