import React, {Component} from 'react'
import Button from '@material-ui/core/Button';
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {CloudDownload} from '@material-ui/icons';
import {renderFromHelper} from "../../utils/form";
import PropTypes from "prop-types";

const style = {
    input: {
        display: 'none'
    },
    inputLabel: {
        cursor: 'pointer',
        width: '100%',
        height: '100%',
        marginBottom: 0,
    },
    actions: {
        justifyContent: 'center',
    },
    cardActionArea: {
        display: "inline-flex",
    },
    previewContainer: {
        display: 'flex',
        verticalAlign: 'middle',
        justifyContent: 'center',
        minHeight: '50px',
        maxHeight: '500px',
    },
    imagePreview: {
        display: 'block',
        width: 'auto',
        height: 'auto',
        maxHeight: '500px',
        maxWidth: '100%',
    },
    textPreview: {
        paddingBottom: 0,
        paddingTop: '26px',
        "& h2": {
            marginBottom: 0,
        }
    }
};

class FileUploadComponent extends Component{
    static defaultProps = {
        buttonText: 'Select File',
        deleteButtonText: 'Delete',
        downloadButtonText: 'Download',
        showDownloadButton: true,
        accept: '*',
    };

    static propTypes = {
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        fileNameMaxLength: PropTypes.number,
    };

    componentWillMount() {
        this.componentId = (this.props.id) ?
            this.props.id :
            'api_file_upload_' + Math.random().toString(36).substring(5);
    }

    onChange = (e) => {
        const { onChange } = this.props;
        let file = e.target.files[0];
        if (file) {
            onChange(file);
        } else {
            onChange(null);
        }
    };

    onDrop = (e) => {
        const { onChange } = this.props;
        e.preventDefault();
        if (e.dataTransfer.items && e.dataTransfer.items.length) {
            onChange(e.dataTransfer.items[0].getAsFile());
        } else {
            onChange(null);
        }
    }

    onDragOver = (e) => {
        //Need to disable default browser behavior to not open file and allow drop
        e.preventDefault();
    }

    renderLabel = () => {
        let {label, required, name} = this.props;
        if (label === undefined) {
            label = name.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
                return str.toUpperCase();
            });
        }

        if (label === false) {
            return null;
        }

        return <><InputLabel htmlFor={this.componentId} required={required}>{label}</InputLabel><br/></>
    };

    renderPreview = (type, {url, fileContent, fileName} = {}) => {
        const { classes, fileNameMaxLength } = this.props;

        if (type === 'image' && (fileContent || url)) {
            let src = (url) ? url : fileContent;
            return (
                <CardMedia
                    component="img"
                    alt={fileName}
                    image={src}
                    title={fileName}
                    className={classes.imagePreview}
                />
            )
        } else {
            let previewText = '';
            if (fileName) {
                if (!fileNameMaxLength) {
                    previewText = fileName;
                } else {
                    previewText = fileName.substr(0, fileNameMaxLength).trim() + '...';
                }
            } else {
                previewText = (<span>{this.props.buttonText}</span>);
            }
            return (
                <CardContent className={classes.textPreview}>
                    <Typography gutterBottom variant="h5" component="h2" color="textSecondary">
                        {previewText}
                    </Typography>
                </CardContent>
            )
        }
    };

    render(){
        const {
            accept, type, value, error, classes, fileUrl, downloadButtonText, showDownloadButton,
        } = this.props;

        return(
            <div className="form-group">
                {this.renderLabel()}
                <input
                    className={classes.input}
                    id={this.componentId}
                    type='file'
                    accept={accept}
                    onChange={this.onChange}
                    value=''
                />
                <Card className={classes.card} onDrop={this.onDrop} onDragOver={this.onDragOver}>
                    <CardActionArea className={classes.cardActionArea}>
                        <label htmlFor={this.componentId} className={classes.inputLabel}>
                            <div className={classes.previewContainer}>
                            {this.renderPreview(type, value)}
                            </div>
                        </label>
                    </CardActionArea>
                    {value && (
                        <CardActions className={classes.actions}>
                            <Button color="secondary" onClick={() => this.props.onChange()}>
                                {this.props.deleteButtonText}
                            </Button>
                            {showDownloadButton && (value.url || fileUrl) && (
                            <Link href={value.url ? value.url : fileUrl}>
                                <Button color="primary">
                                    <CloudDownload className="mr-1"/> {downloadButtonText}
                                </Button>
                            </Link>
                            )}
                        </CardActions>
                    )}
                </Card>
                {renderFromHelper(true, error)}
            </div>
        )
    }
}

export default withStyles(style)(FileUploadComponent);
