import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

class AdjustableText extends Component {
  static propTypes = {
    maxHeight: PropTypes.number.isRequired,
    maxFontSize: PropTypes.number.isRequired,
    children: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);
    this.container = React.createRef();

    this.state = {
      fontSize: this.props.maxFontSize,
    };
    this.widthToFontSize = {};
  }

  componentDidMount() {
    this.recalculateFontSize();
    //Sometimes container size is incorrect on did mount event
    setTimeout(this.recalculateFontSize, 100);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.screenSize.width !== this.props.screenSize.width || this.state.fontSize !== prevState.fontSize) {
      this.recalculateFontSize();
    }
  }

  recalculateFontSize = () => {
    if (!this.container || !this.container.current) {
      return;
    }

    const {screenSize, maxHeight, maxFontSize} = this.props;
    const {fontSize} = this.state;
    const screenWidth = screenSize.width;
    const containerHeight = this.container.current.clientHeight;
    if (containerHeight < maxHeight && fontSize === maxFontSize) {
      return;
    }

    if (
      containerHeight < maxHeight &&
      fontSize < maxFontSize &&
      (!this.widthToFontSize[screenWidth] || this.widthToFontSize[screenWidth] !== (fontSize + 0.5))
    ) {
        this.setState({fontSize: fontSize + 0.5});
    }

    if (containerHeight > maxHeight && fontSize > 1) {
      this.widthToFontSize[screenWidth] = fontSize;
      this.setState({fontSize: fontSize - 0.5});
    }
  }

  getStyle = () => {
    const {fontSize} = this.state;

    return {
      fontSize: fontSize,
    };
  }

  render() {
    const {children} = this.props;
    return (
      <div ref={this.container} style={this.getStyle()}>
        {children}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  screenSize: state.screen.size,
});

AdjustableText = connect(
  mapStateToProps
)(AdjustableText);

export default AdjustableText;
