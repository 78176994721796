import {fetchJSON} from "../../utils/dataAccess";

export function loadCompanyUsers() {
  return dispatch => {
    dispatch(loading(true));
    fetchJSON('/company_users?pagination=false').then((users) => {
      dispatch(companyUsersLoaded(users['hydra:member']));
      dispatch(loading(false));
    }).catch(e => {
      dispatch(loading(false));
      dispatch(error(e.message));
    })
  };
}

export function reset() {
  return {type: 'ACCESS_COMPANY_USERS_RESET'};
}

export function loading(loading) {
  return {type: 'ACCESS_COMPANY_USERS_LOADING', loading};
}

export function error(error) {
  return {type: 'ACCESS_COMPANY_USERS_ERROR', error};
}

export function companyUsersLoaded(retrieved) {
  return {type: 'ACCESS_COMPANY_USERS_RETRIEVED', retrieved};
}
