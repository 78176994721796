import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { renderTextareaField } from "../../utils/form";
import { connect } from "react-redux";
import { create, reset } from "../../actions/OneWayInterviewComment/create";
import { update } from "../../actions/OneWayInterviewComment/update";
import { del } from "../../actions/OneWayInterviewComment/delete";

class OneWayInterviewCommentForm extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    deleted: PropTypes.object,
    created: PropTypes.object,
    updated: PropTypes.object,
  };

  componentWillUnmount() {
    this.props.resetAll();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.deleted || nextProps.created || nextProps.updated) {
      this.props.setShowForm(false, null);
    }
  }

  del = (item) => {
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.del(item);
  };

  render() {
    const initialValues = this.props.initialValues;
    const onSubmit = initialValues.id ? this.props.update : this.props.create;

    return (
      <div>
        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}

        <form onSubmit={this.props.handleSubmit(onSubmit)}>
          <Field
            component={renderTextareaField}
            name="content"
            placeholder="Comment"
            required={true}
          />

          <button type="submit" className="btn btn-success">
            Save Comment
          </button>
          {initialValues.id && (
            <button onClick={() => {this.del(initialValues)}} type="button" className="btn btn-danger ml-3">Delete</button>
          )}
          <button type="button" className="btn btn-primary ml-3" onClick={() => {this.props.setShowForm(false, null)}}>Back to List</button>
        </form>
      </div>
    );
  }
}

OneWayInterviewCommentForm = reduxForm({
  form: 'one_way_interview_comment',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(OneWayInterviewCommentForm);

const mapStateToProps = state => {
  return {
    loading: state.onewayinterviewcomment.create.loading || state.onewayinterviewcomment.update.updateLoading || state.onewayinterviewcomment.del.loading,
    error: state.onewayinterviewcomment.create.error || state.onewayinterviewcomment.update.updateError || state.onewayinterviewcomment.del.error,
    deleted: state.onewayinterviewcomment.del.deleted,
    created: state.onewayinterviewcomment.create.created,
    updated: state.onewayinterviewcomment.update.updated,
  };
};

const mapDispatchToProps = dispatch => ({
  create: values => dispatch(create(values)),
  update: values => dispatch(update(values)),
  resetAll: () => dispatch(reset()),
  del: page => dispatch(del(page)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OneWayInterviewCommentForm);
