//Import webrtc shim, no further action with this variable is required.
import React, {Component} from 'react';

import './../../views/Pages/css/InterviewPage.scss';
import './../../views/Pages/css/OnDemandInterviewPage.scss';

import classNames from 'classnames';
import {reset, setStep} from "../../actions/OnDemandInterviewPage/page";
import {connect} from "react-redux";
import ErrorPage from "../../views/Pages/ErrorPage";
import login from "../../assets/img/inorc_light_bg.jpg";
import OnDemandInterviewHeader from "./OnDemandInterviewHeader";
import OnDemandInterviewSteps, {STEP_ABOUT, STEP_QUESTIONS, STEP_TEST} from "./OnDemandInterviewSteps";
import OnDemandInterviewContent from "./OnDemandInterviewContent";
import OnDemandInterviewFooter from "./OnDemandInterviewFooter";
import OnDemandInterviewStepsController from "./OnDemandInterviewStepsController";
import {clearInterviewCodes, clearStreamManagerConfig, isInterviewFinished} from "../../utils/onDemandInterview";
import {getUserId, isAuthenticated} from "../../utils/auth";
import SiteSettingsBox, {showSiteSettingsBox} from "../../views/Pages/SiteSettingsBox";
import PropTypes from 'prop-types';
import LogRocketTracker from "../Logging/LogRocketTracker";
import storage from "../../utils/storage";
import {IRItoID} from "../../utils/dataAccess";

class OnDemandInterviewPage extends Component {
  static defaultProps = {
    includeLoginButton: true,
  };
  static propTypes = {
    includeLoginButton: PropTypes.bool,
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!isInterviewFinished(prevProps.interview) && isInterviewFinished(this.props.interview)) {
      this.onInterviewFinished();
    }
  }

  onDemandInterviewResetListener = (message) => {
    if (message.data.hasOwnProperty('type') && message.data.type === 'onDemandInterviewReset') {
      clearInterviewCodes();
      clearStreamManagerConfig();

      if (this.props.step === STEP_ABOUT) {
        this.props.setStep(null);
      } else {
        window.location.reload();
      }
    }
  }

  componentDidMount() {
    window.addEventListener('message', this.onDemandInterviewResetListener.bind(this))
  }

  componentWillUnmount() {
    this.props.reset();

    window.removeEventListener('message', this.onDemandInterviewResetListener.bind(this))
  }

  onInterviewFinished() {
    const {interview} = this.props;
    if (window.parent) {
      window.parent.postMessage({
        type: 'onDemandInterviewCompleted',
        interviewId: interview.id
      }, '*');
    }
  }

  renderError = (error) => {
    return (
      <ErrorPage errorTitle={error.title} errorSubTitle={error.subTitle}
                 errorDescription={error.description}/>
    );
  };

  isUserLoggedIn = () => {
    return isAuthenticated() || this.props.loggedIn;
  }

  getTrackData = () => {
    const {interview, loggedIn} = this.props;
    const trackData = {};

    if (interview) {
      trackData['interview_id'] = interview['id'];
      trackData['interview_code'] = interview['interviewCode'];
    }

    if (loggedIn) {
      trackData['user_id'] = getUserId();
      trackData['user_email'] = storage.get('originalUserEmail');
      trackData['user_original_id'] = storage.get('originalUserId');
    }

    return trackData;
  }

  render() {
    const {loading, error, request, step, screenSize, includeLoginButton} = this.props;
    const smallScreen = !screenSize.md;
    const isWholeScreenStep = [STEP_TEST, STEP_QUESTIONS].includes(step);
    const pageAttributes = {
      className: classNames('on-demand-interview-page', 'interview-page', {
        'on-demand-interview-page-whole-screen': smallScreen && isWholeScreenStep,
      }),
      style: {
        backgroundImage: "url(" + login + ")",
        zIndex: 1030,
      }
    };

    if (error && error.title) {
      return (
        <div {...pageAttributes}>
          {this.renderError(error)}
        </div>
      )
    }

    if (loading) {
      return (
        <div {...pageAttributes}>
          <div className="alert alert-info on-demand-interview-page-loading" style={{textAlign: 'center'}} role="status">Loading...</div>
        </div>
      )
    }

    const isLoggedIn = this.isUserLoggedIn();
    return (
      <div {...pageAttributes}>
        {request && (
          <>
            <LogRocketTracker uuid={'request-' + IRItoID(request['@id'])} trackData={this.getTrackData()}/>
            <OnDemandInterviewHeader includeLoginButton={includeLoginButton} />
            <OnDemandInterviewStepsController />
            <OnDemandInterviewSteps />
            <OnDemandInterviewContent />
            {!(smallScreen && isWholeScreenStep) && (<OnDemandInterviewFooter />)}
            {isLoggedIn && showSiteSettingsBox() && (
              <SiteSettingsBox />
            )}
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  error: state.onDemandInterviewPage.error,
  loading: state.onDemandInterviewPage.loading,
  request: state.onDemandInterviewPage.request,
  interview: state.onDemandInterviewPage.interview,
  screenSize: state.screen.size,
  step: state.onDemandInterviewPage.step,
  loggedIn: state.onDemandInterviewPage.auth.loggedIn,
});

const mapDispatchToProps = dispatch => ({
  reset: () => dispatch(reset()),
  setStep: (step) => dispatch(setStep(step)),
});

OnDemandInterviewPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(OnDemandInterviewPage);

export default OnDemandInterviewPage;
