import { SubmissionError } from 'redux-form';
import {
  fetch,
  extractHubURL,
  mercureSubscribe as subscribe, normalizeAttributes
} from '../../utils/dataAccess';
import { success as createSuccess } from './create';
import { loading, error } from './delete';
import {setEndOfDay, setQuestionAndChoicePositions, sort} from "./util";
import {saveAsTemplate} from "./saveAsTemplate";
import {success as deleteSuccess} from "./delete";
import { reset as resetJobsForSelect } from "./../Job/forSelect";
import { cloneDeep } from "lodash";

export function retrieveError(retrieveError) {
  return { type: 'REQUEST_UPDATE_RETRIEVE_ERROR', retrieveError };
}

export function retrieveLoading(retrieveLoading) {
  return { type: 'REQUEST_UPDATE_RETRIEVE_LOADING', retrieveLoading };
}

export function retrieveSuccess(retrieved) {
  return { type: 'REQUEST_UPDATE_RETRIEVE_SUCCESS', retrieved };
}

export function retrieve(id) {
  return dispatch => {
    dispatch(retrieveLoading(true));

    return fetch(id)
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        sort(retrieved);
        normalizeAttributes(retrieved, ['job']);
        dispatch(retrieveLoading(false));
        dispatch(retrieveSuccess(retrieved));

        if (hubURL) dispatch(mercureSubscribe(hubURL, retrieved['@id']));
      })
      .catch(e => {
        dispatch(retrieveLoading(false));
        dispatch(retrieveError(e.message));
      });
  };
}

export function updateError(updateError) {
  return { type: 'REQUEST_UPDATE_UPDATE_ERROR', updateError };
}

export function updateLoading(updateLoading) {
  return { type: 'REQUEST_UPDATE_UPDATE_LOADING', updateLoading };
}

export function updateSuccess(updated) {
  return { type: 'REQUEST_UPDATE_UPDATE_SUCCESS', updated };
}

export function update(item, values) {
  if (values.submitType && values.submitType === 'saveAsTemplate') {
    return dispatch => {
      dispatch(saveAsTemplate(values))
    }
  }

  let data = normalizeAttributes(cloneDeep(values), ['requestIntroVideo', 'requestOutroVideo']);
  if (data.questions && data.questions.length > 0) {
    data.questions = data.questions.map(question => normalizeAttributes(question, ['questionVideo']));
  }
  data['expiresAt'] = setEndOfDay(data['expiresAt']);

  return dispatch => {
    dispatch(updateError(null));
    dispatch(createSuccess(null));
    dispatch(updateLoading(true));

    setQuestionAndChoicePositions(data);
    return fetch(item['@id'], {
      method: 'PUT',
      data: data,
    })
      .then(response =>
        ({ retrieved: sort(response.data), hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {

        dispatch(updateLoading(false));
        dispatch(updateSuccess(retrieved));

        if (hubURL) dispatch(mercureSubscribe(hubURL, retrieved['@id']));
      })
      .catch(e => {
        dispatch(updateLoading(false));

        if (e instanceof SubmissionError) {
          dispatch(updateError(e.errors._error));
          throw e;
        }

        dispatch(updateError(e.message));
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'REQUEST_UPDATE_RESET' });
    dispatch(error(null));
    dispatch(loading(false));
    dispatch(createSuccess(null));
    dispatch(deleteSuccess(null));
    dispatch(resetJobsForSelect());
  };
}

export function mercureSubscribe(hubURL, topic) {
  return dispatch => {
    const eventSource = subscribe(hubURL, [topic]);
    dispatch(mercureOpen(eventSource));
    eventSource.addEventListener('message', event =>
      dispatch(mercureMessage(JSON.parse(event.data)))
    );
  };
}

export function mercureOpen(eventSource) {
  return { type: 'REQUEST_UPDATE_MERCURE_OPEN', eventSource };
}

export function mercureMessage(retrieved) {
  return dispatch => {
    if (1 === Object.keys(retrieved).length) {
      dispatch({ type: 'REQUEST_UPDATE_MERCURE_DELETED', retrieved });
      return;
    }

    dispatch({ type: 'REQUEST_UPDATE_MERCURE_MESSAGE', retrieved });
  };
}
