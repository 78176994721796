import React, {Component} from 'react';
import {connect} from "react-redux";
import CardBody from "../Card/CardBody";
import VideoQuestionAnswerVideoRecorder from "./VideoQuestionAnswerVideoRecorder";
import VideoQuestionAnswerTextarea from "./VideoQuestionAnswerTextarea";
import Card from "../Card/Card";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button";
import VideoQuestionAnswerAudioRecorder from "./VideoQuestionAnswerAudioRecorder";
import TimeElapsedDisplay from "../TimeElapsedDisplay/TimeElapsedDisplay";
import {
  getLastAudioAnswerForVideoQuestion, getLastSelectedAnswerTypeForVideoQuestion,
  getLastVideoAnswerForVideoQuestion, getRecordAttemptsForVideoQuestion,
  thinkTimeIsEnabledForQuestion
} from "../../utils/onDemandInterview";
import {answerError} from "../../actions/OnDemandInterviewPage/answer";

const ANSWER_TYPE_VIDEO = 'video';
const ANSWER_TYPE_AUDIO = 'audio';
const ANSWER_TYPE_TEXT = 'text';

class VideoQuestionAnswer extends Component {
  timeoutId;

  static propTypes = {
    videoQuestion: PropTypes.object.isRequired,
    onAnswer: PropTypes.func.isRequired,
    onAnswerTypeChange: PropTypes.func.isRequired,
    answerType: PropTypes.string,
    allowToChangeDevice: PropTypes.bool,
    startRecordingDisabled: PropTypes.bool,
    videoEndedAt: PropTypes.object,
  };

  state = {
    thinkTimeCountedDown: false,
    thinkTimeFiveSecondsLeft: false,
    recordingStartedAt: null,
  };

  componentDidMount() {
    this.setDefaultAnswerType();
    clearInterval(this.timeoutId);
  }

  setDefaultAnswerType = () => {
    const availableTypes = this.getAvailableAnswerTypes();
    if (availableTypes.length > 1) {
      const videoQuestion = this.props.videoQuestion;
      if (videoQuestion.maxAnswerRecordingRetries && this.allRecordingAttemptsAreUsed() && getLastVideoAnswerForVideoQuestion(videoQuestion)) {
        this.props.onAnswerTypeChange(ANSWER_TYPE_VIDEO);
      } else if (videoQuestion.maxAnswerRecordingRetries && this.allRecordingAttemptsAreUsed() && getLastAudioAnswerForVideoQuestion(videoQuestion)) {
        this.props.onAnswerTypeChange(ANSWER_TYPE_AUDIO);
      } else {
        //Need to select manually
        this.props.onAnswerTypeChange(null);
      }
    } else if (availableTypes.length === 1) {
      this.props.onAnswerTypeChange(availableTypes[0]);
    }
  }

  getAvailableAnswerTypes = () => {
    const {videoQuestion} = this.props;
    const allAttemptsAreUsed = videoQuestion.maxAnswerRecordingRetries && this.allRecordingAttemptsAreUsed();
    let availableAnswerTypes = [];
    if (videoQuestion.allowVideoAnswer && (!allAttemptsAreUsed || getLastVideoAnswerForVideoQuestion(videoQuestion))) {
      availableAnswerTypes.push(ANSWER_TYPE_VIDEO);
    }
    if (videoQuestion.allowAudioAnswer && (!allAttemptsAreUsed || getLastAudioAnswerForVideoQuestion(videoQuestion))) {
      availableAnswerTypes.push(ANSWER_TYPE_AUDIO);
    }
    if (videoQuestion.allowTextAnswer) {
      availableAnswerTypes.push(ANSWER_TYPE_TEXT);
    }

    return availableAnswerTypes
  }

  changeAnswerType = (answerType) => {
    this.props.onAnswerTypeChange(answerType);
    this.props.resetAnswerError();
    this.props.onAnswer(null);
  }

  thinkTimeCountedDown = () => {
    if (!this.state.thinkTimeCountedDown) {
      this.setState({thinkTimeCountedDown: true});
    }
  }

  onFiveSecondsLeftCountdown = (time, endTime) => {
    if (!this.state.thinkTimeFiveSecondsLeft) {
      if (endTime && (endTime.getTime() - time) < 6000) {
        this.setState({thinkTimeFiveSecondsLeft: true});

        if (!this.props.answerType) {
          this.props.onAnswerTypeChange(getLastSelectedAnswerTypeForVideoQuestion(this.props.videoQuestion));
        }
      }
    }
  }

  getTimeElapseComponent = () => {
    const videoQuestion = this.props.videoQuestion;
    if (thinkTimeIsEnabledForQuestion(videoQuestion) && this.props.videoEndedAt && !this.state.thinkTimeCountedDown && !this.props.answer) {
      const endedAt = new Date(this.props.videoEndedAt.getTime());
      const additionalSeconds = (videoQuestion.thinkTimeLimitMinutes * 60) + videoQuestion.thinkTimeLimitSeconds;
      const seconds = endedAt.getSeconds() + additionalSeconds;
      endedAt.setSeconds(seconds);

      return (
        <TimeElapsedDisplay
          startTime={endedAt}
          running={true}
          containerClass={''}
          withHours={false}
          onTick={this.onFiveSecondsLeftCountdown}
          onCountedDown={this.thinkTimeCountedDown}
          isNegative={true}
          indicator={null}
          recordingText={null}
        />);
    }
  }

  onRecordingStarted = (recordingStartedAt) => {
    this.setState({recordingStartedAt: recordingStartedAt});
  }

  onAnswer = (answer) => {
    this.setState({recordingStartedAt: null});
    this.props.resetAnswerError();
    this.props.onAnswer(answer);
  }

  allowToChangeAnswerTypeIfUsedAllAllowedAttempts = () => {
    let {answerType, videoQuestion} = this.props;
    let availableTypes = this.getAvailableAnswerTypes();

    if (videoQuestion.maxAnswerRecordingRetries &&
      this.allRecordingAttemptsAreUsed() &&
      !availableTypes.includes(ANSWER_TYPE_TEXT)
    ) {
      const lastVideoAnswer = getLastVideoAnswerForVideoQuestion(videoQuestion);
      const lastAudioAnswer = getLastAudioAnswerForVideoQuestion(videoQuestion);

      if (answerType === ANSWER_TYPE_TEXT) {
        return lastVideoAnswer !== null || lastAudioAnswer !== null;
      }
      if (answerType === ANSWER_TYPE_AUDIO) {
        return lastVideoAnswer !== null;
      }
      if (answerType === ANSWER_TYPE_VIDEO) {
        return lastAudioAnswer !== null;
      }
    }

    return true;
  }

  allRecordingAttemptsAreUsed = () => {
    return getRecordAttemptsForVideoQuestion(this.props.videoQuestion) >= this.props.videoQuestion.maxAnswerRecordingRetries;
  }

  render() {
    const {answerType} = this.props;
    const {videoQuestion, answerError, audioDevices, screenSize} = this.props;
    const answerTypes = this.getAvailableAnswerTypes();
    const smallScreen = !screenSize.md;
    const denyToChangeAnswerType =
      this.state.recordingStartedAt ||
      this.state.thinkTimeFiveSecondsLeft ||
      (thinkTimeIsEnabledForQuestion(videoQuestion) && this.props.answer) ||
      !this.allowToChangeAnswerTypeIfUsedAllAllowedAttempts();

    return (
      <Card className={'on-demand-interview-page-video-question-card'}>
        <CardBody className="card-body video-answer-card-body">
          <div className="video-card-header">
            <h4>Your Answer</h4>
            {answerType !== null && answerTypes.length > 1 && (
              <Button disabled={!!denyToChangeAnswerType} className={"select-another-answer-type-header-button"} onClick={() => this.changeAnswerType(null)}>
                Select Another Answer Type
              </Button>
            )}
            {answerError && (
              <div className="answer-error">{answerError}</div>
            )}
          </div>
          {answerType === ANSWER_TYPE_VIDEO && (
            <VideoQuestionAnswerVideoRecorder
              question={videoQuestion}
              onAnswer={this.onAnswer}
              cancelAnswer={smallScreen && answerTypes.length > 1 ? () => this.changeAnswerType(null): undefined}
              allowToChangeDevice={this.props.allowToChangeDevice}
              startRecordingDisabled={this.props.startRecordingDisabled}
              thinkTimeLeft={this.getTimeElapseComponent()}
              thinkTimeCountedDown={this.state.thinkTimeCountedDown}
              thinkTimeFiveSecondsLeft={this.state.thinkTimeFiveSecondsLeft}
              onRecordingStarted={this.onRecordingStarted}
            />
          )}
          {answerType === ANSWER_TYPE_AUDIO && (
            <VideoQuestionAnswerAudioRecorder
              question={videoQuestion}
              onAnswer={this.onAnswer}
              audioDevices={audioDevices}
              cancelAnswer={smallScreen && answerTypes.length > 1 ? () => this.changeAnswerType(null): undefined}
              allowToChangeDevice={this.props.allowToChangeDevice}
              startRecordingDisabled={this.props.startRecordingDisabled}
              thinkTimeLeft={this.getTimeElapseComponent()}
              thinkTimeCountedDown={this.state.thinkTimeCountedDown}
              thinkTimeFiveSecondsLeft={this.state.thinkTimeFiveSecondsLeft}
              onRecordingStarted={this.onRecordingStarted}
            />
          )}
          {answerType === ANSWER_TYPE_TEXT && (
            <VideoQuestionAnswerTextarea
              question={videoQuestion}
              onAnswer={this.props.onAnswer}
              cancelAnswer={smallScreen && answerTypes.length > 1 ? () => this.changeAnswerType(null): undefined}
            />
          )}
          {answerType === null && (
            <div className={'answer-type-block'}>
              <div className='answer-type-select-box' key="answer-type-select-box">
                <p className={'answer-type-hint-text'}>How would you like to respond to this question?</p>
                <div className={'answer-type-select-options'}>
                  {answerTypes.includes(ANSWER_TYPE_VIDEO) && (
                  <div className='answer-type-select-option' onClick={() => this.changeAnswerType(ANSWER_TYPE_VIDEO)}>
                    <i className="fa fa-video-camera" /> Video
                  </div>
                  )}
                  {answerTypes.includes(ANSWER_TYPE_AUDIO) && (
                  <div className='answer-type-select-option' onClick={() => this.changeAnswerType(ANSWER_TYPE_AUDIO)}>
                    <i className="fa fa-microphone" /> Audio
                  </div>
                  )}
                  {answerTypes.includes(ANSWER_TYPE_TEXT) && (
                  <div className='answer-type-select-option' onClick={() => this.changeAnswerType(ANSWER_TYPE_TEXT)}>
                    <i className="fa fa-file-text" /> Text
                  </div>
                  )}
                </div>
              </div>
              <div className='answer-type-controls-area' />
            </div>
          )}
        </CardBody>
      </Card>
    )
  }
}

const mapStateToProps = state => ({
  screenSize: state.screen.size,
  answerError: state.onDemandInterviewPage.answerError,
});

const mapDispatchToProps = dispatch => ({
  resetAnswerError: () => dispatch(answerError(null)),
});

VideoQuestionAnswer = connect(
  mapStateToProps, mapDispatchToProps
)(VideoQuestionAnswer);

export default VideoQuestionAnswer;
