export function jobLocation(job) {
    let location = '';

    if (job.city) {
        location += `${job.city}, `;
    }
    if (job.state) {
        location += `${job.state.name}, `;
    }
    if (job.country) {
      location += job.country.name;
    }

    return location;
}
