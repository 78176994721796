import React, {Component} from "react";
import {connect} from "react-redux";
import LoginForm from "./LoginForm";
import PropTypes from "prop-types";
import {logIn} from "../../actions/OnDemandInterviewPage/auth";
import Dialog from "../../views/Components/Dialog/Dialog";

class LoginDialog extends Component {
  static propTypes = {
    handleCloseDialog: PropTypes.func.isRequired,
    enteredEmail: PropTypes.string,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.loggedIn && this.props.loggedIn) {
      this.props.handleCloseDialog();
    }
  }

  render() {
    const {error} = this.props;
    return (
      <Dialog
        open={this}
        className={'on-demand-interview-page-login-dialog'}
        title={"Account Found"}
        onClose={this.props.handleCloseDialog}
        closeButtonText={null}
      >
        {error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" /> {error}
          </div>
        )}
        <p className={"on-demand-interview-page-login-dialog-text"}>We found an account for you.</p>
        <LoginForm
          onSubmit={({email, password}) => this.props.login(email,password)}
          initialValues={{email: this.props.enteredEmail}}
          loggingIn={this.props.loggingIn}
        />
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.onDemandInterviewPage.auth.loggedIn,
  loggingIn: state.onDemandInterviewPage.auth.loggingIn,
  error: state.onDemandInterviewPage.auth.error,
});

const mapDispatchToProps = dispatch => ({
  login: (email, password) => dispatch(logIn(email, password)),
})

LoginDialog = connect(
  mapStateToProps, mapDispatchToProps
)(LoginDialog);

export default LoginDialog;
