import {VIDEO_ENTRYPOINT} from "../../config/entrypoint";
import React, {Component} from "react";
import CombinedVideoRecorder from "./CombinedVideoRecorder";
import {getNewOpenVidu} from "../../utils/OpenViduInstance";
import {closeStreamManager, createStreamManager, DEFAULT_RESOLUTION} from "../../utils/streamManager";
import PropTypes from 'prop-types';

class VideoRecorder extends Component {
    static propTypes = {
        endpoint: PropTypes.string.isRequired,
        socketRecordingType: PropTypes.string,
        resolution: PropTypes.string,
        onRecorded: PropTypes.func,
    }

    static defaultProps = {
        resolution: DEFAULT_RESOLUTION,
    };

    constructor(props) {
        super(props);
        this.state = {
            streamManager: null,
        }
    }

    componentDidMount() {
        this.initStreamManager();
    }

    componentWillUnmount() {
        closeStreamManager(this.state.streamManager);
    }

    initStreamManager = () => {
        createStreamManager(getNewOpenVidu(), {
            resolution: this.props.resolution,
        })
        .then((streamManager) => this.setState({streamManager: streamManager}));
    }

    render() {
        const {endpoint, onRecorded, socketRecordingType} = this.props;
        let props = {
            streamManager: this.state.streamManager,
            onRecorded: onRecorded,
            openviduRecorderConfig: {
                getSessionUrl: `${VIDEO_ENTRYPOINT}/${endpoint}/get-session`,
                startRecordingUrl: `${VIDEO_ENTRYPOINT}/${endpoint}/start-recording`,
                stopRecordingUrl: `${VIDEO_ENTRYPOINT}/${endpoint}/stop-recording`,
            }
        };

        if (socketRecordingType) {
            props.allowLocalRecording = true;
            props.browserRecorderConfig = {
                socketRecordingType: socketRecordingType,
            };
        }

        return (
            <CombinedVideoRecorder {...props}/>
        );
    }
}

export default VideoRecorder;
