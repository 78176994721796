import React, {Component} from "react";
import './VideoQuestionView.scss';
import EntityVideoPlayerWithControl from "../../views/Components/EntityVideoPlayerWithControl";

export default class VideoQuestionView extends Component {
    render() {
        let videoQuestion = this.props.videoQuestion;
        let video = videoQuestion.questionBankVideo || videoQuestion.questionVideo;

        return (
            <div className="video-question-view">
                {this.props.beforeTitle}
                <p>{this.props.videoQuestion.title}{this.props.videoQuestion.required && (<span className="text-danger"> *</span>)}</p>
                {video ? (
                    <EntityVideoPlayerWithControl
                        entity={video}
                        progressIndicatorClassName={'video-question-view-player-indicator'}
                    />
                ) : (
                    <div className="placeholder" style={{
                        backgroundColor: 'black',
                        color: 'white',
                        minHeight: '250px',
                        display: 'flex',
                        borderRadius: '10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        Video is missing for this question
                    </div>
                )}
            </div>
        )
    }
}
