import React, {Component} from "react";
import PropTypes from "prop-types";
import {DialogActions as MaterialDialogActions} from "@material-ui/core";
import DialogConfirmButton from "./DialogConfirmButton";
import DialogCancelButton from "./DialogCancelButton";
import "./DialogActions.scss";

class DialogActions extends Component {
  static propTypes = {
    confirmButtonText: PropTypes.string,
    confirmButtonDisabled: PropTypes.bool,
    onConfirm: PropTypes.func,
    closeButtonText: PropTypes.string,
    onClose: PropTypes.func,
    id: PropTypes.string,
  };

  render() {
    const {
      id, children, confirmButtonText, onConfirm, closeButtonText, onClose, confirmButtonDisabled
    } = this.props;

    const showConfirmButton = confirmButtonText && onConfirm;
    const showCancelButton = closeButtonText && onClose;
    const showDialogActions = showConfirmButton || showCancelButton || children;

    return (
      showDialogActions ? (
        <MaterialDialogActions id={id} className={'io-dialog-actions io-dialog-actions-center'}>
          {children ? (
            children
          ) : (
            <>
              {showConfirmButton && (
                <DialogConfirmButton onClick={onConfirm} text={confirmButtonText} disabled={confirmButtonDisabled} />
              )}
              {showCancelButton && (
                <DialogCancelButton text={closeButtonText} onClick={onClose} />
              )}
            </>
          )}
        </MaterialDialogActions>
      ) : null
    )
  }
}

export default DialogActions;
