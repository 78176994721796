import React, {Component} from "react";
import {renderTextField} from "../../utils/form";
import {Field} from "redux-form";
import {isValidYoutubeUrl} from "../../utils/embedVideo";
import YoutubeVideo from "./YoutubeVideo";

class YoutubeVideoField extends Component {
  render() {
    const {input, ...props} = this.props;
    const url = input.value;
    return (
      <div>
        <Field
          {...input}
          {...props}
          onBlur={() => {}}
          component={renderTextField}
        />
        {url && isValidYoutubeUrl(url) && (
          <YoutubeVideo url={url} />
        )}
      </div>
    );
  }
}

export default YoutubeVideoField;
