const AVERAGE_RATING = 'Average Rating';
const CRITERIA_1 = 'Overall Knowledge/Skills'
const CRITERIA_2 = 'Technical Knowledge';
const CRITERIA_3 = 'Experience and/or Accomplishment';
const CRITERIA_4 = 'Flexibility & Adaptability';
const CRITERIA_5 = 'Interpersonal Skills';

const DEFAULT_NAMES = {
  1: CRITERIA_1,
  2: CRITERIA_2,
  3: CRITERIA_3,
  4: CRITERIA_4,
  5: CRITERIA_5,
}

export {
  AVERAGE_RATING,
  DEFAULT_NAMES
};
