import React, {Component} from "react";
import {Link} from "react-router-dom";
import {grantAccess, retrieveAccessList, reset, deleteAccess} from "../../actions/OneWayInterview/access";
import {retrieve as  retrieveOneWayInterview, reset as resetOneWayInterview} from '../../actions/OneWayInterview/show';
import {connect} from "react-redux";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import ShareInterviewDialog from "../Access/ShareInterviewDialog";
import withStyles from "@material-ui/core/styles/withStyles";
import FlashMessages from "../../views/Components/FlashMessages";

const style = {
  disabledAction: {
    cursor: 'default',
  }
};

class AccessList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessForEdit: null,
    };
  }

  componentDidMount() {
    this.updateList();
    this.props.retrieveOneWayInterview(decodeURIComponent(this.props.match.params.interview));
  }

  componentWillUnmount() {
    this.props.reset();
    this.props.resetOneWayInterview();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.deleted && !prevProps.deleted) {
      this.updateList();
    }
  }

  updateList = () => {
    this.props.retrieve(decodeURIComponent(this.props.match.params.interview));
  };

  editPermissions = (access) => {
    this.setState({accessForEdit: {
      user: access.user['@id'],
      permissions: access.permissions,
    }});
  };

  render() {
    const { classes, listLoading, deleteAccess } = this.props;
    const interviewId = decodeURIComponent(this.props.match.params.interview);
    const showCopyPermission = false;
    return (
      <div>
        <h1>Show "Shared Users"</h1>
        {(listLoading)&& (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
            {this.props.error}
          </div>
        )}
        {this.props.granted && (
          <FlashMessages message={"Interview successfully shared."} type={"success"} duration={10000} />
        )}
        {this.props.shareLinkSaved && (
          <FlashMessages message={"Share link successfully saved."} type={"success"} duration={10000} />
        )}
        <p>
          <Link to={`../../on-demand-interviews/show/${this.props.match.params.interview}`} className="btn btn-primary float-left mr-3">
            Back to Interview
          </Link>
          <button className="btn btn-primary" onClick={() => {
            this.setState({accessForEdit: {}});
          }}>
            Share
          </button>
        </p>
        <div style={{'overflowX': 'auto'}}>
          <Table className="table table-responsive table-striped table-hover">
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>View Interview, Candidate & Job</TableCell>
                <TableCell>View Reviews & Comments</TableCell>
                <TableCell>Add Reviews & Comments</TableCell>
                {showCopyPermission && (<TableCell>Copy (without reviews & comments)</TableCell>)}
                <TableCell colSpan={2} />
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.retrieved && (
                this.props.retrieved.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell scope="row">
                      {item.user.firstName} {item.user.lastName} ({item.user.email})
                    </TableCell>
                    <TableCell scope="row">
                      {item.user.mainRole}
                    </TableCell>
                    <TableCell scope="row">
                      {item.permissions.view_interview && item.permissions.view_candidate && item.permissions.view_job ? 'Yes' : 'No'}
                    </TableCell>
                    <TableCell scope="row">
                      {item.permissions.view_reviews_and_comments ? 'Yes' : 'No'}
                    </TableCell>
                    <TableCell scope="row">
                      {item.permissions.create_reviews_and_comments ? 'Yes' : 'No'}
                    </TableCell>
                    {showCopyPermission && (<TableCell scope="row">
                      {item.user['@type'] === 'CompanyHiringManager' ? 'N/A' : (item.permissions.copy_interview ? 'Yes' : 'No')}
                    </TableCell>)}
                    <TableCell scope="row">
                      <a href='#'
                        onClick={(event) => {
                          event.preventDefault();
                          if (this.props.listLoading) {
                              return;
                          }
                          this.editPermissions(item)
                        }}
                        className={listLoading ? classes.disabledAction : ''}
                      >
                        <span className={'fa fa-pencil ' + (listLoading ? 'text-muted' : '')} aria-hidden="true" />
                        <span className="sr-only">Edit</span>
                      </a>
                    </TableCell>
                    <TableCell scope="row">
                      <a href='#'
                        onClick={(event) => {
                          event.preventDefault();
                          if (this.props.listLoading) {
                            return;
                          }
                          if (window.confirm('Are you sure you want to delete this item?')) {
                            deleteAccess(interviewId, item.user['@id'])
                          }
                        }}
                        className={listLoading ? classes.disabledAction : ''}
                      >
                        <span className={'fa fa-trash ' + (!listLoading ? 'text-danger' : 'text-muted')} aria-hidden="true" />
                        <span className="sr-only">Delete</span>
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </div>
        {this.props.oneWayInterview && (
          <ShareInterviewDialog
            handleSubmit={(values) => this.props.grantAccess(interviewId, values)}
            loading={this.props.grantAccessLoading}
            error={this.props.grantAccessError}
            open={!!this.state.accessForEdit}
            values={this.state.accessForEdit}
            success={this.props.granted}
            onSuccess={() => {
              this.setState({accessForEdit: null});
              this.updateList();
            }}
            onClose={() => this.setState({accessForEdit: null})}
            oneWayInterview={this.props.oneWayInterview}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  retrieved: state.onewayinterview.access.accessList,
  oneWayInterview: state.onewayinterview.show.retrieved,
  error: state.onewayinterview.access.retrieveListError,
  granted: state.onewayinterview.access.granted,
  grantAccessLoading: state.onewayinterview.access.grantAccessLoading,
  listLoading: state.onewayinterview.access.retrieveListLoading || state.onewayinterview.access.deleteAccessLoading,
  deleted: state.onewayinterview.access.deleted,
  shareLinkSaved: state.onewayinterviewsharelink.update.updated,
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieveAccessList(id)),
  retrieveOneWayInterview: id => dispatch(retrieveOneWayInterview(id)),
  grantAccess: (id, values) => dispatch(grantAccess(id, values)),
  deleteAccess: (interviewId, userId) => dispatch(deleteAccess(interviewId, userId)),
  reset: () => dispatch(reset()),
  resetOneWayInterview: () => dispatch(resetOneWayInterview()),
});

AccessList = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccessList);

AccessList = withStyles(style)(AccessList);

export default AccessList;
