import React, {Component} from "react";
import {getYoutubeEmbedUrl} from "../../utils/embedVideo";
import PropTypes from "prop-types";
import "./YoutubeVideo.scss"

class YoutubeVideo extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
  };

  render() {
    const {url} = this.props;
    return (
      <iframe
        className={"youtube-video-iframe"}
        src={getYoutubeEmbedUrl(url)}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    );
  }
}

export default YoutubeVideo;
