import {
  fetch,
  extractHubURL,
} from '../../utils/dataAccess';
import {filterAction, sortAction} from "../../utils/filterAndSortUtils";

export function error(error) {
  return { type: 'SITE_SETTINGS_LIST_ERROR', error };
}

export function loading(loading) {
  return { type: 'SITE_SETTINGS_LIST_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'SITE_SETTINGS_LIST_SUCCESS', retrieved };
}

export function list(page = 'site_settings') {
  return dispatch => {
    dispatch(loading(true));
    dispatch(error(''));

    fetch(page)
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {

        dispatch(loading(false));
        dispatch(success(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    dispatch({type: 'SITE_SETTINGS_LIST_RESET'});
    dispatch({type: 'SITE_SETTINGS_CREATE_RESET'});
    dispatch({type: 'SITE_SETTINGS_UPDATE_RESET'});
  };
}

export function filter(filtersData) {
  return filterAction( 'SITE_SETTINGS_LIST_FILTER', 'site_settings', filtersData);
}

export function sort(sortData) {
  return sortAction('SITE_SETTINGS_LIST_SORT','site_settings', sortData);
}


