import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import {renderCheckbox} from "../../utils/form";
import SearchableSelect from "../../views/Components/SearchableSelect";

class InvitationInterviewStatusForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  static displayedInterviewStatus = [
    {text: 'Pending', value: 'pending'},
    {text: 'Opened',  value: 'opened'},
    {text: 'Accepted', value: 'accepted'},
    {text: 'Declined', value: 'declined'},
    {text: 'Expired', value: 'expired'},
    {text: 'Incomplete', value: 'incomplete'},
    {text: 'Complete', value: 'complete'},
  ];
  static displayedInterviewProgressbarStatus = [
    {text: 'Accepted', value: 'accepted'},
    {text: 'Accepted > Archived', value: 'accepted_archived'},
    {text: 'Accepted > Expired', value: 'accepted_expired'},
    {text: 'Complete', value: 'complete'},
    {text: 'Declined', value: 'declined'},
    {text: 'In Progress', value: 'in_progress'},
    {text: 'In Progress > Archived', value: 'in_progress_archived'},
    {text: 'in progress > Expired', value: 'in_progress_expired'},
    {text: 'Opened', value: 'opened'},
    {text: 'Opened > Archived', value: 'opened_archived'},
    {text: 'Opened > Expired', value: 'opened_expired'},
    {text: 'Pending', value: 'pending'},
    {text: 'Pending > Archived', value: 'pending_archived'},
    {text: 'Pending > Expired', value: 'pending_expired'},
  ];
  static jobStatuses = [
    {text: 'Active', value: 'active'},
    {text: 'Archived', value: 'archived'},
  ];
  static requestStatuses = [
    {text: 'Active', value: 'active'},
    {text: 'Archived', value: 'archived'},
    {text: 'Expired', value: 'expired'},
  ];
  static invitationStatuses = [
    {text: 'Pending', value: 'pending'},
    {text: 'Opened',  value: 'opened'},
    {text: 'Accepted', value: 'accepted'},
    {text: 'Declined', value: 'declined'},
    {text: 'Expired', value: 'expired'},
    {text: 'Incomplete', value: 'incomplete'},
    {text: 'Complete', value: 'complete'},
  ];

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={SearchableSelect}
          name="displayedInterviewStatus"
          placeholder="Displayed Interview Status"
          options={InvitationInterviewStatusForm.displayedInterviewStatus}
          required={true}
        />
        <Field
          component={SearchableSelect}
          name="displayedInterviewProgressbarStatus"
          placeholder="Displayed Interview Progressbar Status"
          options={InvitationInterviewStatusForm.displayedInterviewProgressbarStatus}
          required={true}
        />
        <Field
          component={SearchableSelect}
          name="jobStatus"
          placeholder="Job Status"
          options={InvitationInterviewStatusForm.jobStatuses}
          required={true}
        />
        <Field
          component={SearchableSelect}
          name="requestStatus"
          placeholder="Request Status"
          options={InvitationInterviewStatusForm.requestStatuses}
          required={true}
        />
        <Field
          component={SearchableSelect}
          name="invitationStatus"
          placeholder="Invitation Status"
          options={InvitationInterviewStatusForm.invitationStatuses}
          required={true}
        />
        <Field
          component={renderCheckbox}
          name="invitationExpired"
          type="text"
          label="Invitation Expired"
        />
        <Field
          component={renderCheckbox}
          name="invitationArchived"
          type="text"
          label="Invitation Archived"
        />
        <Field
          component={renderCheckbox}
          name="interviewStarted"
          type="text"
          label="Interview Started"
        />
        <Field
          component={renderCheckbox}
          name="interviewCompleted"
          type="text"
          label="Interview Completed"
        />
        <button type="submit" className="btn btn-success float-left mr-3">
          Submit
        </button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'invitation_interview_status',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(InvitationInterviewStatusForm);
