import { combineReducers } from 'redux';

export function error(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_SHOW_ERROR':
      return action.error;

    case 'LIVEINTERVIEW_SHOW_MERCURE_DELETED':
      return `${action.retrieved['@id']} has been deleted by another user.`;

    case 'LIVEINTERVIEW_SHOW_RESET':
      return null;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_SHOW_LOADING':
      return action.loading;

    case 'LIVEINTERVIEW_SHOW_RESET':
      return false;

    default:
      return state;
  }
}

export function retrieved(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_SHOW_SUCCESS':
    case 'LIVEINTERVIEW_SHOW_MERCURE_MESSAGE':
      if (Array.isArray(action.retrieved.liveInterviewChatMessages)) {
        action.retrieved.messages = [];
        action.retrieved.liveInterviewChatMessages.map((message) => {
          let formattedMessage = {
            body: {
              id: message.id,
              time: message.createdAt,
              firstName: message.user ? message.user.firstName : 'Deleted',
              lastName: message.user ? message.user.lastName : '',
              message: message.content,
            },
            type: message.type === 'message' ? 'chat_message' : message.type,
            own: message.interviewOwner || message.user && message.user['@type'] !== 'Candidate',
          };
          action.retrieved.messages.push(formattedMessage);
        });
      }
      return action.retrieved;

    case 'LIVEINTERVIEW_SHOW_RESET':
      return null;

    default:
      return state;
  }
}

export function eventSource(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_SHOW_MERCURE_OPEN':
      return action.eventSource;

    case 'LIVEINTERVIEW_SHOW_RESET':
      return null;

    default:
      return state;
  }
}

export default combineReducers({ error, loading, retrieved, eventSource });
