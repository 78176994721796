import {
  fetch,
} from '../../utils/dataAccess';

export function error(error) {
  return { type: 'INVITATION_DEFAULT_EMAIL_CONTENT_ERROR', error };
}

export function loading(loading) {
  return { type: 'INVITATION_DEFAULT_EMAIL_CONTENT_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'INVITATION_DEFAULT_EMAIL_CONTENT_SUCCESS', retrieved };
}

export function retrieveInvitationEmailDefaultContent(requestId) {
  return dispatch => {
    dispatch(loading(true));

    const url = `/request/${requestId}/invitation-default-email-content`;

    return fetch(url)
      .then(response =>
        ({ retrieved: response.data })
      )
      .then(({ retrieved }) => {
        dispatch(loading(false));
        dispatch(success(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function resetInvitationEmailDefaultContent(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'INVITATION_DEFAULT_EMAIL_CONTENT_RESET' });
    dispatch(error(null));
    dispatch(loading(false));
  };
}


