import React, {Component} from 'react';
import {Field, FieldArray, formValueSelector, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {loadJobsForSelect} from "../../actions/Job/forSelect";
import {connect} from "react-redux";
import {RenderScheduledDateTimePicker, renderTextField} from "../../utils/form";
import moment from "moment-timezone";
import CompanyUserInvitations from "./CompanyUserInvitations";
import CandidateInvitations from "./CandidateInvitations";
import SearchableSelect from "../../views/Components/SearchableSelect";
import {formatTimezoneLabelWithOffset, getTimezones} from "../../utils/timezone";
import InlineRadioButton from "../../views/Components/InlineRadioButton";

class LiveInterviewForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  componentDidMount() {
    const filters = {status: 'active'};
    const initialValues = this.props.initialValues;
    if (initialValues && initialValues.job) {
      filters.withId = initialValues.job;
    }
    this.props.loadJobsForSelect(filters);
  }
  now = moment();
  validateDate = (currentDate) => {
      return currentDate.isSameOrAfter(this.now, 'day');
  };

  changeExpectedDuration(hours, minutes) {
    this.props.change('expectedDurationHours', hours);
    this.props.change('expectedDurationMinutes', minutes);
  };

  getScheduledEndTime() {
    let scheduledEndTime = '';
    if (Number.isInteger(this.props.initialValues.expectedDurationHours) && Number.isInteger(this.props.initialValues.expectedDurationMinutes)) {
      const expectedDurationHours = this.props.initialValues.expectedDurationHours;
      const expectedDurationMinutes = this.props.initialValues.expectedDurationMinutes
      const expectedInMinutes = (expectedDurationHours * 60) + expectedDurationMinutes;

      scheduledEndTime = moment
        .tz(this.props.initialValues.scheduledStartTime, this.props.initialValues.timezone)
        .add(expectedInMinutes, 'minutes');
    }
    return scheduledEndTime;
  };

  render() {
    const {isNew, timezone} = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={renderTextField}
          name="title"
          placeholder="Title"
          required={true}
        />
        <Field
          component={SearchableSelect}
          name="job"
          label="Job"
          placeholder="Job"
          required={true}
          options={this.props.jobs ? this.props.jobs : []}
        />
        <Field
          component={SearchableSelect}
          options={getTimezones().map((timezone) => ({
            text: formatTimezoneLabelWithOffset(timezone.label, timezone.offset),
            value: timezone.name
          }))}
          name="timezone"
          label="Timezone"
          placeholder="Select Timezone"
          required={true}
        />

        {(!this.props.initialValues || timezone) && (
          <Field
            component={RenderScheduledDateTimePicker}
            name="scheduledStartTime"
            label="Scheduled Date and Time"
            required={true}
            isValidDate={this.validateDate}
            timezone={timezone}
            inputProps={{placeholder: 'Scheduled Date and Time'}}
            onChangeDuration={(hours, minutes) => this.changeExpectedDuration(hours, minutes)}
            scheduledEndTime={this.getScheduledEndTime()}
          />
        )}
        <Field
          component={InlineRadioButton}
          name="recordingEnabled"
          label="Record interview"
          required={true}
          options={[
            {label: 'Yes', value: true},
            {label: 'No', value: false},
          ]}
        />
        <FieldArray
          label="Company User Invitations"
          name="companyUserInvitations"
          component={CompanyUserInvitations}
          allowEdit={isNew}
          currentValues={this.props.companyUserInvitations}
        />
        <FieldArray
          label="Candidate Invitations"
          name="candidateInvitations"
          component={CandidateInvitations}
          allowDelete={isNew}
          allowEdit={isNew}
          change={this.props.change}
        />
        <button type="submit" className="btn btn-success float-left mr-3">
          Send
        </button>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.scheduledStartTime) {
    errors.scheduledStartTime = 'Please, select scheduled start date and time and end time';
  } else if (isNaN(Date.parse(values.scheduledStartTime))) {
    errors.scheduledStartTime = 'Please, select correct scheduled start date and time and end time';
  }
  if (values.expectedDurationHours < 0 || values.expectedDurationMinutes < 0) {
    errors.scheduledStartTime = 'Expected end date and time should be greater or equal expected start date and time';
  }
  if (values.companyUserInvitations && values.companyUserInvitations.length > 0) {
    let invitationsErrors = [];
    values.companyUserInvitations.forEach((invitation, index) => {
      let invitationErrors = {};
      if (!invitation.companyUser) {
        invitationErrors.companyUser = 'Please, select company user to invite'
      }
      if (Object.keys(invitationErrors).length > 0) {
        invitationsErrors[index] = invitationErrors;
      }
    });
    if (invitationsErrors.length > 0) {
      errors['companyUserInvitations'] = invitationsErrors;
    }
  }
  if (values.candidateInvitations && values.candidateInvitations.length > 0) {
    let invitationsErrors = [];
    values.candidateInvitations.forEach((invitation, index) => {
      let invitationErrors = {};
      if (invitation.companyCandidate === null) {
        invitationErrors.companyCandidate = 'Please, select candidate to invite'
      } else {
        if (!invitation.email) {
          invitationErrors.email = 'Please, enter candidate email'
        }
        if (!invitation.firstName) {
          invitationErrors.firstName = 'Please, enter candidate first name'
        }
      }
      if (Object.keys(invitationErrors).length > 0) {
        invitationsErrors[index] = invitationErrors;
      }
    });
    if (invitationsErrors.length > 0) {
      errors['candidateInvitations'] = invitationsErrors;
    }
  }
  return errors;
};

LiveInterviewForm = reduxForm({
  form: 'liveinterview',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate
})(LiveInterviewForm);

const selector = formValueSelector('liveinterview');
const mapStateToProps = state => ({
  jobs: state.job.forSelect.retrieved,
  teams: state.team.forSelect.retrieved,
  timezone: selector(state, 'timezone'),
  companyUserInvitations: selector(state, 'companyUserInvitations'),
});

const mapDispatchToProps = dispatch => ({
  loadJobsForSelect: (filters) => dispatch(loadJobsForSelect(filters)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveInterviewForm);
