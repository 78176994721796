import React from "react";
import PropTypes from "prop-types";
import {IconButton, withStyles} from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import {whiteColor} from "../../assets/jss/material-dashboard-pro-react";
import classNames from 'classnames';
import {connect} from "react-redux";

const style = {
  root: {
    marginLeft: '1em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid gray',
    marginBottom: '1em',
  },
  title: {
    fontSize: 'larger',
    fontWeight: '500',
  },
  closeButtonFullscreen: {
    color: whiteColor,
  },
};

class SidebarPanelHeader extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
  };

  render() {
    const {title, onClose, classes, fullscreenEnabled} = this.props;
    return (
      <div className={classes.root}>
        <span className={classes.title}>{title}</span>
        <IconButton
          size="small"
          onClick={onClose}
          classes={{root: classNames({[classes.closeButtonFullscreen]: fullscreenEnabled})}}
        >
          <ClearIcon />
        </IconButton>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  fullscreenEnabled: state.liveInterviewPage.fullscreen,
});

SidebarPanelHeader = connect(
  mapStateToProps,
)(SidebarPanelHeader);

SidebarPanelHeader = withStyles(style)(SidebarPanelHeader);

export default SidebarPanelHeader;
