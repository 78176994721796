import {fetch} from "../../utils/dataAccess";
import {
  EVENTS_SESSION_INIT_FAIL,
  EVENTS_SESSION_INIT_SUCCESS,
  getSessionInstance
} from "../../utils/liveInterviewSession";
import {createVideoPublisher} from "../../utils/liveInterview";
import {liveInterviewInvitation} from "./invitation";
import {OpenViduError} from "openvidu-browser";

export function initializeByRoomCode(roomCode, userRoles) {
  return async dispatch => {
    try {
      dispatch(loading(true));
      const response = await fetch('live_interview/' + roomCode);
      const liveInterview = response.data;
      const participant = liveInterview.participant;
      let invitation = null;
      if (participant && participant.type === 'candidate' || userRoles && userRoles.find(role => role === 'ROLE_CANDIDATE') !== undefined) {
        invitation = liveInterview.candidateInvitations ? liveInterview.candidateInvitations[0] : null;
      }
      await dispatch(initialize(liveInterview, participant, invitation));
    } catch (e) {
      if (e.response && e.response.status === 404) {
        dispatch(error({
          title: 'Not found',
          subTitle: 'Interview with code ' + roomCode + ' not found',
          description: 'Please double check the interview invitation that you received',
        }));
      } else {
        dispatch(error(e));
      }
    }
    dispatch(loading(false));
  }
}

export function initializeByInvitationCode(invitationCode, invitationType) {
  return async dispatch => {
    try {
      dispatch(loading(true));
      const uri = (invitationType === 'company') ? 'live_interview_company_user_invitations' : 'live_interview_candidate_invitations';
      const response = await fetch(uri + '/open/' + invitationCode, { method: 'POST'});
      const invitation = response.data;
      const liveInterview = invitation.liveInterview;
      const participant = liveInterview.participant;
      await dispatch(initialize(liveInterview, participant, invitation));
    } catch (e) {
      if (e.response && e.response.status === 404) {
        dispatch(error({
          title: 'Not found',
          subTitle: 'Invitation with code ' + invitationCode + ' not found',
          description: 'Please double check the interview invitation that you received',
        }));
      } else {
        dispatch(error(e));
      }
    }
    dispatch(loading(false));
  }
}

function initialize(liveInterview, participant, invitation) {
  return (dispatch) => {
    try {
      dispatch(liveInterviewReceived(liveInterview));
      dispatch(hostStatus(!!liveInterview.isHost));
      dispatch(liveInterviewParticipantReceived(participant));
      dispatch(liveInterviewInvitation(invitation));
    } catch (e) {
      dispatch(error(e));
    }
  };
}

export function initSession(liveInterview, participant) {
  return async dispatch => {
    if (!liveInterview.completedAt && participant) {
      const session = getSessionInstance(liveInterview.id);
      session.setLiveInterviewParticipant(participant);

      session.on(EVENTS_SESSION_INIT_SUCCESS, () => {
        dispatch(interviewSession(session));
      });
      session.on(EVENTS_SESSION_INIT_FAIL, (e) => {
        let errorMessage = '';
        if (e && e.message) {
          errorMessage = e.message;
        } else {
          errorMessage = e;
        }
        dispatch(interviewSession(null));
        dispatch(error('Init session error: ' + errorMessage));
      })
      session.initSession();
    }
  }
}

export function startInterview(session) {
  return async dispatch => {
    dispatch(startingInterview(true));
    try {
      await session.startInterview();
      dispatch(startingInterview(false));
    } catch(e) {
      dispatch(startingInterview(false));
      dispatch(error(e.message));
    }
  }
}

export function joinToInterview(session) {
  return dispatch => {
    dispatch(joiningToInterview(true));
    dispatch(error(null));
    session.joinInterview().then(() => {
      dispatch(joinedToInterview(true));
      dispatch(joiningToInterview(false));
    }).catch(e => {
      dispatch(joinedToInterview(false));
      dispatch(joiningToInterview(false));
      dispatch(error(e));
    });
  }
}

export function endInterview(session) {
  return async dispatch => {
    dispatch(endingInterview(true));
    try {
      await session.endInterview();
      dispatch(endingInterview(false));
    } catch(e) {
      dispatch(endingInterview(false));
      dispatch(error(e));
    }
  }
}

export function setPublisherPreferences(preferences = {}) {
  return {type: 'LIVEINTERVIEW_PAGE_PUBLISHER_PREFERENCES', preferences};
};

export function initPublisher(publisherPreferences) {
  return dispatch => {
    const isSipAudio = publisherPreferences.audioSource === 'SIP';
    if (isSipAudio) {
      publisherPreferences.audioSource = false;
      dispatch(muteAllParticipants(true));
    }
    createVideoPublisher(publisherPreferences || {}).then(publisher => {
      dispatch(videoPublisher(publisher));
    }).catch(e => {
      if (e instanceof OpenViduError) {
        console.error('OpenViduError', e);
      } else {
        dispatch(error(e));
      }
    });
  };
}

export function createLiveInterviewParticipant(liveInterview) {
  return async dispatch => {
    dispatch(creatingLiveInterviewParticipant(true));
    const interviewId = liveInterview.id;
    try {
      const response = await fetch(`live_interviews/${interviewId}/participants`, {
        method: 'POST'
      });
      const participant = response.data;
      dispatch(liveInterviewParticipantReceived(participant));
      dispatch(liveInterviewParticipantCreated(true));
    } catch (e) {
      dispatch(error(e));
    }
    dispatch(creatingLiveInterviewParticipant(false));
  }
}

export function muteAllParticipants(mute) {
  return {type: 'LIVEINTERVIEW_PAGE_MUTE_PARTICIPANTS', mute};
}

export function reset() {
  return {type: 'LIVEINTERVIEW_PAGE_RESET'};
}

export function reconnectToInterview(session, publisher) {
  return async dispatch => {
    dispatch(reconnectingToInterview(true));
    await session.reconnectToInterview(publisher);
    dispatch(reconnectingToInterview(false));
  }
}

export function setInterviewStartedAt(liveInterview) {
  return dispatch => {
    dispatch(liveInterviewReceived({
      ...liveInterview,
      startedAt: new Date().toJSON(),
    }));
  }
}

export function setInterviewCompletedAt(liveInterview) {
  return dispatch => {
    dispatch(liveInterviewReceived({
      ...liveInterview,
      completedAt: new Date().toJSON(),
    }));
  }
}

export function fullScreenMode(enabled) {
  return {type: 'LIVEINTERVIEW_PAGE_FULLSCREEN', enabled};
}

export function bottomPanelActive(active) {
  return {type: 'LIVEINTERVIEW_PAGE_BOTTOM_PANEL_ACTIVE', active};
}

function reconnectingToInterview(value) {
  return {type: 'LIVEINTERVIEW_PAGE_RECONNECTING', value};
}

export function error(error) {
  return {type: 'LIVEINTERVIEW_PAGE_ERROR', error}
}

export function addParticipant(participant) {
  return {type: 'LIVEINTERVIEW_PAGE_ADD_PARTICIPANT', participant}
}

export function closeParticipantStream(userId, streamType) {
  return {type: 'LIVEINTERVIEW_PAGE_CLOSE_PARTICIPANT_STREAM', userId, streamType}
}

export function clearParticipants() {
  return {type: 'LIVEINTERVIEW_PAGE_CLEAR_PARTICIPANTS'}
}

export function updateParticipantSpeakingState(participant, speaking) {
  return {type: 'LIVEINTERVIEW_PAGE_PARTICIPANT_SPEAKING', participant, speaking};
}

function loading(loading) {
  return { type: 'LIVEINTERVIEW_PAGE_LOADING', loading };
}

export function liveInterviewReceived(liveInterview) {
  return { type: 'LIVEINTERVIEW_PAGE_LIVE_INTERVIEW', liveInterview };
}

export function liveInterviewParticipantReceived(participant) {
  const liveInterviewParticipant = participant ? participant : null;
  return { type: 'LIVEINTERVIEW_PAGE_LIVE_INTERVIEW_PARTICIPANT', liveInterviewParticipant};
}

export function liveInterviewParticipantCreated(created) {
  return { type: 'LIVEINTERVIEW_PAGE_LIVE_INTERVIEW_PARTICIPANT_CREATED', created};
}

function hostStatus(isHost) {
  return { type: 'LIVEINTERVIEW_PAGE_HOST_STATUS', isHost }
}

function interviewSession(session) {
  return { type: 'LIVEINTERVIEW_PAGE_SESSION', session }
}

function startingInterview(starting) {
  return { type: 'LIVEINTERVIEW_PAGE_STARTING', starting }
}

function joiningToInterview(joining) {
  return { type: 'LIVEINTERVIEW_PAGE_JOINING', joining };
}

function creatingLiveInterviewParticipant(creating) {
  return { type: 'LIVEINTERVIEW_PAGE_CREATING_LIVE_INTERVIEW_PARTICIPANT', creating };
}

function joinedToInterview(joined) {
  return { type: 'LIVEINTERVIEW_PAGE_JOINED', joined };
}

function endingInterview(ending) {
  return { type: 'LIVEINTERVIEW_PAGE_ENDING', ending };
}

export function videoPublisher(publisher) {
  return { type: 'LIVEINTERVIEW_PAGE_PUBLISHER', publisher };
}

export function sipAudio(sipAudio) {
  return { type: 'LIVEINTERVIEW_PAGE_SIP_AUDIO', sipAudio };
}

export function shareScreen(streamManager) {
  return { type: 'LIVEINTERVIEW_PAGE_SHARE_SCREEN', streamManager};
}

export function setRecordingStatus(recording) {
  return { type: 'LIVEINTERVIEW_PAGE_RECORDING', recording};
}

export function setFocusedParticipantId(participantId) {
  return { type: 'LIVEINTERVIEW_PAGE_FOCUSED_PARTICIPANT', participantId};
}
