import React, {Fragment} from "react";
import {renderTextField, renderCheckbox, renderSelect, renderDatePicker} from "./form";
import { Field } from 'redux-form';
import MenuItem from "@material-ui/core/MenuItem";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import SearchableSelect from "views/Components/SearchableSelect";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import 'assets/scss/filter-form-customization.scss';
import FilterJobsModal from "../components/Job/FilterJobsModal";
import FilterDatesRange from "./FilterDatesRange";
import PropTypes from 'prop-types';

export function FilterText({name, label, placeholder, type})
{
  let normalize;
  if ('number' === type) {
    normalize = (value) => {
      if ('' !== value && value < 1) {
        return 1;
      }

      return value;
    }
  }

    return (<Field
      component={renderTextField}
      type={type}
      label={label}
      name={name}
      placeholder={placeholder}
      normalize={normalize}
      containerClass="form-group col-md-3 float-left"
    />);
}

export function FilterDateTime({name, label, placeholder})
{
  return (<Field
    component={renderDatePicker}
    label={label}
    name={name}
    placeholder={placeholder}
    containerClass="form-group col-md-3 float-left"
    fullWidth
  />);
}

export function FilterSelectSearch({name, label, placeholder, options, isMulti, isDisabled, onChange, customStyles})
{
  return (
      <Field
          component={SearchableSelect}
          name={name}
          label={label}
          placeholder={placeholder}
          containerClass="form-group col-md-3 float-left"
          options={options}
          isMulti={isMulti}
          isDisabled={isDisabled}
          onChange={onChange}
          customStyles={customStyles}
          fullWidth
      />
  );
}

export function SortableTableCells({sort, sortData, fields}) {
  const currentSortParam = null !== sortData ? Object.keys(sortData)[0] : null;
  let Cells = [];
  for (let field in fields) {
    if (fields.hasOwnProperty(field)) {
      const label = fields[field];
      const sortParam = 'sort[' + field + ']';
      const direction = currentSortParam === sortParam && sortData[sortParam] === 'asc' ? 'desc' : 'asc';
      const active = currentSortParam === sortParam;
      const defaultIcon = !active ? <UnfoldMoreIcon fontSize='small'/> : '';
      let newSortData = {};
      if (currentSortParam === sortParam && 'desc' === sortData[sortParam]) {
        newSortData = null;
      } else {
        newSortData[sortParam] = direction;
      }

      Cells.push(
        <TableCell key={field}>
          <TableSortLabel active={active} direction={direction} onClick={() => {sort(newSortData)}}>{label} {defaultIcon}</TableSortLabel>
        </TableCell>
      );
    }
  }

  return Cells;
}

export default class RenderFilterForm extends React.Component {
  static propTypes = {
    onFirstExpand: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      wasExpanded: false,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!this.state.open && nextProps) {
      let defaultValues = this.props.defaultValues ? this.props.defaultValues : null;

      if (JSON.stringify(defaultValues) !== JSON.stringify(nextProps.initialValues)) {
        let state = {open: true};

        if (!this.state.wasExpanded && typeof this.props.onFirstExpand === 'function') {
          this.props.onFirstExpand();
          state['wasExpanded'] = true;
        }

        this.setState(state);
      }
    }
  }

  toggleForm = (e) => {
    e.preventDefault();

    let state = {open: !this.state.open};
    if (!this.state.open && !this.state.wasExpanded && typeof this.props.onFirstExpand === 'function') {
      this.props.onFirstExpand();
      state['wasExpanded'] = true;
    }

    this.setState(state);
  };

  render() {
    const {handleSubmit, resetFilter, fields, initialValues, change} = this.props;

    let Fields = [];
    for (let i in fields) {
      if (fields.hasOwnProperty(i)) {
        let type = fields[i]['type'];
        let name = fields[i]['name'];
        let label = fields[i]['label'];
        let placeholder = fields[i]['placeholder'];
        let options = fields[i]['options'];
        let isMulti = fields[i]['isMulti'];
        let onChange = fields[i]['onChange'];
        let isDisabled = fields[i]['isDisabled'];

        /* 4 filter fields per row */
        let n = Math.floor(i / 4);
        if (Fields[n] === undefined) {
          Fields[n] = [];
        }

        switch (type) {
          case 'text':
          case 'number':
            Fields[n].push(<FilterText key={name} name={name} label={label} type={type} placeholder={placeholder}/>);
            break;
          case 'dateTime':
            Fields[n].push(<FilterDateTime key={name} name={name} label={label} placeholder={placeholder} />);
            break;
          case 'datesRange':
            let past = fields[i]['past'];
            Fields[n].push(<FilterDatesRange key={name} name={name} label={label} placeholder={placeholder} change={change} initialValues={initialValues} past={past} />);
            break;
          case 'selectSearch':
            Fields[n].push(<FilterSelectSearch key={name} name={name} label={label} placeholder={placeholder}
                                               options={options} isMulti={isMulti} isDisabled={isDisabled}
                                               onChange={onChange}/>);
            break;
          case 'jobSelectSearch':
            let jobsFilterSelectJob = fields[i]['jobsFilterSelectJob'];
            let jobsFilterIsModalOpen = fields[i]['jobsFilterIsModalOpen'];
            let jobsFilterToggleModal = fields[i]['jobsFilterToggleModal'];

            Fields[n].push(
              <Fragment key={'job-select-search'}>
                <FilterSelectSearch
                  key={name}
                  name={name}
                  label={label}
                  placeholder={placeholder}
                  options={options}
                  isMulti={isMulti}
                  isDisabled={isDisabled}
                  onChange={onChange}
                  customStyles={{
                    clearIndicator: (provided) => ({
                      ...provided,
                      cursor: 'pointer',
                      margin: '0 25px 0 0',
                    })
                  }}
                />
                <i onClick={() => jobsFilterToggleModal(true)} className="fa fa-search jobs-filter-open-modal" />
                <div className="clearfix" />
                {this.state.wasExpanded && (
                  <FilterJobsModal
                    handleClose={jobsFilterToggleModal}
                    opened={jobsFilterIsModalOpen}
                    selectJob={jobsFilterSelectJob}
                  />
                )}
              </Fragment>
            );
            break;
        }
      }
    }

    return (
      <div className="filter-container">
        <a href='#' onClick={this.toggleForm} className="btn btn-primary btn-sm mb-3">
          {this.state.open ? 'Hide Filters' : 'Show Filters'}
        </a>
        <form onSubmit={handleSubmit} className={(this.state.open ? '' : 'hidden ') + 'filter-form'}>
          {Fields.map((FieldsRow, index) => (
            <div className="row" key={index}>
              {FieldsRow}
            </div>
          ))}
          <div className='clearfix'/>
          <div className='filter-form-actions'>
            <button type="submit" className="btn btn-info">
              Filter
            </button>
            <button className='btn btn-light ml-2' type='button' onClick={resetFilter}>Reset</button>
          </div>
        </form>
      </div>
    );
  }
}
