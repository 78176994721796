import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import Form from './Form';
import {create, reset} from '../../actions/KnockoutQuestionBank/create';

class KnockoutQuestionBankCreate extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    created: PropTypes.object,
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    if (this.props.created)
      this.props.history.push({
        pathname: `show/${encodeURIComponent(this.props.created['@id'])}`,
        state: {successMessage: 'Screening Question Bank successfully created.'}
      });

    return (
      <div>

        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
            {this.props.error}
          </div>
        )}

        <Form onSubmit={this.props.create}
              values={this.props.item}
              initialValues={{knockoutQuestionChoices: [{position: 1}, {position: 2}], type: 'single'}}
        />
        <Link to="." className="btn btn-primary float-left mr-3">
          Back to list
        </Link>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    created: state.knockoutquestionbank.create.created,
    error: state.knockoutquestionbank.create.error || state.knockoutquestionbank.categories.retrieveError || state.questionbank.create.error,
    loading: state.knockoutquestionbank.create.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  create: values => dispatch(create(values)),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KnockoutQuestionBankCreate);
