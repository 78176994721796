import HomePage from "views/Pages/HomePage.jsx";
import LoginPage from "views/Pages/LoginPage.jsx";
import PasswordRecoveryPage from "views/Pages/PasswordRecoveryPage.jsx";
import PasswordResetPage from "views/Pages/PasswordResetPage.jsx";
import CompanyRegisterPage from "views/Pages/CompanyRegisterPage.jsx";
import CandidateRegisterPage from "views/Pages/CandidateRegisterPage.jsx";
import ResumeReviewPage from "views/Pages/ResumeReviewPage.jsx";
import ResumeDistributionPage from "views/Pages/ResumeDistributionPage.jsx";
import InterviewTrainingPage from "views/Pages/InterviewTrainingPage.jsx";
import LiveInterviewByCodePage from "./views/Pages/LiveInterviewByCodePage";
import LiveInterviewByInvitationPage from "./views/Pages/LiveInterviewByInvitationPage";
import LiveInterviewShareLink from "./views/Pages/LiveInterviewShareLink";

// our actual routes
import {CompanyCandidateList, CompanyCandidateShow} from 'components/CompanyCandidate/';
import {CandidateCreate, CandidateList, CandidateShow, CandidateUpdate, CandidateUpdateProfile, CandidateViewProfile} from 'components/Candidate/';
import {CandidateCompanyShow} from 'components/CandidateCompany/';
import {CandidateOneWayInterviewList, CandidateOneWayInterviewShow} from 'components/CandidateOneWayInterview/';
import {CandidateOneWayInterviewInvitationList, CandidateOneWayInterviewInvitationShow} from 'components/CandidateOneWayInterviewInvitation/';
import {CandidateLiveInterviewList, CandidateLiveInterviewSchedule, CandidateLiveInterviewShow } from 'components/CandidateLiveInterview/';
import {CompanyCreate, CompanyList, CompanyShow, CompanyUpdate} from 'components/Company/';
import {CountryCreate, CountryList, CountryShow, CountryUpdate} from 'components/Country/';
import {CompanyUserCreate, CompanyUserList, CompanyUserShow, CompanyUserUpdate} from 'components/CompanyUser/';
import {EmailList, EmailShow} from 'components/Email/';
import {EmailTemplateList, EmailTemplateShow} from 'components/EmailTemplate/';
import {IndustryCreate, IndustryList, IndustryShow, IndustryUpdate} from 'components/Industry/';
import {JobCreate, JobList, JobShow, JobUpdate} from 'components/Job/';
import {CompanyDashboard} from 'components/CompanyDashboard/';
import {PartnerCompanySubscription, PartnerCompanyPlanSelect, PartnerCompanyPaymentMethodSelect, PartnerCompanyOrderShow} from 'components/PartnerCompanySubscription/';
import {
  KnockoutQuestionBankCreate,
  KnockoutQuestionBankList,
  KnockoutQuestionBankShow,
  KnockoutQuestionBankUpdate
} from 'components/KnockoutQuestionBank/';
import {
  KnockoutQuestionBankCategoryCreate,
  KnockoutQuestionBankCategoryList,
  KnockoutQuestionBankCategoryShow,
  KnockoutQuestionBankCategoryUpdate
} from 'components/KnockoutQuestionBankCategory/';
import {
  LiveInterviewAll,
  LiveInterviewCreate,
  LiveInterviewList,
  LiveInterviewSchedule,
  LiveInterviewShow,
  LiveInterviewUpdate,
  LiveInterviewAccessList
} from 'components/LiveInterview/';
import {LiveInterviewCandidateInvitationList} from 'components/LiveInterviewCandidateinvitation';
import {LiveInterviewReviewCreate, LiveInterviewReviewEdit, LiveInterviewReviewList} from 'components/LiveInterviewReview/';
import {LoginHistoryList} from 'components/LoginHistory/';
import {OccupationCreate, OccupationList, OccupationShow, OccupationUpdate} from 'components/Occupation/';
import {OneWayInterviewList, OneWayInterviewShow, OneWayInterviewAccessList} from 'components/OneWayInterview/';
import {InvitationList, SendInvitations} from "./components/Invitation";
import {InvitationInterviewStatusList, InvitationInterviewStatusCreate, InvitationInterviewStatusUpdate, InvitationInterviewStatusShow} from "./components/InvitationInterviewStatus";
import {OneWayInterviewReviewCreate, OneWayInterviewReviewEdit, OneWayInterviewReviewList} from 'components/OneWayInterviewReview/';
import {PartnerCreate, PartnerList, PartnerShow, PartnerUpdate, PartnerSettings} from 'components/Partner/';
import {PartnerAdminCreate, PartnerAdminList, PartnerAdminShow, PartnerAdminUpdate} from 'components/PartnerAdmin/';
import {PartnerAdminPaymentMethodCreate} from 'components/PaymentMethod/';
import {PresenterCreate, PresenterList, PresenterShow, PresenterUpdate} from 'components/Presenter/';
import {QuestionBankCreate, QuestionBankList, QuestionBankShow, QuestionBankUpdate} from 'components/QuestionBank/';
import {PrivacyPolicy, TermsOfService, CandidateSettings} from 'components/SiteSettings/';
import {PlanList, PlanCreate, PlanUpdate} from 'components/Plan/';
import {
  QuestionBankCategoryCreate,
  QuestionBankCategoryList,
  QuestionBankCategoryShow,
  QuestionBankCategoryUpdate
} from 'components/QuestionBankCategory/';
import {RequestCreate, RequestList, RequestShow, RequestUpdate} from 'components/Request/';
import {
  RequestTemplateCreate,
  RequestTemplateList,
  RequestTemplateShow,
  RequestTemplateUpdate
} from 'components/RequestTemplate/';
import {ReviewScaleList} from 'components/ReviewScale';
import {SpecialtyCreate, SpecialtyList, SpecialtyShow, SpecialtyUpdate} from 'components/Specialty/';
import {StateCreate, StateList, StateShow, StateUpdate} from 'components/State/';
import {SuperAdminCreate, SuperAdminList, SuperAdminShow, SuperAdminUpdate} from 'components/SuperAdmin/';
import {TagCreate, TagList, TagShow, TagUpdate} from 'components/Tag/';
import {TeamCreate, TeamList, TeamShow, TeamUpdate} from 'components/Team/';
import {TicketCreate, TicketList, TicketShow, TicketUpdate} from 'components/Ticket/';
import {TransactionList} from 'components/Transactions/';
import {UserList, UserChangePassword} from 'components/User/';
import {CompanyProfileUpdate} from 'components/CompanyProfile/';
import {UserProfileUpdate} from 'components/UserProfile/';
// dashboards
import CandidateDashboardPage from "views/Pages/Candidate/DashboardPage.jsx";
import PartnerAdminDashboardPage from "views/Pages/PartnerAdmin/DashboardPage.jsx";
import SuperAdminDashboardPage from "views/Pages/SuperAdmin/DashboardPage.jsx";
import Logout from "./views/Pages/Logout";
import SiteSettingsPreview from "./views/Pages/SiteSettingsPreview";
import OneWayInterviewPreview from "./views/Pages/OneWayInterviewPreview";
import OneWayInterviewShareLink from "./views/Pages/OneWayInterviewShareLink";
// homepages
import CompanyHomePage from "views/Pages/Company/HomePage.jsx";
// @material-ui/icons
import {Dashboard, DateRange, Image, Place, ExitToApp, BusinessCenter, Help, Person, Assignment, QuestionAnswer, AccountCircle, PermDataSetting, Cloud, GroupWork, Accessibility, Business, Schedule, CheckCircle, SpeakerNotes, Map, LiveHelp, Category, VideoLibrary, SlowMotionVideo, Extension, Label, Slideshow, Drafts, Email, History, Edit, VpnKey, InsertInvitation, AssignmentTurnedIn, WorkOutline, Store, Work, CardMembership, Public, Star, SupervisorAccount, RateReview, AssignmentInd, AssistantPhoto, DeviceHub, Visibility, DoneAll, Gavel, SettingsApplications, MonetizationOn, ImportContacts, Timeline, StarHalf} from "@material-ui/icons";
import SettingsIcon from '@material-ui/icons/Settings';
// pages
import OnDemandInterviewByCodePage from "./views/Pages/OnDemandInterviewByCodePage";
import OnDemandInterviewByInvitationPage from "./views/Pages/OnDemandInterviewByInvitationPage";

var getRoutes = (site) => {
  const siteUrls = (site['urls']) ? site['urls'] : {};
  const routes = [
  // super admin routes
  {
    path: "/dashboard",
    name: "Super Admin Dashboard",
    icon: Dashboard,
    component: SuperAdminDashboardPage,
    layout: "/super-admin",
    roles: ["ROLE_SUPER_ADMIN"]
  },
  {
    path: "/partners/create",
    name: "Create Partner",
    component: PartnerCreate,
    layout: "/super-admin",
    roles: ["ROLE_SUPER_ADMIN"],
    invisible: true
  },
  {
    path: "/partners/edit/:id",
    name: "Update Partner",
    component: PartnerUpdate,
    layout: "/super-admin",
    roles: ["ROLE_SUPER_ADMIN"],
    invisible: true
  },
  {
    path: "/partners/show/:id",
    name: "Show Partner",
    component: PartnerShow,
    layout: "/super-admin",
    roles: ["ROLE_SUPER_ADMIN"],
    invisible: true
  },
  {
    path: "/partners\\/:page(\\d+)?",
    name: "Partners",
    icon: Place,
    component: PartnerList,
    layout: "/super-admin",
    roles: ["ROLE_SUPER_ADMIN"],
  },
  {
    path: "/partner-admins/create",
    name: "Create Partner Admin",
    component: PartnerAdminCreate,
    layout: "/super-admin",
    roles: ["ROLE_SUPER_ADMIN"],
    invisible: true
  },
  {
    path: "/partner-admins/edit/:id",
    name: "Update Partner Admin",
    component: PartnerAdminUpdate,
    layout: "/super-admin",
    roles: ["ROLE_SUPER_ADMIN"],
    invisible: true
  },
  {
    path: "/partner-admins/show/:id",
    name: "Show Partner Admin",
    component: PartnerAdminShow,
    layout: "/super-admin",
    roles: ["ROLE_SUPER_ADMIN"],
    invisible: true
  },
  {
    path: "/partner-admins\\/:page(\\d+)?",
    name: "Partner Admins",
    component: PartnerAdminList,
    layout: "/super-admin",
    roles: ["ROLE_SUPER_ADMIN"],
    invisible: true
  },
  {
    path: "/companies/create",
    name: "Create Company",
    component: CompanyCreate,
    layout: "/super-admin",
    roles: ["ROLE_SUPER_ADMIN"],
    invisible: true
  },
  {
    path: "/companies/edit/:id",
    name: "Update Company",
    component: CompanyUpdate,
    layout: "/super-admin",
    roles: ["ROLE_SUPER_ADMIN"],
    invisible: true
  },
  {
    path: "/companies/show/:id",
    name: "Show Company",
    component: CompanyShow,
    layout: "/super-admin",
    roles: ["ROLE_SUPER_ADMIN"],
    invisible: true
  },
  {
    path: "/companies\\/:page(\\d+)?",
    name: "Companies",
    icon: BusinessCenter,
    component: CompanyList,
    layout: "/super-admin",
    roles: ["ROLE_SUPER_ADMIN"],
  },
  {
    path: "/candidates/edit/:id",
    name: "Update Candidate",
    component: CandidateUpdate,
    layout: "/super-admin",
    roles: ["ROLE_SUPER_ADMIN"],
    invisible: true
  },
  {
    path: "/candidates/show/:id",
    name: "Show Candidate",
    component: CandidateShow,
    layout: "/super-admin",
    roles: ["ROLE_SUPER_ADMIN"],
    invisible: true
  },
  {
    path: "/candidates\\/:page(\\d+)?",
    name: "Candidates",
    icon: Person,
    component: CandidateList,
    layout: "/super-admin",
    roles: ["ROLE_SUPER_ADMIN"],
  },
  {
    path: "/users\\/:page(\\d+)?",
    name: "Users",
    icon: AccountCircle,
    component: UserList,
    layout: "/super-admin",
    roles: ["ROLE_SUPER_ADMIN"],
  },
  {
    collapse: true,
    name: "Data Management",
    icon: Image,
    state: "dataManagementCollapse",
    roles: ["ROLE_SUPER_ADMIN"],
    views: [
      {
        path: "/question-banks/create",
        name: "Create Video Question Bank",
        component: QuestionBankCreate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/question-banks/edit/:id",
        name: "Update Video Question Bank",
        component: QuestionBankUpdate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/question-banks/show/:id",
        name: "Show Video Question Bank",
        component: QuestionBankShow,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/question-banks\\/:page(\\d+)?",
        name: "Video Question Bank",
        icon: VideoLibrary,
        component: QuestionBankList,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
      },
      {
        path: "/question-bank-categories/create",
        name: "Create Video Question Category",
        component: QuestionBankCategoryCreate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/question-bank-categories/edit/:id",
        name: "Update Video Question Category",
        component: QuestionBankCategoryUpdate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/question-bank-categories/show/:id",
        name: "Show Video Question Category",
        component: QuestionBankCategoryShow,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/question-bank-categories\\/:page(\\d+)?",
        name: "Video Question Categories",
        icon: SlowMotionVideo,
        component: QuestionBankCategoryList,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
      },
      {
        path: "/knockout-question-banks/create",
        name: "Create Screening Question Bank",
        component: KnockoutQuestionBankCreate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/knockout-question-banks/edit/:id",
        name: "Update Screening Question Bank",
        component: KnockoutQuestionBankUpdate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/knockout-question-banks/show/:id",
        name: "Show Screening Question Bank",
        component: KnockoutQuestionBankShow,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/knockout-question-banks\\/:page(\\d+)?",
        name: "Screening Question Bank",
        icon: LiveHelp,
        component: KnockoutQuestionBankList,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
      },
      {
        path: "/knockout-question-bank-categories/create",
        name: "Create Screening Question Category",
        component: KnockoutQuestionBankCategoryCreate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/knockout-question-bank-categories/edit/:id",
        name: "Update Screening Question Category",
        component: KnockoutQuestionBankCategoryUpdate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/knockout-question-bank-categories/show/:id",
        name: "Show Screening Question Category",
        component: KnockoutQuestionBankCategoryShow,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/knockout-question-bank-categories\\/:page(\\d+)?",
        name: "Screening Question Categories",
        icon: Category,
        component: KnockoutQuestionBankCategoryList,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
      },
      {
        path: "/request-templates/create",
        name: "Create Interview Template",
        component: RequestTemplateCreate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/request-templates/edit/:id",
        name: "Update Interview Template",
        component: RequestTemplateUpdate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/request-templates/show/:id",
        name: "Show Interview Template",
        component: RequestTemplateShow,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/request-templates\\/:page(\\d+)?",
        name: "Interview Templates",
        icon: Extension,
        component: RequestTemplateList,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
      },
      {
        path: "/tags/create",
        name: "Create Tag",
        component: TagCreate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/tags/edit/:id",
        name: "Update Tag",
        component: TagUpdate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/tags/show/:id",
        name: "Show Tag",
        component: TagShow,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/tags\\/:page(\\d+)?",
        name: "Tags",
        icon: Label,
        component: TagList,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
      },
      {
        path: "/industries/create",
        name: "Create Industry",
        component: IndustryCreate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/presenters/create",
        name: "Create Presenter",
        component: PresenterCreate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/presenters/edit/:id",
        name: "Update Presenter",
        component: PresenterUpdate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/presenters/show/:id",
        name: "Show Presenter",
        component: PresenterShow,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/presenters\\/:page(\\d+)?",
        name: "Presenters",
        icon: Slideshow,
        component: PresenterList,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
      },
      {
        path: "/industries/edit/:id",
        name: "Update Industry",
        component: IndustryUpdate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/industries/show/:id",
        name: "Show Industry",
        component: IndustryShow,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/industries\\/:page(\\d+)?",
        name: "Industries",
        icon: Store,
        component: IndustryList,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
      },
      {
        path: "/occupations/create",
        name: "Create Occupation",
        component: OccupationCreate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/occupations/edit/:id",
        name: "Update Occupation",
        component: OccupationUpdate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/occupations/show/:id",
        name: "Show Occupation",
        component: OccupationShow,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/occupations\\/:page(\\d+)?",
        name: "Occupations",
        icon: Work,
        component: OccupationList,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
      },
      {
        path: "/specialties/create",
        name: "Create Specialty",
        component: SpecialtyCreate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/specialties/edit/:id",
        name: "Update Specialty",
        component: SpecialtyUpdate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/specialties/show/:id",
        name: "Show Specialty",
        component: SpecialtyShow,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/specialties\\/:page(\\d+)?",
        name: "Specialties",
        icon: CardMembership,
        component: SpecialtyList,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
      },
      {
        path: "/countries/create",
        name: "Create Country",
        component: CountryCreate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/countries/edit/:id",
        name: "Update Country",
        component: CountryUpdate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/countries/show/:id",
        name: "Show Country",
        component: CountryShow,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/countries\\/:page(\\d+)?",
        name: "Countries",
        icon: Public,
        component: CountryList,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
      },
      {
        path: "/states/create",
        name: "Create State",
        component: StateCreate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/states/edit/:id",
        name: "Update State",
        component: StateUpdate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/states/show/:id",
        name: "Show State",
        component: StateShow,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/states\\/:page(\\d+)?",
        name: "States",
        icon: Star,
        component: StateList,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
      },
      {
        path: "/invitation-interview-statuses/create",
        name: "Create On-Demand Interview Status",
        component: InvitationInterviewStatusCreate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/invitation-interview-statuses/edit/:id",
        name: "Edit On-Demand Interview Status Country",
        component: InvitationInterviewStatusUpdate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/invitation-interview-statuses/show/:id",
        name: "View On-Demand Interview Status Country",
        component: InvitationInterviewStatusShow,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/invitation-interview-statuses\\/:page(\\d+)?",
        icon: DoneAll,
        name: "On-Demand Interview Statuses List",
        component: InvitationInterviewStatusList,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
      },
      {
        path: "/email-templates/show/:id",
        name: "Show Email Templates",
        component: EmailTemplateShow,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/email-templates\\/:page(\\d+)?",
        name: "Email Templates",
        icon: Drafts,
        component: EmailTemplateList,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
      },
      {
        path: "/super-admins/create",
        name: "Create Super Admin",
        component: SuperAdminCreate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/super-admins/edit/:id",
        name: "Update Super Admin",
        component: SuperAdminUpdate,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/super-admins/show/:id",
        name: "Show Super Admin",
        component: SuperAdminShow,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/super-admins\\/:page(\\d+)?",
        name: "Super Admins",
        icon: SupervisorAccount,
        component: SuperAdminList,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
      },
    ]
  },
  {
    collapse: true,
    name: "Reports",
    icon: DateRange,
    state: "reportsCollapse",
    roles: ["ROLE_SUPER_ADMIN"],
    views: [
      {
        path: "/usage-history",
        name: "Usage History",
        component: SuperAdminDashboardPage,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/emails/show/:id",
        name: "Show Email",
        component: EmailShow,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: true
      },
      {
        path: "/emails\\/:page(\\d+)?",
        name: "Emails",
        icon: Email,
        component: EmailList,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
      },
      {
        path: "/login-histories\\/:page(\\d+)?",
        name: "Login History",
        icon: History,
        component: LoginHistoryList,
        layout: "/super-admin",
        roles: ["ROLE_SUPER_ADMIN"],
      },
    ]
  },
  {
    collapse: true,
    name: "My Profile",
    icon: Person,
    state: "myProfileCollapse",
    roles: ["ROLE_SUPER_ADMIN"],
    views: [
      {
        layout: "/super-admin",
        path: "/change-password",
        name: "Change Password",
        icon: VpnKey,
        component: UserChangePassword,
        roles: ["ROLE_SUPER_ADMIN"],
        invisible: false
      },
    ]
  },
  // partner admin routes
  {
    path: "/partner-admins/create",
    name: "Create Partner Admin",
    component: PartnerAdminCreate,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
    invisible: true
  },
  {
    path: "/partner-admins/edit/:id",
    name: "Update Partner Admin",
    component: PartnerAdminUpdate,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
    invisible: true
  },
  {
    path: "/partner-admins/show/:id",
    name: "Show Partner Admin",
    component: PartnerAdminShow,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
    invisible: true
  },
  {
    path: "/partner-admins\\/:page(\\d+)?",
    name: "Partner Admins",
    component: PartnerAdminList,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
    invisible: true
  },
  {
    path: "/dashboard",
    name: "Partner Admin Dashboard",
    icon: Dashboard,
    component: PartnerAdminDashboardPage,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"]
  },
  {
    path: "/companies/create",
    name: "Create Company",
    component: CompanyCreate,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
    invisible: true
  },
  {
    path: "/companies/edit/:id",
    name: "Update Company",
    component: CompanyUpdate,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
    invisible: true
  },
  {
    path: "/companies/show/:id",
    name: "Show Company",
    component: CompanyShow,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
    invisible: true
  },
  {
    path: "/companies/subscription/:id",
    name: "Company Subscription",
    component: PartnerCompanySubscription,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
    invisible: true,
    hideAdminNavbar: true,
  },
  {
    path: "/companies/subscription/:id/plan-select",
    name: "Select Plan For Company",
    component: PartnerCompanyPlanSelect,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
    invisible: true,
    hideAdminNavbar: true,
  },
  {
    path: "/companies/subscription/:id/payment-methods",
    name: "Select Payment Method",
    component: PartnerCompanyPaymentMethodSelect,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
    invisible: true,
    hideAdminNavbar: true,
  },
  {
    path: "/companies/subscription/:id/payment-methods/add",
    name: "Add Payment Method",
    component: PartnerAdminPaymentMethodCreate,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
    invisible: true,
    hideAdminNavbar: true,
  },
  {
    path: "/companies/subscription/:orderId/payment-success",
    name: "Successful Payment!",
    component: PartnerCompanyOrderShow,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
    invisible: true,
    hideAdminNavbar: true,
  },
  {
    path: "/companies\\/:page(\\d+)?",
    name: "Companies",
    icon: BusinessCenter,
    component: CompanyList,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
  },
  {
    path: "/candidates/create",
    name: "Create Candidate",
    component: CandidateCreate,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
    invisible: true,
  },
  {
    path: "/candidates/edit/:id",
    name: "Update Candidate",
    component: CandidateUpdate,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
    invisible: true
  },
  {
    path: "/candidates/show/:id",
    name: "Show Candidate",
    component: CandidateShow,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
    invisible: true
  },
  {
    path: "/candidates\\/:page(\\d+)?",
    name: "Candidates",
    icon: Person,
    component: CandidateList,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
  },
  {
    path: "/users\\/:page(\\d+)?",
    name: "Users",
    icon: AccountCircle,
    component: UserList,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
  },
  {
    collapse: true,
    name: "Partner Settings",
    icon: SettingsIcon,
    roles: ["ROLE_PARTNER_ADMIN"],
    state: "partnerSettingsCollapse",
    views: [
      {
        path: "/settings",
        name: "Partner Settings",
        icon: SettingsIcon,
        component: PartnerSettings,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
      },
      {
        path: "/privacy-policy",
        name: "Privacy Policy",
        icon: ImportContacts,
        component: PrivacyPolicy,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
      },
      {
        path: "/terms-of-service",
        name: "Terms of Service",
        icon: Gavel,
        component: TermsOfService,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
      },
      {
        path: "/candidate-settings",
        name: "Candidate Settings",
        icon: SettingsApplications,
        component: CandidateSettings,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
      },
      {
        path: "/plans/list",
        name: "Plans",
        icon: MonetizationOn,
        component: PlanList,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        hideAdminNavbar: true,
        invisible: true, // @TODO remove this when all
      },
      {
        path: "/plans/create",
        name: "Create Plan",
        component: PlanCreate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true,
        hideAdminNavbar: true,
      },
      {
        path: "/plans/edit/:id",
        name: "Update Plan",
        component: PlanUpdate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true,
        hideAdminNavbar: true,
      },
    ]
  },
  {
    path: "/tickets/create",
    name: "Create Ticket",
    component: TicketCreate,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
    invisible: true
  },
  {
    path: "/tickets/edit/:id",
    name: "Update Ticket",
    component: TicketUpdate,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
    invisible: true
  },
  {
    path: "/tickets/show/:id",
    name: "Show Ticket",
    component: TicketShow,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
    invisible: true
  },
  {
    path: "/tickets\\/:page(\\d+)?",
    name: "Tickets",
    icon: Help,
    component: TicketList,
    layout: "/partner-admin",
    roles: ["ROLE_PARTNER_ADMIN"],
  },
  {
    collapse: true,
    name: "Data Management",
    icon: Image,
    state: "dataManagementCollapse",
    roles: ["ROLE_PARTNER_ADMIN"],
    views: [
      {
        path: "/question-banks/create",
        name: "Create Video Question Bank",
        component: QuestionBankCreate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/question-banks/edit/:id",
        name: "Update Video Question Bank",
        component: QuestionBankUpdate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/question-banks/show/:id",
        name: "Show Video Question Bank",
        component: QuestionBankShow,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/question-banks\\/:page(\\d+)?",
        name: "Video Question Bank",
        icon: VideoLibrary,
        component: QuestionBankList,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
      },
      {
        path: "/question-bank-categories/create",
        name: "Create Video Question Category",
        component: QuestionBankCategoryCreate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/question-bank-categories/edit/:id",
        name: "Update Video Question Category",
        component: QuestionBankCategoryUpdate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/question-bank-categories/show/:id",
        name: "Show Video Question Category",
        component: QuestionBankCategoryShow,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/question-bank-categories\\/:page(\\d+)?",
        name: "Video Question Categories",
        icon: SlowMotionVideo,
        component: QuestionBankCategoryList,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
      },
      {
        path: "/knockout-question-banks/create",
        name: "Create Screening Question Bank",
        component: KnockoutQuestionBankCreate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/knockout-question-banks/edit/:id",
        name: "Update Screening Question Bank",
        component: KnockoutQuestionBankUpdate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/knockout-question-banks/show/:id",
        name: "Show Screening Question Bank",
        component: KnockoutQuestionBankShow,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/knockout-question-banks\\/:page(\\d+)?",
        name: "Screening Question Bank",
        icon: LiveHelp,
        component: KnockoutQuestionBankList,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
      },
      {
        path: "/knockout-question-bank-categories/create",
        name: "Create Screening Question Category",
        component: KnockoutQuestionBankCategoryCreate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/knockout-question-bank-categories/edit/:id",
        name: "Update Screening Question Category",
        component: KnockoutQuestionBankCategoryUpdate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/knockout-question-bank-categories/show/:id",
        name: "Show Screening Question Category",
        component: KnockoutQuestionBankCategoryShow,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/knockout-question-bank-categories\\/:page(\\d+)?",
        name: "Screening Question Categories",
        icon: Category,
        component: KnockoutQuestionBankCategoryList,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
      },
      {
        path: "/request-templates/create",
        name: "Create Interview Template",
        component: RequestTemplateCreate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/request-templates/edit/:id",
        name: "Update Interview Template",
        component: RequestTemplateUpdate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/request-templates/show/:id",
        name: "Show Interview Template",
        component: RequestTemplateShow,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/request-templates\\/:page(\\d+)?",
        name: "Interview Templates",
        icon: Extension,
        component: RequestTemplateList,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
      },
      {
        path: "/tags/create",
        name: "Create Tag",
        component: TagCreate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/tags/edit/:id",
        name: "Update Tag",
        component: TagUpdate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/tags/show/:id",
        name: "Show Tag",
        component: TagShow,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/tags\\/:page(\\d+)?",
        name: "Tags",
        icon: Label,
        component: TagList,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
      },
      {
        path: "/presenters/create",
        name: "Create Presenter",
        component: PresenterCreate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/presenters/edit/:id",
        name: "Update Presenter",
        component: PresenterUpdate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/presenters/show/:id",
        name: "Show Presenter",
        component: PresenterShow,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/presenters\\/:page(\\d+)?",
        name: "Presenters",
        icon: Slideshow,
        component: PresenterList,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
      },
      {
        path: "/industries/edit/:id",
        name: "Update Industry",
        component: IndustryUpdate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/industries/show/:id",
        name: "Show Industry",
        component: IndustryShow,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/industries\\/:page(\\d+)?",
        name: "Industries",
        icon: Store,
        component: IndustryList,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
      },
      {
        path: "/industries/create",
        name: "Create Industry",
        component: IndustryCreate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/occupations/create",
        name: "Create Occupation",
        component: OccupationCreate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/occupations/edit/:id",
        name: "Update Occupation",
        component: OccupationUpdate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/occupations/show/:id",
        name: "Show Occupation",
        component: OccupationShow,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/occupations\\/:page(\\d+)?",
        name: "Occupations",
        icon: Work,
        component: OccupationList,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
      },
      {
        path: "/specialties/create",
        name: "Create Specialty",
        component: SpecialtyCreate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/specialties/edit/:id",
        name: "Update Specialty",
        component: SpecialtyUpdate,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/specialties/show/:id",
        name: "Show Specialty",
        component: SpecialtyShow,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/specialties\\/:page(\\d+)?",
        name: "Specialties",
        icon: CardMembership,
        component: SpecialtyList,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
      },
      {
        path: "/email-templates/show/:id",
        name: "Show Email Templates",
        component: EmailTemplateShow,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/email-templates/:page",
        name: "Email Templates",
        component: EmailTemplateList,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/email-templates/",
        name: "Email Templates",
        icon: Drafts,
        component: EmailTemplateList,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"]
      },
    ]
  },
  {
    collapse: true,
    name: "Reports",
    icon: DateRange,
    state: "reportsCollapse",
    roles: ["ROLE_PARTNER_ADMIN"],
    views: [
      {
        path: "/usage-history",
        name: "Usage History",
        component: PartnerAdminDashboardPage,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/emails/show/:id",
        name: "Show Email",
        component: EmailShow,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true
      },
      {
        path: "/emails\\/:page(\\d+)?",
        name: "Emails",
        icon: Email,
        component: EmailList,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
      },
      {
        path: "/login-histories\\/:page(\\d+)?",
        name: "Login History",
        icon: History,
        component: LoginHistoryList,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
      },
      {
        path: "/transactions-for-company/:company\\/:page(\\d+)?",
        name: "Billing History",
        component: TransactionList,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true,
      },
      {
        path: "/transactions\\/:page(\\d+)?",
        name: "Billing History",
        icon: MonetizationOn,
        component: TransactionList,
        layout: "/partner-admin",
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: true, // @TODO remove this when whole subscription system will be done.
      },
    ]
  },
  {
    collapse: true,
    name: "My Profile",
    icon: Person,
    state: "myProfileCollapse",
    roles: ["ROLE_PARTNER_ADMIN"],
    views: [
      {
        layout: "/partner-admin",
        path: "/change-password",
        name: "Change Password",
        icon: VpnKey,
        component: UserChangePassword,
        roles: ["ROLE_PARTNER_ADMIN"],
        invisible: false
      },
    ]
  },
  // company routes
  {
    path: "/home",
    name: "Home",
    icon: Dashboard,
    component: CompanyHomePage,
    layout: "/company",
    roles: ["ROLE_COMPANY_USER"]
  },
  {
    layout: "/company",
    path: "/change-password",
    name: "Change Password",
    icon: VpnKey,
    component: UserChangePassword,
    roles: ["ROLE_COMPANY_USER"],
    invisible: true
  },
  {
    layout: "/company",
    path: "/user-profile",
    name: "User Profile",
    component: UserProfileUpdate,
    roles: ["ROLE_COMPANY_USER"],
    invisible: true
  },
  {
    collapse: true,
    name: "On Demand Interviews",
    icon: QuestionAnswer,
    state: "oneWayInterviewsCollapse",
    roles: ["ROLE_COMPANY_USER"],
    views: [
      {
        path: "/requests\\/:page(\\d+)?",
        name: "Manage Interviews",
        icon: WorkOutline,
        component: RequestList,
        layout: "/company",
        roles: ["ROLE_COMPANY_ADMIN", "ROLE_COMPANY_EMPLOYEE"],
      },
      {
        path: "/on-demand-interviews\\/:page(\\d+)?",
        name: "Interview Responses",
        icon: AssignmentTurnedIn,
        component: OneWayInterviewList,
        layout: "/company",
        roles: ["ROLE_COMPANY_USER"],
      },
      {
        path: "/invitations\\/:page(\\d+)?",
        name: "Invitations",
        icon: InsertInvitation,
        component: InvitationList,
        layout: "/company",
        roles: ["ROLE_COMPANY_ADMIN", "ROLE_COMPANY_EMPLOYEE"]
      },
      {
        path: "/invitations/send/:requestId",
        name: "Invite Candidate",
        component: SendInvitations,
        layout: "/company",
        roles: ["ROLE_COMPANY_ADMIN", "ROLE_COMPANY_EMPLOYEE"],
        invisible: true
      },
      //invisible below
      {
        path: "/on-demand-interviews/show/:id",
        name: "Show Interview Responses",
        component: OneWayInterviewShow,
        layout: "/company",
        roles: ["ROLE_COMPANY_USER"],
        invisible: true
      },
      {
        path: "/on-demand-interview-reviews/:interview/review/:review",
        name: "Interview Responses Review",
        component: OneWayInterviewReviewEdit,
        layout: "/company",
        roles: ["ROLE_COMPANY_USER"],
        invisible: true
      },
      {
        path: "/on-demand-interview-reviews/:interview/review",
        name: "Interview Responses Review",
        component: OneWayInterviewReviewCreate,
        layout: "/company",
        roles: ["ROLE_COMPANY_USER"],
        invisible: true
      },
      {
        path: "/on-demand-interview-reviews/:interview/reviews",
        name: "Interview Responses Reviews List",
        component: OneWayInterviewReviewList,
        layout: "/company",
        roles: ["ROLE_COMPANY_USER"],
        invisible: true
      },
      {
        path: "/on-demand-interviews/access/:interview",
        name: "Interview Shared Users",
        component: OneWayInterviewAccessList,
        layout: "/company",
        roles: ["ROLE_COMPANY_USER"],
        invisible: true
      },
      {
        path: "/requests/create",
        name: "Create Interview",
        component: RequestCreate,
        layout: "/company",
        roles: ["ROLE_COMPANY_ADMIN", "ROLE_COMPANY_EMPLOYEE"],
        invisible: true
      },
      {
        path: "/requests/create/:jobId",
        name: "Create Interview",
        component: RequestCreate,
        layout: "/company",
        roles: ["ROLE_COMPANY_ADMIN", "ROLE_COMPANY_EMPLOYEE"],
        invisible: true
      },
      {
        path: "/requests/edit/:id",
        name: "Update Interview",
        component: RequestUpdate,
        layout: "/company",
        roles: ["ROLE_COMPANY_ADMIN", "ROLE_COMPANY_EMPLOYEE"],
        invisible: true
      },
      {
        path: "/requests/show/:id",
        name: "Show Interview",
        component: RequestShow,
        layout: "/company",
        roles: ["ROLE_COMPANY_USER"],
        invisible: true
      },
    ],
  },
  {
    collapse: true,
    name: "Live Interviews",
    icon: Cloud,
    state: "liveInterviewsCollapse",
    roles: ["ROLE_COMPANY_USER"],
    views: [
      {
        path: "/live-interview-schedules\\/:page(\\d+)?",
        name: "Schedule",
        icon: Schedule,
        component: LiveInterviewSchedule,
        layout: "/company",
        roles: ["ROLE_COMPANY_USER"]
      },
      {
        path: "/live-interviews\\/:page(\\d+)?",
        name: "Completed",
        icon: CheckCircle,
        component: LiveInterviewList,
        layout: "/company",
        roles: ["ROLE_COMPANY_USER"]
      },
      {
        path: "/live-interview-all\\/:page(\\d+)?",
        name: "All Interviews",
        icon: SpeakerNotes,
        component: LiveInterviewAll,
        layout: "/company",
        roles: ["ROLE_COMPANY_USER"],
        invisible: true
      },
      {
        path: "/live-interview-invitations\\/:page(\\d+)?",
        name: "Invite Tracking",
        icon: Map,
        component: LiveInterviewCandidateInvitationList,
        layout: "/company",
        roles: ["ROLE_COMPANY_ADMIN", "ROLE_COMPANY_EMPLOYEE"],
      },
      //invisible items below
      {
        path: "/live-interviews/show/:id",
        name: "Show Live Interview",
        component: LiveInterviewShow,
        layout: "/company",
        roles: ["ROLE_COMPANY_USER"],
        invisible: true,
        mainPanelClasses: 'layout-gray',
      },
      {
        path: "/live-interview-schedules/create",
        name: "Schedule Live Interview",
        component: LiveInterviewCreate,
        layout: "/company",
        roles: ["ROLE_COMPANY_ADMIN", "ROLE_COMPANY_EMPLOYEE"],
        invisible: true,
      },
      {
        path: "/live-interview-schedules/create/:jobId",
        name: "Schedule Live Interview",
        component: LiveInterviewCreate,
        layout: "/company",
        roles: ["ROLE_COMPANY_ADMIN", "ROLE_COMPANY_EMPLOYEE"],
        invisible: true,
      },
      {
        path: "/live-interview-schedules/show/:id",
        name: "Show Scheduled Live Interview",
        component: LiveInterviewShow,
        layout: "/company",
        roles: ["ROLE_COMPANY_USER"],
        invisible: true,
        mainPanelClasses: 'layout-gray',
      },
      {
        path: "/live-interview-schedules/edit/:id",
        name: "Edit Live Interview Schedule",
        component: LiveInterviewUpdate,
        layout: "/company",
        roles: ["ROLE_COMPANY_ADMIN", "ROLE_COMPANY_EMPLOYEE"],
        invisible: true
      },
    ]
  },
  {
    path: "/jobs/create",
    name: "Create Job",
    component: JobCreate,
    layout: "/company",
    roles: ["ROLE_COMPANY_ADMIN", "ROLE_COMPANY_EMPLOYEE"],
    invisible: true
  },
  {
    path: "/jobs/edit/:id",
    name: "Update Job",
    component: JobUpdate,
    layout: "/company",
    roles: ["ROLE_COMPANY_ADMIN", "ROLE_COMPANY_EMPLOYEE"],
    invisible: true
  },
  {
    path: "/jobs/show/:id",
    name: "Show Job",
    component: JobShow,
    layout: "/company",
    roles: ["ROLE_COMPANY_ADMIN", "ROLE_COMPANY_EMPLOYEE", "ROLE_COMPANY_HIRING_MANAGER"],
    invisible: true
  },
  {
    path: "/jobs\\/:page(\\d+)?",
    name: "Jobs",
    icon: Assignment,
    component: JobList,
    layout: "/company",
    roles: ["ROLE_COMPANY_ADMIN", "ROLE_COMPANY_EMPLOYEE", "ROLE_COMPANY_HIRING_MANAGER"]
  },
  {
    path: "/candidates/show/:id",
    name: "Show Candidate",
    component: CompanyCandidateShow,
    layout: "/company",
    roles: ["ROLE_COMPANY_USER"],
    invisible: true
  },
  {
    path: "/candidates\\/:page(\\d+)?",
    name: "Candidates",
    icon: Person,
    component: CompanyCandidateList,
    layout: "/company",
    roles: ["ROLE_COMPANY_USER"]
  },
  {
    collapse: true,
    name: "Company Settings",
    icon: Place,
    state: "companySettingsCollapse",
    roles: [
      "ROLE_COMPANY_ADMIN",
      "ROLE_COMPANY_EMPLOYEE",
    ],
    views: [
      {
        path: "/profile",
        name: "Company Profile",
        icon: Business,
        component: CompanyProfileUpdate,
        layout: "/company",
        roles: ["ROLE_COMPANY_ADMIN"]
      },
      {
        path: "/users/create",
        name: "Create User",
        component: CompanyUserCreate,
        layout: "/company",
        roles: ["ROLE_COMPANY_ADMIN", "ROLE_COMPANY_EMPLOYEE"],
        invisible: true
      },
      {
        path: "/users/edit/:id",
        name: "Update User",
        component: CompanyUserUpdate,
        layout: "/company",
        roles: ["ROLE_COMPANY_ADMIN", "ROLE_COMPANY_EMPLOYEE"],
        invisible: true
      },
      {
        path: "/users/show/:id",
        name: "Show User",
        component: CompanyUserShow,
        layout: "/company",
        roles: ["ROLE_COMPANY_ADMIN", "ROLE_COMPANY_EMPLOYEE"],
        invisible: true
      },
      {
        path: "/users\\/:page(\\d+)?",
        name: "Users",
        icon: Accessibility,
        component: CompanyUserList,
        layout: "/company",
        roles: ["ROLE_COMPANY_ADMIN", "ROLE_COMPANY_EMPLOYEE"],
      },
      {
        path: "/teams/create",
        name: "Create Team",
        component: TeamCreate,
        layout: "/company",
        roles: ["ROLE_COMPANY_ADMIN"],
        invisible: true
      },
      {
        path: "/teams/edit/:id",
        name: "Update Team",
        component: TeamUpdate,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE"
        ],
        invisible: true
      },
      {
        path: "/teams/show/:id",
        name: "Show Team",
        component: TeamShow,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/teams\\/:page(\\d+)?",
        name: "Teams",
        icon: GroupWork,
        component: TeamList,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
      },
    ]
  },
  {
    collapse: true,
    name: "Data Management",
    icon: Image,
    state: "dataManagementCollapse",
    roles: [
      "ROLE_COMPANY_ADMIN",
      "ROLE_COMPANY_EMPLOYEE",
    ],
    views: [
      {
        path: "/question-banks/create",
        name: "Create Video Question Bank",
        component: QuestionBankCreate,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/question-banks/edit/:id",
        name: "Update Video Question Bank",
        component: QuestionBankUpdate,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/question-banks/show/:id",
        name: "Show Video Question Bank",
        component: QuestionBankShow,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/question-banks\\/:page(\\d+)?",
        name: "Video Question Bank",
        icon: VideoLibrary,
        component: QuestionBankList,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
      },
      {
        path: "/question-bank-categories/create",
        name: "Create Video Question Category",
        component: QuestionBankCategoryCreate,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/question-bank-categories/edit/:id",
        name: "Update Video Question Category",
        component: QuestionBankCategoryUpdate,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/question-bank-categories/show/:id",
        name: "Show Video Question Category",
        component: QuestionBankCategoryShow,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/question-bank-categories\\/:page(\\d+)?",
        name: "Video Question Categories",
        icon: SlowMotionVideo,
        component: QuestionBankCategoryList,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
      },
      {
        path: "/knockout-question-banks/create",
        name: "Create Screening Question Bank",
        component: KnockoutQuestionBankCreate,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/knockout-question-banks/edit/:id",
        name: "Update Screening Question Bank",
        component: KnockoutQuestionBankUpdate,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/knockout-question-banks/show/:id",
        name: "Show Screening Question Bank",
        component: KnockoutQuestionBankShow,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/knockout-question-banks\\/:page(\\d+)?",
        name: "Screening Question Bank",
        icon: LiveHelp,
        component: KnockoutQuestionBankList,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
      },
      {
        path: "/knockout-question-bank-categories/create",
        name: "Create Screening Question Category",
        component: KnockoutQuestionBankCategoryCreate,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/knockout-question-bank-categories/edit/:id",
        name: "Update Screening Question Category",
        component: KnockoutQuestionBankCategoryUpdate,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/knockout-question-bank-categories/show/:id",
        name: "Show Screening Question Category",
        component: KnockoutQuestionBankCategoryShow,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/knockout-question-bank-categories\\/:page(\\d+)?",
        name: "Screening Question Categories",
        icon: Category,
        component: KnockoutQuestionBankCategoryList,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
      },
      {
        path: "/request-templates/create",
        name: "Create Interview Template",
        component: RequestTemplateCreate,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/request-templates/edit/:id",
        name: "Update Interview Template",
        component: RequestTemplateUpdate,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/request-templates/show/:id",
        name: "Show Interview Template",
        component: RequestTemplateShow,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/request-templates\\/:page(\\d+)?",
        name: "Interview Templates",
        icon: Extension,
        component: RequestTemplateList,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
      },
      {
        path: "/tags/create",
        name: "Create Tag",
        component: TagCreate,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/tags/edit/:id",
        name: "Update Tag",
        component: TagUpdate,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/tags/show/:id",
        name: "Show Tag",
        component: TagShow,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/tags\\/:page(\\d+)?",
        name: "Tags",
        icon: Label,
        component: TagList,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
      },
      {
        path: "/presenters/create",
        name: "Create Presenter",
        component: PresenterCreate,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/presenters/edit/:id",
        name: "Update Presenter",
        component: PresenterUpdate,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/presenters/show/:id",
        name: "Show Presenter",
        component: PresenterShow,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
        invisible: true
      },
      {
        path: "/presenters\\/:page(\\d+)?",
        name: "Presenters",
        icon: Slideshow,
        component: PresenterList,
        layout: "/company",
        roles: [
          "ROLE_COMPANY_ADMIN",
          "ROLE_COMPANY_EMPLOYEE",
        ],
      },
      {
        path: "/email-templates/show/:id",
        name: "Show Email Templates",
        component: EmailTemplateShow,
        layout: "/company",
        roles: ["ROLE_COMPANY_USER"],
        invisible: true
      },
      {
        path: "/email-templates/:page",
        name: "Email Templates",
        component: EmailTemplateList,
        layout: "/company",
        roles: ["ROLE_COMPANY_USER"],
        invisible: true
      },
      {
        path: "/email-templates/",
        name: "Email Templates",
        icon: Drafts,
        component: EmailTemplateList,
        layout: "/company",
        roles: ["ROLE_COMPANY_USER"]
      },
      {
        path: "/review-scales/",
        name: "Review Scales",
        icon: StarHalf,
        component: ReviewScaleList,
        layout: "/company",
        roles: ["ROLE_COMPANY_ADMIN"]
      },
    ]
  },
  {
    collapse: true,
    name: "My Profile",
    icon: Person,
    state: "myProfileCollapse",
    roles: ["ROLE_COMPANY_USER"],
    views: [
      {
        path: "/user-profile",
        name: "Edit Profile",
        icon: Edit,
        component: UserProfileUpdate,
        layout: "/company",
        roles: ["ROLE_COMPANY_USER"]
      },
      {
        path: "/change-password",
        name: "Change Password",
        icon: VpnKey,
        component: UserChangePassword,
        layout: "/company",
        roles: ["ROLE_COMPANY_USER"]
      },
    ]
  },
  {
    collapse: true,
    name: "Reports",
    icon: DateRange,
    state: "reportsCollapse",
    roles: ["ROLE_COMPANY_USER"],
    views: [
      {
        path: "/dashboard",
        name: "Dashboard",
        icon: Timeline,
        component: CompanyDashboard,
        layout: "/company",
        roles: ["ROLE_COMPANY_ADMIN", "ROLE_COMPANY_EMPLOYEE"],
      },
      {
        path: "/emails/show/:id",
        name: "Show Email",
        component: EmailShow,
        layout: "/company",
        roles: ["ROLE_COMPANY_ADMIN", "ROLE_COMPANY_EMPLOYEE"],
        invisible: true
      },
      {
        path: "/emails\\/:page(\\d+)?",
        name: "Emails",
        icon: Email,
        component: EmailList,
        layout: "/company",
        roles: ["ROLE_COMPANY_ADMIN", "ROLE_COMPANY_EMPLOYEE"]
      },
      {
        path: "/login-histories\\/:page(\\d+)?",
        name: "Login History",
        icon: History,
        component: LoginHistoryList,
        layout: "/company",
        roles: ["ROLE_COMPANY_USER"]
      },
    ]
  },
  // candidate routes
  {
    path: "/dashboard",
    name: "Candidate Dashboard",
    mini: "DB",
    component: CandidateDashboardPage,
    layout: "/candidate",
    roles: ["ROLE_CANDIDATE"]
  },
  {
    collapse: true,
    name: "On Demand Interviews",
    icon: QuestionAnswer,
    state: "oneWayInterviewsCollapse",
    roles: [
      "ROLE_CANDIDATE",
    ],
    views: [
      {
        path: "/invitations\\/:page(\\d+)?",
        name: "Invitations",
        icon: InsertInvitation,
        component: CandidateOneWayInterviewInvitationList,
        layout: "/candidate",
        roles: ["ROLE_CANDIDATE"]
      },
      {
        path: "/on-demand-interviews\\/:page(\\d+)?",
        name: "Interview Responses",
        icon: AssignmentTurnedIn,
        component: CandidateOneWayInterviewList,
        layout: "/candidate",
        roles: ["ROLE_CANDIDATE"],
      },
      //invisible items below
      {
        path: "/on-demand-interviews/show/:id",
        name: "Show Interview Responses",
        component: CandidateOneWayInterviewShow,
        layout: "/candidate",
        roles: ["ROLE_CANDIDATE"],
        invisible: true
      },
      {
        path: "/invitations/show/:id",
        name: "Show Interview Invitation",
        invisible: true,
        component: CandidateOneWayInterviewInvitationShow,
        layout: "/candidate",
        roles: ["ROLE_CANDIDATE"]
      },
    ]
  },
  {
    collapse: true,
    name: "Live Interviews",
    icon: Cloud,
    state: "liveInterviewsCollapse",
    roles: [
      "ROLE_CANDIDATE",
    ],
    views: [
      {
        path: "/live-interview-schedules\\/:page(\\d+)?",
        name: "Schedule",
        icon: Schedule,
        component: CandidateLiveInterviewSchedule,
        layout: "/candidate",
        roles: ["ROLE_CANDIDATE"]
      },
      {
        path: "/live-interviews\\/:page(\\d+)?",
        name: "Completed Live Interviews",
        icon: CheckCircle,
        component: CandidateLiveInterviewList,
        layout: "/candidate",
        roles: ["ROLE_CANDIDATE"],
      },
      //invisible items below
      {
        path: "/live-interviews/show/:id",
        name: "Show Live Interview",
        component: CandidateLiveInterviewShow,
        layout: "/candidate",
        roles: ["ROLE_CANDIDATE"],
        invisible: true,
        mainPanelClasses: 'layout-gray',
      },
      {
        path: "/live-interview-schedules/show/:id",
        name: "Show Scheduled Live Interview",
        component: CandidateLiveInterviewShow,
        layout: "/candidate",
        roles: ["ROLE_CANDIDATE"],
        invisible: true,
      },

    ],
  },
  {
    path: "/companies/show/:id",
    name: "Company Profile",
    invisible: true,
    component: CandidateCompanyShow,
    layout: "/candidate",
    roles: ["ROLE_CANDIDATE"]
  },
  {
    layout: "/candidate",
    path: "/change-password",
    name: "Change Password",
    icon: VpnKey,
    component: UserChangePassword,
    roles: ["ROLE_CANDIDATE"],
    invisible: true
  },
  {
    layout: "/candidate",
    path: "/profile",
    name: "User Profile",
    component: UserProfileUpdate,
    roles: ["ROLE_CANDIDATE"],
    invisible: true
  },
  {
    collapse: true,
    name: "Tools",
    icon: PermDataSetting,
    state: "reportsCollapse",
    roles: ["ROLE_CANDIDATE"],
    invisible: !siteUrls.resumeReviewSalesUrl && !siteUrls.resumeRewritingSalesUrl && !siteUrls.interviewTrainingSalesUrl && !siteUrls.resumeDistributionSalesUrl,
    views: [
      {
        path: siteUrls.resumeReviewSalesUrl,
        name: "Free Resume Review",
        icon: RateReview,
        component: CandidateDashboardPage,
        layout: "/candidate",
        roles: ["ROLE_CANDIDATE"],
        invisible: !siteUrls.resumeReviewSalesUrl,
        external: true,
      },
      {
        path: siteUrls.resumeRewritingSalesUrl,
        name: "Resume Rewriting",
        icon: AssignmentInd,
        component: CandidateDashboardPage,
        layout: "/candidate",
        roles: ["ROLE_CANDIDATE"],
        invisible: !siteUrls.resumeRewritingSalesUrl,
        external: true,
      },
      {
        path: siteUrls.interviewTrainingSalesUrl,
        name: "Interview Training",
        icon: AssistantPhoto,
        component: CandidateDashboardPage,
        layout: "/candidate",
        roles: ["ROLE_CANDIDATE"],
        invisible: !siteUrls.interviewTrainingSalesUrl,
        external: true,
      },
      {
        path: siteUrls.resumeDistributionSalesUrl,
        name: "Resume Distribution",
        icon: DeviceHub,
        component: CandidateDashboardPage,
        layout: "/candidate",
        roles: ["ROLE_CANDIDATE"],
        invisible: !siteUrls.resumeDistributionSalesUrl,
        external: true,
      },
    ]
  },
  {
    collapse: true,
    name: "My Profile",
    icon: Person,
    state: "myProfileCollapse",
    roles: ["ROLE_CANDIDATE"],
    views: [
      {
        path: "/view-profile",
        name: "View Profile",
        icon: Visibility,
        component: CandidateViewProfile,
        layout: "/candidate",
        roles: ["ROLE_CANDIDATE"]
      },
      {
        path: "/user-profile",
        name: "Edit Profile",
        icon: Edit,
        component: CandidateUpdateProfile,
        layout: "/candidate",
        roles: ["ROLE_CANDIDATE"]
      },
      {
        path: "/change-password",
        name: "Change Password",
        icon: VpnKey,
        component: UserChangePassword,
        layout: "/candidate",
        roles: ["ROLE_CANDIDATE"]
      },
    ]
  },
  // sales/public routes
  {
    path: "login",
    name: "Login",
    mini: "L",
    component: LoginPage,
    layout: "/",
    invisible: true
  },
  {
    path: "logout",
    name: "Logout",
    icon: ExitToApp,
    mini: "L",
    component: Logout,
    layout: "/",
    invisible: false
  },
  {
    path: "password-recovery",
    name: "Password Recovery",
    component: PasswordRecoveryPage,
    layout: "/",
    invisible: true
  },
  {
    path: "password-reset/:token",
    name: "Reset Password",
    component: PasswordResetPage,
    layout: "/",
    invisible: true
  },
  {
    path: "/:roomid(\\d+)/:step?",
    name: "On Demand Interview",
    component: OnDemandInterviewByCodePage,
    layout: "/on-demand-interview",
    invisible: true
  },
  {
    path: "/invitation/:code/:step?",
    name: "On Demand Interview Invitation",
    component: OnDemandInterviewByInvitationPage,
    layout: "/on-demand-interview",
    invisible: true
  },
  {
    path: "/invitation/:code",
    name: "Live Interview Invitation",
    component: LiveInterviewByInvitationPage,
    layout: "/live-interview",
    invisible: true
  },
  {
    path: "/:roomid",
    name: "Live Interview",
    mini: "IR",
    component: LiveInterviewByCodePage,
    layout: "/live-interview",
    invisible: true
  },
  {
    path: "/live-interview-reviews/:interview/review/:review",
    name: "Live Interview Review",
    component: LiveInterviewReviewEdit,
    layout: "/company",
    roles: ["ROLE_COMPANY_USER"],
    invisible: true
  },
  {
    path: "/live-interview-reviews/:interview/review",
    name: "Live Interview Review",
    component: LiveInterviewReviewCreate,
    layout: "/company",
    roles: ["ROLE_COMPANY_USER"],
    invisible: true
  },
  {
    path: "/live-interview-reviews/:interview/reviews",
    name: "Live Interview Reviews List",
    component: LiveInterviewReviewList,
    layout: "/company",
    roles: ["ROLE_COMPANY_USER"],
    invisible: true
  },
  {
    path: "/live-interviews/access/:interview",
    name: "Live Interview Shared Users",
    component: LiveInterviewAccessList,
    layout: "/company",
    roles: ["ROLE_COMPANY_USER"],
    invisible: true
  },
  {
    path: "resume-review",
    name: "Resume Review",
    mini: "R",
    component: ResumeReviewPage,
    layout: "/",
    invisible: true
  },
  {
    path: "resume-distribution",
    name: "Resume Distribution",
    mini: "R",
    component: ResumeDistributionPage,
    layout: "/",
    invisible: true
  },
  {
    path: "interview-training",
    name: "Interview Training",
    mini: "R",
    component: InterviewTrainingPage,
    layout: "/",
    invisible: true
  },
  {
    path: "",
    name: "Home",
    mini: "H",
    component: HomePage,
    layout: "/",
    invisible: true
  },
  {
    path: "register/candidate/:code?",
    name: "Candidate Registration",
    mini: "R",
    component: CandidateRegisterPage,
    layout: "/",
    invisible: true
  },
  {
    path: "terms-of-service",
    name: "Terms of Service",
    mini: "R",
    component: SiteSettingsPreview,
    layout: "/",
    invisible: true
  },
  {
    path: "privacy-policy",
    name: "Privacy Policy",
    mini: "R",
    component: SiteSettingsPreview,
    layout: "/",
    invisible: true
  },
  {
    path: "/on-demand-interview/:token",
    name: "On Demand Interview Preview",
    component: OneWayInterviewPreview,
    layout: "/embed",
    invisible: true
  },
  {
    path: "/on-demand-interview/:token",
    name: "On Demand Interview Preview",
    component: OneWayInterviewShareLink,
    layout: "/share",
    invisible: true
  },
  {
    path: "/live-interview/:token",
    name: "Live Interview Preview",
    component: LiveInterviewShareLink,
    layout: "/share",
    invisible: true
  },
  {
    path: "/request/:roomid",
    name: "Interview",
    component: OnDemandInterviewByCodePage,
    layout: "/embed",
    invisible: true
  }
];

  if (site.companyRegistrationEnabled) {
    routes.push(
      {
        path: "register/company",
        name: "Company Registration",
        mini: "R",
        component: CompanyRegisterPage,
        layout: "/",
        invisible: true
      },
    );
  }

  return routes;
};

export default getRoutes;
