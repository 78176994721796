import {fetchJSON} from "../../utils/dataAccess";

export function loadPartnersForSelect(valueProperty = 'id') {
  return dispatch => {
    dispatch(loading(true));
    fetchJSON('/partners?pagination=false').then((partners) => {
      dispatch(partnersLoaded(partners['hydra:member'].map(
        function (partner) {
          return {
            value: partner[valueProperty],
            text: partner.name,
          }
        }
      )));
      dispatch(loading(false));
    }).catch(e => {
      dispatch(loading(false));
      dispatch(error(e.message));
    })
  };
}

export function loading(loading) {
  return {type: 'PARTNER_FOR_SELECT_LOADING', loading};
}

export function error(error) {
  return {type: 'PARTNER_FOR_SELECT_ERROR', error};
}

export function partnersLoaded(retrieved) {
  return {type: 'PARTNER_FOR_SELECT_RETRIEVED', retrieved};
}
