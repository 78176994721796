import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";
import InvitationInterviewStatusForm from "./Form";

class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  componentDidMount() {
  }

  render() {
    const YesNoChoices = [
      {text: 'Yes', value: true},
      {text: 'No',  value: false},
    ];

    return (
      <RenderFilterForm {...this.props} fields={[
        {'name': 'id', 'label': 'ID', 'placeholder': 'ID', 'type': 'number'},
        {'name': 'displayedInterviewStatus', 'label': 'Displayed Interview Status', 'placeholder': 'Displayed Interview Status', 'type': 'selectSearch', 'options': InvitationInterviewStatusForm.displayedInterviewStatus},
        {'name': 'displayedInterviewProgressbarStatus', 'label': 'Displayed Interview Progressbar Status', 'placeholder': 'Displayed Interview Progressbar Status', 'type': 'selectSearch', 'options': InvitationInterviewStatusForm.displayedInterviewProgressbarStatus},
        {'name': 'jobStatus', 'label': 'Job Status', 'placeholder': 'Job Status', 'type': 'selectSearch', 'options': InvitationInterviewStatusForm.jobStatuses},
        {'name': 'requestStatus', 'label': 'Request Status', 'placeholder': 'Request Status', 'type': 'selectSearch', 'options': InvitationInterviewStatusForm.requestStatuses},
        {'name': 'invitationStatus', 'label': 'Invitation Status', 'placeholder': 'Invitation Status', 'type': 'selectSearch', 'options': InvitationInterviewStatusForm.invitationStatuses},
        {'name': 'invitationExpired', 'label': 'Invitation Expired', 'placeholder': 'Invitation Expired', 'type': 'selectSearch', 'options': YesNoChoices},
        {'name': 'invitationArchived', 'label': 'Invitation Archived', 'placeholder': 'Invitation Archived', 'type': 'selectSearch', 'options': YesNoChoices},
        {'name': 'interviewStarted', 'label': 'Interview Started', 'placeholder': 'Interview Started', 'type': 'selectSearch', 'options': YesNoChoices},
        {'name': 'interviewCompleted', 'label': 'Interview Completed', 'placeholder': 'Interview Completed', 'type': 'selectSearch', 'options': YesNoChoices},
      ]} />
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({
});

FilterForm = reduxForm({
  form: 'filter_invitation_interview_statuses',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterForm);
