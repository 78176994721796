import { SubmissionError } from 'redux-form';
import {
  fetch,
  extractHubURL,
  normalize,
} from '../../utils/dataAccess';
import storage from "../../utils/storage";

export function acceptError(acceptError) {
  return { type: 'SITE_SETTINGS_ACCEPT_ERROR', acceptError };
}

export function acceptLoading(acceptLoading) {
  return { type: 'SITE_SETTINGS_ACCEPT_LOADING', acceptLoading };
}

export function acceptSuccess(accepted) {
  return { type: 'SITE_SETTINGS_ACCEPT_SUCCESS', accepted };
}

export function accept(history) {
  return dispatch => {
    dispatch(acceptError(null));
    dispatch(acceptLoading(true));

    return fetch('/site_settings/accept', {
      method: 'PATCH',
    })
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        retrieved = normalize(retrieved);

        dispatch(acceptLoading(false));
        dispatch(acceptSuccess(retrieved));
        storage.set('site-settings-accepted-at', retrieved['updatedAtTimestamp']);

        let redirectUrl = storage.get('redirect-after-terms-accept-url');
        if (redirectUrl) {
          storage.remove('redirect-after-terms-accept-url');
          history.push(redirectUrl);
        }
      })
      .catch(e => {
        dispatch(acceptLoading(false));

        if (e instanceof SubmissionError) {
          dispatch(acceptError(e.errors._error));
          throw e;
        }

        dispatch(acceptError(e.message));
      });
  };
}

export function resetAccept() {
  return dispatch => {
    dispatch(acceptError(null));
    dispatch(acceptLoading(false));
    dispatch(acceptLoading(null));
  }
}

