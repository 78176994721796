import React from "react";

import {connect} from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import {currentSiteSettings} from "../../actions/SiteSettings/currentSiteSettings";
import {TYPE_CONTENT, TYPE_URL} from "../../components/SiteSettings/Form";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import "./css/SiteSettings.scss"
import {accept} from "../../actions/SiteSettings/accept";
import {withRouter} from "react-router";
import storage from "../../utils/storage";
import {currentUserHasRole} from "../../utils/auth";

class SiteSettingsBox extends React.Component {
    state = {
        privacyPolicyAccepted: false,
        termsOfServiceAccepted: false,
        privacyPolicyModalOpen: false,
        termsOfServiceModalOpen: false,
    }

    componentDidMount() {
        if (!this.props.retrieved) {
            this.props.currentSiteSettings();
        }
    }

    onAccept = () => {
        if (this.state.termsOfServiceAccepted && this.state.privacyPolicyAccepted) {
            this.props.accept(this.props.history);
        }
    }

    togglePrivacyPolicyModal = (e) => {
        if (e) {
            e.preventDefault();
        }

        this.setState({privacyPolicyModalOpen: !this.state.privacyPolicyModalOpen});
    }

    toggleTermsOfServiceModal = (e) => {
        if (e) {
            e.preventDefault();
        }

        this.setState({termsOfServiceModalOpen: !this.state.termsOfServiceModalOpen});
    }

    render() {
        const settings = this.props.retrieved;

        return (
            <div>
                <Dialog
                    onClose={this.togglePrivacyPolicyModal}
                    open={!this.props.accepted}
                    fullWidth={true}
                    maxWidth="md"
                >
                    <DialogContent className={"site-settings-box"}>
                        {(this.props.loading || this.props.acceptLoading) && (<div className={"alert alert-info"}>Loading...</div>)}
                        {this.props.retrieved && (
                            <div className={"site-settings-content"}>
                                <h4 className={'text-center'}>Terms of Service and Privacy Policy</h4>
                                {settings && (<>
                                    {settings.termsOfServiceType === TYPE_CONTENT && (
                                        <div className={"site-settings-text-container"}>
                                            <div className={"site-settings-text-title"}>Terms of Service</div>
                                            <div className={"ql-editor"} dangerouslySetInnerHTML={{__html: settings.termsOfServiceContent}} />
                                        </div>
                                    )}
                                    <p className={"site-settings-accept"}>
                                        <label htmlFor={"site-settings-terms-checkbox"}>
                                            <Checkbox
                                                id={"site-settings-terms-checkbox"}
                                                checked={this.state.termsOfServiceAccepted}
                                                onChange={() => {this.setState({termsOfServiceAccepted: !this.state.termsOfServiceAccepted})}}
                                                className={"site-settings-checkbox"}
                                            />
                                            I have read and agree to the {settings.termsOfServiceType === TYPE_URL ?
                                            (<a target="_blank" href={settings.termsOfServiceUrl}>Terms of Service</a>) :
                                             'Terms of Service' }
                                        </label>
                                        <div className={"clearfix"} />
                                    </p>

                                    {settings.privacyPolicyType === TYPE_CONTENT && (
                                        <div className={"site-settings-text-container"}>
                                            <div className={"site-settings-text-title"}>Privacy Policy</div>
                                            <div className={"ql-editor"} dangerouslySetInnerHTML={{__html: settings.privacyPolicyContent}} />
                                        </div>
                                    )}
                                    <p className={"site-settings-accept"}>
                                        <label htmlFor={"site-settings-privacy-checkbox"}>
                                            <Checkbox
                                                id={"site-settings-privacy-checkbox"}
                                                checked={this.state.privacyPolicyAccepted}
                                                onChange={() => {this.setState({privacyPolicyAccepted: !this.state.privacyPolicyAccepted})}}
                                                className={"site-settings-checkbox"}
                                            />
                                            I have read and agree to the {settings.privacyPolicyType === TYPE_URL ?
                                            (<a target="_blank" href={settings.privacyPolicyUrl}>Privacy Policy</a>) :
                                            'Privacy Policy' }
                                        </label>
                                        <div className={"clearfix"} />
                                    </p>

                                    <button
                                        disabled={!this.state.privacyPolicyAccepted || !this.state.termsOfServiceAccepted}
                                        className={'btn btn-success'}
                                        onClick={this.onAccept}
                                    >
                                        Submit
                                    </button>
                                </>)}
                            </div>
                        )}
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export function showSiteSettingsBox()
{
    return storage.get('site') &&
        storage.get('site')['site-settings-version'] > storage.get('site-settings-accepted-at') &&
        !storage.get('X-Switch-User') &&
        !currentUserHasRole('ROLE_SUPER_ADMIN') && !currentUserHasRole('ROLE_PARTNER_ADMIN');
}

const mapStateToProps = state => ({
    retrieved: state.siteSettings.currentSiteSettings.retrieved,
    loading: state.siteSettings.currentSiteSettings.loading,
    accepted: state.siteSettings.accept.accepted,
    acceptLoading: state.siteSettings.accept.acceptLoading,
});

const mapDispatchToProps = dispatch => ({
    currentSiteSettings: () => dispatch(currentSiteSettings()),
    accept: (history) => dispatch(accept(history)),
});

SiteSettingsBox = withRouter(SiteSettingsBox);

export default SiteSettingsBox = connect(
    mapStateToProps,
    mapDispatchToProps
)(SiteSettingsBox);
