import React, { Component } from 'react';
import OnDemandInterviewPage from "../../components/OnDemandInterviewPage/OnDemandInterviewPage";
import {initializeByInvitationCode} from "../../actions/OnDemandInterviewPage/page";
import {connect} from "react-redux";

class OnDemandInterviewByInvitationPage extends Component {
  componentDidMount() {
    const invitationCode = this.props.match.params.code;
    this.props.initializeByInvitationCode(invitationCode);
  }

  render() {
    return (
      <OnDemandInterviewPage name={'By Invitation'} />
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  initializeByInvitationCode: (invitationCode) => dispatch(initializeByInvitationCode(invitationCode)),
});

OnDemandInterviewByInvitationPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(OnDemandInterviewByInvitationPage);

export default OnDemandInterviewByInvitationPage;
