import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'LIVEINTERVIEW_ARCHIVE_ERROR', error };
}

export function loading(loading) {
  return { type: 'LIVEINTERVIEW_ARCHIVE_LOADING', loading };
}

export function success(archived) {
  return { type: 'LIVEINTERVIEW_ARCHIVE_SUCCESS', archived };
}

export function archive(item) {
  return dispatch => {
    dispatch(loading(true));
    dispatch(error(null));

    return fetch(item['@id'] + '/archive', { method: 'PUT' })
      .then(() => {
        dispatch(loading(false));
        dispatch(success(item));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.errors));
      });
  };
}

export function reset() {
  return { type: 'LIVEINTERVIEW_ARCHIVE_RESET' };
}
