import { SubmissionError } from 'redux-form';
import {extractHubURL, fetch, normalizeAttributes} from '../../utils/dataAccess';
import { cloneDeep } from "lodash";
import {register as registerUser} from '../../utils/auth';

export function error(error) {
    return { type: 'CANDIDATE_REGISTER_ERROR', error };
}

export function loading(loading) {
    return { type: 'CANDIDATE_REGISTER_LOADING', loading };
}

export function success(registered) {
    return { type: 'CANDIDATE_REGISTER_SUCCESS', registered };
}

export function errorShadowCandidate(error) {
  return { type: 'SHADOW_CANDIDATE_REGISTER_ERROR', error };
}

export function loadingShadowCandidate(loading) {
  return { type: 'SHADOW_CANDIDATE_REGISTER_LOADING', loading };
}

export function successShadowCandidate(retrieved) {
  return { type: 'SHADOW_CANDIDATE_REGISTER_SUCCESS', retrieved };
}

export function register(values) {
    return dispatch => {
        dispatch(loading(true));
        const data = normalizeAttributes(cloneDeep(values), ['resume']);

        return registerUser(data)
            .then(response => {
                dispatch(loading(false));
                dispatch(success(response.data));
            })
            .catch(e => {
                dispatch(loading(false));

                if (e instanceof SubmissionError) {
                    dispatch(error(e.errors._error));
                    throw e;
                }

                dispatch(error(e.message));
            });
    };
}

export function loadShadowCandidate(code) {
  return dispatch => {
    dispatch(loadingShadowCandidate(true));
    dispatch(errorShadowCandidate(''));

    fetch('/shadow_candidates/' + code)
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        dispatch(successShadowCandidate(retrieved));
        dispatch(loadingShadowCandidate(false));
      })
      .catch(e => {
        dispatch(loadingShadowCandidate(false));
        dispatch(errorShadowCandidate(e.message));
      });
  };
}

export function reset() {
    return dispatch => {
        dispatch(loading(false));
        dispatch(error(null));
    };
}
