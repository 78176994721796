import React, {Component} from "react";
import Button from "../CustomButtons/Button";
import CardBody from "../Card/CardBody";
import Card from "../Card/Card";
import VideoQuestionView from "../VideoQuestionPlayer/VideoQuestionView";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";
import './VideoQuestionBankItem.scss';

export default class VideoQuestionBankItem extends Component {
    state = {
        expanded: false,
    };
    toggleExpanded = () => {
        this.setState((prevState) => {
            return {expanded: !prevState.expanded};
        });
    };
    onQuestionAdd = () => {
        this.props.onQuestionAdd(this.props.videoQuestion);
    };

    renderButtons = () => (
        <div className='buttons'>
            <Button size="sm" className='button' onClick={this.toggleExpanded}>
                {this.state.expanded ? (
                    <ExpandLess>Collapse</ExpandLess>
                ) : (
                    <ExpandMore>View</ExpandMore>
                )}
            </Button>
            <Button size="sm" color='success' className='button' onClick={this.onQuestionAdd}>
                <PlaylistAdd>
                    Add
                </PlaylistAdd>
            </Button>
        </div>
    );

    render() {
        let videoQuestion = this.props.videoQuestion;
        return (
            <div className={'video-question-bank-item' + (this.state.expanded ? ' expanded' : '')}>
                {this.state.expanded ? (
                    <Card className="video-question-bank-item-card">
                        <CardBody className="video-question-bank-item-card-body">
                            <VideoQuestionView
                                videoQuestion={videoQuestion}
                                beforeTitle={this.renderButtons()}
                            />
                        </CardBody>
                    </Card>
                ) : (<>
                    {videoQuestion.title}
                    {this.renderButtons()}
                </>)}
            </div>
        );
    }
}
