import React, { Component } from 'react';
import {Field, formValueSelector, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {loadTeamsForSelect} from "../../actions/Team/forSelect";
import {currentUserHasRole} from "../../utils/auth";
import {connect} from "react-redux";
import RequestTemplateWizard from "./RequestTemplateWizard";
import {renderTextField} from "../../utils/form";
import {loadIndustriesForSelect} from "../../actions/Industry/forSelect";
import {loadOccupationsForSelect} from "../../actions/Occupation/forSelect";
import {loadSpecialtiesForSelect} from "../../actions/Specialty/forSelect";
import SearchableSelect from "../../views/Components/SearchableSelect";
import {isValidYoutubeUrl} from "../../utils/embedVideo";
import {copyVideoEntity, isVideoNeedToReEncode} from "../../utils/videoEntity";
import {mercureSubscribe as subscribe, normalize} from "../../utils/dataAccess";
import {MERCURE_ENTRYPOINT} from "../../config/entrypoint";
import {success} from "../../actions/QuestionBank/search";

class RequestTemplateForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.eventSource = null;
    this.subscribeOnChanges(this.getMercureTopics(props));
  }

  getMercureTopics = (props) => {
    const {introVideo, outroVideo, videoQuestions, bankQuestions} = props;
    let topics = [];

    const getVideoTopic = (video) =>  video['videoOrigin'] ? video['videoOrigin'] : video['@id']

    if (introVideo && isVideoNeedToReEncode(introVideo)) {
      topics.push(getVideoTopic(introVideo));
    }

    if (outroVideo && isVideoNeedToReEncode(outroVideo)) {
      topics.push(getVideoTopic(outroVideo));
    }

    if (videoQuestions && videoQuestions.length) {
      videoQuestions.forEach(videoQuestion => {
        if (videoQuestion.questionVideo && isVideoNeedToReEncode(videoQuestion.questionVideo)) {
          topics.push(getVideoTopic(videoQuestion.questionVideo));
        }
      });
    }

    if (bankQuestions && bankQuestions['hydra:member'] && bankQuestions['hydra:member'].length) {
      bankQuestions['hydra:member'].forEach(questionBank => {
        if (questionBank.questionBankVideo && isVideoNeedToReEncode(questionBank.questionBankVideo)) {
          topics.push(questionBank.questionBankVideo['@id']);
        }
      });
    }

    //Return only unique ids
    return [...new Set(topics)];
  }

  componentWillMount() {
    if (currentUserHasRole('ROLE_COMPANY_USER')) {
      const teamFilters = {};
      const initialValues = this.props.initialValues;
      if (initialValues && initialValues.team) {
        teamFilters.withId = initialValues.team;
      }
      this.props.loadTeamsForSelect(teamFilters);
    }
    this.props.loadIndustriesForSelect();
    this.props.loadOccupationsForSelect();
    this.props.loadSpecialtiesForSelect();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevTopics = this.getMercureTopics(prevProps);
    const curTopics = this.getMercureTopics(this.props);
    if (prevTopics.length !== curTopics.length || JSON.stringify(prevTopics) !== JSON.stringify(curTopics)) {
      this.closeEventSource();
      this.subscribeOnChanges(curTopics);
    }
  }

  closeEventSource = () => {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }
  }

  subscribeOnChanges = (topics) => {
    if (topics.length === 0) {
      return;
    }
    this.eventSource = subscribe(new URL(MERCURE_ENTRYPOINT), topics);

    this.eventSource.addEventListener('message', event => {
      const data = normalize(JSON.parse(event.data));
      const type = (data && data['@type']) ? data['@type'] : null;

      switch (type) {
        case 'RequestIntroVideo':
          this.props.change('request[requestIntroVideo]', copyVideoEntity(data));
          break;
        case 'RequestOutroVideo':
          this.props.change('request[requestOutroVideo]', copyVideoEntity(data));
          break;
        case 'QuestionVideo':
          const questions = this.props.videoQuestions.map(question => {
            if (question && question.questionVideo && (
              question.questionVideo['videoOrigin'] === data['@id'] ||
              question.questionVideo['@id'] === data['@id']
            )) {
              return {...question, questionVideo: copyVideoEntity(data)};
            } else {
              return question;
            }
          });
          this.props.change('request[questions]', questions);
          break;
        case 'QuestionBankVideo':
          const bankQuestions = this.props.bankQuestions;
          if (bankQuestions && bankQuestions['hydra:member'] && bankQuestions['hydra:member'].length) {
            const newBankQuestions = {
              ...bankQuestions,
              'hydra:member': bankQuestions['hydra:member'].map(questionBank => {
                if (questionBank.questionBankVideo && questionBank.questionBankVideo['@id'] === data['@id']) {
                  return {...questionBank, questionBankVideo: data};
                } else {
                  return questionBank;
                }
              })
            };
            this.props.updateSearch(newBankQuestions);

            const questions = this.props.videoQuestions.map(question => {
              if (question && question.questionVideo && question.questionVideo['videoOrigin'] === data['@id']) {
                return {...question, questionVideo: copyVideoEntity(data)};
              } else {
                return question;
              }
            });
            this.props.change('request[questions]', questions);
          }
          break;
      }
    });
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={renderTextField}
          name="title"
          type="text"
          placeholder="Template title"
          label="Template title"
          required={true}
        />
        {this.props.teams && (
          <Field
            component={SearchableSelect}
            name="team"
            label="Team"
            placeholder="Team"
            options={this.props.teams}
          />
        )}
        <RequestTemplateWizard
          knockoutQuestions={this.props.knockoutQuestions}
          videoQuestions={this.props.videoQuestions}
          change={this.props.change}
          handleSubmit={this.props.handleSubmit}
          initialValues={this.props.initialValues}
        />
      </form>
    );
  }
}

const selector = formValueSelector('requestTemplate');
const mapStateToProps = state => ({
    teams: state.team.forSelect.retrieved,
    knockoutQuestions: selector(state, 'request[knockoutQuestions]') || [],
    videoQuestions: selector(state, 'request[questions]') || [],
    introVideo: selector(state, 'request[requestIntroVideo]'),
    outroVideo: selector(state, 'request[requestOutroVideo]'),
    bankQuestions: state.questionbank.search.retrieved,
});

const mapDispatchToProps = dispatch => ({
    loadTeamsForSelect: (filters) => dispatch(loadTeamsForSelect('@id', filters)),
    loadIndustriesForSelect: () => dispatch(loadIndustriesForSelect('@id')),
    loadOccupationsForSelect: () => dispatch(loadOccupationsForSelect('@id')),
    loadSpecialtiesForSelect: () => dispatch(loadSpecialtiesForSelect('@id')),
    updateSearch: (newBankQuestions) => dispatch(success(newBankQuestions)),
});

const validate = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = 'This field is required';
  }

  errors.request = {};
  if (!('request' in values) || !values.request.title) {
    errors.request = {title: 'This field should not be blank.'};
  }

  if (values.request && values.request.requestIntroVideoUrl && !isValidYoutubeUrl(values.request.requestIntroVideoUrl)) {
    errors['request']['requestIntroVideoUrl'] = 'YouTube URL is not valid.';
  }
  if (values.request && values.request.requestOutroVideoUrl && !isValidYoutubeUrl(values.request.requestOutroVideoUrl)) {
    errors['request']['requestOutroVideoUrl'] = 'YouTube URL is not valid.';
  }

  return errors;
};

RequestTemplateForm = reduxForm({
  validate,
  form: 'requestTemplate',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(RequestTemplateForm);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RequestTemplateForm);
