import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import List from "./List";
import Form from "./Form";

export default class CommentsModal extends React.Component {
  state = {
    showForm: false,
    showFormComment: null,
  };

  static propTypes = {
    oneWayInterview: PropTypes.object.isRequired,
  };

  setShowForm = (showForm, showFormComment = null) => {
    this.setState({
      showForm: showForm,
      showFormComment: showFormComment,
    })
  };

  render() {
    return (
      <div>
        <Dialog
          open={true}
          onClose={this.props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogTitle id="alert-dialog-title">Comments <i className="fa fa-close float-right comments-modal-close" onClick={this.props.handleClose} /></DialogTitle>
          <DialogContent>
            {this.state.showForm && (
              <Form
                setShowForm={this.setShowForm}
                oneWayInterview={this.props.oneWayInterview}
                initialValues={{...this.state.showFormComment, ...{oneWayInterview: this.props.oneWayInterview['@id']}}} />
            )}
            {!this.state.showForm && (
              <List setShowForm={this.setShowForm} oneWayInterview={this.props.oneWayInterview} />
            )}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

