import React, {Component} from 'react';
import {Stepper} from "@material-ui/core";
import {connect} from "react-redux";
import classNames from 'classnames';
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {
  calculateQuestionsCount,
  getVideoQuestionsCount,
  isInterviewStarted,
  isInvitationDeclined
} from "../../utils/onDemandInterview";

export const STEP_INFO = 'info';
export const STEP_ABOUT = 'about';
export const STEP_TEST = 'test';
export const STEP_QUESTIONS = 'questions';

class OnDemandInterviewSteps extends Component {
  getSteps = () => {
    const {request, interview} = this.props;
    const questionsCount = calculateQuestionsCount(request);
    const answers = interview ? interview.knockoutAnswersCount + interview.questionAnswersCount : 0;
    const isInterviewCompleted = answers === questionsCount;
    let currentQuestionNumber = 0;
    if (interview && isInterviewStarted(interview)) {
      currentQuestionNumber = !isInterviewCompleted ? answers + 1 : questionsCount;
    }
    const steps = [
      {id: STEP_INFO, label: 'About Interview'},
      {id: STEP_ABOUT, label: 'About You'},
    ];

    if (getVideoQuestionsCount(request) > 0) {
      steps.push({id: STEP_TEST, label: 'Test Audio/Video'});
    }

    steps.push({id: STEP_QUESTIONS, label: `Questions (${currentQuestionNumber}/${questionsCount})`, completed: isInterviewCompleted});

    return steps;
  }

  render() {
    const {currentStep, invitation} = this.props;
    const steps = this.getSteps();
    const stepNumber = steps.findIndex((step) => step.id === currentStep);
    return (
      <div className={classNames('on-demand-interview-steps', {
        'on-demand-interview-steps-canceled': isInvitationDeclined(invitation),
      })}>
        <Stepper activeStep={stepNumber >= 0 ? stepNumber : 0} alternativeLabel className={'on-demand-interview-stepper-component'}>
          {steps.map(({id, label, completed}) => {
            const stepProps = {id};
            if (completed) {
              stepProps.completed = true;
            }
            return (
              <Step key={id} {...stepProps}>
                <StepLabel
                  classes={{
                    label: 'on-demand-interview-steps-label',
                  }}
                  StepIconProps={{
                    classes: {
                      active: 'on-demand-interview-steps-active-step',
                      completed: 'on-demand-interview-steps-completed-step'
                    }
                  }}>
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  request: state.onDemandInterviewPage.request,
  interview: state.onDemandInterviewPage.interview,
  invitation: state.onDemandInterviewPage.invitation,
  currentStep: state.onDemandInterviewPage.step,
});

OnDemandInterviewSteps = connect(
  mapStateToProps
)(OnDemandInterviewSteps);

export default OnDemandInterviewSteps;
