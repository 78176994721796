import {applyMiddleware, combineReducers, createStore} from "redux";
import {connectRouter, routerMiddleware} from "connected-react-router";
import {reducer as form} from "redux-form";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";
import { snackbarReducer } from 'react-redux-snackbar'

// Reducers
import liveinterviewcomment from 'reducers/LiveInterviewComment/'
import access from 'reducers/Access';
import candidate from 'reducers/Candidate/';
import candidateCompany from 'reducers/CandidateCompany';
import candidateonewayinterview from 'reducers/CandidateOneWayInterview/';
import candidateonewayinverviewinvitation from 'reducers/CandidateOneWayInterviewInvitation/';
import company from 'reducers/Company/';
import companyCandidate from 'reducers/CompanyCandidate/';
import country from 'reducers/Country/';
import email from 'reducers/Email/';
import emailtemplate from 'reducers/EmailTemplate/';
import forgotPassword from 'reducers/ForgotPassword/';
import companyHiringManager from 'reducers/CompanyHiringManager/';
import companyUser from 'reducers/CompanyUser/';
import companyStatistic from 'reducers/CompanyStatistic/';
import industry from 'reducers/Industry/';
import invitation from 'reducers/Invitation';
import invitationInterviewStatus from 'reducers/InvitationInterviewStatus';
import job from 'reducers/Job/';
import knockoutquestionbank from 'reducers/KnockoutQuestionBank/';
import knockoutquestionbankcategory from 'reducers/KnockoutQuestionBankCategory/';
import liveinterview from 'reducers/LiveInterview/';
import liveInterviewPage from 'reducers/LiveInterviewPage';
import liveinterviewreview from 'reducers/LiveInterviewReview/';
import liveInterviewCandidateInvitation from 'reducers/LiveInterviewCandidateInvitation/';
import liveinterviewsharelink from 'reducers/LiveInterviewShareLink/';
import loginhistory from 'reducers/LoginHistory/';
import occupation from 'reducers/Occupation/';
import onDemandInterviewPage from 'reducers/OnDemandInterviewPage';
import onewayinterview from 'reducers/OneWayInterview/';
import onewayinterviewreview from 'reducers/OneWayInterviewReview/';
import onewayinterviewcomment from 'reducers/OneWayInterviewComment/';
import onewayinterviewwatchtoken from 'reducers/OneWayInterviewWatchToken/';
import onewayinterviewsharelink from 'reducers/OneWayInterviewShareLink/';
import order from 'reducers/Order/';
import partner from 'reducers/Partner/';
import partneradmin from 'reducers/PartnerAdmin/';
import paymentmethod from 'reducers/PaymentMethod/';
import plan from 'reducers/Plan/';
import presenter from 'reducers/Presenter/';
import questionbank from 'reducers/QuestionBank/';
import questionbankcategory from 'reducers/QuestionBankCategory/';
import request from 'reducers/Request/';
import requesttemplate from 'reducers/RequestTemplate/';
import reviewScale from 'reducers/ReviewScale/';
import screen from 'reducers/Screen/';
import siteDetails from 'reducers/SiteDetails/';
import siteSettings from 'reducers/SiteSettings/';
import specialty from 'reducers/Specialty/';
import state from 'reducers/State/';
import subscription from 'reducers/Subscription/';
import superadmin from 'reducers/SuperAdmin/';
import tag from 'reducers/Tag/';
import team from 'reducers/Team/';
import teamCompanyUser from 'reducers/TeamCompanyUser/';
import ticket from 'reducers/Ticket/';
import transaction from 'reducers/Transaction/';
import user from 'reducers/User/';
import userProfile from 'reducers/UserProfile/';
import {createBrowserHistory} from "history";

export let history = createBrowserHistory();

const appReducer = combineReducers({
    router: connectRouter(history),
    form,
    snackbar: snackbarReducer,
    // Reducers
    access,
    candidate,
    candidateCompany,
    candidateonewayinterview,
    candidateonewayinverviewinvitation,
    company,
    companyCandidate,
    country,
    email,
    emailtemplate,
    companyHiringManager,
    companyUser,
    companyStatistic,
    forgotPassword,
    industry,
    invitation,
    invitationInterviewStatus,
    job,
    knockoutquestionbank,
    knockoutquestionbankcategory,
    liveinterview,
    liveInterviewPage,
    liveinterviewreview,
    liveinterviewcomment,
    liveinterviewsharelink,
    liveInterviewCandidateInvitation,
    loginhistory,
    occupation,
    onDemandInterviewPage,
    onewayinterview,
    onewayinterviewreview,
    onewayinterviewcomment,
    onewayinterviewwatchtoken,
    onewayinterviewsharelink,
    order,
    partner,
    partneradmin,
    paymentmethod,
    plan,
    presenter,
    questionbank,
    questionbankcategory,
    request,
    requesttemplate,
    reviewScale,
    siteDetails,
    siteSettings,
    specialty,
    state,
    subscription,
    superadmin,
    screen,
    tag,
    team,
    teamCompanyUser,
    ticket,
    transaction,
    user,
    userProfile,
});

const rootReducer = (state, action) => {
    if (action.type === 'ROOT_RESET_STORE') {
        const { router } = state;
        state = { router };
    }

    return appReducer(state, action)
};

export let store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk))
);
