import { fetch } from "../../utils/dataAccess";

export function loadTeamsForSelect(valueProperty = 'id', filters = {}) {
  return dispatch => {
    dispatch(loading(true));
    fetch('/teams', {
      params: {
        pagination: false,
        status: 'active',
        ...filters
      }
    })
    .then(response => response.data)
    .then(teams => {
      dispatch(teamsLoaded(teams['hydra:member'].map(
        function (team) {
          return {
            value: team[valueProperty],
            text: team.name,
          }
        }
      )));
      dispatch(loading(false));
    }).catch(e => {
      dispatch(loading(false));
      dispatch(error(e.message));
    })
  };
}

export function loading(loading) {
  return {type: 'TEAMS_FOR_SELECT_LOADING', loading};
}

export function error(error) {
  return {type: 'TEAMS_FOR_SELECT_ERROR', error};
}

export function teamsLoaded(retrieved) {
  return {type: 'TEAMS_FOR_SELECT_RETRIEVED', retrieved};
}

export function reset() {
  return {type: 'TEAMS_FOR_SELECT_RESET'};
}
