import React from "react";
import PropTypes from "prop-types";
import ChangeDeviceComponent from "./ChangeDeviceComponent";
import {
  filterVideoDevices,
} from "../../utils/webRtc";
import {IconButton} from "@material-ui/core";
import CameraIcon from "@material-ui/icons/PhotoCamera";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {changeVideoDevice, getStreamManagerVideoDeviceId} from "../../utils/streamManager";
import DialogTitle from "../../views/Components/Dialog/DialogTitle";
import DialogActions from "../../views/Components/Dialog/DialogActions";
import DialogContent from "../../views/Components/Dialog/DialogContent";

class VideoDeviceSelect extends React.Component {
  static defaultProps = {
    disabled: false,
    buttonText: 'Camera Options',
    icon: <CameraIcon />,
  };

  static propTypes = {
    streamManager: PropTypes.object.isRequired,
    buttonText: PropTypes.string,
    icon: PropTypes.object,
    disabled: PropTypes.bool,
  };

  getCurrentDeviceId = () => {
    const {streamManager} = this.props;
    return getStreamManagerVideoDeviceId(streamManager);
  };

  renderDialog = (devices, currentDevice, selectedDevice, onDeviceSelected, finishDeviceChanging, changeDevice, closeDialog) => {
    return (
      <>
        <DialogTitle
          id={"confirmation-dialog-title"}
          onClose={finishDeviceChanging}
        >
          Change Camera
        </DialogTitle>
        <DialogContent>
          <RadioGroup
            value={selectedDevice}
            onChange={event => onDeviceSelected(event.target.value)}
          >
            {Object.values(devices).map(({id, label}) => (
              <FormControlLabel value={id} key={id} control={<Radio />} label={label} />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions
          confirmButtonText={"Ok"}
          onConfirm={() => {
            if (selectedDevice !== currentDevice) {
              changeDevice(selectedDevice);
            } else {
              closeDialog();
            }}
          }
          closeButtonText={"Cancel"}
          onClose={finishDeviceChanging}
        />
      </>
    );
  }

  renderIconButton = (onClick, disabled) => {
    const {icon, buttonClass, buttonText} = this.props;
    const buttonDisabled = disabled || this.props.disabled;
    return (
      <IconButton onClick={onClick} disabled={buttonDisabled} className={buttonClass}>
        {icon}
        {buttonText && (<span className='video-control-label'>{buttonText}</span>)}
      </IconButton>
    )
  };

  render() {
    const {streamManager} = this.props;
    return (
      <ChangeDeviceComponent
        streamManager={streamManager}
        getCurrentDeviceId={this.getCurrentDeviceId}
        filterDevices={filterVideoDevices}
        changeDevice={changeVideoDevice}
        renderDialog={this.renderDialog}
        renderButton={this.renderIconButton}
      />
    )
  }
}

export default VideoDeviceSelect;
