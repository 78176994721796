import {getUserId} from "../../utils/auth";

export function loading (state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_LOADING':
      return action.loading;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

export function creatingLiveInterviewParticipant(state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_CREATING_LIVE_INTERVIEW_PARTICIPANT':
      return action.creating;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

export function liveInterview (state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_LIVE_INTERVIEW':
      return action.liveInterview;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}

export function liveInterviewParticipant(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_LIVE_INTERVIEW_PARTICIPANT':
      return action.liveInterviewParticipant;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}

export function liveInterviewParticipantCreated(state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_LIVE_INTERVIEW_PARTICIPANT_CREATED':
      return action.created;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

export function startingInterview (state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_STARTING':
      return action.starting;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

export function joiningToInterview (state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_JOINING':
      return action.joining;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

export function reconnectingToInterview(state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_RECONNECTING':
      return action.value;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

export function joinedToInterview (state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_JOINED':
      return action.joined;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

export function endingInterview(state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_ENDING':
      return action.ending;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

export function isHost(state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_HOST_STATUS':
      return action.isHost;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

export function session(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_SESSION':
      return action.session;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}

export function error(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_ERROR':
      return action.error;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}

export function publisher(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_PUBLISHER':
      return action.publisher;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}

export function publisherPreferences(state = {}, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_PUBLISHER_PREFERENCES':
      return action.preferences;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return {};
    default:
      return state;
  }
}

export function sipAudio(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_SIP_AUDIO':
      return action.sipAudio;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}

export function sharedScreen(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_SHARE_SCREEN':
      return action.streamManager;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}

export function participants(state = [], action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_ADD_PARTICIPANT':
      return addParticipant(state, action.participant);
    case 'LIVEINTERVIEW_PAGE_CLOSE_PARTICIPANT_STREAM':
      return closeParticipantStream(state, action.userId, action.streamType);
    case 'LIVEINTERVIEW_PAGE_PARTICIPANT_SPEAKING':
      const participant = action.participant;
      const speaking = action.speaking;
      return updateParticipantSpeakingState(state, participant.userId, speaking);
    case 'LIVEINTERVIEW_PAGE_CLEAR_PARTICIPANTS':
    case 'LIVEINTERVIEW_PAGE_RESET':
      return [];
    default:
      return state;
  }
}

export function participantsMuted(state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_MUTE_PARTICIPANTS':
      return action.mute;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

export function fullscreen(state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_FULLSCREEN':
      return action.enabled;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

export function focusedParticipantId(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_FOCUSED_PARTICIPANT':
      return action.participantId;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}

export function bottomPanelActive(state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_BOTTOM_PANEL_ACTIVE':
      return action.active;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

export function recording(state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_RECORDING':
      return action.recording;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

function addParticipant(participants, newParticipant) {
  if (newParticipant.id === getUserId()) {
    return participants;
  }
  const existedParticipant = participants.find((participant) => participant.id === newParticipant.id);
  let newParticipants;
  if (existedParticipant) {
    newParticipants = participants.map(participant => {
      if (participant === existedParticipant && (
        newParticipant.streamManager || newParticipant.sipAudio
      )) {
        if (newParticipant.streamManager) {
          participant.streamManager = newParticipant.streamManager;
        }
        if (newParticipant.sipAudio) {
          participant.sipAudio = newParticipant.sipAudio;
        }
        return participant;
      } else {
        return participant;
      }
    });
  } else {
    newParticipants = [...participants, newParticipant];
  }

  return newParticipants;
}

function closeParticipantStream(participants, userId, streamType) {
  return participants.filter(participant => {
    if (participant.id !== userId) {
      return true;
    }
    participant[streamType === 'sip' ? 'sipAudio' : 'streamManager'] = undefined;
    return !!participant.streamManager || !!participant.sipAudio;
  });
}

function updateParticipantSpeakingState(participants, userId, speaking) {
  return participants.map((participant) => {
    if (participant.id === userId) {
      participant.speaking = speaking;
      if (speaking) {
        participant.lastSpeakTime = new Date();
      }
    }
    return participant;
  })
}
