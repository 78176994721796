import React from "react";
import DashboardErrorPage from "./DashboardErrorPage";
import PropTypes from 'prop-types';
import SiteInactiveError from "../../errors/siteInactiveError";

export default class SiteDetailsLoadingErrorPage extends React.Component {
    static propTypes = {
        error: PropTypes.object.isRequired,
    };

    getSiteInactiveErrorPage = (error) => {
        return (
            <DashboardErrorPage
                errorTitle="Not available"
                errorDescription={error.message}
            />
        )
    }

    getDefaultErrorPage = () => {
        return (
            <DashboardErrorPage
                errorTitle="Error..."
                errorDescription="Server Error. Please try to refresh the page in a few minutes."
            />
        );
    }

    getErrorPage = (error) => {
        switch (true) {
            case error instanceof SiteInactiveError:
                return this.getSiteInactiveErrorPage(error);
            default:
                return this.getDefaultErrorPage();
        }
    }

    render() {
        return (
            <div className="container">
                {this.getErrorPage(this.props.error)}
            </div>
        );
    }
}
