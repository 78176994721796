import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Form from './Form';
import {create, error, reset} from '../../actions/Request/create';
import {loadJobsForSelect} from "../../actions/Job/forSelect";

class RequestCreate extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    created: PropTypes.object,
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentWillMount() {
    this.props.loadJobsForSelect({status: 'active'});
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    if (this.props.created)
      this.props.history.push({
        pathname: `/company/requests/`,
        state: {createdInterview: this.props.created}
      });

    let item = this.props.item ? this.props.item : {};
    if (this.props.match.params.jobId) {
      item['job'] = decodeURIComponent(this.props.match.params.jobId);
    }

    return (
      <div>
        {(this.props.loading || this.props.saveAsTemplateLoading) && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
            {this.props.error}
          </div>
        )}
        {this.props.saveAsTemplateError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
            {this.props.saveAsTemplateError}
          </div>
        )}
        <Form onSubmit={this.props.create}
              initialValues={item}
              jobs={this.props.jobs}
              errorDispatch={this.props.errorDispatch}
              templateLoaded={!!this.props.item} /* this props doesn't use, need to rerender form when template is loaded */
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    created: state.request.create.created,
    error: state.request.create.error || state.job.forSelect.error || state.requesttemplate.forSelect.error || state.request.loadTemplate.error || state.request.saveAsTemplate.error,
    loading: state.request.create.loading || state.job.forSelect.loading || state.requesttemplate.forSelect.loading || state.request.loadTemplate.loading || false,
    jobs: state.job.forSelect.retrieved,
    item: state.request.loadTemplate.request,
    saveAsTemplateLoading: state.request.saveAsTemplate.loading,
    saveAsTemplateError: state.request.saveAsTemplate.error,
  }
};

const mapDispatchToProps = dispatch => ({
  create: values => dispatch(create(values)),
  loadJobsForSelect: (filters) => dispatch(loadJobsForSelect(filters)),
  reset: () => dispatch(reset()),
  errorDispatch: (message) => dispatch(error(message)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestCreate);
