import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";
import {loadJobsForSelect, reset as resetJobsForSelect} from "../../actions/Job/forSelect";
import {loadTeamsForSelect} from "../../actions/Team/forSelect";
import {FILTER_SET_ACTIVE, FILTER_SET_ARCHIVED, FILTER_SET_COMPLETED, FILTER_SET_UPCOMING} from "./AllInterviews";

class AllInterviewsFilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string,
    filterSet: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      jobsFilterIsModalOpen: false,
    }
  }

  onFirstExpand = () => {
    this.props.loadJobsForSelect();

    /* fetching this data is triggered in FilterJobsModal */
    // this.props.loadTeamsForSelect();
  }

  componentWillUnmount() {
    this.props.resetEntities();
  }

  jobsFilterToggleModal = (opened) => {
    this.setState({jobsFilterIsModalOpen: opened});
  }

  selectJob = (job, fieldName = '@id') => {
    this.props.change('job', job[fieldName]);
    this.jobsFilterToggleModal(false);
  }

  render() {
    let teamsPlaceholder = this.props.teamsLoading ? 'Loading...' : 'Team';
    let jobsPlaceholder = this.props.jobsLoading ? 'Loading...' : 'Job';

    const statuses = [
      {value: 'not_started', text: 'Not Started'},
      {value: 'started_by_host', text: 'Started by Host'},
      {value: 'in_progress', text: 'In Progress'},
      {value: 'completed', text: 'Completed'},
      {value: 'unattended', text: 'Unattended'},
      {value: 'no_host', text: 'No Host'},
      {value: 'no_candidate', text: 'No Candidate'},
    ];

    const archivedOptions = [
      {value: true, text: 'Yes'},
      {value: false, text: 'No'}
    ];

    const isStatusDisabled = [FILTER_SET_UPCOMING, FILTER_SET_COMPLETED].includes(this.props.filterSet);
    const isArchivedAtDisabled = [FILTER_SET_ARCHIVED, FILTER_SET_ACTIVE].includes(this.props.filterSet);

    return (
      <>
        {!this.props.parentLoading && this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        <RenderFilterForm {...this.props} onFirstExpand={this.onFirstExpand} fields={[
          {'name': 'candidateInvitations___firstName', 'label': 'Candidate Name', 'placeholder': 'Candidate Name', 'type': 'text'},
          {'name': 'title', 'label': 'Interview Title', 'placeholder': 'Interview Title', 'type': 'text'},
          {'name': 'job___team', 'label': 'Team', 'placeholder': teamsPlaceholder, 'type': 'selectSearch', 'options': this.props.teams},
          {'name': 'status', 'label': 'Status', 'placeholder': 'Status', 'type': 'selectSearch', 'options': statuses, 'isDisabled': isStatusDisabled},
          {'name': 'job', 'label': 'Job', 'placeholder': jobsPlaceholder, 'type': 'jobSelectSearch', 'options': this.props.jobs,
            'jobsFilterSelectJob': this.selectJob,
            'jobsFilterToggleModal': this.jobsFilterToggleModal,
            'jobsFilterIsModalOpen': this.state.jobsFilterIsModalOpen,
          },
          {'name': 'archivedAt__exists__', 'label': 'Archived', 'placeholder': 'Archived', 'type': 'selectSearch', 'options': archivedOptions, 'isDisabled': isArchivedAtDisabled},
          {'name': 'scheduledStartTime__after__', 'label': 'Start Date', 'placeholder': 'Start Date', 'type': 'dateTime'},
          {'name': 'scheduledStartTime__before__', 'label': 'End Date', 'placeholder': 'End Date', 'type': 'dateTime'},
        ]} />
      </>
    );
  }
}

const mapStateToProps = state => {
  let teams = state.team.forSelect.retrieved;
  let jobs = state.job.forSelect.retrieved;
  let loading = state.team.forSelect.loading || state.job.forSelect.loading;
  let teamsLoading = state.team.forSelect.loading;
  let jobsLoading = state.job.forSelect.loading;

  return { teams, jobs, loading, teamsLoading, jobsLoading };
};

const mapDispatchToProps = dispatch => ({
  loadTeamsForSelect: () => dispatch(loadTeamsForSelect()),
  loadJobsForSelect: () => dispatch(loadJobsForSelect()),
  resetEntities: () => {
    dispatch(resetJobsForSelect());
  },
});

AllInterviewsFilterForm = reduxForm({
  form: 'filter_company_live_interview_all',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(AllInterviewsFilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllInterviewsFilterForm);
