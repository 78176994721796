import {combineReducers} from 'redux';

export function retrieved(state = null, action) {
  switch (action.type) {
    case 'KNOCKOUTQUESTIONBANK_CATEGORIES_LOADED':
      return action.categories;
    case 'KNOCKOUTQUESTIONBANK_CATEGORIES_RESET':
      return null;
    default:
      return state;
  }
}
export function retrieveError(state = null, action) {
  switch (action.type) {
    case 'KNOCKOUTQUESTIONBANK_CATEGORIES_LOAD_ERROR':
      return action.error;
    case 'KNOCKOUTQUESTIONBANK_CATEGORIES_RESET':
      return null;
    default:
      return state;
  }
}
export default combineReducers({retrieved, retrieveError});
