import { SubmissionError } from 'redux-form';
import {
  fetch,
  extractHubURL,
} from '../../utils/dataAccess';

export function enableAutoRenewError(error) {
  return { type: 'SUBSCRIPTION_ENABLE_AUTO_RENEW_ERROR', error };
}

export function enableAutoRenewLoading(loading) {
  return { type: 'SUBSCRIPTION_ENABLE_AUTO_RENEW_LOADING', loading };
}

export function enableAutoRenewSuccess(success) {
  return { type: 'SUBSCRIPTION_ENABLE_AUTO_RENEW_SUCCESS', success };
}

export function enableAutoRenew(subscription, callback) {
  return dispatch => {
    dispatch(enableAutoRenewError(null));
    dispatch(enableAutoRenewSuccess(null));
    dispatch(enableAutoRenewLoading(true));

    return fetch((subscription['@id'] + '/enable-auto-renew'), {
      method: 'PUT',
    })
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        dispatch(enableAutoRenewLoading(false));
        dispatch(enableAutoRenewSuccess(retrieved));
      })
      .catch(e => {
        dispatch(enableAutoRenewLoading(false));

        if (e instanceof SubmissionError) {
          dispatch(enableAutoRenewError(e.errors._error));
          throw e;
        }

        dispatch(enableAutoRenewError(e.message));
      }).finally(e => {
        callback();
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'SUBSCRIPTION_ENABLE_AUTO_RENEW_RESET' });
  };
}
