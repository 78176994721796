import React, {Component} from 'react';
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button";

class VideoQuestionAnswerTextarea extends Component {
  static propTypes = {
    onAnswer: PropTypes.func.isRequired,
    question: PropTypes.object.isRequired,
    cancelAnswer: PropTypes.func,
  };

  state = {
    value: ''
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.question && this.props.question && prevProps.question.id !== this.props.question.id) {
      this.setState({value: ''});
    }
  }

  onChange = (e) => {
    let text = e.target.value;
    this.setState({value: text});

    if ('' === text) {
      text = undefined;
    }
    this.props.onAnswer(text);
  }

  render() {
    const {cancelAnswer} = this.props;
    return (
      <div className={'video-answer-text-container'}>
        <textarea
          className='video-answer-textarea'
          name="video-question-text-answer"
          placeholder="Text"
          maxLength={10000}
          onChange={this.onChange}
          value={this.state.value}
        />
        <div className={'text-answer-buttons-container'}>
        {cancelAnswer && (
          <Button className={"text-answer-button select-another-answer-type-button"} onClick={cancelAnswer}>
            Select Another Answer Type
          </Button>
        )}
        </div>
      </div>
    );
  }
}

export default VideoQuestionAnswerTextarea;
