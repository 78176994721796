import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classNames from 'classnames';
import {currentUserHasRole} from "../../utils/auth";
import DateTimeWithTimezone from "../DateTime/DateTimeWithTimezone";
import {
  acceptInvitation, createAndAcceptInvitation, createAndDeclineInvitation,
  createAndRequestNewTime, declineInvitation, requestNewTime
} from "../../actions/LiveInterviewPage/invitation";
import NewTimeRequestDialog from "./NewTimeRequestDialog";
import AcceptInvitationDialog from "./AcceptInvitationDialog";
import {checkIfAccountExists} from "../../actions/LiveInterviewPage/auth";
import storage from "../../utils/storage";
import DeclineInvitationDialog from "../Interview/DeclineInvitationDialog";
import {isInterviewExpired} from "../../utils/liveInterview";
import MicrophoneDeviceDialog from "../VideoControl/MicrophoneDeviceDialog";
import {filterAudioDevices} from "../../utils/webRtc";
import {getOpenViduSingleton} from "../../utils/OpenViduInstance";
import {createLiveInterviewParticipant} from "../../actions/LiveInterviewPage/interview";
import DialogRoot from "../../views/Components/Dialog/DialogRoot";
import PhoneNumber from "../../views/Components/PhoneNumber";

class InterviewInfo extends React.Component {
  static propTypes = {
    liveInterview: PropTypes.object.isRequired,
    invitation: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      joinInterviewDialogOpened: false,
      acceptDialogOpened: false,
      declineInvitationDialogOpened: false,
      newTimeRequestDialogOpened: false,
      audioDevices: [],
    };
  }

  componentDidMount() {
    const {loggedIn, invitation} = this.props;
    this.props.checkIfAccountExists(loggedIn, invitation);
    this.updateAvailableAudioDevices();
  }

  updateAvailableAudioDevices = () => {
    const OV = getOpenViduSingleton();
    return OV.getDevices().then((allDevices) => {
      this.setState({
        audioDevices: filterAudioDevices(allDevices),
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.invitationAccepted && this.props.invitationAccepted) {
      this.setState({acceptDialogOpened: true});
    }

    //Company user wasn't invited, but clicked join interview and created participant
    if (this.props.isHost && this.props.session && this.props.liveInterviewParticipant && this.props.liveInterviewParticipantCreated &&
      (!prevProps.liveInterviewParticipant || !prevProps.session)
    ) {
      const {liveInterview} = this.props;
      if (liveInterview.startedAt) {
        this.openJoinDialog(this.props.joinInterview);
      } else {
        this.openJoinDialog(this.props.startInterview);
      }
    }
  }

  renderInvitationStatus = () => {
    const {invitation, liveInterview, newTimeRequested} = this.props;
    const interviewExpired = isInterviewExpired(liveInterview);
    const interviewFinished = !!liveInterview.completedAt;

    if (interviewExpired && !interviewFinished) {
      return 'Expired';
    } else if (newTimeRequested) {
      return 'Request Reschedule';
    } else if (!invitation) {
      return 'Pending';
    } else if (invitation.status === 'accepted') {
      return 'Accepted';
    } else if (invitation.status === 'declined') {
      return 'Declined';
    } else {
      return 'Pending';
    }
  };

  handleAcceptInvitation = () => {
    const {
      acceptInvitation, createAndAcceptInvitation,
      liveInterview, invitation, loggedIn
    } = this.props;
    if (!loggedIn) {
      storage.setExpires('liveInterviewRoomCode', liveInterview.roomCode, 3600);
    }

    if (invitation) {
      acceptInvitation(invitation);
    } else if (loggedIn) {
      createAndAcceptInvitation(liveInterview['roomCode']);
    } else {
      this.setState({acceptDialogOpened: true});
    }
  };

  handleDeclineInvitation = () => {
    const {
      declineInvitation, createAndDeclineInvitation,
      liveInterview, invitation, loggedIn
    } = this.props;
    if (invitation) {
      declineInvitation(invitation);
    } else if (loggedIn) {
      createAndDeclineInvitation(liveInterview['roomCode']);
    }
  };

  handleNewTimeRequest = (values) => {
    const {
      requestNewTime, createAndRequestNewTime,
      liveInterview, invitation, loggedIn
    } = this.props;

    if (invitation) {
      requestNewTime(invitation, values);
    } else if (loggedIn) {
      createAndRequestNewTime(liveInterview['roomCode'], values);
    }
  };

  renderAcceptDeclineButtons = () => {
    const {invitation, acceptingInvitation, decliningInvitation, loggedIn} = this.props;
    const accepted = invitation && invitation.status === 'accepted';
    const declined = invitation && invitation.status === 'declined';

    return (
      <div className={'interview-page-interview-info-accept-decline-buttons'}>
        <button
          type="button"
          className='btn btn-success'
          disabled={accepted || declined || acceptingInvitation || decliningInvitation}
          onClick={this.handleAcceptInvitation}
        >
          {acceptingInvitation ? 'Accepting...' : (accepted ? 'Accepted' : 'Accept')}
        </button>
        <button
          type="button"
          className='btn btn-danger'
          disabled={declined || accepted || decliningInvitation || acceptingInvitation || (!loggedIn && !invitation)}
          onClick={() => this.setState({declineInvitationDialogOpened: true})}
          title={(!loggedIn && !invitation) ? 'Please login to be able decline the interview' : ''}
        >
          {decliningInvitation ? 'Declining...' : (declined ? 'Declined' : 'Decline')}
        </button>
        <DeclineInvitationDialog
          open={this.state.declineInvitationDialogOpened}
          handleClose={() => this.setState({declineInvitationDialogOpened: false})}
          declineInvitation={() => {
            this.handleDeclineInvitation();
            this.setState({declineInvitationDialogOpened: false});
          }}
        />
      </div>
    )
  }

  renderRequestNewTimeButton = () => {
    const {requestingNewTime, invitation, loggedIn}= this.props;

    return (
      <div className={'interview-page-interview-info-request-new-time-block'}>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => this.setState({newTimeRequestDialogOpened: true})}
          disabled={!invitation && !loggedIn}
          title={!invitation && !loggedIn ? 'Please login to be able request new time' : ''}
        >
          {requestingNewTime ? 'Requesting...' : 'Request a New Time'}
        </button>
        <NewTimeRequestDialog
          open={this.state.newTimeRequestDialogOpened}
          handleClose={() => this.setState({newTimeRequestDialogOpened: false})}
          handleSubmit={(values) => {
            this.handleNewTimeRequest(values);
            this.setState({newTimeRequestDialogOpened: false});
          }}
        />
      </div>
    );
  }

  renderStartJoinButton = () => {
    const {
      liveInterview, joiningToInterview, startingInterview, testing, startingTest,
      isHost, loggedIn, invitation, liveInterviewParticipant, session, creatingLiveInterviewParticipant
    } = this.props;
    const declined = invitation && invitation.status === 'declined';
    const sipPhone = liveInterview.sipPhoneNumber;
    const sipCode = liveInterviewParticipant && liveInterviewParticipant.sipCode;
    const loadingState = joiningToInterview || startingInterview || startingTest || testing || creatingLiveInterviewParticipant;
    const buttonDisabled = declined || !loggedIn || (!session && (!isHost || liveInterviewParticipant)) || loadingState;

    return (
      <div className={'interview-page-interview-info-start-join-block'}>
      {(!isHost || liveInterview.startedAt) ? (
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            if (isHost && !liveInterviewParticipant) {
              this.props.createLiveInterviewParticipant(liveInterview);
            } else {
              this.openJoinDialog(this.props.joinInterview)
            }
          }}
          disabled={buttonDisabled}
          title={declined ? 'You have declined this interview' : 'Join to the interview'}
        >
          {joiningToInterview || creatingLiveInterviewParticipant ? 'Joining...' : 'Join Interview'}
        </button>
      ) : (
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            if (isHost && !liveInterviewParticipant) {
              this.props.createLiveInterviewParticipant(liveInterview);
            } else {
              this.openJoinDialog(this.props.startInterview)
            }
          }}
          disabled={buttonDisabled}
        >
          {startingInterview || joiningToInterview || creatingLiveInterviewParticipant ? 'Starting...' : 'Start Interview'}
        </button>
      )}
      <DialogRoot
        properties={{
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
          maxWidth: "xs",
        }}
        open={this.state.joinInterviewDialogOpened}
      >
        <MicrophoneDeviceDialog
          currentDevice='default'
          devices={this.state.audioDevices}
          changeDevice={(newDevice) => {
            if (this.state.joinCallback) {
              this.state.joinCallback({
                audioSource: newDevice,
              });
            }
            this.closeJoinDialog();
          }}
          confirmSip={() => {
            if (this.state.joinCallback) {
              this.state.joinCallback({
                audioSource: 'SIP',
              });
            }
            this.closeJoinDialog();
          }}
          closeDialog={this.closeJoinDialog}
          sipCode={sipCode}
          sipPhone={sipPhone}
          audioDeviceListHeader='Select Microphone'
          audioDeviceConfirmButtonText='Join'
          sipConfirmButtonText='Join'
        />
      </DialogRoot>
      </div>
    );
  }

  openJoinDialog = (joinCallback) => {
    try {
      navigator.mediaDevices.getUserMedia({audio: true, video: false}).then((mediaStream) => {
        this.updateAvailableAudioDevices()
          .then(() => mediaStream.getTracks().forEach(track => track.stop()));
      }).catch(e => console.log('Cant get microphone permissions', e));
    } catch (e) {
      console.log('Cant get microphone permissions', e);
    }
    this.setState({
      joinInterviewDialogOpened: true,
      joinCallback: joinCallback,
    });
  }

  closeJoinDialog = () => {
    this.setState({joinInterviewDialogOpened: false});
  }

  renderStateInfoBlock = () => {
    const {newTimeRequested, invitation, loggedIn, liveInterview} = this.props;
    const isCompanyUser = currentUserHasRole('ROLE_COMPANY_USER');
    const accepted = invitation && invitation.status === 'accepted';
    const interviewFinished = !!liveInterview.completedAt;
    const interviewExpired = isInterviewExpired(liveInterview);

    if (interviewFinished) {
      return (
        <div className={'interview-page-interview-info-interview-state-block'}>
          This interview is finished.
        </div>
      )
    }

    if (interviewExpired) {
      return (
        <div className={'interview-page-interview-info-interview-state-block'}>
          This interview is no longer available.
        </div>
      )
    }

    if (newTimeRequested) {
      return (
        <div className={'interview-page-interview-info-interview-state-block'}>
          Your request has been sent to the host. You will be notified if the interview is rescheduled.
        </div>
      )
    }

    if (!invitation && isCompanyUser) {
      return (
        <div className={'interview-page-interview-info-wait-interview-starting'}>
          You are not invited to the interview.
        </div>
      )
    }

    if (!loggedIn && accepted) {
      return (
        <div className={'interview-page-interview-info-wait-interview-starting'}>
          Please login to be able to join to the interview.
        </div>
      );
    }

    if ((accepted || isCompanyUser) && !liveInterview.startedAt) {
      return (
        <div className={'interview-page-interview-info-wait-interview-starting'}>
          Please wait for host to start interview. You
          will be connected automatically when the
          interview is started.
        </div>
      )
    }
  };

  renderActionsBlock = () => {
    const {newTimeRequested, invitation, liveInterview, isHost, session} = this.props;
    const acceptedStatus = invitation && invitation.status === 'accepted';
    const declinedStatus = invitation && invitation.status === 'declined';
    const isCompanyUser = currentUserHasRole('ROLE_COMPANY_USER');
    const isCandidate = currentUserHasRole('ROLE_CANDIDATE');
    const interviewStarted = !!liveInterview.startedAt;
    const interviewFinished = !!liveInterview.completedAt;
    const interviewExpired = isInterviewExpired(liveInterview);
    return (
      <div className={'interview-page-interview-info-join-interview-buttons'}>
        {!interviewExpired && !interviewFinished && !newTimeRequested && (
          <>
            {!isCompanyUser && !acceptedStatus && !declinedStatus && this.renderAcceptDeclineButtons()}
            {!isCompanyUser && !acceptedStatus && !declinedStatus && this.renderRequestNewTimeButton()}
            {
              ( isHost || (interviewStarted && (
                  (isCandidate && acceptedStatus) ||
                  (isCompanyUser && invitation)
                ))
              ) && this.renderStartJoinButton()
            }
          </>
        )}
        {(!isHost && session || !isCompanyUser) && this.renderStateInfoBlock()}
      </div>
    )
  };

  render() {
    const {liveInterview, invitation} = this.props;
    const statusAccepted = invitation && invitation.status === 'accepted';
    const statusDeclined = invitation && invitation.status === 'declined';
    const interviewInfoClass = classNames(
      'interview-page-interview-info-container',
      {
        'interview-page-interview-info-container-accepted': statusAccepted,
        'interview-page-interview-info-container-declined': statusDeclined,
      }
    )
    const host = liveInterview.companyUser ? liveInterview.companyUser : {};
    const isCandidate = currentUserHasRole('ROLE_CANDIDATE');
    const isInvited = invitation || isCandidate;
    return (
      <div className='interview-page-interview-info'>
        {isInvited && (<div className={'interview-page-interview-info-title'}>You're invited to interview!</div>)}
        <div>
          <div className={interviewInfoClass}>
            <div className={'interview-page-interview-info-block'}>
              <span className={'interview-page-interview-info-block-title'}>COMPANY</span>
              <span className={'interview-page-interview-info-block-content'}>{liveInterview.job.company.name}</span>
            </div>
            <div className={'interview-page-interview-info-block'}>
              <span className={'interview-page-interview-info-block-title'}>HOST</span>
              <span className={'interview-page-interview-info-block-content'}>{host.firstName} {host.lastName}</span>
              <span className={'interview-page-interview-info-block-content'}>{host.email}</span>
              <span className={'interview-page-interview-info-block-content'}>
                <PhoneNumber phone={host.phoneNumber} formattedPhone={host.formattedPhoneNumber} />
              </span>
            </div>
            <div className={'interview-page-interview-info-block'}>
              <span className={'interview-page-interview-info-block-title'}>SCHEDULED FOR</span>
              <span className={'interview-page-interview-info-block-content'}>
                <DateTimeWithTimezone time={liveInterview.scheduledStartTime} timezone={liveInterview.timezone} />
              </span>
            </div>
            {isCandidate && (
            <div className={'interview-page-interview-info-block'}>
              <span className={'interview-page-interview-info-block-title'}>INVITATION STATUS:</span>
              <span className={'interview-page-interview-info-block-content'}>{this.renderInvitationStatus()}</span>
            </div>
            )}
          </div>
          {!statusDeclined && (
            <div className={'interview-page-interview-info-actions'}>
              {this.renderActionsBlock()}
            </div>
          )}
          <AcceptInvitationDialog
            dialogOpened={this.state.acceptDialogOpened}
            liveInterview={liveInterview}
            invitation={invitation}
            handleCloseDialog={() => {this.setState({acceptDialogOpened: false});}}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  startingInterview: state.liveInterviewPage.startingInterview,
  joiningToInterview: state.liveInterviewPage.joiningToInterview,
  liveInterview: state.liveInterviewPage.liveInterview,
  liveInterviewParticipant: state.liveInterviewPage.liveInterviewParticipant,
  creatingLiveInterviewParticipant: state.liveInterviewPage.creatingLiveInterviewParticipant,
  liveInterviewParticipantCreated: state.liveInterviewPage.liveInterviewParticipantCreated,
  invitation: state.liveInterviewPage.invitation,
  acceptingInvitation: state.liveInterviewPage.acceptingInvitation,
  invitationAccepted: state.liveInterviewPage.acceptedInvitation,
  decliningInvitation: state.liveInterviewPage.decliningInvitation,
  requestingNewTime: state.liveInterviewPage.requestingNewTime,
  newTimeRequested: state.liveInterviewPage.newTimeRequested,
  session: state.liveInterviewPage.session,
  publisher: state.liveInterviewPage.publisher,
  isHost: state.liveInterviewPage.isHost,
  loggedIn: state.liveInterviewPage.auth.loggedIn,
  testing: state.liveInterviewPage.testing,
  startingTest: state.liveInterviewPage.startingTest,
});

const mapDispatchToProps = (dispatch) => ({
  acceptInvitation: (invitation) => dispatch(acceptInvitation(invitation)),
  createAndAcceptInvitation: (roomCode) => dispatch(createAndAcceptInvitation(roomCode)),
  declineInvitation: (invitation) => dispatch(declineInvitation(invitation)),
  createAndDeclineInvitation: (roomCode) => dispatch(createAndDeclineInvitation(roomCode)),
  requestNewTime: (invitation, values) => dispatch(requestNewTime(invitation, values)),
  createAndRequestNewTime: (roomCode, values) => dispatch(createAndRequestNewTime(roomCode, values)),
  checkIfAccountExists: (loggedIn, invitation) => dispatch(checkIfAccountExists(loggedIn, invitation)),
  createLiveInterviewParticipant: (liveInterview) => dispatch(createLiveInterviewParticipant(liveInterview)),
});

InterviewInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(InterviewInfo);

export default InterviewInfo;
