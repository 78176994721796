import {Link} from "react-router-dom";
import React from "react";
import {isGranted} from "./permissions";
import {applyToUrl, changeUrlParam, getUrlQueryParam} from "./urls";
import Pagination from "../views/Components/Pagination";
import {getRoutePage} from "./routes";
import {history} from "../store";
import {generatePath} from "react-router";

export function renderLinks (type, items, nameGetFunction, checkPermission = false) {
  if (Array.isArray(items)) {
    return items.map((item, i) => (
      <div key={i}>{this.entityList(type, item)}</div>
    ));
  }
  if (!items) {
    return '';
  }
  let id = items['@id'] || (items.id ? type + '/' + items.id : false) || items;
  let name;
  if (nameGetFunction === undefined ) {
    name = items.name || id;
  } else {
    name = nameGetFunction(items);
  }
  type=type.replace(/_/g, '-');
  if (!checkPermission || isGranted('show', items)) {
      return (
          <Link to={`../${type}/show/${encodeURIComponent(id)}`}>{name}</Link>
      );
  } else {
      return <span>{name}</span>
  }
}

//Pagination with changing page in browser search bar
export function pagination(retrieved, page, options = {}) {
  if (!retrieved) {
    return null;
  }

  const totalItems = retrieved['hydra:totalItems'] || 0;
  if (totalItems === 0) {
    return null;
  }

  return (
    <Pagination
      {...options}
      count={totalItems}
      page={page}
    />
  );
}

//Pagination that made xhr request without updating browser url
export function inlinePagination(retrieved, listAction, options = {}) {
  if (!retrieved) {
    return null;
  }

  const totalItems = retrieved['hydra:totalItems'] || 0;
  if (totalItems === 0) {
    return null;
  }

  const view = retrieved['hydra:view'] || {};
  const currentUrl = view['@id'];
  const page = parseInt(getUrlQueryParam(currentUrl, 'page', 0));
  return (
      <Pagination
          {...options}
          page={page}
          count={totalItems}
          onChangePage={(event, page) => {
            const newUrl = changeUrlParam(currentUrl, 'page', page + 1);
            listAction(newUrl);
          }}
      />
  );
}

export function handleListUpdate(url, props, prevProps = {}) {
  const nextPage = getRoutePage(props.match);
  const prevPage = getRoutePage(prevProps.match);
  const pageChanged = (nextPage > 0 || prevPage > 0) && nextPage !== prevPage;
  const filtersChanged = props.filtersData !== prevProps.filtersData;
  const sortChanged = props.sortData !== prevProps.sortData;
  if (pageChanged || sortChanged || (filtersChanged && nextPage === 0)) {
    const filters = {...props.filtersData, ...props.sortData};
    if (nextPage > 0) {
      filters.page = nextPage;
    }
    const listUrl = applyToUrl(url, filters);
    props.list(listUrl);
  } else if (filtersChanged) {
    const path = props.match && props.match.path ? props.match.path : '/';
    history.push(generatePath(path, {page: null}));
  }
}
