import React, {Component} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {SortableTableCells} from "../../utils/filterForm";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {pagination, renderLinks} from "../../utils/entityList";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {CopyToClipboard} from "../CopyToClipboard/CopyToClipboard";
import IconButton from "@material-ui/core/IconButton";
import CopyContent from "../Icons/CopyContent";
import {getLiveInterviewUrlByRoomCode} from "../../utils/urls";
import TableFooter from "@material-ui/core/TableFooter";
import DateTimeUserTimeZone from "../DateTime/DateTimeUserTimezone";

export default class LiveInterviewListTable extends Component {
    static propTypes = {
        retrieved: PropTypes.object,
        type: PropTypes.string.isRequired,
        sort: PropTypes.func.isRequired,
        sortData: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.isScheduled = props.type === 'scheduled';
        this.fields = {
            'id': 'ID',
            'title': 'Title',
            'job.title': 'Job',
        };
        if (this.isScheduled) {
            this.fields['roomCode'] = 'Join Link';
            this.fields['scheduledStartTime'] = 'Scheduled Date and Time';
        } else {
            this.fields['startedAt'] = 'Start Date And Time';
            this.fields['completedAt'] = 'Completion Date and Time';
        }
    }

    render() {
        let liveInterviewUrl;
        return (<><div style={{'overflowX': 'auto'}}><Table className="table table-responsive table-striped table-hover mb-0">
            <TableHead>
                <TableRow>
                    <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={this.fields}/>
                    <TableCell className="align-middle" colSpan={1}/>
                </TableRow>
            </TableHead>
            <TableBody>
                {this.props.retrieved &&
                this.props.retrieved['hydra:member'].map(item => {
                    if (this.isScheduled) {
                        liveInterviewUrl = getLiveInterviewUrlByRoomCode(item['roomCode']);
                    }
                    return (
                        <TableRow key={item['@id']}>
                            <TableCell className="align-middle" scope="row">
                                {renderLinks('live-interviews', item, (liveInterview) => liveInterview['id'])}
                            </TableCell>
                            <TableCell className="align-middle">
                                {renderLinks('live-interviews', item, (liveInterview) => liveInterview['title'])}
                            </TableCell>
                            <TableCell className="align-middle">{item['job']['title']}</TableCell>
                            {this.isScheduled ? (
                                <>
                                    <TableCell className="align-middle">
                                        <a href={liveInterviewUrl} target="_blank">{liveInterviewUrl}</a>
                                        <CopyToClipboard text={liveInterviewUrl}>
                                            <IconButton style={{'height': '1em'}}>
                                                <CopyContent style={{'height': '0.6em'}}>
                                                    Copy To Clipboard
                                                </CopyContent>
                                            </IconButton>
                                        </CopyToClipboard>
                                    </TableCell>
                                    <TableCell className="align-middle">
                                        <DateTimeUserTimeZone time={item.scheduledStartTime} />
                                    </TableCell>
                                </>
                            ) : (
                                <>
                                    <TableCell className="align-middle">
                                        <DateTimeUserTimeZone time={item.startedAt} />
                                    </TableCell>
                                    <TableCell className="align-middle">
                                        <DateTimeUserTimeZone time={item.completedAt} />
                                    </TableCell>
                                </>
                            )}
                            <TableCell className="align-middle">
                                <Link to={`show/${encodeURIComponent(item['@id'])}`}>
                                    <span className="fa fa-eye" aria-hidden="true"/>
                                    <span className="sr-only">Show</span>
                                </Link>
                            </TableCell>
                        </TableRow>
                    )
                }
                )}
            </TableBody>
        </Table></div>
        <Table className="table table-responsive no-border">
          <TableFooter>
            <TableRow>
                {pagination(this.props.retrieved, this.props.page)}
            </TableRow>
          </TableFooter>
        </Table></>);
    }
}
