import {
  fetch,
  normalize,
  extractHubURL,
  mercureSubscribe as subscribe
} from '../../utils/dataAccess';
import { success as deleteSuccess } from './delete';
import {filterAction, sortAction} from "../../utils/filterAndSortUtils";
import {isVideoNeedToReEncode} from "../../utils/videoEntity";

export function error(error) {
  return { type: 'QUESTIONBANK_LIST_ERROR', error };
}

export function loading(loading) {
  return { type: 'QUESTIONBANK_LIST_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'QUESTIONBANK_LIST_SUCCESS', retrieved };
}

export function list(page = 'question_banks') {
  return dispatch => {
    dispatch(loading(true));
    dispatch(error(''));

    fetch(page)
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {

        dispatch(loading(false));
        dispatch(success(retrieved));

        if (hubURL && retrieved['hydra:member'].length)
          dispatch(
            mercureSubscribe(
              hubURL,
              retrieved['hydra:member']
            )
          );
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'QUESTIONBANK_LIST_RESET' });
    dispatch(deleteSuccess(null));
  };
}

export function mercureSubscribe(hubURL, questionBanks) {
  let topics = [];
  questionBanks.forEach(questionBank => {
    if (
      questionBank['questionBankVideo'] &&
      questionBank['questionBankVideo']['@id'] &&
      isVideoNeedToReEncode(questionBank['questionBankVideo'])
    ) {
      topics.push(questionBank['questionBankVideo']['@id']);
    }
  });

  return dispatch => {
    if (topics.length) {
      const eventSource = subscribe(hubURL, topics);
      dispatch(mercureOpen(eventSource));
      eventSource.addEventListener('message', event =>
        dispatch(mercureMessage(normalize(JSON.parse(event.data))))
      );
    }
  };
}

export function mercureOpen(eventSource) {
  return { type: 'QUESTIONBANK_LIST_MERCURE_OPEN', eventSource };
}

export function mercureMessage(retrieved) {
  return dispatch => {
    if (1 === Object.keys(retrieved).length) {
      dispatch({ type: 'QUESTIONBANK_LIST_MERCURE_DELETED', retrieved });
      return;
    }

    if (retrieved && retrieved['@type'] === 'QuestionBankVideo') {
      dispatch({ type: 'QUESTIONBANK_QUESTIONBANKVIDEO_LIST_MERCURE_MESSAGE', retrieved });
      return
    }

    dispatch({ type: 'QUESTIONBANK_LIST_MERCURE_MESSAGE', retrieved });
  };
}

export function filter(filtersData) {
  return filterAction( 'QUESTIONBANK_LIST_FILTER', 'question_bank', filtersData)
}

export function sort(sortData) {
  return sortAction('QUESTIONBANK_LIST_SORT','question_bank', sortData)
}
