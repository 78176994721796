import React, {useEffect, useState} from 'react';
import Dialog from "../../views/Components/Dialog/Dialog";
import PropTypes from 'prop-types';
import RichTextEditor from "../../views/Components/RichTextEditor";
import {Field} from "redux-form";
import {stripTags} from "../../utils/string";

function CustomizeEmailInvitationModal({open, fieldName, invitation, handleClose, setCustomEmail, invitationEmailDefaultContent}) {
  let title = 'Customize Email';
  if (invitation.firstName || invitation.lastName) {
    title += ' to';
    if (invitation.firstName) {
      title += ` ${invitation.firstName}`;
    }
    if (invitation.lastName) {
      title += ` ${invitation.lastName}`;
    }
  }

  const [previousValue, setPreviousValue] = useState(null);
  const [candidateNameChanged, setCandidateNameChanged] = useState(false);
  const [html, setHtml] = useState();
  const onSave = () => {
    /* if candidate name was edited in email template, stop synchronize it */
    compareCandidateNames(html, () => {
      setCandidateNameChanged(true);
    });

    setPreviousValue(html);

    handleClose();
  }

  const onClose = () => {
    /* discard changes and set previous value */
    setCustomEmail(fieldName, previousValue);
    handleClose();
  }

  const handleChange = (event, v, c) => {
    setHtml(c.getHTML());

    return event;
  }

  const getDefaultMessage = () => {
    const defaultMessage = invitationEmailDefaultContent ?
      invitationEmailDefaultContent['content'] :
      '';

    return defaultMessage.replaceAll('{{ first_name }}', (' ' + (invitation.firstName || '')));
  }

  const resetToDefaultMessage = (e) => {
    e.preventDefault();
    setCustomEmail(fieldName, getDefaultMessage());
    setCandidateNameChanged(false);
  }

  const compareCandidateNames = (emailTemplate, callback = null) => {
    const domParser = new DOMParser();
    const parsedDocument = domParser.parseFromString(emailTemplate, 'text/html');
    const candidateNameContainer = parsedDocument.getElementsByClassName('candidate-firstname');

    if (candidateNameContainer.length > 0) {
      let candidateNameInEmail = '';
      if (candidateNameContainer[0].textContent) {
        candidateNameInEmail = candidateNameContainer[0].textContent.trim();
      }
      let invitationFormCandidateName = '';
      if (invitation['firstName']) {
        invitationFormCandidateName = invitation['firstName'].trim();
      }

      if (!candidateNameChanged && candidateNameInEmail !== invitationFormCandidateName) {
        if (typeof callback === 'function') {
          callback();
        } else {
          if (candidateNameContainer[0].textContent[0] === ' ') {
            candidateNameContainer[0].textContent = (' ' + invitation['firstName']);
          } else {
            candidateNameContainer[0].textContent = invitation['firstName'];
          }

          setCustomEmail(fieldName, parsedDocument.body.innerHTML);
        }
      }
    }
  }

  useEffect(() => {
    if (open) {
      if (!previousValue) {
        setCustomEmail(fieldName, getDefaultMessage());
      }

      /* in case if candidate name wasn't change in email template, but changed in invitation form, synchronize it */
      if (!candidateNameChanged && previousValue) {
        compareCandidateNames(previousValue);
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        title={title}
      >
        <div className={"on-demand-invitation-custom-email-modal"}>
          <Field
            component={RichTextEditor}
            name={fieldName}
            label={null}
            placeholder="Custom Email Text"
            required={true}
            toolbarOptions={[
              ['bold', 'italic', 'underline', 'link'],
              [{size: []}],
              [{'header': [1, 2, 3, 4, 5, 6]}],
              [{'align': []}],
            ]}
            onChangeSelection={handleChange}
            emailContentEditor={true}
            normalize={v => {
              if (stripTags(v).trim() === '') {
                return null;
              }

              return v;
            }}
          />

          <a
            href={'#'}
            onClick={resetToDefaultMessage}
            className={"reset-to-default-message"}
          >
            Reset to Default Message
          </a>

          <button onClick={onSave} className={"subscription-modal-button-green"}>Save</button>
          <button onClick={onClose} className={"subscription-modal-button-gray-outlined"}>Cancel</button>
        </div>
      </Dialog>
    </div>
  );
}

CustomizeEmailInvitationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  fieldName: PropTypes.string.isRequired,
  invitation: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  setCustomEmail: PropTypes.func.isRequired,
};

export default CustomizeEmailInvitationModal;
