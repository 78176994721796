import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { list, reset} from '../../actions/Plan/list';
import { retrieve as retrieveCompany, reset as resetCompany} from '../../actions/Company/show';
import { list as subscriptionList, reset as subscriptionReset} from '../../actions/Subscription/list';
import {PlanCard} from "../../views/Subscription/PlanCard";
import {formatPrice, PREVIEW_PERIOD_ANNUAL, PREVIEW_PERIOD_MONTHLY} from "../../utils/plan";
import {Link} from "react-router-dom";
import {IRItoID} from "../../utils/dataAccess";
import {applyToUrl} from "../../utils/urls";
import "./PlanSelect.scss"
import {Tabs} from "./Tabs";
import {calculateUpgradeSummary, reset as resetCalculateUpgradeSummary} from "../../actions/Subscription/upgradeSummary";
import CustomLinearProgress from "../CustomLinearProgress/CustomLinearProgress";
import {reset as resetUsage, usage} from "../../actions/Subscription/usage";
import PlanSelectOveruseModal from "./Modal/PlanSelectOveruseModal";

class PlanSelect extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    subscription: PropTypes.object,
    subscriptionList: PropTypes.func.isRequired,
    subscriptionReset: PropTypes.func.isRequired,
  };

  state = {
    period: PREVIEW_PERIOD_MONTHLY,
    selectedPlan: null,
    selectedPlanOveruseLimitations: null,
  };

  componentDidMount() {
    this.props.retrieveCompany(decodeURIComponent(this.props.match.params.id));
    this.props.list(applyToUrl('plans', {'status': 'active', 'sort[monthlyPrice]': 'asc'}));
    const companyId = IRItoID(decodeURIComponent(this.props.match.params.id));
    this.props.subscriptionList(applyToUrl('subscriptions', {'company___id': companyId}));
    this.props.usage(IRItoID(decodeURIComponent(this.props.match.params.id)));
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.subscription && this.props.subscription) {
      this.setState({period: this.props.subscription['planDuration']});
    }
  }

  showOveruseModalForSelectedPlan = (newPlan) => {
    const {usageData} = this.props;

    if (!this.props.subscription) {
      return false;
    }

    for (let i in newPlan['planLimitations']) {
      const planLimitation = newPlan['planLimitations'][i];
      const limitationKey = planLimitation['key'];
      const limitationQuantity = planLimitation['quantity'];

      if (planLimitation['unlimited']) {
        continue;
      }

      if (limitationQuantity < usageData[limitationKey]) {
        this.setState({selectedPlanOveruseLimitations: newPlan});
        return true;
      }
    }

    return false;
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
    this.props.subscriptionReset(this.props.eventSource);
    this.props.resetCalculateUpgradeSummary(this.props.eventSource);
    this.props.resetUsage(this.props.eventSource);
    this.props.resetCompany(this.props.eventSource);
  }

  onPlanSelect = (plan, e) => {
    const {subscription} = this.props;
    if (
      subscription &&
      subscription['plan']['@id'] === plan['@id'] &&
      subscription['planDuration'] === this.state.period
    ) {
      return;
    }

    if (!this.props.usageData) {
      return;
    }

    if (this.showOveruseModalForSelectedPlan(plan)) {
      return;
    }

    this.setState({selectedPlan: plan});
    this.props.resetCalculateUpgradeSummary(this.props.eventSource);
    const companyId = IRItoID(decodeURIComponent(this.props.match.params.id));
    this.props.calculateUpgradeSummary(plan['id'], this.state.period, companyId);
  }

  handleCloseOveruseModal = () => {
    this.setState({selectedPlanOveruseLimitations: null});
  }

  onPeriodSelect = (period) => {
    if (this.state.period !== period) {
      this.setState({
        period: period,
        selectedPlan: null,
      });
    }
  }

  onClickContinue = () => {
    if (this.state.selectedPlan && this.state.period && this.props.upgradeSummary) {
      this.props.history.push({
        pathname: 'payment-methods',
        state: {
          plan: this.state.selectedPlan,
          period: this.state.period,
        }
      });
    }
  }

  render() {
    const {subscription, company} = this.props;
    const plans = this.props.retrieved ? this.props.retrieved['hydra:member'].filter(plan => (
      (this.state.period === PREVIEW_PERIOD_ANNUAL && plan['annualPrice']) ||
      (this.state.period === PREVIEW_PERIOD_MONTHLY && plan['monthlyPrice'])
    )) : [];
    const isUpgrade = !!subscription && subscription['active'];

    return (
      <div className={"partner-dashboard-plan-select"}>
        <Tabs
          planSelectUrl={ `/partner-admin/companies/subscription/${this.props.match.params.id}/plan-select`}
          step={1}
          isUpgrade={isUpgrade}
        />

        {isUpgrade ? (
          <h2>Upgrade Plans For {company && company['name']}</h2>
        ) : (
          <h2>Select A Plan For {company && company['name']}</h2>
        )}

        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}

        <div className={"plan-select-period-tabs"}>
          <button className={this.state.period === PREVIEW_PERIOD_MONTHLY ? 'active' : ''} onClick={() => this.onPeriodSelect(PREVIEW_PERIOD_MONTHLY)}>
            Monthly
          </button>
          <button className={this.state.period === PREVIEW_PERIOD_ANNUAL ? 'active' : ''} onClick={() => this.onPeriodSelect(PREVIEW_PERIOD_ANNUAL)}>
            Annual
          </button>
        </div>
        <div className={"plan-select-list"}>
          {plans.map(plan => (
            <div className={"plan-select-card"} key={plan['@id']}>
              {plan['private'] ? (
                <div className={"plan-card-status private"}>Private</div>
              ) : (
                <div className={"plan-card-status"}>Public</div>
              )}
              <PlanCard
                plan={plan}
                period={this.state.period}
                onSelect={this.onPlanSelect}
                selected={this.state.selectedPlan && this.state.selectedPlan['@id'] === plan['@id']}
                current={subscription && plan['@id'] === subscription['plan']['@id'] && this.state.period === subscription['planDuration']}
              />
            </div>
          ))}
        </div>
        <div className={"plan-select-fixed-bottom"}>
          {this.state.selectedPlan && this.props.upgradeSummary && (
            <div className={"plan-select-selected-plan-text"}>
              You’re upgrading this company to <strong>{this.state.selectedPlan['name']}.</strong> Their account will be charged <strong>{formatPrice(this.props.upgradeSummary['total'])}</strong> (tax included) now, which is prorated for the current billing period.
            </div>
          )}
          {this.props.upgradeSummaryLoading && (
            <div className={"col-md-10"} style={{margin: 'auto'}}>
              <CustomLinearProgress color={"selectedPlan"} />
            </div>
          )}
          <div className={"plan-select-buttons"}>
            <Link to={'../' + encodeURIComponent(this.props.match.params.id)} className={"cancel"}>Cancel</Link>
            <button
              className={"continue" + (!this.state.selectedPlan ? ' disabled' : '')}
              disabled={!this.state.selectedPlan}
              onClick={this.onClickContinue}
            >
              Continue
            </button>
          </div>

          {this.state.selectedPlanOveruseLimitations && subscription && (
            <PlanSelectOveruseModal
              usageData={this.props.usageData}
              currentPlan={subscription['plan']}
              newPlan={this.state.selectedPlanOveruseLimitations}
              handleClose={this.handleCloseOveruseModal}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const loading = state.plan.list.loading || state.subscription.list.loading || state.subscription.usage.loading || state.company.show.loading;
  const error = state.plan.list.error || state.subscription.list.error || state.subscription.upgradeSummary.error || state.subscription.usage.error || state.company.show.error;
  const eventSource = state.plan.list.eventSource;
  const retrieved = state.plan.list.retrieved;
  const upgradeSummaryLoading = state.subscription.upgradeSummary.loading;
  const upgradeSummary = state.subscription.upgradeSummary.retrieved;
  const usageData = state.subscription.usage.retrieved;
  const company = state.company.show.retrieved;

  let subscription;
  if (
    state.subscription.list.retrieved &&
    Array.isArray(state.subscription.list.retrieved['hydra:member']) &&
    state.subscription.list.retrieved['hydra:member'].length > 0
  ) {
    subscription = state.subscription.list.retrieved['hydra:member'][0];
  }

  return { retrieved, loading, error, eventSource, subscription, upgradeSummaryLoading, upgradeSummary, usageData, company };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource)),
  retrieveCompany: companyId => dispatch(retrieveCompany(companyId)),
  resetCompany: eventSource => dispatch(resetCompany(eventSource)),
  subscriptionList: page => dispatch(subscriptionList(page)),
  subscriptionReset: eventSource => dispatch(subscriptionReset(eventSource)),
  calculateUpgradeSummary: (newPlan, newPeriod, companyId) => dispatch(calculateUpgradeSummary(newPlan, newPeriod, companyId)),
  resetCalculateUpgradeSummary: eventSource => dispatch(resetCalculateUpgradeSummary(eventSource)),
  usage: companyId => dispatch(usage(companyId)),
  resetUsage: eventSource => dispatch(resetUsage(eventSource)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanSelect);
