import React from 'react';
import Dialog from "../../../views/Components/Dialog/Dialog";
import PropTypes from 'prop-types';
import {generateLimitationLabel, generateLimitationUsageLabel} from "../../../views/Subscription/PlanCard";
import {LIMITATION_STORAGE} from "../../../utils/plan";

function PlanSelectOveruseModal({usageData, currentPlan, newPlan, handleClose}) {
  const isOverusedLimitationClass = (planLimitation) => {
    if (planLimitation['key'] === LIMITATION_STORAGE) {
      return (!planLimitation['unlimited'] && usageData['storage_raw'] > (planLimitation.quantity * 1073741824)) ? ' overused' : '';
    }


    return (!planLimitation['unlimited'] && usageData[planLimitation.key] > planLimitation.quantity) ? ' overused' : '';
  }

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        title={"You cannot change to this plan."}
        dialogProperties={{subscriptionTheme: true}}
      >
        <div className={"plan-select-overuse-plan-modal"}>
          <p>The current usage of this company is over <strong>{newPlan['name']}</strong> limit. Please reduce usage or choose a different plan</p>

          <div className={"limitations-column"}>
            <div className={"column-label"}>Current Usage</div>
            {currentPlan['planLimitations'].map(planLimitation => (
              <div className={"limitations-column-row"}>
                {generateLimitationUsageLabel(planLimitation, usageData)}
              </div>
            ))}
          </div>

          <div className={"limitations-column"}>
            <div className={"column-label"}>New Plan Limit</div>
            {newPlan['planLimitations'].map(planLimitation => (
              <div className={"limitations-column-row" + isOverusedLimitationClass(planLimitation)}>
                {generateLimitationLabel(planLimitation)}
              </div>
            ))}
          </div>

          <button onClick={handleClose} className={"subscription-modal-button-green"}>OK</button>
        </div>
      </Dialog>
    </div>
  );
}

PlanSelectOveruseModal.propTypes = {
  usageData: PropTypes.object.isRequired,
  currentPlan: PropTypes.object.isRequired,
  newPlan: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default PlanSelectOveruseModal;
