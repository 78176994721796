import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { retrieve, reset } from '../../actions/KnockoutQuestionBankCategory/show';
import { del } from '../../actions/KnockoutQuestionBankCategory/delete';
import Moment from 'react-moment';
import {renderLinks} from "../../utils/entityList";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {currentUserHasRole} from "../../utils/auth";
import IsGranted from "../IsGranted";
import FlashMessages from "../../views/Components/FlashMessages";

class KnockoutQuestionBankCategoryShow extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    deleteError: PropTypes.string,
    deleteLoading: PropTypes.bool.isRequired,
    deleted: PropTypes.object,
    del: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  del = () => {
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.del(this.props.retrieved);
  };

  render() {
    if (this.props.deleted)
      this.props.history.push({
        pathname: `..`,
        state: {successMessage: 'Screening Question Category successfully deleted.'}
      });

    const item = this.props.retrieved;

    return (
      <div>
        <h1>Show &quot;{item && item['name']}&quot;</h1>
        <FlashMessages location={this.props.location} />
        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}
        {this.props.deleteError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.deleteError}
          </div>
        )}

        <div className="grid-buttons">
          <Link to=".." className="btn btn-primary">
            Back to list
          </Link>
          {item && (
            <IsGranted action='edit' object={item}>
            <Link to={`../edit/${encodeURIComponent(item['@id'])}`} className="btn btn-warning">Edit</Link>
            </IsGranted>
          )}
          {item && (
            <IsGranted action='delete' object={item}>
            <button onClick={this.del} className="btn btn-danger">
              Delete
            </button>
            </IsGranted>
          )}
        </div>

        {item && (
          <div style={{'overflowX': 'auto'}}>
            <Table className="table table-responsive table-striped table-hover">
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell scope="row">Name</TableCell>
                  <TableCell>{item['name']}</TableCell>
                </TableRow>
                {(!currentUserHasRole('ROLE_SUPER_ADMIN') && !currentUserHasRole('ROLE_PARTNER_ADMIN')) && (
                  <TableRow>
                    <TableCell scope="row">Team</TableCell>
                    <TableCell>{renderLinks('../teams', item['team'])}</TableCell>
                  </TableRow>
                )}
                {(!currentUserHasRole('ROLE_SUPER_ADMIN') && !currentUserHasRole('ROLE_PARTNER_ADMIN')) && (
                  <TableRow>
                    <TableCell scope="row">User</TableCell>
                    <TableCell>{renderLinks('../users', item['companyUser'], (companyUser) => companyUser.firstName + ' '+ companyUser.lastName)}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell scope="row">Created At</TableCell>
                  <TableCell>{item['createdAt'] && (<Moment>{item['createdAt']}</Moment>)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Updated At</TableCell>
                  <TableCell>{item['updatedAt'] && (<Moment>{item['updatedAt']}</Moment>)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        )}

      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.knockoutquestionbankcategory.show.retrieved,
  error: state.knockoutquestionbankcategory.show.error,
  loading: state.knockoutquestionbankcategory.show.loading,
  eventSource: state.knockoutquestionbankcategory.show.eventSource,
  deleteError: state.knockoutquestionbankcategory.del.error,
  deleteLoading: state.knockoutquestionbankcategory.del.loading,
  deleted: state.knockoutquestionbankcategory.del.deleted
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  del: item => dispatch(del(item)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KnockoutQuestionBankCategoryShow);
