import React, {Component} from 'react';
import {connect} from "react-redux";
import KnockoutQuestion from "../KnockoutQuestion/KnockoutQuestion";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button";
import {saveAnswer} from "../../actions/OnDemandInterviewPage/answer";
import SkipQuestionDialog from "./SkipQuestionDialog";

class Questions extends Component {
  state = {
    showSkipQuestionModal: false,
    answer: null,
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.interview && this.props.interview &&
      prevProps.interview.currentKnockoutQuestion !== this.props.interview.currentKnockoutQuestion
    ) {
      this.setState({answer: null});
    }
  }

  getCurrentQuestion = () => {
    const {request, interview} = this.props;
    const questions = request.knockoutQuestions;
    const currentQuestionIndex = interview.currentKnockoutQuestion;

    if (currentQuestionIndex === null || currentQuestionIndex < 0 || currentQuestionIndex > questions.length - 1) {
      return null;
    }

    return questions[currentQuestionIndex];
  };

  saveAnswer = (answer, skipped = false) => {
    const {interview} = this.props;
    const question = this.getCurrentQuestion();
    if (!question) {
      return false;
    }
    this.props.saveAnswer(answer, question, interview, skipped)
  };

  skipQuestion = () => {
    this.saveAnswer(null, true);
    this.setState({showSkipQuestionModal: false});
  }

  render() {
    const {answer, showSkipQuestionModal} = this.state;
    const {saving} = this.props;
    const question = this.getCurrentQuestion();

    if (!question) {
      return null
    }

    return (
      <>
        <KnockoutQuestion question={question}
                          onChange={(answer) => this.setState({answer})} />
        <GridItem xs={12} style={{textAlign: "center"}} className={'on-demand-interview-page-question-controls'}>
          {!question.required && (
            <Button color="primary" onClick={() => this.setState({showSkipQuestionModal: true})}>Skip</Button>
          )}
          <Button color="success"
                  disabled={saving || answer === undefined || answer === null ||
                            (Array.isArray(answer) && answer.length === 0) ||
                            (typeof answer === 'string' && !answer.trim())}
                  onClick={() => this.saveAnswer(this.state.answer)}>
            {saving ? 'Saving...' : 'Next >>'}
          </Button>
        </GridItem>
        {showSkipQuestionModal && (
          <SkipQuestionDialog
            onClose={() => this.setState({showSkipQuestionModal: false})}
            onSkip={this.skipQuestion}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  request: state.onDemandInterviewPage.request,
  interview: state.onDemandInterviewPage.interview,
  saving: state.onDemandInterviewPage.savingAnswer,
});

const mapDispatchToProps = dispatch => ({
  saveAnswer: (answer, question, interview, skipped) => dispatch(saveAnswer(answer, question, interview, skipped)),
});

Questions = connect(
  mapStateToProps, mapDispatchToProps
)(Questions);

export default Questions;
