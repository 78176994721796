import React, {Component} from 'react';
import {connect} from 'react-redux'
import {formValueSelector, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import RequestWizard from "./RequestWizard";
import {loadIndustriesForSelect} from "../../actions/Industry/forSelect";
import {loadOccupationsForSelect} from "../../actions/Occupation/forSelect";
import {loadSpecialtiesForSelect} from "../../actions/Specialty/forSelect";
import withStyles from "@material-ui/core/styles/withStyles";
import {renderTextField} from "../../utils/form";
import SearchableSelect from "../../views/Components/SearchableSelect";
import {Field} from "redux-form";
import Card from "../Card/Card";
import TemplateLoadingForm from "./TemplateLoadingForm";
import {isValidYoutubeUrl} from "../../utils/embedVideo";
import {mercureSubscribe as subscribe, normalize} from "../../utils/dataAccess";
import {MERCURE_ENTRYPOINT} from "../../config/entrypoint";
import {copyVideoEntity, isVideoNeedToReEncode} from "../../utils/videoEntity";
import {success} from "../../actions/QuestionBank/search";

const style = {
  card: {
    margin: '25px 0 -10px 0',
    padding: '15px',
  }
};

class RequestForm extends Component {
  static propTypes = {
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    errorDispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.eventSource = null;
    this.state = {
      teamDialogueAnchor: null,
    };
    this.subscribeOnChanges(this.getMercureTopics(props));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevTopics = this.getMercureTopics(prevProps);
    const curTopics = this.getMercureTopics(this.props);
    if (prevTopics.length !== curTopics.length || JSON.stringify(prevTopics) !== JSON.stringify(curTopics)) {
      this.closeEventSource();
      this.subscribeOnChanges(curTopics);
    }
  }

  getMercureTopics = (props) => {
    const {introVideo, outroVideo, videoQuestions, bankQuestions} = props;
    let topics = [];

    const getVideoTopic = (video) =>  video['videoOrigin'] ? video['videoOrigin'] : video['@id'];

    if (introVideo && isVideoNeedToReEncode(introVideo)) {
      topics.push(getVideoTopic(introVideo));
    }

    if (outroVideo && isVideoNeedToReEncode(outroVideo)) {
      topics.push(getVideoTopic(outroVideo));
    }

    if (videoQuestions && videoQuestions.length) {
      videoQuestions.forEach(videoQuestion => {
        if (videoQuestion.questionVideo && isVideoNeedToReEncode(videoQuestion.questionVideo)) {
          topics.push(getVideoTopic(videoQuestion.questionVideo));
        }
      });
    }

    if (bankQuestions && bankQuestions['hydra:member'] && bankQuestions['hydra:member'].length) {
      bankQuestions['hydra:member'].forEach(questionBank => {
        if (questionBank.questionBankVideo && isVideoNeedToReEncode(questionBank.questionBankVideo)) {
          topics.push(questionBank.questionBankVideo['@id']);
        }
      });
    }

    //Return only unique ids
    return [...new Set(topics)];
  }

  subscribeOnChanges = (topics) => {
    if (topics.length === 0) {
      return;
    }
    this.eventSource = subscribe(new URL(MERCURE_ENTRYPOINT), topics);
    this.eventSource.addEventListener('message', event => {
      const {introVideo, outroVideo} = this.props;
      const entity = normalize(JSON.parse(event.data));
      const type = (entity && entity['@type']) ? entity['@type'] : null;

      switch (type) {
        case 'RequestIntroVideo':
          const updatedIntroVideo = (introVideo && introVideo['videoOrigin']) ? copyVideoEntity(entity) : entity;
          this.props.change('requestIntroVideo', updatedIntroVideo);
          break;
        case 'RequestOutroVideo':
          const updatedOutroVideo = (outroVideo && outroVideo['videoOrigin']) ? copyVideoEntity(entity) : entity;
          this.props.change('requestOutroVideo', updatedOutroVideo);
          break;
        case 'QuestionVideo':
          const questions = this.props.videoQuestions.map(question => {
            if (question && question.questionVideo && (
              question.questionVideo['@id'] === entity['@id'] ||
              question.questionVideo['videoOrigin'] === entity['@id']
            )) {
              const updatedQuestionVideo = (question.questionVideo['videoOrigin']) ? copyVideoEntity(entity) : entity;
              return {...question, questionVideo: updatedQuestionVideo};
            } else {
              return question;
            }
          });
          this.props.change('questions', questions);
          break;
        case 'QuestionBankVideo':
          const bankQuestions = this.props.bankQuestions;
          if (bankQuestions && bankQuestions['hydra:member'] && bankQuestions['hydra:member'].length) {
            const newBankQuestions = {
              ...bankQuestions,
              'hydra:member': bankQuestions['hydra:member'].map(questionBank => {
                if (questionBank.questionBankVideo && questionBank.questionBankVideo['@id'] === entity['@id']) {
                  return {...questionBank, questionBankVideo: entity};
                } else {
                  return questionBank;
                }
              })
            };
            this.props.updateSearch(newBankQuestions);

            const questions = this.props.videoQuestions.map(question => {
              if (question && question.questionVideo && question.questionVideo['videoOrigin'] === entity['@id']) {
                return {...question, questionVideo: copyVideoEntity(entity)};
              } else {
                return question;
              }
            });
            this.props.change('questions', questions);
          }
          break;
      }
    });
  }

  componentWillMount()
  {
    this.props.loadIndustriesForSelect();
    this.props.loadOccupationsForSelect();
    this.props.loadSpecialtiesForSelect();
  }

  componentWillUnmount() {
    this.closeEventSource();
  }

  closeEventSource = () => {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }
  }

  onSubmit = (event) => {
    let hasError = false;
    let videoQuestions = this.props.videoQuestions;
    event.preventDefault();

    if (Array.isArray(videoQuestions) && videoQuestions.length > 0) {
      for (let i = videoQuestions.length - 1; i >= 0; i--) {
        let question = videoQuestions[i];

        if (!question.title && Object.keys(question.questionVideo).length === 0 && question.questionVideo.constructor === Object) {
          videoQuestions.splice(i, 1);
          continue;
        }

        if (!question.title) {
          this.props.errorDispatch('Please provide title for video question #' + (i + 1));
          hasError = true;
        }

        if (!question.questionVideo || Object.keys(question.questionVideo).length === 0 && question.questionVideo.constructor === Object) {
          this.props.errorDispatch(`Please upload a video for question "${question.title}"`);
          hasError = true;
        }
      }

      this.props.change('videoQuestions', videoQuestions);
    }

    if (!hasError) {
      this.props.handleSubmit(event);
    }
  };

  render() {
    const {classes} = this.props;
    return (
      <>
        <TemplateLoadingForm
          loading={this.props.loading}
          handleSubmit={this.props.handleSubmit}
          onSubmit={this.props.onSubmit}
          hasQuestions={this.props.videoQuestions.length > 0 || this.props.knockoutQuestions.length > 0}
        />
        <div className={'clearfix'} />

        <form onSubmit={this.onSubmit}>
          <Card className={classes.card}>
            <Field
              component={renderTextField}
              name="title"
              type="text"
              placeholder="Title"
              label="Interview Title"
              required={true}
              fullWidth
            />
            <Field
              component={SearchableSelect}
              name="job"
              label="Job"
              placeholder={this.props.jobs ? 'The Job to which this Interview belongs.' : 'Loading...'}
              required={true}
              fullWidth
              options={this.props.jobs ? this.props.jobs : []}
              blurInputOnSelect={true}
            />
          </Card>
          <RequestWizard
            knockoutQuestions={this.props.knockoutQuestions}
            videoQuestions={this.props.videoQuestions}
            jobs={this.props.jobs}
            job={this.props.job}
            initialValues={this.props.initialValues}
            change={this.props.change}
            handleSubmit={this.props.handleSubmit}
          />
        </form>
      </>
    );
  }
}

const selector = formValueSelector('request');
const mapStateToProps = state => ({
  knockoutQuestions: selector(state, 'knockoutQuestions') || [],
  videoQuestions: selector(state, 'questions') || [],
  job: selector(state, 'job'),
  introVideo: selector(state, 'requestIntroVideo'),
  outroVideo: selector(state, 'requestOutroVideo'),
  bankQuestions: state.questionbank.search.retrieved,
});

RequestForm = withStyles(style)(RequestForm);

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadIndustriesForSelect: () => dispatch(loadIndustriesForSelect('@id')),
  loadOccupationsForSelect: () => dispatch(loadOccupationsForSelect('@id')),
  loadSpecialtiesForSelect: () => dispatch(loadSpecialtiesForSelect('@id')),
  updateSearch: (newBankQuestions) => dispatch(success(newBankQuestions)),
});

RequestForm = connect(
  mapStateToProps, mapDispatchToProps
)(RequestForm);

export default reduxForm({
  form: 'request',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: (values) => {
    let errors = {questions: []};

    if (values.videoQuestions) {
      for (let i = 0; i < values.videoQuestions.length; i++) {
        let question = values.videoQuestions[i];
        if (!question.title) {
          errors.questions[i] = {title: 'Please provide title for video question #' + (i + 1)};
        }

        if (!question.questionVideo || Object.keys(question.questionVideo).length === 0 && question.questionVideo.constructor === Object) {
          errors.questions[i] = {title: `Please upload a video for question "${question.title}"`};
        }
      }
    }

    if (values.requestIntroVideoUrl && !isValidYoutubeUrl(values.requestIntroVideoUrl)) {
      errors['requestIntroVideoUrl'] = 'YouTube URL is not valid.';
    }
    if (values.requestOutroVideoUrl && !isValidYoutubeUrl(values.requestOutroVideoUrl)) {
      errors['requestOutroVideoUrl'] = 'YouTube URL is not valid.';
    }

    return errors;
  },
})(RequestForm);
