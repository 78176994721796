import { combineReducers } from 'redux';

export function error(siteSettings = null, action) {
  switch (action.type) {
    case 'SITE_SETTINGS_CREATE_ERROR':
      return action.error;
    case 'SITE_SETTINGS_CREATE_RESET':
      return null;

    default:
      return siteSettings;
  }
}

export function loading(siteSettings = false, action) {
  switch (action.type) {
    case 'SITE_SETTINGS_CREATE_LOADING':
      return action.loading;
    case 'SITE_SETTINGS_CREATE_RESET':
      return false;

    default:
      return siteSettings;
  }
}

export function created(siteSettings = null, action) {
  switch (action.type) {
    case 'SITE_SETTINGS_CREATE_SUCCESS':
      return action.created;
    case 'SITE_SETTINGS_CREATE_RESET':
      return null;

    default:
      return siteSettings;
  }
}

export default combineReducers({ error, loading, created });
