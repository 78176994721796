import React from "react";
import moment from "moment-timezone";
import Moment from "react-moment";
import Datetime from "react-datetime";
import calendarIcon from "../../assets/img/icons/calendar.svg";

export default class OnDemandInvitationExpirationDatePicker extends React.Component
{
  constructor(props) {
    super(props);

    this.state = {
      value: props.input.value ? moment(props.input.value).utcOffset(0) : null,
      previewValue: props.input.value ? moment(props.input.value).utcOffset(0) : null,
      hours: props.input.value ? moment(props.input.value).utcOffset(0).format('h') : '12',
      minutes: props.input.value ? moment(props.input.value).utcOffset(0).format('mm') : '00',
      ampm: props.input.value ? moment(props.input.value).utcOffset(0).format('A') : 'AM',
      hideTime: false,
    };
  }

  resetButton = (closeCalendarFunction) => {
    this.setState({value: null, previewValue: null, hours: 0, minutes: 0});
    this.saveValue(null);
    closeCalendarFunction();
  }

  doneButton = (closeCalendarFunction) => {
    const value = this.state.value;
    if (value) {
      let hours = parseInt(this.state.hours);
      if (this.state.ampm === 'AM' && hours === 12) {
        hours = 0;
      }
      if (this.state.ampm === 'PM' && hours !== 12) {
        hours += 12;
      }

      value.set('hour', hours);
      value.set('minute', parseInt(this.state.minutes));
    }

    this.setState({
      previewValue: value,
      value: value,
    });
    this.saveValue(value);
    closeCalendarFunction();
  }

  onClose = (e) => {
    const value = this.state.previewValue ? moment(this.state.previewValue).utcOffset(0) : null;
    this.setState({
      value: value,
      hours: value ? value.format('h') : '12',
      minutes: value ? value.format('mm') : '00',
    });
  }

  saveValue = (value) => {
    if (this.props.invitationIRI && this.props.saveValue) {
      this.props.saveValue(value, this.props.invitationIRI);
    } else {
      this.props.input.onChange(value);
    }
  }

  onChangeCalendar = (value) => {
    this.setState({value: value});
  }

  changeHours = (direction) => {
    let value = this.state.hours ? this.state.hours : 0;
    if (direction === 'down' && value > 0) {
      value--;
    }

    if (direction === 'up' && value < 12) {
      value++;
    }

    this.setState({hours: value});
  }

  changeMinutes = (direction) => {
    let value = this.state.minutes ? parseInt(this.state.minutes) : 0;
    if (direction === 'down' && value > 0) {
      value--;
    }

    if (direction === 'up' && value < 59) {
      value++;
    }

    value = value < 10 ? ('0' + value) : value;

    this.setState({minutes: value});
  }

  onChangeHour = (e) => {
    if (e.target.value === '') {
      this.setState({hours: ''});
      return;
    }

    let hours = parseInt(e.target.value);
    if (hours >= 0 && hours <= 12) {
      this.setState({hours: parseInt(e.target.value)});
    }
  }

  onChangeMinutes = (e) => {
    if (e.target.value === '' || e.target.value === '00') {
      this.setState({minutes: e.target.value});
      return;
    }

    let minutes = parseInt(e.target.value);
    if (minutes >= 0 && minutes <= 59) {
      this.setState({minutes: minutes});
    }
  }

  changeAmPm = () => {
    const value = this.state.ampm === 'AM' ? 'PM' : 'AM';
    this.setState({ampm: value});
  }

  valid = (current) => {
    return this.props.maxDate ?
      current.isSameOrBefore(this.props.maxDate) &&
      current.isSameOrAfter(moment().utcOffset(0).format("YYYY-MM-DD")) :
      current.isSameOrAfter(moment().utcOffset(0).format("YYYY-MM-DD"));
  }

  render() {
    const {custom, input} = this.props;
    let closeCalendarFunction;

    const renderView = (mode, renderDefault) => {
      return (
        <div className={"wrapper-scheduler"}>
          <div className={'date-time-scheduler'}>
            {renderDefault()}
            {mode === 'days' && (
              <div className="scheduled-time">
                <div className="schedule-time">
                  <label>Time:</label>
                  <div className="tooglers tooglers-up">
                    <span onClick={e => this.changeHours('up')} />
                    <span onClick={e => this.changeMinutes('up')} />
                    <span onClick={e => this.changeAmPm()} />
                  </div>
                  <input
                    type="text"
                    min={0}
                    max={12}
                    value={this.state.hours}
                    onChange={this.onChangeHour} />
                  <input
                    type="text"
                    value={this.state.minutes}
                    onChange={this.onChangeMinutes} />
                  <input
                    type="text"
                    value={this.state.ampm} />
                  <div className="tooglers tooglers-down">
                    <span onClick={e => this.changeHours('down')} />
                    <span onClick={e => this.changeMinutes('down')} />
                    <span onClick={e => this.changeAmPm()} />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="controls-wrapper">
            <div className="btn btn-sm done" onClick={() => this.doneButton(closeCalendarFunction)}>Done</div>
            <div className="btn btn-sm reset" onClick={() => this.resetButton(closeCalendarFunction)}>Remove expiration date</div>
          </div>
        </div>
      );
    };

    const renderInput = (props, openCalendar, closeCalendar) => {
      closeCalendarFunction = closeCalendar;
      return (
        <span className={'expires-at-invitation'} onClick={openCalendar}>
          <img alt={"Change Expiration Date"} src={calendarIcon} height={18} />
          {this.state.previewValue && (
            <Moment utc={true} format={'MM/DD/YYYY hh:mm a'}>{this.state.previewValue}</Moment>
          )}
        </span>
      );
    };

    return (
      <Datetime
        timeFormat={false}
        utc={true}
        closeOnSelect={false}
        closeOnClickOutside={true}
        {...custom}
        {...input}
        value={this.state.value}
        className={"on-demand-invitation-expires-at-calendar" + (this.state.hideTime ? ' hide-time' : '')}
        isValidDate={this.valid}
        onClose={this.onClose}
        onChange={this.onChangeCalendar}
        renderView={renderView}
        renderInput={renderInput}
        onNavigate={mode => this.setState({hideTime: mode !== 'days'})}
      />
    );
  }
}
