import React, {Component} from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

const style = {
  canvas: {
    width: '100%',
    height: '100%',
  }
}

class AudioVisualizer extends Component {
  static propTypes = {
    stream: PropTypes.object,
    backgroundColor: PropTypes.string,
    lineColor: PropTypes.string,
  }

  static defaultProps = {
    backgroundColor: 'rgb(255,255,255)',
    lineColor: 'rgb(0, 0, 0)',
  }

  constructor(props) {
    super(props);
    this.canvas = new React.createRef();
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    this.audioContext = new AudioContext();
  }

  componentDidMount() {
    if (this.props.stream) {
      this.connectCanvasWithAudio();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.stream !== this.props.stream) {
      this.connectCanvasWithAudio();
    }
  }

  connectCanvasWithAudio = () => {
    const {stream} = this.props;
    let source = null;
    if (stream) {
      source = this.audioContext.createMediaStreamSource(stream);
    }

    if (!source) {
      return;
    }
    this.visualizeStream(source);
  }

  visualizeStream(source) {
    const {backgroundColor, lineColor} = this.props;
    const canvas = this.canvas.current;
    const canvasCtx = canvas.getContext("2d");
    const analyser = this.audioContext.createAnalyser();
    analyser.fftSize = 2048;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    source.connect(analyser);

    draw()

    function draw() {
      const WIDTH = canvas.width
      const HEIGHT = canvas.height;

      requestAnimationFrame(draw);

      analyser.getByteTimeDomainData(dataArray);

      canvasCtx.fillStyle = backgroundColor;
      canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = lineColor;

      canvasCtx.beginPath();

      let sliceWidth = WIDTH * 1.0 / bufferLength;
      let x = 0;


      for(let i = 0; i < bufferLength; i++) {

        let v = dataArray[i] / 128.0;
        let y = v * HEIGHT/2;

        if(i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      canvasCtx.lineTo(canvas.width, canvas.height/2);
      canvasCtx.stroke();
    }
  }

  render() {
    const {classes} = this.props;
    return (
      <canvas className={classes.canvas} ref={this.canvas} />
    )
  }
}

AudioVisualizer = withStyles(style)(AudioVisualizer);

export default AudioVisualizer;
