import React from "react";
import MessageHistory from "../Chat/MessageHistory";
import UsersTyping from "../Chat/UsersTyping";
import MessageEntryForm from "../Chat/MessageEntryForm";
import PropTypes from "prop-types";
import classNames from 'classnames';

import {
  EVENTS_SESSION_CHAT_MESSAGE,
  EVENTS_SESSION_CHAT_USER_NOT_TYPING,
  EVENTS_SESSION_CHAT_USER_TYPING,
} from "../../utils/liveInterviewSession";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";

const style = {
  root: {
    flexGrow: 1,
    padding: '0 1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100%',
  }
};

class LiveInterviewChat extends React.Component {
  static propTypes = {
    showChat: PropTypes.bool.isRequired,
    session: PropTypes.object.isRequired,
  };

  static defaultProps = {
    showChat: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      messageHistory: [],
      usersTyping: [],
    };

    this.messagesRef = null;
    this.setMessagesRef = elem => {
      this.messagesRef = elem;
    };

    const {session} = this.props;
    this.listenSession(session);
  }

  componentDidMount() {
    this.scrollChatToTheBottom();
  }

  componentWillUnmount() {
    this.removeSessionListeners(this.props.session);
  }

  listenSession = (session) => {
    session.on(EVENTS_SESSION_CHAT_MESSAGE, this.scrollChatToTheBottom)
    session.on(EVENTS_SESSION_CHAT_USER_TYPING, this.addUserTyping);
    session.on(EVENTS_SESSION_CHAT_USER_NOT_TYPING, this.clearUserTyping);
  };

  removeSessionListeners = (session) => {
    session.off(EVENTS_SESSION_CHAT_MESSAGE, this.scrollChatToTheBottom)
    session.off(EVENTS_SESSION_CHAT_USER_TYPING, this.addUserTyping);
    session.off(EVENTS_SESSION_CHAT_USER_NOT_TYPING, this.clearUserTyping);
  };

  clearUserTyping = (message) => {
    let updateTypings = this.state.usersTyping.filter(user => {
      return user.userId !== message.userId;
    });
    this.setState({usersTyping: updateTypings});
  };

  addUserTyping = (message) => {
    const currentUser = message.firstName + ' ' + message.lastName;
    const typingExists = this.state.usersTyping.find(typing => typing.userId === message.userId);
    if (!typingExists) {
      let updateTypings = this.state.usersTyping.concat({userId: message.userId, userName: currentUser});
      this.setState({usersTyping: updateTypings});
    }
  };

  scrollChatToTheBottom = () => {
    if (this.messagesRef) {
      this.messagesRef.scrollTop = this.messagesRef.scrollHeight
    }
  };

  render() {
    const {liveInterview, reconnecting, session, messages, classes, fullscreenEnabled} = this.props;
    const {usersTyping} = this.state;

    return (
      <div className={classNames('live-interview-chat', classes.root)}>
        <MessageHistory
          messages={messages}
          initialDate={liveInterview.startedAt}
          fullscreen={fullscreenEnabled}
          messagesRef={this.setMessagesRef}
        />
        <UsersTyping usersTyping={usersTyping}
          fullscreen={fullscreenEnabled} />
        <MessageEntryForm
          session={session}
          fullscreen={fullscreenEnabled}
          disabled={reconnecting}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  liveInterview: state.liveInterviewPage.liveInterview,
  session: state.liveInterviewPage.session,
  messages: state.liveInterviewPage.chat.messages,
  fullscreenEnabled: state.liveInterviewPage.fullscreen,
  reconnecting: state.liveInterviewPage.reconnectingToInterview,
});

const mapDispatchToProps = dispatch => ({
});

LiveInterviewChat = connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveInterviewChat);

LiveInterviewChat = withStyles(style)(LiveInterviewChat);

export default LiveInterviewChat;
