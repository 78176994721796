import { combineReducers } from 'redux';
import list from './list';
import scheduleList from './scheduleList';
import create from './create';
import update from './update';
import del from './delete';
import show from './show';
import updateTags from './updateTags';
import access from './access';
import allInterviewsList from './allInterviewsList';
import archive from './archive';
import activate from './activate';

export default combineReducers({ list, scheduleList, create, update, del, show, updateTags, access, allInterviewsList, archive, activate });
