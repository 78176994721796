export const LoginFormName = 'login_form';

export const LoginFormStyle = {
  inputContainer: {
    marginBottom: '17px',
    paddingTop: '27px',
  },
  loginPageLinks: {
    textAlign: "center"
  },
  justifyContentCenter: {
    justifyContent: "center !important",
    display: "flex",
  },
};
