import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { list, reset, filter, sort } from '../../actions/Job/list';
import {handleListUpdate, pagination, renderLinks} from "../../utils/entityList";
import { SortableTableCells } from "../../utils/filterForm";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Moment from 'react-moment';
import FilterForm from './FilterForm';
import filtersStorage from '../../utils/filtersStorage';
import IsGranted from "../IsGranted";
import ProfessionMappingView from "../../views/Components/ProfessionMappingView";
import {getRoutePage} from "../../utils/routes";
import { jobLocation } from "../../utils/jobLocation";
import { firstLetterToUppercase } from "../../utils/firstLetterToUpperCase";
import Dialog from "../../views/Components/Dialog/Dialog";
import FlashMessages from "../../views/Components/FlashMessages";

const defaultFilters = {
  'status': 'active',
};

class JobList extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    filter: PropTypes.func.isRequired,
    filtersData: PropTypes.object,
    sort: PropTypes.func.isRequired,
    sortData: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalCreatedJobId: props.location.state ? props.location.state.createdJobId : null,
    };

    props.history.replace({pathname: props.location.pathname, search: props.location.search});
  }

  handleCloseModal = () => {
    this.setState({modalCreatedJobId: null});
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('status');
    let afterRedirectFilters;
    if (status) {
      afterRedirectFilters = {status: status};
      this.props.filter(afterRedirectFilters);
    }

    const storageName = 'company_jobs';
    const filters = afterRedirectFilters ? afterRedirectFilters : filtersStorage.get(storageName, defaultFilters);
    const sort = filtersStorage.getSort(storageName);
    if (filters !== null || sort !== null) {
      this.props.filter(filters);
      this.props.sort(sort);
    } else {
      handleListUpdate('jobs', this.props);
    }
  }

  componentDidUpdate(prevProps) {
    handleListUpdate('jobs', this.props, prevProps);
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {
    return (
      <div>
        <FlashMessages location={this.props.location} />
        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}

        <IsGranted action="create" object={this.props.retrieved}>
        <p>
          <Link to="create" className="btn btn-primary">
            Create
          </Link>
        </p>
        </IsGranted>

        <FilterForm
          defaultValues={defaultFilters}
          initialValues={this.props.filtersData}
          onSubmit={this.props.filter}
          resetFilter={this.props.filterReset}
          parentLoading={this.props.loading}
        />

        <div style={{'overflowX': 'auto'}}>
          <Table className="table table-responsive table-striped table-hover mb-0">
            <TableHead>
              <TableRow>
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                  'id': 'ID',
                }}/>
                <TableCell>Job Number</TableCell>
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                  'title': 'Title',
                }}/>
                <TableCell>Location</TableCell>
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                  'team.name': 'Team',
                  'companyUser.firstName': 'Posted by',
                }}/>
                <TableCell>Industry, Occupation, Specialty</TableCell>
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                  'updatedAt': 'Updated Date',
                }}/>
                <TableCell>Status</TableCell>
                <TableCell colSpan={2} />
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.retrieved &&
                this.props.retrieved['hydra:member'].map(item => (
                  <TableRow key={item['@id']}>
                    <TableCell scope="row">
                      {renderLinks('jobs', item, (job) => job['id'], true)}
                    </TableCell>
                    <TableCell scope="row">
                      {item.jobNumber}
                    </TableCell>
                    <TableCell>
                      {renderLinks('jobs', item, (job) => job['title'], true)}
                    </TableCell>
                    <TableCell scope="row">
                      {jobLocation(item)}
                    </TableCell>
                    <TableCell>{renderLinks('teams', item['team'], undefined, true)}</TableCell>
                    <TableCell>{renderLinks('users', item['companyUser'], (companyUser) => companyUser.firstName + ' '+ companyUser.lastName, true)}</TableCell>
                    <TableCell>
                      {item.professionMappings && item.professionMappings.length > 0 && item.professionMappings.map(
                        (mapping, index) => <ProfessionMappingView key={index} mapping={mapping}/>
                      )}
                    </TableCell>
                    <TableCell>{item['updatedAt'] && (<Moment>{item['updatedAt']}</Moment>)}</TableCell>
                    <TableCell scope="row">
                      {firstLetterToUppercase(item.status)}
                    </TableCell>
                    <TableCell>
                      <IsGranted action="show" object={item}>
                      <Link to={`show/${encodeURIComponent(item['@id'])}`}>
                        <span className="fa fa-eye" aria-hidden="true" />
                        <span className="sr-only">Show</span>
                      </Link>
                      </IsGranted>
                    </TableCell>
                    <TableCell>
                      <IsGranted action="edit" object={item}>
                      <Link to={`edit/${encodeURIComponent(item['@id'])}`}>
                        <span className="fa fa-pencil" aria-hidden="true" />
                        <span className="sr-only">Edit</span>
                      </Link>
                      </IsGranted>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>

        <Table className="table table-responsive no-border">
          <TableFooter>
            <TableRow>
              {pagination(this.props.retrieved, getRoutePage(this.props.match))}
            </TableRow>
          </TableFooter>
        </Table>
        {this.state.modalCreatedJobId && (
          <Dialog
            id={'create-job-success-modal'}
            open={!!this.state.modalCreatedJobId}
            onClose={this.handleCloseModal}
            title={"Congratulations! Your job was created successfully."}
            contentClass={'text-center'}
          >
              <p className={"mt-3"}>Here is what you can do now:</p>
              <Link to={'../requests/create/' + encodeURIComponent(this.state.modalCreatedJobId)} className={'btn btn-info mb-2 create-job-modal-button'}>Create an On Demand Interview</Link>
              <Link to={'../live-interview-schedules/create/' + encodeURIComponent(this.state.modalCreatedJobId)} className={'btn btn-info mb-2 create-job-modal-button'} style={{width: '70%'}}>Schedule a Live Interview</Link>
              <Link to={'/company/jobs/show/' + encodeURIComponent(this.state.modalCreatedJobId) } className={'btn btn-info create-job-modal-button'} style={{width: '70%'}}>View Job</Link>
          </Dialog>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    retrieved,
    loading,
    error,
    eventSource,
    filtersData,
    sortData,
  } = state.job.list;

  return { retrieved, loading, error, eventSource, filtersData, sortData };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource)),
  filter: (filtersData) => dispatch(filter(filtersData)),
  filterReset: () => dispatch(filter(defaultFilters)),
  sort: (sortData) => dispatch(sort(sortData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobList);
