import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { list, reset, filter, sort } from '../../actions/Invitation/list';
import {handleListUpdate, pagination, renderLinks} from "../../utils/entityList";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Moment from 'react-moment';
import filtersStorage from '../../utils/filtersStorage';
import FilterForm from "./FilterForm";
import {SortableTableCells} from "../../utils/filterForm";
import TableFooter from "@material-ui/core/TableFooter";
import {getRoutePage} from "../../utils/routes";
import './Invitation.scss';
import {loadRequestsForSelect} from "../../actions/Request/forSelect";
import InviteCandidatesDialog from "./InviteCandidatesDialog";
import OnDemandInvitationExpirationDatePicker from "../../views/Components/OnDemandInvitationExpirationDatePicker";
import {updateExpiresAt, reset as updateExpiresAtReset} from "../../actions/Invitation/updateExpiresAt";
import FlashMessages from "../../views/Components/FlashMessages";

class InvitationList extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    filter: PropTypes.func.isRequired,
    filtersData: PropTypes.object,
    sort: PropTypes.func.isRequired,
    sortData: PropTypes.object,
  };

  state = {
    inviteCandidatesDialogOpen: false,
  }

  componentDidMount() {
    const storageName = 'company_invitation';
    const filters = filtersStorage.get(storageName, null);
    const sort = filtersStorage.getSort(storageName);
    if (filters !== null || sort !== null) {
      this.props.filter(filters);
      this.props.sort(sort);
    } else {
      handleListUpdate('invitations', this.props);
    }

    this.props.loadRequestsForSelect();
  }

  componentDidUpdate(prevProps) {
    handleListUpdate('invitations', this.props, prevProps);
  }

  componentWillUnmount() {
    this.props.reset();
    this.props.updateExpiresAtReset();
  }

  toggleInviteCandidateDialog = (e) => {
    this.setState({
      inviteCandidatesDialogOpen: !this.state.inviteCandidatesDialogOpen,
    });
  }

  onChangeExpiresAt = (expiresAt, invitationIRI) => {
    this.props.updateExpiresAtReset();
    this.props.updateExpiresAt(invitationIRI, expiresAt);
  }

  render() {
    return (
      <div>
        <FlashMessages location={this.props.location} />

        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}
        {this.props.updateExpiresAtUpdated && (
          <FlashMessages type={"success"} message={"Expiration date has been updated successfully."} duration={10000} />
        )}
        <button onClick={this.toggleInviteCandidateDialog} className={'btn btn-sm btn-primary mb-2'}>Invite Candidates</button>
        <FilterForm
          initialValues={this.props.filtersData}
          onSubmit={this.props.filter}
          resetFilter={this.props.filterReset}
          parentLoading={this.props.loading}
        />

        <div>
          <Table className="table table-responsive table-striped table-hover one-way-interview mb-0">
            <TableHead>
              <TableRow>
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                  'id': 'ID',
                  'firstName': 'Candidate',
                  'request.title': 'On Demand Interview',
                  'request.job.title': 'Job',
                }}/>
                <TableCell>Status</TableCell>
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                  'interviewStatus': 'Progress',
                }}/>
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                  'expiresAt': 'Expires At',
                  'createdAt': 'Created At',
                }}/>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.retrieved &&
                this.props.retrieved['hydra:member'].map(item => (
                  <TableRow key={item['@id']}>
                    <TableCell scope="row" className="align-middle">
                      {item['id']}
                    </TableCell>
                    <TableCell className="align-middle">
                      {item['fullName']}
                    </TableCell>
                    <TableCell className="align-middle">
                      {renderLinks('requests', item['request'], request => request.title)}
                    </TableCell>
                    <TableCell className="align-middle">
                      {renderLinks('jobs', item.request.job, job => job.title)}
                    </TableCell>
                    <TableCell className="align-middle" style={{textTransform: 'capitalize'}}>{item["status"]}</TableCell>
                    <TableCell className="align-middle" style={{textTransform: 'capitalize'}}>
                      {item["interviewStatus"] ? item["interviewStatus"].replace('_', ' ') : ''}
                      <div className="progress-wrapper" style={{borderColor: item['progressBarColor']}}>
                          <div className="progress-bar" style={{width: item['progressBarPercents'] + '%', backgroundColor: item['progressBarColor']}} />
                          <div className={`progress-percents ${item['progressBarPercents'] === 100 ? 'full' : ''} ${item['progressBarColor']}`}>{ item['progressBarPercents'] }%</div>
                        </div>
                    </TableCell>
                    <TableCell className={'on-demand-interview-expires-at-calendar-container'}>
                      <OnDemandInvitationExpirationDatePicker
                        invitationIRI={item['@id']}
                        saveValue={this.onChangeExpiresAt}
                        input={{value: item['expiresAt']}}
                        maxDate={item.request.expiresAt}
                      />
                    </TableCell>
                    <TableCell className="align-middle"><Moment format={Moment.timeFormat}>{item['createdAt']}</Moment></TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>

        <Table className="table table-responsive no-border">
          <TableFooter>
            <TableRow>
              {pagination(this.props.retrieved, getRoutePage(this.props.match))}
            </TableRow>
          </TableFooter>
        </Table>

        <InviteCandidatesDialog
          requests={this.props.requests}
          requestsLoading={this.props.requestsLoading}
          inviteCandidatesDialogOpen={this.state.inviteCandidatesDialogOpen}
          toggleInviteCandidateDialog={this.toggleInviteCandidateDialog}
          history={this.props.history}
        />

      </div>
    );
  }
}

const mapStateToProps = state => {
  const requests = state.request.forSelect.retrieved;
  const requestsLoading = state.request.forSelect.loading;
  const retrieved = state.invitation.list.retrieved;
  const filtersData = state.invitation.list.filtersData;
  const sortData = state.invitation.list.sortData;
  const updateExpiresAtUpdated = state.invitation.updateExpiresAt.updated;
  const loading = state.invitation.list.loading || state.invitation.updateExpiresAt.loading;
  const error = state.invitation.list.error || state.invitation.updateExpiresAt.error;

  return { retrieved, loading, error, filtersData, sortData, requests, requestsLoading, updateExpiresAtUpdated };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: () => dispatch(reset()),
  filter: (filtersData) => dispatch(filter(filtersData)),
  filterReset: () => dispatch(filter(null)),
  sort: (sortData) => dispatch(sort(sortData)),
  loadRequestsForSelect: () => dispatch(loadRequestsForSelect('@id')),
  updateExpiresAt: (invitationIRI, expiresAt) => dispatch(updateExpiresAt(invitationIRI, expiresAt)),
  updateExpiresAtReset: () => dispatch(updateExpiresAtReset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitationList);
