import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Form from './Form';
import { save, reset } from '../../actions/OneWayInterviewReview/save';
import { retrieve } from '../../actions/OneWayInterview/show';
import {getReviewCriteria} from "../../utils/reviewScales";
import {getReviewScales, reset as resetReviewScales} from "../../actions/ReviewScale/show";

class OneWayInterviewReviewCreate extends Component {
  static propTypes = {
    error: PropTypes.string,
    interviewLoading: PropTypes.bool.isRequired,
    interview: PropTypes.object,
    reviewSaveLoading: PropTypes.bool.isRequired,
    savedReview: PropTypes.object,
    createReview: PropTypes.func.isRequired,
  };
  componentDidMount() {
    this.props.getReviewScales();
    this.props.retrieveInterview(decodeURIComponent(this.props.match.params.interview));
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const initialValues = {
      oneWayInterview: decodeURIComponent(this.props.match.params.interview),
    };

    if (this.props.savedReview)
      this.props.history.push({
        pathname: `reviews`,
        state: {successMessage: 'Review successfully created.'}
      });

    return (
      <div>
        {this.props.interview && (
          <h2 className="mb-3">Add Review to On Demand Interview with&nbsp;
            {this.props.interview.companyCandidate.firstName} {this.props.interview.companyCandidate.lastName}
          </h2>
        )}

        {(this.props.interviewLoading ||
          this.props.reviewSaveLoading) && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}
        {!this.props.interviewLoading  && !this.props.criteriaLoading && this.props.modifiedCriteria && (
          <Form
            onSubmit={this.props.createReview}
            initialValues={initialValues}
            reviewCriteria={getReviewCriteria(this.props.modifiedCriteria)}
          />
        )}
        <Link to={`../../on-demand-interviews/show/${this.props.match.params.interview}`} className="btn btn-primary mr-3 mb-3">
          Back to Interview
        </Link>
        <Link to="reviews" className="btn btn-primary mb-3">
          Back to All Reviews
        </Link>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const interviewLoading = state.onewayinterview.show.loading;
  const error = state.onewayinterview.show.error || state.onewayinterviewreview.save.error;
  const interview = state.onewayinterview.show.retrieved;
  const reviewSaveLoading = state.onewayinterviewreview.save.loading;
  const savedReview = state.onewayinterviewreview.save.saved;

  return {
    interviewLoading,
    error,
    interview,
    reviewSaveLoading,
    savedReview,
    modifiedCriteria: state.reviewScale.show.criteria,
    criteriaLoading: state.reviewScale.show.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  createReview: values => dispatch(save(values)),
  retrieveInterview: (id) => dispatch(retrieve(id)),
  getReviewScales: () => dispatch(getReviewScales()),
  reset: () => {
    dispatch(reset());
    dispatch(resetReviewScales());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OneWayInterviewReviewCreate);
