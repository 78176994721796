import React from "react";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import {currentUserHasRole, currentUserRoles} from "../../utils/auth";
import {history} from "../../store";
import {resolveDashboardUrl} from "../../utils/dashboardUrlResolver";
import {Prompt} from "react-router";
import {Link} from "react-router-dom";
import {endTesting, startTesting} from "../../actions/LiveInterviewPage/testing";
import {connect} from "react-redux";
import EndInterviewDialog from "./EndInterviewDialog";
import storage from "../../utils/storage";
import classNames from 'classnames';
import HeaderCompanyLogo from "../Interview/HeaderCompanyLogo";
import RecordingIndicator from "../VideoRecorder/RecordingIndicator";

const LEAVE_PROMPT_TEXT = 'Are you sure you want to leave this interview?';

class LiveInterviewHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      endInterviewDialogShow: false,
    };
    window.reactHistory = history;
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.showClosePagePrompt);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.showClosePagePrompt);
  }

  leaveInterview = () => {
    const roles = currentUserRoles();
    history.push(resolveDashboardUrl(roles));
  };

  showClosePagePrompt = (event) => {
    const {joinedToInterview, liveInterview} = this.props;
    const {endingInterview} = this.state;
    if (joinedToInterview && !(liveInterview && liveInterview.completedAt) && !endingInterview) {
      event.returnValue = LEAVE_PROMPT_TEXT;
      return LEAVE_PROMPT_TEXT;
    }
  };

  renderButtons = () => {
    const {
      testing, isHost, publisher, startingTest, joinedToInterview, startTesting,
      endTesting, loggedIn, liveInterview, interviewEnding
    } = this.props;
    return (
      <>
        {joinedToInterview ? (
          <>
            {isHost && (
              <>
                <button className='btn btn-primary' onClick={() => this.setState({endInterviewDialogShow: true})}>
                  {interviewEnding ? 'Ending...' : 'End Interview'}
                </button>
                <EndInterviewDialog
                  showDialog={this.state.endInterviewDialogShow}
                  endInterview={this.endInterview}
                  closeDialog={() => this.setState({endInterviewDialogShow: false})}
                />
              </>
            )}
            <Prompt message={LEAVE_PROMPT_TEXT} when={liveInterview && !liveInterview.completedAt && !interviewEnding}/>
            <button className='btn btn-primary' onClick={this.leaveInterview}>Leave Interview</button>
          </>
        ) : (
          <>
            {loggedIn ? (
              <>
                {testing ? (
                  <button className='btn btn-danger' onClick={() => endTesting(publisher)}>End testing</button>
                ) : (
                  <button className='btn btn-danger' onClick={startTesting}>
                    {startingTest ? 'Starting...' : 'Test Audio/Video'}
                  </button>
                )}
                <Link to={currentUserHasRole('ROLE_CANDIDATE') ? '/candidate/user-profile' : '/company/user-profile'} className="btn btn-primary">
                  My Account
                </Link>
              </>
            ) : (
              <button className="btn btn-primary" onClick={() => {
                storage.set('afterLoginUrl', window.location.pathname);
                history.push('/login');
              }}>Log In</button>
            )}
          </>
        )}
      </>
    )
  };

  render() {
    const {liveInterview, fullscreenEnabled, recording} = this.props;
    const company = liveInterview && liveInterview.job ? liveInterview.job.company : null;

    return (
      <div className={classNames('live-interview-page-header', 'interview-page-header')}>
        <GridContainer className='interview-header-grid'>
          <GridItem xs={4} md={fullscreenEnabled ? 6 : 7} className='interview-header-company-container'>
            <HeaderCompanyLogo company={company} />
          </GridItem>
          <GridItem xs={8} md={fullscreenEnabled ? 6 : 5} className='interview-header-buttons-container'>
            {recording && (
              <div className={'live-interview-page-header-recording-wrapper'}>
                <RecordingIndicator />
              </div>
            )}
            {this.renderButtons()}
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  recording: state.liveInterviewPage.recording,
  testing: state.liveInterviewPage.testing,
  startingTest: state.liveInterviewPage.startingTest,
  session: state.liveInterviewPage.session,
  isHost: state.liveInterviewPage.isHost,
  joinedToInterview: state.liveInterviewPage.joinedToInterview,
  interviewEnding: state.liveInterviewPage.endingInterview,
  liveInterview: state.liveInterviewPage.liveInterview,
  publisher: state.liveInterviewPage.publisher,
  loggedIn: state.liveInterviewPage.auth.loggedIn,
  fullscreenEnabled: state.liveInterviewPage.fullscreen,
});

const mapDispatchToProps = dispatch => ({
  startTesting: () => dispatch(startTesting()),
  endTesting: (publisher) => dispatch(endTesting(publisher)),
});

LiveInterviewHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveInterviewHeader);

export default LiveInterviewHeader;
