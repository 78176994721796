import React, {Component} from "react";
import {Field, Fields} from "redux-form";
import {renderCheckbox, renderDatePicker, renderTextField} from "../../utils/form";
import Switch from "@material-ui/core/Switch";
import "./RequestBasicInfo.scss";
import moment from "moment";
import IntroOutroVideoFields, {INTRO_VIDEO_FIELD, OUTRO_VIDEO_FIELD} from "./IntroOutroVideoFields";

export default class RequestBasicInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showExpiresAt: !!this.props.initialValues.expiresAt,
            showInvitationExpirationDays: !!this.props.initialValues.invitationExpirationDays,
        };
    }

    parseRetries = (number) => parseInt(number);

    toggleExpiresAt = (e, value) => {
        this.props.change('expiresAt', null);
        this.setState({showExpiresAt: value});
    }

    toggleInvitationExpirationDays = (e, value) => {
        this.props.change('invitationExpirationDays', null);
        this.setState({showInvitationExpirationDays: value});
    }

    render() {
        return (
            <div>
                <div className={"manage-on-demand-interview-expiration-settings"}>
                    <p className={'expiration-settings-title'}>Expiration Settings:</p>
                    <div className={"clearfix"}>
                        <div className={"float-left"}>
                            <Switch
                                checked={(this.state.showExpiresAt)}
                                onChange={this.toggleExpiresAt}
                                color="primary"
                            />
                        </div>
                        <label className={"float-left mt-3" + (this.state.showExpiresAt ? ' text-dark' : '')}>
                            <strong>Set Expiration for Interview</strong>
                            {this.state.showExpiresAt && (
                                <span className={'ml-3'}>Date </span>
                            )}
                        </label>
                        {this.state.showExpiresAt && (
                            <div className={'expiration-settings-expires-at'}>
                                <Field
                                    component={renderDatePicker}
                                    name={`expiresAt`}
                                    label={''}
                                    isValidDate={d => d.isSameOrAfter(moment().format("YYYY-MM-DD"))}
                                />
                            </div>
                        )}
                    </div>

                    <div className={"clearfix"}>
                        <div className={"float-left"}>
                            <Switch
                                checked={(this.state.showInvitationExpirationDays)}
                                onChange={this.toggleInvitationExpirationDays}
                                color="primary"
                            />
                        </div>
                        <label className={"float-left mt-3" + (this.state.showInvitationExpirationDays ? ' text-dark' : '')}>
                            <strong>Set Expiration for Invitation</strong>
                            {this.state.showInvitationExpirationDays && (
                                <span className={'ml-3'}>In </span>
                            )}
                        </label>
                        {this.state.showInvitationExpirationDays && (
                            <>
                                <div className={'expiration-settings-invitation-expiration-days'}>
                                    <Field
                                        component={renderTextField}
                                        name={`invitationExpirationDays`}
                                        type={"number"}
                                        label={false}
                                        inputProps={{min: 1, max: 365}}
                                        placeholder={""}
                                        normalize={v => parseInt(v)}
                                    />
                                </div>
                                <span className={'float-left mt-3'}>Days</span>
                            </>
                        )}
                    </div>
                    <p className={'expiration-settings-invitation-info'}>Once one invitation is sent, candidates will have the selected amount of time to complete the interview. After this time period the invitation will expire. The candidate's invitations could expire sooner if the interview itself expire first.</p>
                </div>
                <Field
                    name="resumeRequired"
                    label="Require candidate to upload a CV/resume before starting the interview"
                    component={renderCheckbox}
                    fullWidth
                />
                <Fields
                    label={"Intro Video"}
                    names={['requestIntroVideo', 'requestIntroVideoUrl']}
                    component={IntroOutroVideoFields}
                    type={INTRO_VIDEO_FIELD}
                />
                <Fields
                    label={"Outro Video"}
                    names={['requestOutroVideo', 'requestOutroVideoUrl']}
                    component={IntroOutroVideoFields}
                    type={OUTRO_VIDEO_FIELD}
                />
            </div>
        );
    }
}
