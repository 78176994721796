import React from "react";
import ErrorPage from "./ErrorPage";

export default class NotFound extends React.Component {
    render() {
        return (
            <ErrorPage errorTitle="Not Found"
                       errorDescription="The page you are trying to access does not exist"
            />
        );
    }
}
