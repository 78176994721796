import {fetchJSON} from "../../utils/dataAccess";

export function loadIndustriesForSelect(valueProperty = 'id', isPublic = false) {
  return dispatch => {
    dispatch(loading(true));
    const url = isPublic ? '/industries/select_public' : '/industries/select';

    fetchJSON(url).then((industries) => {
      dispatch(industriesLoaded(industries['hydra:member'].map(
        function (industry) {
          return {
            value: industry[valueProperty],
            text: industry.name,
          }
        }
      )));
      dispatch(loading(false));
    }).catch(e => {
      dispatch(loading(false));
      dispatch(error(e.message));
    })
  };
}

export function loading(loading) {
  return {type: 'INDUSTRIES_FOR_SELECT_LOADING', loading};
}

export function error(error) {
  return {type: 'INDUSTRIES_FOR_SELECT_ERROR', error};
}

export function industriesLoaded(retrieved) {
  return {type: 'INDUSTRIES_FOR_SELECT_RETRIEVED', retrieved};
}
