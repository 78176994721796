import {
  containerFluid,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react.jsx";

const headerStyle = theme => ({
  itemLink: {
    paddingLeft: "10px",
    paddingRight: "10px",
    transition: "all 300ms linear",
    borderRadius: "3px",
    position: "relative",
    display: "block",
    padding: "10px 15px",
    backgroundColor: "transparent",
    ...defaultFont,
    width: "auto",
    zIndex: '5',
    "&:hover": {
      outline: "none",
      backgroundColor: "rgba(" + hexToRgb(grayColor[17]) + ", 0.2)",
      boxShadow: "none"
    },
    "&,&:hover,&:focus": {
      color: "inherit"
    },
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",
      left: "0",
      height: "1px",
      width: "100%",
      backgroundColor: "hsla(0,0%,100%,.3)",
      zIndex: '5',
    }
  },
  itemIcon: {
    color: "inherit",
    width: "30px",
    height: "24px",
    float: "left",
    position: "inherit",
    top: "3px",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    opacity: "0.8",
    zIndex: '5',
  },
  loadingIcon: {
    margin: '3px 21px 3px 6px',
    float: "left",
    position: "inherit",
    top: "3px",
    textAlign: "center",
    verticalAlign: "middle",
    opacity: "0.8",
    zIndex: '5',
  },
  itemText: {
    color: "inherit",
    ...defaultFont,
    margin: "0",
    lineHeight: "30px",
    fontSize: "14px",
    transform: "translate3d(0px, 0, 0)",
    opacity: "1",
    transition: "transform 300ms ease 0s, opacity 300ms ease 0s",
    position: "relative",
    display: "block",
    height: "auto",
    whiteSpace: "nowrap",
    zIndex: '5',
  },
  caret: {
    transition: "all 150ms ease-in",
    display: "inline-block",
    width: "0",
    height: "0",
    marginLeft: "7px",
    verticalAlign: "middle",
    borderTop: "4px solid",
    borderRight: "4px solid transparent",
    borderLeft: "4px solid transparent",
  },
  switchUserRow: {
    padding: '10px',
    cursor: 'pointer',
    '&:hover': {
      'backgroundColor': '#eaeaea',
    },
  },
  switchUserRowFirst: {
    padding: '10px',
    'backgroundColor': '#f1f1f1',
  },
  switchUserRowEmail: {
    fontSize: '12px',
    color: '#777'
  },
  switchUserRowRoleName: {
    fontSize: '12px',
    color: '#333',
  },
});

export default headerStyle;
