import React from "react";
import LiveInterviewPage from "../../components/LiveInterviewPage/LiveInterviewPage";
import {initializeByInvitationCode} from "../../actions/LiveInterviewPage/interview";
import {connect} from "react-redux";

class LiveInterviewByInvitationPage extends React.Component {
  componentDidMount() {
    this.props.initializeByInvitationCode(this.getInvitationCode());
  }

  getInvitationCode = () => {
    return decodeURIComponent(this.props.match.params.code)
  };

  render() {
    return (
      <LiveInterviewPage />
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const invitationType = ownProps.location.pathname.indexOf('company-user') > -1 ? "company" : "candidate";
  return {
    initializeByInvitationCode: (invitationCode) => dispatch(initializeByInvitationCode(invitationCode, invitationType)),
  }
};

LiveInterviewByInvitationPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveInterviewByInvitationPage);

export default LiveInterviewByInvitationPage;
