import React, {Component} from 'react';
import AudioVideoTestComponent from "../VideoTest/AudioVideoTest";
import PropTypes from "prop-types";
import ChangeDeviceNotification from "../VideoControl/ChangeDeviceNotification";
import ChangeDeviceButtons from "./ChangeDeviceButtons";

class AudioVideoTestMobile extends Component {
  static propTypes = {
    streamManager: PropTypes.object,
    audioDevices: PropTypes.object,
    videoDevices: PropTypes.object,
    onTestingStart: PropTypes.func,
    onTestingEnd: PropTypes.func,
    testingStarted: PropTypes.bool,
  };

  static defaultProps = {
    audioDevices: {},
    videoDevices: {},
    testingStarted: false,
  };

  render() {
    const { streamManager, videoDevices, audioDevices, testingStarted } = this.props;
    return (
      <div className={"on-demand-interview-page-mobile-audio-video-test-component-container"}>
        <ChangeDeviceButtons
          streamManager={streamManager}
          videoDevices={videoDevices}
          audioDevices={audioDevices}
          visible={!testingStarted}
        />
        <ChangeDeviceNotification
          streamManager={streamManager}
          notificationContainerClass={'on-demand-interview-video-notification'}
        />
        <AudioVideoTestComponent
          streamManager={this.props.streamManager}
          autoStart={false}
          onTestingStart={this.props.onTestingStart}
          onTestingEnd={this.props.onTestingEnd}
          allowLocalRecording={true}
        />
      </div>
    )
  }
}

export default AudioVideoTestMobile;
