import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { retrieve, reset } from '../../actions/CompanyUser/show';
import { del } from '../../actions/CompanyUser/delete';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Moment from 'react-moment';
import IsGranted from "../IsGranted";
import Chip from "@material-ui/core/Chip";
import FlashMessages from "../../views/Components/FlashMessages";
import PhoneNumber from "../../views/Components/PhoneNumber";

class CompanyUserShow extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    deleteError: PropTypes.string,
    deleteLoading: PropTypes.bool.isRequired,
    deleted: PropTypes.object,
    del: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  del = () => {
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.del(this.props.retrieved);
  };

  renderTeams = (teams) => {
    if (teams.length && teams.length > 0) {
      return teams.map((team, i) => (
          <Chip key={i} label={team.name}/>
      ));
    } else {
      return <span>-</span>;
    }
  };

  render() {
    if (this.props.deleted)
      this.props.history.push({
        pathname: `..`,
        state: {successMessage: 'Company User successfully deleted.'}
      });

    const item = this.props.retrieved;

    return (
      <div>
        <h1>Show &quot;{item && item['firstName']} {item && item['lastName']}&quot;</h1>
        <FlashMessages location={this.props.location} />

        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}
        {this.props.deleteError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.deleteError}
          </div>
        )}

        <div className="grid-buttons">
          <Link to=".." className="btn btn-primary">
            Back to list
          </Link>
          {item && (
            <IsGranted action="edit" object={item}>
            <Link to={`../edit/${encodeURIComponent(item['@id'])}`} className="btn btn-warning">Edit</Link>
            </IsGranted>
          )}
          {item && (
            <IsGranted action="delete" object={item}>
            <button onClick={this.del} className="btn btn-danger">
              Delete
            </button>
            </IsGranted>
          )}
        </div>

        {item && (
          <div style={{'overflowX': 'auto'}}>
            <Table className="table table-responsive table-striped table-hover">
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell scope="row">First Name</TableCell>
                  <TableCell>{item['firstName']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Last Name</TableCell>
                  <TableCell>{item['lastName']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Email</TableCell>
                  <TableCell>{item['email']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Phone Number</TableCell>
                  <TableCell>
                    <PhoneNumber phone={item['phoneNumber']} formattedPhone={item['formattedPhoneNumber']} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Status</TableCell>
                  <TableCell>{item['status']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Role</TableCell>
                  <TableCell>{item['mainRole']}</TableCell>
                </TableRow>
                {item && item['teams'] !== undefined && (
                <TableRow>
                  <TableCell scope="row">Teams</TableCell>
                  <TableCell>{this.renderTeams(item['teams'])}</TableCell>
                </TableRow>
                )}
                <TableRow>
                  <TableCell scope="row">Created At</TableCell>
                  <TableCell>{item['createdAt'] && (<Moment>{item['createdAt']}</Moment>)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Updated At</TableCell>
                  <TableCell>{item['updatedAt'] && (<Moment>{item['updatedAt']}</Moment>)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        )}

      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.companyUser.show.retrieved,
  error: state.companyUser.show.error,
  loading: state.companyUser.show.loading,
  eventSource: state.companyUser.show.eventSource,
  deleteError: state.companyUser.del.error,
  deleteLoading: state.companyUser.del.loading,
  deleted: state.companyUser.del.deleted
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  del: item => dispatch(del(item)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyUserShow);
