import React from "react";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import JobSalary from "../Job/JobSalary";

const style = {
  root: {
    padding: '1em',
    paddingTop: '0',
  },
  cityString: {
    marginBottom: '1em',
    display: 'block',
  },
  infoList: {
    listStyle: 'none',
    paddingLeft: '0',
    '& li:not(:last-child)': {
      marginBottom: '1em',
    }
  }
};

class VideoInterviewJobInfo extends React.Component {
  renderJobInfoList = () => {
    const {classes, liveInterview: {job}} = this.props;
    const industries = [];
    job.professionMappings.forEach((mapping) => {
      const parts = [];
      if (mapping.industry) {
        parts.push(mapping.industry.name);
      }
      if (mapping.occupation) {
        parts.push(mapping.occupation.name);
      }
      if (mapping.specialty) {
        parts.push(mapping.specialty.name);
      }
      industries.push(parts.join(', '));
    });

    return (
      <ul className={classes.infoList}>
        <li><b>Industry</b> {industries.join(' / ')}</li>
        <li><b>Employment Type</b> {job.employmentType}</li>
        <li><b>Salary</b> <JobSalary job={job} /></li>
        <li><b>Visa Sponsorship</b> {job.visaSponsorship ? 'YES' : 'NO'}</li>
        <li><b>Relocation</b> {job.relocation ? 'YES' : 'NO'}</li>
      </ul>
    )
  };
  render() {
    const {classes, liveInterview: {job}} = this.props;
    return (
      <div className={classes.root}>
        <h3>{job.title}</h3>
        <b className={classes.cityString}>{job.city}{job.state && (', ' + job.state.code)}</b>
        <div className={'interview-page-job-description ql-editor'}
             dangerouslySetInnerHTML={{__html: job.description }}
        />
        <div>{this.renderJobInfoList()}</div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  liveInterview: state.liveInterviewPage.liveInterview,
});

const mapDispatchToProps = dispatch => ({
});

VideoInterviewJobInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoInterviewJobInfo);


VideoInterviewJobInfo = withStyles(style)(VideoInterviewJobInfo);

export default VideoInterviewJobInfo;
