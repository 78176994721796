import React, {Component} from "react";
import {Dialog as MaterialDialog} from "@material-ui/core";
import PropTypes from "prop-types";

const defaultProperties = {
  fullWidth: true,
  maxWidth: "sm",
  scroll: "paper",
};

class DialogRoot extends Component {
  static propTypes = {
    id: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    properties: PropTypes.object,
    className: PropTypes.string,
    titleId: PropTypes.string,
    contentId: PropTypes.string,
  }

  static defaultProps = {
    properties: {},
  }

  render() {
    const {id, open, onClose, titleId, contentId, properties, className, children} = this.props;
    const classes = {paper: 'io-dialog-paper'};
    if (properties.subscriptionTheme) {
      classes['paper'] += ' io-dialog-subscription-paper';
    }

    return (
      <MaterialDialog
        id={id}
        open={open}
        onClose={onClose}
        aria-labelledby={titleId}
        aria-describedby={contentId}
        {...Object.assign({}, defaultProperties, properties)}
        className={'io-dialog' + (className ? (' ' + className) : '')}
        classes={classes}
      >
        {children}
      </MaterialDialog>
    );
  }
}

export default DialogRoot;
