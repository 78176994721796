import React, {Component} from 'react';
import {isInterviewExpired, isInvitationAccepted, isInvitationDeclined} from "../../utils/onDemandInterview";
import {STEP_ABOUT} from "./OnDemandInterviewSteps";
import {setStep} from "../../actions/OnDemandInterviewPage/page";
import {connect} from "react-redux";
import classNames from 'classnames';
import DeclineInvitationDialog from "../Interview/DeclineInvitationDialog";
import {acceptInvitation, declineInvitation} from "../../actions/OnDemandInterviewPage/invitation";
import {currentUserHasRole, isAuthenticated} from "../../utils/auth";

class InterviewInfoActions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      declineInvitationDialogOpened: false,
    };
  }

  renderStartInterviewButton = () => {
    return (
      <button
        type="button"
        className='btn btn-success'
        onClick={() => {
          this.props.setStep(STEP_ABOUT);
        }}
      >
        Start Interview
      </button>
    )
  }

  renderAcceptDeclineButtons = () => {
    const {request, invitation, accepting, declining} = this.props;
    const expired = isInterviewExpired(request, invitation);
    const accepted = isInvitationAccepted(invitation);
    const declined = isInvitationDeclined(invitation);

    const buttonDisabled = accepted || declined || accepting || declining || expired;

    return (
      <>
        <button
          type="button"
          className={classNames('btn', {
            'btn-success': !expired && !declined,
            'btn-secondary': expired || declined,
          })}
          disabled={buttonDisabled}
          onClick={() => {
            this.props.acceptInvitation(this.props.invitation);
          }}
        >
          {accepting ? 'Accepting...' : (accepted ? 'Accepted' : 'Accept')}
        </button>
        <button
          type="button"
          className={classNames('btn', 'on-demand-interview-page-decline-button', {
            'btn-danger': !expired && !declined,
            'btn-secondary': expired || declined,
          })}
          disabled={buttonDisabled}
          onClick={() => this.setState({declineInvitationDialogOpened: true})}
        >
          {declining ? 'Declining...' : (declined ? 'Declined' : 'Decline')}
        </button>
        <DeclineInvitationDialog
          open={this.state.declineInvitationDialogOpened}
          handleClose={() => this.setState({declineInvitationDialogOpened: false})}
          declineInvitation={() => {
            this.props.declineInvitation(this.props.invitation);
            this.setState({declineInvitationDialogOpened: false});
          }}
        />
      </>
    );
  }

  render() {
    const {request, invitation, loggedIn} = this.props;
    const expired = isInterviewExpired(request, invitation);
    const isAuth = isAuthenticated() || loggedIn;
    const isCandidate = currentUserHasRole('ROLE_CANDIDATE');

    return (
      <div className={classNames('interview-page-interview-info-actions', 'on-demand-interview-page-interview-info-actions')}>
        {!expired && !invitation ? (
          (!isAuth || isCandidate) ? this.renderStartInterviewButton() : null
        ) : (
          (!isAuth || isCandidate) ? this.renderAcceptDeclineButtons() : null
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  request: state.onDemandInterviewPage.request,
  invitation: state.onDemandInterviewPage.invitation,
  loggedIn: state.onDemandInterviewPage.auth.loggedIn,
  accepting: state.onDemandInterviewPage.invitationState.accepting,
  declining: state.onDemandInterviewPage.invitationState.declining,
});

const mapDispatchToProps = dispatch => ({
  acceptInvitation: (invitation) => dispatch(acceptInvitation(invitation)),
  declineInvitation: (invitation) => dispatch(declineInvitation(invitation)),
  setStep: (step) => dispatch(setStep(step)),
});

InterviewInfoActions = connect(
  mapStateToProps, mapDispatchToProps
)(InterviewInfoActions);

export default InterviewInfoActions;
