import React, { Component } from 'react';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import PropTypes from 'prop-types';
import {renderTextareaField} from "../../utils/form";
import Rating from "../../views/Components/Rating";
import "./Style.scss";

class ReviewForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reviewCriteria: PropTypes.object.isRequired,
    error: PropTypes.string
  };

  onSubmit = (values) => {
    return new Promise(() => {
      const {reviewCriteria} = this.props;
      let errors = {};
      Object.keys(reviewCriteria).forEach(number => {
        const fieldName = this.getCriteriaFieldName(number);
        const fieldLabel = reviewCriteria[number].name;
        const value = values[fieldName];
        if (!value) {
          errors[fieldName] = fieldLabel + ' should be rated.';
        }
      });

      if (Object.keys(errors).length > 0) {
        throw new SubmissionError(errors);
      }
      this.props.onSubmit(values);
    })
  }

  getCriteriaFieldName = (number) => {
    return `criteria${number}`;
  }

  render() {
    const {reviewCriteria} = this.props;
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        {Object.keys(reviewCriteria).map(number => {
          const fieldName = this.getCriteriaFieldName(number);
          const fieldLabel = reviewCriteria[number].name;
          return (
            <div key={`review-criteria-${number}-field`}>
              <span>{fieldLabel}:</span>
              <Field
                component={Rating}
                name={fieldName}
                className="review-rating-block"
              />
            </div>
          )
        })}
        <Field
          component={renderTextareaField}
          name="comment"
          placeholder="Comment"
        />
        <button type="submit" className="btn btn-success float-left mr-3 mb-3">
          Save Review
        </button>
      </form>
    );
  }
}

ReviewForm = reduxForm({
  form: 'one_way_interview_review',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(ReviewForm);

export default ReviewForm;
