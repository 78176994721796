import {fetchJSON} from "../../utils/dataAccess";

export function loadCategories() {
  return dispatch => {
    fetchJSON('/question_bank_categories').then((categories) => {
      dispatch(categoriesLoaded(categories['hydra:member'].map(
        function (category) {
          return {
            value: category['@id'],
            text: category.name,
          }
        }
      )));
    }).catch(e => {
      dispatch(error(e.message));
    })
  };
}

export function error(error) {
  return {type: 'QUESTIONBANK_CATEGORIES_LOAD_ERROR', error};
}

export function categoriesLoaded(categories) {
  return {type: 'QUESTIONBANK_CATEGORIES_LOADED', categories};
}
