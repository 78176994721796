import { fetch } from '../../utils/dataAccess';
import {filterAction, sortAction} from "../../utils/filterAndSortUtils";

export function error(error) {
  return { type: 'LIVE_INTERVIEW_CANDIDATE_INVITATION_LIST_ERROR', error };
}

export function loading(loading) {
  return { type: 'LIVE_INTERVIEW_CANDIDATE_INVITATION_LIST_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'LIVE_INTERVIEW_CANDIDATE_INVITATION_LIST_SUCCESS', retrieved };
}

export function filter(filtersData) {
  return filterAction( 'LIVE_INTERVIEW_CANDIDATE_INVITATION_LIST_FILTER', 'company_invitation', filtersData)
}

export function sort(sortData) {
  return sortAction('LIVE_INTERVIEW_CANDIDATE_INVITATION_LIST_SORT','company_live_interview_candidate_invitation', sortData)
}

export function list(page = 'live_interview_candidate_invitations') {
  return dispatch => {
    dispatch(loading(true));
    dispatch(error(''));

    fetch(page)
      .then(response => (response.data))
      .then((retrieved) => {
        dispatch(loading(false));
        dispatch(success(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch({ type: 'LIVE_INTERVIEW_CANDIDATE_INVITATION_LIST_RESET' });
  };
}
