import { title, whiteColor, blackColor } from "assets/jss/material-dashboard-pro-react.jsx";

const errorPageStyles = theme => ({
  contentCenter: {
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: "3",
    transform: "translate(-50%,-50%)",
    textAlign: "center",
    color: whiteColor,
    padding: "0 15px",
    width: "100%",
    maxWidth: "880px"
  },
  title: {
    ...title,
    fontSize: "5em",
    color: whiteColor,
    letterSpacing: "14px",
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3em"
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "4em"
    },
  },
  black: {
    color: blackColor
  },
  subTitle: {
    fontSize: "2.25rem",
    marginTop: "0",
    marginBottom: "8px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.25em"
    },
  },
  description: {
    fontSize: "2.25rem",
    marginTop: "0",
    marginBottom: "8px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.25em"
    },
  }
});

export default errorPageStyles;
