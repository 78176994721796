import {fetch, extractHubURL, normalize, clearValues} from '../../utils/dataAccess';
import {SubmissionError} from "redux-form";

export function retrieveError(error) {
  return { type: 'COMPANY_CURRENT_RETRIEVE_ERROR', error };
}

export function retrieveLoading(loading) {
  return { type: 'COMPANY_CURRENT_RETRIEVE_LOADING', loading };
}

export function retrieveSuccess(retrieved) {
  return { type: 'COMPANY_CURRENT_RETRIEVE_SUCCESS', retrieved };
}

export function getCurrentCompany(normalizeData = true) {
  return dispatch => {
    dispatch(retrieveLoading(true));

    return fetch('/companies/current')
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        if (normalizeData) {
          retrieved = normalize(retrieved);
        }
        dispatch(retrieveLoading(false));
        dispatch(retrieveSuccess(retrieved));
      })
      .catch(e => {
        dispatch(retrieveLoading(false));
        dispatch(retrieveError(e.message));
      });
  };
}

export function updateError(updateError) {
  return { type: 'COMPANY_CURRENT_UPDATE_ERROR', updateError };
}

export function updateLoading(loading) {
  return { type: 'COMPANY_CURRENT_UPDATE_LOADING', loading };
}

export function updateSuccess(updated) {
  return { type: 'COMPANY_CURRENT_UPDATE_SUCCESS', updated };
}

export function updateCurrentCompany(values) {
  return dispatch => {
    dispatch(updateError(null));
    dispatch(updateLoading(true));
    dispatch(updateSuccess(null));

    values = clearValues(values, ['country', 'state', 'team']);

    return fetch('/companies/current', {
      method: 'PUT',
      data: values
    })
      .then(response => response.data)
      .then(retrieved => {
        retrieved = normalize(retrieved);
        dispatch(updateLoading(false));
        dispatch(updateSuccess(retrieved));
      })
      .catch(e => {
        dispatch(updateLoading(false));
        if (e instanceof SubmissionError) {
          dispatch(updateError(e.errors._error));
          throw e;
        }
        dispatch(updateError(e.message));
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({type: 'COMPANY_CURRENT_RETRIEVE_RESET'});
    dispatch({type: 'COMPANY_CURRENT_UPDATE_RESET'});
  };
}
