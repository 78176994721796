import Loader from "../Loader/Loader";
import {BigPlayButton} from "video-react";
import OpenViduVideoComponent from "../video/OpenViduVideoComponent";
import React from "react";
import VideoPlayer from "../video/VideoPlayer";

export default function RecorderVideo(props) {
    const {
        stoppingRecord,
        recordingUrl,
        recordedVideoRef,
        streamManager,
        videoStreamLoaded,
        className,
        videoText,
    } = props;
    const showRecording = stoppingRecord || recordingUrl;

    return (
        <div className={className}>
            {showRecording ? (
            recordingUrl == null ? (
                <div className="placeholder">
                    <Loader/>
                </div>
            ) : (
                <VideoPlayer
                    ref={recordedVideoRef}
                    src={recordingUrl}
                >
                    <BigPlayButton position="center" />
                </VideoPlayer>
            )
            ) : (
                (!streamManager || streamManager.stream && streamManager.stream.hasVideo) ? (
                    <OpenViduVideoComponent
                        className="answer-recording-own-stream"
                        streamManager={streamManager}
                        videoLoaded={videoStreamLoaded}
                        videoText={videoText}
                    />
                ) : (
                    <div className="placeholder no-video-placeholder">
                        No Video
                    </div>
                )
            )}
        </div>
    )
}
