import {fetchJSON} from "../../utils/dataAccess";

export function loadOccupationsForSelect(valueProperty = 'id', isPublic = false) {
  return dispatch => {
    dispatch(loading(true));
    const url = isPublic ? '/occupations/select_public' : '/occupations/select';
    fetchJSON(url).then((occupations) => {
      dispatch(occupationsLoaded(occupations['hydra:member'].map(
        function (occupation) {
          return {
            value: occupation[valueProperty],
            text: occupation.name,
            industry: occupation.industry,
          }
        }
      )));
      dispatch(loading(false));
    }).catch(e => {
      dispatch(loading(false));
      dispatch(error(e.message));
    })
  };
}

export function loading(loading) {
  return {type: 'OCCUPATIONS_FOR_SELECT_LOADING', loading};
}

export function error(error) {
  return {type: 'OCCUPATIONS_FOR_SELECT_ERROR', error};
}

export function occupationsLoaded(retrieved) {
  return {type: 'OCCUPATIONS_FOR_SELECT_RETRIEVED', retrieved};
}
