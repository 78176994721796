export function testing (state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_TESTING':
      return action.testing;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

export function startingTest(state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_STARTING_TEST':
      return action.starting;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}
