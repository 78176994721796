export function isObject(variable) {
  return typeof variable === 'object' && variable !== null;
}

export function isEmptyObject(object) {
  return Object.keys(object).length === 0;
}

export function areTheSameObjects(object1, object2) {
  if (object1 === object2) {
    return true;
  }

  if (typeof object1 !== typeof object2) {
    return false;
  }

  const props1 = Object.getOwnPropertyNames(object1);
  const props2 = Object.getOwnPropertyNames(object2);

  if (props1.length !== props2.length) {
    return false;
  }

  for (let i = 0; i < props1.length; i++) {
    let prop = props1[i];
    let propsAreObjects = typeof(object1[prop]) === 'object' && typeof(object2[prop]) === 'object';

    if (
      (!propsAreObjects && (object1[prop] !== object2[prop])) ||
      (propsAreObjects && !areTheSameObjects(object1[prop], object1[prop]))
    ) {
      return false;
    }
  }

  return true;
}
