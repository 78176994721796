import {combineReducers} from "redux";

export function error(state = null, action) {
  switch (action.type) {
    case 'REVIEW_SCALES_SHOW_ERROR':
      return action.error;
    case 'REVIEW_SCALES_SHOW_RESET':
      return null;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'REVIEW_SCALES_SHOW_LOADING':
      return action.loading;
    case 'REVIEW_SCALES_SHOW_RESET':
      return false;

    default:
      return state;
  }
}

export function criteria(state = null, action) {
  switch (action.type) {
    case 'REVIEW_SCALES_SHOW_RETRIEVED':
      return action.criteria;
    case 'REVIEW_SCALES_SHOW_RESET':
      return null;

    default:
      return state;
  }
}

export default combineReducers({ error, loading, criteria });
