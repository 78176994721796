import {fetch} from '../../utils/dataAccess';

export function error(error) {
  return { type: 'CANDIDATE_ONEWAYINTERVIEW_INVITATION_SHOW_ERROR', error };
}

export function loading(loading) {
  return { type: 'CANDIDATE_ONEWAYINTERVIEW_INVITATION_SHOW_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'CANDIDATE_ONEWAYINTERVIEW_INVITATION_SHOW_SUCCESS', retrieved };
}

export function retrieve(id) {
  return dispatch => {
    dispatch(loading(true));

    id = id.toString();
    return fetch(id)
      .then(response => response.data)
      .then(retrieved => {
        dispatch(loading(false));
        dispatch(success(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch({ type: 'CANDIDATE_ONEWAYINTERVIEW_INVITATION_SHOW_RESET' });
    dispatch(error(null));
    dispatch(loading(false));
  };
}
