import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {renderTextField} from "../../utils/form";
import {Field, reduxForm} from "redux-form";

const style = {
    justifyContentCenter: {
        justifyContent: "center !important",
        display: "flex",
        paddingTop: "10px"
    },
};

const validate = values => {
    const errors = {};
    if (!values.newPassword) {
        errors.newPassword = 'Please enter new password';
    }
    if (!values.repeatPassword) {
        errors.repeatPassword = 'Please repeat password';
    } else if (values.newPassword !== values.repeatPassword) {
        errors.repeatPassword = 'New password and repeat password should be the same';
    }

    return errors;
};

class PasswordResetForm extends React.Component {
    render() {
        const { handleSubmit, classes } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <Field
                    component={renderTextField}
                    name='newPassword'
                    type="password"
                    placeholder="New Password"
                    label={false}
                    required={true}
                />
                <Field
                    component={renderTextField}
                    name='repeatPassword'
                    type="password"
                    placeholder="Repeat Password"
                    label={false}
                    required={true}
                />
                <div className={classes.justifyContentCenter}>
                    <input type="submit" value="Reset Password"/>
                </div>
            </form>
        );
    }
}

PasswordResetForm = reduxForm({
    form: 'password_reset',
    validate
})(PasswordResetForm);

export default withStyles(style)(PasswordResetForm);
