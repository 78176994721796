import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";
import { loadPresentersForSelect } from "../../actions/Presenter/forSelect";
import { loadQuestionBankCategoriesForSelect } from "../../actions/QuestionBankCategory/forSelect";

class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  componentDidMount() {
    this.props.loadPresentersForSelect();
    this.props.loadQuestionBankCategoriesForSelect();
  }

  render() {
    return (
      <RenderFilterForm {...this.props} fields={[
        {'name': 'id', 'label': 'ID', 'placeholder': 'ID', 'type': 'number'},
        {'name': 'title', 'label': 'Question Statement', 'placeholder': 'Question Statement', 'type': 'text'},
        {'name': 'questionBankCategories____', 'label': 'Category', 'placeholder': 'Category', 'type': 'selectSearch', 'isMulti': true, 'options': this.props.categories},
        {'name': 'presenter', 'label': 'Presenter', 'placeholder': 'Presenter', 'type': 'selectSearch', 'options': this.props.presenters},
      ]} />
    );
  }
}

const mapStateToProps = state => {
  let presenters = state.presenter.forSelect.retrieved;
  let categories = state.questionbankcategory.forSelect.retrieved;

  return { presenters, categories };
};

const mapDispatchToProps = dispatch => ({
  loadPresentersForSelect: () => dispatch(loadPresentersForSelect()),
  loadQuestionBankCategoriesForSelect: () => dispatch(loadQuestionBankCategoriesForSelect()),
});

FilterForm = reduxForm({
  form: 'filter_company_question_bank',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterForm);
