import React, {Component} from "react";
import PropTypes from "prop-types";

class DismissibleComponent extends Component {
  static propTypes = {
    dismissTime: PropTypes.number, //in seconds
    onDismiss: PropTypes.func,
  };

  static defaultProps = {
    dismissTime: 3,
  }

  constructor(props) {
    super(props);
    this.state = {
      timeLeft: props.dismissTime,
    }
    this.dismissInterval = null;
  }

  componentDidMount() {
    this.initTimer();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.dismissInterval && (!this.state.timeLeft || this.state.timeLeft <= 0)) {
      this.clearTimer();
      this.setState({timeLeft: 0});
      if (this.props.onDismiss) {
        this.props.onDismiss();
      }
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  initTimer = () => {
    this.clearTimer();
    this.dismissInterval = setInterval(() => {
      this.setState((state) => ({timeLeft: state.timeLeft - 1}))
    }, 1000);
  }

  clearTimer = () => {
    if (this.dismissInterval) {
      clearInterval(this.dismissInterval);
    }
    this.dismissInterval = null;
  }

  render() {
    const {timeLeft} = this.state;
    return (timeLeft > 0 ? this.props.children : null);
  }
}

export default DismissibleComponent;
