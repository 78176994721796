import React, {Component} from "react";
import {Helmet} from "react-helmet";

class PageFavicons extends Component {
  prepareManifestObject = (site) => {
    return {
      "name": site.name,
      "short_name": site.name,
      "icons": [
        {
          "src": site.favicon['192'],
          "sizes": "192x192",
          "type": "image/png"
        },
        {
          "src": site.favicon['256'],
          "sizes": "256x256",
          "type": "image/png"
        }
      ],
      "start_url": "./index.html",
      "display": "standalone",
      "theme_color": "#000000",
      "background_color": "#ffffff"
    };
  };

  componentWillUpdate(nextProps, nextState, nextContext) {
    return false;
  }

  componentDidMount() {
    const {site} = this.props;
    if (!site.favicon || !site.favicon.legth === 0) {
      return;
    }

    const manifestLink = document.querySelector('#page-manifest');
    if (!manifestLink) {
      return;
    }

    const manifest = this.prepareManifestObject(site);
    const stringManifest = JSON.stringify(manifest);
    const blob = new Blob([stringManifest], {type: 'application/json'});
    const manifestURL = URL.createObjectURL(blob);
    manifestLink.setAttribute('href', manifestURL);
  }

  render() {
    const {site} = this.props;
    const favicon = site.favicon;

    if (!favicon || !favicon.length === 0) {
      return null;
    }

    return (
      <Helmet>
        <link rel="icon" type="image/png" sizes="16x16" href={favicon['16']} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon['32']} />
        <link rel="apple-touch-icon" sizes="180x180" href={favicon['180']} />
      </Helmet>
    );
  }
}

export default PageFavicons;
