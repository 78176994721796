import React, {Component} from 'react';
import {renderTextField} from "../../utils/form";
import InputAdornment from "@material-ui/core/InputAdornment";
import Face from "@material-ui/icons/Face";
import FormControl from "@material-ui/core/FormControl";
import {Field, formValueSelector, reduxForm, SubmissionError} from "redux-form";
import {connect} from "react-redux";
import Email from "@material-ui/icons/Email";
import Smartphone from "@material-ui/icons/Smartphone";
import ResourceFileUpload from "../../views/Components/ResourceFileUpload";
import PropTypes from 'prop-types';
import Button from "components/CustomButtons/Button.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {loadIndustriesForSelect} from "../../actions/Industry/forSelect";
import {loadOccupationsForSelect} from "../../actions/Occupation/forSelect";
import {loadSpecialtiesForSelect} from "../../actions/Specialty/forSelect";
import SearchableSelect from "../../views/Components/SearchableSelect";
import LockOutlined from "@material-ui/icons/LockOutlined";
import RegistrationSiteSettings from "../Candidate/RegistrationSiteSettings";
import {fetch} from "../../utils/dataAccess";
import LoginDialog from "./LoginDialog";
import {isEmail} from 'validator';
import {isAuthenticated} from "../../utils/auth";
import {CircularProgress} from "@material-ui/core";

class AboutForm extends Component {
  static propTypes = {
    resumeIsRequired: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      createAccount: false,
      accountExists: false,
      termsAccepted: false,
      loginModalOpened: false,
      resumeUploading: false,
    }
  }

  addMessageListeners = (message) => {
    switch (message.data.type) {
      case 'candidateData':
        const data = message.data.data;
        const allowedFields = ['email', 'firstName', 'lastName', 'phoneNumber', 'termsOfServiceAccepted', 'privacyPolicyAccepted'];

        for (let field in data) {
          if (data.hasOwnProperty(field)) {
            if (allowedFields.includes(field)) {
              this.props.change(field, data[field]);
            }
          } else {
            console.error(`Unknown field for "about you" form: ${field}`)
          }
        }

        if (data['termsOfServiceAccepted'] && data['privacyPolicyAccepted']) {
          this.setState({termsAccepted: true}, () => {
            if (window.parent) {
              window.parent.postMessage({type: 'onDemandAboutFormFilled'}, '*');
            }
          });
        } else {
          if (window.parent) {
            window.parent.postMessage({type: 'onDemandAboutFormFilled'}, '*');
          }
        }

        break;
      case 'testAudioVideo':
        this.props.submit();
        break;
    }
  }


  componentDidMount() {
    this.props.loadIndustriesForSelect();
    this.props.loadOccupationsForSelect();
    this.props.loadSpecialtiesForSelect();

    if (this.props.initialValues && this.props.initialValues.email) {
      this.checkAccountByEmail(this.props.initialValues.email);
    }

    window.addEventListener('message', this.addMessageListeners.bind(this));
    if (window.parent) {
      window.parent.postMessage({type: 'onDemandAboutFormLoaded'}, '*');
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.addMessageListeners);
  }

  checkAccountByEmail = (email) => {
    if (this.isUserLoggedIn() || !isEmail(email)) {
      return;
    }

    let emailExists = false;
    return fetch('/check-email', {
      data: {email: email},
      method: 'POST',
    }).then((resp) => {
      const data = resp.data || {};
      emailExists = !!data.exists;
    }).catch((e) => {
      emailExists = false;
    }).finally(() => {
      this.setState({accountExists: emailExists});
    })
  }

  renderCheckbox = ({label, input, handleChange, meta: {touched, error}}) => {
    return (
      <div>
        <FormControlLabel
          classes={{
            root: 'mb-0'
          }}
          control={
            <Checkbox
              checked={!!input.value}
              onChange={ (event, value) => {
                if (handleChange) {
                  handleChange(value);
                }
                return input.onChange(event, value);
              }}
            />
          }
          label={label}
        />
      </div>
    )
  };

  onIndustryChange = (e, value, oldValue) => {
    if (value !== oldValue) {
      this.props.change('professionMapping[occupation]', null);
      this.props.change('professionMapping[specialty]', null);
    }
  };

  onOccupationChange = (e, value, oldValue) => {
    if (value !== oldValue) {
      this.props.change('professionMapping[specialty]', null);
    }
  };

  getOccupationOptions = () => {
    const {industry, occupations} = this.props;
    if (industry && occupations) {
      return occupations.filter(occupation => occupation.industry === industry);
    }
    return [];
  };

  getSpecialtyOptions = () => {
    const {occupation, specialties} = this.props;
    if (occupation && specialties) {
      return specialties.filter(specialty => specialty.occupation === occupation);
    }
    return [];
  };

  renderIndustryOccupationSpecialtyBlock = () => {
    const {industries, loadingIndusties, loadingOccupations, loadingSpecialties} = this.props;
    const occupationOptions = this.getOccupationOptions();
    const specialtyOptions = this.getSpecialtyOptions();

    return (
      <>
        {(loadingSpecialties || loadingOccupations || loadingIndusties) ? (
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress />
            <span style={{paddingLeft: '15px'}}>Please wait...</span>
          </div>
        ) : (
          <>
            <FormControl fullWidth={true} className={'on-demand-interview-page-about-form-control-industry'}>
              <label className={"mb-1"}>Industry <span className="text-danger">*</span></label>
              <Field
                component={SearchableSelect}
                name="professionMapping[industry]"
                type="text"
                placeholder="Industry"
                label={null}
                onChange={this.onIndustryChange}
                options={industries}
              />
            </FormControl>
            {occupationOptions && occupationOptions.length > 0 && (
              <FormControl fullWidth={true}>
                <label className={"mb-1"}>Occupation</label>
                <Field
                  component={SearchableSelect}
                  name="professionMapping[occupation]"
                  type="text"
                  placeholder={'Occupation'}
                  label={null}
                  onChange={this.onOccupationChange}
                  options={occupationOptions}
                />
              </FormControl>
            )}
            {specialtyOptions && specialtyOptions.length > 0 && (
              <FormControl fullWidth={true}>
                <label className={"mb-1"}>Specialty</label>
                <Field
                  component={SearchableSelect}
                  name="professionMapping[specialty]"
                  type="text"
                  placeholder={'Specialty'}
                  label={null}
                  options={specialtyOptions}
                />
              </FormControl>
            )}
          </>
        )}
      </>
    );
  }

  renderPasswordBlock = () => {
    return (
      <>
        <FormControl fullWidth={true} className={'on-demand-interview-page-about-form-control on-demand-interview-page-about-form-control-password'}>
          <Field
            component={renderTextField}
            name="password"
            type="password"
            placeholder="Password"
            required={true}
            label={false}
            className="required"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" >
                  <LockOutlined/>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl fullWidth={true} className={'on-demand-interview-page-about-form-control'}>
          <Field
            component={renderTextField}
            name="repeatPassword"
            type="password"
            placeholder="Repeat Password"
            required={true}
            label={false}
            className="required"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" >
                  <LockOutlined />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </>
    );
  };

  validateForm = (values) => {
    const {resumeIsRequired} = this.props;
    const isLoggedIn = this.isUserLoggedIn();

    return new Promise((resolve) => {
      const errors = {};
      if (!values.firstName) {
        errors.firstName = 'Please enter a first name';
      }
      if (!values.lastName) {
        errors.lastName = 'Please enter a last name';
      }
      if (!values.email) {
        errors.email = 'Please enter an email';
      } else if (!isEmail(values.email)) {
        errors.email = 'Please enter a valid email';
      }
      if (!values.phoneNumber) {
        errors.phoneNumber = 'Please enter a phone number';
      }
      if (values.createAccount) {
        if (!values.password) {
          errors.password = 'Please enter a password';
        }
        if (!values.repeatPassword) {
          errors.repeatPassword = 'Please repeat password';
        } else if (values.password !== values.repeatPassword) {
          errors.repeatPassword = 'Password and repeat password should be the same';
        }
      }

      if (values.createAccount || isLoggedIn) {
        if (!values.professionMapping || !values.professionMapping.industry) {
          errors.professionMapping = {industry: 'Please select an industry'};
        }
      }

      if (resumeIsRequired && !values.resume) {
        errors.resume = 'Please upload a resume';
      }

      if (Object.keys(errors).length > 0) {
        throw new SubmissionError(errors);
      }
      resolve(values);
    }).then((values) => this.props.onSubmit(values));
  }

  isUserLoggedIn = () => {
    return isAuthenticated() || this.props.loggedIn;
  }

  render() {
    const {resumeIsRequired, creatingInterview} = this.props;
    const {accountExists, createAccount, termsAccepted, loginModalOpened, resumeUploading} = this.state;
    const isLoggedIn = this.isUserLoggedIn();

    return (
      <>
      <form onSubmit={this.props.handleSubmit(this.validateForm)}>
        <FormControl fullWidth={true} className={'on-demand-interview-page-about-form-control'}>
          <Field
            component={renderTextField}
            name="email"
            type="email"
            placeholder="Email"
            required={true}
            label={false}
            className="required"
            onBlur={(e, value) => this.checkAccountByEmail(value)}
            onChange={() => this.setState({accountExists: false})}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                >
                  <Email />
                </InputAdornment>
              ),
            }}
          />
          {!isLoggedIn && accountExists && (
            <span
              className={'on-demand-interview-page-existing-candidate-login-link'}
              onClick={() => this.setState({loginModalOpened: true})}
            >
              We found an account for you. Click here to login.
            </span>
          )}
        </FormControl>
        <FormControl fullWidth={true} className={'on-demand-interview-page-about-form-control'}>
          <Field
            component={renderTextField}
            name="firstName"
            type="text"
            placeholder="First Name"
            required={true}
            label={false}
            className="required"
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                >
                  <Face />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl fullWidth={true} className={'on-demand-interview-page-about-form-control'}>
          <Field
            component={renderTextField}
            name="lastName"
            type="text"
            placeholder="Last Name"
            required={true}
            label={false}
            className="required"
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                >
                  <Face />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl fullWidth={true} className={'on-demand-interview-page-about-form-control'}>
          <Field
            component={renderTextField}
            name="phoneNumber"
            type="text"
            placeholder="Phone Number"
            required={true}
            label={false}
            className="required"
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                >
                  <Smartphone />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl fullWidth={true}>
          <label className={"mb-1 on-demand-interview-page-upload-resume-label"}>
            Upload Resume {resumeIsRequired && (<span className="text-danger">*</span>)}
          </label>
          <Field
            component={ResourceFileUpload}
            name="resume"
            label={false}
            buttonText="Choose File"
            deleteButtonText="Change"
            required={resumeIsRequired}
            showDownloadButton={false}
            filePropertyPath="file"
            fileNameMaxLength={25}
            uploadPropertyPath="uploadedFile"
            onUploadingStarted={ () => this.setState({resumeUploading: true})}
            onUploadingFinished={ () => this.setState({resumeUploading: false})}
            url="/resume/upload"
            accept=".pdf, .txt, .rtf, .doc, .docx, .odt, .jpg, .jpeg, .png, .tiff"
          />
          <p>
            Supported File Types: .doc, .docx, .pdf up to 10 MB
          </p>
        </FormControl>
        {!isLoggedIn && (
        <FormControl fullWidth={true}>
          <RegistrationSiteSettings
            classes={{
              formGroup: null
            }}
            termsAccepted={(value) => this.setState({'termsAccepted': value})}
          />
          <Field
            component={this.renderCheckbox}
            name="createAccount"
            onChange={(e, value) => {
              if (!value) {
                this.props.change('professionMapping', '');
              }
              return this.setState({createAccount: value})
            }}
            label={'Create an account'}
          />
        </FormControl>
        )}
        {(createAccount || isLoggedIn) && (this.renderIndustryOccupationSpecialtyBlock())}
        {createAccount && (this.renderPasswordBlock())}
        <FormControl className={'on-demand-interview-page-about-form-control on-demand-interview-page-about-form-actions'}>
          <Button
            type="submit"
            round color="primary"
            disabled={(!termsAccepted && !isLoggedIn) || creatingInterview || resumeUploading}
          >
            {creatingInterview ? 'Loading...' : 'Get started'}
          </Button>
        </FormControl>
      </form>
      {loginModalOpened && (
        <LoginDialog
          enteredEmail={this.props.enteredEmail}
          handleCloseDialog={() => this.setState({loginModalOpened: false})}
        />
      )}
      </>
    )
  }
}

const selector = formValueSelector('onDemandInterviewStart');

const mapStateToProps = (state) => ({
  enteredEmail: selector(state, 'email'),
  industry: selector(state, 'professionMapping[industry]'),
  industries: state.industry.forSelect.retrieved,
  occupation: selector(state, 'professionMapping[occupation]'),
  occupations: state.occupation.forSelect.retrieved,
  specialty: selector(state, 'professionMapping[specialty]'),
  specialties: state.specialty.forSelect.retrieved,
  loggedIn: state.onDemandInterviewPage.auth.loggedIn,
  loadingIndusties: state.industry.forSelect.loading,
  loadingSpecialties: state.specialty.forSelect.loading,
  loadingOccupations: state.occupation.forSelect.loading,
});

const mapDispatchToProps = (dispatch) => ({
  loadIndustriesForSelect: () => dispatch(loadIndustriesForSelect('@id', true)),
  loadOccupationsForSelect: () => dispatch(loadOccupationsForSelect('@id', true)),
  loadSpecialtiesForSelect: () => dispatch(loadSpecialtiesForSelect('@id', true)),
});

AboutForm = reduxForm({
  form: 'onDemandInterviewStart',
  enableReinitialize: true,
})(AboutForm);

AboutForm = connect(
  mapStateToProps,
  mapDispatchToProps)
(AboutForm);

export default AboutForm;
