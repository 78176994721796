import {combineReducers} from 'redux';

export function retrieved(state = false, action) {
    switch (action.type) {
        case 'COMPANY_HIRING_MANAGERS_FOR_SELECT_RETRIEVED':
            return action.retrieved;
        default:
            return state;
    }
}

export function loading(state = false, action) {
    switch (action.type) {
        case 'COMPANY_HIRING_MANAGERS_FOR_SELECT_LOADING':
            return action.loading;
        default:
            return state;
    }
}

export function error(state = null, action) {
    switch (action.type) {
        case 'COMPANY_HIRING_MANAGERS_FOR_SELECT_ERROR':
            return action.error;

        default:
            return state;
    }
}

export default combineReducers({retrieved, error, loading});
