import {fetch} from "../../utils/dataAccess";
import {error, liveInterviewParticipantReceived, liveInterviewReceived} from "./interview";

export function acceptInvitation(invitation) {
  return dispatch => {
    dispatch(acceptingInvitation(true));
    dispatch(acceptedInvitation(false));
    dispatch(error(null));
    const uri = getInvitationUri(invitation);
    return fetch(`/${uri}/accept/${invitation.invitationCode}`, { method: 'POST' })
      .then(response => response.data)
      .then(invitation => {
        const interview = invitation.liveInterview;
        const participant = interview.participant;
        dispatch(liveInterviewInvitation(invitation));
        dispatch(liveInterviewParticipantReceived(participant));
        dispatch(liveInterviewReceived(interview));
        dispatch(acceptingInvitation(false));
        dispatch(acceptedInvitation(true));
      })
      .catch(e => {
        dispatch(acceptingInvitation(false));
        dispatch(error(e.message));
      });
  }
}

export function createAndAcceptInvitation(liveInterviewRoomCode) {
  return dispatch => {
    dispatch(acceptingInvitation(true));
    dispatch(acceptedInvitation(false));
    dispatch(error(null));
    return fetch(`live_interview/${liveInterviewRoomCode}/invitation/accept`, { method: 'POST' })
      .then(response => response.data)
      .then(liveInterview => {
        const invitation = liveInterview.candidateInvitations ?
          liveInterview.candidateInvitations[0] : null;
        const participant = liveInterview.participant;
        dispatch(liveInterviewInvitation(invitation));
        dispatch(liveInterviewParticipantReceived(participant));
        dispatch(liveInterviewReceived(liveInterview));
        dispatch(acceptingInvitation(false));
        dispatch(acceptedInvitation(true));
      })
      .catch(e => {
        dispatch(acceptingInvitation(false));
        dispatch(error(e.message));
      });
  }
}

export function declineInvitation(invitation) {
  return dispatch => {
    dispatch(decliningInvitation(true));
    dispatch(error(null));
    const uri = getInvitationUri(invitation);
    return fetch(`/${uri}/decline/${invitation.invitationCode}`, { method: 'POST' })
      .then(response => response.data)
      .then(invitation => {
        dispatch(decliningInvitation(false));
        dispatch(liveInterviewInvitation(invitation));
      })
      .catch(e => {
        dispatch(acceptingInvitation(false));
        dispatch(error(e.message));
      });
  }
}

export function createAndDeclineInvitation(liveInterviewRoomCode) {
  return dispatch => {
    dispatch(decliningInvitation(true));
    dispatch(error(null));
    return fetch(`live_interview/${liveInterviewRoomCode}/invitation/decline`, { method: 'POST' })
      .then(response => response.data)
      .then(liveInterview => {
        const invitation = liveInterview.candidateInvitations ?
          liveInterview.candidateInvitations[0] : null;
        dispatch(liveInterviewInvitation(invitation));
        dispatch(decliningInvitation(false));
      })
      .catch(e => {
        dispatch(decliningInvitation(false));
        dispatch(error(e.message));
      });
  }
}

export function requestNewTime(invitation, data) {
  return dispatch => {
    dispatch(requestingNewTime(true));
    dispatch(error(null));
    const uri = getInvitationUri(invitation);
    return fetch(`/${uri}/request-new-time/${invitation.invitationCode}`, {
      method: 'POST',
      data: data,
    })
      .then(() => {
        dispatch(newTimeRequested(true));
        dispatch(requestingNewTime(false));
      })
      .catch(e => {
        dispatch(requestingNewTime(false));
        dispatch(error(e.message));
      });
  }
}

export function createAndRequestNewTime(liveInterviewRoomCode, data) {
  return dispatch => {
    dispatch(requestingNewTime(true));
    dispatch(error(null));
    return fetch(`live_interview/${liveInterviewRoomCode}/invitation/request_new_time`, {
      method: 'POST',
      data: data,
    })
      .then(response => response.data)
      .then(liveInterview => {
        const invitation = liveInterview.candidateInvitations ?
          liveInterview.candidateInvitations[0] : null;
        dispatch(liveInterviewInvitation(invitation));
        dispatch(newTimeRequested(true));
        dispatch(requestingNewTime(false));
      })
      .catch(e => {
        dispatch(requestingNewTime(false));
        dispatch(error(e.message));
      });
  }
}

export function liveInterviewInvitation(invitation) {
  return { type: 'LIVEINTERVIEW_PAGE_INVITATION', invitation: (invitation ? invitation : null)};
}

function acceptingInvitation(accepting) {
  return {type: 'LIVEINTERVIEW_PAGE_INVITATION_ACCEPTING', accepting}
}

function acceptedInvitation(accepted) {
  return {type: 'LIVEINTERVIEW_PAGE_INVITATION_ACCEPTED', accepted}
}

function decliningInvitation(declining) {
  return {type: 'LIVEINTERVIEW_PAGE_INVITATION_DECLINING', declining}
}

function requestingNewTime(requestingNewTime) {
  return {type: 'LIVEINTERVIEW_PAGE_INVITATION_REQUESTING_NEW_TIME', requestingNewTime}
}

function newTimeRequested(newTimeRequested) {
  return {type: 'LIVEINTERVIEW_PAGE_INVITATION_NEW_TIME_REQUESTED', newTimeRequested}
}

function getInvitationUri(invitation) {
  if (invitation) {
    return invitation['@type'] === 'LiveInterviewCompanyUserInvitation' ?
      'live_interview_company_user_invitations' :
      'live_interview_candidate_invitations'
  }
  return undefined;
}
