import React, {Component} from "react";
import Radio from '@material-ui/core/Radio';
import PropTypes from "prop-types";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withStyles from "@material-ui/core/styles/withStyles";

const redAsterisks = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "red",
        "&$error": "red",
      }
    }
  }
});

const style = {
  inputLabel: {
    paddingRight: '15px',
  },
  radioLabel: {
    marginLeft: '-5px',
  }
};

class InlineRadioButton extends Component{
  static propTypes = {
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    })).isRequired,
  };

  renderLabel = () => {
    const {label, required, classes} = this.props;

    if (label !== null) {
      return (
        <MuiThemeProvider theme={redAsterisks}>
          <InputLabel classes={{root: classes.inputLabel}} required={required}>{label}</InputLabel>
        </MuiThemeProvider>
      );
    } else {
      return null;
    }
  };

  renderOptions = () => {
    const {input, options, classes} = this.props;

    if (!options || options.length === 0) {
      return null;
    }

    const valueIsBoolean = typeof input.value === 'boolean';
    return options.map((option, i) => {
      const optionIsBoolean = typeof option.value === 'boolean';
      return (<FormControlLabel
        key={input.name + '_' + i}
        checked={input.value === option.value || (optionIsBoolean && !valueIsBoolean && ((input.value === 'true') === option.value))}
        value={option.value.toString()}
        control={<Radio />}
        label={option.label}
        onChange={(event) => {
          this.props.input.onChange(option.value)
        }}
        classes={{label: classes.radioLabel}}
      />
    )})
  }

  render() {
    return (
      <div className={'form-group'}>
        {this.renderLabel()}
        {this.renderOptions()}
      </div>
    )
  }
}

export default withStyles(style)(InlineRadioButton);
