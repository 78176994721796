import React, {Component} from 'react';
import AboutForm from "./AboutForm";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import {connect} from "react-redux";
import {createInterview} from "../../actions/OnDemandInterviewPage/interview";
import {normalize} from "../../utils/dataAccess";
import PageError from "./PageError";
import {CircularProgress} from "@material-ui/core";

class OnDemandInterviewAboutContent extends Component {
  onSubmit = (data) => {
    const {request, invitation} = this.props;
    const interviewData = {
      ...data,
      request: request['@id']
    };

    if (invitation) {
      interviewData['invitationCode'] = invitation.invitationCode;
    }

    return this.props.createInterview(interviewData);
  }

  render() {
    const {request, invitation, candidateProfile, creatingInterview, profileLoading} = this.props;
    const formInitialValues = {};
    if (candidateProfile) {
      formInitialValues.firstName = candidateProfile.firstName;
      formInitialValues.lastName = candidateProfile.lastName;
      formInitialValues.email = candidateProfile.email;
      formInitialValues.phoneNumber = candidateProfile.phoneNumber;
      if (candidateProfile.resume) {
        formInitialValues.resume = candidateProfile.resume;
      }
      if (candidateProfile.professionMappings && candidateProfile.professionMappings.length > 0) {
        const professionMapping = normalize(candidateProfile.professionMappings[0]);
        delete professionMapping['@id'];
        delete professionMapping['@type'];
        formInitialValues.professionMapping = professionMapping;
      }
    }
    if (invitation && invitation.email) {
      formInitialValues.email = invitation.email;
    }

    return (
      <>
        {profileLoading ? (
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '85vmin'}}>
            <CircularProgress />
            <span style={{paddingLeft: '15px'}}>Please wait...</span> 
          </div>
        ) : (
          <div className="on-demand-interview-page-about-content">
            <div className="alert alert-warning on-demand-interview-page-about-tips" role="status">
              <b>Tips:</b>
              <ol>
                <li>
                  <span className={'on-demand-interview-page-tips-highlighted-phrase'}>Create an account or login</span> to
                  save your profile for future interviews, access your interview history, find and resume “in progress”
                  interviews, and receive notifications and reminders about account activity.
                </li>
                <li>
                  A device with a <span className={'on-demand-interview-page-tips-highlighted-phrase'}>camera and microphone</span> may
                  be required for this interview. <span className={'on-demand-interview-page-tips-highlighted-phrase'}>A laptop or desktop
                  computer is recommended</span>. Mobile devices such as <span className={'on-demand-interview-page-tips-highlighted-phrase'}>phones and tablets</span> are also compatible.
                </li>
              </ol>
            </div>
            <PageError />
            <GridContainer>
              <GridItem xs={12} md={8} lg={6} className={'on-demand-interview-page-about-form-container'}>
                <h3 className={'on-demand-interview-page-about-form-title'}>About You</h3>
                <AboutForm
                  onSubmit={this.onSubmit}
                  resumeIsRequired={request.resumeRequired}
                  initialValues={formInitialValues}
                  creatingInterview={creatingInterview}
                />
              </GridItem>
            </GridContainer>
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  request: state.onDemandInterviewPage.request,
  invitation: state.onDemandInterviewPage.invitation,
  candidateProfile: state.onDemandInterviewPage.auth.candidateProfile,
  loggedIn: state.onDemandInterviewPage.auth.loggedIn,
  creatingInterview: state.onDemandInterviewPage.creatingInterview,
  profileLoading: state.onDemandInterviewPage.auth.candidateProfileLoading,
});

const mapDispatchToProps = dispatch => ({
  createInterview: (data) => dispatch(createInterview(data)),
})

OnDemandInterviewAboutContent = connect(
  mapStateToProps, mapDispatchToProps
)(OnDemandInterviewAboutContent);

export default OnDemandInterviewAboutContent;
