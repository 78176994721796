import { combineReducers } from 'redux';

export function error(state = null, action) {
  switch (action.type) {
    case 'PASSWORD_RESET_ERROR':
      return action.error;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'PASSWORD_RESET_LOADING':
      return action.loading;

    default:
      return state;
  }
}

export function success(state = {}, action) {
  switch (action.type) {
    case 'PASSWORD_RESET_SUCCESS':
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({ error, success, loading });
