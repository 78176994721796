import React from "react";
import {List, ListItem, Tab, Tabs} from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "../../views/Components/Dialog/DialogTitle";
import DialogActions from "../../views/Components/Dialog/DialogActions";
import DialogContent from "../../views/Components/Dialog/DialogContent";

const style = {
  sipListItem: {
    fontSize: '1rem',
  },
  sipCodeText: {
    letterSpacing: '.2rem',
    fontWeight: 'bold',
  },
  microphoneAccessDeniedBlock: {
    fontWeight: 500,
  }
}

class MicrophoneDeviceDialog extends React.Component {
  static defaultProps = {
    audioDeviceListHeader: 'Change Microphone',
    microphonePermissionDeniedText: 'Please provide access to your microphone to use computer audio.',
    audioDeviceCancelButtonText: 'Cancel',
    audioDeviceConfirmButtonText: 'Ok',
    sipCancelButtonText: 'Cancel',
    sipConfirmButtonText: 'Ok',
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      selectedDevice: props.currentDevice,
    };
  }

  isMicrophoneAccessGranted = () => {
    let microphoneAccessGranted = false;
    Object.values(this.props.devices).forEach(({label}) => {
      if (label.length > 0) {
        microphoneAccessGranted = true;
      }
    });
    return microphoneAccessGranted;
  }

  render() {
    const {activeTab, selectedDevice} = this.state;
    const {
      classes, closeDialog, changeDevice, confirmSip, devices, sipCode, sipPhone,
      audioDeviceCancelButtonText, audioDeviceConfirmButtonText,
      sipCancelButtonText, sipConfirmButtonText, audioDeviceListHeader
    } = this.props;
    const sipEnabled = sipCode && sipPhone;
    const showTabs = sipEnabled;
    const microphoneAccessGranted = this.isMicrophoneAccessGranted();

    return (
      <>
        {showTabs && (
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => this.setState({activeTab: newValue})}
            aria-label="simple tabs example"
            variant="fullWidth"
          >
            <Tab label="Device Audio" />
            <Tab label="Join from Phone" />
          </Tabs>
        )}
        {activeTab === 0 && (
          <>
            <DialogTitle
              id={"confirmation-dialog-title"}
              onClose={!showTabs ? closeDialog : undefined}
            >
              {microphoneAccessGranted && audioDeviceListHeader}
            </DialogTitle>
            <DialogContent>
              {microphoneAccessGranted ? (
                <RadioGroup
                  value={selectedDevice}
                  onChange={event => this.setState({selectedDevice: event.target.value})}
                >
                  {Object.values(devices).map(({id, label}) => (
                    <FormControlLabel value={id} key={id} control={<Radio />} label={label} />
                  ))}
                </RadioGroup>
                ) : (
                <div className={classes.microphoneAccessDeniedBlock}>
                  {this.props.microphonePermissionDeniedText}
                </div>
              )}
            </DialogContent>
            <DialogActions
              confirmButtonText={audioDeviceConfirmButtonText}
              onConfirm={() => changeDevice(selectedDevice)}
              closeButtonText={audioDeviceCancelButtonText}
              onClose={closeDialog}
            />
          </>
        )}
        {sipEnabled && activeTab === 1 && (
          <>
            <DialogTitle
              id={"confirmation-dialog-title"}
              onClose={!showTabs ? closeDialog : undefined}
            >
              Phone Call
            </DialogTitle>
            <DialogContent>
              <List>
                <ListItem>
                  <span className={classes.sipListItem}>Dial: {sipPhone}</span>
                </ListItem>
                <ListItem>
                  <span className={classes.sipListItem}>Code: <span className={classes.sipCodeText}>{sipCode}#</span></span>
                </ListItem>
              </List>
            </DialogContent>
            <DialogActions
              confirmButtonText={sipConfirmButtonText}
              onConfirm={confirmSip}
              closeButtonText={sipCancelButtonText}
              onClose={closeDialog}
            />
          </>
        )}
      </>
    );
  }
}

MicrophoneDeviceDialog = withStyles(style)(MicrophoneDeviceDialog);

export default MicrophoneDeviceDialog;
