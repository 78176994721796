import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from "@material-ui/core/InputAdornment";

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, loading, ...other } = inputProps;
  const InputProps = {
    inputRef: node => {
      ref(node);
      inputRef(node);
    },
    classes: {
      input: classes.input,
    },
  };

  if (loading) {
    InputProps['endAdornment'] = (
      <InputAdornment position="end">
        <i className="fa fa-spinner fa-spin" />
      </InputAdornment>
    );
  }

  return (
    <TextField
      fullWidth
      InputProps={InputProps}
      {...other}
    />
  );
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

class Autocomplete extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onSuggestionsFetchRequested: PropTypes.func.isRequired,
    renderSuggestion: PropTypes.func.isRequired,
  };

  state = {
    focused: false,
  };

  handleSuggestionsClearRequested = () => {};

  handleChange = (newValue, method, onChange) => {
    if (method !== 'up' && method !== 'down') {
      if (typeof onChange === 'function') {
        onChange(newValue);
      }

      this.setState({value: newValue});
    }
  };

  onFocus = () => {
    this.setState({focused: true});
  };
  onBlur = () => {
    this.setState({focused: false});
  };

  render() {
    const { onChange, classes, placeholder, suggestions, loading, theme = {} } = this.props;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: suggestions,
      onSuggestionsFetchRequested: this.props.onSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: this.props.getSuggestionValue,
      renderSuggestion: this.props.renderSuggestion,
      onSuggestionSelected: this.props.onSuggestionSelected,
    };

    const defaultTheme = {
      container: classes.container,
      suggestionsContainerOpen: classes.suggestionsContainerOpen,
      suggestionsList: classes.suggestionsList,
      suggestion: classes.suggestion,
    };

    return (
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          classes,
          value: this.props.value,
          autoComplete: 'off',
          placeholder: placeholder,
          onChange: (event, {newValue, method}) => this.handleChange(newValue, method, onChange),
          onFocus: this.onFocus,
          onBlur: this.onBlur,
          error: this.props.error,
          helperText: this.props.helperText,
          disabled: this.props.disabled,
          loading: loading && this.state.focused,
        }}
        theme={{...defaultTheme, ...theme}}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    );
  }
}

export default withStyles(styles)(Autocomplete);
