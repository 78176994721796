import { SubmissionError } from 'redux-form';
import {
  fetch,
  extractHubURL,
  normalize
} from '../../utils/dataAccess';
import {getSwitchedUsersHistory, setSwitchedUsersHistory} from "../../utils/auth";

export function activateError(activateError) {
  return { type: 'COMPANY_USER_ACTIVATE_ERROR', activateError };
}

export function activateLoading(activateLoading) {
  return { type: 'COMPANY_USER_ACTIVATE_LOADING', activateLoading };
}

export function activateSuccess(activated) {
  return { type: 'COMPANY_USER_ACTIVATE_SUCCESS', activated };
}

export function activate(companyUser, callback) {
  return dispatch => {
    dispatch(activateError(null));
    dispatch(activateLoading(true));

    return fetch('/company_users/' + companyUser['id'] + '/activate', {
      method: 'PUT',
    })
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        retrieved = normalize(retrieved);

        dispatch(activateLoading(false));
        dispatch(activateSuccess(retrieved));

        if (retrieved['status'] !== 'active') {
          let history = getSwitchedUsersHistory();
          for (let i = 0; i < history.length; i++) {
            if (history[i]['email'] === retrieved['email']) {
              history.splice(i, 1);
              setSwitchedUsersHistory(history);

              break;
            }
          }
        }

        if (typeof callback === 'function') {
          callback();
        }
      })
      .catch(e => {
        dispatch(activateLoading(false));

        if (e instanceof SubmissionError) {
          dispatch(activateError(e.errors._error));
          throw e;
        }

        dispatch(activateError(e.message));

        if (typeof callback === 'function') {
          callback();
        }
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'COMPANY_USER_ACTIVATE_RESET' });
  };
}
