import { SubmissionError } from 'redux-form';
import {
  fetch,
  extractHubURL,
  normalize
} from '../../utils/dataAccess';

export function activateError(activateError) {
  return { type: 'COMPANY_ACTIVATE_ERROR', activateError };
}

export function activateLoading(activateLoading) {
  return { type: 'COMPANY_ACTIVATE_LOADING', activateLoading };
}

export function activateSuccess(activated) {
  return { type: 'COMPANY_ACTIVATE_SUCCESS', activated };
}

export function activate(company, callback) {
  return dispatch => {
    dispatch(activateError(null));
    dispatch(activateLoading(true));

    return fetch('/companies/' + company['id'] + '/activate', {
      method: 'PUT',
    })
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        retrieved = normalize(retrieved);

        dispatch(activateLoading(false));
        dispatch(activateSuccess(retrieved));
      })
      .catch(e => {
        dispatch(activateLoading(false));

        if (e instanceof SubmissionError) {
          dispatch(activateError(e.errors._error));
          throw e;
        }

        dispatch(activateError(e.message));
      }).finally(() => {
        if (typeof callback === 'function') {
          callback();
        }
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'COMPANY_ACTIVATE_RESET' });
  };
}
