import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { retrieve, reset } from '../../actions/Email/show';
import Moment from 'react-moment';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {renderLinks} from "../../utils/entityList";
import {currentUserHasRole} from "../../utils/auth";

class EmailShow extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {

    const item = this.props.retrieved;

    return (
      <div>
        <h1>Show &quot;{item && item['subject']}&quot;</h1>

        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}

        <div className="grid-buttons">
          <Link to=".." className="btn btn-primary">
            Back to list
          </Link>
        </div>

        {item && (
          <div style={{'overflowX': 'auto'}}>
            <Table className="table table-responsive table-striped table-hover">
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell scope="row">ID</TableCell>
                  <TableCell>{item['id']}</TableCell>
                </TableRow>
                {currentUserHasRole('ROLE_SUPER_ADMIN') && (
                <TableRow>
                  <TableCell scope="row">Partner</TableCell>
                  <TableCell>{renderLinks('../partners', item['partner'])}</TableCell>
                </TableRow>
                )}
                <TableRow>
                  <TableCell scope="row">To Address</TableCell>
                  <TableCell>{item['toAddress']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">From Address</TableCell>
                  <TableCell>{item['fromAddress']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Subject</TableCell>
                  <TableCell>{item['subject']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Body</TableCell>
                  <TableCell>{item['body']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Created At</TableCell>
                  <TableCell>{item['createdAt'] && (<Moment>{item['createdAt']}</Moment>)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        )}

      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.email.show.retrieved,
  error: state.email.show.error,
  loading: state.email.show.loading,
  eventSource: state.email.show.eventSource,
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailShow);
