import {
  fetch,
} from '../../utils/dataAccess';
import {filterAction, sortAction} from "../../utils/filterAndSortUtils";

export function error(error) {
  return { type: 'CANDIDATE_ONEWAYINTERVIEW_INVITATION_LIST_ERROR', error };
}

export function loading(loading) {
  return { type: 'CANDIDATE_ONEWAYINTERVIEW_INVITATION_LIST_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'CANDIDATE_ONEWAYINTERVIEW_INVITATION_LIST_SUCCESS', retrieved };
}

export function list(page = 'invitations') {
  return dispatch => {
    dispatch(loading(true));
    dispatch(error(''));

    fetch(page)
      .then(response =>
        ({ retrieved: response.data })
      )
      .then(({ retrieved }) => {
        dispatch(loading(false));
        dispatch(success(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch({ type: 'CANDIDATE_ONEWAYINTERVIEW_INVITATION_LIST_RESET' });
  };
}

export function filter(filtersData) {
  return filterAction('CANDIDATE_ONEWAYINTERVIEW_INVITATION_LIST_FILTER', 'candidate_one_way_interview_invitation', filtersData)
}

export function sort(sortData) {
  return sortAction('CANDIDATE_ONEWAYINTERVIEW_INVITATION_LIST_SORT','candidate_one_way_interview_invitation', sortData)
}
