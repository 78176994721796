import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { retrieve, reset } from '../../actions/CompanyCandidate/show';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Moment from 'react-moment';
import ProfessionMappingView from "../../views/Components/ProfessionMappingView";
import CompanyCandidateTagsForm from "./CompanyCandidateTagsForm";
import {isGranted} from "../../utils/permissions";
import PhoneNumber from "../../views/Components/PhoneNumber";

class CompanyCandidateShow extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const item = this.props.retrieved;

    return (
      <div>
        <h1>Show &quot;{item && (`${item.firstName} ${item.lastName}`)}&quot;</h1>

        {this.props.loading && (
            <div className="alert alert-info" role="status">
              Loading...
            </div>
        )}
        {this.props.error && (
            <div className="alert alert-danger" role="alert">
              <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
              {this.props.error}
            </div>
        )}

        <div className="grid-buttons">
          <Link to=".." className="btn btn-primary">
            Back to list
          </Link>
        </div>

        {item && (
          <>
            <Table className="table table-striped table-hover">
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell scope="row">First Name</TableCell>
                  <TableCell>{item['firstName']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Last Name</TableCell>
                  <TableCell>{item['lastName']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Email Address</TableCell>
                  <TableCell>{item['email']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Mobile Number</TableCell>
                  <TableCell>
                    <PhoneNumber phone={item['phoneNumber']} formattedPhone={item['formattedPhoneNumber']} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Profession Mapping</TableCell>
                  <TableCell>
                    {item['professionMapping'] ?
                      (<ProfessionMappingView mapping={item['professionMapping']}/>) : '-'
                    }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Resume</TableCell>
                  <TableCell>
                    {item['resume'] ? (
                      <a href={item['resume']['file']['url']} target="_blank">
                        {item['resume']['file']['fileName']}
                      </a>
                    ) : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Created At</TableCell>
                  <TableCell>{item['createdAt'] && (<Moment>{item['createdAt']}</Moment>)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Updated At</TableCell>
                  <TableCell>{item['updatedAt'] && (<Moment>{item['updatedAt']}</Moment>)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Tags</TableCell>
                  <TableCell>
                    {isGranted('edit', item) ? (
                      <CompanyCandidateTagsForm object={item} />
                    ) : (
                      item.tags.length > 0 ? item.tags.map(tag => tag.name).join(', ') : '-'
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.companyCandidate.show.retrieved,
  error: state.companyCandidate.show.error,
  loading: state.companyCandidate.show.loading,
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyCandidateShow);
