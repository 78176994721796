import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import {renderTextField, renderCheckbox, renderTextareaField} from "../../utils/form";
import {loadStatesForSelect} from "../../actions/State/forSelect";
import {connect} from "react-redux";
import InlineFileUpload from "../../views/Components/InlineFileUpload";
import SearchableSelect from "../../views/Components/SearchableSelect";
import RichTextEditor from "../../views/Components/RichTextEditor";
import {loadCountriesForSelect} from "../../actions/Country/forSelect";
import moment from "moment";
import Moment from "react-moment";

class PartnerForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    allowInactivate: PropTypes.bool,
    error: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      country: props && props.initialValues && ('country' in props.initialValues) ? props.initialValues['country'] : null,
    };
  }

  componentDidMount() {
    this.props.loadStatesForSelect();
    this.props.loadCountriesForSelect(this.props.site.country && this.props.site.country.code);
  }

  handleCountrySelect = (e, value, oldValue) => {
    if (value !== oldValue) {
      this.props.change('state', null);

      this.setState({country: value});
    }
  };

  render() {
    let stateOptions = [];
    if (this.state.country !== null) {
      if (this.props.states) {
        this.props.states.map(state => {
          if (state.country === this.state.country) {
            stateOptions.push(state);
          }
        });
      }
    }

    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={renderTextField}
          name="name"
          type="text"
          placeholder="Partner's Name"
          required={true}
        />
        {this.props.allowInactivate && (
        <Field
          component={SearchableSelect}
          name="status"
          label="Status"
          placeholder='Select Partner Status'
          options={[
            {value: 'active', text: 'Active'},
            {value: 'inactive', text: 'Inactive'}
          ]}
          required={true}
        />
        )}
        <Field
          component={renderTextField}
          name="gracePeriodDays"
          type="number"
          placeholder="How many days should we give Companies to pay before suspending them."
          required={true}
          normalize={v => parseFloat(v)}
        />
        <Field
          component={renderTextField}
          name="trialDays"
          type="number"
          placeholder="How many days new Companies can try the system for free."
          required={true}
          normalize={v => parseFloat(v)}
        />
        <Field
          component={renderTextField}
          name="homePageSlogan"
          type="text"
          placeholder="Home Page Slogan"
        />
        <Field
          component={RichTextEditor}
          name="description"
          type="text"
          placeholder="Site's Description"
        />
        <Field
          component={renderTextField}
          name="domain"
          type="text"
          placeholder="Site's Domain"
          required={true}
        />
        <Field
          component={renderTextField}
          name="address"
          type="text"
          placeholder="Address"
          required={true}
        />
        <Field
          component={renderTextField}
          name="address2"
          type="text"
          placeholder="Address 2"
        />
        <Field
          component={renderTextField}
          name="city"
          type="text"
          placeholder="City"
          required={true}
        />
        <Field
          component={SearchableSelect}
          name="country"
          label="Country"
          placeholder={this.props.countries ? 'Select Country' : 'Loading...'}
          options={this.props.countries ? this.props.countries : []}
          required={true}
          onChange={this.handleCountrySelect}
        />
        {stateOptions.length > 0 && (
          <Field
            component={SearchableSelect}
            name="state"
            label="State"
            placeholder="Select State"
            options={stateOptions}
            required={true}
            isDisabled={!this.state.country}
          />
        )}
        <Field
          component={renderTextField}
          name="zipCode"
          type="text"
          placeholder="Zip Code"
          required={true}
        />
        <Field
          component={renderTextField}
          name="fromEmail"
          type="email"
          placeholder="Site's From Email Address"
          required={true}
        />
        <Field
          component={renderTextField}
          name="salesEmail"
          type="email"
          placeholder="Site's Sales Email Address"
          required={true}
        />
        <Field
          component={renderTextField}
          name="salesPhone"
          type="text"
          placeholder="Site's Sales Phone Number"
          required={true}
        />
        <Field
          component={renderTextField}
          name="supportEmail"
          type="email"
          placeholder="Site's Support Email Address"
          required={true}
        />
        <Field
          component={renderTextField}
          name="privacyEmail"
          type="email"
          placeholder="Site's Privacy Email Address"
          required={true}
        />
        <Field
          component={renderTextField}
          name="supportPhone"
          type="text"
          placeholder="Site's Support Phone Number"
          required={true}
        />
        <Field
          component={renderTextField}
          name="accountingEmail"
          type="email"
          placeholder="Site's Accounting Email Address"
          required={true}
        />
        <Field
          component={renderTextField}
          name="accountingPhone"
          type="text"
          placeholder="Site's Accounting Phone Number"
          required={true}
        />
        <Field
          component={renderTextField}
          name="primaryColor"
          type="text"
          placeholder="Site's Primary Color"
        />
        <Field
          component={renderTextField}
          name="accentColor"
          type="text"
          placeholder="Site's Accent Color"
        />
        <Field
          component={renderTextField}
          name="emailHeaderColor"
          type="text"
          placeholder="Site's Email Header Color"
        />
        <Field
          component={InlineFileUpload}
          type="image"
          accept=".jpg, .png, .jpeg"
          name="logo"
        />
        <Field
          component={InlineFileUpload}
          type="image"
          accept=".jpg, .png, .jpeg"
          name="inverseLogo"
        />
        <Field
          component={InlineFileUpload}
          type="image"
          accept=".jpg, .png, .jpeg"
          name="emailLogo"
        />
        <Field
          component={InlineFileUpload}
          type="image"
          accept=".ico, .png, .gif, .jpg, .jpeg, .svg"
          name="favicon"
        />
        <Field
          component={renderTextField}
          name="resumeReviewSalesUrl"
          type="text"
          placeholder="The URL to send people for information about Resume Review services"
        />
        <Field
          component={renderTextField}
          name="resumeRewritingSalesUrl"
          type="text"
          placeholder="The URL to send people for information about Resume Rewriting services"
        />
        <Field
          component={renderTextField}
          name="interviewTrainingSalesUrl"
          type="text"
          placeholder="The URL to send people for information about Interview Training services"
        />
        <Field
          component={renderTextField}
          name="resumeDistributionSalesUrl"
          type="text"
          placeholder="The URL to send people for information about Resume Distribution services"
        />
        <Field
          component={renderTextareaField}
          name="headerHtml"
          type="text"
          placeholder="Custom header HTML"
        />
        <Field
          component={renderCheckbox}
          label="Allow Discover Users By API"
          name="partnerUsersDiscoverable"
          type="checkbox"
        />
        <Field
          component={renderCheckbox}
          label="Enable Company Registration"
          name="companyRegistrationEnabled"
          type="checkbox"
        />
        <Field
          component={renderTextField}
          name="dateFormat"
          type="text"
          placeholder="Date Format"
        />
        <Field
          component={renderCheckbox}
          name="enableFreeResumeReviewCheckbox"
          type="checkbox"
          label="Enable Free Resume Review checkbox on candidate registration form"
        />
        <Field
          component={renderCheckbox}
          name="displayDefaultContent"
          type="checkbox"
          label={"Display default content (libraries, categories, etc.)"}
        />
        <Field
          component={renderTextField}
          name="braintreeMerchantId"
          type="text"
          placeholder="Braintree Merchant ID"
          normalize={v => v ? v.trim() : v}
        />
        <Field
          component={renderTextField}
          name="braintreeMerchantPublicKey"
          type="text"
          placeholder="Braintree Merchant Public Key"
          normalize={v => v ? v.trim() : v}
        />
        <Field
          component={renderTextField}
          name="braintreeMerchantPrivateKey"
          type="text"
          placeholder="Braintree Merchant Private Key"
          normalize={v => v ? v.trim() : v}
        />
        <Field
          component={renderTextField}
          name="braintreeClientTokenizationKey"
          type="text"
          placeholder="Braintree Client Tokenization Key"
          normalize={v => v ? v.trim() : v}
        />
        <button type="submit" className="btn btn-success float-left mr-3">
          Submit
        </button>
      </form>
    );
  }
}

const validFormat = (dateFormat) => {
  let validation = moment(moment().format(dateFormat)).inspect();
  if (validation.indexOf('invalid') < 0) {
    return true;
  }
  return false;
};

const validate = (values) => {
  const errors = {};
    if (!values.dateFormat) {
        errors.dateFormat = 'Please input Date Format';
    } else {
      if (!validFormat(values.dateFormat)) {
        errors.dateFormat = `Please input correct Date Format, example: ${Moment.globalFormat}`;
      }
    }

    return errors;
};

const mapStateToProps = state => ({
  countries: state.country.forSelect.retrieved,
  states: state.state.forSelect.retrieved,
  site: state.siteDetails.siteDetails.retrieved,
});

const mapDispatchToProps = dispatch => ({
  loadCountriesForSelect: (countryCode) => dispatch(loadCountriesForSelect('@id', countryCode)),
  loadStatesForSelect: () => dispatch(loadStatesForSelect('@id')),
});

PartnerForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerForm);

export default reduxForm({
  validate: validate,
  form: 'partner',
  enableReinitialize: true,
})(PartnerForm);
