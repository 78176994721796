import React, {Component} from "react";
import VideoPlayerWithPlaybackRate from "../video/VideoPlayerWithPlaybackRate";
import AudioPlayer from "../Audio/AudioPlayer";
import Tooltip from "@material-ui/core/Tooltip";
import VideoPlayer from "../video/VideoPlayer";
import PropTypes from "prop-types";

export default class VideoQuestionAnswerView extends Component {
    static propTypes = {
        question: PropTypes.object,
        answer: PropTypes.object,
    };

    renderAnswer = (answer) => {
        if (answer && answer.answerVideo) {
            return (
                <>
                    <h4>Answer</h4>
                    <VideoPlayerWithPlaybackRate src={answer.answerVideo.videoUrl} />
                </>
            );
        }
        if (answer && answer.answerAudio) {
            return (
                <>
                    <h4>Answer</h4>
                    <AudioPlayer
                        className="on-demand-audio-answer-preview"
                        src={answer.answerAudio.audioUrl}
                        showPlaybackRate={true}
                    />
                </>
            )
        }
        if (answer && answer.answerText) {
            return (
                <>
                    <h4>Answer</h4>
                    <p className="on-demand-text-answer-preview">{answer.answerText}</p>
                </>
            )
        }
        return 'Not Answered';
    }

    renderAnswerStatistic = (answer) => {
        const statistic = answer && answer.answerStatistic;
        const isTextAnswer = answer && !!answer.answerText
        const thinkTimeIsExpired = answer && answer.statisticThinkTimeSeconds > 0 && answer.thinkTimeNotAnswered;
        const placeholder = '--';

        if (!statistic) {
            return null;
        }

        return (
            <ul className={"on-demand-video-question-preview-statistic"}>
                <li>
                    <span className={"statistic-name"}>Total Time Spent</span>
                    <span className={"statistic-value"}>{statistic['totalTimeSpent'] ?
                        statistic['totalTimeSpent'] :
                        (<span className={'text-secondary'}>{placeholder}</span>)}
                    </span>
                </li>
                <li>
                    <span className={"statistic-name" + (thinkTimeIsExpired ? ' text-danger' : '')}>Think Time Used/Limit</span>
                    <span className={"statistic-value" + (thinkTimeIsExpired ? ' text-danger' : '')}>
                        {answer && !answer.statisticThinkTimeSeconds && (placeholder)}
                        {thinkTimeIsExpired && (
                            <>
                                <Tooltip
                                    title="User left the page before answering the question"
                                    placement="top"
                                >
                                    <span className={''}>Expired/{statistic['thinkTimeLimit']}</span>
                                </Tooltip>
                            </>
                        )}
                        {answer.statisticThinkTimeSeconds > 0 && !answer.thinkTimeNotAnswered && (
                            statistic['thinkTimeUsed'] ? statistic['thinkTimeUsed'] : (
                                <Tooltip
                                    title="User started recording answer before think time started"
                                    placement="top"
                                >
                                    <span className={'text-success'}>0:00</span>
                                </Tooltip>
                            )
                        )}
                        {answer.statisticThinkTimeSeconds > 0 && !answer.thinkTimeNotAnswered && (
                            <>/{statistic['thinkTimeLimit']}</>
                        )}
                    </span>
                </li>
                <li>
                    <span className={"statistic-name"}>Recording Duration/Time Limit</span>
                    <span className={"statistic-value"}>
                        {isTextAnswer ?
                            placeholder :
                            <>
                                {statistic['recordingDuration'] ? statistic['recordingDuration'] : '0:00'}/
                                {statistic['recordingLimit'] ? statistic['recordingLimit'] : placeholder}
                            </>
                        }
                    </span>
                </li>
                <li>
                    <span className={"statistic-name"}>Record Attempts</span>
                    <span className={"statistic-value"}>
                        {(isTextAnswer || !statistic['maxAnswerRecordingRetries']) ?
                            placeholder :
                            <>{statistic['answerRecordingRetriesUsed']}/{statistic['maxAnswerRecordingRetries']}</>
                        }
                    </span>
                </li>
            </ul>
        );
    }

    render = () => {
        const {question, answer} = this.props;
        const skipped = answer && answer.skipped;
        const thinkTimeExpired = answer && answer.thinkTimeNotAnswered;

        return (
            <>
                <h4>Question</h4>
                <p style={{minHeight: '3em'}} className={skipped ? 'text-muted' : ''}>
                    {(question && question.title) || 'Deleted Question'}{question && question.required && (<span className="text-danger"> *</span>)}
                </p>
                {question && (<VideoPlayer
                    src={question.questionVideo.videoUrl}
                />)}
                <hr/>
                {thinkTimeExpired && (
                    <>
                        <h4>Answer</h4>
                        <h4 className="text-muted">Expired</h4>
                        <hr />
                        {this.renderAnswerStatistic(answer)}
                    </>
                )}
                {skipped && (
                    <>
                        <h4>Answer</h4>
                        <h4 className="text-muted">Skipped</h4>
                    </>
                )}
                {!skipped && !thinkTimeExpired && (
                    <>
                        {this.renderAnswer(answer)}
                        <hr />
                        {this.renderAnswerStatistic(answer)}
                    </>
                )}
            </>
        );
    };
}
