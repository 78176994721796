import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Button from "../CustomButtons/Button";
import classNames from 'classnames';
import {
  createVideoPublisher, getLastVideoAnswerForVideoQuestion, getQuestionRecorderText,
  getRecordAttemptsForVideoQuestion,
  hasAllowedReRecordAttemptsForVideoQuestion,
  incrementRecordAttemptsForVideoQuestion,
  isQuestionHasRecordAttemptLimit, setEndThinkTimeForVideoQuestion,
  setLastVideoAnswerForVideoQuestion
} from "../../utils/onDemandInterview";
import ChangeDeviceNotification from "../VideoControl/ChangeDeviceNotification";
import DesktopRecorderLimitations from "./DesktopRecorderLimitations";
import ChangeDeviceButtons from "./ChangeDeviceButtons";
import {getOpenViduSingleton} from "../../utils/OpenViduInstance";
import {filterAudioDevices, filterVideoDevices} from "../../utils/webRtc";
import {closeStreamManager} from "../../utils/streamManager";
import MobileRecordingTime from "./MobileRecordingTime";
import AnswerRecorder from "../VideoRecorder/AnswerRecorder";
import {answerError} from "../../actions/OnDemandInterviewPage/answer";

class VideoQuestionAnswerVideoRecorder extends Component {
  static propTypes = {
    onAnswer: PropTypes.func.isRequired,
    question: PropTypes.object.isRequired,
    cancelAnswer: PropTypes.func,
    thinkTimeCountedDown: PropTypes.bool.isRequired,
    thinkTimeLeft: PropTypes.object,
    onRecordingStarted: PropTypes.func.isRequired,
    startRecordingDisabled: PropTypes.bool,
  };

  static defaultAnswerState = {
    videoAnswer: null,
    recordingStartTime: null,
    recording: false,
  };

  constructor(props) {
    super(props);

    this.recorder = React.createRef();
    this.state = {
      ...VideoQuestionAnswerVideoRecorder.defaultAnswerState,
      streamManager: null,
      audioDevices: {},
      videoDevices: {},
    }
  }

  componentDidMount() {
    const {question} = this.props;
    const videoAnswer = getLastVideoAnswerForVideoQuestion(question);
    if (videoAnswer) {
      this.setVideoAnswer(videoAnswer);
    }
    this.setState({recordAttempts: getRecordAttemptsForVideoQuestion(question)});
    this.createStreamManager().catch(e => console.log('Failed to create stream manager', e));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.question && this.props.question && prevProps.question.id !== this.props.question.id) {
      this.onQuestionChange(this.props.question, prevProps.question);
    }
  }

  componentWillUnmount() {
    closeStreamManager(this.state.streamManager);
  }

  onQuestionChange = (newQuestion, oldQuestion) => {
    this.setState({
      ...VideoQuestionAnswerVideoRecorder.defaultAnswerState,
      recordAttempts: getRecordAttemptsForVideoQuestion(newQuestion),
    });
  }

  createStreamManager = () => {
    return createVideoPublisher()
      .then((publisher) => {
        const OV = getOpenViduSingleton();
        return OV.getDevices().then(devices => {
          this.setState({
            streamManager: publisher,
            audioDevices: filterAudioDevices(devices),
            videoDevices: filterVideoDevices(devices),
          });
          return publisher;
        });
      })
      .catch((e) => {
        this.setState({streamManager: null})
        throw e;
      });
  }

  stopRecording = () => {
    this.recorder.current.stopRecording();
  }

  onRecordingStart = () => {
    const {question} = this.props;
    const recordingStartTime = new Date();

    this.props.onAnswerError(null);
    if (hasAllowedReRecordAttemptsForVideoQuestion(question)) {
      if (question && question.answerTimeLimitMinutes) {
        recordingStartTime.setMinutes(recordingStartTime.getMinutes() + question.answerTimeLimitMinutes);
      }
      if (question && question.answerTimeLimitSeconds) {
        recordingStartTime.setSeconds(recordingStartTime.getSeconds() + question.answerTimeLimitSeconds);
      }

      if (question) {
        setEndThinkTimeForVideoQuestion(question);
      }
    }

    this.setState({
      recordingStartTime: recordingStartTime,
      recording: true,
    });

    this.props.onRecordingStarted(recordingStartTime);
  };

  onRecordingEnd = (recordAgain = false) => {
    this.setState({
      recordingStartTime: null,
      recording: false,
    });
  };

  onRecorded = (videoAnswer) => {
    const {question} = this.props;
    setLastVideoAnswerForVideoQuestion(question, videoAnswer);
    if (isQuestionHasRecordAttemptLimit(question)) {
      const attempts = incrementRecordAttemptsForVideoQuestion(question);
      this.setState({recordAttempts: attempts});
    }
    this.setVideoAnswer(videoAnswer);
  };

  setVideoAnswer = (videoAnswer) => {
    this.setState({videoAnswer: videoAnswer});
    this.props.onAnswer(videoAnswer);
  };

  onAnswerReset = () => {
    this.setVideoAnswer(null);
  };

  getReRecordButtonText = () => {
    const {question} = this.props;
    const {recordAttempts} = this.state;

    let text = 'Re-record';
    if (isQuestionHasRecordAttemptLimit(question)) {
      text = text + ' (' + recordAttempts + '/' + question.maxAnswerRecordingRetries + ')';
    }

    return text;
  }

  getAdditionalControlButtons = () => {
    const {cancelAnswer} = this.props;
    let buttons = {};
    if (cancelAnswer) {
      const selectAnotherAnswerButton = (
        <Button className={"control-button select-another-answer-type-button"} onClick={cancelAnswer}>
          Select Another Answer Type
        </Button>
      );
      buttons['startRecordingAdditionalButtons'] = selectAnotherAnswerButton;
      buttons['noVideoButtons'] = selectAnotherAnswerButton;
    }

    return buttons;
  }

  render() {
    const {streamManager, audioDevices, videoDevices, recording, recordingStartTime, recordAttempts, videoAnswer} = this.state;
    const {question, onAnswerError, screenSize} = this.props;
    const smallScreen = !screenSize.md;
    const timeLimitText = getQuestionRecorderText(question);
    let videoText = null;
    if (timeLimitText || this.props.thinkTimeLeft) {
      videoText = (
        <>
          {timeLimitText}
          {timeLimitText && this.props.thinkTimeLeft && (
            <>
              <br/>
              <br/>
            </>
          )}
          {this.props.thinkTimeLeft && (
            <>
              Recording begins in<br/>
              <span className={this.props.thinkTimeFiveSecondsLeft ? 'text-warning' : undefined}>{this.props.thinkTimeLeft}</span>
            </>
          )}
        </>
      );
    }

    let videoUrl;
    if (videoAnswer) {
      videoUrl = videoAnswer.localUrl ? videoAnswer.localUrl : videoAnswer.videoUrl;
    }

    return (
      <>
        {!smallScreen && (
          <DesktopRecorderLimitations
            question={question}
            stopRecording={this.stopRecording}
            recordingStartTime={recordingStartTime}
            recordingAttempts={recordAttempts}
          />
        )}
        <div className={classNames('video-answer-recorder', {
          'video-answer-recorder-recording': recording,
        })}>
          {this.props.allowToChangeDevice && !recording && (
            <ChangeDeviceButtons
              streamManager={streamManager}
              audioDevices={audioDevices}
              videoDevices={videoDevices}
            />
          )}
          {smallScreen && (
            <>
              <MobileRecordingTime question={question} stopRecording={this.stopRecording} recordingStartTime={recordingStartTime} />
              <ChangeDeviceNotification streamManager={streamManager} notificationContainerClass={'on-demand-interview-video-notification'} />
            </>
          )}
          <AnswerRecorder
            key={question.id}
            ref={this.recorder}
            streamManager={streamManager}
            onRecordingStarted={this.onRecordingStart}
            onRecordingStopping={this.onRecordingEnd}
            onRecorded={this.onRecorded}
            onVideoReset={this.onAnswerReset}
            onError={(errorMessage) => onAnswerError(errorMessage)}
            videoText={videoText}
            allowedToRecord={hasAllowedReRecordAttemptsForVideoQuestion(question)}
            videoUrl={videoUrl}
            autostartRecording={this.props.thinkTimeCountedDown}
            startRecordingDisabled={this.props.startRecordingDisabled}
            buttonLabels={{
              recordAgain: this.getReRecordButtonText()
            }}
            additionalButtons={this.getAdditionalControlButtons()}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  screenSize: state.screen.size,
});

const mapDispatchToProps = dispatch => ({
  onAnswerError: (message) => dispatch(answerError(message)),
});

VideoQuestionAnswerVideoRecorder = connect(
  mapStateToProps, mapDispatchToProps
)(VideoQuestionAnswerVideoRecorder);

export default VideoQuestionAnswerVideoRecorder;
