import { combineReducers } from 'redux';

export function updateError(state = null, action) {
  switch (action.type) {
    case 'COMPANY_USER_UPDATE_ROLE_ERROR':
      return action.updateError;

    case 'COMPANY_USER_UPDATE_ROLE_RESET':
      return null;

    default:
      return state;
  }
}

export function updateLoading(state = false, action) {
  switch (action.type) {
    case 'COMPANY_USER_UPDATE_ROLE_LOADING':
      return action.updateLoading;

    case 'COMPANY_USER_UPDATE_ROLE_RESET':
      return false;

    default:
      return state;
  }
}

export function updated(state = null, action) {
  switch (action.type) {
    case 'COMPANY_USER_UPDATE_ROLE_SUCCESS':
      return action.updated;

    case 'COMPANY_USER_UPDATE_ROLE_RESET':
      return null;

    default:
      return state;
  }
}


export default combineReducers({
  updateError,
  updateLoading,
  updated,
});
