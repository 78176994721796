import React, {Component} from "react";
import PropTypes from "prop-types";
import {ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon} from "@material-ui/icons";
import {Paper} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import CandidateJobInfo from "./CandidateJobInfo";

export default class ExpandableJob extends Component {
  static propTypes = {
    job: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {showDetails: false};
  }

  render() {
    const {job} = this.props;
    const { showDetails } = this.state;

    return (
      <>
        <div onClick={() => this.setState({showDetails: !showDetails})} style={{cursor: "pointer"}}>
          <span className="text-primary">{job['title']}</span>
          <div className="float-right">
            {showDetails ? (<ExpandLessIcon/>) : (<ExpandMoreIcon/>)}
          </div>
        </div>
        {showDetails && (
          <Paper className="mt-3">
            <Table>
              <CandidateJobInfo job={job} />
            </Table>
          </Paper>
        )}
      </>
    )
  }
}
