import React from "react";
import "./Style.scss";
const logoVisa = require("assets/img/subscription/payment-method-visa.png");
const logoMastercard = require("assets/img/subscription/payment-method-mastercard.png");
const logoUnionPay = require("assets/img/subscription/payment-method-unionpay.png");
const logoJCB = require("assets/img/subscription/payment-method-jcb.png");
const logoDiscover = require("assets/img/subscription/payment-method-discover.png");
const logoAMEX = require("assets/img/subscription/payment-method-amex.png");

export function PaymentMethodCard({paymentMethod, selected, onClick, onDelete, onSetAsDefaultPaymentMethod}) {
  const onSetAsDefaultClick = (e) => {
    e.stopPropagation();
    onSetAsDefaultPaymentMethod(paymentMethod);
  }

  const onDeleteClick = (e) => {
    e.stopPropagation();
    onDelete(paymentMethod);
  }



  return (
    <div className={"payment-method-card" + (selected ? ' selected' : '')} onClick={() => onClick(paymentMethod)}>
      {selected && (
        <i className={"fa fa-check-circle"} />
      )}
      {paymentMethod.isDefault && (
        <div className={"default-label"}>Default Payment</div>
      )}
      <div className={"label"}>Credit Card</div>
      <PaymentMethodLogo brand={paymentMethod.brand} />

      {!paymentMethod.isDefault && (
        <button className={"set-as-default"} onClick={onSetAsDefaultClick}>
          Set as default payment method
        </button>
      )}
      <div className={"last-digits"}>**** {paymentMethod['lastFour']}</div>
      <button className={"delete"} onClick={onDeleteClick}>Delete</button>
    </div>
  );
}

export function PaymentMethodLogo({brand, height = 21, width = 48}) {
  let logoSrc = null;
  let logoTitle = brand;

  switch (brand) {
    case 'Visa':
      logoSrc = logoVisa;
      break;
    case 'MasterCard':
      logoSrc = logoMastercard;
      break;
    case 'UnionPay':
      logoSrc = logoUnionPay;
      break;
    case 'JCB':
      logoSrc = logoJCB;
      break;
    case 'Discover':
      logoSrc = logoDiscover;
      break;
    case 'American Express':
      logoSrc = logoAMEX;
      break;
  }


  return logoSrc ? (
      <img height={height} width={width} src={logoSrc} alt={logoTitle} title={logoTitle} />
    ) : (
      <i className="fa fa-credit-card-alt" title={logoTitle} aria-hidden="true" />
    );
}
