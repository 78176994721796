import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import PhoneNumber from "../../views/Components/PhoneNumber";

class InterviewPageFooter extends Component {
  static propTypes = {
    site: PropTypes.object.isRequired,
  };

  render() {
    const {site} = this.props;
    const isPartnerSite = (site.type === 'partner');
    return (
      <div className={classNames('interview-page-footer')}>
        <div className={'interview-page-footer-support'}>
          Call <PhoneNumber phone={site.supportPhone} formattedPhone={site.supportPhoneFormatted} /> or email <a href={`mailto:${site.supportEmail}`}>{site.supportEmail}</a> for help.
        </div>
        {isPartnerSite && (
          <div className={classNames('interview-page-footer-powered-by', {'interview-page-footer-powered-by-with-logo mt-2': !!site.logo})}>
            <span>Powered by</span>
            {site.logo && (
              <img src={site.logo} alt={site.title} title={site.title} className={'interview-page-footer-site-logo'} />
            )}
            <span className={'interview-page-footer-site-name'}>{site.name}</span>
          </div>
        )}
      </div>
    )
  }
}

export default InterviewPageFooter;
