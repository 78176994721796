import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";
import {loadIndustriesForSelect} from "../../actions/Industry/forSelect";

class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  componentDidMount() {
    this.props.loadIndustriesForSelect();
  }

  render() {
    const fields = [
      {'name': 'id', 'label': 'ID', 'placeholder': 'ID', 'type': 'number'},
      {'name': 'name', 'label': 'Name', 'placeholder': 'Name', 'type': 'text'},
      {'name': 'industry', 'label': 'Industry', 'placeholder': 'Industry', 'type': 'selectSearch', 'options': this.props.industries},
    ];

    return (
      <RenderFilterForm {...this.props} fields={fields} />
    );
  }
}

const mapStateToProps = state => {
  let industries = state.industry.forSelect.retrieved;

  return { industries };
};


const mapDispatchToProps = dispatch => ({
  loadIndustriesForSelect: () => dispatch(loadIndustriesForSelect('@id')),
});

FilterForm = reduxForm({
  form: 'occupations',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterForm);
