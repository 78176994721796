import {
  fetch,
  extractHubURL
} from '../../utils/dataAccess';
import {filterAction} from "../../utils/filterAndSortUtils";
import {applyToUrl} from "../../utils/urls";

export function error(error) {
  return { type: 'COMPANY_STATISTIC_ERROR', error };
}

export function loading(loading) {
  return { type: 'COMPANY_STATISTIC_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'COMPANY_STATISTIC_SUCCESS', retrieved };
}

export function companyStatistic(filters = {}) {
  return dispatch => {
    const page =  'company-statistic';
    dispatch(loading(true));
    dispatch(error(''));

    fetch(page, {method: 'POST', data: filters})
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        dispatch(loading(false));
        dispatch(success(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch({ type: 'COMPANY_STATISTIC_RESET' });
  };
}

export function filter(filtersData) {
  return filterAction('COMPANY_STATISTIC_FILTER', 'company_statistic', filtersData)
}
