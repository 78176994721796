import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import {resolveDashboardUrl} from "../../utils/dashboardUrlResolver";
import {Link} from "react-router-dom";
import {currentUserRoles, handleAuthentication, isAuthenticated, login} from "../../utils/auth";
import SweetAlert from "react-bootstrap-sweetalert";
import {Redirect} from "react-router-dom";
import LoginForm from "../../components/Login/LoginForm";
import {destroy} from "redux-form";
import {connect} from "react-redux";
import {LoginFormName} from "../../components/Login/LoginFormSettings";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      alert: null,
      loggingIn: false,
    };
  }

  addSweetAlert(error) {
    this.setState({
      alert: (
        <SweetAlert
          style={{color: '#ea4f58'}}
          danger
          title={error}
          onConfirm={() => this.hideAlert()}
          confirmBtnCssClass={this.props.classes.danger + " " + this.props.classes.button + ' ml-2'}
        />
      )
    });
  }

  hideAlert() {
    this.setState({alert: null});
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  onSubmit = (submitValues) => {
    this.setState({loggingIn: true});
    login(submitValues.email, submitValues.password)
    .then(res => {
      this.setState({loggingIn: false});
      this.props.clearLoginFormData();
      handleAuthentication(res.data);
    })
    .catch(error => {
      this.setState({loggingIn: false});
      this.addSweetAlert(error.message);
    });
  };

  render() {
    const { classes, site } = this.props;
    const { loggingIn } = this.state;
    const isPartnerSite = site.type === 'partner';

    if (isAuthenticated()) return <Redirect to={resolveDashboardUrl(currentUserRoles())} />;

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={7} md={4}>
            <Card login>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
              >
                <h4>Login</h4>
              </CardHeader>
              <CardBody>
                <LoginForm onSubmit={this.onSubmit} loggingIn={loggingIn} />
              </CardBody>
            </Card>
            <Card>
              <CardBody align="center">
                <h4>Don't have an account?</h4>
                {isPartnerSite && site.companyRegistrationEnabled && (<Link to={`/register/company`}>
                  <Button round color="primary">
                    Company Registration
                  </Button>
                </Link>)}
                <Link to={`/register/candidate`}>
                  <Button round color="info">
                    Candidate Registration
                  </Button>
                </Link>
              </CardBody>
            </Card>
            {this.state.alert}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  site: state.siteDetails.siteDetails.retrieved,
});
const mapDispatchToProps = (dispatch) => ({
  clearLoginFormData: () => dispatch(destroy(LoginFormName)),
});

LoginPage = connect(mapStateToProps, mapDispatchToProps)
(LoginPage);

export default withStyles(loginPageStyle)(LoginPage);
