import {fetch, normalizeAttributes} from "../../utils/dataAccess";
import {error} from "./page";
import { cloneDeep } from "lodash";
import {handleAuthentication} from "../../utils/auth";
import {SubmissionError} from "redux-form";
import {loggedIn} from "./auth";
import {saveInterviewCode} from "../../utils/onDemandInterview";

export function createInterview(data) {
  return (dispatch) => {
    dispatch(error(null));
    dispatch(creatingInterview(true));
    return fetch(`/on_demand_interviews`, {
      method: 'POST',
      data: normalizeAttributes(cloneDeep(data), ['resume']),
    }).then(response => {
      const interview = response.data;
      if (interview['@auth']) {
        handleAuthentication(interview['@auth'], false);
        dispatch(loggedIn(true));
      }
      delete interview['@auth'];
      saveInterviewCode(interview);
      dispatch(interviewReceived(interview));
      dispatch(creatingInterview(false));
    }).catch((e) => {
      dispatch(creatingInterview(false));
      if (e instanceof SubmissionError) {
        if (e.errors && e.errors._error) {
          dispatch(error(e.errors._error));
        }
        throw e;
      }
      dispatch(error(e.message));
    });
  }
}

export function startInterview(interview) {
  return async (dispatch) => {
    dispatch(startingInterview(true));
    dispatch(error(null));
    try {
      const response = await fetch(`/on_demand_interviews/${interview['interviewCode']}/start`, {
        method: 'POST',
      });
      const startedInterview = response.data;
      dispatch(interviewReceived(startedInterview));
      dispatch(startingInterview(false));
    } catch (e) {
      dispatch(startingInterview(false));
      dispatch(error(e.message));
    }
  }
}

export function creatingInterview(creating) {
  return { type: 'ON_DEMAND_INTERVIEW_PAGE_INTERVIEW_CREATING', creating };
}

export function startingInterview(starting) {
  return { type: 'ON_DEMAND_INTERVIEW_PAGE_INTERVIEW_STARTING', starting };
}

export function interviewReceived(interview) {
  return { type: 'ON_DEMAND_INTERVIEW_PAGE_INTERVIEW', interview };
}
