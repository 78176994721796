import { combineReducers } from 'redux';

export function error(state = null, action) {
  switch (action.type) {
    case 'INVITATION_UPDATE_EXPIRES_AT_ERROR':
      return action.error;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'INVITATION_UPDATE_EXPIRES_AT_LOADING':
      return action.loading;

    default:
      return state;
  }
}

export function updated(state = null, action) {
  switch (action.type) {
    case 'INVITATION_UPDATE_EXPIRES_AT_SUCCESS':
      return action.updated;

    default:
      return state;
  }
}

export default combineReducers({ error, loading, updated });
