import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";


class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  render() {
    const interviewStatusChoices = [
      {text: 'All', value: '__all__'},
      {text: 'Complete', value: 'true'},
      {text: 'Incomplete', value: 'false'}
    ];

    return (
      <RenderFilterForm {...this.props} fields={[
        {'name': 'completedAt__exists__', 'label': 'Interview Status', 'placeholder': 'Interview Status', 'type': 'selectSearch', 'options': interviewStatusChoices},
        {'name': 'completedAt', 'label': 'Completed Date', 'placeholder': 'Completed Date', 'type': 'datesRange', 'past': true},
      ]} />
    );
  }
}

export default reduxForm({
  form: 'filter_candidate_one_way_interview',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);
