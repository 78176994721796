import React, {Component} from "react";
import {Field, Form, formValueSelector, reduxForm} from "redux-form";
import SearchableSelect from "../../views/Components/SearchableSelect";
import {connect} from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import withStyles from "@material-ui/core/styles/withStyles";
import {Tooltip} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/InfoOutlined';
import {renderCheckbox} from "../../utils/form";
import Typography from "@material-ui/core/Typography";

const style = {
  userLabel: {
    lineHeight: '2.4em',
  },
  userLabelTooltip: {
    fontSize: 'medium',
    cursor: 'pointer',
  },
  fieldsContainer: {
    paddingLeft: '2em',
  },
  permissionsHeading: {
    paddingBottom: '6px',
  },
  checkboxLabelClass: {
    paddingTop: 0,
    paddingBottom: 0,
  }
};

class InterviewAccessForm extends Component {

  getGroupedUserOptions = () => {
    const {users} = this.props;
    const options = [];
    const admins = [];
    const employees = [];
    const manager = [];

    const formatUserForSelect = (user) => {
      return {
        'value': user['@id'],
        'text': `${user.firstName} ${user.lastName} (${user.email})`
      };
    };

    users.map((user) => {
      if (user['@type'] === 'CompanyAdmin') {
        admins.push(formatUserForSelect(user));
      } else if (user['@type'] === 'CompanyEmployee') {
        employees.push(formatUserForSelect(user));
      } else if (user['@type'] === 'CompanyHiringManager') {
        manager.push(formatUserForSelect(user));
      }
    });

    if (manager.length) {
      options.push({
        label: 'Hiring Managers',
        options: manager,
      })
    }

    if (employees.length) {
      options.push({
        label: 'Company Employees',
        options: employees,
      })
    }

    if (admins.length) {
      options.push({
        label: 'Company Admins',
        options: admins,
      })
    }

    return options;
  };

  render() {
    const {classes, selectedUser, initialValues} = this.props;
    const checkboxClasses = {checkbox: {
      root: classes.checkboxLabelClass,
    }};
    const showCopyPermission = false;
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <GridContainer>
          <GridItem xs={12} sm={2}>
            <InputLabel className={classes.userLabel}>
              Users <Tooltip title="Users on the same team can already view this interview.">
                <InfoIcon className={classes.userLabelTooltip} />
              </Tooltip>
            </InputLabel>
          </GridItem>
          <GridItem xs={12} sm={10}>
            <Field
              label={null}
              component={SearchableSelect}
              name="user"
              placeholder="Select User"
              isDisabled={initialValues && initialValues.user}
              options={this.getGroupedUserOptions()}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Typography variant="h6" component="h2" className={classes.permissionsHeading}>Viewing Permissions</Typography>
          </GridItem>
          <GridItem xs={12}>
            <div className={classes.fieldsContainer}>
              <Field
                component={renderCheckbox}
                name="permissions[view_interview]"
                type="checkbox"
                label="Interview"
                classes={checkboxClasses}
                disabled={true}
              />
              <Field
                component={renderCheckbox}
                name="permissions[view_candidate]"
                type="checkbox"
                label="Candidate"
                classes={checkboxClasses}
                disabled={true}
              />
              <Field
                component={renderCheckbox}
                name="permissions[view_job]"
                type="checkbox"
                label="Job"
                classes={checkboxClasses}
                disabled={true}
              />
              <Field
                component={renderCheckbox}
                name="permissions[view_reviews_and_comments]"
                type="checkbox"
                label="View Reviews/Comments"
                classes={checkboxClasses}
              />
            </div>
          </GridItem>
        </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              <Typography variant="h6" component="h2" className={classes.permissionsHeading}>Change Permissions</Typography>
            </GridItem>
            <GridItem xs={12}>
              <div className={classes.fieldsContainer}>
                <Field
                  component={renderCheckbox}
                  name="permissions[create_reviews_and_comments]"
                  type="checkbox"
                  label="Add Reviews/Comments"
                  classes={checkboxClasses}
                />
                {showCopyPermission && selectedUser && (
                  selectedUser['@type'] === 'CompanyAdmin' ||
                  selectedUser['@type'] === 'CompanyEmployee')
                && (
                  <Field
                    component={renderCheckbox}
                    name="permissions[copy_interview]"
                    type="checkbox"
                    label="Copy this interview (without reviews/comments)"
                    classes={checkboxClasses}
                  />
                )}
              </div>
            </GridItem>
          </GridContainer>
      </Form>
    );
  }
}
const selector = formValueSelector('interview_access_form');
const mapStateToProps = (state, ownProps) => {
  const userId = selector(state, 'user');
  return {
    selectedUser: userId ? ownProps.users.find(user => user['@id'] === userId) : null,
  }
};

InterviewAccessForm = connect(mapStateToProps)(InterviewAccessForm);

InterviewAccessForm = reduxForm({
  form: 'interview_access_form',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(InterviewAccessForm);

export default withStyles(style)(InterviewAccessForm);
