import React, {Component} from "react";
import {Field} from "redux-form";
import {renderTextField} from "../../utils/form";
import './VideoQuestion.scss'
import PropTypes from "prop-types";
import Switch from '@material-ui/core/Switch'

export default class VideoQuestionTimeLimitOption extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        change: PropTypes.func.isRequired,
        onEnable: PropTypes.func,
        fieldNameMinutes: PropTypes.string.isRequired,
        fieldNameSeconds: PropTypes.string.isRequired,
        currentValueMinutes: PropTypes.number,
        currentValueSeconds: PropTypes.number,
        minValue: PropTypes.number,
    };

    static defaultProps = {
        minValue: 15,
    }

    onMinutesChange = (e, v) => {
        v = parseInt(v);
        let seconds;

        if (v > 59) {
            seconds = 0;
        } else if (v === 0 && this.props.currentValueSeconds < 15) {
            seconds = 15;
        }
        this.props.change(this.props.fieldNameSeconds, seconds);
    }

    onSecondsChange = (e, v) => {
        v = parseInt(v);

        if (v > 59 || v < 0) {
            const seconds = (v === 60) ? 0 : 59;
            const minutes = v === 60 ? this.props.currentValueMinutes + 1 : this.props.currentValueMinutes - 1;

            setTimeout(() => {
                this.props.change(this.props.fieldNameSeconds, seconds);
            }, 25);

            this.props.change(this.props.fieldNameMinutes, minutes);
        }
    }

    onTimeLimitChecked = (e, value) => {
        const minutes = value ? 2 : null;
        const seconds = value ? 30 : null;
        this.props.change(this.props.fieldNameMinutes, minutes);
        this.props.change(this.props.fieldNameSeconds, seconds);

        if (value && typeof this.props.onEnable === 'function') {
            this.props.onEnable();
        }
    };

    render() {
        const timeLimitMinutes = this.props.currentValueMinutes;
        const timeLimitSeconds = this.props.currentValueSeconds;
        const showTimeLimit = !!(timeLimitMinutes || timeLimitSeconds);

        return (
            <div className={"clearfix"}>
                <div className={"float-left"}>
                    <Switch
                        checked={showTimeLimit}
                        onChange={this.onTimeLimitChecked}
                        color="primary"
                    />
                </div>
                <label className={"float-left mt-3" + (showTimeLimit ? ' text-dark' : '')}>
                    {this.props.label}{showTimeLimit && (' (min:sec):')}
                </label>
                {showTimeLimit && (
                    <div className={"float-left ml-2"} style={{width: 150}}>
                        <Field
                            key={'min'}
                            component={renderTextField}
                            name={this.props.fieldNameMinutes}
                            normalize={v => v ? parseInt(v) : null}
                            type="number"
                            inputProps={{
                                min: 0,
                                max: 60,
                                step: 1,
                                style: {textAlign: 'center'},
                            }}
                            label={false}
                            placeholder={""}
                            containerClass={'float-left time-limits'}
                            onChange={this.onMinutesChange}
                        />
                        <div key={'div'} className={"float-left ml-1 mr-1 pt-1"}>:</div>
                        <Field
                            key={'sec'}
                            component={renderTextField}
                            name={this.props.fieldNameSeconds}
                            type="number"
                            normalize={v => v ? parseInt(v) : null}
                            inputProps={{
                                min: timeLimitMinutes ? -1 : this.props.minValue,
                                max: 60,
                                step: 1,
                                style: {textAlign: 'center'},
                            }}
                            label={false}
                            placeholder={""}
                            containerClass={'float-left time-limits'}
                            disabled={timeLimitSeconds > 59}
                            onChange={this.onSecondsChange}
                        />
                    </div>
                )}
            </div>
        );

    }
}
