import React from "react";
import MuteControl from "../VideoControl/MuteControl";
import VideoControl from "../VideoControl/VideoControl";
import VideoDeviceSelect from "../VideoControl/VideoDeviceSelect";
import MicrophoneDeviceSelect from "../VideoControl/MicrophoneDeviceSelect";
import {connect} from "react-redux";
import LiveInterviewShareScreen from "./LiveInterviewShareScreen";
import PropTypes from 'prop-types';

class VideoInterviewSettings extends React.Component {
  static propTypes = {
    showScreenShareButton: PropTypes.bool,
  };

  static defaultProps = {
    showScreenShareButton: true
  };

  render() {
    const { buttonClass, streamManager, liveInterview, liveInterviewParticipant, sipAudio, showScreenShareButton, sessionContainSharedScreen } = this.props;
    return (
      streamManager && streamManager.accessAllowed ? (
        <>
          <MuteControl streamManager={streamManager} buttonClass={buttonClass} disabled={!!sipAudio} />
          <VideoControl streamManager={streamManager} buttonClass={buttonClass} />
          <MicrophoneDeviceSelect streamManager={streamManager} buttonClass={buttonClass} sipOptions={{
            sipPhone: liveInterview.sipPhoneNumber,
            sipCode: liveInterviewParticipant && liveInterviewParticipant.sipCode,
          }}/>
          <VideoDeviceSelect streamManager={streamManager} buttonClass={buttonClass} />
          {showScreenShareButton && (
            <LiveInterviewShareScreen sessionContainSharedScreen={sessionContainSharedScreen} streamManager={streamManager} buttonClass={buttonClass} />
          )}
        </>
      ) : null
    )
  }
}

const mapStateToProps = state => ({
  streamManager: state.liveInterviewPage.publisher,
  sipAudio: state.liveInterviewPage.sipAudio,
  sharedScreen: state.liveInterviewPage.sharedScreen,
  liveInterview: state.liveInterviewPage.liveInterview,
  liveInterviewParticipant: state.liveInterviewPage.liveInterviewParticipant,
});

VideoInterviewSettings = connect(
  mapStateToProps,
)(VideoInterviewSettings);

export default VideoInterviewSettings;
