import { SubmissionError } from 'redux-form';
import { cloneDeep } from "lodash";
import {fetch, normalizeAttributes} from '../../utils/dataAccess';

export function error(error) {
  return { type: 'CANDIDATE_CREATE_ERROR', error };
}

export function loading(loading) {
  return { type: 'CANDIDATE_CREATE_LOADING', loading };
}

export function success(created) {
  return { type: 'CANDIDATE_CREATE_SUCCESS', created };
}

export function create(values) {
  return dispatch => {
    dispatch(loading(true));
    if (values.occupation === '') {
      values.occupation = null;
    }
    if (values.industry === '') {
      values.industry = null;
    }

    const data = normalizeAttributes(cloneDeep(values), ['resume']);
    return fetch('candidates', { method: 'POST', data })
      .then(response => {
        dispatch(loading(false));
        dispatch(success(response.data));
      })
      .catch(e => {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw e;
        }

        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(error(null));
    dispatch(success(null));
  };
}
