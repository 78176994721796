import React from "react";
import Chip from "@material-ui/core/Chip";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  chip: {
    marginBottom: '5px',
    marginRight: '5px'
  },
};

class ProfessionMappingView extends React.Component {
  render() {
    const {classes, mapping: {industry, occupation, specialty}} = this.props;
    let label = '';
    if (industry) {
      label += industry.name;
    }
    if (occupation) {
      label += `, ${occupation.name}`;
    }
    if (specialty) {
      label += `, ${specialty.name}`;
    }
    return (
      <Chip label={label} variant="outlined" className={classes.chip}/>
    );
  }
}

export default withStyles(styles)(ProfessionMappingView);
