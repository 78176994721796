import {VIDEO_ENTRYPOINT} from "../config/entrypoint";
import {getAuthToken} from "./auth";
import storage from "./storage";
import socketIOClient from "socket.io-client";

const SOCKET_URL = VIDEO_ENTRYPOINT + '/browser-recording';

function initSocket(recordingType, recordingId) {
  let headers = {
    'X-Recording-Type': recordingType,
    'X-Recording-Id': recordingId,
  };
  const token = getAuthToken();
  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }

  let xSwitchUser = storage.get('X-Switch-User');
  if (xSwitchUser) {
    headers['X-Switch-User'] = xSwitchUser;
  }

  return socketIOClient(SOCKET_URL, {
    transportOptions: {
      polling: {
        extraHeaders: headers,
      }
    }
  });
}

export default {
  initSocket,
}


