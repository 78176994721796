import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";
import {loadPartnersForSelect} from "../../actions/Partner/forSelect";
import {currentUserHasRole} from "../../utils/auth";
import {loadIndustriesForSelect} from "../../actions/Industry/forSelect";
import {loadOccupationsForSelect} from "../../actions/Occupation/forSelect";
import {loadSpecialtiesForSelect} from "../../actions/Specialty/forSelect";

class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  state = {
    industry:  null,
    occupation: null,
  };

  componentDidMount() {
    this.props.loadIndustriesForSelect();
    this.props.loadOccupationsForSelect();
    this.props.loadSpecialtiesForSelect();
    if (currentUserHasRole('ROLE_SUPER_ADMIN')) {
      this.props.loadPartnersForSelect();
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.initialValues !== this.props.initialValues) {
      const values = nextProps.initialValues;
      this.setState({
        industry: null !== values && ('professionMappings___industry' in values) ? values['professionMappings___industry'] : null,
        occupation: null !== values && ('professionMappings___occupation' in values) ? values['professionMappings___occupation'] : null,
      });
    }
  }

  handleIndustrySelect = (e, value, oldValue) => {
    if (value !== oldValue) {
      this.props.change('professionMappings___occupation', null);
      this.props.change('professionMappings___specialty', null);

      this.setState({
        industry: value,
        occupation: null,
      });
    }
  };

  handleOccupationSelect = (e, value, oldValue) => {
    if (value !== oldValue) {
      this.props.change('professionMappings___specialty', null);

      this.setState({occupation: value});
    }
  };

  render() {
    const statuses = [
      {value: 'active', text: 'Active'},
      {value: 'inactive', text: 'Inactive'},
      {value: 'pending', text: 'Pending'},
    ];

    let occupationPlaceholder = 'Select Industry First';
    let specialtyPlaceholder = 'Select Occupation First';
    let occupationOptions = [];
    let specialtyOptions = [];

    if (this.state.industry !== null) {
      occupationPlaceholder = 'Occupation';
      if (this.props.occupations) {
        this.props.occupations.map(occupation => {
          if (occupation.industry === this.state.industry) {
            occupationOptions.push(occupation);
          }
        });
      }
    }
    if (this.state.occupation !== null) {
      specialtyPlaceholder = 'Specialty';
      if (this.props.specialties) {
        this.props.specialties.map(specialty => {
          if (specialty.occupation === this.state.occupation) {
            specialtyOptions.push(specialty);
          }
        });
      }
    }

    const fields = [
      {'name': 'id', 'label': 'ID', 'placeholder': 'ID', 'type': 'number'},
      {'name': 'firstName', 'label': 'First Name', 'placeholder': 'Last Name', 'type': 'text'},
      {'name': 'lastName', 'label': 'Last Name', 'placeholder': 'Last Name', 'type': 'text'},
      {'name': 'email', 'label': 'Email Address', 'placeholder': 'Email Address', 'type': 'text'},
      {'name': 'status', 'label': 'Status', 'placeholder': 'Status', 'type': 'selectSearch', 'options': statuses},
      {'name': 'professionMappings___industry', 'label': 'Industry', 'placeholder': 'Industry', 'type': 'selectSearch', 'options': this.props.industries, 'onChange': this.handleIndustrySelect},
      {'name': 'professionMappings___occupation', 'label': 'Occupation', 'placeholder': occupationPlaceholder, 'type': 'selectSearch', 'options': occupationOptions, 'onChange': this.handleOccupationSelect, 'isDisabled': !this.state.industry},
      {'name': 'professionMappings___specialty', 'label': 'Specialty', 'placeholder': specialtyPlaceholder, 'type': 'selectSearch', 'options': specialtyOptions, 'isDisabled': !this.state.occupation},
    ];

    if (currentUserHasRole('ROLE_SUPER_ADMIN')) {
      fields.push({'name': 'partner', 'label': 'Partner', 'placeholder': 'Partner', 'type': 'selectSearch', 'options': this.props.partners});
    }

    return (
      <RenderFilterForm {...this.props} fields={fields} />
    );
  }
}

const mapStateToProps = state => {
  let partners = state.partner.forSelect.retrieved;
  let industries = state.industry.forSelect.retrieved;
  let occupations = state.occupation.forSelect.retrieved;
  let specialties = state.specialty.forSelect.retrieved;

  return { partners, industries, occupations, specialties };
};

const mapDispatchToProps = dispatch => ({
  loadPartnersForSelect: () => dispatch(loadPartnersForSelect()),
  loadOccupationsForSelect: () => dispatch(loadOccupationsForSelect('@id')),
  loadSpecialtiesForSelect: () => dispatch(loadSpecialtiesForSelect('@id')),
  loadIndustriesForSelect: () => dispatch(loadIndustriesForSelect('@id')),
});

FilterForm = reduxForm({
  form: 'candidates',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterForm);
