import React from "react";
import DialogContentText from '@material-ui/core/DialogContentText';
import LiveInterviewRequestNewTimeForm from "../LiveInterviewCandidateinvitation/LiveInterviewRequestNewTimeForm";
import {connect} from "react-redux";
import { submit } from 'redux-form'
import Dialog from "../../views/Components/Dialog/Dialog";

class NewTimeRequestDialog extends React.Component  {
  render() {
    const {open, handleSubmit, handleClose, submitForm, screenSize} = this.props;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        closeButtonText={"Cancel"}
        onConfirm={submitForm}
        confirmButtonText={"Submit"}
        dialogProperties={{fullScreen: !screenSize.md}}
        title={"Request a New Time"}
      >
        <DialogContentText>
          Please, write us a message with the time that will be convenient for you to take an interview.
        </DialogContentText>
        <LiveInterviewRequestNewTimeForm onSubmit={handleSubmit}/>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  screenSize: state.screen.size,
});

const mapDispatchToProps = dispatch => ({
  submitForm: () => dispatch(submit('LiveInterviewRequestNewTimeForm')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps)
(NewTimeRequestDialog);
