import {Field, reduxForm} from "redux-form";
import {renderTextField} from "../../utils/form";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Email} from "@material-ui/icons";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {LoginFormName, LoginFormStyle} from "../../components/Login/LoginFormSettings";
import { isEmail } from 'validator';
import LoadingButton from "../../views/Components/LoadingButton";

const LoginFirstStep = props => {
  const { handleSubmit, loading, classes } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        label={null}
        component={renderTextField}
        name="email"
        type="email"
        placeholder="Email"
        required={true}
        containerClass={classes.inputContainer}
        validate={(value) => {
          if (!value || value.trim().length === 0) {
            return 'Please, enter an email';
          }
          if (!isEmail(value)) {
            return 'Invalid email address';
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Email className={classes.inputAdornmentIcon} />
            </InputAdornment>
          ),
        }}
      />
      <div className={classes.justifyContentCenter + ' mt-5'}>
        <LoadingButton
          type="submit"
          color="info"
          loading={loading}
          loadingText="Checking..."
        >
          Next
        </LoadingButton>
      </div>
    </form>
  );
};

const styledForm = withStyles(LoginFormStyle)(LoginFirstStep);

export default reduxForm({
  form: LoginFormName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(styledForm);
