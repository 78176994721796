import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";
import {loadTagsForSelect} from "../../actions/Tag/forSelect";
import {loadIndustriesForSelect} from "../../actions/Industry/forSelect";
import {loadOccupationsForSelect} from "../../actions/Occupation/forSelect";
import {loadSpecialtiesForSelect} from "../../actions/Specialty/forSelect";

class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string,
    parentLoading: PropTypes.bool,
  };

  state = {
    industry: null,
    occupation: null,
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.initialValues !== this.props.initialValues) {
      const values = nextProps.initialValues;
      this.setState({
        industry: null !== values && ('professionMapping___industry' in values) ? values['professionMapping___industry'] : null,
        occupation: null !== values && ('professionMapping___occupation' in values) ? values['professionMapping___occupation'] : null,
      });
    }
  }

  onFirstExpand = () => {
    this.props.loadTagsForSelect();
    this.props.loadIndustriesForSelect();
    this.props.loadOccupationsForSelect();
    this.props.loadSpecialtiesForSelect();
  }

  handleIndustrySelect = (e, value, oldValue) => {
    if (value !== oldValue) {
      this.props.change('professionMapping___occupation', null);
      this.props.change('professionMapping___specialty', null);

      this.setState({
        industry: value,
        occupation: null,
      });
    }
  };

  handleOccupationSelect = (e, value, oldValue) => {
    if (value !== oldValue) {
      this.props.change('professionMapping___specialty', null);

      this.setState({occupation: value});
    }
  };

  render() {
    let occupationPlaceholder = this.props.occupationsLoading ? 'Loading...' : 'Select Industry First';
    let specialtyPlaceholder = this.props.specialtiesLoading ? 'Loading...' : 'Select Occupation First';
    let tagsPlaceholder = this.props.tagsLoading ? 'Loading...' : 'Tags';
    let industriesPlaceholder = this.props.industriesLoading ? 'Loading...' : 'Industries';
    let occupationOptions = [];
    let specialtyOptions = [];

    if (this.state.industry !== null) {
      occupationPlaceholder = 'Occupation';
      if (this.props.occupations) {
        this.props.occupations.map(occupation => {
          if (occupation.industry === this.state.industry) {
            occupationOptions.push(occupation);
          }
        });
      }
    }
    if (this.state.occupation !== null) {
      specialtyPlaceholder = 'Specialty';
      if (this.props.specialties) {
        this.props.specialties.map(specialty => {
          if (specialty.occupation === this.state.occupation) {
            specialtyOptions.push(specialty);
          }
        });
      }
    }

    return (
      <>
        {!this.props.parentLoading && this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        <RenderFilterForm {...this.props} onFirstExpand={this.onFirstExpand} fields={[
          {'name': 'id', 'label': 'ID', 'placeholder': 'ID', 'type': 'number'},
          {'name': 'firstName', 'label': 'First Name', 'placeholder': 'First Name', 'type': 'text'},
          {'name': 'lastName', 'label': 'Last Name', 'placeholder': 'Last Name', 'type': 'text'},
          {'name': 'email', 'label': 'Email Address', 'placeholder': 'Email Address', 'type': 'text'},
          {'name': 'phoneNumber', 'label': 'Phone Number', 'placeholder': 'Phone Number', 'type': 'text'},
          {'name': 'tags', 'label': 'Tags', 'placeholder': tagsPlaceholder, 'type': 'selectSearch', 'options': this.props.tags},
          {'name': 'createdAt', 'label': 'Created Date', 'placeholder': 'Created Date', 'type': 'datesRange', 'past': true},
          {'name': 'professionMapping___industry', 'label': 'Industry', 'placeholder': industriesPlaceholder, 'type': 'selectSearch', 'options': this.props.industries, 'onChange': this.handleIndustrySelect},
          {'name': 'professionMapping___occupation', 'label': 'Occupation', 'placeholder': occupationPlaceholder, 'type': 'selectSearch', 'options': occupationOptions, 'onChange': this.handleOccupationSelect, 'isDisabled': !this.state.industry},
          {'name': 'professionMapping___specialty', 'label': 'Specialty', 'placeholder': specialtyPlaceholder, 'type': 'selectSearch', 'options': specialtyOptions, 'isDisabled': !this.state.occupation},
        ]} />
      </>
    );
  }
}

const mapStateToProps = state => {
  let tags = state.tag.forSelect.retrieved;
  let industries = state.industry.forSelect.retrieved;
  let occupations = state.occupation.forSelect.retrieved;
  let specialties = state.specialty.forSelect.retrieved;
  let tagsLoading = state.tag.forSelect.loading;
  let industriesLoading = state.industry.forSelect.loading;
  let occupationsLoading = state.occupation.forSelect.loading;
  let specialtiesLoading = state.specialty.forSelect.loading;
  let loading = state.tag.forSelect.loading || state.industry.forSelect.loading || state.occupation.forSelect.loading || state.specialty.forSelect.loading;

  return { tags, industries, occupations, specialties, tagsLoading, industriesLoading, occupationsLoading, specialtiesLoading, loading };
};

const mapDispatchToProps = dispatch => ({
  loadTagsForSelect: () => dispatch(loadTagsForSelect()),
  loadIndustriesForSelect: () => dispatch(loadIndustriesForSelect('@id')),
  loadOccupationsForSelect: () => dispatch(loadOccupationsForSelect('@id')),
  loadSpecialtiesForSelect: () => dispatch(loadSpecialtiesForSelect('@id')),
});

FilterForm = reduxForm({
  form: 'filter_company_application',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterForm);
