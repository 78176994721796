import React, {Component} from 'react';
import {Form, Field, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {reset ,loadTagsForSelect} from "../../actions/Tag/forSelect";
import {connect} from "react-redux";
import SearchableSelect from "../../views/Components/SearchableSelect";
import {normalize} from "../../utils/dataAccess";

class TagsForm extends Component {
  static propTypes = {
    handleSave: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.string,
    updated: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      tags: props.initialValues.tags,
      lastSavedTags: props.initialValues.tags,
    };
  }

  componentDidMount() {
    this.props.loadTagsForSelect();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  handleChange = (e, newValue) => {
    this.state.tags = newValue;
  };

  render() {
    const {tagOptions, customStyles} = this.props;
    return (
      <Form className="tags-container" 
      onSubmit={this.props.handleSubmit((values) => {
        this.props.onSubmit(values);
        this.state.lastSavedTags = values.tags;
      })} >
        <Field
          name='tags'
          label={null}
          placeholder='Tags'
          isMulti={true}
          component={SearchableSelect}
          options={tagOptions}
          containerClass="pl-0 mr-2"
          onChange={this.handleChange}
          customStyles={customStyles}
        />
        {this.props.loading && (
          <button type="submit" className="btn btn-info" disabled={true}>
            <i className="fa fa-spinner fa-spin mr-2" aria-hidden="true" />
            Save
          </button>
        )}
        {!this.props.loading && (
          <button type="submit" className="btn btn-info" disabled={!this.state || this.state.tags.join('|') === this.state.lastSavedTags.join('|')}>
            Save
          </button>
        )}
        {this.props.updated && (
          <div className="mt-2 text-success tags-message">Successfully Saved</div>
        )}
        {this.props.error && (
          <div className="mt-2 text-danger tags-message">Error: {this.props.error}</div>
        )}
      </Form>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  tagOptions: state.tag.forSelect.retrieved,
  initialValues: {
    tags: (normalize({tags: (ownProps.tags || [])})).tags,
  },
  onSubmit: (values) => ownProps.handleSave(values.tags),
});

const mapDispatchToProps = dispatch => ({
  loadTagsForSelect: () => dispatch(loadTagsForSelect('@id')),
  reset: () => dispatch(reset()),
});

TagsForm = reduxForm({
  form: 'tags_form',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(TagsForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagsForm);
