import React, {Component} from 'react';
import {connect} from "react-redux";
import classNames from 'classnames';
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import {currentUserHasRole, isAuthenticated} from "../../utils/auth";
import storage from "../../utils/storage";
import {history} from "../../store";
import HeaderCompanyLogo from "../Interview/HeaderCompanyLogo";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from '@material-ui/icons/Help';
import {STEP_QUESTIONS, STEP_TEST} from "./OnDemandInterviewSteps";
import HelpDialog from "./HelpDialog";
import PropTypes from "prop-types";
import Dialog from "../../views/Components/Dialog/Dialog";

class OnDemandInterviewHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showHelpDialog: false,
      thinkTimeDialogOpenFor: null,
    };
  }
  static defaultProps = {
    includeLoginButton: true,
  };
  static propTypes = {
    includeLoginButton: PropTypes.bool,
  }

  onClickLogin = () => {
    if (this.props.thinkTimeStarted && !this.state.thinkTimeDialogOpenFor) {
      this.setState({thinkTimeDialogOpenFor: 'login'});
      return;
    }

    storage.setExpires('afterLoginUrl', window.location.pathname, 900);
    history.push('/login');
  }

  onClickMyAccount = () => {
    if (this.props.thinkTimeStarted && !this.state.thinkTimeDialogOpenFor) {
      this.setState({thinkTimeDialogOpenFor: 'my_account'});
      return;
    }

    history.push(currentUserHasRole('ROLE_CANDIDATE') ? '/candidate/user-profile' : '/company/user-profile');
  }

  renderButtons = () => {
    const {showHelpDialog} = this.state;
    const {loggedIn, screenSize, step, includeLoginButton} = this.props;
    const isLoggedIn = isAuthenticated() || loggedIn;

    const smallScreen = !screenSize.md;
    const isWholeScreenStep = [STEP_TEST, STEP_QUESTIONS].includes(step);
    const showHelp = smallScreen && isWholeScreenStep;

    return (
      <>
      {showHelp && (
        <>
          <IconButton className={'on-demand-interview-page-help-button'} color={"primary"} onClick={() => this.setState({showHelpDialog: true})}>
            <HelpIcon /> <span className={'on-demand-interview-page-help-button-text'}>Help</span>
          </IconButton>
          {showHelpDialog && (<HelpDialog onClose={() => this.setState({showHelpDialog: false})}/>)}
        </>
      )}
        {isLoggedIn ? (
            <button onClick={this.onClickMyAccount} className="btn btn-primary">
              My Account
            </button>
        ) :
          <>
            {includeLoginButton && (
              <button className="btn btn-primary" onClick={this.onClickLogin}>Log In</button>
            )}
          </>
        }
      </>
    );
  }

  renderThinkTimeDialog = () => {
    if (!this.state.thinkTimeDialogOpenFor) {
      return;
    }

    return (
      <Dialog
        open={true}
        onClose={() => this.setState({thinkTimeDialogOpenFor: null})}
        title={null}
        closeButtonText={"Cancel"}
        confirmButtonText={"Leave"}
        onConfirm={this.state.thinkTimeDialogOpenFor === 'login' ? this.onClickLogin : this.onClickMyAccount}
      >
        Do you want to leave the site? Changes you made may not be saved.
      </Dialog>
    );
  }

  render() {
    const { request } = this.props;
    const company = request.job.company;
    return (
      <div className={classNames('on-demand-interview-page-header', 'interview-page-header')}>
        <GridContainer className='interview-header-grid'>
          <GridItem xs={8} className='interview-header-company-container'>
            <HeaderCompanyLogo company={company} />
          </GridItem>
          <GridItem xs={4} className='interview-header-buttons-container'>
            {this.renderButtons()}
          </GridItem>
          {this.renderThinkTimeDialog()}
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  request: state.onDemandInterviewPage.request,
  loggedIn: state.onDemandInterviewPage.auth.loggedIn,
  thinkTimeStarted: state.onDemandInterviewPage.thinkTimeStarted,
  screenSize: state.screen.size,
  step: state.onDemandInterviewPage.step,
})

OnDemandInterviewHeader = connect(
  mapStateToProps
)(OnDemandInterviewHeader);

export default OnDemandInterviewHeader;
