import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import React, {useState} from "react";
import FormHelperText from "@material-ui/core/es/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import Moment from 'react-moment';
import MenuItem from "@material-ui/core/MenuItem";
import Typography from '@material-ui/core/Typography';
import {isObject} from "./object";
import Autocomplete from "../views/Components/Autocomplete";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputAdornment from "@material-ui/core/InputAdornment";

const redAsterisks = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "red",
        "&$error": {
          color: "red"
        }
      }
    }
  }
});

export function renderFromHelper(touched, error) {
  if (!(touched && error)) {
    return;
  }

  let errorMessage = error;
  if (isObject(error)) {
    errorMessage = (error._error) ? error._error : "Unexpected server error";
  }

  return (
    <FormHelperText
        className='form-input-error'
        error={!!(touched && errorMessage)}
    >
        {touched && errorMessage}
    </FormHelperText>
  );
}

export function renderTextField({label, input, containerClass, required, htmlRequired, charsCounterMaxValue, meta: {touched, invalid, error}, ...custom}) {
  if (label === undefined) {
    label = input.name.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
      return str.toUpperCase();
    });
  }
  containerClass = containerClass || 'form-group';

  if (charsCounterMaxValue) {
    if (!custom.InputProps) {
      custom.InputProps = {};
    }

    custom.InputProps.endAdornment = <InputAdornment position="end">{input.value.length}/{charsCounterMaxValue}</InputAdornment>;
  }

  let id = 'text_' + input.name;
  return (<div className={containerClass}>
    {label && (<>
      <MuiThemeProvider theme={redAsterisks}>
        <InputLabel htmlFor={id} required={required}>{label}</InputLabel>
        <br/>
      </MuiThemeProvider>
    </>)}
    <TextField
      fullWidth
      id={id}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      required={htmlRequired}
      {...input}
      {...custom}
    /></div>);
}

export function renderAutocomplete({label, input, containerClass, required, meta: {touched, invalid, error}, ...custom}) {
  if (label === undefined) {
    label = input.name.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
      return str.toUpperCase();
    });
  }
  containerClass = containerClass || 'form-group';

  let id = 'text_' + input.name;
  return (
    <div className={containerClass}>
      {label && (<>
      <MuiThemeProvider theme={redAsterisks}>
        <InputLabel htmlFor={id} required={required}>{label}</InputLabel><br/>
      </MuiThemeProvider>
      </>)}
      <Autocomplete
        fullWidth
        id={id}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
      />
    </div>);
}

export function renderTextareaField({label, input, containerClass, meta: {touched, invalid, error}, ...custom}) {
  custom.multiline = true;
  custom.variant = 'outlined';
  custom.rowsMax = 'rowsMax' in custom ? custom.rowsMax : 15;
  custom.rows = 'rows' in custom ? custom.rows : 5;
  custom.type = 'text';

  return renderTextField({label, input, containerClass, meta: {touched, invalid, error}, ...custom})
}

export function renderSelect({
                               input,
                               label,
                               containerClass,
                               required,
                               meta: {touched, error},
                               children,
                               ...custom
                             }) {
  let id = 'select' + input.name;
  containerClass = containerClass || 'form-group';

  let emptyOptionAsPlaceholder = '';
  if (('placeholder' in custom) && input.value === '') {
    /* do not add "placeholder" if at least one option already has empty value */
    let hasEmptyOption = false;
    for (let i = 0; i < children.length; i++) {
      let child = children[i];
      if (typeof child === 'object' && ('props' in child) && child.props.value === '') {
        hasEmptyOption = true;
        break;
      }
    }

    if (!hasEmptyOption) {
      custom.displayEmpty = true;
      emptyOptionAsPlaceholder =
        <MenuItem key="none" value="" disabled={true}>
          <Typography color='textSecondary'>{custom.placeholder}</Typography>
        </MenuItem>;
    }
  }

  return (
    <div className={containerClass}>
      <MuiThemeProvider theme={redAsterisks}>
        <InputLabel htmlFor={id} required={required}>{label}</InputLabel>
      </MuiThemeProvider>
      <FormControl error={!!(touched && error)} fullWidth>
        <Select
          fullWidth
          {...input}
          {...custom}
          inputProps={{
            id: id,
            name: input.name,
          }}
        >
          {emptyOptionAsPlaceholder}
          {children}
        </Select>
        {renderFromHelper(touched, error)}
      </FormControl>
    </div>
  );
}

export function renderCheckbox({input, label, classes, disabled, containerClass}) {
  classes = classes || {};
  return (
    <div className={containerClass}>
      <FormControlLabel
        control={
          <Checkbox
            checked={!!input.value}
            onChange={input.onChange}
            classes={classes.checkbox || undefined}
            disabled={disabled || false}
          />
        }
        label={label}
        classes={classes.label || undefined}
        disabled={disabled || false}
      />
    </div>
  );
}

export function renderDatePicker({
                                   input,
                                   label,
                                   required,
                                   containerClass,
                                   meta: {touched, error},
                                   children,
                                   ...custom
                                 }) {
  let id = 'datePicker' + input.name;
  containerClass = containerClass || 'form-group';

  let value = input.value;
  if (null !== value && '' !== value) {
    value = moment(value).format(Moment.globalFormat);
  }
  delete input.value;

  return (
    <div className={containerClass}>
      {label && (
        <>
          <InputLabel htmlFor={id} required={required}>{label}</InputLabel>
          <br/>
        </>
      )}
      <FormControl error={!!(touched && error)} fullWidth label={label}>
        <Datetime
          style={{marginTop: '16px'}}
          id={id}
          {...custom}
          {...input}
          label={label}
          timeFormat={false}
          inputProps={{placeholder: label, value: value, pattern: '^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) ([1-9]|[12]\\d|3[01]) (1|2)\\d{3}$'}}
          utc={true}
          closeOnSelect={true}
        />
        {children}
      </FormControl>
    </div>
  );
}
export function renderDateTimePicker({
                                       input,
                                       label,
                                       containerClass,
                                       required,
                                       meta: {touched, error},
                                       children,
                                       timeFormat = true,
                                       timezone = null,
                                       ...custom
                                     }) {
  let id = 'datePicker' + input.name;
  containerClass = containerClass || 'form-group';
  const value = moment(input.value);
  return (
    <div className={containerClass}>
      <MuiThemeProvider theme={redAsterisks}>
        <InputLabel htmlFor={id} required={required}>{label}</InputLabel>
        <br/>
      </MuiThemeProvider>
      <FormControl error={!!(touched && error)} fullWidth label={label}>
        <Datetime
          style={{marginTop: '16px'}}
          id={id}
          {...custom}
          {...input}
          value={value}
          label={label}
          displayTimeZone={timezone}
          timeFormat={timeFormat}
          dateFormat={Moment.dateFormat}
          utc={false}
          closeOnSelect={true}
          onBlur={(value) => {}} //passing on blur event to redux input resets input value
        />
        {children}
      </FormControl>
    </div>
  );
}

export function RenderScheduledDateTimePicker({
  input,
  label,
  containerClass,
  required,
  meta: {touched, error},
  children,
  timeFormat = true,
  timezone = null,
  ...custom
}) {
  let id = 'datePicker' + input.name;
  let closeScheduledPicker;
  let changeStartDateTimeScheduleValue;
  containerClass = containerClass || 'form-group';
  const initialStartHour = custom.startHour || '12';
  const initialStartMinutes = custom.startMinutes || '00';
  const initialEndHour = custom.endHour || initialStartHour;
  const initialEndMinutes = custom.endMinutes || '30';
  const regexNum = /^[0-9]+$/;
  const value = moment.utc(input.value);
  value.tz(timezone);

  const formatTime = (time, format) => {
    time = time.toString();
    if (time.match(regexNum)) {
      if (time.length > 2) {
        if (+time.slice(0, 1) === 0) {
          time = time.slice(1, 3);
        } else {
          if (format === 'm') {
            if (+time.slice(0, 1) < 6) {
              time = `${time.slice(0, 1)}${time.slice(2)}`;
            } else {
              time = time.slice(0, 2);
            }
          }
          if (format === 'h') {
            if (+time.slice(0, 2) < 12) {
              time = `${time.slice(0, 1)}${time.slice(2)}`;
            } else {
              time = time.slice(0, 2);
            }
          }
        }
      }
      if (time.length < 2) {
        time = `0${time}`;
      }
      return time;
    }
    return false;
  };
  const getAmPmFromScheduledTime = (time) => {
    return (time.format('h:mm A')).split(' ')[1];
  };

  const [hideTimePicker, changeHideTimePicker] = useState(false);
  const [startHour, changeStartHour] = useState(value.isValid() ? formatTime((value.format('h:mm A')).split(':')[0], 'h') : initialStartHour);
  const [startMinutes, changeStartMinutes] = useState(value.isValid() ? formatTime(value.minutes(), 'm') : initialStartMinutes);
  const [startAmPm, changeStartAmPm] = useState(value.isValid() ? getAmPmFromScheduledTime(value) : 'PM');
  const [endHour, changeEndHour] = useState(moment(custom.scheduledEndTime).isValid() ? formatTime(((custom.scheduledEndTime).format('h:mm A')).split(':')[0], 'h') : initialEndHour);
  const [endMinutes, changeEndMinutes] = useState(moment(custom.scheduledEndTime).isValid() ? formatTime((custom.scheduledEndTime).minutes(), 'm') : initialEndMinutes);
  const [endAmPm, changeEndAmPm] = useState(moment(custom.scheduledEndTime).isValid() ? getAmPmFromScheduledTime(custom.scheduledEndTime) : 'PM');

  const getScheduledStartTime = () => {
    return (Number.isInteger(+startHour) && Number.isInteger(+startMinutes) && startAmPm.match("(am|pm|AM|PM)")) ?
      moment(`${startHour}:${startMinutes} ${startAmPm}`, 'h:mm A') : moment('');
  };
  const getScheduledEndTime = () => {
    return (Number.isInteger(+endHour) && Number.isInteger(+endMinutes) && endAmPm.match("(am|pm|AM|PM)")) ?
      moment(`${endHour}:${endMinutes} ${endAmPm}`, 'h:mm A') : moment('');
  };
  const getScheduledStartDateTime = (scheduledStartTime) => {
    return value.set({
      hour: scheduledStartTime.get('hour'),
      minute: scheduledStartTime.get('minute')
    });
  };
  const getHumanScheduled = (scheduledStartDateTime, scheduledEndTime) => {
    return `${scheduledStartDateTime.format('MMMM D, YYYY, h:mm A')} - ${scheduledEndTime.format('h:mm A')}`;
  };

  const [scheduledDateTime, scheduleDateTime] = useState((value.isValid() && moment(custom.scheduledEndTime).isValid()) ? getHumanScheduled(value, getScheduledEndTime()) : 'Scheduled Date and Time');

  const onChangeStartHour = (e) => {
    const hour = formatTime(e.target.value.slice(0, 3), 'h');
    if (hour && +hour < 13) {
      changeStartHour(hour);
    }
  };
  const onChangeStartMinutes = (e) => {
    let minutes = formatTime(e.target.value.slice(0, 3), 'm');
    if (minutes && +minutes < 60) {
      changeStartMinutes(minutes);
    }
  };
  const onChangeStartTimeAmPm = (e) => {
    const noon = (e.target.value).toUpperCase().slice(0, 2);
    changeStartAmPm(noon);
  };
  const onChangeEndHour = (e) => {
    const hour = formatTime(e.target.value.slice(0, 3), 'h');
    if (hour && +hour < 13) {
      changeEndHour(hour);
    }
  };
  const onChangeEndMinutes = (e) => {
    let minutes = formatTime(e.target.value.slice(0, 3), 'm');
    if (minutes && +minutes < 60) {
      changeEndMinutes(minutes);
    }
  };
  const onChangeEndTimeAmPm = (e) => {
    const noon = (e.target.value).toUpperCase().slice(0, 2);
    changeEndAmPm(noon);
  };

  const calculateExpectedDuration = (startTime, endTime) => {
    const duration = moment.duration(endTime.diff(startTime));
    const durationHours = Math.floor(duration.asHours());
    const durationMinutes = duration.minutes();
    custom.onChangeDuration(durationHours, durationMinutes);
  };

  const closeScheduler = (e) => {
    e.preventDefault();
    const scheduledStartTime = getScheduledStartTime();
    const scheduledEndTime = getScheduledEndTime();
    if (scheduledStartTime.isValid() && scheduledEndTime.isValid() && value.isValid()) {
      const scheduledStartDateTime = getScheduledStartDateTime(scheduledStartTime);
      const humanScheduled = getHumanScheduled(scheduledStartDateTime, scheduledEndTime);
      scheduleDateTime(humanScheduled);
      calculateExpectedDuration(scheduledStartTime, scheduledEndTime);
      changeStartDateTimeScheduleValue({target: {value: scheduledStartDateTime}});
    }
    closeScheduledPicker();
  };

  const increaseTimeByToggle = (time) => {
    time = +time + 1;
    return time < 10 ? `0${time}` : time;
  };
  const decreaseTimeByToggle = (time) => {
    time = +time - 1;
    return time < 10 ? `0${time}` : time;
  };

  const renderInput = (props, openCalendar, closeCalendar) => {
    props.type = 'hidden';
    closeScheduledPicker = closeCalendar;
    changeStartDateTimeScheduleValue = props.onChange;
    return (
      <>
        <div
          className="humanized-schedule" onClick={openCalendar}>
            {scheduledDateTime}
        </div>
        <input {...props}/>
      </>
    );
  };

  const renderView = (mode, renderDefault) => {
    if (mode === 'months' || mode === 'years') {
      changeHideTimePicker(true);
    } else {
      changeHideTimePicker(false);
    }
    return (
      <div className="wrapper-scheduler">
        <div className="date-time-scheduler">
          {renderDefault()}
          {!hideTimePicker && (
            <div className="scheduled-time">
              <div className="schedule-time">
                <label>Start Time:</label>
                <div className="tooglers tooglers-up">
                  <span onClick={e =>
                    changeStartHour((Number.isInteger(+startHour) && +startHour < 12) ? increaseTimeByToggle(startHour) : '00'
                  )}></span>
                  <span onClick={e =>
                    changeStartMinutes((Number.isInteger(+startMinutes) && +startMinutes < 59) ? increaseTimeByToggle(startMinutes) : '00'
                  )}></span>
                  <span onClick={e => changeStartAmPm(startAmPm === 'AM' ? 'PM' : 'AM')}></span>
                </div>
                <input
                  type="text"
                  value={startHour}
                  onChange={onChangeStartHour} />
                <input
                  type="text"
                  value={startMinutes}
                  onChange={onChangeStartMinutes} />
                <input
                  type="text"
                  value={startAmPm}
                  onChange={onChangeStartTimeAmPm} />
                <div className="tooglers tooglers-down">
                  <span onClick={e =>
                    changeStartHour((Number.isInteger(+startHour) && +startHour > 0) ? decreaseTimeByToggle(startHour) : '00'
                  )}></span>
                  <span onClick={e =>
                    changeStartMinutes((Number.isInteger(+startMinutes) && +startMinutes > 0) ? decreaseTimeByToggle(startMinutes) : '00'
                  )}></span>
                  <span onClick={e => changeStartAmPm(startAmPm === 'AM' ? 'PM' : 'AM')}></span>
                </div>
              </div>
              <div className="schedule-time">
                <label>End Time:</label>
                <div className="tooglers tooglers-up">
                  <span onClick={e => changeEndHour((Number.isInteger(+endHour) && +endHour < 12) ? increaseTimeByToggle(endHour) : '00')}></span>
                  <span onClick={e => changeEndMinutes((Number.isInteger(+endMinutes) && +endMinutes < 59) ? increaseTimeByToggle(endMinutes) : '00')}></span>
                  <span onClick={e => changeEndAmPm(endAmPm === 'AM' ? 'PM' : 'AM')}></span>
                </div>
                <input
                  type="text"
                  value={endHour}
                  onChange={onChangeEndHour} />
                <input
                  type="text"
                  value={endMinutes}
                  onChange={onChangeEndMinutes} />
                <input
                  type="text"
                  value={endAmPm}
                  onChange={onChangeEndTimeAmPm} />
                <div className="tooglers tooglers-down">
                  <span onClick={e => changeEndHour((Number.isInteger(+endHour) && +endHour > 0) ? decreaseTimeByToggle(endHour) : '00')}></span>
                  <span onClick={e =>
                    changeEndMinutes((Number.isInteger(+endMinutes) && +endMinutes > 0) ? decreaseTimeByToggle(endMinutes) : '00'
                  )}></span>
                  <span onClick={e => changeEndAmPm(endAmPm === 'AM' ? 'PM' : 'AM')}></span>
                </div>
              </div>
            </div>
          )}
        </div>
        {!hideTimePicker && (
          <div className="scheduler-controls-wrapper">
            <div
              className="btn btn-sm"
              onClick={closeScheduler}>
                Done
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={containerClass}>
      <MuiThemeProvider theme={redAsterisks}>
        <InputLabel htmlFor={id} required={required}>{label}</InputLabel>
        <br/>
      </MuiThemeProvider>
        <div className="scheduled-date-time-picker">
          <FormControl
            error={!!(touched && error)}
            fullWidth>
              <Datetime
                style={{marginTop: '16px'}}
                className={!hideTimePicker ? 'scheduler' : undefined}
                id={id}
                {...custom}
                {...input}
                value={value}
                label={label}
                timeFormat={false}
                dateFormat="MMMM D, YYYY, h:mm A"
                utc={false}
                closeOnSelect={false}
                onBlur={(value) => {}} //passing on blur event to redux input resets input value
                closeOnClickOutside={true}
                renderInput={renderInput}
                renderView={(mode, renderDefault) => renderView(mode, renderDefault)}
              />
              {renderFromHelper(touched, error)}
          </FormControl>
        </div>
    </div>
  );
}

export class renderInlineRadioButtons extends React.Component
{
  render() {
    let {input, choices, label, containerClass, required, custom} = this.props;

    let id = 'radio_' + input.name;
    if (label === undefined) {
      label = input.name.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase();
      });
    }
    containerClass = containerClass || 'form-group';

    if (true === input.value) {
      input.value = "true";
    }
    if (false === input.value) {
      input.value = "false";
    }

    return (
      <div className={containerClass}>
        <MuiThemeProvider theme={redAsterisks}>
          <InputLabel className="float-left" htmlFor={id} required={required}>{label}</InputLabel>
        </MuiThemeProvider>
        <RadioGroup {...input} className={"radio-group-class"}>
          {choices.map((choice, key) => (
            <FormControlLabel key={key} value={choice.value} control={<Radio className={"float-left"} />} label={choice.label} />
          ))}
        </RadioGroup>
        <div className={"clearfix"} />
      </div>
    );
  }
}
