import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { retrieve, reset } from '../../actions/Request/show';
import { del } from '../../actions/Request/delete';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {renderLinks} from "../../utils/entityList";
import Moment from 'react-moment';
import RequestPreview from "./RequestPreview";
import {getRequestUrlByRoomCode} from "../../utils/urls";
import {CopyToClipboard} from "../CopyToClipboard/CopyToClipboard";
import IconButton from "@material-ui/core/IconButton";
import CopyContent from "../Icons/CopyContent";
import IsGranted from "../IsGranted";
import VideoQuestionPlayer from "../VideoQuestionPlayer/VideoQuestionPlayer";
import YoutubeVideo from "../../views/Components/YoutubeVideo";
import EntityVideoPlayer from "../../views/Components/EntityVideoPlayer";
import withStyles from "@material-ui/core/styles/withStyles";
import EntityVideoPlayerWithControl from "../../views/Components/EntityVideoPlayerWithControl";

const style = {
  videoContainer: {
    maxWidth: '570px',
  },
  progressIndicator: {
    height: '320px',
  },
};

class RequestShow extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    deleteError: PropTypes.string,
    deleteLoading: PropTypes.bool.isRequired,
    deleted: PropTypes.object,
    del: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  del = () => {
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.del(this.props.retrieved);
  };

  render() {
    if (this.props.deleted)
      this.props.history.push({
        pathname: `..`,
        state: {successMessage: 'On-Demand Interview successfully deleted.'}
      });

    const item = this.props.retrieved;
    let requestUrl = '';
    if (item) {
      requestUrl = getRequestUrlByRoomCode(item['roomCode']);
    }

    const {classes} = this.props;

    return (
      <div>
        <h1>Show &quot;{item && item['title']}&quot;</h1>

        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}
        {this.props.deleteError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.deleteError}
          </div>
        )}

        <div className="grid-buttons">
          <Link to=".." className="btn btn-primary">
            Back to list
          </Link>
          {item && (
            <IsGranted action='edit' object={item}>
            <Link to={`../edit/${encodeURIComponent(item['@id'])}`} className="btn btn-warning">Edit</Link>
            </IsGranted>
          )}
          {item && (
            <IsGranted action='edit' object={item}>
            <button onClick={this.del} className="btn btn-danger">
              Delete
            </button>
            </IsGranted>
          )}
          {item && (
            <Link to={`/company/invitations/send/${encodeURIComponent(item['@id'])}`} className="btn btn-info">
              Invite
            </Link>
          )}
        </div>

        {item && (
          <>
            <div style={{ 'overflowX': 'auto' }}>
              <Table className="table table-responsive table-striped table-hover">
                <TableHead>
                  <TableRow>
                    <TableCell>Field</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell scope="row">Title</TableCell>
                    <TableCell>{item['title']}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Expires At</TableCell>
                    <TableCell>{item['expiresAt'] && (<Moment>{item['expiresAt']}</Moment>)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Job</TableCell>
                    <TableCell>{renderLinks('../jobs', item['job'], (job) => job.title)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">User</TableCell>
                    <TableCell>{renderLinks('../users', item['companyUser'], (companyUser) => companyUser.firstName + ' ' + companyUser.lastName)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Link</TableCell>
                    <TableCell scope="row">
                      {requestUrl}
                      <CopyToClipboard text={requestUrl}>
                        <IconButton style={{ 'height': '1em' }}>
                          <CopyContent style={{ 'height': '0.6em' }}>
                            Copy To Clipboard
                        </CopyContent>
                        </IconButton>
                      </CopyToClipboard>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Resume Required</TableCell>
                    <TableCell>{item['resumeRequired'] ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                  {(item['requestIntroVideo'] || item['requestIntroVideoUrl']) && (
                    <TableRow>
                      <TableCell scope="row">Intro Video</TableCell>
                      <TableCell>
                        {item['requestIntroVideo'] ? (
                          <div className={classes.videoContainer}>
                            <EntityVideoPlayerWithControl
                              entity={item['requestIntroVideo']}
                              progressIndicatorClassName={classes.progressIndicator}
                            />
                          </div>
                        ) : (
                          <YoutubeVideo url={item['requestIntroVideoUrl']} />
                        )}
                      </TableCell>
                    </TableRow>
                  )}

                  {(item['requestOutroVideo'] || item['requestOutroVideoUrl']) && (
                    <TableRow>
                      <TableCell scope="row">Outro Video</TableCell>
                      <TableCell>
                        {item['requestOutroVideo'] ? (
                          <div className={classes.videoContainer}>
                            <EntityVideoPlayerWithControl
                              entity={item['requestOutroVideo']}
                              progressIndicatorClassName={classes.progressIndicator}
                            />
                          </div>
                        ) : (
                          <YoutubeVideo url={item['requestOutroVideoUrl']} />
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell scope="row">Created At</TableCell>
                    <TableCell>{item['createdAt'] && (<Moment>{item['createdAt']}</Moment>)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Updated At</TableCell>
                    <TableCell>{item['updatedAt'] && (<Moment>{item['updatedAt']}</Moment>)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <RequestPreview
              knockoutQuestions={item.knockoutQuestions}
              videoQuestions={item.questions}
              job={item.job}
            />
          </>
        )}

      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.request.show.retrieved,
  error: state.request.show.error,
  loading: state.request.show.loading,
  eventSource: state.request.show.eventSource,
  deleteError: state.request.del.error,
  deleteLoading: state.request.del.loading,
  deleted: state.request.del.deleted
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  del: item => dispatch(del(item)),
  reset: eventSource => dispatch(reset(eventSource))
});

RequestShow = connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestShow);

RequestShow = withStyles(style)(RequestShow);

export default RequestShow;
