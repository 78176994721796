import {fetchJSON} from "../../utils/dataAccess";

export function loadKnockoutQuestionBankCategoryForSelect() {
  return dispatch => {
    dispatch(loading(true));
    fetchJSON('/knockout_question_bank_categories?pagination=false').then((categories) => {
      dispatch(categoriesLoaded(categories['hydra:member'].map(
        function (category) {
          return {
            value: category['id'],
            text: category.name,
          }
        }
      )));
      dispatch(loading(false));
    }).catch(e => {
      dispatch(loading(false));
      dispatch(error(e.message));
    })
  };
}

export function loading(loading) {
  return {type: 'KNOCKOUTQUESTIONBANKCATEGORIES_FOR_SELECT_LOADING', loading};
}

export function error(error) {
  return {type: 'KNOCKOUTQUESTIONBANKCATEGORIES_FOR_SELECT_ERROR', error};
}

export function categoriesLoaded(retrieved) {
  return {type: 'KNOCKOUTQUESTIONBANKCATEGORIES_FOR_SELECT_RETRIEVED', retrieved};
}
