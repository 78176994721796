import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'PAYMENT_METHOD_SET_AS_DEFAULT_ERROR', error };
}

export function loading(loading) {
  return { type: 'PAYMENT_METHOD_SET_AS_DEFAULT_LOADING', loading };
}

export function success(result) {
  return { type: 'PAYMENT_METHOD_SET_AS_DEFAULT_SUCCESS', result };
}

export function setAsDefault(item, callback) {
  return dispatch => {
    dispatch(loading(true));

    return fetch(item['@id'], { method: 'PUT' })
      .then(() => {
        dispatch(loading(false));
        dispatch(success(item));
        if (typeof callback === 'function') {
          callback();
        }
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}
