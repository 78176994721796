import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'TEAM_INACTIVATE_ERROR', error };
}

export function loading(loading) {
  return { type: 'TEAM_INACTIVATE_LOADING', loading };
}

export function success(inactivated) {
  return { type: 'TEAM_INACTIVATE_SUCCESS', inactivated };
}

export function inactivate(item) {
  return dispatch => {
    dispatch(loading(true));
    dispatch(error(null));

    return fetch(item['@id'] + '/inactivate', { method: 'PUT' })
      .then(() => {
        dispatch(loading(false));
        dispatch(success(item));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return { type: 'TEAM_INACTIVATE_RESET' };
}
