import React from "react";
import PropTypes from "prop-types";
import MuteIcon from '@material-ui/icons/Mic';
import UnMuteIcon from '@material-ui/icons/MicOff';
import {IconButton} from "@material-ui/core";
import {
  isStreamManagerMuted,
  streamManagerHasAudio, toggleAudioForStreamManager,
} from "../../utils/streamManager";

class MuteControl extends React.Component {
  static propTypes = {
    streamManager: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
  };

  componentDidMount() {
    const {streamManager} = this.props;
    streamManager.ee.on('streamPropertyChanged', this.listenStreamPropertyChange);
  }

  componentWillUnmount() {
    const {streamManager} = this.props;
    streamManager.ee.off('streamPropertyChanged', this.listenStreamPropertyChange);
  }

  listenStreamPropertyChange = (event) => {
    if (event.changedProperty === 'audioActive') {
      this.forceUpdate();
    }
  };

  toggleMute = () => {
    const {streamManager} = this.props;
    toggleAudioForStreamManager(streamManager);
    this.forceUpdate();
  };

  render() {
    const {streamManager, buttonClass, disabled} = this.props;
    const muted = isStreamManagerMuted(streamManager);
    const buttonDisabled = disabled || !streamManagerHasAudio(streamManager);
    return (
      <IconButton onClick={this.toggleMute} disabled={buttonDisabled} className={buttonClass}>
        {muted || buttonDisabled ? <UnMuteIcon /> : <MuteIcon /> }
        <span className='video-control-label'>{muted || buttonDisabled ? 'Unmute' : 'Mute'}</span>
      </IconButton>
    )
  }
}

export default MuteControl;
