import { SubmissionError } from 'redux-form';
import {
  fetch,
  extractHubURL,
  normalize, normalizeExcept, normalizeAttributes
} from '../../utils/dataAccess';
import { success as createSuccess } from './create';
import { loading, error } from './delete';
import {success as deleteSuccess} from "./delete";
import { cloneDeep } from "lodash";

export function retrieveError(retrieveError) {
  return { type: 'CANDIDATE_UPDATE_RETRIEVE_ERROR', retrieveError };
}

export function retrieveLoading(retrieveLoading) {
  return { type: 'CANDIDATE_UPDATE_RETRIEVE_LOADING', retrieveLoading };
}

export function retrieveSuccess(retrieved) {
  return { type: 'CANDIDATE_UPDATE_RETRIEVE_SUCCESS', retrieved };
}

export function retrieve(id) {
  return dispatch => {
    dispatch(retrieveLoading(true));

    return fetch(id)
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        retrieved = normalizeExcept(retrieved, ['professionMappings', 'resume']);
        retrieved.professionMappings.forEach((item) => {
          normalizeAttributes(item, ['industry', 'occupation', 'specialty']);
        });

        dispatch(retrieveLoading(false));
        dispatch(retrieveSuccess(retrieved));
      })
      .catch(e => {
        dispatch(retrieveLoading(false));
        dispatch(retrieveError(e.message));
      });
  };
}

export function updateError(updateError) {
  return { type: 'CANDIDATE_UPDATE_UPDATE_ERROR', updateError };
}

export function updateLoading(updateLoading) {
  return { type: 'CANDIDATE_UPDATE_UPDATE_LOADING', updateLoading };
}

export function updateSuccess(updated) {
  return { type: 'CANDIDATE_UPDATE_UPDATE_SUCCESS', updated };
}

export function update(item, values) {
  return dispatch => {
    dispatch(updateError(null));
    dispatch(createSuccess(null));
    dispatch(updateLoading(true));

    if (values.occupation === '') {
      values.occupation = null;
    }
    if (values.industry === '') {
      values.industry = null;
    }
    const data = normalizeAttributes(cloneDeep(values), ['resume']);
    return fetch(item['@id'], {
      method: 'PUT',
      data: data
    })
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        retrieved = normalize(retrieved);

        dispatch(updateLoading(false));
        dispatch(updateSuccess(retrieved));
      })
      .catch(e => {
        dispatch(updateLoading(false));

        if (e instanceof SubmissionError) {
          dispatch(updateError(e.errors._error));
          throw e;
        }

        dispatch(updateError(e.message));
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'CANDIDATE_UPDATE_RESET' });
    dispatch(error(null));
    dispatch(loading(false));
    dispatch(createSuccess(null));
    dispatch(deleteSuccess(null));
  };
}
