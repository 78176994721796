import React from "react";

export default class QuestionNumber extends React.Component {
    render() {
        return (
            <span className='question-number' style={{fontWeight: 'bold', marginRight: '5px'}}>
              {this.props.children}
            </span>
        );
    }
}
