import moment from 'moment';
import {timezones} from "react-timezone";

let offsetAdjusted = false;

export function getTimezones() {
  if (!offsetAdjusted) {
    const dt = moment(Date.now());
    timezones.forEach((timezone) => {
      const offsetParts = dt.tz(timezone.name).format('Z').split(':');
      const offsetHours = parseInt(offsetParts[0]);
      const offsetMinutes = parseInt(offsetParts[1]) / 60;
      const offset = (offsetHours > 0) ? (offsetHours + offsetMinutes) : (offsetHours - offsetMinutes);
      timezone.offset = offset;
    });
    offsetAdjusted = true;
  }
  return timezones;
}

export function formatGMTOffset(offset) {
  let offsetAbsHours = Math.floor(Math.abs(offset));
  let offsetAbsMinutes = Math.round(Math.abs(offset % 1) * 60);


  if (offsetAbsHours < 10) {
    offsetAbsHours = '0' + offsetAbsHours;
  }

  if (offsetAbsMinutes < 10) {
    offsetAbsMinutes = '0' + offsetAbsMinutes;
  }

  let sign = offset >= 0 ? '+' : '-';

  return `${sign}${offsetAbsHours}:${offsetAbsMinutes}`;
}

export function getTimezoneDataByName(name) {
  return getTimezones().find(timezone => timezone.name === name);
}

export function formatTimezoneLabelWithOffset(label, offset) {
  if (typeof offset === "number") {
    offset = formatGMTOffset(offset);
  }
  return '(GMT' + offset + ') ' + label;
}

export function formatTimezoneWithOffset(offset) {
  if (typeof offset === "number") {
    offset = formatGMTOffset(offset);
  }
  return '(GMT' + offset + ') ';
}
