import { SubmissionError } from 'redux-form';
import {
  fetch,
  extractHubURL,
  normalize
} from '../../utils/dataAccess';

export function updateError(updateError) {
  return { type: 'ONEWAYINTERVIEW_SHARE_LINK_UPDATE_ERROR', updateError };
}

export function updateLoading(updateLoading) {
  return { type: 'ONEWAYINTERVIEW_SHARE_LINK_UPDATE_LOADING', updateLoading };
}

export function updateSuccess(updated) {
  return { type: 'ONEWAYINTERVIEW_SHARE_LINK_UPDATE_SUCCESS', updated };
}

export function update(item, values, callback) {
  return dispatch => {
    dispatch(updateError(null));
    dispatch(updateLoading(true));

    return fetch(item['@id'], {
      method: 'PUT',
      data: values
    })
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        retrieved = normalize(retrieved);

        dispatch(updateLoading(false));
        dispatch(updateSuccess(retrieved));
        if (typeof callback === 'function') {
          callback();
        }
      })
      .catch(e => {
        dispatch(updateLoading(false));
        if (e instanceof SubmissionError) {
          dispatch(updateError(e.errors._error));
          throw e;
        }

        dispatch(updateError(e.message));
        if (typeof callback === 'function') {
          callback();
        }
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(updateError(null));
    dispatch(updateLoading(false));
    dispatch(updateSuccess(null));
  };
}
