import React, { Component } from 'react';
import moment from "moment";
import {firstLetterToUppercase} from "../../utils/firstLetterToUpperCase";
import {applyToUrl} from "../../utils/urls";
import {list as listTransactions, reset as resetTransactions} from "../../actions/Transaction/list";
import {connect} from "react-redux";
import TransactionViewModal from "../Transactions/TransactionViewModal";
import {PaymentMethodLogo} from "../../views/Subscription/PaymentMethodCard";
import {formatPrice} from "../../utils/plan";

export function getTransactionsForCompanyUrl(decodedCompanyIRI)  {
  return applyToUrl('transactions', {'company___id': decodeURIComponent(decodedCompanyIRI)});
}

class SubscriptionTransactions extends React.Component
{
  state = {
    showTransaction: null,
  };

  componentDidMount() {
    this.props.listTransactions(getTransactionsForCompanyUrl(this.props.companyId));
  }

  componentWillUnmount() {
    this.props.resetTransactions(this.props.eventSource);
  }

  setShowTransaction = (transaction) => {
    this.setState({showTransaction: transaction});
  }

  render() {
    const transactions = this.props.transactions ? this.props.transactions['hydra:member'] : [];

    return (
      <div className={"subscription-transactions"}>
        <table>
          <thead>
            <tr>
              <th>DATE</th>
              <th>DETAILS</th>
              <th>AMOUNT</th>
              <th>STATUS</th>
              <th>PAYMENT METHOD</th>
              <th className={"text-center"}>RECEIPT</th>
            </tr>
          </thead>
          <tbody>
          {transactions.map(transaction => (
            <tr key={transaction['@id']}>
              <td className={"date"}>{moment(transaction['createdAt']).format("MM/DD/YYYY")}</td>
              <td>{transaction['description']}</td>
              <td>{formatPrice(transaction['amount'])}</td>
              <td>
                <div className={"transaction-status " + transaction['status']}>
                  {firstLetterToUppercase(transaction['status'])}
                </div>
              </td>
              <td>
                {transaction['brand'] && transaction['lastFour'] && (
                  <>
                    <PaymentMethodLogo brand={transaction['brand']} /> ending in {transaction['lastFour']}
                  </>
                )}
              </td>
              <td className={"text-center"}>
                <button className={"view-receipt"} onClick={() => this.setShowTransaction(transaction)}>View</button>
              </td>
            </tr>
          ))}
          </tbody>
        </table>

        {this.state.showTransaction && (
          <TransactionViewModal
            transaction={this.state.showTransaction}
            handleClose={() => this.setShowTransaction(null)}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.transaction.list.loading,
  error: state.transaction.list.error,
  transactions: state.transaction.list.retrieved,
});

const mapDispatchToProps = dispatch => ({
  listTransactions: (page) => dispatch(listTransactions(page)),
  resetTransactions: (eventSource) => dispatch(resetTransactions(eventSource)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionTransactions);
