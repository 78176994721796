export function isLocalUrl(url) {
  return typeof url === 'string' && url.startsWith("blob:");
}

export function clearLocalUrl(url) {
  if (!isLocalUrl(url)) {
    return;
  }
  window.URL.revokeObjectURL(url);
}
