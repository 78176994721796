import React from "react";
import {IconButton, Snackbar} from "@material-ui/core";
import ChatIcon from '@material-ui/icons/Chat';
import JobIcon from '@material-ui/icons/CardTravel';
import BusinessIcon from '@material-ui/icons/Business';
import ProfileIcon from '@material-ui/icons/AccountBox';
import withStyles from "@material-ui/core/styles/withStyles";
import LiveInterviewChat from "./LiveInterviewChat";
import {connect} from "react-redux";
import VideoInterviewSettings from "./VideoInterviewSettings";
import SidebarPanelHeader from "./SidebarPanelHeader";
import VideoInterviewJobInfo from "./VideoInterviewJobInfo";
import {currentUserHasRole} from "../../utils/auth";
import VideoInterviewCompanyInfo from "./VideoInterviewCompanyInfo";
import VideoInterviewProfileInfo from "./VideoInterviewProfileInfo";
import {blackColor, successColor, whiteColor} from "../../assets/jss/material-dashboard-pro-react";
import {EVENTS_SESSION_CHAT_MESSAGE} from "../../utils/liveInterviewSession";
import Badge from '@material-ui/core/Badge';
import classNames from 'classnames';

const style = {
  panelContainer: {
    flexBasis: '350px',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  panelContainerFullscreen: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    height: '90vh',
    width: '350px',
    color: 'white',
    position: 'absolute',
    right: '100px',
    zIndex: '110',
  },
  sidebarPanelContent: {
    overflowY: 'hidden',
    height: '100%',
  },
  menuContainer: {
    flexBasis: '10%',
    flexShrink: 0,
    textAlign: 'center',
    padding: '0 1em',
    overflow: 'auto',
  },
  menuContainerFullscreen: {
    position: 'absolute',
    right: 0,
    width: '106px',
    padding: 0,
    zIndex: '10000000',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(85vh - 45px)',

    '&.menu-autohide': {
      '& > div': {
        marginLeft: '100%',
        width: '100%',
        transition: 'margin-left 0.5s ease',
      },
      '&:hover > div': {
        marginLeft: 0,
      },
    }
  },
  menuButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  menuButtonsContainerFullscreen: {
    backgroundColor: 'rgba(0,0,0,.5)',
    width: '100%',
  },
  menuButton: {
    fontSize: 'small',
    outline: 'none !important',
    padding: '6px',
    transition: 'none',
    '&:hover': {
      borderRadius: '4px',
    },
    '& > span': {
      display: 'flex',
      flexDirection: 'column',
    },
    '&:first-child': {
      paddingTop: '15px',
    },
  },
  menuButtonFullscreen: {
    color: whiteColor,
    fontSize: 'small',
    '&:disabled': {
      color: 'grey',
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
      borderRadius: '4px',
    },
  },
  messageNotification: {
    '& > div': {
      backgroundColor: successColor[4],
    }
  },
};

const defaultPanelsState = {
    chatShown: false,
    jobShown: false,
    companyShown: false,
    profileShown: false,
};

class VideoInterviewMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
       ...defaultPanelsState,
      unreadMessages: 0,
      showNewMessageNotification: false,
    };
  }

  componentDidMount() {
    const { session } = this.props;
    session.on(EVENTS_SESSION_CHAT_MESSAGE, (message) => {
      if (!this.state.chatShown && !message.read && !message.own) {
        this.setState((prevState) => ({unreadMessages: prevState.unreadMessages + 1}));
      }
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.unreadMessages === 0 && this.state.unreadMessages !== 0) {
      this.setState({showNewMessageNotification: true});
    }
  }

  hideNewMessageNotification = () => {
    this.setState({showNewMessageNotification: false});
  }

  toggleChat = () => {
      this.setState((prevState) => ({
        ...defaultPanelsState,
        chatShown: !prevState.chatShown,
        unreadMessages: (!prevState.chatShown) ? 0 : prevState.unreadMessages,
        showNewMessageNotification: false,
      }));
  };

  toggleJob = () => {
    this.setState((prevState) => ({...defaultPanelsState, jobShown: !prevState.jobShown}));
  };

  toggleProfile = () => {
    this.setState((prevState) => ({...defaultPanelsState, profileShown: !prevState.profileShown}));
  };

  toggleCompany = () => {
    this.setState((prevState) => ({...defaultPanelsState, companyShown: !prevState.companyShown}));
  };

  render() {
    const {
      classes,
      fullscreenEnabled,
      sessionContainSharedScreen,
      autohideMenu = sessionContainSharedScreen
    } = this.props;
    const {chatShown, jobShown, profileShown, companyShown} = this.state;
    const panelOpened = chatShown || jobShown || profileShown || companyShown;
    const isCandidate = currentUserHasRole('ROLE_CANDIDATE');
    const buttonClass = classNames(classes.menuButton, {[classes.menuButtonFullscreen]: fullscreenEnabled});
    return (
      <>
        <div className={classNames(classes.panelContainer, {[classes.panelContainerFullscreen]: fullscreenEnabled})} style={{display: panelOpened ? 'flex' : 'none'}}>
          {chatShown && (
            <>
              <SidebarPanelHeader title='Live Chat' onClose={this.toggleChat} />
              <div className={classes.sidebarPanelContent}>
                <LiveInterviewChat />
              </div>
            </>
          )}
          {jobShown && (
            <>
              <SidebarPanelHeader title='Job Description' onClose={this.toggleJob} />
              <div className={classes.sidebarPanelContent}>
                <VideoInterviewJobInfo />
              </div>
            </>
          )}
          {profileShown && (
            <>
              <SidebarPanelHeader title='Candidate Profile' onClose={this.toggleProfile} />
              <div className={classes.sidebarPanelContent}>
                <VideoInterviewProfileInfo />
              </div>
            </>
          )}
          {companyShown && (
            <>
              <SidebarPanelHeader title='Company Profile' onClose={this.toggleCompany} />
              <div className={classes.sidebarPanelContent}>
                <VideoInterviewCompanyInfo />
              </div>
            </>
          )}
        </div>
        <div className={classNames(classes.menuContainer, {
          [classes.menuContainerFullscreen]: fullscreenEnabled,
          'menu-autohide': autohideMenu && !panelOpened,
        })}>
          <div>
            <div className={classNames(classes.menuButtonsContainer, {[classes.menuButtonsContainerFullscreen]: fullscreenEnabled})}>
              <>
              <IconButton onClick={this.toggleChat} className={buttonClass}>
                <Badge color="secondary" badgeContent={this.state.unreadMessages}>
                  <ChatIcon />
                </Badge>
                <span>Chat</span>
              </IconButton>
              <Snackbar
                open={this.state.showNewMessageNotification}
                onClose={this.hideNewMessageNotification}
                message="You have a new message. Click Chat to view."
                autoHideDuration={null}
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'top',
                }}
                classes={{root: classes.messageNotification}}
              />
              </>
              <IconButton onClick={this.toggleJob} className={buttonClass}>
                <JobIcon />
                <span>Job</span>
              </IconButton>
              {isCandidate ? (
                <>
                  <IconButton onClick={this.toggleCompany} className={buttonClass}>
                    <BusinessIcon />
                    <span>Company</span>
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton onClick={this.toggleProfile} className={buttonClass}>
                    <ProfileIcon />
                    <span>Candidate</span>
                  </IconButton>
                </>
              )}
              <VideoInterviewSettings 
                sessionContainSharedScreen={sessionContainSharedScreen}
                buttonClass={buttonClass} />
            </div>
          </div>
        </div>
      </>
     );
  }
}

const mapStateToProps = state => ({
  session: state.liveInterviewPage.session,
  fullscreenEnabled: state.liveInterviewPage.fullscreen,
});

const mapDispatchToProps = dispatch => ({
});

VideoInterviewMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoInterviewMenu);

VideoInterviewMenu = withStyles(style)(VideoInterviewMenu);

export default VideoInterviewMenu;
