import {
  fetch,
  extractHubURL
} from '../../utils/dataAccess';
import {filterAction, sortAction} from "../../utils/filterAndSortUtils";

export function error(error) {
  return { type: 'COMPANY_CANDIDATE_LIST_ERROR', error };
}

export function loading(loading) {
  return { type: 'COMPANY_CANDIDATE_LIST_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'COMPANY_CANDIDATE_LIST_SUCCESS', retrieved };
}

export function list(page = 'applications') {
  return dispatch => {
    dispatch(loading(true));
    dispatch(error(''));

    fetch(page)
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        dispatch(loading(false));
        dispatch(success(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch({ type: 'COMPANY_CANDIDATE_LIST_RESET' });
  };
}

export function filter(filtersData) {
  return filterAction('COMPANY_CANDIDATE_LIST_FILTER', 'company_candidate', filtersData)
}

export function sort(sortData) {
  return sortAction('COMPANY_CANDIDATE_LIST_SORT', 'company_candidate', sortData)
}
