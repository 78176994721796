import {combineReducers} from 'redux';

export function retrieved(state = null, action) {
  switch (action.type) {
    case 'REQUEST_LOAD_TEMPLATE_SUCCESS':
      return action.retrieved;
    default:
      return state;
  }
}

export function request(state = null, action) {
  switch (action.type) {
    case 'REQUEST_LOAD_TEMPLATE_SUCCESS':
      if (!action.retrieved) {
         return null;
      }
      const request = action.retrieved.request;
      delete request.title;

      return request;
    default:
      return state;
  }
}
export function loading(state = null, action) {
  switch (action.type) {
    case 'REQUEST_LOAD_TEMPLATE_LOADING':
      return action.loading;
    default:
      return state;
  }
}

export function error(state = null, action) {
  switch (action.type) {
    case 'REQUEST_LOAD_TEMPLATE_ERROR':
      return action.error;
    default:
      return state;
  }
}

export default combineReducers({error, loading, retrieved, request});
