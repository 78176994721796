import {
  fetch,
  extractHubURL,
} from '../../utils/dataAccess';

export function error(error) {
  return { type: 'LIVEINTERVIEW_SHARE_LINK_BY_CODE_ERROR', error };
}

export function passwordError(passwordError) {
  return { type: 'LIVEINTERVIEW_SHARE_LINK_BY_CODE_PASSWORD_ERROR', passwordError };
}

export function passwordRequired(passwordRequired) {
  return { type: 'LIVEINTERVIEW_SHARE_LINK_BY_CODE_PASSWORD_REQUIRED', passwordRequired };
}
export function noLongerAvailable(noLongerAvailable) {
  return { type: 'LIVEINTERVIEW_SHARE_LINK_BY_CODE_NO_LONGER_AVAILABLE', noLongerAvailable };
}

export function loading(loading) {
  return { type: 'LIVEINTERVIEW_SHARE_LINK_BY_CODE_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'LIVEINTERVIEW_SHARE_LINK_BY_CODE_SUCCESS', retrieved };
}

export function retrieve(token, password = null) {
  return dispatch => {
    let url = '/live_interview_share_link/by_code/' + token;
    if (password) {
      url = url + '?password=' + password;
    }

    dispatch(loading(true));
    dispatch(error(null));
    dispatch(passwordError(null));

    return fetch(url)
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        dispatch(loading(false));
        dispatch(success(retrieved));
        dispatch(passwordRequired(null));
      })
      .catch(e => {
        const status = e.response ? e.response.status : null;
        if (status === 403) {
          dispatch(passwordError(e.message));
        } else if (status === 401) {
          dispatch(passwordRequired(e.message));
        } else if (status === 422) {
          dispatch(noLongerAvailable(e.message));
        } else {
          dispatch(error(e.message));
        }

        dispatch(loading(false));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(error(null));
    dispatch(passwordError(null));
    dispatch(loading(false));
    dispatch(success(null));
  };
}
