import React, {Component} from 'react';
import Button from "../CustomButtons/Button";
import SearchableSelect from "react-select"
import PropTypes from 'prop-types';
import {loadRequestTemplatesForSelect} from "../../actions/RequestTemplate/forSelect";
import {loadTemplate, reset as loadTemplateReset} from "../../actions/Request/loadTemplate";
import {connect} from 'react-redux';
import "./TemplateLoadingForm.scss"
import {currentUserHasRole} from "../../utils/auth";
import SaveAsTemplateTeamDialogue from "./SaveAsTemplateTeamDialogue";
import {Popover} from '@material-ui/core';
import {loadTeamsForSelect, reset as resetTeams} from "../../actions/Team/forSelect";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "../../views/Components/Dialog/Dialog";

const style = {
  popover: {
    overflow: 'visible',
  },
};

class TemplateLoadingForm extends Component
{
  static propTypes = {
    loading: PropTypes.bool,
    hasQuestions: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  state = {
    template: '',
    teamDialogueAnchor: null,
    openLoadTemplateDialog: false,
  };

  componentWillMount() {
    this.props.loadTemplates();
    if (!currentUserHasRole('ROLE_COMPANY_ADMIN')) {
      this.props.loadTeamsForSelect();
    }
  }

  componentWillUnmount() {
    this.props.loadTemplateReset();
    if (!currentUserHasRole('ROLE_COMPANY_ADMIN')) {
      this.props.resetTeams();
    }
  }

  selectTemplate = (e) => {
    this.setState({template: e});
  };

  onSaveTemplateClick = (e) => {
    if (!currentUserHasRole('ROLE_COMPANY_ADMIN')) {
      this.setState({teamDialogueAnchor: e.currentTarget});
    } else {
      this.props.handleSubmit(this.saveAsTemplate)();
    }
  };

  onTeamSelected = (team) => {
    if (team) {
      this.props.handleSubmit((values) => {
        values['team'] = team;
        return this.saveAsTemplate(values);
      })();
    }
    this.setState({teamDialogueAnchor: null})
  };

  saveAsTemplate = (values) => {
    return this.props.onSubmit({
      ...values,
      submitType: 'saveAsTemplate'
    });
  };

  loadTemplate = () => {
    if (!this.props.hasQuestions || this.state.openLoadTemplateDialog) {
      this.props.loadTemplate(this.state.template.value);
      this.setState({openLoadTemplateDialog: false});
    }

    if (this.props.hasQuestions && !this.state.openLoadTemplateDialog) {
      this.setState({openLoadTemplateDialog: true});
    }
  }

  toggleLoadTemplateModal = () => {
    this.setState({openLoadTemplateDialog: !this.state.openLoadTemplateDialog});
  }

  render() {
    const {teams, classes, templates, saveAsTemplateLoading, loading} = this.props;

    return (
      <div className={'admin-on-demand-interview-template-load'}>
        <SearchableSelect
          label="Load Template"
          placeholder={templates ? "Select Template" : 'Loading...'}
          options={templates ? templates : []}
          onChange={this.selectTemplate}
          value={this.state.template}
          className="select-template"
        />
        <div className={"buttons"}>
          <Button
            disabled={loading || !this.state.template}
            onClick={this.loadTemplate}
          >
            Load Template
          </Button>
          <Button
            color={"success"}
            onClick={this.onSaveTemplateClick}
            disabled={saveAsTemplateLoading}
          >
            Save as Template
          </Button>
        </div>
        {!currentUserHasRole('ROLE_COMPANY_ADMIN') && (
          <Popover
            open={!!this.state.teamDialogueAnchor}
            anchorEl={this.state.teamDialogueAnchor}
            onClose={() => this.onTeamSelected()}
            classes={{paper: classes.popover}}
          >
            <SaveAsTemplateTeamDialogue teams={teams} onSubmit={(values) => this.onTeamSelected(values.team)} />
          </Popover>
        )}
        <Dialog
          open={this.state.openLoadTemplateDialog}
          onClose={() => this.setState({openLoadTemplateDialog: false})}
          title={"Load Template"}
          confirmButtonText={loading ? "Loading..." : "Load"}
          confirmButtonDisabled={loading || !this.state.template}
          onConfirm={this.loadTemplate}
          closeButtonText={"Cancel"}
        >
          Are you sure you want to load this template? All the questions in interview will be replaced.
        </Dialog>
      </div>
    );
  }
}

TemplateLoadingForm = withStyles(style)(TemplateLoadingForm);

const mapStateToProps = state => {
  return {
    templates: state.requesttemplate.forSelect.retrieved,
    teams: state.team.forSelect.retrieved,
    saveAsTemplateLoading: state.request.saveAsTemplate.loading,
    saveAsTemplateSaved: state.request.saveAsTemplate.saved,
    saveAsTemplateError: state.request.saveAsTemplate.error,
  }
};

const mapDispatchToProps = dispatch => ({
  loadTemplates: () => dispatch(loadRequestTemplatesForSelect()),
  loadTemplate: (template) => dispatch(loadTemplate(template)),
  loadTemplateReset: () => dispatch(loadTemplateReset()),
  loadTeamsForSelect: () => dispatch(loadTeamsForSelect('@id')),
  resetTeams: () => dispatch(resetTeams()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateLoadingForm);
