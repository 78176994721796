import {SubmissionError} from 'redux-form';
import {fetch, fetchJSON, normalize} from '../../utils/dataAccess';
import { cloneDeep } from "lodash";

export function error(error) {
  return {type: 'QUESTIONBANK_CREATE_ERROR', error};
}

export function loading(loading) {
  return {type: 'QUESTIONBANK_CREATE_LOADING', loading};
}

export function loadCategories() {
  return dispatch => {
    fetchJSON('/question_bank_categories?pagination=false').then((categories) => {
      dispatch(categoriesLoaded(categories['hydra:member'].map(
        function (questionBankCategory) {
          return {
            value: questionBankCategory['@id'],
            text: questionBankCategory.name,
          }
        }
      )));
    }).catch(e => {
      dispatch(error(e.message));
    })
  };
}

export function categoriesLoaded(categories) {
  return {type: 'QUESTIONBANK_CATEGORIES_LOADED', categories};
}

export function success(created) {
  return {type: 'QUESTIONBANK_CREATE_SUCCESS', created};
}

export function create(values) {
  return dispatch => {
    dispatch(loading(true));

    return fetch('question_banks', {method: 'POST', data: normalize(cloneDeep(values))})
      .then(response => {
        dispatch(loading(false));
        dispatch(success(response.data));
      })
      .catch(e => {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw e;
        }

        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(error(null));
    dispatch(success(null));
  };
}
