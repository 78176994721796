import {Field, reduxForm} from "redux-form";
import {renderTextField} from "../../utils/form";
import InputAdornment from "@material-ui/core/InputAdornment";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import {Link} from "react-router-dom";
import {LoginFormName, LoginFormStyle} from "../../components/Login/LoginFormSettings";
import LoadingButton from "../../views/Components/LoadingButton";

const LoginSecondStep = props => {
  const { handleSubmit, classes, loading } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        label={null}
        component={renderTextField}
        type="password"
        name="password"
        placeholder="Password"
        htmlRequired={true}
        required={true}
        containerClass={classes.inputContainer}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Icon>lock_outline</Icon>
            </InputAdornment>
          ),
        }}
      />
      <div className={classes.loginPageLinks}>
        <Link to={`/password-recovery`}>
          Forgot Password
        </Link>
      </div>
      <div className={classes.justifyContentCenter + ' mt-3'}>
        <LoadingButton
          type="submit"
          color="info"
          loading={loading}
          loadingText="Logging In..."
        >
          Login
        </LoadingButton>
      </div>
    </form>
  );
};

const styledForm = withStyles(LoginFormStyle)(LoginSecondStep);

export default reduxForm({
  form: LoginFormName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(styledForm);
