import {combineReducers} from "redux";
import {isAuthenticated} from "../../utils/auth";

function loggedIn (state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_AUTH_LOGGED_IN':
      return action.loggedIn;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return isAuthenticated();
  }
}

function loggingIn(state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_AUTH_LOGGING_IN':
      return action.loggingIn;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

function accountExists(state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_AUTH_ACCOUNT_EXISTS':
      return action.exists;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

function checkingAccountExists(state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_AUTH_ACCOUNT_EXISTS_CHECKING':
      return action.checking;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return false;
    default:
      return state;
  }
}

function error(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_PAGE_AUTH_ERROR':
      return action.error;
    case 'LIVEINTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}

export default combineReducers({ loggedIn, loggingIn, accountExists, checkingAccountExists, error });
