import React from 'react';
import Dialog from "../../views/Components/Dialog/Dialog";
import PropTypes from 'prop-types';
import moment from "moment";
import {CircularProgress} from "@material-ui/core";
import "./Transactions.scss";
import {PaymentMethodLogo} from "../../views/Subscription/PaymentMethodCard";
import {firstLetterToUppercase} from "../../utils/firstLetterToUpperCase";
import {formatPlanPrice, formatPrice, PREVIEW_PERIOD_ANNUAL} from "../../utils/plan";

export default class TransactionViewModal extends React.Component {
  static propTypes = {
    transaction: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  render() {
    const transaction = this.props.transaction;
    const summary = transaction['order']['summary'];

    return (
      <div>
        <Dialog
          open={true}
          onClose={this.props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          title={"Receipt " + (transaction ? moment(transaction['createdAt']).format("MM/DD/YYYY"): '')}
          dialogProperties={{subscriptionTheme: true}}
        >
          {this.props.loading && (
            <div className={"text-center"}>
              <CircularProgress /> Loading ...
            </div>
          )}
          {transaction && (
            <div className={"transaction-modal-container"}>
              <div className={"field-row"}>
                <div className={"field-name"}>Transaction ID</div>
                <div className={'field-value'}>{transaction['token']}</div>
              </div>

              <div className={"field-row"}>
                <div className={"field-name"}>Date & Time</div>
                <div className={'field-value'}>{moment(transaction['createdAt']).format("MM/DD/YYYY hh:m A")}</div>
              </div>

              {summary && summary['plan_price'] && (
                <div className={"field-row price"}>
                  <div className={"field-name"}>{summary['plan_name']} (bills {summary['plan_period'] === PREVIEW_PERIOD_ANNUAL ? 'annually' : 'monthly'})</div>
                  <div className={'field-value'}>
                    {formatPrice(summary['plan_price'])}
                  </div>
                </div>
              )}

              {summary && !!summary['prorated_adjustment'] && (
                <>
                  <div className={"field-row price"}>
                    <div className={"field-name"}>Prorated Adjustment</div>
                    <div className={'field-value'}>
                      {formatPrice(summary['prorated_adjustment'])}
                    </div>
                  </div>
                  <div className={"field-row price"}>
                    <div className={"field-name"}>Subtotal</div>
                    <div className={'field-value'}>
                      {formatPrice(summary['subtotal'])}
                    </div>
                  </div>
                </>
              )}

              {summary && summary['tax'] && (
                <div className={"field-row price"}>
                  <div className={"field-name"}>Tax (10%)</div>
                  <div className={'field-value'}>
                    {formatPrice(summary['tax'])}
                  </div>
                </div>
              )}

              {summary && summary['total'] && (
                <div className={"field-row amount"}>
                  <div className={"field-name"}>Amount</div>
                  <div className={'field-value'}>
                    {formatPrice(summary['total'])}
                  </div>
                </div>
              )}

              <div className={"field-row"}>
                <div className={"field-name"}>Status</div>
                <div className={'field-value'}>
                  <div className={"transaction-status " + transaction['status']}>
                    {firstLetterToUppercase(transaction['status'])}
                  </div>
                </div>
              </div>
              <div className={"field-row"}>
                <div className={"field-name"}>Card Used</div>
                <div className={'field-value'}>
                  {transaction['brand'] && transaction['lastFour'] && (
                    <>
                      <PaymentMethodLogo brand={transaction['brand']} /> ending in {transaction['lastFour']}
                    </>
                  )}
                </div>
              </div>

              <button className={"subscription-modal-button-green"} onClick={() => this.props.handleClose()}>Close</button>
            </div>
          )}

        </Dialog>
      </div>
    );
  }
}
