import React, {Component} from "react";
import PropTypes from "prop-types";
import VideoProgressIndicator from "./VideoProgressIndicator";
import VideoPlayer from "../../components/video/VideoPlayer";
import {isVideoNeedToReEncode} from "../../utils/videoEntity";
import {BigPlayButton, ControlBar} from "video-react";

class EntityVideoPlayer extends Component {
  static propTypes = {
    entity: PropTypes.object.isRequired,
    progressIndicatorClassName: PropTypes.string,
  };


  render() {
    const {entity, progressIndicatorClassName, innerRef, ...otherProps} = this.props;
    const videoSrc = entity.localUrl ? entity.localUrl : entity.videoUrl;
    return (
      isVideoNeedToReEncode(entity) ? (
        <VideoProgressIndicator className={progressIndicatorClassName} label={'Processing file...'} progress={entity.reEncodingProgress} />
      ) : (
        <VideoPlayer ref={innerRef} src={videoSrc} {...otherProps}>
          <BigPlayButton position='center' className={'big-play-button-hide'} />
          <ControlBar disableCompletely={true}/>
        </VideoPlayer>
      )
    );
  }
}

export default React.forwardRef(
  (props, ref) => (
    <EntityVideoPlayer
      innerRef={ref} {...props}
    />
  )
);
