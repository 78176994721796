import React from "react";
import PropTypes from "prop-types";
import {isGranted} from "../../utils/permissions";
import {Link} from "react-router-dom";

export default class CandidateLink extends React.Component {
  static propTypes = {
    candidate: PropTypes.object.isRequired,
    checkPermissions: PropTypes.bool
  };

  render() {
    const { candidate, checkPermissions } = this.props;

    let parts = [];
    if (candidate.salutation && candidate.salutation.length > 0) {
      parts.push(candidate.salutation);
    }

    if (candidate.firstName && candidate.firstName.length > 0) {
      parts.push(candidate.firstName);
    }

    if (candidate.lastName && candidate.lastName.length > 0) {
      parts.push(candidate.lastName);
    }

    const allowLink = checkPermissions ? isGranted('show', candidate) : true;

    if (candidate['@id'] && allowLink) {
      return (
        <Link to={`/company/candidates/show/${encodeURIComponent(candidate['@id'])}`}>
          {parts.join(' ')}
        </Link>
      );
    }

      return (<span>{parts.join(' ')}</span>);
  };
}
