import React, {Component} from "react";
import { FieldArray} from 'redux-form';
import KnockoutQuestionChoiceField from "./KnockoutQuestionChoiceField";
import Button from "../CustomButtons/Button";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import Clear from "@material-ui/icons/Clear"
import NoteAdd from "@material-ui/icons/NoteAdd"

export default class KnockoutQuestionChoicesEditor extends Component {
    addChoice = (choices) => {
        return () => {
            let maxPosition = choices.getAll().reduce((max, choice) => choice.position > max ? choice.position : max, 0);
            choices.push({position: (maxPosition + 1)});
        }
    };

    removeChoice = (choices, index) => {
        return () => {
            choices.remove(index)
        }
    };

    renderChoices = ({fields: choices, meta: {error}}) => (
        <>
            {choices.map((choice, index) => (
                <GridItem xs={12} key={'knockout-question-choice' + index}>
                    <KnockoutQuestionChoiceField name={choice}>
                        <Button
                            size="sm"
                            color="danger"
                            onClick={this.removeChoice(choices, index)}
                        ><Clear>Remove Choice</Clear></Button>
                    </KnockoutQuestionChoiceField>
                    {error}
                </GridItem>
            ))}
            <GridItem xs={12}>
                {error && <div className="error">{error}</div>}
                <Button color="success" onClick={this.addChoice(choices)}><NoteAdd/>Add Choice</Button>
            </GridItem>
        </>
    );

    render() {
        return (
            <GridContainer>
                <FieldArray name={this.props.name}
                            component={this.renderChoices}
                />
            </GridContainer>
        );
    }
}
