import React, {Component} from "react";
import InputLabel from "@material-ui/core/InputLabel";
import PropTypes from "prop-types";
import VideoField from "../../views/Components/VideoField";
import {VIDEO_ENTRYPOINT} from "../../config/entrypoint";
import {Field} from "redux-form";
import YoutubeVideoField from "../../views/Components/YoutubeVideoField";

export const INTRO_VIDEO_FIELD = 'request-intro-video';
export const OUTRO_VIDEO_FIELD = 'request-outro-video';

class IntroOutroVideoFields extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    names: PropTypes.array.isRequired,
    type: PropTypes.oneOf([INTRO_VIDEO_FIELD, OUTRO_VIDEO_FIELD])
  };

  getYoutubeComponent = (field) => {
    return (
      <YoutubeVideoField
        {...field}
        label={'URL'}
        placeholder="Supported (YouTube)"
        className={'col-lg-5'}
        fullWidth
      />
    )
  }

  getFieldDataByName = (fieldName) => {
    const nameParts = fieldName.split(/(\w*)?/).filter(item => item && !['[',']'].includes(item));

    if (!nameParts || nameParts.length === 0) {
      return null;
    }

    let source = this.props;
    for (const name of nameParts) {
      if (!source || !source[name]) {
        return null;
      }
      source = source[name];
    }

    return source ? source : null;
  }

  render() {
    const {names: fieldNames, type} = this.props;
    const videoEntityFieldName = fieldNames[0];
    const videoEntityField = this.getFieldDataByName(videoEntityFieldName);
    const videoUrlFieldName = fieldNames[1];
    const videoUrlField = this.getFieldDataByName(videoUrlFieldName);
    return (
      <div>
          <InputLabel className={"mt-5"}>{this.props.label}</InputLabel>
          <Field
            component={VideoField}
            name={videoEntityFieldName}
            uploadingEndpoint={VIDEO_ENTRYPOINT + `/${type}/upload`}
            recordingEndpoint={type}
            recordingSocketType={type}
            autoHideModes={false}
            initialMode={videoUrlField.input.value ? 'youtube' : ''}
            additionalModes={[
              {
                value: 'youtube',
                label: 'Link (i.e. YouTube)',
                component: this.getYoutubeComponent(videoUrlField),
                onChange: () => videoUrlField.input.onChange(null),
              }
            ]}
            {...videoEntityField}
          />
      </div>
    );
  }
}

export default IntroOutroVideoFields;
