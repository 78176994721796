import {combineReducers} from 'redux';

export function retrieved(state = false, action) {
  switch (action.type) {
    case 'ACCESS_COMPANY_USERS_RETRIEVED':
      return action.retrieved;
    case 'ACCESS_COMPANY_USERS_RESET':
      return false;
    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'ACCESS_COMPANY_USERS_LOADING':
      return action.loading;
    case 'ACCESS_COMPANY_USERS_RESET':
      return false;
    default:
      return state;
  }
}

export function error(state = null, action) {
  switch (action.type) {
    case 'ACCESS_COMPANY_USERS_ERROR':
      return action.error;
    case 'ACCESS_COMPANY_USERS_RESET':
      return null;
    default:
      return state;
  }
}

export default combineReducers({retrieved, error, loading});
