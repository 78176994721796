import React, { Component } from 'react';
import {Field, FieldArray, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {renderCheckbox, renderTextField} from "../../utils/form";
import ProfessionMapping from "../../views/Components/ProfessionMapping";
import SearchableSelect from "../../views/Components/SearchableSelect";
import CandidateResumeField from "./CandidateResumeField";

class CandidateForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
    isNew: PropTypes.bool,
  };

  render() {
    const statuses = [
      {text: 'Active', value: 'active'},
      {text: 'Inactive', value: 'inactive'},
      {text: 'Pending', value: 'pending'},
    ];

    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={renderTextField}
          name="firstName"
          type="text"
          placeholder="First Name"
          required={true}
        />
        <Field
          component={renderTextField}
          name="lastName"
          type="text"
          placeholder="Last Name"
          required={true}
        />
        <Field
          component={renderTextField}
          name="email"
          type="email"
          placeholder="Email"
          required={true}
        />
        <Field
          component={renderCheckbox}
          name="receiveNotifications"
          label="Receive Email Notifications"
        />
        <Field
          component={renderTextField}
          name="phoneNumber"
          label="Mobile Number"
          type="text"
          placeholder="Mobile Number"
          required={true}
        />
        <Field
          component={SearchableSelect}
          name="status"
          label="Status"
          placeholder="Status"
          required={true}
          options={statuses}
        />
        <FieldArray
          label="Profession Mappings"
          name="professionMappings"
          component={ProfessionMapping}
        />
        <CandidateResumeField />
        <Field
          component={renderTextField}
          name="plainPassword"
          type="password"
          placeholder="Password"
          required={this.props.isNew}
        />
        <Field
          component={renderTextField}
          name="passwordConfirm"
          type="password"
          placeholder="Confirm Password"
          required={this.props.isNew}
        />
        <button type="submit" className="btn btn-success float-left mr-3">
          Submit
        </button>
      </form>
    );
  }
}

const validate = values => {
  const errors = {};

  if (!values['@id']) {
    if (!values.plainPassword) {
      errors.plainPassword = 'Please enter new password';
    }
    if (!values.passwordConfirm) {
      errors.passwordConfirm = 'Please repeat password';
    } else if (values.plainPassword !== values.passwordConfirm) {
      errors.passwordConfirm = 'New password and repeat password should be the same';
    }
  } else {
    if ((values.plainPassword || values.passwordConfirm) && values.plainPassword !== values.passwordConfirm) {
      errors.passwordConfirm = 'Passwords are not the same';
    }
  }

  return errors;
};

export default reduxForm({
  form: 'candidate',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate
})(CandidateForm);
