import React, {Component} from 'react';
import PropTypes from "prop-types";
import {isQuestionHasRecordTimeLimit} from "../../utils/onDemandInterview";
import TimeElapsedDisplay from "../TimeElapsedDisplay/TimeElapsedDisplay";

class MobileRecordingTime extends Component {
  static propTypes = {
    question: PropTypes.object.isRequired,
    stopRecording: PropTypes.func.isRequired,
    recordingStartTime: PropTypes.object,
  };

  render() {
    const {question, recordingStartTime, stopRecording} = this.props;
    const hasTimeLimitation = isQuestionHasRecordTimeLimit(question);
    if (recordingStartTime) {
      return (
        <TimeElapsedDisplay
          startTime={recordingStartTime}
          running={true}
          onCountedDown={stopRecording}
          withHours={!hasTimeLimitation}
          isNegative={hasTimeLimitation}
        />
      );
    } else {
      return null;
    }
  }
}

export default MobileRecordingTime;
