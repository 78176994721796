import React from "react";
import PropTypes from "prop-types";
import ChangeDeviceComponent from "./ChangeDeviceComponent";
import {filterAudioDevices, getAudioDevice, getAudioTrack, isMultipleAudioDevicesSupported} from "../../utils/webRtc";
import PhoneIcon from "@material-ui/icons/Phone";
import {IconButton} from "@material-ui/core";
import {changeAudioDevice, PUBLISHER_SIP_AUDIO_CONNECTED} from "../../utils/streamManager";
import MicrophoneDeviceDialog from "./MicrophoneDeviceDialog";

class MicrophoneDeviceSelect extends React.Component {
  static defaultProps = {
    buttonText: 'Audio Options',
    icon: <PhoneIcon />,
    disabled: false,
  };

  static propTypes = {
    streamManager: PropTypes.object.isRequired,
    sipOptions: PropTypes.object,
    buttonText: PropTypes.string,
    icon: PropTypes.object,
    disabled: PropTypes.bool,
  };

  getCurrentDeviceId = () => {
    const {streamManager} = this.props;
    const mediaStream = streamManager && streamManager.stream ? streamManager.stream.mediaStream : null;
    if (!mediaStream) {
      return null;
    }
    const device = getAudioDevice(mediaStream);
    return device ? device.deviceId : null;
  };

  renderDialog = (devices, currentDevice, selectedDevice, onDeviceSelected, finishDeviceChanging, changeDevice, closeDialog) => {
    const {streamManager, sipOptions} = this.props;

    //Auto close dialog if sip audio is connected
    const closeAudioDialog = () => {
      streamManager.off(PUBLISHER_SIP_AUDIO_CONNECTED, closeDialog);
      closeDialog();
    };
    streamManager.on(PUBLISHER_SIP_AUDIO_CONNECTED, closeDialog);

    return <MicrophoneDeviceDialog
      currentDevice={currentDevice}
      devices={devices}
      changeDevice={(newDevice) => {
        if (newDevice && newDevice !== currentDevice) {
          changeDevice(newDevice);
        } else {
          closeAudioDialog();
        }
      }}
      closeDialog={closeAudioDialog}
      sipCode={sipOptions ? sipOptions.sipCode : null}
      sipPhone={sipOptions ? sipOptions.sipPhone : null}
      sipConfirmButtonText={null}
    />;
  }

  renderIconButton = (onClick, disabled) => {
    const {icon, buttonClass, buttonText} = this.props;
    const buttonDisabled = disabled || this.props.disabled;
    return (
      <IconButton onClick={onClick} disabled={buttonDisabled} className={buttonClass}>
        {icon}
        {buttonText && (<span className='video-control-label'>{buttonText}</span>)}
      </IconButton>
    )
  }

  render() {
    const {streamManager} = this.props;
    return (
      <ChangeDeviceComponent
        streamManager={streamManager}
        getCurrentDeviceId={this.getCurrentDeviceId}
        filterDevices={filterAudioDevices}
        changeDevice={changeAudioDevice}
        renderDialog={this.renderDialog}
        renderButton={this.renderIconButton}
      />
    )
  }
}

export default MicrophoneDeviceSelect;
