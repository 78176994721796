import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {renderTextField} from "../../utils/form";
import {Field, reduxForm} from "redux-form";

const style = {
    justifyContentCenter: {
        justifyContent: "center !important",
        display: "flex",
        paddingTop: "10px"
    },
};

class PasswordRecoveryForm extends React.Component {
    handleSubmitForm(data) {
        const {onSubmit, reset} = this.props;
        return onSubmit(data).then(() => {
            reset();
        });
    }

    render() {
        const { handleSubmit, classes } = this.props;
        return (
            <form onSubmit={handleSubmit(this.handleSubmitForm.bind(this))}>
                <Field
                    component={renderTextField}
                    name='email'
                    type="email"
                    placeholder="Email"
                    label={false}
                    required={true}
                />
                <div className={classes.justifyContentCenter}>
                    <input type="submit" value="Submit"/>
                </div>
            </form>
        );
    }
}

PasswordRecoveryForm = reduxForm({
    form: 'password_recovery'
})(PasswordRecoveryForm);

export default withStyles(style)(PasswordRecoveryForm);
