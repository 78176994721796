import React, {Component} from "react";
import IsGranted from "../IsGranted";
import {Link} from "react-router-dom";

class ReviewActions extends Component  {
  render() {
    const {review, handleDelete} = this.props;
    return (
      <div>
        <IsGranted action="edit" object={review}>
          <Link to={`review/${encodeURIComponent(review['@id'])}`} className="btn btn-sm btn-warning mr-2">Edit</Link>
        </IsGranted>
        <IsGranted action="delete" object={review}>
          <button type="button" onClick={handleDelete} className="btn btn-sm btn-danger">Delete</button>
        </IsGranted>
      </div>
    );
  };
}

export default ReviewActions;
