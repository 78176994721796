import { combineReducers } from 'redux';

export function error(state = null, action) {
  switch (action.type) {
    case 'COMPANY_CURRENT_RETRIEVE_ERROR':
    case 'COMPANY_CURRENT_UPDATE_ERROR':
      return action.updateError;
    case 'COMPANY_CURRENT_RETRIEVE_RESET':
      return null;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'COMPANY_CURRENT_RETRIEVE_LOADING':
    case 'COMPANY_CURRENT_UPDATE_LOADING':
      return action.loading;

    case 'COMPANY_CURRENT_RETRIEVE_RESET':
      return false;

    default:
      return state;
  }
}

export function retrieved(state = null, action) {
  switch (action.type) {
    case 'COMPANY_CURRENT_RETRIEVE_SUCCESS':
      return action.retrieved;

    case 'COMPANY_CURRENT_RETRIEVE_RESET':
      return null;

    default:
      return state;
  }
}

export function updated(state = null, action) {
  switch (action.type) {
    case 'COMPANY_CURRENT_UPDATE_SUCCESS':
      return action.updated;

    case 'COMPANY_CURRENT_UPDATE_RESET':
      return null;

    default:
      return state;
  }
}

export default combineReducers({ error, loading, retrieved, updated });
