import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Form from './Form';
import { create } from '../../actions/SiteSettings/create';
import { update } from '../../actions/SiteSettings/update';
import {list, reset} from "../../actions/SiteSettings/list";

class PrivacyPolicy extends Component {
  static propTypes = {
    retrieve: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  static defaultProps = {
    keyword: 'Privacy Policy',
    fieldPrefix: 'privacyPolicy',
  }

  componentDidMount() {
    this.props.retrieve();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    let item = null;
    if (this.props.retrieved) {
      this.props.retrieved['hydra:member'].map(settings => {
        if (null === item && !settings['partner']) {
          item = settings;
        }

        if (settings['partner']) {
          item = settings;
        }
      });
    }

    let save = item && item['partner'] ? this.props.update : this.props.create;
    if (item && !item['partner']) {
      item = {
        privacyPolicyUrl: item.privacyPolicyUrl,
        privacyPolicyContent: item.privacyPolicyContent,
        privacyPolicyType: item.privacyPolicyType,
        termsOfServiceUrl: item.termsOfServiceUrl,
        termsOfServiceContent: item.termsOfServiceContent,
        termsOfServiceType: item.termsOfServiceType,
      };
    }

    if (!item && !this.props.loading) {
      item = {
        privacyPolicyType: 'content',
        termsOfServiceType: 'content',
      }
    }

    return (
      <div>
        {(this.props.created || this.props.updated) && (
          <div className="alert alert-success" role="status">
            {this.props.keyword} successfully saved.
          </div>
        )}
        {(this.props.loading) && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}

        <Form
          onSubmit={values => save(item, values)}
          initialValues={item}
          keyword={this.props.keyword}
          fieldPrefix={this.props.fieldPrefix}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.siteSettings.list.retrieved,
  created: state.siteSettings.create.created,
  updated: state.siteSettings.update.updated,
  loading: state.siteSettings.list.loading || state.siteSettings.create.loading || state.siteSettings.update.updateLoading ,
  error: state.siteSettings.currentSiteSettings.error || state.siteSettings.create.error || state.siteSettings.update.error,
});

const mapDispatchToProps = dispatch => ({
  retrieve: () => dispatch(list()),
  create: (item, values) => dispatch(create(item, values)),
  update: (item, values) => dispatch(update(item, values)),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivacyPolicy);
