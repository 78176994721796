import React from "react";
import {Route, Switch} from "react-router-dom";
import NotFound from "../views/Pages/NotFound.jsx";
import {connect} from "react-redux";
import getRoutes from "../routes";
import {withRouter} from "react-router";

class InterviewLayout extends React.Component {
    getRoutes = routes => {
        const {interviewType} = this.props;
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return this.getRoutes(prop.views);
            }
            if (prop.layout === '/' + interviewType) {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                        exact
                    />
                );
            } else {
                return null;
            }
        });
    };

    render() {
        const {site, ...rest} = this.props;
        const routes = getRoutes(site);
        return (
            <Switch>
                {this.getRoutes(routes)}
                <Route component={NotFound} />
            </Switch>
        );
    }
}

const mapStateToProps = state => ({
    site: state.siteDetails.siteDetails.retrieved,
});

InterviewLayout = connect(
    mapStateToProps
)(InterviewLayout);

InterviewLayout = withRouter(InterviewLayout);

export default InterviewLayout;
