import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import {renderTextField} from "../../utils/form";
import {loadStatesForSelect} from "../../actions/State/forSelect";
import {connect} from 'react-redux';
import RichTextEditor from "../../views/Components/RichTextEditor";
import SearchableSelect from "../../views/Components/SearchableSelect";
import InlineFileUpload from "../../views/Components/InlineFileUpload";
import {loadCountriesForSelect} from "../../actions/Country/forSelect";

class CompanyForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      country: props && props.initialValues && ('country' in props.initialValues) ? props.initialValues['country'] : null,
    };
  }

  componentDidMount() {
    this.props.loadCountriesForSelect(this.props.site.country && this.props.site.country.code);
    this.props.loadStatesForSelect();
  }

  handleCountrySelect = (e, value, oldValue) => {
    if (value !== oldValue) {
      this.props.change('state', null);

      this.setState({country: value});
    }
  };

  render() {
    let stateOptions = [];
    if (this.state.country !== null) {
      if (this.props.states) {
        this.props.states.map(state => {
          if (state.country === this.state.country) {
            stateOptions.push(state);
          }
        });
      }
    }

    const showWhiteLabelOptions = this.props.initialValues.whiteLabelEnabled;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={renderTextField}
          name="name"
          type="text"
          placeholder="Name"
          required={true}
        />
        <Field
          component={renderTextField}
          name="address"
          type="text"
          placeholder="Address"
          required={true}
        />
        <Field
          component={renderTextField}
          name="address2"
          type="text"
          placeholder="Address 2"
        />
        <Field
          component={renderTextField}
          name="city"
          type="text"
          placeholder="City"
          required={true}
        />
        <Field
          component={SearchableSelect}
          name="country"
          label="Country"
          placeholder={this.props.countries ? 'Select Country' : 'Loading...'}
          options={this.props.countries ? this.props.countries : []}
          required={true}
          onChange={this.handleCountrySelect}
        />
        {stateOptions.length > 0 && (
          <Field
            component={SearchableSelect}
            name="state"
            label="State"
            placeholder="Select State"
            options={stateOptions}
            required={true}
            isDisabled={!this.state.country}
          />
        )}
        <Field
          component={renderTextField}
          name="zipCode"
          type="text"
          placeholder="Zip Code"
          required={true}
        />
        <Field
          component={RichTextEditor}
          name="description"
          placeholder="Description"
          required={true}
        />
        <Field
          component={renderTextField}
          name="phoneNumber"
          type="text"
          placeholder="Phone Number"
          required={true}
        />
        <Field
          component={renderTextField}
          name="emailAddress"
          type="text"
          placeholder="Email Address"
          required={true}
        />
        <Field
          component={renderTextField}
          name="webSite"
          type="text"
          placeholder="Web Site"
          required={true}
        />
        <Field
          component={InlineFileUpload}
          type="image"
          accept=".jpg, .png, .jpeg"
          name="logo"
          fileUrl={this.props.fileUrl}
        />
        {showWhiteLabelOptions && (
        <>
          <Field
            component={renderTextField}
            name="whiteLabelDomain"
            type="text"
            placeholder="Site Domain"
            label="Site Domain"
            required={true}
          />
          <Field
            component={renderTextField}
            name="whiteLabelEmailAddress"
            type="text"
            placeholder="Site Email"
            label="Site Email"
            required={true}
          />
          <Field
            component={renderTextField}
            name="whiteLabelHomePageSlogan"
            placeholder="Home Page Slogan"
            label="Home Page Slogan"
          />
          <Field
            component={InlineFileUpload}
            type="image"
            accept=".ico, .png, .gif, .jpg, .jpeg, .svg"
            name="whiteLabelFavicon"
            label="Favicon"
          />
        </>
        )}
        <button type="submit" className="btn btn-success float-left mr-3">
          Submit
        </button>
      </form>
    );
  }
}

const requiredState = value => value ? undefined : 'Please select State';

const mapStateToProps = state => {
  let countries = state.country.forSelect.retrieved;
  let states = state.state.forSelect.retrieved;
  let site =  state.siteDetails.siteDetails.retrieved;

  return { countries, states, site };
};

const mapDispatchToProps = dispatch => ({
  loadCountriesForSelect: (countryCode) => dispatch(loadCountriesForSelect('@id', countryCode)),
  loadStatesForSelect: () => dispatch(loadStatesForSelect('@id')),
});

CompanyForm = reduxForm({
  form: 'company',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(CompanyForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyForm);
