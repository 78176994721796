import React from "react";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import PhoneNumber from "../../views/Components/PhoneNumber";

const style = {
  root: {
    padding: '1em',
    paddingTop: '0',
  },
  profileContainer: {
    '&:not(:last-child)': {
      borderBottom: '1px solid gray',
      marginBottom: '1em',
    }
  },
  profileInfoBlock: {
    marginBottom: '1em',
  },
  profileInfoHeader: {
    fontSize: 'large',
    fontWeight: 'bold',
  },
  profileInfoContent: {
    display: 'block',
  },
};

class VideoInterviewProfileInfo extends React.Component {
  renderCandidateProfession = (professionMappings) => {
    const industries = [];
    professionMappings.forEach((mapping) => {
      const parts = [];
      if (mapping.industry) {
        parts.push(mapping.industry.name);
      }
      if (mapping.occupation) {
        parts.push(mapping.occupation.name);
      }
      if (mapping.specialty) {
        parts.push(mapping.specialty.name);
      }
      industries.push(parts.join(', '));
    });

    return industries.join(' / ');
  };

  render() {
    const {classes, liveInterview: {candidateInvitations}} = this.props;
    return (
      <div className={classes.root}>
      {candidateInvitations && candidateInvitations.length > 0 ? (
        candidateInvitations.map((invitation, key) => {
          const candidate = (invitation.companyCandidate && invitation.companyCandidate.candidate) ?
            invitation.companyCandidate.candidate : null;
          return (
            <div key={key} className={classes.profileContainer}>
              <div className={classes.profileInfoBlock}>
                <span className={classes.profileInfoHeader}>Candidate Name:</span>
                <span className={classes.profileInfoContent}>{invitation.firstName} {invitation.lastName}</span>
              </div>
              <div className={classes.profileInfoBlock}>
                <span className={classes.profileInfoHeader}>Candidate Email:</span>
                <span className={classes.profileInfoContent}>{invitation.email}</span>
              </div>
              <div className={classes.profileInfoBlock}>
                <span className={classes.profileInfoHeader}>Candidate Phone:</span>
                <span className={classes.profileInfoContent}>
                  <PhoneNumber phone={invitation.phoneNumber} />
                </span>
              </div>
              {candidate && candidate.professionMappings && candidate.professionMappings.length > 0 && (
                <div className={classes.profileInfoBlock}>
                  <span className={classes.profileInfoHeader}>Industry:</span>
                  <span className={classes.profileInfoContent}>
                    {this.renderCandidateProfession(candidate.professionMappings)}
                  </span>
                </div>
              )}
              {candidate && candidate.resume && candidate.resume.file && (
                <div className={classes.profileInfoBlock}>
                  <span className={classes.profileInfoContent}>
                    <a target='_blank' href={candidate.resume.file.url}>View Resume</a>
                  </span>
                </div>
              )}
            </div>
          );
        })
        ) : (
          <span>No information</span>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  liveInterview: state.liveInterviewPage.liveInterview,
});

const mapDispatchToProps = dispatch => ({
});

VideoInterviewProfileInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoInterviewProfileInfo);

VideoInterviewProfileInfo = withStyles(style)(VideoInterviewProfileInfo);

export default VideoInterviewProfileInfo;
