import { SubmissionError } from 'redux-form';
import { fetch } from '../../utils/dataAccess';
import { reset as resetJobsForSelect } from "./../Job/forSelect";
import {updateScheduledStartTimeToTimezoneForLiveInterview} from "./update";

export function error(error) {
  return { type: 'LIVEINTERVIEW_CREATE_ERROR', error };
}

export function loading(loading) {
  return { type: 'LIVEINTERVIEW_CREATE_LOADING', loading };
}

export function success(created) {
  return { type: 'LIVEINTERVIEW_CREATE_SUCCESS', created };
}

export function create(values) {
  return dispatch => {
    dispatch(loading(true));
    updateScheduledStartTimeToTimezoneForLiveInterview(values);

    return fetch('live_interviews', { method: 'POST', data: values })
      .then(response => {
        dispatch(loading(false));
        dispatch(success(response.data));
      })
      .catch(e => {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          let errorText = e.errors._error.replace(/candidateInvitations\[\d+]\./g, '');
          dispatch(error(errorText));
          throw e;
        }

        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(error(null));
    dispatch(success(null));
    dispatch(resetJobsForSelect());
  };
}
