import {fetchJSON} from "../../utils/dataAccess";

export function loadCompanyUsersForSelect(valueProperty = '@id') {
    return dispatch => {
        dispatch(loading(true));
        fetchJSON('/company_users?pagination=false').then((users) => {
            dispatch(companyUsersLoaded(users['hydra:member'].map(
                function (user) {
                    return {
                        'value': user[valueProperty],
                        'text': `${user.firstName} ${user.lastName} (${user.mainRole})`,
                    }
                }
            )));
            dispatch(loading(false));
        }).catch(e => {
            dispatch(loading(false));
            dispatch(error(e.message));
        })
    };
}

export function reset() {
  return {type: 'COMPANY_USERS_FOR_SELECT_RESET'};
}

export function loading(loading) {
    return {type: 'COMPANY_USERS_FOR_SELECT_LOADING', loading};
}

export function error(error) {
    return {type: 'COMPANY_USERS_FOR_SELECT_ERROR', error};
}

export function companyUsersLoaded(retrieved) {
    return {type: 'COMPANY_USERS_FOR_SELECT_RETRIEVED', retrieved};
}
