import {handleAuthentication, login} from "../../utils/auth";
import {fetch} from "../../utils/dataAccess";

export function loginInvitedUser(email, password) {
  return dispatch => {
    dispatch(loggingIn(true));
    dispatch(error(null));
    login(email, password)
      .then(res => {
        handleAuthentication(res.data, false);
        dispatch(loggingIn(false));
        dispatch(loggedIn(true));
      }).catch(e => {
        dispatch(loggingIn(false));
        dispatch(loggedIn(false));
        dispatch(error(e.message));
      });
  }
}

export function loginAfterRegistration(data) {
  return dispatch => {
    if (data && data.token) {
      handleAuthentication(data, false);
      dispatch(loggingIn(true));
    }
  }
}

export function checkIfAccountExists(loggedIn, invitation){
  return dispatch => {
    dispatch(checkingAccountExists(true));
    if (loggedIn) {
      dispatch(accountExists(true));
      dispatch(checkingAccountExists(false));
      return;
    }

    if (!invitation) {
      dispatch(accountExists(false));
      dispatch(checkingAccountExists(false));
      return;
    }

    if (invitation && invitation.companyCandidate) {
      dispatch(accountExists(true));
      dispatch(checkingAccountExists(false));
      return;
    }

    let emailExists = false;
    fetch('/check-email', {
      data: {email: invitation.email},
      method: 'POST',
    }).then((resp) => {
      const data = resp.data || {};
      emailExists = !!data.exists;
    })
    .catch(e => {
      emailExists = false;
    })
    .finally(() => {
      dispatch(accountExists(emailExists))
      dispatch(checkingAccountExists(false));
    });
  }
}

function checkingAccountExists(checking) {
  return {type: 'LIVEINTERVIEW_PAGE_AUTH_ACCOUNT_EXISTS_CHECKING', checking}
}

function accountExists(exists) {
  return {type: 'LIVEINTERVIEW_PAGE_AUTH_ACCOUNT_EXISTS', exists}
}

function loggingIn(loggingIn) {
  return {type: 'LIVEINTERVIEW_PAGE_AUTH_LOGGING_IN', loggingIn}
}

function loggedIn(loggedIn) {
  return {type: 'LIVEINTERVIEW_PAGE_AUTH_LOGGED_IN', loggedIn}
}

function error(error) {
  return {type: 'LIVEINTERVIEW_PAGE_AUTH_ERROR', error}
}
