import React from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Home from "@material-ui/icons/Home";
import Business from "@material-ui/icons/Business";
import AccountBalance from "@material-ui/icons/AccountBalance";
import LocationCity from "@material-ui/icons/LocationCity";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import resumeDistributionPageStyle from "assets/jss/material-dashboard-pro-react/views/resumeDistributionPageStyle.jsx";

class ResumeDistributionPage extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">

        </GridContainer>
      </div>
    );
  }
}

ResumeDistributionPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(resumeDistributionPageStyle)(ResumeDistributionPage);
