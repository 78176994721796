import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { retrieve as retrieveCompany, reset as resetCompany } from '../../actions/Company/show';
import { del } from '../../actions/Company/delete';
import { list, reset } from '../../actions/Subscription/list';
import { usage, reset as resetUsage } from '../../actions/Subscription/usage';
import { enableAutoRenew, reset as enableAutoRenewReset } from '../../actions/Subscription/enableAutoRenew';
import { disableAutoRenew, reset as disableAutoRenewReset } from '../../actions/Subscription/disableAutoRenew';
import { activate } from '../../actions/Company/activate';
import { deactivate } from '../../actions/Company/deactivate';
import {list as paymentMethodsList, reset as paymentMethodsListReset} from '../../actions/PaymentMethod/list';
import "./Subscription.scss";
import {generateLimitationLabel, generateLimitationUsageLabel} from "../../views/Subscription/PlanCard";
import {
  formatPlanPrice, formatPrice,
  PREVIEW_PERIOD_ANNUAL
} from "../../utils/plan";
import {IRItoID} from "../../utils/dataAccess";
import {applyToUrl} from "../../utils/urls";
import moment from "moment";
import {CustomSwitch} from "../../views/Components/CusomSwitch";
import IsGranted from "../IsGranted";
import {handleSwitchUser} from "../SwitchUser/SwitchUser";
import {PaymentMethodLogo} from "../../views/Subscription/PaymentMethodCard";
import SubscriptionTransactions from "./SubscriptionTransactions";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import AutoPayTurnOffModal from "./Modal/AutoPayTurnOffModal";

export function getCompanySubscriptionUrl(decodedCompanyIRI)  {
  const companyId = IRItoID(decodeURIComponent(decodedCompanyIRI));
  return applyToUrl('subscriptions', {'company___id': companyId});
}

export function getPaymentMethodsForCompanyUrl(decodedCompanyIRI)  {
  const companyId = IRItoID(decodeURIComponent(decodedCompanyIRI));
  return applyToUrl('payment_methods', {'company___id': companyId});
}

class Subscription extends Component {
  state = {
    actionsDropdownOpen: false,
    showDisableAutopayModal: false,
  }

  componentDidMount() {
    this.props.list(getCompanySubscriptionUrl(this.props.match.params.id));
    this.props.usage(IRItoID(decodeURIComponent(this.props.match.params.id)));
    this.props.retrieveCompany(decodeURIComponent(this.props.match.params.id));
    this.props.paymentMethodsList(getPaymentMethodsForCompanyUrl(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
    this.props.resetUsage(this.props.eventSource);
    this.props.enableAutoRenewReset(this.props.eventSource);
    this.props.disableAutoRenewReset(this.props.eventSource);
    this.props.resetCompany(this.props.eventSource);
    this.props.paymentMethodsListReset(this.props.eventSource);
  }

  toggleAutoPay = (subscription) => {
    const callback = () => this.props.list(getCompanySubscriptionUrl(this.props.match.params.id));

    if (subscription['autoRenew'] && !this.state.showDisableAutopayModal) {
      this.setState({showDisableAutopayModal: true});
      return;
    }

    if (subscription['autoRenew']) {
      this.props.disableAutoRenew(subscription, callback);
      this.setState({showDisableAutopayModal: false});
    } else {
      this.props.enableAutoRenew(subscription, callback);
    }
  }

  reactivatePlan = (subscription) => {
    this.props.history.push({
      pathname: `${encodeURIComponent(this.props.match.params.id)}/payment-methods`,
      state: {
        plan: subscription['plan'],
        period: subscription['planDuration'],
        backToSubscription: true,
      }
    });
  }

  del = () => {
    if (window.confirm('Are you sure you want to delete this Company?'))
      this.props.del(this.props.company);
  };

  toggleCompanyStatus = () => {
    const {company} = this.props;
    const callback = () => this.props.retrieveCompany(company['@id']);
    if (company['status'] === 'active') {
      this.props.deactivate(company, callback);
    } else {
      this.props.activate(company, callback);
    }
  }

  handleCloseDropdown = (e) => {
    if (e.target.closest('.actions-dropdown')) {
      return;
    }

    this.setState({actionsDropdownOpen: false});
  }

  render() {
    if (this.props.deleted)
      this.props.history.push({
        pathname: `..`,
        state: {successMessage: 'Company successfully deleted.'}
      });

    const subscription = this.props.retrieved && this.props.retrieved['hydra:member'].length > 0 ?
      this.props.retrieved['hydra:member'][0] :
      null;

    const defaultPaymentMethod =
      this.props.paymentMethodsRetrieved &&
      this.props.paymentMethodsRetrieved['hydra:member'].length > 0 &&
      this.props.paymentMethodsRetrieved['hydra:member'][0]['isDefault'] ?
      this.props.paymentMethodsRetrieved['hydra:member'][0] :
      null;

    const company = this.props.company;
    const hasPaymentMethods = this.props.paymentMethodsRetrieved && this.props.paymentMethodsRetrieved['hydra:member'].length > 0;

    return (
      <div className={"partner-company-subscription"}>
        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}

        <Link to={"../"} className={"btn btn-primary mb-2"}>Back to Company List</Link>
        <h2>{subscription ? subscription['company']['name'] : ''}</h2>

        <ClickAwayListener onClickAway={this.handleCloseDropdown}>
          <>
            <button className={"actions"} onClick={() => this.setState({actionsDropdownOpen: !this.state.actionsDropdownOpen})}>ACTIONS</button>
            {this.state.actionsDropdownOpen && (
              <div className={'actions-dropdown'}>
                <div className={"actions-dropdown-header"}>MANAGE COMPANY</div>
                <ul>
                  {company && (
                    <>
                      <li>
                        <IsGranted action="impersonate" object={company['companyAdmin']}>
                          <a onClick={() => handleSwitchUser(company['companyAdmin']['id'])}>Log In As Administrator</a>
                        </IsGranted>
                        {!(company['companyAdmin'] && company['companyAdmin']['@permissions'].includes('impersonate')) && (
                          <span className={"disabled"}>Log In As Administrator</span>
                        )}
                      </li>
                      <li>
                        <Link to={"../edit/" + this.props.match.params.id}>Edit</Link>
                      </li>
                      <li onClick={this.toggleCompanyStatus}>
                        {this.props.company['status'] === 'active' ? 'Block' : 'Activate'}
                        {this.props.companyStatusLoading && (<i className="fa fa-spinner fa-spin" />)}
                      </li>
                      <IsGranted action="delete" object={company}>
                        <li>
                          <a onClick={this.del}>Delete</a>
                        </li>
                      </IsGranted>
                    </>
                  )}
                </ul>
                <div className={"actions-dropdown-separator"}>MANAGE SUBSCRIPTION</div>
                <ul>
                  <li><Link to={this.props.match.params.id + "/plan-select"}>Change Plan</Link></li>
                  {subscription && (
                    <li onClick={() => this.toggleAutoPay(subscription)}>
                      {subscription['autoRenew'] ? 'Disable' : 'Enable'} Autopay
                      {this.props.saveAutoRenewLoading && (
                        <i className={"fa fa-spin fa-spinner ml-1"} />
                      )}
                    </li>
                  )}
                </ul>
              </div>
            )}
            </>
        </ClickAwayListener>

        <div className={"company-subscription-tabs"}>
          <Link className={"company-subscription-tabs-details"} to={"../show/" + this.props.match.params.id}>Details</Link>
          <p>Subscription</p>
        </div>

        {subscription && (
          <>
            <div className={"plan-info"}>
              <div className={"info"}>
                <p className={'plan-label'}>PLAN</p>
                <div className={'plan-name'}>{subscription['plan']['name']}</div>
                {subscription['active'] ? (
                  <div className={'subscription-status active'}>Active</div>
                ) : (
                  <div className={'subscription-status inactive'}>Inactive</div>
                )}
                <div className={'plan-price'}>
                  {subscription['planDuration'] === PREVIEW_PERIOD_ANNUAL ? (
                    <>{formatPlanPrice(subscription['plan']['annualPrice'])} / Year</>
                  ) : (
                    <>{formatPlanPrice(subscription['plan']['monthlyPrice'])} / Month</>
                  )}
                </div>
                <div className={"clearfix"} />
                <div className={"next-payment-date"}>Your next payment will process on {moment(subscription['endPeriod']).format("MM/DD/YYYY")}</div>

                <div className={"subscription-limitations"}>
                  <p><strong>Plan Details</strong></p>

                  {subscription['plan']['planLimitations'].map(planLimitation => (!planLimitation['hidden'] && (
                    <div className={"plan-card-limitation"} key={planLimitation['@id']}>
                      <i className={"fa fa-check"} />
                      <span>{generateLimitationLabel(planLimitation)}</span>
                    </div>
                  )))}
                </div>
                {this.props.usageData && (
                  <div className={"subscription-limitations-usages"}>
                    <p><strong>Current Usage</strong></p>
                    {subscription['plan']['planLimitations'].map(planLimitation => (!planLimitation['hidden'] && (
                      <p key={planLimitation['@id']} className={"limitation-usage-label"}>{generateLimitationUsageLabel(planLimitation, this.props.usageData)}</p>
                    )))}
                  </div>
                )}
              </div>

              <div className={"subscription-buttons"}>
                {subscription['active'] ? (
                  <Link to={this.props.match.params.id + "/plan-select"} className={"subscription-button-blue"}>Change Plan</Link>
                ) : (
                  <>
                    <button className={"subscription-button-blue"} onClick={() => this.reactivatePlan(subscription)}>Reactivate</button>
                    <Link to={this.props.match.params.id + "/plan-select"} className={"subscription-button-blue-outlined"}>Select A New Plan</Link>
                  </>
                )}
              </div>
            </div>
            <div className={"subscription-account-information"}>
              <p className={"account-information-label"}>ACCOUNT INFORMATION</p>

              <table>
                <tbody>
                  <tr>
                    <td className={"name"}>Subscription Period</td>
                    <td className={"value"}>{moment(subscription['startPeriod']).format("MM/DD/YYYY")} - {moment(subscription['endPeriod']).format("MM/DD/YYYY")}</td>
                  </tr>
                  <tr>
                    <td className={"name"}>Last Paid</td>
                    <td className={"value"}>{formatPrice(subscription['lastPaymentAmount'])} on {moment(subscription['lastPaymentDate']).format("MM/DD/YYYY")}</td>
                  </tr>
                  {subscription['active'] && (
                    <tr>
                      <td className={"name"}>Expires On</td>
                      <td className={"value"}>{moment(subscription['endPeriod']).format("MM/DD/YYYY")}</td>
                    </tr>
                  )}
                  {defaultPaymentMethod && (
                    <tr>
                      <td className={"name"}>Default Payment Method</td>
                      <td className={"value"}><PaymentMethodLogo brand={defaultPaymentMethod['brand']} /> ending {defaultPaymentMethod['lastFour']}</td>
                    </tr>
                  )}
                  {subscription && hasPaymentMethods && (
                    <tr>
                      <td className={"name"}>
                        Enable autopay
                        {this.props.saveAutoRenewLoading && (
                          <i className={"fa fa-spin fa-spinner ml-1"} />
                        )}
                      </td>
                      <td className={"value"}>
                        <CustomSwitch
                          color={"primary"}
                          value={subscription['autoRenew']}
                          onClick={() => this.toggleAutoPay(subscription)}
                          disabled={this.props.loading}
                        />
                        {this.state.showDisableAutopayModal && (
                          <AutoPayTurnOffModal
                            handleClose={() => this.setState({showDisableAutopayModal: false})}
                            handleAccept={() => this.toggleAutoPay(subscription)}
                            subscription={subscription}
                          />
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

            </div>
          </>
        )}
        {!this.props.loading && !subscription && (
          <div className={"plan-info"}>
            <p className={"no-plan-selected"}>NO SELECTED PLAN</p>
            <div className={"subscription-buttons"}>
             <Link to={this.props.match.params.id + "/plan-select"} className={"subscription-button-blue-outlined"}>Select A New Plan</Link>
            </div>
          </div>
        )}

        <SubscriptionTransactions companyId={this.props.match.params.id} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.subscription.list.retrieved,
  error: state.subscription.list.error || state.subscription.usage.error || state.subscription.enableAutoRenew.error || state.subscription.disableAutoRenew.error ||
    state.company.show.error || state.company.del.error || state.company.activate.activateError || state.company.deactivate.deactivateError,
  loading: state.subscription.list.loading || state.subscription.usage.loading || state.company.show.loading || state.company.del.loading,
  saveAutoRenewLoading: state.subscription.enableAutoRenew.enableAutoRenewLoading || state.subscription.disableAutoRenew.disableAutoRenewLoading,
  usageData: state.subscription.usage.retrieved,
  enableAutoRenewSuccess: state.subscription.enableAutoRenew.enableAutoRenewSuccess,
  disableAutoRenewSuccess: state.subscription.disableAutoRenew.disableAutoRenewSuccess,
  company: state.company.show.retrieved,
  deleted: state.company.del.deleted,
  companyStatusLoading: state.company.activate.activateLoading || state.company.deactivate.deactivateLoading,
  paymentMethodsRetrieved: state.paymentmethod.list.retrieved,
});

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource)),
  usage: companyId => dispatch(usage(companyId)),
  resetUsage: eventSource => dispatch(resetUsage(eventSource)),
  enableAutoRenew: (subscription, callback) => dispatch(enableAutoRenew(subscription, callback)),
  enableAutoRenewReset: eventSource => dispatch(enableAutoRenewReset(eventSource)),
  disableAutoRenew: (subscription, callback) => dispatch(disableAutoRenew(subscription, callback)),
  disableAutoRenewReset: eventSource => dispatch(disableAutoRenewReset(eventSource)),
  retrieveCompany: id => dispatch(retrieveCompany(id)),
  resetCompany: eventSource => dispatch(resetCompany(eventSource)),
  del: item => dispatch(del(item)),
  activate: (item, callback) => dispatch(activate(item, callback)),
  deactivate: (item, callback) => dispatch(deactivate(item, callback)),
  paymentMethodsList: (page) => dispatch(paymentMethodsList(page)),
  paymentMethodsListReset: (eventSource) => dispatch(paymentMethodsListReset(eventSource)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Subscription);
