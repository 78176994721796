import React, {Component} from 'react';
import {
  createAudioPublisher, getLastAudioAnswerForVideoQuestion, getQuestionRecorderText,
  getRecordAttemptsForVideoQuestion,
  hasAllowedReRecordAttemptsForVideoQuestion,
  incrementRecordAttemptsForVideoQuestion,
  isQuestionHasRecordAttemptLimit, setEndThinkTimeForVideoQuestion, setLastAudioAnswerForVideoQuestion
} from "../../utils/onDemandInterview";
import {connect} from "react-redux";
import AudioRecorder from "../AudioRecorder/AudioRecorder";
import PropTypes from "prop-types";
import DesktopRecorderLimitations from "./DesktopRecorderLimitations";
import ChangeDeviceNotification from "../VideoControl/ChangeDeviceNotification";
import ChangeDeviceButtons from "./ChangeDeviceButtons";
import {getOpenViduSingleton} from "../../utils/OpenViduInstance";
import {filterAudioDevices} from "../../utils/webRtc";
import {closeStreamManager} from "../../utils/streamManager";
import Button from "../CustomButtons/Button";
import MobileRecordingTime from "./MobileRecordingTime";
import {answerError} from "../../actions/OnDemandInterviewPage/answer";

class VideoQuestionAnswerAudioRecorder extends Component {
  static propTypes = {
    onAnswer: PropTypes.func.isRequired,
    question: PropTypes.object.isRequired,
    cancelAnswer: PropTypes.func,
    thinkTimeCountedDown: PropTypes.bool.isRequired,
    thinkTimeLeft: PropTypes.object,
    onRecordingStarted: PropTypes.func.isRequired,
    startRecordingDisabled: PropTypes.bool,
  };

  static defaultAnswerState = {
    audioAnswer: null,
    recordingStartTime: null,
    recording: false,
    recordingStopped: false,
  };

  constructor(props) {
    super(props);
    this.recorder = React.createRef();
    this.state = {
      ...VideoQuestionAnswerAudioRecorder.defaultAnswerState,
      streamManager: null,
      audioDevices: {},
    }
  }

  componentDidMount() {
    const {question} = this.props;
    this.createStreamManager();

    const audioAnswer = getLastAudioAnswerForVideoQuestion(question);
    if (audioAnswer) {
      this.setAudioAnswer(audioAnswer);
    }
    this.setState({recordAttempts: getRecordAttemptsForVideoQuestion(question)});
  }

  createStreamManager() {
    return createAudioPublisher()
      .then((publisher) => {
        const OV = getOpenViduSingleton();
        return OV.getDevices().then(devices => {
          this.setState({
            streamManager: publisher,
            audioDevices: filterAudioDevices(devices),
          });
          return publisher;
        });
      })
      .catch((e) => {
        this.setState({streamManager: null})
        throw e;
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.question && this.props.question && prevProps.question.id !== this.props.question.id) {
      this.onQuestionChange(this.props.question, prevProps.question);
    }
  }

  componentWillUnmount() {
    closeStreamManager(this.state.streamManager);
  }

  onQuestionChange = (newQuestion, oldQuestion) => {
    this.setState({
      ...VideoQuestionAnswerAudioRecorder.defaultAnswerState,
      recordAttempts: getRecordAttemptsForVideoQuestion(newQuestion),
    });
  }

  onRecordingAgain = () => {
    this.setState({
      recording: false,
      recordingStopped: false,
    })
    this.setAudioAnswer(null);
  }

  onRecorded = (audioAnswer) => {
    const {question} = this.props;
    setLastAudioAnswerForVideoQuestion(question, audioAnswer);
    if (isQuestionHasRecordAttemptLimit(question)) {
      const attempts = incrementRecordAttemptsForVideoQuestion(question);
      this.setState({recordAttempts: attempts});
    }
    this.setAudioAnswer(audioAnswer);
  };

  setAudioAnswer = (audioAnswer) => {
    this.setState({audioAnswer: audioAnswer})
    this.props.onAnswer(audioAnswer);
  };

  getReRecordButtonText = () => {
    const {question} = this.props;
    const {recordAttempts} = this.state;

    let text = 'Re-record';
    if (isQuestionHasRecordAttemptLimit(question)) {
      text = text + ' (' + recordAttempts + '/' + question.maxAnswerRecordingRetries + ')';
    }

    return text;
  }

  onRecordingStart = (recordingStartTime) => {
    const {question} = this.props;

    if (hasAllowedReRecordAttemptsForVideoQuestion(question)) {
      if (question && question.answerTimeLimitMinutes) {
        recordingStartTime.setMinutes(recordingStartTime.getMinutes() + question.answerTimeLimitMinutes);
      }
      if (question && question.answerTimeLimitSeconds) {
        recordingStartTime.setSeconds(recordingStartTime.getSeconds() + question.answerTimeLimitSeconds);
      }

      if (question) {
        setEndThinkTimeForVideoQuestion(question);
      }

      this.setState({
        recordingStartTime: recordingStartTime,
        recording: true,
        recordingStopped: false,
      });

      this.props.onRecordingStarted(recordingStartTime);
    }
  };

  onRecordingEnd = () => {
    this.setState({
      recordingStartTime: null,
      recording: false,
      recordingStopped: true,
    });
  };

  stopRecording = () => {
    this.setState({
      recordingStartTime: null,
    });
    this.recorder.current.stopRecording();
  }

  getAdditionalControlButtons = () => {
    const {cancelAnswer} = this.props;
    let buttons = {};
    if (cancelAnswer) {
      const selectAnotherAnswerButton = (
        <Button className={"control-button select-another-answer-type-button"} onClick={cancelAnswer}>
          Select Another Answer Type
        </Button>
      );
      buttons['startRecordingAdditionalButtons'] = selectAnotherAnswerButton;
      buttons['noVideoButtons'] = selectAnotherAnswerButton;
    }

    return buttons;
  }

  render() {
    const {streamManager, audioDevices, audioAnswer, recordingStartTime, recordAttempts, recording, recordingStopped} = this.state;
    const {question, screenSize, onAnswerError} = this.props;
    const smallScreen = !screenSize.md;

    let audioUrl;
    if (audioAnswer) {
      audioUrl = audioAnswer.localUrl ? audioAnswer.localUrl : audioAnswer.audioUrl;
    }
    return (
      <>
        {!smallScreen && (
          <DesktopRecorderLimitations
            question={question}
            stopRecording={this.stopRecording}
            recordingStartTime={recordingStartTime}
            recordingAttempts={recordAttempts}
          />
        )}
        <div className={'audio-answer-recorder'}>
          {this.props.allowToChangeDevice && (
            <ChangeDeviceButtons
              streamManager={streamManager}
              visible={!recording && !audioAnswer && !recordingStopped}
              audioDevices={audioDevices}
            />
          )}
          {smallScreen && (
            <>
              <MobileRecordingTime question={question} stopRecording={this.stopRecording} recordingStartTime={recordingStartTime} />
              <ChangeDeviceNotification streamManager={streamManager} notificationContainerClass={'on-demand-interview-video-notification'}/>
            </>
          )}
          <AudioRecorder
            streamManager={streamManager}
            socketRecordingType={'on-demand-interview-audio-answer'}
            recordingUrl={audioUrl}
            ref={this.recorder}
            onRecordingStart={this.onRecordingStart}
            onRecordingStop={this.onRecordingEnd}
            onRecordingAgain={this.onRecordingAgain}
            onRecorded={this.onRecorded}
            onError={(errorMessage) => onAnswerError(errorMessage)}
            allowedToRecordAnswer={hasAllowedReRecordAttemptsForVideoQuestion(question)}
            audioText={getQuestionRecorderText(question)}
            messages={{
              recordAgain: this.getReRecordButtonText(),
            }}
            additionalButtons={this.getAdditionalControlButtons()}
            autostartRecording={this.props.thinkTimeCountedDown}
            startRecordingDisabled={this.props.startRecordingDisabled}
          />
          {this.props.thinkTimeLeft && !this.state.recording && !this.state.recordingStopped && (
            <h5 className={"audio-answer-think-time"}>
              Recording begins in<br/>
              <span className={this.props.thinkTimeFiveSecondsLeft ? 'text-warning' : undefined}>{this.props.thinkTimeLeft}</span>
            </h5>
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  screenSize: state.screen.size,
});

const mapDispatchToProps = dispatch => ({
  onAnswerError: (message) => dispatch(answerError(message)),
});

VideoQuestionAnswerAudioRecorder = connect(
  mapStateToProps, mapDispatchToProps
)(VideoQuestionAnswerAudioRecorder);

export default VideoQuestionAnswerAudioRecorder;
