import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import {renderTextField} from "../../utils/form";

const validate = values => {
    const errors = {};
    if (!values.currentPassword) {
        errors.currentPassword = 'Please enter current password';
    }
    if (!values.newPassword) {
        errors.newPassword = 'Please enter new password';
    }
    if (!values.repeatPassword) {
        errors.repeatPassword = 'Please repeat password';
    } else if (values.newPassword !== values.repeatPassword) {
        errors.repeatPassword = 'New password and repeat password should be the same';
    }

    return errors;
};

class ChangePasswordForm extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    handleSubmitForm(data) {
        const {onSubmit, reset} = this.props;
        return onSubmit(data).then(() => {
            reset();
        });
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <form name="change_password" onSubmit={handleSubmit(this.handleSubmitForm.bind(this))}>
                <Field
                    component={renderTextField}
                    name='currentPassword'
                    type="password"
                    placeholder="Current Password"
                    required={true}
                />
                <Field
                    component={renderTextField}
                    name='newPassword'
                    type="password"
                    placeholder="New Password"
                    required={true}
                />
                <Field
                    component={renderTextField}
                    name='repeatPassword'
                    type="password"
                    placeholder="Repeat Password"
                    required={true}
                />
                <button type="submit" className="btn btn-success">
                    Change Password
                </button>
            </form>
        );
    }
}

export default reduxForm({
    form: 'change_password',
    validate
})(ChangePasswordForm);

