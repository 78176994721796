import React, {Component} from "react";
import ResourceFileUpload from "../../views/Components/ResourceFileUpload";
import {Field} from "redux-form";

class CandidateResumeField  extends Component {
  render() {
    return (<Field
      component={ResourceFileUpload}
      name="resume"
      label={false}
      buttonText="Upload Resume"
      filePropertyPath="file"
      uploadPropertyPath="uploadedFile"
      url="/resume/upload"
      accept=".pdf, .txt, .rtf, .doc, .docx, .odt, .jpg, .jpeg, .png, .tiff"
      normalize={v => {
        if (v === '') {
          return null;
        }

        return v;
      }}
    />)
  }
}

export default CandidateResumeField;
