import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { list, reset, filter, sort } from '../../actions/CompanyCandidate/list';
import {handleListUpdate, pagination, renderLinks} from "../../utils/entityList";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Moment from 'react-moment';
import filtersStorage from '../../utils/filtersStorage';
import FilterForm from "./FilterForm";
import {SortableTableCells} from "../../utils/filterForm";
import IsGranted from "../IsGranted";
import TableFooter from "@material-ui/core/TableFooter";
import {getRoutePage} from "../../utils/routes";
import PhoneNumber from "../../views/Components/PhoneNumber";

class CompanyCandidateList extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    filter: PropTypes.func.isRequired,
    filtersData: PropTypes.object,
    sort: PropTypes.func.isRequired,
    sortData: PropTypes.object
  };

  componentDidMount() {
    const storageName = 'company_candidate';
    const filters = filtersStorage.get(storageName, null);
    const sort = filtersStorage.getSort(storageName);
    if (filters !== null || sort !== null) {
      this.props.filter(filters);
      this.props.sort(sort);
    } else {
      handleListUpdate('company_candidates', this.props);
    }
  }

  componentDidUpdate(prevProps) {
    handleListUpdate('company_candidates', this.props, prevProps);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    return (
      <div>
        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}

        <FilterForm
          initialValues={this.props.filtersData}
          onSubmit={this.props.filter}
          resetFilter={this.props.filterReset}
          parentLoading={this.props.loading}
        />

        <div style={{'overflowX': 'auto'}}>
          <Table className="table table-responsive table-striped table-hover mb-0">
            <TableHead>
              <TableRow>
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                  'id': 'ID',
                  'firstName': 'Candidate',
                  'phoneNumber': 'Mobile Number',
                  'email': 'Email Address',
                }} />
                <TableCell>Industry</TableCell>
                <TableCell>Occupation</TableCell>
                <TableCell>Specialty</TableCell>
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                  'createdAt': 'Created At',
                  'updatedAt': 'Updated At',
                }} />
                <TableCell colSpan={1} />
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.retrieved &&
                this.props.retrieved['hydra:member'].map(item => (
                  <TableRow key={item['@id']}>
                    <TableCell scope="row">
                      {renderLinks('candidates', item, (candidate) => candidate['id'], true)}
                    </TableCell>
                    <TableCell>
                      {renderLinks('candidates', item, (item) => item.firstName + ' '+ item.lastName, true)}
                    </TableCell>
                    <TableCell>
                      <PhoneNumber phone={item['phoneNumber']} formattedPhone={item['formattedPhoneNumber']} />
                    </TableCell>
                    <TableCell>{item['email']}</TableCell>
                    <TableCell>{item['industryName']}</TableCell>
                    <TableCell>{item['occupationName']}</TableCell>
                    <TableCell>{item['specialtyName']}</TableCell>
                    <TableCell>{item['createdAt'] && (<Moment>{item['createdAt']}</Moment>)}</TableCell>
                    <TableCell>{item['updatedAt'] && (<Moment>{item['updatedAt']}</Moment>)}</TableCell>
                    <TableCell>
                      <IsGranted action='show' object={item}>
                        <Link to={`show/${encodeURIComponent(item['@id'])}`}>
                          <span className="fa fa-eye" aria-hidden="true" />
                          <span className="sr-only">Show</span>
                        </Link>
                      </IsGranted>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>

        <Table className="table table-responsive no-border">
          <TableFooter>
            <TableRow>
              {pagination(this.props.retrieved, getRoutePage(this.props.match))}
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    retrieved,
    loading,
    error,
    filtersData,
    sortData,
  } = state.companyCandidate.list;
  return { retrieved, loading, error, filtersData, sortData };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: () => dispatch(reset()),
  filter: (filtersData) => dispatch(filter(filtersData)),
  filterReset: () => dispatch(filter(null)),
  sort: (sortData) => dispatch(sort(sortData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyCandidateList);
