import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {list, reset, filter, sort} from '../../actions/LiveInterview/allInterviewsList';
import {resetDelete} from '../../actions/LiveInterview/delete';
import filtersStorage from '../../utils/filtersStorage';
import {handleListUpdate, pagination, renderLinks} from "../../utils/entityList";
import {getRoutePage} from "../../utils/routes";
import AllInterviewsFilterForm from "./AllInterviewsFilterForm";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {SortableTableCells} from "../../utils/filterForm";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Moment from "react-moment";
import TableFooter from "@material-ui/core/TableFooter";
import Table from "@material-ui/core/Table";
import IsGranted from "../IsGranted";
import {Link} from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import { del } from '../../actions/LiveInterview/delete';
import { archive, reset as archiveReset } from '../../actions/LiveInterview/archive';
import { activate, reset as activateReset } from '../../actions/LiveInterview/activate';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Button from "../CustomButtons/Button";

const style = {
  filterSetsContainer: {
    margin: '10px',
    '& > span': {
      marginRight: '10px;',
      textTransform: 'uppercase',
      cursor: 'pointer',
      borderBottom: '1px dashed #2b7887',
      color: '#2b7887',
      '&:hover': {
        color: '#50bbd0',
        borderBottom: '1px dashed #50bbd0',
      },
      '&.active': {
        fontWeight: 'bold',
      }
    }
  },
};

export const FILTER_SET_ALL = 'all';
export const FILTER_SET_UPCOMING = 'upcoming';
export const FILTER_SET_COMPLETED = 'completed';
export const FILTER_SET_ACTIVE = 'active';
export const FILTER_SET_ARCHIVED = 'archived';

class LiveInterviewAll extends Component {
  static storageName = 'company_live_interview_all';

  constructor(props) {
    super(props);

    this.state = {
      filterSet: this.determineFiltersSetName(),
    }
  }

  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    filter: PropTypes.func.isRequired,
    filtersData: PropTypes.object,
    sort: PropTypes.func.isRequired,
    sortData: PropTypes.object
  };

  componentDidMount() {
    const filters = filtersStorage.get(LiveInterviewAll.storageName, null);
    const sort = filtersStorage.getSort(LiveInterviewAll.storageName);
    if (filters !== null || sort !== null) {
      this.props.filter(filters);
      this.props.sort(sort);
    } else {
      handleListUpdate('live_interviews', this.props);
    }
  }

  componentDidUpdate(prevProps) {
    handleListUpdate('live_interviews', this.props, prevProps);

    if (prevProps.deletedItem !== this.props.deletedItem ||
      prevProps.archivedItem !== this.props.archivedItem ||
      prevProps.activatedItem !== this.props.activatedItem
    ) {
      this.props.resetDelete();
      handleListUpdate('live_interviews', this.props);
    }
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
    this.props.activateReset(this.props.eventSource);
    this.props.archiveReset(this.props.eventSource);
  }

  getDefaultFiltersForFilterSet = (filterSet) => {
    switch (filterSet) {
      case FILTER_SET_ALL:
        return null;
      case FILTER_SET_UPCOMING:
        return {'scheduledStartTime__after__': moment().format(Moment.globalFormat)};
      case FILTER_SET_COMPLETED:
        return {'completedAt__exists__': true};
      case FILTER_SET_ACTIVE:
        return {'archivedAt__exists__': false};
      case FILTER_SET_ARCHIVED:
        return {'archivedAt__exists__': true};
      default:
        throw new Error('Unknown filter set');
    }
  };

  selectFilterSet = (filterSet) => {
    this.props.filter(this.getDefaultFiltersForFilterSet(filterSet));
    this.setState({filterSet: filterSet})
  }

  determineFiltersSetName = () => {
    const filters = filtersStorage.get(LiveInterviewAll.storageName, null);

    if (null === filters) {
      return FILTER_SET_ALL;
    }

    if (typeof filters === 'object' && Object.keys(filters).length === 1) {
      if ('scheduledStartTime__after__' in filters) {
        return FILTER_SET_UPCOMING;
      }

      if ('completedAt__exists__' in filters && filters['completedAt__exists__'] === true) {
        return FILTER_SET_COMPLETED;
      }

      if ('archivedAt__exists__' in filters && filters['archivedAt__exists__'] === false) {
        return FILTER_SET_ACTIVE;
      }

      if ('archivedAt__exists__' in filters && filters['archivedAt__exists__'] === true) {
        return FILTER_SET_ARCHIVED;
      }
    }

    return FILTER_SET_ALL;
  };

  filter = (values) => {
    const defaultValues = this.getDefaultFiltersForFilterSet(this.state.filterSet);
    values = {...values, ...defaultValues};

    this.props.filter(values);
  }

  editRedirect = (e, item) => {
    e.preventDefault();

    this.props.history.push({
      pathname: `../live-interview-schedules/edit/${encodeURIComponent(item['@id'])}`,
      state: {backToAll: true}
    });
  }

  del = (e, item) => {
    e.preventDefault();

    if (!this.props.anyLoading) {
      if (window.confirm('Are you sure you want to delete this item?'))
        this.props.del(item);
    }
  };

  archive = (e, item) => {
    e.preventDefault();
    if (!this.props.anyLoading) {
      if (window.confirm('Are you sure you want to archive this item?'))
        this.props.archive(item);
    }
  };

  activate = (e, item) => {
    e.preventDefault();

    if (!this.props.anyLoading) {
      this.props.activate(item)
    }
  };

  showRedirect = (e, item) => {
    e.preventDefault();

    if (item['completedAt']) {
      this.props.history.push({
        pathname: `../live-interviews/show/${encodeURIComponent(item['@id'])}`,
        state: {backToAll: true}
      });
    } else {
      this.props.history.push({
        pathname: `../live-interview-schedules/show/${encodeURIComponent(item['@id'])}`,
        state: {backToAll: true}
      });
    }
  }

  render() {
    return (
      <div>
        {this.props.anyLoading && (
          <div className="alert alert-info">Loading...</div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}
        {this.props.deleteError && (
          <div className="alert alert-danger">{this.props.deleteError}</div>
        )}
        {this.props.activateError && (
          <div className="alert alert-danger">{this.props.activateError}</div>
        )}
        {this.props.archiveError && (
          <div className="alert alert-danger">{this.props.archiveError}</div>
        )}

        <div className={'mb-4'} style={{maxWidth: 920}}>
          <Paper square>
            <Tabs
              value={this.state.filterSet}
              onChange={(e, value) => this.selectFilterSet(value)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab className={"live-interviews-all-tabs"} label="All Interviews" value={FILTER_SET_ALL} />
              <Tab className={"live-interviews-all-tabs"} label="Upcoming Interviews" value={FILTER_SET_UPCOMING} />
              <Tab className={"live-interviews-all-tabs"} label="Completed Interviews" value={FILTER_SET_COMPLETED} />
              <Tab className={"live-interviews-all-tabs"} label="Active Interviews" value={FILTER_SET_ACTIVE} />
              <Tab className={"live-interviews-all-tabs"} label="Archived Interviews" value={FILTER_SET_ARCHIVED} />
            </Tabs>
          </Paper>
        </div>

        <AllInterviewsFilterForm
          initialValues={this.props.filtersData}
          onSubmit={this.filter}
          resetFilter={() => this.selectFilterSet(this.state.filterSet)}
          parentLoading={this.props.loading}
          filterSet={this.state.filterSet}
        />

        <div style={{'overflowX': 'auto'}}>
          <Table className="table table-responsive table-striped table-hover mb-0">
            <TableHead>
              <TableRow>
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                  'id': 'ID',
                  'title': 'Interview Title',
                  'job.title': 'Job',
                }}/>
                <TableCell>Candidates</TableCell>
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                  'companyUser.firstName': 'Organizer',
                  'status': 'Status',
                  'scheduledStartTime': 'Scheduled Date and Time',
                  'archivedAt': 'Archived',
                }}/>
                <TableCell colSpan={4}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.retrieved &&
              this.props.retrieved['hydra:member'].map(item => (
                <TableRow key={item['@id']}>
                  <TableCell scope="row" className="align-middle">{renderLinks('live_interviews', item, (interview) => interview['id'], true)}</TableCell>
                  <TableCell scope="row" className="align-middle">{renderLinks('live_interviews', item, (interview) => interview['title'], true)}</TableCell>
                  <TableCell scope="row" className="align-middle">{renderLinks('jobs', item['job'], (job) => job['title'], true)}</TableCell>
                  <TableCell scope="row" className="align-middle">
                    {item['candidateInvitations'].map((invitation, index) => {
                      return invitation['firstName'] + ' ' + invitation['lastName'];
                    }).join(', ')}
                    </TableCell>
                  <TableCell scope="row" className="align-middle">{renderLinks(
                    'users',
                    item['companyUser'],
                    (companyUser) => companyUser['firstName'] + ' ' + companyUser['lastName'],
                    true
                  )}</TableCell>
                  <TableCell scope="row" className="align-middle">-</TableCell>
                  <TableCell className="align-middle"><Moment format={Moment.timeFormat}>{item['scheduledStartTime']}</Moment></TableCell>
                  <TableCell scope="row" className="align-middle">{item['archivedAt'] ? 'Yes' : 'No'}</TableCell>
                  <TableCell className="align-middle">
                    <IsGranted action='show' object={item}>
                      <Link to={'#'} onClick={(e) => this.showRedirect(e, item)}>
                        <span className="fa fa-eye" aria-hidden="true"/>
                        <span className="sr-only">Show</span>
                      </Link>
                    </IsGranted>
                  </TableCell>
                  <TableCell className="align-middle">
                    <IsGranted action='edit' object={item}>
                      <Link to={'#'} onClick={(e) => this.editRedirect(e, item)}>
                        <span className="fa fa-pencil" aria-hidden="true"/>
                        <span className="sr-only">Edit</span>
                      </Link>
                    </IsGranted>
                  </TableCell>
                  <TableCell className="align-middle text-center">
                    <IsGranted action='archive' object={item}>
                      <Button disabled={this.props.anyLoading} size={'sm'} onClick={(e) => {this.archive(e, item)}}>
                        Archive
                      </Button>
                    </IsGranted>
                    <IsGranted action='activate' object={item}>
                      <Button disabled={this.props.anyLoading} size={'sm'} color={'info'} onClick={(e) => {this.activate(e, item)}}>
                        Activate
                      </Button>
                    </IsGranted>
                  </TableCell>
                  <TableCell className="align-middle">
                    <IsGranted action='delete' object={item}>
                      <Button disabled={this.props.anyLoading} size={'sm'} color={'danger'} onClick={(e) => {this.del(e, item)}}>
                        Delete
                      </Button>
                    </IsGranted>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <Table className="table table-responsive no-border">
          <TableFooter>
            <TableRow>
              {pagination(this.props.retrieved, getRoutePage(this.props.match))}
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    retrieved: state.liveinterview.allInterviewsList.retrieved,
    loading: state.liveinterview.allInterviewsList.loading,
    error: state.liveinterview.allInterviewsList.error,
    eventSource: state.liveinterview.allInterviewsList.eventSource,
    filtersData: state.liveinterview.allInterviewsList.filtersData,
    sortData: state.liveinterview.allInterviewsList.sortData,
    deleteError: state.liveinterview.del.error,
    deleteLoading: state.liveinterview.del.loading,
    deletedItem: state.liveinterview.del.deleted,
    archivedItem: state.liveinterview.archive.archived,
    archiveLoading: state.liveinterview.archive.loading,
    archiveError: state.liveinterview.archive.error,
    activatedItem: state.liveinterview.activate.activated,
    activateLoading: state.liveinterview.activate.loading,
    activateError: state.liveinterview.activate.error,
    anyLoading: state.liveinterview.allInterviewsList.loading || state.liveinterview.del.loading || state.liveinterview.archive.loading || state.liveinterview.activate.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource)),
  filter: (filtersData) => dispatch(filter(filtersData)),
  sort: (sortData) => dispatch(sort(sortData)),
  del: (item) => dispatch(del(item)),
  archive: (item) => dispatch(archive(item)),
  activate: (item) => dispatch(activate(item)),
  archiveReset: (eventSource) => dispatch(archiveReset(eventSource)),
  activateReset: (eventSource) => dispatch(activateReset(eventSource)),
  resetDelete: (eventSource) => dispatch(resetDelete(eventSource)),
});

LiveInterviewAll = connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveInterviewAll);

export default withStyles(style)(LiveInterviewAll);
