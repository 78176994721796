import React, {Component} from 'react';
import {connect} from "react-redux";
import InterviewPageFooter from "../Interview/InterviewPageFooter";

class OnDemandInterviewFooter extends Component {
  render() {
    const {site} = this.props;
    return (
      <InterviewPageFooter site={site} />
    );
  }
}

const mapStateToProps = state => ({
  site: state.siteDetails.siteDetails.retrieved,
});

OnDemandInterviewFooter = connect(
  mapStateToProps
)(OnDemandInterviewFooter);

export default OnDemandInterviewFooter;
