import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import {renderTextField} from "../../utils/form"

class TicketForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={renderTextField}
          name="firstName"
          type="text"
          placeholder="First Name"
          required={true}
        />
        <Field
          component={renderTextField}
          name="lastName"
          type="text"
          placeholder="Last Name"
          required={true}
        />
        <Field
          component={renderTextField}
          name="email"
          type="text"
          placeholder="Email"
          required={true}
        />
        <Field
          component={renderTextField}
          name="phoneNumber"
          type="text"
          placeholder="Phone Number"
          required={true}
        />
        <Field
          component={renderTextField}
          name="comments"
          type="text"
          placeholder="Comments"
          required={true}
        />

        <button type="submit" className="btn btn-success float-left mr-3">
          Submit
        </button>
      </form>
    );
  }
}

export default reduxForm({
  form: 'ticket',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(TicketForm);
