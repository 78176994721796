import {renderTextareaField} from "../../utils/form";
import {Field, reduxForm} from "redux-form";
import React from "react";

const LiveInterviewRequestNewTimeForm = props => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        component={renderTextareaField}
        name="message"
        placeholder="Please, write a message"
        required={true}
        label={false}
      />
    </form>
  )
};

export default reduxForm({
  form: 'LiveInterviewRequestNewTimeForm',
  validate: (values) => {
    let errors = {};

    if (!values.message || values.message.length === 0) {
      errors['message'] = 'Please, enter a message'
    }

    return errors;
  },
})(LiveInterviewRequestNewTimeForm)
