import {fetch} from "../../utils/dataAccess";
import {SubmissionError} from "redux-form";

export function requestPasswordRecovery(values) {
    return dispatch => {
        dispatch(success(false));
        dispatch(error(null));
        dispatch(loading(true));
        return fetch('/password-recovery', { method: 'POST', data: values })
            .then(response => {
                dispatch(loading(false));
                dispatch(success(response.status === 204));
            })
            .catch(e => {
                dispatch(loading(false));
                if (e instanceof SubmissionError) {
                    dispatch(error(e.errors._error));
                    throw e;
                }

                dispatch(error(e.message));
            });
    };
}

export function error(error) {
    return { type: 'PASSWORD_RECOVERY_ERROR', error };
}

export function loading(loading) {
    return { type: 'PASSWORD_RECOVERY_LOADING', loading };
}

export function success(sent) {
    return { type: 'PASSWORD_RECOVERY_SUCCESS', sent };
}

export function reset() {
    return dispatch => {
        dispatch(loading(false));
        dispatch(error(null));
        dispatch(success(false))
    };
}
