import {getCurrentSiteDetails} from "../../utils/currentSiteDetails";
import SiteInactiveError from "../../errors/siteInactiveError";

export function loadSiteDetails() {
  return dispatch => {
    dispatch(loading(true));
    let promise = getCurrentSiteDetails();

    promise.then((siteDetails) => {
      dispatch(updateSiteDetails(siteDetails));
      dispatch(loading(false));
    }).catch(e => {
      dispatch(error(e));
      dispatch(loading(false));
    });
  };
}

export function loading(loading) {
  return {type: 'SITE_DETAILS_LOADING', loading};
}

export function error(error) {
  return {type: 'SITE_DETAILS_ERROR', error};
}

export function updateSiteDetails(siteDetails) {
  return (dispatch) => {
    if (siteDetails.status && siteDetails.status === 'inactive') {
      dispatch(error(new SiteInactiveError('This site is currently not active')));
    } else {
      dispatch(siteDetailsLoaded(siteDetails));
    }
  }
}

export function siteDetailsLoaded(retrieved) {
  return {type: 'SITE_DETAILS_RETRIEVED', retrieved};
}
