import React, {Component} from 'react'
import FileUploadComponent from "./FileUploadComponent";
import PropTypes from "prop-types";
import {CircularProgress} from "@material-ui/core";
import {fetch} from "../../utils/dataAccess";

class ResourceFileUpload extends Component{
  static propTypes = {
    filePropertyPath: PropTypes.string.isRequired,
    uploadPropertyPath: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    onUploadingStarted: PropTypes.func,
    onUploadingFinished: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      uploadError : null,
      buttonText: this.props.buttonText,
    };
  }

  onChange = (file) => {
    this.setState({'uploadError': null});
    const { input: { name, onChange }, uploadPropertyPath, url } = this.props;

    if (file) {
      const formData = new FormData();
      formData.append(uploadPropertyPath, file);

      this.setState({'buttonText': (
        <>
          <CircularProgress /> Uploading ...
        </>
      )});
      this.clearValue();

      if (this.props.onUploadingStarted) {
        this.props.onUploadingStarted();
      }

      fetch(url, {
        method: 'POST',
        data: formData
      }).then((response) => {
        onChange(response.data);
      }).catch((e) => {
        if (e.errors && e.errors[name]) {
          this.setState({'uploadError': e.errors[name]})
        } else {
          this.setState({'uploadError': 'Unexpected server error'})
        }
      }).finally(() => {
        this.setState({'buttonText': this.props.buttonText});
        if (this.props.onUploadingFinished) {
          this.props.onUploadingFinished();
        }
      });
    } else {
      this.clearValue()
    }
  };

  clearValue = (e) => {
    const { input: { onChange } } = this.props;
    onChange('');
  };

  render(){
    const {filePropertyPath, input: {name}, meta: {error}, ...props} = this.props;
    const fieldError = (this.state.uploadError || error);
    const resource = this.props.input.value;

    return(
      <FileUploadComponent
        name={name}
        value={resource ? resource[filePropertyPath] : undefined}
        error={fieldError}
        onChange={this.onChange}
        {...props}
        buttonText={this.state.buttonText}
      />
    )
  }
}

export default ResourceFileUpload;
