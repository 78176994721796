export function firstLetterToUppercase(str) {
    if (!str) {
      return;
    }
    const regexMultipleWhiteSpace = /\s\s+/g;
    let lowCase = str.toLowerCase().replace(regexMultipleWhiteSpace, ' ').trim();
    if (!lowCase) {
      return;
    }
    return lowCase.charAt(0).toUpperCase() + lowCase.slice(1);
  }
