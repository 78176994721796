import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import "./OrderShow.scss";
import {Link} from "react-router-dom";
import {Tabs} from "./Tabs";
import {retrieve, reset} from "../../actions/Order/show";
import {PaymentMethodLogo} from "../../views/Subscription/PaymentMethodCard";
import moment from "moment";
import {formatPrice, PREVIEW_PERIOD_ANNUAL} from "../../utils/plan";
import TransactionIcon from "../../views/Subscription/TransactionIcon";

class OrderShow extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.orderId));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {
    const order = this.props.retrieved;
    const transaction = order && order['transactions'].length > 0 && order['transactions'][order['transactions'].length - 1];
    const summary = order && order['summary'] || [];
    const proratedAdjustment = order && order.withoutPayment ? 0 : summary && summary['prorated_adjustment'];
    const subtotal = order && order.withoutPayment ? 0 : summary && summary['subtotal'];
    const tax = order && order.withoutPayment ? 0 : summary && summary['tax'];
    const total = order && order.withoutPayment ? 0 : summary && summary['total'];
    const planPrice = order && order.withoutPayment ? 0 : summary && summary['plan_price'];
    let isUpgrade = true;
    if (this.props.location.state && this.props.location.state.hasOwnProperty('isUpgrade')) {
      isUpgrade = this.props.location.state.isUpgrade;
    }

    let status = null;
    if (order && order['withoutPayment']) {
      status = 'success';
    } else if (transaction) {
      status = transaction['status'];
    }

    return (
      <div className={"partner-company-order-show"}>
        {order && (
          <Tabs
            planSelectUrl={ `/partner-admin/companies/subscription/${order && encodeURIComponent(order['company']['@id'])}/plan-select`}
            step={2}
            isUpgrade={isUpgrade}
          />
        )}

        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}

        {order && (
          <>
            <h2>
              {status === 'success'  && 'Payment Success!'}
              {status === 'failed'   && 'Payment Failed!'}
              {status === 'canceled' && 'Payment Canceled!'}
              {status === 'pending'  && 'Payment Pending!'}
            </h2>
            <div className={"order-show"}>
              <TransactionIcon status={status} />

              <table>
                <tbody>
                {transaction && (
                  <tr className={"bordered"}>
                    <td>Transaction ID</td>
                    <td>{transaction['token']}</td>
                  </tr>
                )}
                <tr className={"bordered"}>
                  <td>Date & Time</td>
                  <td>{moment(order['createdAt']).format("MM/DD/YYYY hh:m A")}</td>
                </tr>
                {summary['start_period'] && summary['end_period'] && (
                  <tr className={"bordered"}>
                    <td>Subscription Period</td>
                    <td>{moment(summary['start_period']).format("MM/DD/YYYY")} - {moment(summary['end_period']).format("MM/DD/YYYY")}</td>
                  </tr>
                )}
                <tr>
                  <td className={"plan-name"}><div>{summary['plan_name']} (bills {summary['plan_period'] === PREVIEW_PERIOD_ANNUAL ? 'annually' : 'monthly'})</div></td>
                  <td>{formatPrice(planPrice)}</td>
                </tr>
                {!!parseFloat(proratedAdjustment) && (
                  <>
                    <tr>
                      <td>Prorated Adjustment</td>
                      <td>{formatPrice(proratedAdjustment)}</td>
                    </tr>
                    <tr>
                      <td>Subtotal</td>
                      <td>{formatPrice(subtotal)}</td>
                    </tr>
                  </>
                )}
                <tr>
                  <td>Tax (10%)</td>
                  <td>{formatPrice(tax)}</td>
                </tr>
                <tr className={"bordered blue-color"}>
                  <td>Amount</td>
                  <td><strong>{formatPrice(total)}</strong></td>
                </tr>
                {transaction['brand'] && transaction['lastFour'] && (
                  <tr>
                    <td>Card Used</td>
                    <td>
                      <PaymentMethodLogo brand={transaction['brand']} /> ending in {transaction['lastFour']}
                    </td>
                  </tr>
                )}
                </tbody>
              </table>
            </div>

            <div className={"partner-company-order-show-buttons"}>
              <Link to={'/partner-admin/transactions-for-company/' + encodeURIComponent(order['company']['@id']) + '/'} className={"subscription-button-blue-outlined"}>View Billing History</Link>
              <Link to={'../' + encodeURIComponent(order['company']['@id'])} className={"subscription-button-blue"}>Back To Subscription Home</Link>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    retrieved,
    loading,
    error,
    eventSource,
  } = state.order.show;

  return { retrieved, loading, error, eventSource };
};

const mapDispatchToProps = dispatch => ({
  retrieve: page => dispatch(retrieve(page)),
  reset: eventSource => dispatch(reset(eventSource)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderShow);
