import React from "react";
import Select from "react-select"
import InputLabel from "@material-ui/core/InputLabel";
import {renderFromHelper} from "../../utils/form";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import FormHelperText from "@material-ui/core/es/FormHelperText";

const redAsterisks = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "red",
        "&$error": {
          color: "red"
        }
      }
    }
  }
});

class SearchableSelect extends React.Component {  
    componentWillMount() {
        this.componentId = (this.props.id) ?
            this.props.id :
            'searchable_select_' + Math.random().toString(36).substring(5);
    }

    convertOptionsToReactSelectFormat = (options) => {
        return (options || []).map((option) => {
          if (option.label && option.options) {
            return {
              label: option.label,
              options: this.convertOptionsToReactSelectFormat(option.options),
            }
          } else {
            return {
              label: option.text,
              value: option.value,
              isDisabled: option.isDisabled,
            }
          }
        });
    };

    convertValueToReactSelectFormat = (value) => {
        const {options, isMulti} = this.props;
        let reactOptions = this.convertOptionsToReactSelectFormat(options);

        const findOptionByValue = (value) => {
          let foundOption = null;
          reactOptions.find((option) => {
            if (option.label && option.options) {
              const foundSubOption = option.options.find(subOption => subOption.value === value);
              if (foundSubOption) {
                foundOption = foundSubOption;
                return true;
              }
            } else if (option.value === value) {
              foundOption = option;
              return true;
            }
          });
          return foundOption;
        };

        if (isMulti) {
            let values = (value || []);
            let reactValues = [];
            //This need to keep values order
            values.forEach((value) => {
                let found = findOptionByValue(value);
                if (found) {
                    reactValues.push(found);
                }
            });
            return reactValues;
        } else {
            if (value === "") {
                return null;
            }
            return findOptionByValue(value);
        }
    };

    handleValueChange = (input, value) => {
        const {isMulti, onOptionValueChange} = this.props;
        if (isMulti) {
            let values = (value || []).map((element) => element.value);
            input.onChange(values);
            input.value = values;
        } else if (value) {
            input.value = value.value;
            input.onChange(value.value);
        } else {
          input.value = null;
          input.onChange(null);
        }
        if (onOptionValueChange && typeof onOptionValueChange === 'function') {
          onOptionValueChange(value);
        }
    };

    renderLabel = () => {
        let {label, required} = this.props;
        const { input} = this.props;
        if (label === undefined) {
            label = input.name.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
                return str.toUpperCase();
            });
        }

        if (label !== null) {
            return <>
                <MuiThemeProvider theme={redAsterisks}>
                    <InputLabel htmlFor={this.componentId} required={required}>{label}</InputLabel><br/>
                </MuiThemeProvider>
            </>
        } else {
            return (null);
        }
    };

    standardizeIsClearableRequiredSelects = (required) => {
      let isClearable = true;
      if (required) {
        isClearable = false;
      }
      return isClearable;
    };

    render() {
        const { input, options, meta: {touched, error}, ...custom } = this.props;

        if (custom) {
          custom.isClearable = this.standardizeIsClearableRequiredSelects(custom.required);
        }

        let { containerClass, customStyles } = this.props;

        containerClass = containerClass || 'form-group';

        let defaultCustomStyles = {
          menu: (provided) => ({
            ...provided,
            ...{zIndex: '1000 !important'}
          }),
          clearIndicator: (provided) => ({
            ...provided,
            cursor: 'pointer',
          })
        };

        customStyles = {...defaultCustomStyles, ...customStyles};

        return (
            <div className={containerClass}>
                {this.renderLabel()}
                <Select
                    id={this.componentId}
                    {...input}
                    {...custom}
                    styles={customStyles}
                    onChange={value => this.handleValueChange(input, value)}
                    onBlur={() => {
                      const value = this.props.input.value;
                      input.onBlur(value || value === false ? value : null);
                    }}
                    options={this.convertOptionsToReactSelectFormat(options)}
                    value={this.convertValueToReactSelectFormat(input.value)}
                />
                {renderFromHelper(touched, error)}
                {custom.helpText && (<FormHelperText>{custom.helpText}</FormHelperText>)}
            </div>
        )
    }
}

export default SearchableSelect;
