import React, {Component} from "react";
import PropTypes from "prop-types";
import {generateRandomString} from "../../../utils/string";
import DialogActions from "./DialogActions";
import DialogTitle from "./DialogTitle";
import DialogRoot from "./DialogRoot";
import DialogContent from "./DialogContent";

class Dialog extends Component{
  static propTypes = {
    open: PropTypes.bool.isRequired,
    showTitleCloseButton: PropTypes.bool,
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    confirmButtonText: PropTypes.string,
    confirmButtonDisabled: PropTypes.bool,
    onConfirm: PropTypes.func,
    closeButtonText: PropTypes.string,
    onClose: PropTypes.func,
    actionButtons: PropTypes.object,
    id: PropTypes.string,
    dialogProperties: PropTypes.object.isRequired,
    contentProperties: PropTypes.object.isRequired,
    className: PropTypes.string,
    contentClass: PropTypes.string,
  };

  static defaultProps = {
    open: true,
    showTitleCloseButton: true,
    confirmButtonText: 'Confirm',
    confirmButtonDisabled: false,
    cancelButtonText: 'Cancel',
    dialogProperties: {},
    contentProperties: {},
  }

  componentDidMount() {
    if (this.props.id) {
      this.id = this.props.id;
    } else {
      this.id = 'dialog_' + generateRandomString(10);
    }
  }

  render() {
    const {
      open, title, showTitleCloseButton, contentClass, dialogProperties, actionButtons, children,
      confirmButtonText, confirmButtonDisabled, closeButtonText, onConfirm, onClose, className, contentProperties
    } = this.props;

    const titleId = this.id + '_title';
    const contentId = this.id + '_content';
    const actionsId = this.id + '_actions';

    return (
      <DialogRoot
        id={this.id}
        open={open}
        onClose={onClose}
        titleId={titleId}
        contentId={contentId}
        properties={dialogProperties}
        className={className}
      >
        <DialogTitle
          id={titleId}
          onClose={showTitleCloseButton ? onClose : undefined}
        >
          {title}
        </DialogTitle>
        <DialogContent
          id={contentId}
          className={contentClass}
          properties={contentProperties}
        >
          {children}
        </DialogContent>
        <DialogActions
          id={actionsId}
          confirmButtonText={confirmButtonText}
          confirmButtonDisabled={confirmButtonDisabled}
          onConfirm={onConfirm}
          closeButtonText={closeButtonText}
          onClose={onClose}
        >
          {actionButtons}
        </DialogActions>
      </DialogRoot>
    );
  }
}

export default Dialog;
