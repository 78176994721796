import {fetchJSON} from "../../utils/dataAccess";

export function loadTagsForSelect(valueProperty = 'id') {
  return dispatch => {
    dispatch(loading(true));
    fetchJSON('/tags?pagination=false').then((states) => {
      dispatch(tagsLoaded(states['hydra:member'].map(
        function (tag) {
          return {
            value: tag[valueProperty],
            text: tag.name,
          }
        }
      )));
      dispatch(loading(false));
    }).catch(e => {
      dispatch(loading(false));
      dispatch(error(e.message));
    })
  };
}

export function loading(loading) {
  return {type: 'TAGS_FOR_SELECT_LOADING', loading};
}

export function error(error) {
  return {type: 'TAGS_FOR_SELECT_ERROR', error};
}

export function tagsLoaded(retrieved) {
  return {type: 'TAGS_FOR_SELECT_RETRIEVED', retrieved};
}

export function reset() {
  return {type: 'TAGS_FOR_SELECT_RESET'};
}
