import {
  fetch,
  extractHubURL,
  normalize,
  mercureSubscribe as subscribe
} from '../../utils/dataAccess';
import {success as deleteSuccess} from "./delete";
import {isVideoNeedToReEncode} from "../../utils/videoEntity";

export function error(error) {
  return { type: 'QUESTIONBANK_SHOW_ERROR', error };
}

export function loading(loading) {
  return { type: 'QUESTIONBANK_SHOW_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'QUESTIONBANK_SHOW_SUCCESS', retrieved };
}

export function retrieve(id) {
  return dispatch => {
    dispatch(loading(true));

    return fetch(id)
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {

        dispatch(loading(false));
        dispatch(success(retrieved));

        if (hubURL) dispatch(mercureSubscribe(hubURL, retrieved));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'QUESTIONBANK_SHOW_RESET' });
    dispatch(error(null));
    dispatch(loading(false));
    dispatch(deleteSuccess(null));
  };
}

export function mercureSubscribe(hubURL, questionBank) {
  let topics = [];
  if (
    questionBank['questionBankVideo'] &&
    questionBank['questionBankVideo']['@id'] &&
    isVideoNeedToReEncode(questionBank['questionBankVideo'])
  ) {
    topics.push(questionBank['questionBankVideo']['@id']);
  }
  return dispatch => {
    if (topics.length) {
      const eventSource = subscribe(hubURL, topics);
      dispatch(mercureOpen(eventSource));
      eventSource.addEventListener('message', event =>
        dispatch(mercureMessage(normalize(JSON.parse(event.data))))
      );
    }
  };
}

export function mercureOpen(eventSource) {
  return { type: 'QUESTIONBANK_SHOW_MERCURE_OPEN', eventSource };
}

export function mercureMessage(retrieved) {
  return dispatch => {
    if (1 === Object.keys(retrieved).length) {
      dispatch({ type: 'QUESTIONBANK_SHOW_MERCURE_DELETED', retrieved });
      return;
    }

    if (retrieved && retrieved['@type'] === 'QuestionBankVideo') {
      dispatch({ type: 'QUESTIONBANK_QUESTIONBANKVIDEO_SHOW_MERCURE_MESSAGE', retrieved });
      return
    }

    dispatch({ type: 'QUESTIONBANK_SHOW_MERCURE_MESSAGE', retrieved });
  };
}
