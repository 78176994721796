import React from "react";
import {Field, change, formValueSelector} from "redux-form";
import {renderFromHelper} from "../../utils/form";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from '@material-ui/core/IconButton';
import NoteAdd from "@material-ui/icons/NoteAdd"
import Clear from "@material-ui/icons/Clear"
import Button from "../../components/CustomButtons/Button";
import {loadIndustriesForSelect} from "../../actions/Industry/forSelect";
import {loadOccupationsForSelect} from "../../actions/Occupation/forSelect";
import {loadSpecialtiesForSelect} from "../../actions/Specialty/forSelect";
import {connect} from "react-redux";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import SearchableSelect from "./SearchableSelect";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

const redAsterisks = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "red",
        "&$error": "red",
      }
    }
  }
});

const styles = {
  card: {
    marginBottom: '1em',
    overflow: 'visible',
  },
  cardHeader: {
    paddingBottom: 0,
  },
  cardTitle: {
    fontSize: '1.1em',
  },
  addButton: {
    marginBottom: '1em',
  }
};

class ProfessionMapping extends React.Component {
  static defaultProps = {
    allowAdd: true,
    allowDelete: true,
    showOccupationField: true,
    showSpecialtyField: true,
    addMappingText: 'Add Mapping',
    mappingCardTitle: 'Mapping',
  };

  componentWillMount() {
    this.props.loadIndustriesForSelect();
    if (this.props.showOccupationField) {
      this.props.loadOccupationsForSelect();
    }
    if (this.props.showSpecialtyField) {
      this.props.loadSpecialtiesForSelect();
    }
  }

  handleIndustryChange = (name, newValue, prevValue) => {
    if (newValue !== prevValue) {
      const {meta: {form} } = this.props;
      this.props.change(form, `${name}.occupation`, null);
      this.props.change(form, `${name}.specialty`, null);
    }
  };

  handleOccupationChange = (name, newValue, prevValue) => {
    if (newValue !== prevValue) {
      const {meta: {form} } = this.props;
      this.props.change(form, `${name}.specialty`, null);
    }
  };

  getOccupationsForSelect = (industry) => {
    return (this.props.occupations || []).filter(occupation => occupation.industry === industry);
  };

  getSpecialtiesForSelect = (occupation) => {
    return (this.props.specialties || []).filter(specialty => specialty.occupation === occupation);
  };

  renderFields = (name, mapping) => {
    const {industry, occupation} = mapping;
    const {showOccupationField, showSpecialtyField} = this.props;
    const occupations = this.getOccupationsForSelect(industry);
    const specialties = this.getSpecialtiesForSelect(occupation);
    return (
          <>
          <Field
            component={SearchableSelect}
            name={`${name}.industry`}
            label="Industry"
            placeholder="Industry"
            onChange={(event, newValue, prevValue) => this.handleIndustryChange(name, newValue, prevValue)}
            normalize={val => val === "" ? null : val}
            options={this.props.industries}
            required
          />
          {showOccupationField && occupations.length > 0 && (
          <Field
            component={SearchableSelect}
            name={`${name}.occupation`}
            label="Occupation"
            placeholder="Occupation"
            onChange={(event, newValue, prevValue) => this.handleOccupationChange(name, newValue, prevValue)}
            options={occupations}
            disabled={industry ? false : true}
            normalize={val => val === "" ? null : val}
          />
          )}
          {showSpecialtyField && specialties.length > 0 && (
          <Field
            component={SearchableSelect}
            name={`${name}.specialty`}
            label="Specialty"
            placeholder="Specialty"
            disabled={occupation ? false : true}
            normalize={val => val === "" ? null : val}
            options={specialties}
          />
          )}
          </>
    );
  };

  render() {
    const {
      classes, fields, label, required, allowAdd, allowDelete, meta: {error},
      addMappingText, mappingCardTitle
    } = this.props;
    return (
      <div>
        {label && (
          <>
          <MuiThemeProvider theme={redAsterisks}>
            <InputLabel required={required}>{label}</InputLabel><br/></MuiThemeProvider>
          </>
        )}
        {fields.map((name, index) => {
          const mapping = fields.get(index);
          return (
            <Card key={index} className={classes.card}>
              <CardHeader
                className={classes.cardHeader}
                disableTypography={true}
                subheader={
                  <Typography
                    className={classes.cardTitle}
                    color='textSecondary'
                  >
                    {`${mappingCardTitle} #${index + 1}`}
                  </Typography>
                }
                action={
                  allowDelete && !(required && parseInt(index) === 0) && (
                    <IconButton
                      onClick={() => fields.remove(index)}
                    ><Clear>Remove Mapping</Clear></IconButton>
                  )
                }
              />
              <CardContent>
                {this.renderFields(name, mapping)}

              </CardContent>
            </Card>
          )
        })}
        {renderFromHelper(true, error)}
        {allowAdd && (
          <Button className={classes.addButton} color="success" onClick={() => fields.push({})}>
            <NoteAdd/>{addMappingText}
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {meta: {form}, fields: {name}} = ownProps;
  const selector = formValueSelector(form);
  return {
    industries: state.industry.forSelect.retrieved,
    fieldValues: selector(state, name), //Need to pass values to props explicitly to force component rerender on child values change
    occupations: state.occupation.forSelect.retrieved,
    specialties: state.specialty.forSelect.retrieved,
  }
};

const mapDispatchToProps = dispatch => ({
  loadIndustriesForSelect: () => dispatch(loadIndustriesForSelect('@id')),
  loadOccupationsForSelect: () => dispatch(loadOccupationsForSelect('@id')),
  loadSpecialtiesForSelect: () => dispatch(loadSpecialtiesForSelect('@id')),
  change: (formName, fieldName, value) => dispatch(change(formName, fieldName, value)),
});

ProfessionMapping = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfessionMapping);

export default withStyles(styles)(ProfessionMapping);
