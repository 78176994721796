import React from "react";
import PropTypes from "prop-types";
import {
  changeAudioDevice,
  getStreamManagerAudioDeviceId,
} from "../../utils/streamManager";
import {IconButton} from "@material-ui/core";
import MicrophoneIcon from '@material-ui/icons/Mic';
import classNames from 'classnames';

class ToggleMicrophone extends React.Component {
  static propTypes = {
    streamManager: PropTypes.object,
    devices: PropTypes.object,
    className: PropTypes.string,
    disabled: PropTypes.bool,
  };

  state = {
    currentDeviceId: null,
    toggling: false,
  }

  componentDidMount() {
    const {streamManager} = this.props;
    if (streamManager) {
      this.updateCurrentDeviceId();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.streamManager && this.props.streamManager) {
      this.updateCurrentDeviceId();
    }
  }

  updateCurrentDeviceId = () => {
    const {streamManager} = this.props;
    this.setState({
      currentDeviceId: getStreamManagerAudioDeviceId(streamManager)
    })
  }

  getNextDeviceId = () => {
    const {currentDeviceId} = this.state;
    if (!this.props.devices) {
      return null;
    }

    const deviceIds = Object.keys(this.props.devices);
    if (deviceIds.length === 0) {
      return null;
    }

    const currentIndex = deviceIds.indexOf(currentDeviceId);
    if (deviceIds[currentIndex + 1]) {
      return deviceIds[currentIndex + 1];
    } else {
      return deviceIds[0];
    }
  }

  toggle = () => {
    const { streamManager } = this.props;
    const nextDeviceId = this.getNextDeviceId();
    if (nextDeviceId) {
      this.setState({toggling: true});
      changeAudioDevice(streamManager, nextDeviceId)
        .catch(e => console.log("Unable to change audio device", e))
        .finally(() => this.setState({
          currentDeviceId: getStreamManagerAudioDeviceId(this.props.streamManager),
          toggling: false,
        }));
    }
  }

  render() {
    const {toggling} = this.state;
    const {className, disabled} = this.props;
    const buttonDisabled = toggling || disabled;
    return (
      <IconButton
        onClick={this.toggle}
        disabled={buttonDisabled}
        className={classNames(className, {
          'toggle-microphone-button-disabled': buttonDisabled,
        })}
      >
        <MicrophoneIcon />
      </IconButton>
    )
  }
}

export default ToggleMicrophone;
