import {
  fetch,
  extractHubURL,
  normalize
} from '../../utils/dataAccess';

export function error(error) {
  return { type: 'SUBSCRIPTION_UPGRADE_SUMMARY_ERROR', error };
}

export function loading(loading) {
  return { type: 'SUBSCRIPTION_UPGRADE_SUMMARY_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'SUBSCRIPTION_UPGRADE_SUMMARY_SUCCESS', retrieved };
}

export function calculateUpgradeSummary(newPlan, newPeriod, companyId = null) {
  return dispatch => {
    dispatch(loading(true));

    let url = `/subscription/calculate-upgrade-summary/${newPlan}/${newPeriod}`;
    if (companyId) {
      url += ('/' + companyId);
    }

    return fetch(url)
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        retrieved = normalize(retrieved);

        dispatch(loading(false));
        dispatch(success(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'SUBSCRIPTION_UPGRADE_SUMMARY_RESET' });
    dispatch(error(null));
    dispatch(loading(false));
  };
}
