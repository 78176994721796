import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {MenuItem, Select} from "@material-ui/core";
import {PLAYBACK_RATES} from "../../config/player";

class AudioPlayer extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    controls: PropTypes.bool.isRequired,
    controlsList: PropTypes.string.isRequired,
    showPlaybackRate: PropTypes.bool,
    playbackRates: PropTypes.array,
    className: PropTypes.string,
  };

  static defaultProps = {
    controls: true,
    controlsList: 'nodownload',
    className: '',
    showPlaybackRate: false,
    playbackRates: PLAYBACK_RATES,
  };

  constructor(props) {
    super(props);
    this.audioElement = React.createRef();

    this.state = {
      playbackRate: 1,
    }
  }

  openPlaybackSpeedDialog = (event) => {
    this.setState({anchorElement: event.currentTarget});
  };

  closePlaybackSpeedDialog = () => {
    this.setState({anchorElement: null});
  }

  changePlaybackRate = (event) => {
    const playbackRate = event.target.value
    this.setState({playbackRate: playbackRate});
    if (this.audioElement && this.audioElement.current) {
      this.audioElement.current.playbackRate = playbackRate;
    }
  }

  render() {
    const {playbackRate} = this.state;
    const {src, className, controls, controlsList, showPlaybackRate, playbackRates} = this.props;

    return (
      <div className={'audio-player ' + className}>
        <div className={'audio-element-wrapper'}>
          <audio ref={this.audioElement} src={src} controls={controls} controlsList={controlsList} />
        </div>
        {showPlaybackRate && (
          <div className={'playback-rate-wrapper'}>
            <Select
              value={playbackRate}
              onChange={this.changePlaybackRate}
            >
              {playbackRates.map((rate) => {
                return (<MenuItem key={rate} value={rate}>{rate}x</MenuItem>);
              })}
            </Select>
          </div>
        )}
      </div>
    );
  }
}

export default AudioPlayer;
