import {combineReducers} from 'redux';

export function saved(state = null, action) {
  switch (action.type) {
    case 'REQUEST_SAVE_AS_TEMPLATE_SUCCESS':
      return action.saved;
    default:
      return state;
  }
}

export function loading(state = null, action) {
  switch (action.type) {
    case 'REQUEST_SAVE_AS_TEMPLATE_LOADING':
      return action.loading;
    default:
      return state;
  }
}

export function error(state = null, action) {
  switch (action.type) {
    case 'REQUEST_SAVE_AS_TEMPLATE_ERROR':
      return action.error;
    default:
      return state;
  }
}

export default combineReducers({error, loading, saved});
