import { SubmissionError } from 'redux-form';
import {fetch} from '../../utils/dataAccess';

export function error(error) {
  return { type: 'INVITATION_UPDATE_EXPIRES_AT_ERROR', error };
}

export function loading(loading) {
  return { type: 'INVITATION_UPDATE_EXPIRES_AT_LOADING', loading };
}

export function success(updated) {
  return { type: 'INVITATION_UPDATE_EXPIRES_AT_SUCCESS', updated };
}

export function updateExpiresAt(iri, expiresAt) {
  return dispatch => {
    dispatch(loading(true));

    return fetch(iri + '/update-expires-at', {
        method: 'PUT',
        data: {expiresAt: expiresAt},
      })
      .then(retrieved => {
        dispatch(loading(false));
        dispatch(success(retrieved))
      })
      .catch(e => {
        dispatch(loading(false));
        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw e;
        }

        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(error(null));
    dispatch(success(null));
  };
}
