export function resolveDashboardUrl(roles) {
    if (!Array.isArray(roles)) {
        throw new Error('Provided roles to dashboardUrlResolver should be an array');
    }

    for (let i = 0; i < roles.length; i++) {
        let role = roles[i];
        switch (role) {
            case 'ROLE_SUPER_ADMIN':
                return '/super-admin/dashboard';
            case 'ROLE_PARTNER_ADMIN':
                return '/partner-admin/dashboard';
            case 'ROLE_COMPANY_USER':
                return '/company/home';
            case 'ROLE_CANDIDATE':
                return '/candidate/dashboard';
        }
    }

    return '/login';
}
