import React, {Component} from "react";
import CheckBox from "@material-ui/core/es/internal/svg-icons/CheckBox";
import RadioButtonChecked from "@material-ui/core/es/internal/svg-icons/RadioButtonChecked";
import CheckBoxOutlineBlank from "@material-ui/core/es/internal/svg-icons/CheckBoxOutlineBlank";
import RadioButtonUnchecked from "@material-ui/core/es/internal/svg-icons/RadioButtonUnchecked";

export default class KnockoutQuestionPreview extends Component {
    render() {
        let knockoutQuestion = this.props.knockoutQuestion;
        return (
            <>
                <p>
                    <small>Select type: <i>{knockoutQuestion.type}</i></small>
                </p>
                <p>
                    {knockoutQuestion.content}{knockoutQuestion.required && (<span className="text-danger"> *</span>)}
                </p>

                {knockoutQuestion.knockoutQuestionChoices && knockoutQuestion.knockoutQuestionChoices.length && knockoutQuestion.knockoutQuestionChoices.map((choice, index) => {
                    return (
                        <p key={index}>
                            {
                                choice.correct ?
                                    knockoutQuestion.type === 'multiple' ?
                                        (<CheckBox nativeColor="#f50057"/>) :
                                        (<RadioButtonChecked nativeColor="#f50057"/>)

                                    : knockoutQuestion.type === 'multiple' ?
                                    (<CheckBoxOutlineBlank nativeColor="rgba(0, 0, 0, 0.54)"/>) :
                                    (<RadioButtonUnchecked nativeColor="rgba(0, 0, 0, 0.54)"/>)

                            }
                            {choice.content}
                        </p>)
                })}
            </>
        );
    }
}
