import React, {Component} from 'react';
import {connect} from "react-redux";
import {IntroOutroPlayer} from "./IntroOutroPlayer";
import {
  clearStorageForQuestion,
  getRequestVideoQuestionByIndex,
  getScreeningQuestionsCount, getStartThinkTimeForVideoQuestion,
  isInterviewFinished
} from "../../utils/onDemandInterview";
import Questions from "./Questions";
import JobBar from "../JobBar/JobBar";
import VideoQuestionsTextIntro from "./VideoQuestionsTextIntro";
import VideoQuestions from "./VideoQuestions";
import CardBody from "../Card/CardBody";
import Button from "../CustomButtons/Button";
import Card from "../Card/Card";
import {Link} from "react-router-dom";
import {isAuthenticated} from "../../utils/auth";

class OnDemandInterviewQuestionsContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      introVideoSkipped: false,
      showOutroVideo: false,
      videoQuestionsTextIntroSkipped: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!isInterviewFinished(prevProps.interview) && isInterviewFinished(this.props.interview)) {
      this.setState({showOutroVideo: true});

      const prevInterview = prevProps.interview;
      const lastQuestionIndex = prevInterview.currentQuestion;
      const lastQuestion = getRequestVideoQuestionByIndex(this.props.request, lastQuestionIndex);
      if (lastQuestion) {
        clearStorageForQuestion(lastQuestion);
      }
    }
  }

  renderContent = () => {
    const {request, interview, loggedIn, screenSize} = this.props;
    const isLoggedIn = isAuthenticated() || loggedIn;
    const smallScreen = !screenSize.md;

    if (this.isOutroVideoShouldBeShown()) {
      return <IntroOutroPlayer
        onSkip={() => this.setState({showOutroVideo: false})}
        buttonLabel={'Next >>'}
        video={request.requestOutroVideo ? request.requestOutroVideo.videoUrl : null}
        videoUrl={request.requestOutroVideoUrl}
        showPlayButtonOnVideo={smallScreen}
      />
    }

    if (isInterviewFinished(interview)) {
      return (
        <div className={'on-demand-interview-page-interview-finished-card-wrapper'}>
          <Card>
            <CardBody style={{textAlign:'center'}}>
              Success! Your responses have been submitted to {request.job.company.name}. Please contact them if you have any questions.
              <br/>
              {isLoggedIn && (<Link to='/candidate/dashboard'>
                <Button color='primary'>Proceed to Dashboard</Button>
              </Link>)}
            </CardBody>
          </Card>
        </div>
      )
    }

    if (this.isIntroVideoShouldBeShown()) {
      return <IntroOutroPlayer
        onSkip={() => this.setState({introVideoSkipped: true})}
        buttonLabel={'Next >>'}
        video={request.requestIntroVideo ? request.requestIntroVideo.videoUrl : null}
        videoUrl={request.requestIntroVideoUrl}
        showPlayButtonOnVideo={smallScreen}
      />
    }

    if (this.isVideoQuestionsTextIntroShouldBeShown()) {
      return <VideoQuestionsTextIntro onSkip={() => this.setState({videoQuestionsTextIntroSkipped: true})}/>;
    }

    if (interview.currentQuestion !== null) {
      return <VideoQuestions />;
    }

    return <Questions />
  }

  isIntroVideoShouldBeShown = () => {
    const {request, interview} = this.props;
    return !this.state.introVideoSkipped && (request.requestIntroVideo || request.requestIntroVideoUrl) &&
      (
        interview.currentKnockoutQuestion === 0 && interview.currentQuestion === null ||
        getScreeningQuestionsCount(request) === 0 && interview.currentQuestion === 0
      )
  }

  isOutroVideoShouldBeShown = () => {
    const {request, interview} = this.props;
    return this.state.showOutroVideo && (request.requestOutroVideo || request.requestOutroVideoUrl) && isInterviewFinished(interview)
  }

  isVideoQuestionsTextIntroShouldBeShown = () => {
    const {interview, request} = this.props;
    return !this.state.videoQuestionsTextIntroSkipped &&
            interview.currentKnockoutQuestion === null &&
            interview.currentQuestion === 0 &&
            !getStartThinkTimeForVideoQuestion(getRequestVideoQuestionByIndex(request, interview.currentQuestion));
  }

  isJobInfoShouldBeShown = () => {
    const {screenSize} = this.props;
    const smallScreen = !screenSize.md;
    return !smallScreen && !this.isIntroVideoShouldBeShown() && !this.isOutroVideoShouldBeShown();
  }

  render() {
    const {request} = this.props;
    return (
      <div className='on-demand-interview-page-question-content'>
        {this.isJobInfoShouldBeShown() && (
          <JobBar job={request.job}
                  company={request.company}
                  withDescription={false}
          />
        )}
        {this.renderContent()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  request: state.onDemandInterviewPage.request,
  interview: state.onDemandInterviewPage.interview,
  loggedIn: state.onDemandInterviewPage.auth.loggedIn,
  screenSize: state.screen.size,
});

const mapDispatchToProps = dispatch => ({

});

OnDemandInterviewQuestionsContent = connect(
  mapStateToProps, mapDispatchToProps
)(OnDemandInterviewQuestionsContent);

export default OnDemandInterviewQuestionsContent;
