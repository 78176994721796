import React, {Component} from "react";
import {reset, retrieve} from "../../actions/UserProfile/update";
import {connect} from "react-redux";
import {currentUserHasRole, saveAuthData} from "../../utils/auth";
import IsGranted from "../IsGranted";
import {handleSwitchUser} from "../SwitchUser/SwitchUser";
import ProfessionMappingView from "../../views/Components/ProfessionMappingView";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Moment from 'react-moment';
import {Link} from 'react-router-dom';
import {renderLinks} from "../../utils/entityList";
import FlashMessages from "../../views/Components/FlashMessages";
import PhoneNumber from "../../views/Components/PhoneNumber";

class CandidateViewProfile extends Component {
  componentDidMount() {
    this.props.retrieve();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.auth && !prevProps.auth) {
      saveAuthData(this.props.auth);
    }
  }

  render() {
    const user = this.props.user;
    return (
      <div>
        <h1>View Profile &quot;{user && user['firstName']} {user && user['lastName']}&quot;</h1>

        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}
        <FlashMessages location={this.props.location} />

        <div className="grid-buttons">
          {user && (
            <Link to="user-profile" className="btn btn-warning float-left mb-3">
              <span className="fa fa-pencil" aria-hidden="true" /> Edit Profile
            </Link>
          )}
          {user && (
            <IsGranted action="impersonate" object={user}>
              <a href='javascript:'>
                <button className="btn btn-info float-left mr-3" onClick={() => handleSwitchUser(user['id'])}>Impersonate</button>
              </a>
            </IsGranted>
          )}
          {user && (
            <IsGranted action="delete" object={user}>
              <button onClick={this.del} className="btn btn-danger float-left mr-3">
                Delete
            </button>
            </IsGranted>
          )}
        </div>


        {user && (
          <div style={{ 'overflowX': 'auto' }}>
            <Table className="table table-responsive table-striped table-hover">
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentUserHasRole('ROLE_SUPER_ADMIN') && (
                  <TableRow>
                    <TableCell scope="row">Partner</TableCell>
                    <TableCell>{renderLinks('../partners', user['partner'])}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell scope="row">First Name</TableCell>
                  <TableCell>{user['firstName']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Last Name</TableCell>
                  <TableCell>{user['lastName']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Email</TableCell>
                  <TableCell>{user['email']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Mobile Number</TableCell>
                  <TableCell>
                    <PhoneNumber phone={user['phoneNumber']} formattedPhone={user['formattedPhoneNumber']} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Profession Mapping</TableCell>
                  <TableCell>
                    {user.professionMappings && user.professionMappings.length > 0 && user.professionMappings.map(
                      (mapping, index) => <ProfessionMappingView key={index} mapping={mapping} />
                    )}
                    {!user.professionMappings || user.professionMappings.length === 0 && ('-')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Resume</TableCell>
                  <TableCell>
                    {user['resume'] && user['resume']['file'] && (
                      <a href={user['resume']['file']['url']}>{user['resume']['file']['fileName']}</a>
                    )}
                    {!user['resume'] && ('-')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Status</TableCell>
                  <TableCell>{user['status']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Created At</TableCell>
                  <TableCell>{user['createdAt'] && (<Moment>{user['createdAt']}</Moment>)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Updated At</TableCell>
                  <TableCell>{user['updatedAt'] && (<Moment>{user['updatedAt']}</Moment>)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = state => ({
  loading: state.userProfile.update.loading,
  error: state.userProfile.update.error,
  user: state.userProfile.update.retrieved,
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(false)),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateViewProfile);
