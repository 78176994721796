import { combineReducers } from 'redux';

export function updateError(state = null, action) {
  switch (action.type) {
    case 'ONEWAYINTERVIEW_SHARE_LINK_UPDATE_ERROR':
      return action.updateError;

    default:
      return state;
  }
}

export function updateLoading(state = false, action) {
  switch (action.type) {
    case 'ONEWAYINTERVIEW_SHARE_LINK_UPDATE_LOADING':
      return action.updateLoading;

    default:
      return state;
  }
}

export function updated(state = null, action) {
  switch (action.type) {
    case 'ONEWAYINTERVIEW_SHARE_LINK_UPDATE_SUCCESS':
      return action.updated;

    default:
      return state;
  }
}

export default combineReducers({

  updateError,
  updateLoading,
  updated,
});
