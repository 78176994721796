import React, {Component} from "react";
import CandidateCard from "./CandidateCard";
import PropTypes from "prop-types";
import {getLiveInterviewInvitationUrlByCode} from "../../utils/urls";

class InvitationCard extends Component {
  static propTypes = {
    index: PropTypes.number,
    invitation: PropTypes.object.isRequired,
    checkPermissions: PropTypes.bool,
    cardComponent: PropTypes.elementType,
  };

  static defaultProps = {
    checkPermissions: false,
  };

  render() {
    const { invitation, checkPermissions, cardComponent } = this.props;
    const companyCandidate = invitation.companyCandidate ? invitation.companyCandidate :
      {
        salutation: invitation.salutation,
        firstName: invitation.firstName,
        lastName: invitation.lastName,
        phoneNumber: invitation.phoneNumber,
        formattedPhoneNumber: invitation.formattedPhoneNumber,
        email: invitation.email,
      };
    const invitationLink = invitation['invitationCode'] ? getLiveInterviewInvitationUrlByCode(invitation['invitationCode']) : null;

    if (cardComponent) {
      return React.createElement(cardComponent, {
        companyCandidate: companyCandidate,
        link: invitationLink,
        ...this.props,
      });
    }

    return (
      <CandidateCard companyCandidate={companyCandidate} link={invitationLink} checkPermissions={checkPermissions} />
    )
  }
}

export default InvitationCard;
