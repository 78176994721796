import WebmFile from "./webmFile";

export function fixWebmDuration(blob, duration) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onloadend = function() {
        try {
          const file = new WebmFile(new Uint8Array(reader.result));
          if (file.fixDuration(duration)) {
            blob = file.toBlob();
          }
          resolve(blob);
        } catch (ex) {
          reject(ex);
        }
      };
      reader.readAsArrayBuffer(blob);
    } catch (ex) {
      reject(ex);
    }
  })
}
