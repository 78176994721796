import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {list, reset, filter, sort} from '../../actions/LiveInterview/scheduleList';
import filtersStorage from '../../utils/filtersStorage';
import LiveInterviewListTable from "./LiveInterviewListTable";
import ScheduleFilterForm from "./ScheduleFilterForm";
import {handleListUpdate} from "../../utils/entityList";
import {getRoutePage} from "../../utils/routes";

const defaultFilters = {
  'completedAt__exists__': false,
};

class CandidateLiveInterviewSchedule extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    filter: PropTypes.func.isRequired,
    filtersData: PropTypes.object,
    sort: PropTypes.func.isRequired,
    sortData: PropTypes.object
  };

  componentDidMount() {
    const storageName = 'candidate_live_interview_schedule';
    this.props.filter(filtersStorage.get(storageName, defaultFilters));
    this.props.sort(filtersStorage.getSort(storageName));
  }

  componentDidUpdate(prevProps) {
    handleListUpdate('live_interviews', this.props, prevProps);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    return (
      <div>
        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}
        <ScheduleFilterForm
          defaultValues={defaultFilters}
          initialValues={this.props.filtersData}
          onSubmit={this.props.filter}
          resetFilter={this.props.filterReset}
        />
        <LiveInterviewListTable
          type="scheduled"
          sort={this.props.sort}
          sortData={this.props.sortData}
          retrieved={this.props.retrieved}
          page={getRoutePage(this.props.match)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    retrieved,
    loading,
    error,
    filtersData,
    sortData,
  } = state.liveinterview.scheduleList;
  return { retrieved, loading, error, filtersData, sortData };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: () => dispatch(reset()),
  filter: (filtersData) => dispatch(filter(filtersData)),
  filterReset: () => dispatch(filter(defaultFilters)),
  sort: (sortData) => dispatch(sort(sortData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateLiveInterviewSchedule);
