import moment from "moment";
import storage from "./storage";
import {getOpenViduSingleton} from "./OpenViduInstance";
import {isIphone} from "./userAgent";
import {createStreamManager} from "./streamManager";
import {isObject} from "./object";

const INTERVIEW_STORAGE_KEY = 'on-demand-interviews';
const CONFIG_STORAGE_KEY = 'on-demand-interview-video-config';
const VIDEO_QUESTIONS_RERECORDS_QUANTITY_KEY = 'video_questions_rerecords_quantity_';

const LAST_ANSWER_VIDEO_KEY = 'last_video_answer_for_question_';
const LAST_ANSWER_AUDIO_KEY = 'last_audio_answer_for_question_'

const VIDE_QUESTION_START_THINK_TIME = 'video_question_start_think_time_';
const VIDE_QUESTION_END_THINK_TIME = 'video_question_end_think_time_';

const VIDE_QUESTION_START_TOTAL_TIME = 'video_question_start_total_time_';

const LAST_SELECTED_ANSWER_TYPE = 'last_selected_answer_type_video_question_';

export function getScreeningQuestionAnswerPairs(interview) {
  let pairs = [];
  if (!interview || !interview.request) {
    return [];
  }
  const questions = interview.request.knockoutQuestions || [];
  const answers = interview.knockoutAnswers || [];
  questions.forEach((question, index) => {
    const answer = answers.find(answer => {
      return answer.knockoutQuestion === question['@id'];
    });
    pairs.push({question, answer});
  });

  return pairs;
}

export function getVideoQuestionAnswerPairs(interview) {
  let pairs = [];
  if (!interview || !interview.request) {
    return [];
  }
  const questions = interview.request.questions || [];
  const answers = interview.questionAnswers || [];
  questions.forEach((question, index) => {
    const answer = answers.find(answer => {
      return answer.question === question['@id'];
    });
    pairs.push({question, answer});
  });

  return pairs;
}

export function isInterviewExpired(request, invitation) {
  return isRequestExpired(request) || isInvitationExpired(invitation);
}

export function isRequestExpired(request) {
  if (!request) {
    return false;
  }

  const expireDate = moment(request.expiresAt);
  const currentDate = moment();

  return currentDate.diff(expireDate, 'minutes') > 1;
}

export function isInvitationExpired(invitation) {
  if (!invitation) {
    return false;
  }

  //TODO: implement invitation expiration check
  return false;
}

export function isInvitationAccepted(invitation) {
  if (!invitation) {
    return false;
  }

  return invitation.status === 'accepted';
}

export function isInvitationDeclined(invitation) {
  if (!invitation) {
    return false;
  }

  return invitation.status === 'declined';
}

export function isInterviewStarted(interview) {
  if (!interview) {
    return false;
  }

  return !!interview.startedAt;
}

export function isInterviewFinished(interview) {
  if (!interview) {
    return false;
  }

  return !!interview.completedAt;
}

export function calculateQuestionsCount(request) {
  if (!request) {
    return 0;
  }

  return getScreeningQuestionsCount(request) + getVideoQuestionsCount(request);
}

export function getVideoQuestionsCount(request) {
  if (!request) {
    return 0;
  }

  return request.questions ? request.questions.length : 0;
}

export function getScreeningQuestionsCount(request) {
  if (!request) {
    return 0;
  }

  return request.knockoutQuestions ? request.knockoutQuestions.length : 0;
}

export function videoResponseIsRequired(request) {
  if (!request) {
    return false;
  }

  if (!request.questions || request.questions.length === 0) {
    return false;
  }

  const requiredVideoQuestion = request.questions.find((question) => {
    return question.required && question.allowVideoAnswer && !question.allowTextAnswer;
  });

  return !!requiredVideoQuestion;
}

export function isQuestionHasRecordTimeLimit(question) {
  return !!(question.answerTimeLimitMinutes || question.answerTimeLimitSeconds);
}

export function thinkTimeIsEnabledForQuestion(question) {
  return question.thinkTimeLimitMinutes > 0 || question.thinkTimeLimitSeconds > 0;
}

export function isQuestionHasRecordAttemptLimit(question) {
  return !!question.maxAnswerRecordingRetries;
}

export function hasAllowedReRecordAttemptsForVideoQuestion(question) {
  return (
    !isQuestionHasRecordAttemptLimit(question) ||
    question.maxAnswerRecordingRetries > getRecordAttemptsForVideoQuestion(question)
  );
}

export function getRecordAttemptsForVideoQuestion(question) {
  if (!isQuestionHasRecordAttemptLimit(question)) {
    return 0;
  }

  let key = VIDEO_QUESTIONS_RERECORDS_QUANTITY_KEY + question.id;
  let quantity = parseInt(storage.get(key));
  return isNaN(quantity) ? 0 : quantity;
}

export function incrementRecordAttemptsForVideoQuestion(question) {
  const key = VIDEO_QUESTIONS_RERECORDS_QUANTITY_KEY + question.id;
  let quantity = storage.get(key) ? storage.get(key) : 0;
  quantity++;
  storage.set(key, quantity);

  return quantity;
}

function clearRecordAttemptsForVideoQuestion(question) {
  storage.remove(VIDEO_QUESTIONS_RERECORDS_QUANTITY_KEY + question.id);
}

export function getLastVideoAnswerForVideoQuestion(question) {
  return storage.get(LAST_ANSWER_VIDEO_KEY + question.id);
}

export function setLastVideoAnswerForVideoQuestion(question, answer) {
  const key = LAST_ANSWER_VIDEO_KEY + question.id;
  const answerToSave = {
    '@id': answer['@id'],
    '@type': answer['@type'],
    'videoUrl': answer['videoUrl'],
  }
  let videoAnswerLifetime = 12 * 60 * 60;
  storage.setExpires(key, answerToSave, videoAnswerLifetime);
}

function clearLastVideoAnswerForVideoQuestion(question) {
  storage.remove(LAST_ANSWER_VIDEO_KEY + question.id);
}

export function getLastAudioAnswerForVideoQuestion(question) {
  return storage.get(LAST_ANSWER_AUDIO_KEY + question.id);
}

export function setLastAudioAnswerForVideoQuestion(question, answer) {
  const key = LAST_ANSWER_AUDIO_KEY + question.id;
  const answerToSave = {
    '@id': answer['@id'],
    '@type': answer['@type'],
    'audioUrl': answer['audioUrl'],
  }
  let audioAnswerLifetime = 12 * 60 * 60;
  storage.setExpires(key, answerToSave, audioAnswerLifetime);
}

function clearLastAudioAnswerForVideoQuestion(question) {
  storage.remove(LAST_ANSWER_AUDIO_KEY + question.id);
}

export function clearStorageForQuestion(question) {
  clearLastVideoAnswerForVideoQuestion(question);
  clearLastAudioAnswerForVideoQuestion(question);
  clearRecordAttemptsForVideoQuestion(question);
  clearStorageForThinkTimeVideoQuestion(question);
  clearLastAnsweredTypeForVideoQuestion(question);
  clearTotalTimeForVideoQuestion(question);
}

export function getQuestionRecorderText(question) {
  if (question.answerTimeLimitMinutes < 1 && question.answerTimeLimitMinutes < 1) {
    return null;
  }

  let placeholder = getPlaceholderTimeText(question.answerTimeLimitMinutes, question.answerTimeLimitSeconds);

  return `You will have ${placeholder} to record your response.`;
}

export function getVideoQuestionThinkTimeText(question) {
  if (!thinkTimeIsEnabledForQuestion(question)) {
    return null;
  }

  const totalSeconds = (question.thinkTimeLimitMinutes * 60) + question.thinkTimeLimitSeconds + question.questionVideo.duration;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60

  let placeholder = getPlaceholderTimeText(minutes, seconds);

  return `You will have ${placeholder} before your recording starts automatically. The countdown will begin after you click "Play".`;
}

function getPlaceholderTimeText(minutes, seconds) {
  let placeholder = '';
  if (!minutes && !seconds) {
    return '';
  }

  if (minutes) {
    placeholder = minutes === 1 ? '1 minute ' : minutes + ' minutes ';
  }

  if (seconds) {
    if (minutes) {
      placeholder += 'and '
    }
    placeholder += seconds === 1 ? '1 second' : seconds + ' seconds';
  }

  return placeholder;
}

export function getQuestionAnswerTime(videoQuestion) {
  return timeLimitTextFormat(videoQuestion.answerTimeLimitMinutes, videoQuestion.answerTimeLimitSeconds);
}

export function getQuestionThinkTime(videoQuestion) {
  return timeLimitTextFormat(videoQuestion.thinkTimeLimitMinutes, videoQuestion.thinkTimeLimitSeconds);
}

export function timeLimitTextFormat(minutes, seconds) {
  let text = '';
  if (!minutes) {
    text += '00'
  } else {
    text += (minutes < 10 ? '0' + minutes : minutes);
  }

  text += ':';

  if (!seconds) {
    text += '00';
  } else {
    text += (seconds < 10 ? '0' + seconds : seconds);
  }

  return text;
}

export function getRequestVideoQuestionByIndex(request, index) {
  const questions = request.questions;
  if (index === null || index < 0 || index > questions.length - 1) {
    return null;
  }

  return questions[index];
}

export function saveInterviewCode(interview) {
  let interviews = storage.get(INTERVIEW_STORAGE_KEY);
  if (!interviews) {
    interviews = {};
  }
  interviews[interview.requestRoomCode] = interview['interviewCode'];
  storage.set(INTERVIEW_STORAGE_KEY, interviews)
}

export function loadInterviewCode(requestRoomCode) {
  const interviews = storage.get(INTERVIEW_STORAGE_KEY);
  return interviews && interviews[requestRoomCode] ? interviews[requestRoomCode] : null;
}

export function clearInterviewCodes() {
  storage.remove(INTERVIEW_STORAGE_KEY);
}

export function saveAudioDeviceConfig(deviceId) {
  const config = loadStreamManagerConfig();
  config.audioSource = deviceId;
  saveStreamManagerConfig(config);
}

export function saveVideoDeviceConfig(deviceId) {
  const config = loadStreamManagerConfig();
  config.videoSource = deviceId;
  saveStreamManagerConfig(config);
}

function saveStreamManagerConfig(config) {
  storage.set(CONFIG_STORAGE_KEY, config);
}

function loadStreamManagerConfig() {
  const config = storage.get(CONFIG_STORAGE_KEY);
  return config || {};
}

export function clearStreamManagerConfig() {
  storage.remove(CONFIG_STORAGE_KEY);
}

export function setStartThinkTimeForVideoQuestion(videoQuestion, addDuration = false) {
  if (thinkTimeIsEnabledForQuestion(videoQuestion)) {
    const key = VIDE_QUESTION_START_THINK_TIME + videoQuestion.id;
    let time = new Date().getTime();
    if (addDuration) {
      time += (parseInt(videoQuestion.questionVideo.duration) * 1000);
    }
    storage.set(key, time);
  }
}

export function getStartThinkTimeForVideoQuestion(videoQuestion) {
  if (thinkTimeIsEnabledForQuestion(videoQuestion)) {
    const key = VIDE_QUESTION_START_THINK_TIME + videoQuestion.id;
    return storage.get(key);
  }
}

export function setEndThinkTimeForVideoQuestion(videoQuestion) {
  if (thinkTimeIsEnabledForQuestion(videoQuestion)) {
    const key = VIDE_QUESTION_END_THINK_TIME + videoQuestion.id;
    storage.set(key, new Date().getTime());
  }
}

export function getSpentThinkTimeForVideoQuestion(videoQuestion) {
  if (thinkTimeIsEnabledForQuestion(videoQuestion)) {
    const start_time_key = VIDE_QUESTION_START_THINK_TIME + videoQuestion.id;
    const end_time_key = VIDE_QUESTION_END_THINK_TIME + videoQuestion.id;
    let spentThinkTime = parseInt(storage.get(end_time_key)) - parseInt(storage.get(start_time_key));
    spentThinkTime = Math.floor(spentThinkTime / 1000);

    if (isNaN(spentThinkTime) || spentThinkTime < 0) {
      spentThinkTime = 0; /* case when candidate start recording answer before video question is ended */
    }

    return spentThinkTime;
  }
}

export function getRemainingThinkTimeForVideoQuestion(videoQuestion, date = null) {
  if (thinkTimeIsEnabledForQuestion(videoQuestion)) {
    const startTimeKey = VIDE_QUESTION_START_THINK_TIME + videoQuestion.id;
    const startTime = parseInt(storage.get(startTimeKey));
    if (!startTime) {
      return -1;
    }

    const thinkTimeSeconds =  videoQuestion.thinkTimeLimitSeconds + (60 * videoQuestion.thinkTimeLimitMinutes);
    const remainingTime = thinkTimeSeconds - ((new Date().getTime() - startTime) / 1000);


    if (remainingTime < 1) {
      return 0;
    }

    return remainingTime;
  }

  return -1;
}

export function clearStorageForThinkTimeVideoQuestion(videoQuestion) {
  const startTimeKey = VIDE_QUESTION_START_THINK_TIME + videoQuestion.id;
  const endTimeKey = VIDE_QUESTION_END_THINK_TIME + videoQuestion.id;

  storage.remove(startTimeKey);
  storage.remove(endTimeKey);
}

export function getTotalTimeSpentForVideoQuestion(videoQuestion) {
  const startKey = VIDE_QUESTION_START_TOTAL_TIME + videoQuestion.id;
  const startTime = parseInt(storage.get(startKey));
  const spentMilliseconds = (new Date().getTime() - startTime) / 1000;

  return Math.floor(spentMilliseconds);
}

export function setStartTotalTimeForVideoQuestion(videoQuestion) {
  if (!getTotalTimeSpentForVideoQuestion(videoQuestion)) {
    const startTimeKey = VIDE_QUESTION_START_TOTAL_TIME + videoQuestion.id;
    storage.set(startTimeKey, new Date().getTime());
  }
}

export function clearTotalTimeForVideoQuestion(videoQuestion) {
  const startTimeKey = VIDE_QUESTION_START_TOTAL_TIME + videoQuestion.id;
  storage.remove(startTimeKey);
}

export function setLastSelectedAnswerTypeForVideoQuestion(videoQuestion, answerType) {
  storage.set(LAST_SELECTED_ANSWER_TYPE + videoQuestion.id, answerType);
}

export function getLastSelectedAnswerTypeForVideoQuestion(videoQuestion) {
  return storage.get(LAST_SELECTED_ANSWER_TYPE + videoQuestion.id);
}

export function clearLastAnsweredTypeForVideoQuestion(videoQuestion) {
  return storage.remove(LAST_SELECTED_ANSWER_TYPE + videoQuestion.id);
}

export function createVideoPublisher(config = {}) {
  const OV = getOpenViduSingleton();

  const publisherConfig = Object.assign({}, loadStreamManagerConfig(), config);
  if (isIphone()) {
    publisherConfig['audioSource'] = undefined;
  }

  return createStreamManager(OV, publisherConfig)
  .catch(error => {
    if (['INPUT_AUDIO_DEVICE_NOT_FOUND', 'INPUT_VIDEO_DEVICE_NOT_FOUND'].includes(error.name)) {
      const fallbackConfig = Object.assign({}, publisherConfig, {
        audioSource: undefined,
        videoSource: undefined,
      });
      return createStreamManager(OV, fallbackConfig);
    } else {
      throw error;
    }
  });
}

export function createAudioPublisher(config = {}) {
  const OV = getOpenViduSingleton();

  const mergedConfig = Object.assign({}, {
    audioSource: undefined,
    publishAudio: true,
  }, loadStreamManagerConfig(), config, {
    videoSource: false,
    publishVideo: false,
  });

  if (isIphone()) {
    mergedConfig['audioSource'] = undefined;
  }

  return new Promise((resolve, reject) => {
    let publisher = OV.initPublisher(undefined, mergedConfig, (error) => {
      if (error) {
        if (['INPUT_AUDIO_DEVICE_NOT_FOUND'].includes(error.name)) {
          publisher = OV.initPublisher(undefined, Object.assign({}, mergedConfig, {
            audioSource: undefined,
          }), (error) => {
            if (error) {
              return reject(error);
            }
            return resolve(publisher);
          });
        } else {
          return reject(error);
        }
      } else {
        return resolve(publisher);
      }
    });
  });
}

export const getDefaultExpiresAtForInvitations = (request) => {
  let expiresAt = null;
  let expiresAtInvitations = null;
  if (request['invitationExpirationDays']) {
    expiresAtInvitations = moment().add(parseInt(request['invitationExpirationDays']), 'days');
    expiresAtInvitations.set({hour: 23, minute: 59,second: 59});
  }

  if (request['expiresAt']) {
    expiresAt = moment(request['expiresAt']);
    expiresAt.set({hour: 23, minute: 59, second: 59});
  }

  if (expiresAtInvitations && expiresAt && expiresAt.diff(moment(), 'minutes') > 0) {
    return expiresAtInvitations.unix() < expiresAt.unix() ?
      expiresAtInvitations :
      expiresAt;
  }

  if (expiresAtInvitations) {
    return expiresAtInvitations;
  }

  if (expiresAt && expiresAt.diff(moment(), 'minutes') > 0) {
    return expiresAt;
  }

  return null;
}
