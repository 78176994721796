import React, {Component} from "react";
import CardBody from "../Card/CardBody";
import Card from "../Card/Card";
import Rating from 'material-ui-rating'
import {
  AVERAGE_RATING,
} from "./DefaultCriteriaNames";
import PropTypes from "prop-types";
import {CardActions} from "@material-ui/core";
import '../Review/ReviewCard.scss';

class ReviewCard extends Component {
  static propTypes = {
    review: PropTypes.object.isRequired,
    reviewCriteria: PropTypes.object.isRequired,
    actions: PropTypes.object,
  };

  static defaultProps = {
    reviewCriteria: {},
  };

  renderCriteriaName = (criteriaName, wasChanged) => {
    if (wasChanged) {
      return (
        <b>
          {criteriaName} <span className={'required-asterisk'}>*</span>
        </b>
      )
    }
    return criteriaName;
  }

  criteriaWasChanged = (criteria, reviewDate) => {
    return criteria && criteria.updatedAt && Date.parse(criteria.updatedAt) > reviewDate;
  }

  renderCriteria = (criteriaNumber, wasChanged) => {
    const {review, reviewCriteria} = this.props;
    const criteria = reviewCriteria[criteriaNumber];

    return (
      <div className="reviews-list-rating-container" key={`criteria-container-${criteriaNumber}`}>
        <span className="info-xs">
          {this.renderCriteriaName(criteria.name, wasChanged)}
        </span>
        <Rating
          value={review['criteria' + criteriaNumber]}
          max={5}
          className="reviews-list-rating"
          readOnly={true}
        />
        <span className={'info'}> - {this.renderCriteriaName(criteria.name, wasChanged)}</span>
      </div>
    );
  }

  render() {
    const {review, reviewCriteria, actions} = this.props;
    const reviewUpdatedAt = Date.parse(this.props.review.updatedAt);
    let criteriaChanges = {};
    Object.keys(reviewCriteria).forEach(number => {
      criteriaChanges[number] = this.criteriaWasChanged(reviewCriteria[number], reviewUpdatedAt)
    })
    const anyCriteriaChanged = Object.values(criteriaChanges).reduce(
      (cumulativeValue, currentValue) => cumulativeValue || currentValue
    , false);

    const user = review.companyUser;
    return (
      <Card className="review-card">
        <CardBody>
          {user && (
            <h4>{user.firstName} {user.lastName}</h4>
          )}
          <div className="reviews-list-rating-container mb-2">
            <span className="info-xs"><strong>{review.averageRating}</strong> {AVERAGE_RATING}</span>
            <Rating
              value={review.averageRating}
              max={5}
              className="reviews-list-rating float-left"
              readOnly={true}
            />
            <span className="float-left info"> - <strong>{review.averageRating}</strong> {AVERAGE_RATING}</span>
            <div className="clearfix" />
          </div>
          {Object.keys(reviewCriteria).map(number => this.renderCriteria(number, criteriaChanges[number]))}
          {review.comment && (
            <>
              <hr className="mb-0"/>
              <div className="mt-2">{review.comment}</div>
            </>
          )}
        </CardBody>
        {actions && (
          <CardActions className={'review-card-actions'}>
            {actions}
          </CardActions>
        )}
        {anyCriteriaChanged && (
          <p className={'text-muted ml-2'}>
            <em>*Criteria was customized after review was submitted</em>
          </p>
        )}
      </Card>
    )
  }
}

export default ReviewCard;
