import {Field} from "redux-form";
import {renderCheckbox, renderTextField} from "../../utils/form";
import React from "react";
import {normalizeMaxLength} from "./Form";
import {LIMITATION_JOBS, LIMITATION_STORAGE, LIMITATION_USERS, planLimitationLabels} from "../../utils/plan";
import InputAdornment from "@material-ui/core/InputAdornment";

export function FormPlanLimitations({fields: limitations, meta: {error}, initialValues}, ) {
  return (
    <div className={'plan-limitations'}>
      <p className={"section-label"}>FEATURES SETTINGS  <span className={"text-danger"}>*</span></p>
      {limitations.map((fieldName, i) => {
        const limitation = limitations.get(i);
        const limitationKey = limitation['key'];

        const InputProps = limitationKey === LIMITATION_STORAGE ?
          {endAdornment: <InputAdornment position="end">GB</InputAdornment>} :
          {};

        return (
          <div key={i}>
            <div className={"plan-limitation"}>
              <div className={"title"}>
                {planLimitationLabels[limitationKey]['label']}
              </div>
              <Field
                component={renderTextField}
                label={false}
                name={`${fieldName}[quantity]`}
                type="number"
                placeholder={planLimitationLabels[limitationKey]['placeholder']}
                required={!limitation['unlimited']}
                containerClass={"form-group quantity"}
                inputProps={{step: 1}}
                normalize={v => v ? parseInt(normalizeMaxLength(v, 8)) : v}
                htmlRequired={!limitation['unlimited']}
                InputProps={InputProps}
              />
              <Field
                component={renderCheckbox}
                label={"Unlimited"}
                name={`${fieldName}[unlimited]`}
                containerClass={"unlimited"}
              />
              <Field
                component={renderCheckbox}
                label={"Hide This Feature"}
                name={`${fieldName}[hidden]`}
                containerClass={"hide-this-feature"}
              />
            </div>
            {isQuantityDecreased(limitation, initialValues) && (
              <div className={"plan-change-warning mt-1 mb-1"}>
                <i className="fa fa-exclamation-circle" aria-hidden="true" />
                {getDecreaseWarningMessage(limitation)}
              </div>
            )}
          </div>
        )
      })}
    </div>
  );
}

const getDecreaseWarningMessage = (limitation) => {
  switch (limitation.key) {
    case LIMITATION_USERS:
      return <React.Fragment><span>You’re decreasing the number of users. Companies on this plan exceeding the new limit will retain all users but they will not be able to add or edit users until they have <b>{limitation['quantity']} or less</b> active users</span></React.Fragment>;
    case LIMITATION_JOBS:
      return <React.Fragment><span>You’re decreasing the number of job postings. Companies on this plan exceeding the new limit will retain all job postings but they will not be able to add or edit job postings until they have <b>{limitation['quantity']} or less</b> active job postings</span></React.Fragment>;
  }
}

const isQuantityDecreased = (limitation, initialLimitations) => {
  if (limitation['key'] === LIMITATION_STORAGE) {
    return false;
  }

  const initLimitation = initialLimitations.find(initLimitation => limitation.key === initLimitation.key);

  if (limitation['unlimited'] || (0 !== limitation['quantity'] && !limitation['quantity'])) {
    return false;
  }

  return (initLimitation['unlimited'] && !limitation['unlimited']) || (initLimitation['quantity'] > limitation['quantity']);
}
