import {getAuthToken, isAuthTokenExpired} from "./auth";
import storage from "./storage";
import socketIOClient from "socket.io-client";
import {VIDEO_ENTRYPOINT} from "../config/entrypoint";
import {refreshToken} from "./authTokenRefresh";
import {history} from "../store";

function connectToSocket(interviewId) {
  return new Promise(async (resolve, reject) => {
    const tokenExpired = isAuthTokenExpired(getAuthToken());
    if (tokenExpired) {
      try {
        await refreshToken();
      } catch (e) {
        history.push('/login');
        reject(e);
        return;
      }
    }

    const headers = {
      'Authorization': 'Bearer ' + getAuthToken(),
      'X-Interview-Id': interviewId,
    };
    let xSwitchUser = storage.get('X-Switch-User');
    if (null !== xSwitchUser) {
      headers['X-Switch-User'] = xSwitchUser;
    }

    const socket = socketIOClient(VIDEO_ENTRYPOINT + '/live-interview', {
      transportOptions: {
        polling: {
          extraHeaders: headers,
        }
      }
    });

    resolve(socket);
  });
}

export {
  connectToSocket,
}
