import {
  fetch,
  extractHubURL,
  normalize,
  mercureSubscribe as subscribe
} from '../../utils/dataAccess';
import {success as deleteSuccess} from "./delete";
import {copyVideoEntity, isVideoNeedToReEncode} from "../../utils/videoEntity";
import {MERCURE_ENTRYPOINT} from "../../config/entrypoint";

export function error(error) {
  return { type: 'REQUESTTEMPLATE_SHOW_ERROR', error };
}

export function loading(loading) {
  return { type: 'REQUESTTEMPLATE_SHOW_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'REQUESTTEMPLATE_SHOW_SUCCESS', retrieved };
}

export function retrieve(id) {
  return dispatch => {
    dispatch(loading(true));

    return fetch(id)
      .then(response =>response.data)
      .then((retrieved) => {

        dispatch(loading(false));
        dispatch(success(retrieved));

        dispatch(mercureSubscribe(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'REQUESTTEMPLATE_SHOW_RESET' });
    dispatch(error(null));
    dispatch(loading(false));
    dispatch(deleteSuccess(null));
  };
}

export function mercureSubscribe(template) {
  const request = template['request'];
  let topics = [];
  if (
    request['requestIntroVideo'] &&
    request['requestIntroVideo']['videoOrigin'] &&
    isVideoNeedToReEncode(request['requestIntroVideo'])
  ) {
    topics.push(request['requestIntroVideo']['videoOrigin']);
  }

  if (
    request['requestOutroVideo'] &&
    request['requestOutroVideo']['videoOrigin'] &&
    isVideoNeedToReEncode(request['requestOutroVideo'])
  ) {
    topics.push(request['requestOutroVideo']['videoOrigin']);
  }

  if (request['questions']) {
    request['questions'].forEach(question => {
      if (question && question['questionVideo'] &&
        question['questionVideo']['videoOrigin'] && isVideoNeedToReEncode(question['questionVideo'])
      ) {
        topics.push(question['questionVideo']['videoOrigin']);
      }
    })
  }

  return dispatch => {
    if (topics.length) {
      const eventSource = subscribe(new URL(MERCURE_ENTRYPOINT), topics);
      dispatch(mercureOpen(eventSource));
      eventSource.addEventListener('message', event =>
        dispatch(mercureMessage(normalize(JSON.parse(event.data))))
      );
    }
  };
}

export function mercureOpen(eventSource) {
  return { type: 'REQUESTTEMPLATE_SHOW_MERCURE_OPEN', eventSource };
}

export function mercureMessage(retrieved) {
  return dispatch => {
    if (1 === Object.keys(retrieved).length) {
      dispatch({ type: 'REQUESTTEMPLATE_SHOW_MERCURE_DELETED', retrieved });
      return;
    }

    if (retrieved && retrieved['@type'] === 'RequestIntroVideo') {
      dispatch({ type: 'REQUESTTEMPLATE_INTROVIDEO_SHOW_MERCURE_MESSAGE', retrieved });
      return
    }

    if (retrieved && retrieved['@type'] === 'RequestOutroVideo') {
      dispatch({ type: 'REQUESTTEMPLATE_OUTROVIDEO_SHOW_MERCURE_MESSAGE', retrieved });
      return
    }

    if (retrieved && retrieved['@type'] === 'QuestionVideo') {
      dispatch({ type: 'REQUESTTEMPLATE_QUESTION_VIDEO_SHOW_MERCURE_MESSAGE', retrieved: copyVideoEntity(retrieved) });
      return
    }

    dispatch({ type: 'REQUESTTEMPLATE_SHOW_MERCURE_MESSAGE', retrieved });
  };
}
