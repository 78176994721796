import React, {Component} from "react";
import PropTypes from "prop-types";
import OpenviduVideoRecorder from "./OpenviduVideoRecorder";
import BrowserVideoRecorder from "./BrowserVideoRecorder";

class CombinedVideoRecorder extends Component {
  static propTypes = {
    showUi: PropTypes.bool.isRequired,
    allowLocalRecording: PropTypes.bool.isRequired,
    allowedToRecord: PropTypes.bool,
    startRecordingDisabled: PropTypes.bool,
    className: PropTypes.string,
    streamManager: PropTypes.object,
    onRecordingStarting: PropTypes.func,
    onRecordingStarted: PropTypes.func,
    onRecordingStopping: PropTypes.func,
    onRecorded: PropTypes.func,
    onError: PropTypes.func,
    onVideoReset: PropTypes.func,
    videoText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    initialVideoUrl: PropTypes.string,
    buttonLabels: PropTypes.object,
    additionalButtons: PropTypes.object,
    browserRecorderConfig: PropTypes.shape({
      socketRecordingType: PropTypes.string,
      storeVideoInMemory: PropTypes.bool,
    }),
    openviduRecorderConfig: PropTypes.shape({
      getSessionUrl: PropTypes.string.isRequired,
      startRecordingUrl: PropTypes.string.isRequired,
      stopRecordingUrl: PropTypes.string.isRequired,
    })
  }

  static defaultProps = {
    allowLocalRecording: false,
    allowedToRecord: true,
    showUi: true,
    className: '',
    buttonLabels: {},
    additionalButtons: {},
  };

  constructor(props) {
    super(props);

    this.browserRecorder = React.createRef();
    this.openviduRecorder = React.createRef();
  }

  isBrowserRecordingSupported = () => {
    if (typeof MediaRecorder === 'undefined') {
      return false;
    }
    return true;
  }

  startRecording = () => {
    const recorder = this.getActiveVideoRecorderComponent();
    if (recorder) {
      recorder.startRecording();
    }
  }

  startRecordingWithUi = () => {
    const recorder = this.getActiveVideoRecorderComponent();
    if (recorder) {
      recorder.startRecordingWithUi();
    }
  }

  stopRecording = () => {
    const recorder = this.getActiveVideoRecorderComponent();
    if (recorder) {
      recorder.stopRecording();
    }
  }

  stopRecordingWithUi = () => {
    const recorder = this.getActiveVideoRecorderComponent();
    if (recorder) {
      recorder.stopRecordingWithUi();
    }
  }

  resetVideo = () => {
    const recorder = this.getActiveVideoRecorderComponent();
    if (recorder) {
      recorder.resetVideo();
    }
  }

  getActiveVideoRecorderComponent = () => {
    if (this.browserRecorder && this.browserRecorder.current) {
      return this.browserRecorder.current;
    }

    if (this.openviduRecorder && this.openviduRecorder.current) {
      return this.openviduRecorder.current;
    }

    return null;
  }

  render() {
    const {
      showUi, streamManager, allowLocalRecording, startRecordingDisabled, openviduRecorderConfig, browserRecorderConfig,
      videoText, initialVideoUrl, allowedToRecord, onRecordingStarting, onRecordingStarted, onRecordingStopping, onRecorded,
      onError, onVideoReset, className, buttonLabels, additionalButtons,
    } = this.props;
    const useBrowserRecorder = allowLocalRecording && this.isBrowserRecordingSupported();
    const commonProps = {
      showUi, className, streamManager, onRecordingStarting, onRecordingStarted, buttonLabels, startRecordingDisabled,
      onError, onRecordingStopping, onRecorded, onVideoReset, videoText, initialVideoUrl, allowedToRecord, additionalButtons
    }
    return useBrowserRecorder ? (
      <BrowserVideoRecorder
        {...Object.assign({}, commonProps, browserRecorderConfig)}
        ref={this.browserRecorder}
      />
    ) : (
      <OpenviduVideoRecorder
        {...Object.assign({}, commonProps, openviduRecorderConfig)}
        ref={this.openviduRecorder}
      />
    );
  }
}

export default CombinedVideoRecorder;
