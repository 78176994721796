import { combineReducers } from 'redux';
import list from './list';
import create from './create';
import update from './update';
import show from './show';
import forSelect from './forSelect';
import activate from './activate';
import deactivate from './deactivate';

export default combineReducers({ list, create, update, show, forSelect, activate, deactivate });
