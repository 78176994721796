import { SubmissionError } from 'redux-form';
import {
  fetch,
  extractHubURL,
  normalize,
} from '../../utils/dataAccess';

export function error(error) {
  return { type: 'LIVEINTERVIEW_TAGS_UPDATE_ERROR', error };
}

export function loading(loading) {
  return { type: 'LIVEINTERVIEW_TAGS_UPDATE_LOADING', loading };
}

export function updateSuccess(updated) {
  return { type: 'LIVEINTERVIEW_TAGS_UPDATE_SUCCESS', updated };
}

export function updateTags(item, values) {
  return dispatch => {
    dispatch(error(null));
    dispatch(loading(true));

    let endpoint = '/live_interviews/update_tags/' + item['id'];
    return fetch(endpoint, {
      method: 'PUT',

      data: values
    })
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        retrieved = normalize(retrieved);

        dispatch(loading(false));
        dispatch(updateSuccess(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw e;
        }

        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return { type: 'LIVEINTERVIEW_TAGS_UPDATE_RESET' };
}
