import React from "react";
import {formatPlanPrice} from "../../utils/plan";
import {LIMITATION_USERS, LIMITATION_JOBS, LIMITATION_STORAGE, PREVIEW_PERIOD_ANNUAL} from "../../utils/plan";
import "./Style.scss";

export function PlanCard({plan, onSelect, period, selected, current}) {
  const onClick = (e) => {
    e.preventDefault();
    if (typeof onSelect === 'function') {
      onSelect(plan, e);
    }
  }

  const planLimitations = plan['planLimitations'] ?
    plan['planLimitations'].filter(v => !v.hidden) :
    [];

  let price = period === PREVIEW_PERIOD_ANNUAL ?
    formatPlanPrice(plan.annualPrice) :
    formatPlanPrice(plan.monthlyPrice);

  let hugePriceClass = '';
  if (period === PREVIEW_PERIOD_ANNUAL && plan.annualPrice < (plan.monthlyPrice * 12)) {
    const discountPrice = formatPlanPrice(Math.round(plan.monthlyPrice) * 12);
    hugePriceClass = (discountPrice.length + price.length) > 20 ? ' huge-price' : '';
    price = (
      <>
        <span>{price} </span>
        <span className={"discount"}>{discountPrice}</span>
      </>
    );
  }

  let classesList = 'plan-card';
  if (selected) {
    classesList += ' selected';
  }
  if (current) {
    classesList += ' current';
  }

  return (
    <div className={classesList}>
      {plan.description && (
        <div className={'plan-card-top'}><span>{plan.description}</span></div>
      )}
      <div className={"plan-card-main-info"}>
        <div className={'plan-card-title'}>{plan.name || (<span>Plan Name</span>)}</div>
        <div className={'plan-card-price' + hugePriceClass}>{price}</div>
        <div className={'plan-card-period'}>{period === 'annual' ? 'per year' : 'per month'}</div>
      </div>
      <button
        type={"button"}
        className={'plan-card-select-button'}
        onClick={onClick}
      >
        {current ? 'Current Plan' : 'Select This Plan'}
      </button>
      <div className={"plan-card-limitations"}>
        {planLimitations.map((planLimitation, i) => (
          <div className={"plan-card-limitation"} key={i}>
            <i className={"fa fa-check"} />
            <span>{generateLimitationLabel(planLimitation)}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export function generateLimitationLabel(planLimitation) {
  const singularLabels = {};
  singularLabels[LIMITATION_USERS] = 'User';
  singularLabels[LIMITATION_JOBS] = 'Job';
  singularLabels[LIMITATION_STORAGE] = 'GB of Video Storage';

  const pluralLabels = {};
  pluralLabels[LIMITATION_USERS] = 'Users';
  pluralLabels[LIMITATION_JOBS] = 'Jobs';
  pluralLabels[LIMITATION_STORAGE] = 'GB of Video Storage';

  let quantity = planLimitation['quantity'] || 0;
  if (planLimitation['unlimited']) {
    quantity = 'Unlimited';
  }

  const label = !planLimitation['unlimited'] && (parseInt(quantity) === 1) ?
    singularLabels[planLimitation['key']] :
    pluralLabels[planLimitation['key']];

  return quantity + ' ' + label;
}

export function generateLimitationUsageLabel(planLimitation, usageData) {
  let usage = usageData[planLimitation['key']];
  if (planLimitation['key'] === LIMITATION_STORAGE) {
    let quantity = planLimitation['unlimited'] ? 'Unlimited' : planLimitation['quantity'];
    return usage + '/' + quantity + ' GB storage used';
  }

  let label = generateLimitationLabel(planLimitation);

  return usage + '/' + label.toLowerCase();
}
