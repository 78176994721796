import {fetchJSON} from "../../utils/dataAccess";

export function loadStatesForSelect(valueProperty = 'id') {
  return dispatch => {
    dispatch(loading(true));
    fetchJSON('/states?pagination=false').then((states) => {
      dispatch(statesLoaded(states['hydra:member'].map(
        function (state) {
          return {
            value: state[valueProperty],
            text: state.name,
            country: state.country['@id'],
          }
        }
      )));
      dispatch(loading(false));
    }).catch(e => {
      dispatch(loading(false));
      dispatch(error(e.message));
    })
  };
}

export function loading(loading) {
  return {type: 'STATES_FOR_SELECT_LOADING', loading};
}

export function error(error) {
  return {type: 'STATES_FOR_SELECT_ERROR', error};
}

export function statesLoaded(retrieved) {
  return {type: 'STATES_FOR_SELECT_RETRIEVED', retrieved};
}
