import React, {Component} from "react";
import PropTypes from "prop-types";
import LabeledProgressIndicator from "./LabeledProgressIndicator";
import "./VideoProgressIndicator.scss";

class VideoProgressIndicator extends Component {
  static propTypes = {
    label: PropTypes.string,
    progress: PropTypes.number,
    className: PropTypes.string,
  }

  render() {
    const {label, progress, className} = this.props;
    return (
      <div className={'video-progress-indicator ' + className}>
        {label && (<span className={'indicator-text'}>{label}</span>)}
        <LabeledProgressIndicator value={progress} color={'secondary'}/>
      </div>
    )
  }
}

export default VideoProgressIndicator;

