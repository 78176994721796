import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { list, reset } from '../../actions/LiveInterviewReview/list';
import { del } from '../../actions/LiveInterviewReview/delete';
import {applyToUrl} from "../../utils/urls";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import { Link } from 'react-router-dom';
import IsGranted from "../IsGranted";
import {retrieve as retrieveInterview} from "../../actions/LiveInterview/show";
import storage from "../../utils/storage";
import FlashMessages from "../../views/Components/FlashMessages";
import ReviewActions from "../Review/Actions";
import ReviewCard from "../Review/Card";

import '../Review/ReviewList.scss';
import {getReviewScales, reset as resetCriteria} from "../../actions/ReviewScale/show";
import {getReviewCriteria} from "../../utils/reviewScales";
import {getUserId} from "../../utils/auth";

class LiveInterviewReviewList extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    deleted: PropTypes.object,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieveInterview: PropTypes.func.isRequired,
    interview: PropTypes.object,
  };

  state = {
    flashMessage: null,
  };

  componentDidMount() {
    this.props.getReviewScales();
    this.loadAllReviews();
    this.props.retrieveInterview(decodeURIComponent(this.props.match.params.interview));
  }

  componentWillUnmount() {
    this.props.reset();
    this.props.resetCriteria();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.deleted && !prevProps.deleted) {
      this.setState({flashMessage: 'Review successfully deleted.'});
    }
  }

  del = (item) => {
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.del(item);
  };

  loadAllReviews = () => {
    const interviewId = decodeURIComponent(this.props.match.params.interview);
    const url = applyToUrl('live_interview_reviews', {liveInterview: interviewId});
    this.props.list(url);
  };

  render() {
    if (this.props.deleted) {
      this.props.reset();
      this.loadAllReviews();
    }

    let button = (
      <IsGranted action="create" object={this.props.retrieved}>
        <Link to="review" className="btn btn-info">Add Review</Link>
      </IsGranted>
    );
    if (this.props.retrieved) {
      let currentUserId = getUserId();
      this.props.retrieved['hydra:member'].map(item => {
        if (item.companyUser.id === currentUserId) {
          button = (<Link to={`review/${encodeURIComponent(item['@id'])}`} className="btn btn-info">Edit Review</Link>);
        }
      });
    }

    return (
      <div>
        {this.props.interview && (
          <h2 className="mb-3">All Reviews of "{this.props.interview.title}" Interview.</h2>
        )}

        <FlashMessages location={this.props.location} />
        {this.state.flashMessage && (
          <FlashMessages type={"success"} message={this.state.flashMessage} duration={10000} />
        )}
        {(this.props.loading) && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}

        {!this.props.loading && (
          button
        )}

        <GridContainer>
        {!this.props.loading && this.props.retrieved && this.props.retrieved['hydra:member'].map(item => (
          <GridItem lg={4} md={6} sm={12} key={item.id} className="reviews-list-item">
            <ReviewCard
              review={item}
              reviewCriteria={getReviewCriteria(this.props.modifiedCriteria)}
              actions={
                <ReviewActions review={item} handleDelete={() => {this.del(item)}}/>
              }
            />
          </GridItem>
        ))}
        </GridContainer>

        <div className="mt-4">
          <Link to={`../../live-interviews/show/${this.props.match.params.interview}`} className="btn btn-primary mr-3">
            Back to Interview
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.liveinterviewreview.list.loading || state.liveinterviewreview.del.loading || state.liveinterview.show.loading || state.reviewScale.show.loading,
    error : state.liveinterviewreview.list.error || state.liveinterviewreview.del.error || state.liveinterview.show.error,
    retrieved : state.liveinterviewreview.list.retrieved,
    modifiedCriteria: state.reviewScale.show.criteria,
    deleted: state.liveinterviewreview.del.deleted,
    interview: state.liveinterview.show.retrieved,
  };
};

const mapDispatchToProps = dispatch => ({
  list : (id) => dispatch(list(id)),
  getReviewScales: () => dispatch(getReviewScales()),
  del  : (values) => dispatch(del(values)),
  reset: () => dispatch(reset()),
  resetCriteria: () => dispatch(resetCriteria()),
  retrieveInterview: (id) => dispatch(retrieveInterview(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveInterviewReviewList);
