import React, {Component} from 'react';
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import JobInfo from "../Interview/JobInfo";
import classNames from 'classnames';
import {connect} from "react-redux";
import InterviewInfo from "./InterviewInfo";
import {isInvitationDeclined} from "../../utils/onDemandInterview";
import PageError from "./PageError";
import {STEP_ABOUT} from "./OnDemandInterviewSteps";
import {setStep} from "../../actions/OnDemandInterviewPage/page";

class OnDemandInterviewInfoContent extends Component {
  state = {
    invitationJustDeclined: false,
  }

  addMessageListeners = (message) => {
    if (message.data.hasOwnProperty('type') && message.data.type === 'startInterview') {
      this.props.setStep(STEP_ABOUT);
    }
  }

  componentDidMount() {
    window.addEventListener('message', this.addMessageListeners.bind(this));

    if (window.parent) {
      window.parent.postMessage({type: 'onDemandInfoPageLoaded'}, '*');
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.addMessageListeners);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.invitation && !isInvitationDeclined(prevProps.invitation) && this.props.invitation && isInvitationDeclined(this.props.invitation)) {
      this.setState({invitationJustDeclined: true})
    }
  }

  render() {
    const {request} = this.props;
    return (
      <>
        <PageError />
        {this.state.invitationJustDeclined && (
        <div className="alert alert-warning mb-0" style={{textAlign: 'center'}} role="status">
          You have declined this interview invitation. Thank you for your response.
        </div>
        )}
        <GridContainer className={classNames('interview-page-content-grid-container')}>
          <GridItem xs={12} md={6} lg={8}>
            <JobInfo job={request.job} />
          </GridItem>
          <GridItem xs={12} md={6} lg={4}>
            <InterviewInfo />
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

const mapStateToProps = state => ({
  request: state.onDemandInterviewPage.request,
  invitation: state.onDemandInterviewPage.invitation,
});

const mapDispatchToProps = dispatch => ({
  setStep: (step) => dispatch(setStep(step)),
});

OnDemandInterviewInfoContent = connect(
  mapStateToProps,
  mapDispatchToProps
)(OnDemandInterviewInfoContent);

export default OnDemandInterviewInfoContent;
