import React, {Component} from "react";
import Button from "../../../components/CustomButtons/Button";
import PropTypes from "prop-types";

class DialogCancelButton extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  render() {
    const {text, onClick} = this.props;

    return (
      <Button
        variant="contained"
        onClick={onClick}
      >
        {text}
      </Button>
    )
  }
}

export default DialogCancelButton;
