import {combineReducers} from 'redux';

export function retrieved(state = false, action) {
  switch (action.type) {
    case 'SITE_DETAILS_RETRIEVED':
      return action.retrieved;
    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'SITE_DETAILS_LOADING':
      return action.loading;
    default:
      return state;
  }
}

export function silentLoading(state = false, action) {
  switch (action.type) {
    case 'SITE_DETAILS_SILENT_LOADING':
      return action.loading;
    default:
      return state;
  }
}

export function error(state = null, action) {
  switch (action.type) {
    case 'SITE_DETAILS_ERROR':
      return action.error;

    default:
      return state;
  }
}

export default combineReducers({retrieved, error, loading, silentLoading});
