import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";
import { loadTeamsForSelect } from "../../actions/Team/forSelect";

class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  componentDidMount() {
    this.props.loadTeamsForSelect();
  }

  render() {
    const roles = [
      {text: 'Company Admin', value: 'company-admin'},
      {text: 'Company Employee', value: 'company-employee'},
      {text: 'Company Hiring Manager', value: 'company-hiring-manager'},
    ];
    const statuses = [
      {text: 'Active', value: 'active'},
      {text: 'Inactive', value: 'inactive'},
      {text: 'Pending', value: 'pending'},
    ];

    return (
      <RenderFilterForm {...this.props} fields={[
        {'name': 'id', 'label': 'ID', 'placeholder': 'ID', 'type': 'number'},
        {'name': 'firstName', 'label': 'First Name', 'placeholder': 'First Name', 'type': 'text'},
        {'name': 'lastName', 'label': 'Last Name', 'placeholder': 'Last Name', 'type': 'text'},
        {'name': 'email', 'label': 'Email', 'placeholder': 'Email', 'type': 'text'},
        {'name': 'phoneNumber', 'label': 'Phone Number', 'placeholder': 'Phone Number', 'type': 'text'},
        {'name': 'team', 'label': 'Team', 'placeholder': 'Team', 'type': 'selectSearch', 'options': this.props.teams},
        {'name': 'role', 'label': 'Role', 'placeholder': 'Role', 'type': 'selectSearch', 'options': roles},
        {'name': 'status', 'label': 'Status', 'placeholder': 'Status', 'type': 'selectSearch', 'options': statuses},
      ]} />
    );
  }
}

const mapStateToProps = state => {
  let teams = state.team.forSelect.retrieved;

  return { teams };
};

const mapDispatchToProps = dispatch => ({
  loadTeamsForSelect: () => dispatch(loadTeamsForSelect()),
});

FilterForm = reduxForm({
  form: 'filter_company_user',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterForm);
