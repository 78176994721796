import {extractHubURL, fetch} from "../../utils/dataAccess";

export function error(error) {
  return {type: 'QUESTIONBANK_SEARCH_ERROR', error};
}

export function loading(loading) {
  return {type: 'QUESTIONBANK_SEARCH_LOADING', loading};
}

export function success(retrieved) {
  return {type: 'QUESTIONBANK_SEARCH_SUCCESS', retrieved};
}
export function categoriesSelect(selectedCategories) {
  return {type: 'QUESTIONBANK_CATEGORIES_SELECT', selectedCategories};
}

export function search(filters) {
  return dispatch => {
    dispatch(loading(true));
    dispatch(error(''));

    fetch('question_banks', {params: filters})
      .then(response => ( response.data ))
      .then(retrieved => {
        dispatch(loading(false));
        dispatch(success(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch({type: 'QUESTIONBANK_SEARCH_RESET'});
  };
}
