import { combineReducers } from 'redux';

export function activateError(state = null, action) {
  switch (action.type) {
    case 'PLAN_ACTIVATE_ERROR':
      return action.activateError;

    case 'PLAN_ACTIVATE_RESET':
      return null;

    default:
      return state;
  }
}

export function activateLoading(state = false, action) {
  switch (action.type) {
    case 'PLAN_ACTIVATE_LOADING':
      return action.activateLoading;

    case 'PLAN_ACTIVATE_RESET':
      return false;

    default:
      return state;
  }
}

export function activated(state = null, action) {
  switch (action.type) {
    case 'PLAN_ACTIVATE_SUCCESS':
      return action.activated;

    case 'PLAN_ACTIVATE_RESET':
      return null;

    default:
      return state;
  }
}


export default combineReducers({
  activateError,
  activateLoading,
  activated,
});
