import React, {Component} from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import LockOutlined from "@material-ui/icons/LockOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import InputAdornment from "@material-ui/core/InputAdornment";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Smartphone from "@material-ui/icons/Smartphone";
import Button from "components/CustomButtons/Button.jsx";
import {renderTextField} from "utils/form";
import FormControl from "@material-ui/core/FormControl";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import {renderFromHelper} from "../../utils/form";
import {connect} from "react-redux";
import {loadIndustriesForSelect} from "../../actions/Industry/forSelect";
import {loadOccupationsForSelect} from "../../actions/Occupation/forSelect";
import {loadSpecialtiesForSelect} from "../../actions/Specialty/forSelect";
import SearchableSelect from "../../views/Components/SearchableSelect";
import CandidateResumeField from "./CandidateResumeField";
import RegistrationSiteSettings from "./RegistrationSiteSettings";

const style = theme => ({
    formControl: {
        paddingTop: "27px",
    },
    checkboxFormHelp: {
        marginTop: "-25px",
    },
    center: {
        textAlign: "center",
    },
    selectIcon: {
        marginRight: "8px",
        marginBottom: "6px",
    },
    selectWithIcon: {
        paddingBottom: "0px",
    },
    selectPlaceholderText: {
        display: "inline",
        fontSize: '1em',
        color: 'rgba(0, 0, 0, 0.38)',
    },
    selectValueText: {
        display: "inline",
        fontSize: '1em',
    }
});

class RegistrationForm extends Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        submitButtonText: PropTypes.string.isRequired,
        submittingText: PropTypes.string.isRequired,
        submitButtonProps: PropTypes.object.isRequired,
        loading: PropTypes.bool,
    };

    static defaultProps = {
        hideEmail: false,
        loading: false,
        submitButtonText: 'Get started',
        submittingText: 'Registering...',
        submitButtonProps: {round: "true", color: "primary"},
    };

    constructor(props) {
        super(props);
        this.state = {
            termsAccepted: false,
        };
    }

    componentDidMount() {
        this.props.loadIndustriesForSelect();
        this.props.loadOccupationsForSelect();
        this.props.loadSpecialtiesForSelect();
    }

    handleTermsAccepted = (termsAccepted) => {
        this.setState({
            'termsAccepted': termsAccepted,
        });
    };

    onIndustryChange = (e, value, oldValue) => {
        if (value !== oldValue) {
            this.props.change('professionMapping[occupation]', null);
            this.props.change('professionMapping[specialty]', null);
        }
    };

    onOccupationChange = (e, value, oldValue) => {
        if (value !== oldValue) {
            this.props.change('professionMapping[specialty]', null);
        }
    };

    renderCheckbox = ({label, input, handleChange, meta: {touched, error}}) => {
        const { classes} = this.props;
        return (
        <div className="form-group">
            <FormControlLabel
                classes={{
                    label: classes.checkboxLabel
                }}
                control={
                    <Checkbox
                        checked={!!input.value}
                        onChange={ (event, value) => {
                            if (handleChange) {
                                handleChange(value);
                            }
                            return input.onChange(event, value);
                        }}
                    />
                }
                label={label}
            />
            <div className={classes.checkboxFormHelp}>
            {renderFromHelper(touched, error)}
            </div>
        </div>
        )
    };

    render() {
        const {
            classes, formControlProps, industries, industry,
            occupations, occupation, specialties, loading, site, submitButtonProps
        } = this.props;
        console.log(submitButtonProps);
        let formControlClasses;
        if (formControlProps !== undefined) {
            formControlClasses = classNames(
                formControlProps.className,
                classes.formControl
            );
        } else {
            formControlClasses = classes.formControl;
        }

        let occupationPlaceholder = 'Occupation (Select Industry First)';
        let specialtyPlaceholder = 'Specialty (Select Occupation First)';
        let occupationOptions = [];
        let specialtyOptions = [];

        if (industry && occupations) {
            occupationPlaceholder = 'Occupation';
            occupations.map(occupation => {
                if (occupation.industry === industry) {
                    occupationOptions.push(occupation);
                }
            });
        }
        if (occupation && specialties) {
            specialtyPlaceholder = 'Specialty';
            specialties.map(specialty => {
                if (specialty.occupation === occupation) {
                    specialtyOptions.push(specialty);
                }
            });
        }

        const registrationDisabled = this.props.site && !!this.props.site['defaultContentWarningMessage'];

        return (
            registrationDisabled ? (
                <div className={"alert alert-danger"}>Registration temporary disabled!</div>
            ) : (
            <form name='registration' onSubmit={this.props.handleSubmit}>
                <FormControl fullWidth={true} {...formControlProps} className={formControlClasses}>
                    <Field
                        component={renderTextField}
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        required={true}
                        label={false}
                        className="required"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <Face className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
                <FormControl fullWidth={true} {...formControlProps} className={formControlClasses}>
                    <Field
                        component={renderTextField}
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                        required={true}
                        label={false}
                        className="required"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <Face className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
                <FormControl fullWidth={true} {...formControlProps} className={formControlClasses + (this.props.hideEmail ? ' d-none' : '')}>
                    <Field
                        component={renderTextField}
                        name="email"
                        type="email"
                        placeholder="Email"
                        required={true}
                        label={false}
                        className="required"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <Email className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
                <FormControl fullWidth={true} {...formControlProps} className={formControlClasses}>
                    <Field
                        component={renderTextField}
                        name="phoneNumber"
                        type="text"
                        placeholder="Mobile Number"
                        required={true}
                        label={false}
                        className="required"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <Smartphone className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
                <FormControl fullWidth={true} {...formControlProps}>
                    <label className={"mb-1"}>Industry <span className="text-danger">*</span></label>
                    <Field
                        component={SearchableSelect}
                        name="professionMapping[industry]"
                        type="text"
                        placeholder="Industry"
                        label={null}
                        onChange={this.onIndustryChange}
                        classes={{select: classes.selectWithIcon}}
                        options={industries}
                    />
                </FormControl>
                {occupationOptions.length > 0 && (
                    <FormControl fullWidth={true} {...formControlProps}>
                        <label className={"mb-1"}>Occupation</label>
                        <Field
                            component={SearchableSelect}
                            name="professionMapping[occupation]"
                            type="text"
                            placeholder={occupationPlaceholder}
                            label={null}
                            onChange={this.onOccupationChange}
                            classes={{select: classes.selectWithIcon}}
                            options={occupationOptions || []}
                        />
                </FormControl>)}
                {specialtyOptions.length > 0 && (
                    <FormControl fullWidth={true} {...formControlProps}>
                        <label className={"mb-1"}>Specialty</label>
                        <Field
                            component={SearchableSelect}
                            name="professionMapping[specialty]"
                            type="text"
                            placeholder={specialtyPlaceholder}
                            label={null}
                            classes={{select: classes.selectWithIcon}}
                            options={specialtyOptions || []}
                        />
                    </FormControl>)}
                <FormControl fullWidth={true} {...formControlProps} className={formControlClasses}>
                    <Field
                        component={renderTextField}
                        name="password"
                        type="password"
                        placeholder="Password"
                        required={true}
                        label={false}
                        className="required"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <LockOutlined className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
                <FormControl fullWidth={true} {...formControlProps} className={formControlClasses}>
                    <Field
                        component={renderTextField}
                        name="repeatPassword"
                        type="password"
                        placeholder="Repeat Password"
                        required={true}
                        label={false}
                        className="required"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                >
                                    <LockOutlined className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
                <CandidateResumeField />
                {site.freeResumeReviewCheckboxEnabled && (
                    <FormControl fullWidth={true} {...formControlProps}>
                        <Field
                            component={this.renderCheckbox}
                            name="freeResumeReview"
                            label="Free Resume Review"
                        />
                    </FormControl>
                )}
                <FormControl fullWidth={true} {...formControlProps}>
                    { /* terms of service and privacy policy checkboxes */ }
                    <RegistrationSiteSettings classes={this.props.classes} termsAccepted={this.handleTermsAccepted}/>
                </FormControl>
                <div className={classes.center}>
                    <Button type="submit" {...submitButtonProps} disabled={!this.state.termsAccepted || loading}>
                        {!loading ? (this.props.submitButtonText) : (this.props.submittingText)}
                    </Button>
                </div>
            </form>
            )
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.firstName) {
        errors.firstName = 'Please enter a first name';
    }
    if (!values.lastName) {
        errors.lastName = 'Please enter a last name';
    }
    if (!values.email) {
        errors.email = 'Please enter an email';
    }
    if (!values.phoneNumber) {
        errors.phoneNumber = 'Please enter a phone number';
    }
    if (!values.professionMapping || !values.professionMapping.industry) {
        errors.professionMapping = {industry: 'Please select an industry'};
    }
    if (!values.password) {
        errors.password = 'Please enter a password';
    }
    if (!values.repeatPassword) {
        errors.repeatPassword = 'Please repeat password';
    } else if (values.password !== values.repeatPassword) {
        errors.repeatPassword = 'Password and repeat password should be the same';
    }
    if (!values.termsAccepted) {
        errors.termsAccepted = 'Please accept terms';
    }

    return errors;
};

RegistrationForm = withStyles(style)(RegistrationForm);

RegistrationForm = reduxForm({
    form: 'candidateRegistration',
    validate
})(RegistrationForm);

const selector = formValueSelector('candidateRegistration');
const mapStateToProps = (state) => ({
    industry: selector(state, 'professionMapping[industry]'),
    industries: state.industry.forSelect.retrieved,
    occupation: selector(state, 'professionMapping[occupation]'),
    occupations: state.occupation.forSelect.retrieved,
    specialty: selector(state, 'professionMapping[specialty]'),
    specialties: state.specialty.forSelect.retrieved,
    site: state.siteDetails.siteDetails.retrieved,
});

const mapDispatchToProps = (dispatch) => ({
    loadIndustriesForSelect: () => dispatch(loadIndustriesForSelect('@id', true)),
    loadOccupationsForSelect: () => dispatch(loadOccupationsForSelect('@id', true)),
    loadSpecialtiesForSelect: () => dispatch(loadSpecialtiesForSelect('@id', true)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps)
(RegistrationForm);
