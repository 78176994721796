import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import {renderTextField} from "../../utils/form";

class SuperAdminForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  render() {
    const { isNew } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={renderTextField}
          name="firstName"
          type="text"
          placeholder="First Name"
          required={true}
        />
        <Field
          component={renderTextField}
          name="lastName"
          type="text"
          placeholder="Last Name"
          required={true}
        />
        <Field
          component={renderTextField}
          name="email"
          type="text"
          placeholder="Email"
          required={true}
        />
        <Field
          component={renderTextField}
          name="phoneNumber"
          type="text"
          placeholder="Phone Number"
          required={true}
        />
        {isNew && (
          <>
          <Field
            component={renderTextField}
            name="plainPassword"
            type="password"
            placeholder="Password"
            required={true}
          />
          <Field
            component={renderTextField}
            name="confirmPassword"
            type="password"
            label="Confirm Password"
            placeholder="Confirm Password"
            required={true}
          />
          </>
        )}
        <button type="submit" className="btn btn-success float-left mr-3">
          Submit
        </button>
      </form>
    );
  }
}

const validate = values => {
    const errors = {};
    if (values.plainPassword !== undefined && values.plainPassword !== values.confirmPassword) {
        errors.confirmPassword = 'Passwords are not the same';
    }

    return errors;
};

export default reduxForm({
  form: 'superadmin',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: validate,
})(SuperAdminForm);
