import {matchPath} from "react-router-dom";

const getActiveRouteName = (routes, location) => {
  let activeRoute = 'Home';
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].collapse) {
      let collapseActiveRoute = getActiveRouteName(routes[i].views, location);
      if (collapseActiveRoute !== activeRoute) {
        return collapseActiveRoute;
      }
    } else {
      if (isActiveRoute(routes[i], location)) {
        if (routes[i].hideAdminNavbar) {
          return null;
        }

        return routes[i].name;
      }
    }
  }
  return activeRoute;
};

const getActiveRoute = (routes, location) => {
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].collapse) {
      let collapseActiveRoute = getActiveRoute(routes[i].views, location);
      if (collapseActiveRoute) {
        return collapseActiveRoute;
      }
      continue;
    }

    if (isActiveRoute(routes[i], location)) {
      return routes[i];
    }
  }
};

const isActiveRoute = (route, location) => {
  const match = matchPath(location.pathname, {
    path: route.layout + route.path,
    exact: route.exact || true,
    strict: route.strict || true,
  });
  return match !== null;
};

const getRoutePage = (match) => {
  return match && match.params && match.params.page ? parseInt(match.params.page) : 0;
};

export {
  getActiveRouteName,
  getActiveRoute,
  isActiveRoute,
  getRoutePage,
}
