import {fetchJSON} from "../../utils/dataAccess";
import {SubmissionError} from "redux-form";
import {showSnack} from "react-redux-snackbar";

export function loadTemplateError(error) {
  return {type: 'REQUEST_LOAD_TEMPLATE_ERROR', error};
}

export function loadTemplateLoading(loading) {
  return {type: 'REQUEST_LOAD_TEMPLATE_LOADING', loading};
}

export function loadTemplateSuccess(retrieved) {
  return {type: 'REQUEST_LOAD_TEMPLATE_SUCCESS', retrieved};
}

export function loadTemplate(iri) {
  return dispatch => {
    console.log('Loading template', iri);
    dispatch(loadTemplateError(null));
    dispatch(loadTemplateSuccess(null));
    dispatch(loadTemplateLoading(true));

    return fetchJSON(iri, {
        method: 'GET',
        headers: new Headers({'Content-Type': 'application/ld+json'}),
      }
    )
      .then((retrieved) => {
        dispatch(loadTemplateLoading(false));
        dispatch(loadTemplateSuccess(retrieved));

        dispatch(showSnack('Loaded Template', {
          label: `Template "${retrieved.title}" was loaded.`,
          timeout: 15000,
          button: {label: 'Hide'}
        }));


      })
      .catch(e => {
        dispatch(loadTemplateLoading(false));

        if (e instanceof SubmissionError) {
          dispatch(loadTemplateError(e.errors._error));
          throw e;
        }

        dispatch(loadTemplateError(e.message));
      });
  };
}

export function reset(retrieved) {
  return dispatch => {
    dispatch(loadTemplateLoading(false));
    dispatch(loadTemplateError(null));
    dispatch(loadTemplateSuccess(null));
  };
}

