import { SubmissionError } from 'redux-form';
import { fetch } from '../../utils/dataAccess';

export function error(error) {
    return { type: 'COMPANY_REGISTER_ERROR', error };
}

export function loading(loading) {
    return { type: 'COMPANY_REGISTER_LOADING', loading };
}

export function success(registered) {
    return { type: 'COMPANY_REGISTER_SUCCESS', registered };
}

export function register(values) {
    return dispatch => {
        dispatch(loading(true));

        return fetch('/register/company', { method: 'POST', data: values })
            .then(response => {
                dispatch(loading(false));
                dispatch(success(response.data));
            })
            .catch(e => {
                dispatch(loading(false));

                if (e instanceof SubmissionError) {
                    dispatch(error(e.errors._error));
                    throw e;
                }

                dispatch(error(e.message));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch(loading(false));
        dispatch(error(null));
    };
}
