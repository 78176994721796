import React, {Component} from 'react';
import PropTypes from "prop-types";


export default class FlashMessages extends Component
{
  static propTypes = {
    location: PropTypes.object,
    duration: PropTypes.number,
    message: PropTypes.string,
    type: PropTypes.string,
  };

  constructor(props) {
    super(props);
    let messages = [];

    if (props.type && props.message) {
      messages.push({type: props.type, message: props.message});
    } else if (props.location && props.location.state && props.location.state.successMessage) {
      messages.push({type: 'success', message: props.location.state.successMessage, duration: 10000});
      /* clear history state after render */
      window.history.replaceState({}, document.title);
    }

    this.state = {messages: messages};
  }

  onRemove = (index) => {
    const messages = this.state.messages;
    messages.splice(index, 1);
    this.setState({messages: messages});
  };

  render() {
    if (this.state.messages.length > 0) {
      return (
        <div className={"flash-messages-container"}>
          {this.state.messages.map((m, i) => {

            if (this.props.duration || m.duration) {
              let duration = this.props.duration || m.duration;
              setTimeout(() => this.onRemove(i), duration);
            }

            return (
              <div key={i} className={"alert alert-" + m.type}>
                <span className={"text-message"}>
                  {m.message}
                </span>
                <i className={"fa fa-close"} onClick={() => this.onRemove(i)}/>
                <div className={'clearfix'} />
              </div>);
            }
          )}
        </div>
      );
    }

    return null;
  }
}
