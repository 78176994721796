import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";

class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  render() {
    const statuses = [
      {value: 'active', text: 'Active'},
      {value: 'inactive', text: 'Inactive'},
    ];

    return (
      <RenderFilterForm {...this.props} fields={[
        {'name': 'id', 'label': 'ID', 'placeholder': 'ID', 'type': 'number'},
        {'name': 'name', 'label': 'Name', 'placeholder': 'Name', 'type': 'text'},
        {'name': 'status', 'label': 'Status', 'placeholder': 'Status', 'type': 'selectSearch', 'options': statuses},
      ]} />
    );
  }
}

export default reduxForm({
  form: 'filter_company_team',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);
