import UAParser from "ua-parser-js";

let parser;

function getParser() {
  if (!parser) {
    parser = new UAParser();
  }
  return parser;
}

function getBrowserName() {
  const parser = getParser();
  let browser;
  if (parser) {
    browser = parser.getBrowser();
  }

  return browser ? browser.name : '';
}

function isSafari() {
  return getBrowserName().toLowerCase().search('safari') !== -1;
}

function isFirefox() {
  return getBrowserName().toLowerCase().search('firefox') !== -1;
}

function isIphone() {
  const parser = getParser();
  let device;
  if (parser) {
    device = parser.getDevice();
  }

  return device && device.model && device.model.toLowerCase() === 'iphone';
}

function isMobile() {
  const parser = getParser();
  let device;
  if (parser) {
    device = parser.getDevice();
  }

  return device && device.type && device.type === 'mobile';
}

export {
  getBrowserName,
  isSafari,
  isFirefox,
  isIphone,
  isMobile,
}
