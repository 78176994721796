import React from "react";
import PropTypes from "prop-types";
import CameraIcon from "../Icons/FlipCameraIos";
import {IconButton} from "@material-ui/core";
import {changeVideoDevice, getStreamManagerVideoDeviceId} from "../../utils/streamManager";
import classNames from 'classnames';

class ToggleCamera extends React.Component {
  static propTypes = {
    streamManager: PropTypes.object,
    devices: PropTypes.object,
    className: PropTypes.string,
    disabled: PropTypes.bool,
  };

  state = {
    currentDeviceId: null,
    toggling: false,
  }

  componentDidMount() {
    const {streamManager} = this.props;
    if (streamManager) {
      this.updateCurrentDeviceId();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.streamManager && this.props.streamManager) {
      this.updateCurrentDeviceId();
    }
  }

  updateCurrentDeviceId = () => {
    const {streamManager} = this.props;
    this.setState({
      currentDeviceId: getStreamManagerVideoDeviceId(streamManager)
    })
  }

  getNextDeviceId = () => {
    const {currentDeviceId} = this.state;
    if (!this.props.devices) {
      return null;
    }

    const deviceIds = Object.keys(this.props.devices);
    if (deviceIds.length === 0) {
      return null;
    }

    //Only toggle between two cameras
    const currentIndex = deviceIds.indexOf(currentDeviceId);
    if (currentIndex === -1 || currentIndex === 1) {
      return deviceIds[0];
    } else {
      return deviceIds[1];
    }
  }

  toggle = () => {
    const { streamManager } = this.props;
    const nextDeviceId = this.getNextDeviceId();
    if (nextDeviceId) {
      this.setState({toggling: true});
      changeVideoDevice(streamManager, nextDeviceId)
        .catch(e => console.log("Unable to change video device", e))
        .finally(() => {
          this.setState({
            currentDeviceId: getStreamManagerVideoDeviceId(streamManager),
            toggling: false,
          })
        });
    }
  }

  render() {
    const {toggling} = this.state;
    const {className, disabled} = this.props;
    const buttonDisabled = toggling || disabled;
    return (
      <IconButton
        onClick={this.toggle}
        disabled={buttonDisabled}
        className={classNames(className, {
          'toggle-camera-button-disabled': buttonDisabled,
        })}
      >
        <CameraIcon />
      </IconButton>
    )
  }
}

export default ToggleCamera;

