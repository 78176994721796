export const stripTags = (html) => {
  /* https://stackoverflow.com/a/5002161 */
  return html ? html.replace(/<\/?[^>]+(>|$)/g, "") : "";
}

export function generateRandomString(
  length = 64,
  keyspace = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
) {
  if (length < 1) {
    throw new Error("Length must be a positive integer");
  }
  let pieces = [];
  let max = keyspace.length - 1;
  for (let i = 0; i < length; ++i) {
    pieces.push(keyspace[getRandomInt(0, max)]);
  }

  return pieces.join('');
}

/* https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random#getting_a_random_integer_between_two_values_inclusive */
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
}
