import {combineReducers} from "redux";

function accepting (state = null, action) {
  switch (action.type) {
    case 'ON_DEMAND_INTERVIEW_PAGE_INVITATION_ACCEPTING':
      return action.accepting;
    case 'ON_DEMAND_INTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}

function declining (state = null, action) {
  switch (action.type) {
    case 'ON_DEMAND_INTERVIEW_PAGE_INVITATION_DECLINING':
      return action.declining;
    case 'ON_DEMAND_INTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}

export default combineReducers({
  accepting, declining,
});
