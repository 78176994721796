import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";

class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  componentDidMount() {

  }

  render() {
    const statuses = [
      {value: 'active', text: 'Active'},
      {value: 'inactive', text: 'Inactive'}
    ];

    return (
      <RenderFilterForm {...this.props} fields={[
        {'name': 'id', 'label': 'ID', 'placeholder': 'ID', 'type': 'number'},
        {'name': 'name', 'label': 'Name', 'placeholder': 'Name', 'type': 'text'},
        {'name': 'status', 'label': 'Status', 'placeholder': 'Status', 'type': 'selectSearch', 'options': statuses},
        {'name': 'code', 'label': 'Plan Code', 'placeholder': 'Plan Code', 'type': 'text'},
        {'name': 'monthlyPrice__gte__', 'label': 'Monthly Price From', 'placeholder': 'Monthly Price From', 'type': 'number'},
        {'name': 'monthlyPrice__lte__', 'label': 'Monthly Price To', 'placeholder': 'Monthly Price To', 'type': 'number'},
        {'name': 'annualPrice__gte__', 'label': 'Annual Price From', 'placeholder': 'Annual Price From', 'type': 'number'},
        {'name': 'annualPrice__lte__', 'label': 'Annual Price To', 'placeholder': 'Annual Price To', 'type': 'number'},
      ]} />
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({
});

FilterForm = reduxForm({
  form: 'filter_plans',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterForm);
