import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";
import {currentUserHasRole} from "../../utils/auth";

class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  render() {
    const fields = [
      {'name': 'id', 'label': 'ID', 'placeholder': 'ID', 'type': 'number'},
      {'name': 'subject', 'label': 'Subject', 'placeholder': 'Subject', 'type': 'text'},
    ];

    if (currentUserHasRole('ROLE_SUPER_ADMIN')) {
      let publicOptions = [
        {'text': 'Yes', 'value': true},
        {'text': 'No',  'value': false},
      ];

      fields.push({'name': 'public', 'label': 'Public', 'placeholder': 'Public', 'type': 'selectSearch', 'options': publicOptions});
    }

    return (
      <RenderFilterForm {...this.props} fields={fields} />
    );
  }
}

const mapStateToProps = state => {

};

const mapDispatchToProps = dispatch => ({

});

FilterForm = reduxForm({
  form: 'filter_emails',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterForm);
