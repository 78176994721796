import ScreenShareControl from "../VideoControl/ScreenShareControl";
import {connect} from "react-redux";
import React from "react";
import {shareScreen} from "../../actions/LiveInterviewPage/interview";

class LiveInterviewShareScreen extends React.Component {
  publishScreen = (screenStreamManager) => {
    const {session, setShareScreen} = this.props;
    session.shareScreen(screenStreamManager);
    setShareScreen(screenStreamManager);
  }

  unpublishScreen = () => {
    const {session, setShareScreen} = this.props;
    console.log('Need to unpublish stream');
    session.stopScreenSharing();
    setShareScreen(null);
  }

  render() {
    const {sharedScreen, buttonClass, sessionContainSharedScreen} = this.props;

    return (
      <ScreenShareControl
        streamManager={sharedScreen}
        buttonClass={buttonClass}
        onScreenShared={this.publishScreen}
        onScreenStopped={this.unpublishScreen}
        sessionContainSharedScreen={sessionContainSharedScreen}
      />
    )
  }
}

const mapStateToProps = state => ({
  session: state.liveInterviewPage.session,
  sharedScreen: state.liveInterviewPage.sharedScreen,
});

const mapDispatchToProps = dispatch => ({
  setShareScreen: (streamManager) => dispatch(shareScreen(streamManager))
})

LiveInterviewShareScreen = connect(
  mapStateToProps, mapDispatchToProps
)(LiveInterviewShareScreen);

export default LiveInterviewShareScreen;
