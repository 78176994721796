import React from "react";
import PropTypes from "prop-types";
import {isGranted} from "../utils/permissions";

class IsGranted extends React.Component {
    render() {
        const { action, object, children } = this.props;

        if (isGranted(action, object))
            return (children);
        else
            return (null);
    }
}

IsGranted.propTypes = {
    action: PropTypes.string.isRequired,
    object: PropTypes.object
};

export default IsGranted;
