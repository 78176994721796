import React, { Component } from 'react';
import InterviewLayout from "./InterviewLayout";

class OnDemandInterviewLayout extends Component {
    render() {
        return (
            <InterviewLayout interviewType='on-demand-interview' />
        );
    }
}

export default OnDemandInterviewLayout;
