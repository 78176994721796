import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import AdjustableText from "../../views/Components/AdjustableText";

class HeaderCompanyLogo extends Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
  };

  render() {
    const {company} = this.props;
    return (
      <>
        {company.logo && (
          <div className='interview-header-company-logo'>
            <img src={company.logo.url} title={company.name} alt={company.name}/>
          </div>
        )}
        <div className={classNames(
          'interview-header-company-name',
          {
            'interview-header-company-name-without-logo': !company.logo,
          }
        )}>
          <AdjustableText maxFontSize={26} maxHeight={80}>
            {company.name}
          </AdjustableText>
        </div>
      </>
    );
  }
}

export default HeaderCompanyLogo;
