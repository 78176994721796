import filterStorage from "./filtersStorage";
import moment from "moment";
import Moment from 'react-moment';

export function filterAction(eventName, filtersName, filtersData) {
  return dispatch => {
    if (null === filtersData) {
      filterStorage.remove(filtersName);
    } else {
      for (let key in filtersData) {
        if (filtersData.hasOwnProperty(key)) {
          if (typeof filtersData[key] === 'string') {
            filtersData[key] = filtersData[key].trim();
          }

          if (null !== filtersData[key] && typeof filtersData[key] === 'object' && '_isAMomentObject' in filtersData[key]) {
            filtersData[key] = moment(filtersData[key]).format(Moment.globalFormat);
          }

          if (
            null === filtersData[key] ||
            '' === filtersData[key] ||
            undefined === filtersData[key] ||
            (Array.isArray(filtersData[key]) && filtersData[key].length === 0)
          ) {
            delete filtersData[key];
          }
        }
      }

      if (Object.keys(filtersData).length > 0) {
        filterStorage.set(filtersName, filtersData);
      } else {
        filtersData = null;
        filterStorage.remove(filtersName);
      }
    }

    dispatch({ type: eventName, filtersData })
  }
}
export function sortAction(eventName, sortName, sortData) {
  return dispatch => {
    if (null === sortData) {
      filterStorage.removeSort(sortName);
    } else {
      filterStorage.setSort(sortName, sortData);
    }

    dispatch({ type: eventName, sortData })
  }
}
