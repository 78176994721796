import {ENTRYPOINT, MERCURE_ENTRYPOINT} from '../config/entrypoint';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get';
import has from 'lodash/has';
import mapValues from 'lodash/mapValues';
import difference from 'lodash/difference';
import axios from 'axios';
import ResponseError from "./ResponseError";
import qs from 'qs';
import {unflatten} from 'flatley';
import FileSaver from 'file-saver';

const MIME_TYPE = 'application/ld+json';

export function fetch(url, options = {}) {
  const defaults = {
    'url': (new URL(url, ENTRYPOINT)).toString(),
    'headers': {},
    paramsSerializer: function (params) {
      return qs.stringify(params, { allowDots: true, skipNulls: true });
    },
  };

  const config = {
    ...defaults,
    ...options,
  };

  if (undefined === config.headers.accept) {
    config.headers.accept = MIME_TYPE;
  }

  if (!(config.body instanceof FormData) && !config.headers['content-type']) {
    config.headers['content-type'] = MIME_TYPE;
  }

  //Fix empty content type for put and post requests without body
  if (config.method && ['POST', 'PUT'].includes(config.method.toUpperCase()) && !config.data) {
    config.data = {};
  }

  return axios.request(config).catch(error => {
    if (axios.isCancel(error)) {
      return;
    }

    const response = error.response;
    if (!response) {
      throw error;
    }
    const data = response.data;
    const errorText = data['hydra:description'] || response.statusText;
    if (!data.violations) throw new ResponseError(response, errorText);

    const submittedData = config.data;
    let errors = { _error: errorText, _response: response };
    data.violations.map(violation => {
      const path = violation.propertyPath;
      const message = violation.message;
      if (submittedData && submittedData[path] && Array.isArray(submittedData[path])) {
        errors[`${path}._error`] = message;
      } else if (path.match(/\[(\d+)\]/g)) {
        errors[path.replace(/\[(\d+)\]/g, '.$1')] = message;
      } else {
        errors[path] = message;
      }
    });
    throw new SubmissionError(unflatten(errors));
  });
}

export function fetchJSON(id,  options = {}) {
  //TODO: remove this method
  return fetch(id, options).then(response => response.data).catch(error => error);
}

export function mercureSubscribe(url, topics) {
  topics.forEach(topic =>
    url.searchParams.append('topic', new URL(topic, MERCURE_ENTRYPOINT))
  );

  return new EventSource(url.toString());
}

export function normalize(data) {
  if (has(data, 'hydra:member')) {
    // Normalize items in collections
    data['hydra:member'] = data['hydra:member'].map(item => normalize(item));

    return data;
  }

  // Flatten nested documents
  return mapValues(data, value =>
    Array.isArray(value)
      ? value.map(v => get(v, '@id', v))
      : get(value, '@id', value)
  );
}

export function normalizeExcept(object, exceptAttributes) {
  return normalizeAttributes(object, difference(Object.keys(object), exceptAttributes));
}

export function normalizeAttributes(object, attributes) {
  attributes.forEach(attributeName => {
    let value = object[attributeName];
    object[attributeName] = Array.isArray(value)
       ? value.map(v => get(v, '@id', v))
       : get(value, '@id', value)
    ;
  });
  return object;
}

export function deleteAttributes(object, attributes) {
  attributes.forEach(attributeName => {
    delete object[attributeName];
  });
  return object;
}

export function extractHubURL(response) {
  const linkHeader = response.headers.link;
  if (!linkHeader) return null;

  const matches = linkHeader.match(
    /<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/
  );

  return matches && matches[1] ? new URL(matches[1], ENTRYPOINT) : null;
}

export function downloadFileFromApi(uri, customFileName = null) {
  return fetch(uri, {
        responseType: 'blob'
      })
      .then(response => {
        const blob = response.data;
        const header = response.headers['content-disposition'];
        let filename = 'filename';
        if (header) {
          const matches = header.match(/filename="(.*)"/);
          if (matches && matches.length > 0) {
            filename = matches[1];
          }
        }
        return {blob, filename};
      })
      .then(({blob, filename}) => {
        FileSaver.saveAs(blob, customFileName || filename);
      })
  ;
}

export function clearValues(object, fields) {
  for (let i = 0; i < fields.length; i++) {
    if (object.hasOwnProperty(fields[i]) && object[fields[i]] === '') {
      object[fields[i]] = null;
    }
  }

  return object;
}

export function IRItoID(iri) {
  return iri.split('/').pop();
}
