import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Form from './Form';
import { retrieve, update, reset } from '../../actions/CompanyUser/update';
import { updateRole } from '../../actions/CompanyUser/updateRole';
import { del } from '../../actions/CompanyUser/delete';
import IsGranted from "../IsGranted";
import storage from "../../utils/storage";
import {currentUserHasRole, getUserId} from "../../utils/auth";
import {activate, reset as activateReset} from "../../actions/CompanyUser/activate";
import {deactivate, reset as deactivateReset} from "../../actions/CompanyUser/deactivate";
import {isAllowedToChangeStatus} from "./List";
import ActivateDeactivateUserDialog from "./ActivateDeactivateUserDialog";

class CompanyUserUpdate extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    retrieveLoading: PropTypes.bool.isRequired,
    retrieveError: PropTypes.string,
    updateLoading: PropTypes.bool.isRequired,
    updateError: PropTypes.string,
    updateRoleLoading: PropTypes.bool.isRequired,
    deleteLoading: PropTypes.bool.isRequired,
    deleteError: PropTypes.string,
    updated: PropTypes.object,
    updatedRole: PropTypes.object,
    deleted: PropTypes.object,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    del: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  state = {
    changeStatusUserModal: null,
  }

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
    this.props.activateReset();
    this.props.deactivateReset();
  }

  del = () => {
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.del(this.props.retrieved);
  };

  update = (item, values) => {
    if (item.roles.indexOf(values.role) === -1) {
      let successRoleUpdateCallback = (updatedCompanyUser) => {
        return this.props.update(updatedCompanyUser, values);
      }
      return this.props.updateRole(values, successRoleUpdateCallback);
    } else {
      return this.props.update(item, values);
    }
  }

  updateUserStatus = (companyUser) => {
    let changeStatusCallback = () => {
      this.componentDidMount();
    }
    companyUser['status'] = companyUser['status'] === 'active' ? 'inactive' : 'active';
    this.toggleModal();

    this.props.activateReset();
    this.props.deactivateReset();

    companyUser['status'] === 'active' ?
      this.props.activate(companyUser, changeStatusCallback) :
      this.props.deactivate(companyUser, changeStatusCallback);
  }

  toggleModal = (user = null) => {
    this.setState({changeStatusUserModal: user});
  }

  render() {
    if (this.props.deleted)
      this.props.history.push({
        pathname: `..`,
        state: {successMessage: 'Company User successfully deleted.'}
      });

    if (this.props.updated)
      this.props.history.push({
        pathname: `../show/${encodeURIComponent(this.props.updated['@id'])}`,
        state: {successMessage: 'Company User successfully updated.'}
      });

    const item = this.props.retrieved;
    let initialValues = {};
    if (item) {
      let initialRole = 'ROLE_COMPANY_ADMIN';
      if (item['roles'].indexOf('ROLE_COMPANY_EMPLOYEE') > -1) {
        initialRole = 'ROLE_COMPANY_EMPLOYEE';
      } else if (item['roles'].indexOf('ROLE_COMPANY_HIRING_MANAGER') > -1) {
        initialRole = 'ROLE_COMPANY_HIRING_MANAGER';
      }

      initialValues = {
        companyUser: item['@id'],
        firstName: item['firstName'],
        lastName: item['lastName'],
        email: item['email'],
        phoneNumber: item['phoneNumber'],
        role: initialRole,
        receiveNotifications: item['receiveNotifications'],
        teams: item['teams'],
      };
    }

    const activate = item && item['status'] === 'inactive';

    return (
      <div>
        <h1>Edit &quot;{item && item['firstName']} {item && item['lastName']}&quot;</h1>

        {this.props.activated && (
          <div className="alert alert-success" role="status">
            {this.props.activated['firstName']} {this.props.activated['lastName']} has been activated.
          </div>
        )}
        {this.props.deactivated && (
          <div className="alert alert-success" role="status">
            {this.props.deactivated['firstName']} {this.props.deactivated['lastName']} has been deactivated.
          </div>
        )}
        {(this.props.retrieveLoading ||
          this.props.updateLoading ||
          this.props.updateRoleLoading ||
          this.props.updateStatusLoading ||
          this.props.deleteLoading) && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.retrieveError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.retrieveError}
          </div>
        )}
        {this.props.updateError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.updateError}
          </div>
        )}
        {this.props.deleteError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.deleteError}
          </div>
        )}

        <ActivateDeactivateUserDialog
          open={this.state.changeStatusUserModal !== null}
          activate={activate}
          onClose={() => this.toggleModal()}
          onConfirm={() => this.updateUserStatus(this.state.changeStatusUserModal)}
        />

        {item && (
          <Form
            onSubmit={values => this.update(item, values)}
            initialValues={initialValues}
            showRoleField={currentUserHasRole('ROLE_COMPANY_ADMIN') && (!item || item['id'] !== getUserId())}
          />
        )}
        <Link to=".." className="btn btn-primary float-left mr-3">
          Back to list
        </Link>
        {item && (
          <>
            {isAllowedToChangeStatus(item) && (
              <button disabled={this.props.updateStatusLoading} onClick={() => this.toggleModal(item)} className="btn btn-warning float-left mr-3">
                {activate ? 'Activate' : 'Deactivate'}
              </button>
            )}
            <IsGranted action="delete" object={item}>
            <button onClick={this.del} className="btn btn-danger float-left mr-3">
              Delete
            </button>
            </IsGranted>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.companyUser.update.retrieved,
  retrieveError: state.companyUser.update.retrieveError,
  retrieveLoading: state.companyUser.update.retrieveLoading,
  updateError: state.companyUser.update.updateError || state.companyUser.updateRole.updateError,
  updateLoading: state.companyUser.update.updateLoading,
  updateRoleLoading: state.companyUser.updateRole.updateLoading,
  deleteError: state.companyUser.del.error,
  deleteLoading: state.companyUser.del.loading,
  eventSource: state.companyUser.update.eventSource,
  created: state.companyUser.create.created,
  deleted: state.companyUser.del.deleted,
  updated: state.companyUser.update.updated,
  updatedRole: state.companyUser.updateRole.updated,
  updateStatusLoading: state.companyUser.activate.activateLoading || state.companyUser.deactivate.deactivateLoading,
  activated: state.companyUser.activate.activated,
  deactivated: state.companyUser.deactivate.deactivated,
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  update: (item, values) => dispatch(update(item, values)),
  updateRole: (values, callback) => dispatch(updateRole(values, callback)),
  del: item => dispatch(del(item)),
  reset: eventSource => dispatch(reset(eventSource)),
  activate: (companyUser, callback) => dispatch(activate(companyUser, callback)),
  deactivate: (companyUser, callback) => dispatch(deactivate(companyUser, callback)),
  activateReset: () => dispatch(activateReset()),
  deactivateReset: () => dispatch(deactivateReset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyUserUpdate);
