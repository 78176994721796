import React from "react";
import {connect} from "react-redux";
import {retrieve, reset} from "actions/LiveInterviewShareLink/byCode"
import DateTimeWithTimezone from "../../components/DateTime/DateTimeWithTimezone";
import defaultProfileImage from "assets/img/default-avatar.png";
import Dialog from "../Components/Dialog/Dialog";
import TextField from "@material-ui/core/TextField";
import LiveInterviewComposedVideoPlayer from "../../components/LiveInterview/LiveInterviewComposedVideoPlayer";
import LiveInterviewPlayer from "../../components/LiveInterview/LiveInterviewPlayer";

class LiveInterviewShareLink extends React.Component
{
  state = {
    password: '',
    startVideoAt: null,
  }

  constructor(props) {
    super(props);

    this.player = React.createRef();
  }

  retrieveWithPassword = () => {
    if (this.state.password)
      this.props.retrieve(this.props.match.params.token, this.state.password);
  }

  componentDidMount() {
    this.props.retrieve(this.props.match.params.token);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  onMessageTimeClick = (time) => {
    const parts = time.split(':');
    let seconds = 0;
    if (parts.length === 3) {
      seconds += parseInt(parts[0]) * 3600;
      seconds += parseInt(parts[1]) * 60;
      seconds += parseInt(parts[2]);
    } else {
      seconds += parseInt(parts[0]) * 60;
      seconds += parseInt(parts[1]);
    }

    /* hack for sending the same seek twice */
    if (seconds === this.state.startVideoAt) {
      seconds += 0.001;
    }

    this.setState({startVideoAt: seconds});
  }

  render() {
    const liveInterviewShareLink = this.props.retrieved;
    const liveInterview = liveInterviewShareLink ? liveInterviewShareLink['liveInterview'] : null;
    const {loading, site} = this.props;
    const hasChat = liveInterviewShareLink && liveInterviewShareLink['chatHistory'].length > 0;
    const hasComposedVideo = liveInterviewShareLink && liveInterviewShareLink['composedVideo'] && !!liveInterviewShareLink['composedVideo']['path'];
    const composedVideoProcessing = liveInterviewShareLink && liveInterviewShareLink['composedVideo'] && liveInterviewShareLink['composedVideo']['status'] === 'processing';
    const hasLiveInterviewVideos = liveInterview && Array.isArray(liveInterview['liveInterviewVideos']) && liveInterview['liveInterviewVideos'].length > 0;

    return (
      <div className={"live-interview-share-link-container"}>
        {this.props.error && (
          <>
            <h3 className={'text-center'} style={{marginTop: '100px'}}>{this.props.error}</h3>
            <div className={"clearfix"} />
          </>
        )}
        <div className={"live-interview-share-link"}>
          {loading && (
            <div className={"alert alert-info"}>
              Loading...
            </div>
          )}
          {liveInterview && (
            <>
              <div className={"live-interview-share-link-header"}>
                {site.logo && (
                  <img src={site.logo} alt={site.title} title={site.title} />
                )}
                <h3>Viewing {liveInterview['title']}</h3>
              </div>

              <div className="live-interview-share-link-video-and-chat">
                <h4>Live Interview Recording</h4>

                <div className={"live-interview-share-link-video-element" + (hasChat ? ' with-chat' : '')}>
                  {hasComposedVideo && (
                    <LiveInterviewComposedVideoPlayer
                      composedVideo={liveInterviewShareLink['composedVideo']} seek={this.state.startVideoAt}
                    />
                  )}
                  {!hasComposedVideo && !composedVideoProcessing && hasLiveInterviewVideos && (
                    <>
                      <LiveInterviewPlayer
                        interview={liveInterview}
                        iframeStyle={{
                          borderTop: '1',
                          borderRadius: '5px',
                          height: '100%',
                        }}
                      />
                      <div className={"alert alert-warning mb-0"}>There was an error processing this recording. The playback experience may be degraded.</div>
                    </>
                  )}
                </div>
                {(!hasComposedVideo && !hasLiveInterviewVideos || composedVideoProcessing) && (
                  <div className={"live-interview-share-link-video-element placeholder"  + (hasChat ? ' with-chat' : '')}>
                    {composedVideoProcessing ?
                      'Video is currently processing, please check back later.' :
                      'This interview was not recorded.'
                    }
                  </div>
                )}

                {liveInterviewShareLink['chatHistory'].length > 0 && (
                  <>
                    <div className={'live-interview-share-link-video-and-chat-separator'}>
                      <div className={'top'} />
                      <div className={'bottom'} />
                    </div>
                    <div className={"live-interview-share-link-chat"}>
                      <p className={"live-interview-share-link-chat-header"}>
                        <i className="fa fa-comments" aria-hidden="true" /> Chat History
                      </p>

                      <div className={"live-interview-share-link-chat-messages"}>
                        {liveInterviewShareLink['chatHistory'].map(message => (
                          <div className={"live-interview-share-link-chat-message"} key={message['id']}>
                            <div className={'message-time' + (!hasComposedVideo ? '-disabled' : '')} onClick={() => this.onMessageTimeClick(message['time'])}>{message['time']}</div>
                            <div className={'message'}>
                              <img src={defaultProfileImage} alt={message['username']}/>
                              <p className={"live-interview-share-link-chat-message-text"}>
                                <span className={"username"}>{message['username']}: </span>{message['content']}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>

                    </div>
                  </>
                )}
                <div className={'clearfix'} />
              </div>

              <div className="live-interview-share-link-overview">
                <h4>Overview:</h4>
                <div className={"live-interview-share-link-overview-info"}>
                  <div className={"overview-label"}>Participants:</div>
                  <div className={"overview-text"}>{liveInterviewShareLink['participantNames']}</div>

                  <div className={"overview-label"}>Title:</div>
                  <div className={"overview-text"}>{liveInterview['title']}</div>

                  <div className={"overview-label"}>Job:</div>
                  <div className={"overview-text"}>{liveInterview['job'] ? liveInterview['job']['title'] : 'Deleted'}</div>

                  <div className={"overview-label"}>Started At:</div>
                  <div className={"overview-text"}><DateTimeWithTimezone time={liveInterview['startedAt']} timezone={liveInterview['timezone']} /></div>

                  <div className={"overview-label"}>Completed At:</div>
                  <div className={"overview-text"}><DateTimeWithTimezone time={liveInterview['completedAt']} timezone={liveInterview['timezone']} /></div>
                </div>
              </div>
            </>
          )}
          {this.props.passwordRequired && (
            <Dialog
              open={true}
              title={"Password Required"}
            >
              {this.props.passwordError && (
                <div className={"alert alert-danger"}>{this.props.passwordError}</div>
              )}
              {this.props.loading && (
                <div className="alert alert-info" role="status">
                  Loading...
                </div>
              )}
              <div className={"share-link-password-container"}>
                <TextField
                  label={'Password'}
                  placeholder={'Password'}
                  variant="outlined"
                  type="password"
                  value={this.state.password}
                  onChange={(e) => {this.setState({password: e.target.value})}}
                  onKeyPress={e => {e.key === 'Enter' && this.retrieveWithPassword(e)}}
                  className={"width-300"}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <br />
                <button disabled={!this.state.password} className={"btn btn-success btn-lg mt-3"} onClick={this.retrieveWithPassword}>Submit</button>
              </div>
            </Dialog>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.liveinterviewsharelink.byCode.retrieved,
  loading: state.liveinterviewsharelink.byCode.loading,
  error: state.liveinterviewsharelink.byCode.error || state.liveinterviewsharelink.byCode.noLongerAvailable,
  passwordError: state.liveinterviewsharelink.byCode.passwordError,
  passwordRequired: state.liveinterviewsharelink.byCode.passwordRequired,
  site: state.siteDetails.siteDetails.retrieved,
});

const mapDispatchToProps = dispatch => ({
  retrieve: (token, password) => dispatch(retrieve(token, password)),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveInterviewShareLink);
