import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import candidateRegisterPageStyle from "assets/jss/material-dashboard-pro-react/views/candidateRegisterPageStyle";
import RegistrationForm from "components/Candidate/RegistrationForm.jsx";
import {loadShadowCandidate, register, reset} from "../../actions/Candidate/register";
import {connect} from "react-redux";
import {destroy, formValueSelector, reset as resetForm} from "redux-form";
import {currentUserRoles, handleAuthentication, isAuthenticated} from "../../utils/auth";
import {resolveDashboardUrl} from "../../utils/dashboardUrlResolver";
import {Link} from "react-router-dom";
import Button from "components/CustomButtons/Button.jsx";
import storage from "../../utils/storage";
import {LoginFormName} from "../../components/Login/LoginFormSettings";

class CandidateRegisterPage extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    loadingShadowCandidate: PropTypes.bool,
    shadowCandidate: PropTypes.object,
  };

  componentWillMount() {
    if (isAuthenticated()) {
      this.props.history.push(resolveDashboardUrl(currentUserRoles()));
    }
    let code = this.props.match.params.code;
    if (code) {
      this.props.loadShadowCandidate(code);
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  handleSubmit = (values) => {
    return this.props.register(values);
  }

  handleSuccessSubmit = (result, dispatch) => {
    dispatch(resetForm('registration'));
    dispatch(destroy(LoginFormName));
    if (this.props.registered && this.props.registered.token) {
      if (this.props.shadowCandidate) {
        storage.set('afterLoginUrl', '/on-demand-interview/' + this.props.shadowCandidate.request.roomCode);
      }
      handleAuthentication(this.props.registered);
    }
  };

  getInitialValues = () => {
    let initialValues = {email: this.props.loginEmail, professionMapping: {}};
    if (this.props.shadowCandidate) {
        let shadowCandidate = this.props.shadowCandidate;
       initialValues.shadowCandidate = shadowCandidate['@id'];
       initialValues.firstName = shadowCandidate.firstName;
       initialValues.lastName = shadowCandidate.lastName;
       initialValues.phoneNumber = shadowCandidate.phoneNumber;
       initialValues.email = shadowCandidate.email;
       initialValues.resume = shadowCandidate.resume;
       initialValues.professionMapping.industry = shadowCandidate.industry;
       initialValues.professionMapping.occupation = shadowCandidate.occupation;
       initialValues.professionMapping.specialty = shadowCandidate.specialty;
    }

    return initialValues;
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>Candidates - Get Started Now</h2>
                <br/>
                <div align="center">
                    <Card style={{'maxWidth': '50%', margin: 0, padding: 0}}>
                        <CardBody>
                            <p align="center">
                                <br/>
                                <strong>Important:</strong> Do you already have an account?
                                <br/><br/>
                                <Link to={`/login`}>
                                    <Button round color="rose">
                                        Please Login
                                    </Button>
                                </Link>
                            </p>
                        </CardBody>
                    </Card>
                </div>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={8} md={5}>
                    { (this.props.loading || this.props.loadingShadowCandidate ) && (
                      <div className="alert alert-info" role="status">
                        Loading...
                      </div>
                    )}
                    {!this.props.loadingShadowCandidate && (
                      <RegistrationForm
                        onSubmit={this.handleSubmit}
                        onSubmitSuccess={this.handleSuccessSubmit}
                        initialValues={this.getInitialValues()}
                        loading={this.props.loading}
                      />
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

CandidateRegisterPage.propTypes = {
    classes: PropTypes.object.isRequired
};

const selector = formValueSelector(LoginFormName);
const mapStateToProps = state => {
    return {
      registered: state.candidate.register.registered,
      loading: state.candidate.register.loading,
      shadowCandidate: state.candidate.shadowCandidate.retrieved,
      loadingShadowCandidate: state.candidate.shadowCandidate.loading,
      loginEmail: selector(state, 'email'),
    };
};

const mapDispatchToProps = dispatch => ({
    register: values => dispatch(register(values)),
    reset: () => dispatch(reset()),
    loadShadowCandidate: (token) => dispatch(loadShadowCandidate(token)),
});

CandidateRegisterPage = withStyles(candidateRegisterPageStyle)(CandidateRegisterPage);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CandidateRegisterPage);
