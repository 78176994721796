import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {create, reset as resetInvitations} from "../../actions/Invitation/sendInvitation";
import {retrieve, reset} from "../../actions/Request/show";
import SendInvitationsForm from "./SendInvitationsForm";
import {getDefaultExpiresAtForInvitations} from "../../utils/onDemandInterview";
import {
  resetInvitationEmailDefaultContent,
  retrieveInvitationEmailDefaultContent
} from "../../actions/Invitation/invitationEmailDefaultContent";
import {IRItoID} from "../../utils/dataAccess";

class SendInvitations extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    created: PropTypes.object,
    retrieved: PropTypes.object,
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    const requestIRI = decodeURIComponent(this.props.match.params.requestId);
    this.props.retrieve(requestIRI);
    this.props.retrieveInvitationEmailDefaultContent(IRItoID(requestIRI));
  }

  componentWillUnmount() {
    this.props.reset();
    this.props.resetInvitations();
    this.props.resetInvitationEmailDefaultContent();
  }

  render() {
    if (this.props.created)
      this.props.history.push({
        pathname: `../`,
        state: {successMessage: 'Invitations has been successfully sent.'}
      });

    return (
      <div className={'send-invitations-container'}>
        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}

        {this.props.retrieved && (
          <>
            <h4>{this.props.retrieved['title']}</h4>
            <SendInvitationsForm
              invitationEmailDefaultContent={this.props.invitationEmailDefaultContent}
              onSubmit={this.props.create}
              request={this.props.retrieved}
              initialValues={{
                request: this.props.retrieved['@id'],
                invitations: [{
                  expiresAt: this.props.retrieved['expiresAt'],
                  _key: Math.random(),
                }],
              }}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    created: state.invitation.sendInvitation.created,
    error: state.invitation.sendInvitation.error || state.request.show.error,
    loading: state.invitation.sendInvitation.loading || state.request.show.loading,
    retrieved: state.request.show.retrieved,
    invitationEmailDefaultContent: state.invitation.invitationEmailDefaultContent.retrieved,
  }
};

const mapDispatchToProps = dispatch => ({
  create: values => dispatch(create(values)),
  retrieve: id => dispatch(retrieve(id)),
  reset: () => dispatch(reset()),
  resetInvitations: () => dispatch(resetInvitations()),
  retrieveInvitationEmailDefaultContent: (requestId) => dispatch(retrieveInvitationEmailDefaultContent(requestId)),
  resetInvitationEmailDefaultContent: () => dispatch(resetInvitationEmailDefaultContent())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendInvitations);
