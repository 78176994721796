import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import companyHomePageStyle from "assets/jss/material-dashboard-pro-react/views/company/companyHomePageStyle.jsx";
import {getCurrentCompany} from "../../../actions/Company/currentCompany";
import {connect} from "react-redux";
import SiteSettingsBox, {showSiteSettingsBox} from "../SiteSettingsBox";
import createJobImage from "assets/img/create_job.png";
import createDemandInterviewImage from "assets/img/create_demand_interview.png";
import scheduleInterviewImage from "assets/img/schedule_interview.png";

import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import GridItem from "components/Grid/GridItem.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {Link} from "react-router-dom";

class CompanyHomePage extends React.Component {
    componentDidMount() {
        this.props.getCurrentCompany();
    }

    render() {
        const { classes, company, site } = this.props;
        if (company && !company.profileComplete) {
            this.props.history.push('/company/profile');
        }
        return (
            <div className={classes.container}>
                {company && (
                    <>
                        <h2>Welcome to {site.name}</h2>

                        <div>
                            <CardBody>
                                <h4>What is an On Demand Interview?</h4>
                                <p>Think of these like video screenings that candidates take right after they apply. No phone screening necessary! You can add text questions and/or video questions to assess a candidate's qualifications, soft skills, and personality traits. Not camera ready? That's OK! Check out the Video Question Bank of professionally recorded videos to choose from.</p>

                                <h4>What is a Live Interview?</h4>
                                <p>A Live Interview is a video meeting that happens online between you and the candidate(s). They're all recorded for you to rate, review, tag, and share!</p>
                            </CardBody>
                        </div>

                        <div>
                            <Grid container alignItems="center" justify="center">
                                <GridItem xs={12} sm={12} md={10} className={classes.header}>
                                    <CardHeader color="info">
                                        Here are some quick actions to get you started.
                                        </CardHeader>
                                </GridItem>
                            </Grid>
                        </div>

                        <div>
                            <Grid container alignItems="center">
                                <GridItem xs={12} sm={12} md={2}>
                                    <Avatar className={classes.avatar}>Step 1</Avatar>
                                </GridItem>
                                <Grid container item={true} xs={12} sm={12} md={8} justify="center">
                                    <GridItem xs={12} sm={12} md={5}>
                                        <Card>
                                            <Link to={`/company/jobs/create`}
                                                className={classes.imageLink}>
                                                <img
                                                    src={createJobImage}
                                                    alt="Create Job"
                                                    className={classes.image}
                                                />
                                            </Link>
                                            <Link to={`/company/jobs/create`}
                                                className={classes.link}>Create a Job</Link>
                                        </Card>
                                    </GridItem>
                                </Grid>
                            </Grid>
                            
                            <Grid container alignItems="center">
                                <GridItem xs={12} sm={12} md={2}>
                                    <Avatar className={classes.avatar}>Step 2</Avatar>
                                </GridItem>
                                <Grid container item={true} xs={12} sm={12} md={8} alignItems="center">
                                    <GridItem xs={12} sm={12} md={5} className={classes.justufyCenter}>
                                        <Card>
                                            <Link to={`/company/requests/create`}
                                                className={classes.imageLink}>
                                                <img
                                                    src={createDemandInterviewImage}
                                                    alt="Create an On Demand Interview"
                                                    className={classes.image}
                                                />
                                            </Link>
                                            <Link to={`/company/requests/create`}
                                                className={classes.link}>Create an On Demand Interview</Link>
                                        </Card>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={2}>
                                        <CardBody className={classes.textCenter}>OR</CardBody>
                                    </GridItem>
                                    
                                    <GridItem xs={12} sm={12} md={5}>
                                        <Card>
                                            <Link to={`/company/live-interview-schedules/create`}
                                                className={classes.imageLink}>
                                                <img
                                                    src={scheduleInterviewImage}
                                                    alt="Schedule Live Interview"
                                                    className={classes.image}
                                                />
                                            </Link>
                                            <Link to={`/company/live-interview-schedules/create`}
                                                className={classes.link}>Schedule Live Interview</Link>
                                        </Card>
                                    </GridItem>
                                </Grid>
                            </Grid>
                        </div>
                    </>
                )}

                {showSiteSettingsBox() && (
                    <SiteSettingsBox />
                )}
            </div>
        );
    }
}

CompanyHomePage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    site: state.siteDetails.siteDetails.retrieved,
    company: state.company.currentCompany.retrieved,
});

const mapDispatchToProps = dispatch => ({
    getCurrentCompany: () => dispatch(getCurrentCompany()),
});

CompanyHomePage = connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyHomePage);

export default withStyles(companyHomePageStyle)(CompanyHomePage);
