import React, {Component} from 'react';
import {connect} from "react-redux";

class PageError extends Component {
  render() {
    const { error } = this.props;
    return (
      error ? (<div className="alert alert-danger">{error}</div>) : null
    );
  }
}

const mapStateToProps = state => ({
  error: state.onDemandInterviewPage.error,
});

PageError = connect(
  mapStateToProps,
)(PageError);

export default PageError;
