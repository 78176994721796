import React, {Component} from 'react';
import RequestBasicInfo from "./RequestBasicInfo";
import RequestKnockoutQuestions from "./RequestKnockoutQuestions";
import RequestVideoQuestions from "./RequestVideoQuestions";
import RequestPreview from "./RequestPreview";
import FormWizard from "../Wizard/FormWizard";
import {copyVideoEntity} from "../../utils/videoEntity";

export default class RequestWizard extends Component {
    state = {
        videoQuestionSettings: {
            required: true,
            allowVideoAnswer: true,
            allowTextAnswer: false,
            allowAudioAnswer: false,
        },
    };

    addKnockoutQuestionFromBank = (question) => {
        let knockoutQuestions = [...this.props.knockoutQuestions];
        knockoutQuestions.push({
            content: question.content,
            type: question.type,
            required: true,
            knockoutQuestionChoices: question.knockoutQuestionChoices.map((choice) => {
                return {
                    content: choice.content,
                    correct: choice.correct,
                }
            }),
        });
        this.props.change('knockoutQuestions', knockoutQuestions);
    };

    addVideoQuestionFromBank = (question) => {
        let videoQuestions = [...this.props.videoQuestions];
        videoQuestions.push({
            title: question.title,
            answerTimeLimitMinutes: question.answerTimeLimitMinutes,
            answerTimeLimitSeconds: question.answerTimeLimitSeconds,
            thinkTimeLimitMinutes: question.thinkTimeLimitMinutes,
            thinkTimeLimitSeconds: question.thinkTimeLimitSeconds,
            maxAnswerRecordingRetries: question.maxAnswerRecordingRetries,
            required: this.state.videoQuestionSettings.required,
            allowVideoAnswer: this.state.videoQuestionSettings.allowVideoAnswer,
            allowAudioAnswer: this.state.videoQuestionSettings.allowAudioAnswer,
            allowTextAnswer: this.state.videoQuestionSettings.allowTextAnswer,
            questionVideo: copyVideoEntity(question.questionBankVideo),
        });
        this.props.change('questions', videoQuestions);
    };

    updateVideoQuestionSettings = (index, fieldName, value) => {
        if (this.props.videoQuestions.length === (index + 1) && !this.props.videoQuestions[index]['@id']) {
            const videoQuestion = this.props.videoQuestions[index];
            let settings = {
                required: videoQuestion.required,
                allowVideoAnswer: videoQuestion.allowVideoAnswer,
                allowTextAnswer: videoQuestion.allowTextAnswer,
                allowAudioAnswer: videoQuestion.allowAudioAnswer,
            };

            settings[fieldName] = !!value;

            this.setState({videoQuestionSettings: settings});
        }
    };

    swapOrderQuestions = (startIndex, endIndex) => {
        let knockoutQuestions = [...this.props.knockoutQuestions];
        const [removed] = knockoutQuestions.splice(startIndex, 1);
        knockoutQuestions.splice(endIndex, 0, removed);
        this.props.change('knockoutQuestions', knockoutQuestions);
    };

    swapOrderVideoQuestions = (startIndex, endIndex) => {
        let videoQuestions = [...this.props.videoQuestions];
        const [removed] = videoQuestions.splice(startIndex, 1);
        videoQuestions.splice(endIndex, 0, removed);
        this.props.change('questions', videoQuestions);
    };

    onExpand = (questionIndex, expanded) => {
        let knockoutQuestions = [...this.props.knockoutQuestions];
        knockoutQuestions[questionIndex].expanded = expanded;
        this.props.change('knockoutQuestions', knockoutQuestions);
    };

    getWizardSteps = () => (
        [
            {
                stepName: '1. Basic Info',
                content: (<RequestBasicInfo
                    change={this.props.change}
                    initialValues={this.props.initialValues}
                />)
            },
            {
                stepName: '2. Screening Questions',
                content: (<RequestKnockoutQuestions knockoutQuestions={this.props.knockoutQuestions}
                    swapQuestions={this.swapOrderQuestions}
                    onExpand={this.onExpand}
                                                    addQuestionFromBank={this.addKnockoutQuestionFromBank}/>),
            },
            {
                stepName: '3. Video Questions',
                content: (<RequestVideoQuestions videoQuestions={this.props.videoQuestions}
                    swapQuestions={this.swapOrderVideoQuestions}
                                                 addQuestionFromBank={this.addVideoQuestionFromBank}
                                                 change={this.props.change}
                                                 updateVideoQuestionSettings={this.updateVideoQuestionSettings}
                />),
            },
            {
                stepName: '4. Preview',
                content: (<RequestPreview
                    videoQuestions={this.props.videoQuestions}
                    knockoutQuestions={this.props.knockoutQuestions}
                    jobIRI={this.props.job}
                />),
            },
        ]
    );

    render() {
        return (
            <FormWizard
                steps={this.getWizardSteps()}
                onSubmit={this.props.handleSubmit}
                cancelButtonUrl={"/company/requests/"}
            />
        );
    }
}
