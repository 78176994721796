import { SubmissionError } from 'redux-form';
import { fetch } from '../../utils/dataAccess';

const roleEndpointMap = {
  'ROLE_COMPANY_ADMIN': '/company_admins',
  'ROLE_COMPANY_EMPLOYEE': '/company_employees',
  'ROLE_COMPANY_HIRING_MANAGER': '/company_hiring_managers',
};

export function error(error) {
  return { type: 'COMPANY_USER_CREATE_ERROR', error };
}

export function loading(loading) {
  return { type: 'COMPANY_USER_CREATE_LOADING', loading };
}

export function success(created) {
  return { type: 'COMPANY_USER_CREATE_SUCCESS', created };
}

export function create(values) {
  return dispatch => {
    dispatch(loading(true));

    let endpoint = roleEndpointMap[values.role];
    if (!endpoint) {
      dispatch(loading(false));
      dispatch(error('Unknown user role'));

      return;
    }

    return fetch(endpoint, { method: 'POST', data: values })
      .then(response => {
        dispatch(loading(false));
        dispatch(success(response.data));
      })
      .catch(e => {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          if (e.errors.teamCompanyUsers) {
            //Adjust team validation error property path
            e.errors._error = e.errors._error.replace(/teamCompanyUsers\[[\d]+\]\.team/, 'teams')
            const teamError = e.errors.teamCompanyUsers.find(error => error && error.team ? error.team : null);
            e.errors.teams = teamError ? teamError.team : undefined;
          }
          dispatch(error(e.errors._error));
          throw e;
        }

        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(error(null));
    dispatch(success(null));
  };
}
