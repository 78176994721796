import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SettingsForm from "./SettingsForm";
import {retrieve} from "../../actions/UserProfile/update";

class Settings extends Component {
  static propTypes = {
    retrieve: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  componentDidMount() {
    /* send this request to api, to get actual version of site details */
    this.props.getCurrentUser();
  }

  render() {

    return (
      <div>
        {(this.props.created || this.props.updated) && (
          <div className="alert alert-success" role="status">
            Partner settings successfully saved.
          </div>
        )}
        {(this.props.loading) && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}
        {!this.props.loading && (
          <SettingsForm
            initialValues={{displayDefaultContent: this.props.site['displayDefaultContent']}}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  site: state.siteDetails.siteDetails.retrieved,
  loading: state.siteDetails.siteDetails.silentLoading || state.userProfile.update.loading,
});

const mapDispatchToProps = dispatch => ({
  getCurrentUser: () => dispatch(retrieve()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
