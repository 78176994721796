import React, {Component} from "react";
import {connect} from 'react-redux';
import {loadCategories} from "../../actions/QuestionBank/categories";
import {search, reset} from "../../actions/QuestionBank/search";
import QuestionBankFilterForm from "./QuestionBankFilterForm";
import VideoQuestionBankItem from "./VideoQuestionBankItem";
import {loadPresentersForSelect} from "../../actions/Presenter/forSelect";
import questionBankFilterFormStyle from "../../assets/jss/material-dashboard-pro-react/components/questionBankFilterFormStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import FilterList from '@material-ui/icons/FilterList';
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import {getUrlQueryParams} from "../../utils/urls";
import {inlinePagination} from "../../utils/entityList";
import Typography from "@material-ui/core/Typography";

class VideoQuestionBankSearch extends Component {
    static defaultProps = {
        itemsPerPage: 15,
    };

    componentWillMount() {
        this.props.categories || this.props.loadQuestionBankCategories();
        this.props.presenters || this.props.loadPresentersForSelect();
        this.props.retrieved || this.loadQuestions();
    }

    componentWillUnmount() {
        this.props.reset();
    }

    paginate = (newUrl) => {
        const filters = getUrlQueryParams(newUrl);
        this.loadQuestions(filters);
    };

    loadQuestions = (filters = {}) => {
        filters['itemsPerPage'] = this.props.itemsPerPage;
        this.props.search(filters);
    };

    render() {
        const {categories, classes, presenters, retrieved, loading} = this.props;
        return (
            <>
                <ExpansionPanel className={classes.expansionPanel}>
                    <ExpansionPanelSummary expandIcon={<FilterList />} classes={{
                        root: classes.expansionPanelSummary,
                        content: classes.expansionPanelSummaryContent,
                        expanded: classes.expansionPanelSummaryExpanded,
                    }}>
                        <h2>Question Bank</h2>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                        <QuestionBankFilterForm
                            value={this.props.selectedCategories}
                            categories={categories}
                            presenters={presenters}
                            onSubmit={this.loadQuestions}
                            formName="VideoQuestionBankFilterForm"
                            keywordFieldName="title"
                            categoriesFieldName="questionBankCategories"
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {loading && (
                    <div className="alert alert-info">Loading...</div>
                )}
                {retrieved && retrieved['hydra:member'].map((videoQuestion, index) => {
                    return (<VideoQuestionBankItem key={index}
                                                   videoQuestion={videoQuestion}
                                                   onQuestionAdd={this.props.onQuestionAdd}
                    />);
                })}
                {!loading && retrieved && retrieved['hydra:member'].length === 0 && (
                    <Typography color="textSecondary">No results found. Please change your search criteria.</Typography>
                )}
                {inlinePagination(retrieved, this.paginate, {component: 'div', rowsPerPage: this.props.itemsPerPage})}
            </>
        )
    }
}

VideoQuestionBankSearch = withStyles(questionBankFilterFormStyle)(VideoQuestionBankSearch);

const mapStateToProps = state => {
    return {
        retrieved: state.questionbank.search.retrieved,
        categories: state.questionbank.categories.retrieved,
        presenters: state.presenter.forSelect.retrieved,
        error: state.questionbank.search.error || state.questionbank.categories.error,
        loading: state.questionbank.search.loading,
    }
};
const mapDispatchToProps = dispatch => ({
    loadQuestionBankCategories: () => dispatch(loadCategories()),
    loadPresentersForSelect: () => dispatch(loadPresentersForSelect('@id')),
    search: (filters) => dispatch(search(filters)),
    reset: () => dispatch(reset())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps)
(VideoQuestionBankSearch);
