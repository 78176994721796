import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import {renderTextField} from "../../utils/form";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Email} from "@material-ui/icons";
import LockOutlined from "@material-ui/icons/LockOutlined";
import FormControl from "@material-ui/core/FormControl";
import {Link} from "react-router-dom";
import {isEmail} from "validator";

class LoginForm extends Component {
  render() {
    const {loggingIn} = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <FormControl fullWidth={true} className={'on-demand-interview-page-login-form-control'}>
          <Field
            component={renderTextField}
            name="email"
            type="email"
            placeholder="Email"
            required={true}
            label={false}
            className="required"
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                >
                  <Email />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl fullWidth={true} className={'on-demand-interview-page-login-form-control'}>
          <Field
            component={renderTextField}
            name="password"
            type="password"
            placeholder="Password"
            required={true}
            label={false}
            className="required"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" >
                  <LockOutlined/>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <div className={'on-demand-interview-page-forgot-password'}>
          <Link to={`/password-recovery`}>Forgot Password</Link>
        </div>
        <div className={'on-demand-interview-page-login-dialog-actions'}>
          <button type="submit" className={"btn btn-info on-demand-interview-page-dialog-login-button"} disabled={loggingIn}>
            {!loggingIn ? ('Login') : ('Logging In...')}
          </button>
        </div>
      </form>
    )
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Please enter an email';
  } else if (!isEmail(values.email)) {
    errors.email = 'Please enter a valid email';
  }
  if (!values.password) {
    errors.password = 'Please enter a password';
  }
  return errors
}

LoginForm = reduxForm({
  form: 'onDemandInterviewLoginForm',
  validate,
})(LoginForm);

export default LoginForm;
