import {fetch,} from '../../utils/dataAccess';

export function manageDeletedError(manageDeletedError) {
  return { type: 'TEAM_COMPANY_USER_MANAGE_DELETE_ERROR', manageDeletedError };
}

export function manageDeletedSaved(manageDeletedSaved) {
  return { type: 'TEAM_COMPANY_USER_MANAGE_DELETE_SAVED', manageDeletedSaved };
}

export function manageDeletedLoading(manageDeletedLoading) {
  return { type: 'TEAM_COMPANY_USER_MANAGE_DELETE_LOADING', manageDeletedLoading };
}

export function manageDeleted(teamCompanyUser) {
  return dispatch => {
    dispatch(manageDeletedLoading(true));

    return fetch(teamCompanyUser['@id'], {method: 'PUT'})
      .then(response =>
        ({ result: response.data})
      )
      .then(({ result}) => {
        dispatch(manageDeletedLoading(false));
        dispatch(manageDeletedSaved(result));
      })
      .catch(e => {
        dispatch(manageDeletedLoading(false));
        dispatch(manageDeletedError(e.message));
      });
  };
}


export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch(manageDeletedError(null));
    dispatch(manageDeletedSaved(null));
    dispatch(manageDeletedLoading(false));
  };
}

