import { combineReducers } from 'redux';

export function error(state = null, action) {
  switch (action.type) {
    case 'TEAM_ACTIVATE_ERROR':
      return action.error;
    case 'TEAM_ACTIVATE_RESET':
      return null;
    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'TEAM_ACTIVATE_LOADING':
      return action.loading;
    case 'TEAM_ACTIVATE_RESET':
      return false;
    default:
      return state;
  }
}

export function activated(state = null, action) {
  switch (action.type) {
    case 'TEAM_ACTIVATE_SUCCESS':
      return action.activated;
    case 'TEAM_ACTIVATE_RESET':
      return null;
    default:
      return state;
  }
}

export default combineReducers({ error, loading, activated });
