import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
import Business from "@material-ui/icons/Business";
import AccountBalance from "@material-ui/icons/AccountBalance";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import candidateDashboardPageStyle from "assets/jss/material-dashboard-pro-react/views/candidate/dashboardPageStyle.jsx";
import storage from "../../../utils/storage";
import SiteSettingsBox, {showSiteSettingsBox} from "../SiteSettingsBox";

class CandidateDashboardPage extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        Welcome to the Dashboard! Please click on any link to the left to get started!

        {showSiteSettingsBox() && (
          <SiteSettingsBox />
        )}
      </div>
    );
  }
}

CandidateDashboardPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(candidateDashboardPageStyle)(CandidateDashboardPage);
