import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import KnockoutQuestionAnswerView from "../KnockoutQuestion/KnockoutQuestionAnswerView";
import VideoQuestionAnswerView from "../VideoQuestionPlayer/VideoQuestionAnswerView";
import React from "react"
import {getScreeningQuestionAnswerPairs, getVideoQuestionAnswerPairs} from "../../utils/onDemandInterview";

export class OneWayInterviewAnswers extends React.Component
{
  render() {
    const screeningQuestionAnswerPairs = getScreeningQuestionAnswerPairs(this.props.item);
    const videoQuestionAnswerPairs = getVideoQuestionAnswerPairs(this.props.item);
    return (
      <GridContainer>
        {screeningQuestionAnswerPairs.length > 0 && (
          <>
            <GridItem xs={12}><h2>Screening Questions</h2></GridItem>
            {screeningQuestionAnswerPairs.map(({question, answer}, index) => (
              <GridItem lg={4} md={6} xs={12}
                        style={{marginBottom: '20px'}} key={'knockout-question-answer-view-' + index}
              >
                <Card style={{height: '100%', margin: 0}}>
                  <CardBody>
                    <KnockoutQuestionAnswerView question={question} answer={answer} showCorrect={true}/>
                  </CardBody>
                </Card>
              </GridItem>
            ))}
          </>
        )}
        {videoQuestionAnswerPairs.length > 0 && (
          <>
            <GridItem xs={12}><h2>Video Questions</h2></GridItem>
            {videoQuestionAnswerPairs.map(({question, answer}, index) => (
              <GridItem lg={4} md={6} xs={12}
                        key={'video-question-answer-view-' + index}
                        style={{marginBottom: '20px'}}
              >
                <Card style={{height: '100%', margin: 0}}>
                  <CardBody>
                    <VideoQuestionAnswerView question={question} answer={answer}/>
                  </CardBody>
                </Card>
              </GridItem>
            ))}
          </>
        )}
      </GridContainer>
    );
  }
}
