import React, {Component} from 'react';
import PropTypes from "prop-types";
import Dialog from "../../views/Components/Dialog/Dialog";

class SkipQuestionDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSkip: PropTypes.func.isRequired,
  };

  render() {
    const {onClose, onSkip} = this.props;

    return (
      <Dialog
        open={true}
        onClose={onClose}
        title={"Skip Question"}
        closeButtonText={"No"}
        confirmButtonText={"Yes"}
        onConfirm={onSkip}
      >
        Are you sure you want to skip this question? You will not be able to answer this question later.
      </Dialog>
    )
  }
}

export default SkipQuestionDialog;
