import {
  fetch,
  normalize,
  extractHubURL,
} from '../../utils/dataAccess';
import {filterAction, sortAction} from "../../utils/filterAndSortUtils";
import {success as deleteSuccess} from "./delete";
import {success as createSuccess} from "./create";
import {success as setAsDefaultSuccess} from "./setAsDefault";

export function error(error) {
  return { type: 'PAYMENT_METHOD_LIST_ERROR', error };
}

export function loading(loading) {
  return { type: 'PAYMENT_METHOD_LIST_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'PAYMENT_METHOD_LIST_SUCCESS', retrieved };
}

export function list(page = 'payment_methods') {
  return dispatch => {
    dispatch(loading(true));
    dispatch(error(''));

    fetch(page)
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {
        retrieved = normalize(retrieved);

        dispatch(loading(false));
        dispatch(success(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'PAYMENT_METHOD_LIST_RESET' });
    dispatch(createSuccess(null));
    dispatch(setAsDefaultSuccess(null));
    dispatch(deleteSuccess(null));
  };
}

export function filter(filtersData) {
  return filterAction('PAYMENT_METHOD_LIST_FILTER', 'payment_methods', filtersData)
}

export function sort(sortData) {
  return sortAction('PAYMENT_METHOD_LIST_SORT', 'payment_methods', sortData)
}
