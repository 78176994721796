import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';
import {fetchJSON} from "../../utils/dataAccess";
import {resolveDashboardUrl} from "../../utils/dashboardUrlResolver";
import switchUserStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarSwitchUser.jsx";
import Divider from '@material-ui/core/Divider';
import withStyles from "@material-ui/core/styles/withStyles";
import {NavLink} from "react-router-dom";
import ListItemText from "@material-ui/core/ListItemText";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import {getSwitchedUsersHistory, setSwitchedUsersHistory} from "../../utils/auth";
import {history, store} from '../../store';
import {resetStore} from "../../actions/Root";
import {loadSiteDetails} from "../../actions/SiteDetails/loadSiteDetails";
import storage from "../../utils/storage";
import {CircularProgress} from "@material-ui/core";

class SwitchUser extends Component {
    state = {
        anchorEl: null,
        loading: false,
    };

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleClickSwitchUser = (index, item) => {
        if (index > 0 && !this.state.loading) {
            this.setState({loading: true});

            handleSwitchUser(
                item.id,
                false,
                () => {this.setState({loading: false});}
            );
        }
    }

    render() {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const switchedUsersHistory = getSwitchedUsersHistory();
        const {classes} = this.props;
        if (switchedUsersHistory.length < 2) {
            return '';
        }

        return (
            <div>
                <NavLink
                    to={"#"}
                    className={classes.itemLink}
                    aria-owns={open ? 'switch-user-popper' : undefined}
                    onClick={this.handleClick}
                >

                    {this.state.loading ? (
                        <CircularProgress
                            className={classes.loadingIcon}
                            size={18}
                            color={'gray'}
                        />
                    ) : (
                        <SupervisorAccountIcon className={classes.itemIcon}/>
                    )}
                    <ListItemText
                        primary={switchedUsersHistory[0].name.slice(0, 22)}
                        secondary={<b className={classes.caret} />}
                        disableTypography={true}
                        className={classes.itemText}
                    />
                </NavLink>

                <Popover
                    id="switch-user-popper"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    {switchedUsersHistory.map((item, index) => (
                        <div key={index}>
                            <div className={index === 0 ? classes.switchUserRowFirst : classes.switchUserRow} onClick={() => this.handleClickSwitchUser(index, item)}>
                                <span>{item.username}</span><br/>
                                <span className={classes.switchUserRowEmail}>{item.email}</span><br/>
                                <span className={classes.switchUserRowRoleName}>{item.role_name}</span><br/>
                            </div>
                            <Divider />
                        </div>
                    ))}
                </Popover>
            </div>
        );
    }
}

export function handleSwitchUser(id, redirectToDashboard = true, callback = null) {
    let url = '/switch-user';
    const switchedUsersHistory = getSwitchedUsersHistory();
    if (storage.get('originalUserId') === id) {
        storage.remove('X-Switch-User');
    } else {
        storage.set('X-Switch-User', id);
    }

    fetchJSON(url).then((res) => {
        if (!('roles' in res)) {
            storage.remove('X-Switch-User');

            return;
        }

        storage.set('roles', res.roles);
        storage.set('userId', res.userId);
        storage.set('site-settings-accepted-at', res['site-settings-accepted-at']);

        /* remove user from history if already exist */
        let original = false;
        for (let i = 0; i < switchedUsersHistory.length; i++) {
            let user = switchedUsersHistory[i];
            if (user.id === id) {
                original = switchedUsersHistory[i].original;
                switchedUsersHistory.splice(i, 1);
            }
        }
        if (switchedUsersHistory.length > 4) {
            /* if last switched user in history is original don't remove him */
            if (switchedUsersHistory[4].original) {
                switchedUsersHistory.splice(3, 1);
            } else {
                switchedUsersHistory.splice(4, 1);
            }
        }

        switchedUsersHistory.unshift({
            'id'        : res.userId,
            'email'     : res.email,
            'username'  : res.username,
            'name'      : res.name,
            'role_name' : res.role_name,
            'original'  : original,
        });

        store.dispatch(resetStore());
        store.dispatch(loadSiteDetails());
        setSwitchedUsersHistory(switchedUsersHistory);

        if (redirectToDashboard) {
            history.push(resolveDashboardUrl(res.roles));
        }
    }).finally(res => {
        if (typeof callback === 'function') {
            callback(res);
        }
    });
}

export default withStyles(switchUserStyle)(SwitchUser);
