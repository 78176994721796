import React from "react";
import PropTypes from 'prop-types';
import defaultProfileImage from "../../assets/img/chat-default-avatar.png";
import iconChatMessages from "../../assets/img/icon-chat-messages.png";
import {toHHMMSS} from "../../utils/time";

export default class LiveInterviewChatHistory extends React.Component {
  static propTypes = {
    startedAt: PropTypes.string.isRequired,
    messages: PropTypes.array.isRequired,
    onChatMessageTimeClick: PropTypes.func,
  };

  render() {
    const {startedAt, messages, onChatMessageTimeClick} = this.props;

    const startDate = new Date(startedAt);

    const getSecondsSinceStart = (time) => {
      return ((new Date(time)).getTime() - startDate.getTime()) / 1000;
    };

    const getMessageTime = (createdAt) => {
      return toHHMMSS(getSecondsSinceStart(createdAt));
    };

    return (
      <div className="chat-history">
        <p className="chat-header">
          <img src={iconChatMessages} alt="Chat messages" /> Chat History
        </p>

        <div className="chat-messages">
          {messages.map(message => (
            <div className="chat-message-wrapper" key={'message-' + message['body']['id']}>
              <div className={'chat-message-time' + (!onChatMessageTimeClick ? '-disabled' : '')} onClick={() =>
                onChatMessageTimeClick && onChatMessageTimeClick(getSecondsSinceStart(message['body']['time']))
              }>
                {getMessageTime(message['body']['time'])}
              </div>
              <div className="chat-message">
                <img src={defaultProfileImage} alt={message['username']}/>
                <p className="chat-message-text">
                  <span className="username">{message['body']['firstName'] + ' ' + message['body']['lastName']}: </span>{message['body']['message']}
                </p>
              </div>
            </div>
          ))}
        </div>

      </div>
    )
  }
}
