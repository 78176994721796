import React, {Component} from "react";
import {Field} from "redux-form";
import {renderInlineRadioButtons, renderSelect, renderTextField} from "../../utils/form";
import Button from "../CustomButtons/Button";
import KnockoutQuestionChoicesEditor from "../KnockoutQuestion/KnockoutQuestionChoicesEditor";
import FormLabel from "@material-ui/core/FormLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import './KnockoutQuestion.scss';
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";

export default class KnockoutQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: KnockoutQuestion.shouldBeExpanded(this.props.currentValue, (expanded) => {
                this.props.onExpand(
                    this.props.index,
                    expanded
                );

                return expanded;
            }),
            type: props.currentValue.type,
        };
    }

    toggleExpanded = () => {
        this.setState((prevState) => ({expanded: !prevState.expanded}), this.updateExpandedStatus)
    };

    updateExpandedStatus = () => {
        this.props.onExpand(this.props.index, this.state.expanded);
    };

    static shouldBeExpanded(knockoutQuestion, cb) {
        if (!knockoutQuestion.content) {
            return cb(true);
        }
        for (let i = 0; i < knockoutQuestion.knockoutQuestionChoices.length; i++) {
            if (knockoutQuestion.knockoutQuestionChoices[i].correct) {
                return cb(false);
            }
        }

        return cb(true);
    };

    componentDidUpdate(prevProps) {
        if (this.props.currentValue.expanded !== prevProps.currentValue.expanded) {
            this.setState({expanded: this.props.currentValue.expanded});
        }
    };

    renderButtons = () => (
        <div className='buttons'>
            <Button size="sm" className='button' onClick={this.toggleExpanded}>
                {this.state.expanded ? (
                    <ExpandLess>Collapse</ExpandLess>
                ) : (
                    <ExpandMore>View</ExpandMore>
                )}
            </Button>
            {this.props.children}
        </div>
    );

    onSelectType = (e, value) => {
        if (value === 'text') {
            this.props.currentValue.knockoutQuestionChoices = [];
        }

        this.setState({type: value});
    }

    render() {
        const {isDroppable} = this.props;
        return (
            <div className={'knockout-question' + (this.state.expanded ? ' expanded' : '') + (isDroppable ? ' droppable' : '')}>
                {!this.state.expanded ?
                    (<>
                        {this.props.prefix}
                        <div className="question-content">{this.props.currentValue.content}</div>
                        {this.renderButtons()}
                    </>) : (
                        <Card className='editor-card'>
                            <CardBody className='editor-card-body'>
                                <div className='prefix-and-buttons'>
                                    {this.props.prefix}
                                    {this.renderButtons()}
                                </div>
                                <Field
                                    name={`${this.props.name}.content`}
                                    component={renderTextField}
                                    label="Question Statement"
                                    required={true}
                                    fullWidth
                                />
                                <Field
                                    name={`${this.props.name}.required`}
                                    component={renderInlineRadioButtons}
                                    type="checkbox"
                                    label="Mark as Required"
                                    required={true}
                                    containerClass={"form-group mt-2"}
                                    choices={[
                                        {value: "true", label: 'Yes'},
                                        {value: "false", label: 'No'},
                                    ]}
                                    normalize={v => v === 'true'}
                                />
                                <Field
                                    component={renderSelect}
                                    name={`${this.props.name}.type`}
                                    label="Question Type"
                                    placeholder="Select Question Type"
                                    required={true}
                                    onChange={this.onSelectType}
                                >
                                    {[
                                        {
                                            text: "Multiple Choice (One Answer)",
                                            value: 'single',
                                        },
                                        {
                                            text: "Multiple Choice (Many Answers)",
                                            value: "multiple",
                                        },
                                        {
                                            text: "Text",
                                            value: "text",
                                        }
                                    ].map((choice, i) => (
                                        <MenuItem key={i}
                                                  value={choice.value}>
                                            {choice.text}
                                        </MenuItem>
                                    ))}
                                </Field>
                                {this.state.type !== 'text' && (
                                    <>
                                        <FormLabel>Choices:</FormLabel>
                                        <div className='choices'>
                                            <KnockoutQuestionChoicesEditor
                                                name={`${this.props.name}.knockoutQuestionChoices`}
                                            />
                                        </div>
                                    </>
                                )}
                            </CardBody>
                        </Card>
                    )}
            </div>
        );
    }
}
