import moment from "moment-timezone";

export function setQuestionAndChoicePositions(request) {
  if (!request) {
    return;
  }
  if (request.knockoutQuestions) {
    for (let i = 0; i < request.knockoutQuestions.length; i++) {
      for (let n = 0; n < request.knockoutQuestions[i].knockoutQuestionChoices.length; n++) {
        request.knockoutQuestions[i].knockoutQuestionChoices[n].position = n;
      }
      request.knockoutQuestions[i].position = i;
    }
  }
  if (request.questions) {
    for (let i = 0; i < request.questions.length; i++) {
      request.questions[i].position = i;
    }
  }
}
export function sort (retrieved)  {
  retrieved.knockoutQuestions && retrieved.knockoutQuestions.sort((itemA, itemB) => {
    return Math.sign(itemA.position-itemB.position);
  });
  retrieved.questions && retrieved.questions.sort((itemA, itemB) => {
    return Math.sign(itemA.position-itemB.position);
  });
  return retrieved;
}

export function setEndOfDay(date) {
  if (date) {
    let expiresAt = date;
    if (expiresAt.format) {
      expiresAt = expiresAt.format();
    }

    date = moment(expiresAt).endOf('day');
  }

  return date;
}
