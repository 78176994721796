import React, {Component} from 'react';
import RecordingIndicator from "../VideoRecorder/RecordingIndicator";
import PropTypes from 'prop-types';

export default class TimeElapsedDisplay extends Component {
    static propTypes = {
        startTime: PropTypes.instanceOf(Date),
        onCountedDown: PropTypes.func,
        containerClass: PropTypes.string,
        running: PropTypes.bool,
        withHours: PropTypes.bool,
        isNegative: PropTypes.bool,
        indicator: PropTypes.object,
        recordingText: PropTypes.string,
        onTick: PropTypes.func,
    }

    static defaultProps = {
        containerClass: 'time-elapsed-display',
        withHours: true,
        isNegative: false,
        indicator: <RecordingIndicator text={null} />,
        recordingText: 'Recording'
    }

    constructor(props) {
        super(props);
        this.state = {time: Date.now()};
    }

    componentDidMount() {
        if (this.props.running) {
            this.startTimer();
        }
    }

    componentDidUpdate() {
        const seconds = this.getTimerSeconds();
        if (this.props.isNegative && seconds === 0 || seconds === 3600) {
            if (typeof this.props.onCountedDown === 'function') {
                this.stopTimer();
                this.props.onCountedDown();
            }
        }

        if (this.props.running) {
            if (this.interval) {
                return;
            }
            this.startTimer();
        } else if (this.interval) {
            this.stopTimer();
        }
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    startTimer() {
        this.setState({time: Date.now()});
        this.interval = setInterval(() => {
            const time = Date.now();
            this.setState({time: time});
            if (typeof this.props.onTick === 'function') {
                this.props.onTick(time, this.props.startTime);
            }
        }, 1000);
    }

    stopTimer() {
        clearInterval(this.interval);
        this.interval = undefined;
    }

    getTimerSeconds = () => {
        const {startTime} = this.props;
        const {time} = this.state;
        const diff = (time - startTime) / 1000;

        return parseInt(diff.toString(), 10);
    }

    render() {
        const {indicator, recordingText} = this.props;
        let dateDifference;
        if (this.props.startTime !== undefined) {
            dateDifference = this.toHHMMSS(this.getTimerSeconds());
        }
        return (
            <span className={this.props.containerClass}>
                {indicator && (indicator )}
                {recordingText && (recordingText )} {this.props.startTime ? (dateDifference) : '00:00'}
            </span>
        );
    }

    toHHMMSS = (secs) => {
        let hours = Math.floor(Math.abs(secs / 3600) % 24);
        let minutes = Math.floor(Math.abs(secs / 60) % 60);
        let seconds = Math.abs(secs % 60);
        let time = this.props.withHours ? [hours, minutes, seconds] : [minutes, seconds];

        time = time.map(v => v < 10 ? "0" + v : v)
        if (!this.props.isNegative) {
            time = time.filter((v, i) => v !== "00" || i > 0);
        }

        return time.join(':')
    };
}
