import React, {Component} from "react";
import CardBody from "../Card/CardBody";
import Card from "../Card/Card";
import KnockoutQuestionPreview from "../KnockoutQuestion/KnockoutQuestionPreview";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import './KnockoutQuestionBankItem.scss'
import Button from "../CustomButtons/Button";

export default class KnockoutQuestionBankItem extends Component {
    state = {
        expanded: false,
    };
    toggleExpanded = () => {
        this.setState((prevState) => {
            return {expanded: !prevState.expanded};
        });
    };
    onQuestionAdd = () => {
        this.props.onQuestionAdd(this.props.knockoutQuestion);
    };
    renderButtons = () => (
        <div className='buttons'>
            <Button size="sm" className='button' onClick={this.toggleExpanded}>
                {this.state.expanded ? (
                    <ExpandLess>Collapse</ExpandLess>
                ) : (
                    <ExpandMore>View</ExpandMore>
                )}
            </Button>
            <Button size="sm" color='success' className='button' onClick={this.onQuestionAdd}>
                <PlaylistAdd>
                    Add
                </PlaylistAdd>
            </Button>
        </div>
    );

    render() {
        let knockoutQuestion = this.props.knockoutQuestion;
        return (
            <div className={'knockout-question-bank-item' + (this.state.expanded ? ' expanded' : '')}>
                {this.state.expanded ? (
                    <Card className='preview-card'>
                        <CardBody className='preview-card-body'>
                            {this.renderButtons()}
                            <KnockoutQuestionPreview knockoutQuestion={knockoutQuestion}/>
                        </CardBody>
                    </Card>
                ) : (
                    <>
                        {knockoutQuestion.content}
                        {this.renderButtons()}
                    </>
                )}
            </div>
        );
    }
}
