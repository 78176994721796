import UAParser from "ua-parser-js";

export function getAudioTrack(mediaStream) {
  return mediaStream.getAudioTracks().length > 0 ? mediaStream.getAudioTracks()[0] : null;
}

export function getVideoTrack(mediaStream) {
  return mediaStream.getVideoTracks().length > 0 ? mediaStream.getVideoTracks()[0] : null;
}

export function getAudioDevice(mediaStream) {
  const track = getAudioTrack(mediaStream);
  return track ? track.getSettings() : null;
}

export function getVideoDevice(mediaStream) {
  const track = getVideoTrack(mediaStream);
  return track ? track.getSettings() : null;
}

export function isMultipleAudioDevicesSupported() {
  const browser = new UAParser().getBrowser();
  return (browser && browser.name.toLowerCase() !== 'firefox');
}

export function isVideoTrackMuted(mediaStream) {
  const videoTrack = getVideoTrack(mediaStream);
  return videoTrack ? videoTrack.muted === true : false;
}

export function filterAudioDevices(devices) {
  const audioDevices = {};
  devices.map(({deviceId, label, kind}) => {
    if (kind === 'audioinput') {
      audioDevices[deviceId] = {id: deviceId, label: cleanMediaDeviceName(label)};
    }
  });
  return audioDevices;
}

export function filterVideoDevices(devices) {
  const videoDevices = {};
  devices.map(({deviceId, label, kind}) => {
    if (kind === 'videoinput') {
      videoDevices[deviceId] = {id: deviceId, label: cleanMediaDeviceName(label)};
    }
  });
  return videoDevices;
}

export function cleanMediaDeviceName(deviceName) {
  if (!deviceName) {
    return '';
  }
  return deviceName.replace(/\(([a-z0-9:]*)\)/g,'').trim();
}
