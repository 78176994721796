import React, {Component} from 'react';
import Dialog from "../../views/Components/Dialog/Dialog";
import {getRequestUrlByRoomCode} from "../../utils/urls";
import {TextField, Tooltip} from "@material-ui/core";
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  dialog: {
    '& .io-dialog-paper': {
      maxWidth: '620px',
    }
  }
}

class AfterCreateInterviewDialog extends Component {
  state = {
    clipboardTooltipOpen: false,
  }

  static propTypes = {
    interview: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  copyLink = (e) => {
    e.preventDefault();

    let input = document.querySelector('.link-input input');
    input.focus();
    input.select();
    input.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy");

    this.setState({clipboardTooltipOpen: true});
    setTimeout(() => {this.setState({clipboardTooltipOpen: false})}, 3330);
  }

  renderContent = () => {
    const {interview} = this.props;
    return (
      <>
        <p className={"mt-3"}>Here is what you can do now:</p>
        <p className={"share-link-text"}><i className="fa fa-link" aria-hidden="true" /> Share Link:</p>
        <div className={"share-link-field"}>
          <TextField
            label={'Link'}
            placeholder={'Link'}
            variant="outlined"
            value={getRequestUrlByRoomCode(interview.roomCode)}
            className="link-input"
            readOnly={true}
          />
          <Tooltip open={this.state.clipboardTooltipOpen} title="Copied to your clipboard" placement={"top"}>
            <button className={'btn btn-link'} onClick={this.copyLink}><i className="fa fa-files-o" aria-hidden="true" /> Copy</button>
          </Tooltip>
        </div>
        <Link to={'/company/invitations/send/' + encodeURIComponent(interview['@id'])} className={'btn btn-info mb-2'} style={{width: '70%'}}>Invite Candidates via Email</Link>
        <Link to={'show/' + encodeURIComponent(interview['@id'])} className={'btn btn-info mb-2'} style={{width: '70%'}}>View Interview</Link>
      </>
    );
  }

  render() {
    const {onClose, classes} = this.props;
    return (
      <Dialog
        id="create-interview-success-modal"
        open={true}
        onClose={onClose}
        title={"Congratulations! Your interview was created successfully"}
        contentClass={"text-center create-interview-success-modal-content"}
        className={classes.dialog}
      >
        {this.renderContent()}
      </Dialog>
    )
  }
}

AfterCreateInterviewDialog = withStyles(styles)(AfterCreateInterviewDialog);

export default AfterCreateInterviewDialog;
