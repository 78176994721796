import React, {Component} from "react";
import {connect} from "react-redux";
import SearchableSelect from "../../views/Components/SearchableSelect";
import {Field, formValueSelector, reduxForm, reset} from "redux-form";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import {renderTextField} from "../../utils/form";

class QuestionBankFilterForm extends Component {
    onIndustryChange = (e, value, oldValue) => {
        if (value !== oldValue) {
            this.props.change('professionMappings.occupation', null);
            this.props.change('professionMappings.specialty', null);
        }
    };

    onOccupationChange = (e, value, oldValue) => {
        if (value !== oldValue) {
            this.props.change('professionMappings.specialty', null);
        }
    };

    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            event.preventDefault();
            this.props.handleSubmit();
        }
    };

    resetFilters = () => {
        this.props.resetFilters();
        this.props.onSubmit({});
    };

    render() {
        const {
            keywordFieldName, categoriesFieldName, resetFilters, presenters, applyFilters,
            categories, industries, industry, occupations, occupation, specialties,
        } = this.props;
        let occupationPlaceholder = 'Select Industry First';
        let specialtyPlaceholder = 'Select Occupation First';
        let occupationOptions = [];
        let specialtyOptions = [];

        if (industry && occupations) {
            occupationPlaceholder = 'Occupation';
            occupations.map(occupation => {
                if (occupation.industry === industry) {
                    occupationOptions.push(occupation);
                }
            });
        }
        if (occupation && specialties) {
            specialtyPlaceholder = 'Specialty';
            specialties.map(specialty => {
                if (specialty.occupation === occupation) {
                    specialtyOptions.push(specialty);
                }
            });
        }

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Field
                        name={keywordFieldName}
                        label='Keyword'
                        placeholder='Keyword'
                        autoComplete='off'
                        component={renderTextField}
                        onKeyPress={this.handleKeyPress}
                        fullWidth
                    />
                </GridItem>
                {categories && (
                <GridItem xs={12} sm={12} md={12}>
                    <Field
                        name={categoriesFieldName}
                        label='Categories'
                        placeholder='Categories'
                        isMulti={true}
                        component={SearchableSelect}
                        onKeyPress={this.handleKeyPress}
                        options={categories}
                        fullWidth
                    />
                </GridItem>
                )}
                {presenters && (
                <GridItem xs={12} sm={12} md={12}>
                    <Field
                        name='presenter'
                        label='Presenter'
                        placeholder='Presenters'
                        component={SearchableSelect}
                        onKeyPress={this.handleKeyPress}
                        options={presenters}
                        fullWidth
                    />
                </GridItem>
                )}
                <GridItem xs={12} sm={12} md={12}>
                    <Field
                        name="professionMappings.industry"
                        label='Industry'
                        placeholder='Industry'
                        component={SearchableSelect}
                        options={industries}
                        onChange={this.onIndustryChange}
                        onKeyPress={this.handleKeyPress}
                        fullWidth
                    />
                </GridItem>
                {industry && (
                <GridItem xs={12} sm={12} md={12}>
                    <Field
                        name="professionMappings.occupation"
                        label='Occupation'
                        placeholder={occupationPlaceholder}
                        component={SearchableSelect}
                        options={occupationOptions}
                        isDisabled={!industry}
                        onChange={this.onOccupationChange}
                        onKeyPress={this.handleKeyPress}
                        fullWidth
                    />
                </GridItem>
                )}
                {occupation && (
                <GridItem xs={12} sm={12} md={12}>
                    <Field
                        name="professionMappings.specialty"
                        label='Specialty'
                        placeholder={specialtyPlaceholder}
                        component={SearchableSelect}
                        onKeyPress={this.handleKeyPress}
                        options={specialtyOptions}
                        isDisabled={!occupation}
                        fullWidth
                    />
                </GridItem>
                )}
                <GridItem xs={12} sm={12} md={12}>
                    <button className="btn btn-info" type="button" onClick={this.props.handleSubmit}>
                        Filter
                    </button>
                    <button className="btn btn-light ml-2" type="button" onClick={this.resetFilters}>
                        Reset
                    </button>
                    <hr style={{marginBottom: 0}}/>
                </GridItem>
            </GridContainer>
        );
    }
}

QuestionBankFilterForm = reduxForm({
    form: 'QuestionBankFilterForm',
})(QuestionBankFilterForm);

const mapStateToProps = (state, ownProps) => {
    const {formName} = ownProps;
    const selector = formValueSelector(formName);
    return {
        form: formName,
        industries: state.industry.forSelect.retrieved,
        industry: selector(state, 'professionMappings.industry'),
        occupations: state.occupation.forSelect.retrieved,
        occupation: selector(state, 'professionMappings.occupation'),
        specialties: state.specialty.forSelect.retrieved,
    }
};
const mapDispatchToProps = (dispatch, ownProps) => ({
    resetFilters: () => dispatch(reset(ownProps.formName)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps)
(QuestionBankFilterForm);
