import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";
import { loadKnockoutQuestionBankCategoryForSelect } from "../../actions/KnockoutQuestionBankCategory/forSelect";

class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  componentDidMount() {
    this.props.loadKnockoutQuestionBankCategoryForSelect();
  }

  render() {
    return (
      <RenderFilterForm {...this.props} fields={[
        {'name': 'id', 'label': 'ID', 'placeholder': 'ID', 'type': 'number'},
        {'name': 'content', 'label': 'Question Statement', 'placeholder': 'Question Statement', 'type': 'text'},
        {'name': 'knockoutQuestionBankCategories____', 'label': 'Category', 'placeholder': 'Category', 'type': 'selectSearch', 'isMulti': true, 'options': this.props.categories},
      ]} />
    );
  }
}

const mapStateToProps = state => {
  let categories = state.knockoutquestionbankcategory.forSelect.retrieved;

  return { categories };
};

const mapDispatchToProps = dispatch => ({
  loadKnockoutQuestionBankCategoryForSelect: () => dispatch(loadKnockoutQuestionBankCategoryForSelect()),
});

FilterForm = reduxForm({
  form: 'filter_knockout_question_bank',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterForm);
