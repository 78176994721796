import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Form from './Form';
import { retrieve, update, reset } from '../../actions/Plan/update';

class PlanUpdate extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    retrieveLoading: PropTypes.bool.isRequired,
    retrieveError: PropTypes.string,
    updateLoading: PropTypes.bool.isRequired,
    updateError: PropTypes.string,
    updated: PropTypes.object,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  prepareInitialValues = (plan) => {
    const removeZeros = (price) => {
      if (price && price.toString().slice(-3) === '.00') {
        return parseInt(price).toString();
      }

      return price;
    }

    plan.annualPrice = removeZeros(plan.annualPrice);
    plan.monthlyPrice = removeZeros(plan.monthlyPrice);

    return plan;
  }

  render() {
    if (this.props.updated)
      this.props.history.push({
        pathname: `../list`,
        state: {successMessage: 'Plan successfully updated.'}
      });

    const item = this.props.retrieved;

    return (
      <div>
        <Link to={"../list"} className={"btn btn-primary"}>Back to Plan List</Link>
        <h3 className={"admin-plan-form-header"}>Edit Plan</h3>

        {(this.props.retrieveLoading ||
          this.props.updateLoading) && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.retrieveError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.retrieveError}
          </div>
        )}
        {this.props.updateError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.updateError}
          </div>
        )}

        {item && (
          <Form
            onSubmit={values => this.props.update(item, values)}
            initialValues={this.prepareInitialValues(item)}
            cancelButtonUrl={'../list'}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.plan.update.retrieved,
  retrieveError: state.plan.update.retrieveError,
  retrieveLoading: state.plan.update.retrieveLoading,
  updateError: state.plan.update.updateError,
  updateLoading: state.plan.update.updateLoading,
  eventSource: state.plan.update.eventSource,
  created: state.plan.create.created,
  updated: state.plan.update.updated
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  update: (item, values) => dispatch(update(item, values)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanUpdate);
