import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import {renderTextField} from "../../utils/form";
import {loadIndustriesForSelect} from "../../actions/Industry/forSelect";
import {connect} from "react-redux";
import SearchableSelect from "../../views/Components/SearchableSelect";

class OccupationForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  componentDidMount() {
    this.props.loadIndustriesForSelect();
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={renderTextField}
          name="name"
          type="text"
          placeholder="Name"
          required={true}
        />
        <Field
          component={SearchableSelect}
          name="industry"
          label="Industry"
          placeholder={this.props.industries ? 'Select Industry' : 'Loading...'}
          options={this.props.industries ? this.props.industries : []}
          required={true}
        />
        <button type="submit" className="btn btn-success float-left mr-3">
          Submit
        </button>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors['name'] = 'This field should not be blank.'
  }

  if (!values.industry) {
    errors['industry'] = 'This field should not be blank.'
  }

  return errors;
}

OccupationForm = reduxForm({
  form: 'occupation',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(OccupationForm);

const mapStateToProps = state => {
  let industries = state.industry.forSelect.retrieved;
  return { industries };
};

const mapDispatchToProps = dispatch => ({
  loadIndustriesForSelect: () => dispatch(loadIndustriesForSelect('@id')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OccupationForm);
