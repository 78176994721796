import React, {Component} from 'react';
import {connect} from "react-redux";
import {saveVideoAnswer} from "../../actions/OnDemandInterviewPage/answer";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button";
import GridContainer from "../Grid/GridContainer";
import SkipQuestionDialog from "./SkipQuestionDialog";
import VideoQuestion from "./VideoQuestion";
import classNames from 'classnames';
import VideoQuestionAnswer from "./VideoQuestionAnswer";
import {
  clearStorageForQuestion,
  getLastAudioAnswerForVideoQuestion,
  getLastSelectedAnswerTypeForVideoQuestion,
  getLastVideoAnswerForVideoQuestion,
  getRemainingThinkTimeForVideoQuestion,
  getRequestVideoQuestionByIndex,
  getStartThinkTimeForVideoQuestion,
  getVideoQuestionThinkTimeText, setLastSelectedAnswerTypeForVideoQuestion,
  setStartThinkTimeForVideoQuestion,
  thinkTimeIsEnabledForQuestion
} from "../../utils/onDemandInterview";
import {setThinkTimeStarted} from "../../actions/OnDemandInterviewPage/page";

class VideoQuestions extends Component {
  state = {
    answer: null,
    showSkipQuestionModal: false,
    questionState: VideoQuestion.defaultState,
    streamManager: null,
    audioDevices: {},
    videoDevices: {},
    answerType: null,
    lastSelectedAnswerType: null,
    videoStartedAt: null,
    videoEndedAt: null,
  }

  componentDidMount() {
    const {videoQuestion} = this.state;

    if (thinkTimeIsEnabledForQuestion(videoQuestion)) {
      /* can't use this.state.answer because it is null on did mount event even if there is saved answer */
      if (!getLastVideoAnswerForVideoQuestion(videoQuestion) && !getLastAudioAnswerForVideoQuestion(videoQuestion)) {
        const remainingThinkTime = getRemainingThinkTimeForVideoQuestion(videoQuestion);

        if (remainingThinkTime > 0) {
          const videoEndedAt = getStartThinkTimeForVideoQuestion(videoQuestion);
          const videoStartedAt = videoEndedAt - (videoQuestion.questionVideo.duration * 1000);

          this.setOnBeforeUpload();
          this.setState({
            answerType: getLastSelectedAnswerTypeForVideoQuestion(videoQuestion),
            videoStartedAt: new Date(videoStartedAt),
            videoEndedAt: new Date(videoEndedAt),
          });
        } else if (remainingThinkTime === 0) {
          this.saveAnswer({thinkTimeNotAnswered: true});
        }
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState)
  {
    const {request, interview} = nextProps;
    if (!request || !interview) {
      return null;
    }

    const videoQuestionInInterview = getRequestVideoQuestionByIndex(request, interview.currentQuestion);
    const videoQuestionInComponent = prevState.videoQuestion;
    if (
      videoQuestionInInterview &&
      videoQuestionInComponent &&
      videoQuestionInInterview.id === videoQuestionInComponent.id
    ) {
      return null;
    }

    return {
      videoQuestion: videoQuestionInInterview,
      answer: null,
      answerType: null,
      questionState: VideoQuestion.defaultState,
      lastSelectedAnswerType: null,
      videoStartedAt: null,
      videoEndedAt: null,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.interview && this.props.interview &&
      prevProps.interview.currentQuestion !== this.props.interview.currentQuestion
    ) {
      this.onQuestionChange(
        this.getQuestionByIndex(prevProps.interview.currentQuestion),
        this.getQuestionByIndex(this.props.interview.currentQuestion)
      );

      this.clearOnBeforeUpload();
    }

    if (!prevState.answer && this.state.answer) {
      this.clearOnBeforeUpload();
    }
  }

  componentWillUnmount() {
    this.clearOnBeforeUpload();
  }

  setOnBeforeUpload = () => {
    this.props.setThinkTimeStarted(true);
    window.onbeforeunload = function(e) {
      return 'Do you want to leave this site? Changes you made may not been saved.';
    };
  }

  clearOnBeforeUpload = () => {
    this.props.setThinkTimeStarted(false);
    window.onbeforeunload = null;
  }

  onQuestionChange = (previousQuestion, currentQuestion) => {
    clearStorageForQuestion(previousQuestion);
  }

  getQuestionByIndex = (index) => {
    const {request} = this.props;
    return getRequestVideoQuestionByIndex(request, index);
  }

  saveAnswer = (answer, skipped = false) => {
    const {interview} = this.props;
    const question = this.state.videoQuestion;
    if (!question) {
      return false;
    }
    this.props.saveAnswer(answer, question, interview, skipped)
  }

  skipQuestion = () => {
    this.saveAnswer(null, true);
    this.setState({showSkipQuestionModal: false});
  }

  onVideoStarted = () => {
    const videoQuestion = this.state.videoQuestion;
    if (thinkTimeIsEnabledForQuestion(videoQuestion) && !this.state.videoStartedAt && !this.state.answer) {
      this.setState({videoStartedAt: new Date()});

      setStartThinkTimeForVideoQuestion(videoQuestion, true);
      this.setOnBeforeUpload();
    }
  };

  onVideoEnded = () => {
    const videoQuestion = this.state.videoQuestion;
    if (thinkTimeIsEnabledForQuestion(videoQuestion) && !this.state.videoEndedAt && !this.state.answer) {
      const state = {videoEndedAt: new Date()};
      if (!this.state.answerType) {
        state['answerType'] = getLastSelectedAnswerTypeForVideoQuestion(videoQuestion);
      }

      this.setState(state);
      setStartThinkTimeForVideoQuestion(videoQuestion);
    }
  };

  onAnswerTypeChange = (answerType) => {
    this.setState({answerType: answerType});

    if (answerType) {
      setLastSelectedAnswerTypeForVideoQuestion(this.state.videoQuestion, answerType);
    }
  }

  render() {
    const {videoQuestion, answer, showSkipQuestionModal, streamManager, audioDevices, videoDevices} = this.state;
    const {saving} = this.props;

    if (!videoQuestion) {
      return null
    }
    const thinkTimeIsEnabled = thinkTimeIsEnabledForQuestion(videoQuestion)
    const playIsDisabled = thinkTimeIsEnabled && (!this.state.answerType || (this.state.videoStartedAt && !this.state.videoEndedAt));
    const showThinkTimeText = thinkTimeIsEnabled && !this.state.videoStartedAt && !answer;
    const showQuestionTitle = !thinkTimeIsEnabled || !!this.state.videoStartedAt || !!answer;

    return (
      <>
        <GridContainer className={"on-demand-interview-page-video-questions-grid-container"}>
          <GridItem lg={6} md={6} sm={12} xs={12}
                    className={classNames("question-box", {
                      "question-box-video-hidden": !this.state.questionState.showVideo
                    })}
          >
            <VideoQuestion
              key={videoQuestion.id}
              videoQuestion={videoQuestion}
              onQuestionStateChange={(newState) => this.setState({questionState: newState})}
              playIsDisabled={playIsDisabled}
              thinkTimeText={showThinkTimeText ? getVideoQuestionThinkTimeText(videoQuestion) : null}
              showQuestionTitle={showQuestionTitle}
              onVideoStarted={this.onVideoStarted}
              onVideoEnded={this.onVideoEnded}
              playsInline={true}
            />
          </GridItem>
          <GridItem lg={6} md={6} sm={12} xs={12} className="answer-box">
            <VideoQuestionAnswer
              key={videoQuestion.id}
              videoQuestion={videoQuestion}
              createStreamManager={this.createStreamManager}
              streamManager={streamManager}
              audioDevices={audioDevices}
              videoDevices={videoDevices}
              allowToChangeDevice={!thinkTimeIsEnabled || !this.state.videoStartedAt}
              startRecordingDisabled={thinkTimeIsEnabled && !this.state.videoStartedAt}
              onAnswer={(answer) => this.setState({answer: answer})}
              answerType={this.state.answerType}
              onAnswerTypeChange={this.onAnswerTypeChange}
              videoEndedAt={this.state.videoEndedAt}
              answer={this.state.answer}
            />
          </GridItem>
        </GridContainer>
        <div className={"on-demand-interview-page-video-questions-buttons-container"}>
            {!videoQuestion.required && (
              <Button
                color="primary"
                disabled={saving}
                onClick={() => this.setState({showSkipQuestionModal: true})}>
                  Skip
              </Button>
            )}
            <Button
              color="success"
              disabled={saving || !answer || (typeof answer === 'string' && !answer.trim())}
              onClick={() => this.saveAnswer(this.state.answer)}
            >
              {saving ? 'Saving...' : 'Submit'}
            </Button>
            {showSkipQuestionModal && (
              <SkipQuestionDialog
                onClose={() => this.setState({showSkipQuestionModal: false})}
                onSkip={this.skipQuestion}
              />
            )}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  request: state.onDemandInterviewPage.request,
  interview: state.onDemandInterviewPage.interview,
  saving: state.onDemandInterviewPage.savingAnswer,
});

const mapDispatchToProps = dispatch => ({
  saveAnswer: (answer, question, interview, skipped) => dispatch(saveVideoAnswer(answer, question, interview, skipped)),
  setThinkTimeStarted: (thinkTimeStarted) => dispatch(setThinkTimeStarted(thinkTimeStarted)),
});

VideoQuestions = connect(
  mapStateToProps, mapDispatchToProps
)(VideoQuestions);

export default VideoQuestions;
