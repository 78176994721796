import React, { Component } from 'react';
import {Field, FieldArray, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {renderAutocomplete, renderTextField} from "../../utils/form";
import {applyToUrl} from "../../utils/urls";
import MenuItem from "@material-ui/core/MenuItem";
import { list, reset } from "../../actions/CompanyCandidate/list";
import {connect} from "react-redux";
import OnDemandInvitationExpirationDatePicker from "../../views/Components/OnDemandInvitationExpirationDatePicker";
import {getDefaultExpiresAtForInvitations} from "../../utils/onDemandInterview";
import { isEmail } from 'validator';
import CustomizeEmailInvitationModal from "./CustomizeEmailInvitationModal";
import envelopeIcon from "../../assets/img/icons/mail-envelope-open.svg";
import trashIcon from "../../assets/img/icons/trash-remove.svg";
import SearchableSelect from "../../views/Components/SearchableSelect";

export const SALUTATION_CHOICES = [
  {value: 'Dr.', text: 'Dr.'},
  {value: 'Ms.', text: 'Ms.'},
  {value: 'Mrs.', text: 'Mrs.'},
  {value: 'Mr.', text: 'Mr.'},
  {value: 'Miss', text: 'Miss'},
];

class SendInvitationsForm extends Component {
  static timeoutId;
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  state = {
    submitDisabled: false,
    customizeEmailForInvitationIndex: null,
  };

  onSuggestionsFetchRequested = ({value}) => {
    clearTimeout(SendInvitationsForm.timeoutId);
    this.props.resetSuggestions();

    SendInvitationsForm.timeoutId = setTimeout(() => {
      let searchValue = value.trim();
      if (searchValue.length > 0) {
        this.props.searchCandidates(applyToUrl('company_candidates/select', {'firstName': searchValue}));
      }
    }, 100);
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    return (
      <MenuItem selected={isHighlighted} component="div" value={suggestion['@id']}>
        {suggestion.firstName} {suggestion.lastName} ({suggestion.email})
      </MenuItem>
    );
  };

  getSuggestionValue = (suggestion) => {
    return suggestion.firstName;
  };

  onSuggestionSelected = (index, event, suggestion) => {
    const fieldPrefix = `invitations[${index}]`;
    this.props.change(fieldPrefix + '[firstName]', suggestion.firstName);
    this.props.change(fieldPrefix + '[lastName]', suggestion.lastName);
    this.props.change(fieldPrefix + '[email]', suggestion.email);
    this.props.change(fieldPrefix + '[phoneNumber]', suggestion.phoneNumber);

    event.preventDefault();
  };

  addInvitation = (e, invitations) => {
    e.preventDefault();
    invitations.push({
      expiresAt: getDefaultExpiresAtForInvitations(this.props.request),
      _key: Math.random(),
    });

    this.setState({submitDisabled: false});
  }

  removeInvitation = (invitations, index) => {
    invitations.splice(index, 1);

    if ((invitations.length - 1) < 1) {
      this.setState({submitDisabled: true});
    }
  }

  setCustomEmail = (fieldName, value) => {
    value = typeof value === 'string' ? value.trim() : value;
    this.props.change(fieldName, value);
  }

  openCustomizeEmailModalForInvitation = (index, invitation) => {
    this.setState({customizeEmailForInvitationIndex: index});
  }

  renderInvitations = ({fields: invitations, meta: {error}}) => (
    <>
      <Table className="table table-striped mb-0">
        <TableHead>
          <TableRow>
            <TableCell>Salutation</TableCell>
            <TableCell>First Name <span className={'text-danger'}>*</span></TableCell>
            <TableCell>Last Name <span className={'text-danger'}>*</span></TableCell>
            <TableCell>Email <span className={'text-danger'}>*</span></TableCell>
            <TableCell>Phone</TableCell>
            <TableCell className={'text-center'}>Invite Expiration</TableCell>
            <TableCell className={'text-center'}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invitations.getAll().map((invitation, index) => {
            const fieldPrefix = `invitations[${index}]`;

            return (
              <TableRow key={invitation['_key']}>
                <TableCell width={'15%'}>
                  <Field
                    component={SearchableSelect}
                    name={fieldPrefix + '[salutation]'}
                    options={SALUTATION_CHOICES}
                    placeholder="Salutation"
                    label={null}
                  />
                </TableCell>
                <TableCell width={'15%'}>
                  <Field
                    component={renderAutocomplete}
                    name={fieldPrefix + '[firstName]'}
                    type="text"
                    placeholder="First Name"
                    label={null}
                    suggestions={this.props.retrieved ? this.props.retrieved['hydra:member'] : []}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    renderSuggestion={this.renderSuggestion}
                    getSuggestionValue={this.getSuggestionValue}
                    onSuggestionSelected={(event, {suggestion}) => this.onSuggestionSelected(index, event, suggestion)}
                    loading={this.props.loading}
                    theme={{suggestionsContainerOpen: 'on-demand-invitations-suggestions-list'}}
                  />
                </TableCell>
                <TableCell>
                  <Field
                    component={renderTextField}
                    name={fieldPrefix + '[lastName]'}
                    type="text"
                    placeholder="Last Name"
                    label={null}
                  />
                </TableCell>
                <TableCell>
                  <Field
                    component={renderTextField}
                    name={fieldPrefix + '[email]'}
                    type="text"
                    placeholder="Email"
                    label={null}
                  />
                </TableCell>
                <TableCell>
                  <Field
                    component={renderTextField}
                    name={fieldPrefix + '[phoneNumber]'}
                    type="text"
                    placeholder="Phone"
                    label={null}
                  />
                </TableCell>
                <TableCell className={'on-demand-interview-expires-at-calendar-container'}>
                  <Field
                    component={OnDemandInvitationExpirationDatePicker}
                    name={fieldPrefix + '[expiresAt]'}
                    label={null}
                    maxDate={this.props.request.expiresAt}
                  />
                </TableCell>
                <TableCell className={'text-center text-middle on-demand-invitation-icons'}>
                  <img
                    src={envelopeIcon}
                    alt={"Customize Invitation Email"}
                    title={"Customize Invitation Email"}
                    onClick={() => this.openCustomizeEmailModalForInvitation(index, invitation)}
                    height={18}
                  />
                  <img
                    src={trashIcon}
                    alt={"Remove"}
                    className={"invitation-remove"}
                    onClick={() => this.removeInvitation(invitations, index)}
                    height={18}
                  />
                </TableCell>

                <CustomizeEmailInvitationModal
                  invitationEmailDefaultContent={this.props.invitationEmailDefaultContent}
                  open={index === this.state.customizeEmailForInvitationIndex}
                  fieldName={`invitations[${index}][customEmail]`}
                  invitation={invitation}
                  handleClose={() => this.setState({customizeEmailForInvitationIndex: null})}
                  setCustomEmail={this.setCustomEmail}
                />
              </TableRow>
            );
          })}

        </TableBody>
      </Table>

      <a href={'#'} onClick={(e) => this.addInvitation(e, invitations)} className={'add-invitation'}>+ Add Invitation</a>
    </>
  );

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <FieldArray
          name={"invitations"}
          component={this.renderInvitations}
          index={this.state.customizeEmailForInvitationIndex} /* unused property for render update */
        />
        <div className={'clearfix'} />

        <button type="submit" className="btn btn-success" disabled={this.state.submitDisabled}>
          Submit
        </button>
        <Link to={`../../requests/show/${encodeURIComponent(this.props.request['@id'])}`} className="btn btn-primary ml-2">Back to Interview</Link>
        <Link to={`../`} className="btn btn-info ml-2">Back to List</Link>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (values.invitations && values.invitations.length > 0) {
    let invitationsErrors = [];
    values.invitations.forEach((invitation, index) => {
      let invitationErrors = {};
      if (!invitation.email) {
        invitationErrors.email = 'Please, enter candidate email';
      } else if (!isEmail(invitation.email)) {
        invitationErrors.email = 'Please, enter correct email';
      }

      if (!invitation.firstName || !invitation.firstName.trim()) {
        invitationErrors.firstName = 'Please, enter candidate first name';
      }
      if (!invitation.lastName || !invitation.lastName.trim()) {
        invitationErrors.lastName = 'Please, enter candidate last name';
      }
      if (Object.keys(invitationErrors).length > 0) {
        invitationsErrors[index] = invitationErrors;
      }
    });

    if (invitationsErrors.length > 0) {
      errors['invitations'] = invitationsErrors;
    }
  }

  return errors;
};

SendInvitationsForm = reduxForm({
  validate: validate,
  form: 'request_invite_candidate',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(SendInvitationsForm);

const mapStateToProps = state => {
  const {
    retrieved,
    loading,
    error,
  } = state.companyCandidate.list;

  return { retrieved, loading, error };
};

const mapDispatchToProps = dispatch => ({
  searchCandidates: page => dispatch(list(page)),
  resetSuggestions: () => dispatch(reset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendInvitationsForm);
