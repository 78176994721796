export let ENTRYPOINT;
if (window) {
  ENTRYPOINT = 'https://api.' + window.location.hostname.replace(/^www./, '');
} else {
  ENTRYPOINT = process.env.REACT_APP_API_ENTRYPOINT
}

export let VIDEO_ENTRYPOINT;

if (window) {
  VIDEO_ENTRYPOINT = 'https://video-api.' + window.location.hostname.replace(/^www./, '');

} else {
  VIDEO_ENTRYPOINT = process.env.REACT_APP_VIDEO_API_ENTRYPOINT;
}

export let MERCURE_ENTRYPOINT;

MERCURE_ENTRYPOINT = process.env.REACT_APP_MERCURE_ENTRYPOINT;
