import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { retrieve, reset } from '../../actions/Job/show';
import { del } from '../../actions/Job/delete';
import {archive} from "../../actions/Job/archive";
import {activate} from "../../actions/Job/activate";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {renderLinks} from "../../utils/entityList";
import IsGranted from "../IsGranted";
import Moment from 'react-moment';
import ProfessionMappingView from "../../views/Components/ProfessionMappingView";
import FlashMessages from "../../views/Components/FlashMessages";

class JobShow extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    deleted: PropTypes.object,
    del: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.loadJob();
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.activated && !prevProps.activated) {
      this.loadJob();
    }
  }

  del = () => {
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.del(this.props.retrieved);
  };

  archive = () => {
    if (window.confirm('Are you sure you want to archive this job?')) {
      this.props.archive(this.props.retrieved);
    }
  }

  activate = () => {
    if (window.confirm('Are you sure you want to activate this job?')) {
      this.props.activate(this.props.retrieved);
    }
  }

  loadJob = () => {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  render() {
    if (this.props.deleted)
      this.props.history.push({
        pathname: `..`,
        state: {successMessage: 'Job successfully deleted.'}
      });

    if (this.props.archived)
      this.props.history.push({
        pathname: `..`,
        state: {successMessage: 'Job successfully archived.'}
      });

    const item = this.props.retrieved;

    return (
      <div>
        <h1>Show &quot;{item && item['title']}&quot;</h1>
        <FlashMessages location={this.props.location} />
        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}
        {this.props.activated && (
          <div className="alert alert-success">
            Job activated.
          </div>
        )}

        <div className="grid-buttons">
          <Link to=".." className="btn btn-primary">
            Back to list
          </Link>
          {item && (
            <>
              <IsGranted action="edit" object={item}>
              <Link to={`../edit/${encodeURIComponent(item['@id'])}`} className="btn btn-warning">Edit</Link>
              </IsGranted>
              <IsGranted action="archive" object={item}>
                <button onClick={this.archive} className="btn btn-secondary">
                  Archive
                </button>
              </IsGranted>
              <IsGranted action="activate" object={item}>
                <button onClick={this.activate} className="btn btn-info">
                  Activate
                </button>
              </IsGranted>
              <IsGranted action="delete" object={item}>
              <button onClick={this.del} className="btn btn-danger">
                Delete
              </button>
              </IsGranted>
            </>
          )}
        </div>

        {item && (
          <div style={{'overflowX': 'auto'}}>
            <Table className="table table-responsive table-striped table-hover">
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell scope="row">Title</TableCell>
                  <TableCell>{item['title']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Status</TableCell>
                  <TableCell
                    className={item['status'] === 'active' ? 'text-success' : 'text-black-50'}
                    style={{textTransform: 'capitalize'}}
                  >{item['status']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Description</TableCell>
                  <TableCell className={"ql-editor"} dangerouslySetInnerHTML={{__html: item['description'] }}/>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Address</TableCell>
                  <TableCell>{item['address']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Address 2</TableCell>
                  <TableCell>{item['address2']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">City</TableCell>
                  <TableCell>{item['city']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">State</TableCell>
                  <TableCell>{item['state'] ? item['state']['name'] : '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Country</TableCell>
                  <TableCell>{item['country'] ? item['country']['name'] : '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">ZIP Code</TableCell>
                  <TableCell>{item['zipCode']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Minimum Salary</TableCell>
                  <TableCell>{item['minSalary']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Maximum Salary</TableCell>
                  <TableCell>{item['maxSalary']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Salary Type</TableCell>
                  <TableCell>{item['salaryType']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Display Salary</TableCell>
                  <TableCell>
                    {item['displaySalary'] ? "Yes" : "No" }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Employment Type</TableCell>
                  <TableCell>{item['employmentType']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Visa Sponsorship</TableCell>
                  <TableCell>
                    {item['visaSponsorship'] ? "Yes" : "No" }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Relocation</TableCell>
                  <TableCell>
                    {item['relocation'] ? "Yes" : "No" }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Team</TableCell>
                  <TableCell>{renderLinks('../teams', item['team'], undefined, true)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Hiring Managers</TableCell>
                  <TableCell>{(item['hiringManagers'] && item['hiringManagers'].length > 0) ?
                      item['hiringManagers'].map(manager => `${manager.firstName} ${manager.lastName}`).join(', ') : '-'
                  }</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Profession Mapping</TableCell>
                  <TableCell>
                    {item.professionMappings && item.professionMappings.length > 0 && item.professionMappings.map(
                      (mapping, index) => <ProfessionMappingView key={index} mapping={mapping}/>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Notes</TableCell>
                  <TableCell>{item['notes']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Created At</TableCell>
                  <TableCell>{item['createdAt'] && (<Moment>{item['createdAt']}</Moment>)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Updated At</TableCell>
                  <TableCell>{item['updatedAt'] && (<Moment>{item['updatedAt']}</Moment>)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        )}

      </div>
    );
  }

  renderLinks = (type, items) => {
    if (Array.isArray(items)) {
      return items.map((item, i) => (
        <div key={i}>{renderLinks(type, item)}</div>
      ));
    }

    return (
      <Link to={`../${type}/show/${encodeURIComponent(items)}`}>{items}</Link>
    );
  };
}

const mapStateToProps = state => ({
  retrieved: state.job.show.retrieved,
  error: state.job.show.error || state.job.del.error || state.job.archive.error || state.job.activate.error,
  loading: state.job.show.loading || state.job.del.loading || state.job.archive.loading || state.job.activate.loading,
  eventSource: state.job.show.eventSource,
  deleted: state.job.del.deleted,
  archived: state.job.archive.archived,
  activated: state.job.activate.activated,
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  del: item => dispatch(del(item)),
  archive: item => dispatch(archive(item)),
  activate: item => dispatch(activate(item)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobShow);
