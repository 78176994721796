import {
  fetch,
  extractHubURL,
} from '../../utils/dataAccess';
import {success as deleteSuccess} from "./delete"
import {filterAction, sortAction} from "../../utils/filterAndSortUtils";

export function error(error) {
  return { type: 'LIVEINTERVIEW_REVIEW_LIST_ERROR', error };
}

export function loading(loading) {
  return { type: 'LIVEINTERVIEW_REVIEW_LIST_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'LIVEINTERVIEW_REVIEW_LIST_SUCCESS', retrieved };
}

export function list(page = 'live_interview_reviews') {
  return dispatch => {
    dispatch(loading(true));
    dispatch(error(''));

    fetch(page)
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {

        dispatch(loading(false));
        dispatch(success(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();
    dispatch(deleteSuccess(null));

    dispatch({ type: 'LIVEINTERVIEW_REVIEW_LIST_RESET' });
  };
}

export function filter(filtersData) {
  return filterAction( 'LIVEINTERVIEW_REVIEW_LIST_FILTER', 'company_live_interview_review', filtersData)
}

export function sort(sortData) {
  return sortAction('LIVEINTERVIEW_REVIEW_LIST_SORT','company_live_interview_review', sortData)
}
