import React from 'react';
import Dialog from "../../../views/Components/Dialog/Dialog";
import PropTypes from 'prop-types';
import moment from "moment";

function AutoPayTurnOffModal({handleClose, handleAccept, subscription}) {
  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        title={"Turn off autopay"}
        dialogProperties={{subscriptionTheme: true}}
      >
        <div className={"subscription-turn-off-autopay-modal"}>
          <p>If you turn off autopay, your subscription will expire on {moment(subscription['endPeriod']).format("MM/DD/YYYY")}. Are you sure you want to turn off autopay?</p>
          <button onClick={handleAccept} className={"subscription-modal-button-green"}>Yes</button>
          <button onClick={handleClose} className={"subscription-modal-button-red"}>No</button>
        </div>
      </Dialog>
    </div>
  );
}

AutoPayTurnOffModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired,
  subscription: PropTypes.object.isRequired,
};

export default AutoPayTurnOffModal;
