const YOUTUBE_EMBED_URL_PREFIX = 'https://www.youtube.com/embed/';

export function isValidYoutubeUrl(url) {
  /* https://irishdotnet.dev/how-to-validate-a-youtube-url-using-javascript */
  let regExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

  return typeof url === 'string' && null !== url.match(regExp);
}

export function getYoutubeEmbedUrl(url) {
  if (isValidYoutubeUrl(url)) {
    /* https://stackoverflow.com/a/28735569 */
    let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    let matchGroups = url.match(regExp);

    return YOUTUBE_EMBED_URL_PREFIX + matchGroups[2];
  }

  return null;
}
