import React from "react";
import LiveInterviewPage from "../../components/LiveInterviewPage/LiveInterviewPage";
import {initializeByRoomCode} from "../../actions/LiveInterviewPage/interview";
import {currentUserRoles} from "../../utils/auth";
import {connect} from "react-redux";

class LiveInterviewByCodePage extends React.Component {
  componentDidMount() {
    const roomCode = this.props.match.params.roomid;
    this.props.initializeByRoomCode(roomCode);
  }

  render() {
    return (
      <LiveInterviewPage />
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  initializeByRoomCode: (roomCode) => dispatch(initializeByRoomCode(roomCode, currentUserRoles())),
});

LiveInterviewByCodePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveInterviewByCodePage);

export default LiveInterviewByCodePage;
