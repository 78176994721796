import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import {Route, Switch} from "react-router-dom";
import DashboardNotFound from "views/Pages/DashboardNotFound.jsx";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import getRoutes from "routes.js";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";

import {currentUserHasRole, currentUserRoles, isAuthenticated} from "../utils/auth";
import {resolveDashboardUrl} from "../utils/dashboardUrlResolver";
import {history} from './../store';
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import storage from "../utils/storage";
import Pages from "./Auth";
import {getActiveRoute} from "../utils/routes";

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      image: null,
      color: "blue",
      bgColor: "white",
      hasImage: true,
      fixedClasses: "dropdown"
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }
  checkDashboardPermissions() {
    const path = this.props.match.path;
    const roles = currentUserRoles();
    if (path === '/super-admin' && currentUserHasRole('ROLE_SUPER_ADMIN')) {
        return;
    }
    if (path === '/partner-admin' && currentUserHasRole('ROLE_PARTNER_ADMIN')) {
        return;
    }
    if (path === '/company' && (
        currentUserHasRole('ROLE_COMPANY_USER') ||
        currentUserHasRole('ROLE_COMPANY_ADMIN') ||
        currentUserHasRole('ROLE_COMPANY_HIRING_MANAGER') ||
        currentUserHasRole('ROLE_COMPANY_EMPLOYEE')
    )) {
        return;
    }
    if (path === '/candidate'&& currentUserHasRole('ROLE_CANDIDATE')) {
        return;
    }

    if (0 === roles.length && ['/super-admin', '/partner-admin', '/company', '/candidate'].indexOf(path) > -1) {
        storage.set('afterLoginUrl', window.location.pathname);
        if (isAuthenticated()) {
            history.push('/logout');
        } else {
            history.push('/login');
        }

        return;
    }

    history.push(resolveDashboardUrl(roles));
  }

  getAvailableRoutes = (routes) => {
      let userRoles = currentUserRoles();
      return routes.reduce((availableRoutes, route) => {
        let routeAvailable = !Array.isArray(route.roles) || route.roles.filter(role => userRoles.includes(role)).length > 0;

        if (routeAvailable && Array.isArray(route.views) && route.views.length > 0) {
           let cloneRoute = Object.assign({}, route);
           cloneRoute.views = this.getAvailableRoutes(route.views);
           availableRoutes.push(cloneRoute);
        } else if (routeAvailable) {
          availableRoutes.push(route);
        }

        return availableRoutes;
      }, []);
  };

  componentDidMount() {
    this.checkDashboardPermissions();
    Pages.shouldRedirectToTermsOfServiceAcceptance(this.props);

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", this.resizeFunction);
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.history.location.pathname !== prevProps.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }

    if (this.props.location.pathname !== prevProps.location.pathname && ['/company/home', '/candidate/dashboard'].indexOf(this.props.location.pathname) === -1) {
      Pages.shouldRedirectToTermsOfServiceAcceptance(this.props);
    }
  }
  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleBgColorClick = bgColor => {
    this.setState({ bgColor: bgColor });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/admin/full-screen-maps";
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (
        prop.layout === "/super-admin" ||
        prop.layout === "/partner-admin" ||
        prop.layout === "/company" ||
        prop.layout === "/candidate"
      ) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      } else {
        return null;
      }
    });
  };
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  render() {
    const { classes, site, ...rest } = this.props;

    const availableRoutes = this.getAvailableRoutes(getRoutes(site));
    const activeRoute = getActiveRoute(availableRoutes, this.props.location);

    const mainPanel =
      classes.mainPanel + ' dashboard-main-panel' +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      }) +
      " " +
      (activeRoute && cx({
          [activeRoute.mainPanelClasses]: activeRoute.mainPanelClasses,
      }));

    const logo = (site.logo) ? site.logo : null;

    return (
      <div className={classes.wrapper}>
        <Helmet>
          <title>{activeRoute && activeRoute.name}</title>
        </Helmet>
        <Sidebar
          routes={availableRoutes}
          logo={logo}
          logoText={site.name}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          bgColor={this.state.bgColor}
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          {(activeRoute && !activeRoute.hideAdminNavbar) && (
            <AdminNavbar
              sidebarMinimize={this.sidebarMinimize.bind(this)}
              miniActive={this.state.miniActive}
              brandText={activeRoute.name}
              handleDrawerToggle={this.handleDrawerToggle}
              {...rest}
            />
          )}
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content + (activeRoute && activeRoute.hideAdminNavbar ? (' ' + classes.contentWithoutNavbar) : '')}>
              <div className={classes.container}>
                {this.props.site['defaultContentWarningMessage'] && currentUserHasRole("ROLE_PARTNER_ADMIN") && (
                  <div className={"alert alert-warning"}>
                      <i className="fa fa-exclamation-triangle" aria-hidden="true" /> {this.props.site['defaultContentWarningMessage']}
                  </div>
                )}
                <Switch>
                  {this.getRoutes(availableRoutes)}
                  <Route component={DashboardNotFound} />
                </Switch>
              </div>
            </div>
          ) : (
            <div className={classes.map}>
              <Switch>
                {this.getRoutes(availableRoutes)}
                <Route component={DashboardNotFound} />
              </Switch>
            </div>
          )}
          {this.getRoute() && this.props.site ? <Footer siteDetails={this.props.site} fluid /> : null}
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

Dashboard = withStyles(appStyle)(Dashboard);

const mapStateToProps = state => ({
  site: state.siteDetails.siteDetails.retrieved,
});

export default connect(
    mapStateToProps
)(Dashboard);
