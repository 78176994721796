import {DEFAULT_NAMES} from "../components/Review/DefaultCriteriaNames";

export function getReviewCriteria(values = {}) {
  let result = {};
  Object.keys(DEFAULT_NAMES).forEach(criteriaKey => {
    const currentValue = values[criteriaKey];
    if (currentValue !== undefined && currentValue.name !== undefined) {
      result[criteriaKey] = {name: currentValue.name, updatedAt: currentValue.updatedAt};
    } else {
      result[criteriaKey] = {name: DEFAULT_NAMES[criteriaKey]};
    }
  })

  return result;
}
