import React, { Component } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import CustomButton from "../CustomButtons/Button";

const AXIS_DAY = 'axis_day';
const AXIS_WEEK = 'axis_week';
const AXIS_MONTH = 'axis_month';
const AXIS_QUARTER = 'axis_quarter';
const AXIS_YEAR = 'axis_year';

export class CustomChart extends Component
{
  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  maxValue = 0;

  state = {
    disabledLegends: [],
    expanded: false,
    axisValue: AXIS_MONTH,
  };

  onLegendClick = (e) => {
    let disabledLegends = this.state.disabledLegends;
    if (disabledLegends.includes(e.dataKey)) {
      const index = disabledLegends.indexOf(e.dataKey);
      disabledLegends.splice(index, 1);
    } else {
      disabledLegends.push(e.dataKey);
    }

    this.setState({disabledLegends: disabledLegends});
  };

  toggleExpand = () => {
    this.setState({expanded: !this.state.expanded}, () => {
      document.querySelector('.dashboard-main-panel').scroll(0, this.ref.current.offsetTop - 85);
    });
  }

  onAxisClick = (value) => {
    this.setState({axisValue: value});
  }

  groupData = (data) => {
    const groupedData = [];
    this.maxValue = 0;

    for (let i in data) {
      if (data.hasOwnProperty(i)) {
        const dateArray = data[i]['name'].split('/');
        const day = dateArray[0];
        const month = dateArray[1];
        const year = dateArray[2];
        let newName = null;

        switch (this.state.axisValue) {
          case AXIS_DAY:
            newName = `${month}/${day}/${year}`;
            break;
          case AXIS_WEEK:
            let a = new Date();
            a.setFullYear(parseInt(year));
            a.setMonth(parseInt(month) - 1);
            a.setDate(parseInt(day));

            const subtractMilliseconds = (a.getDay() - 1) * 86400000;
            a.setUTCMilliseconds(a.getUTCMilliseconds() - subtractMilliseconds);

            let newDate = a.getDate() < 10 ? '0' + a.getDate() : a.getDate();
            let newMonth = a.getMonth() + 1;
            newMonth = newMonth < 10 ? '0' + newMonth : newMonth;

            newName = newMonth + '/' + newDate + '/' + a.getFullYear();

            break;
          case AXIS_MONTH:
            newName = month + '/' + year;
            break;
          case AXIS_QUARTER:
            let quarter = Math.floor(month / 4) + 1;
            if (month < 4)
              quarter = 'I';
            else if (month < 7)
              quarter = 'II';
            else if (month < 10)
              quarter = 'III';
            else
              quarter = 'IV';

            newName = quarter + '/' + year;
            break;
          case AXIS_YEAR:
            newName = year;
            break;
          default:
            return data;
        }

        if (!(newName in groupedData)) {
          groupedData[newName] = {name: newName}
        }

        for (let label in data[i]) {
          if (data[i].hasOwnProperty(label) && 'name' !== label) {
            let value = parseFloat(data[i][label]);

            if (!groupedData[newName].hasOwnProperty(label)) {
              groupedData[newName][label] = value;
            } else {
              groupedData[newName][label] += value;
            }
          }
        }
      }
    }


    for (let dateLabel in groupedData) {
      /* calculate average value*/
      if (this.props.averageCalculationLabel) {
        let sum = 0;
        for (let label in groupedData[dateLabel]) {
          if (groupedData[dateLabel].hasOwnProperty(label) && !['_quantity', 'name'].includes(label)) {
            sum = parseFloat(groupedData[dateLabel][label]);
          }
        }

        let calculatedValue = this.props.disableRounding ?
          sum / groupedData[dateLabel]['_quantity'] :
          Math.round(sum / groupedData[dateLabel]['_quantity'])

        groupedData[dateLabel][this.props.averageCalculationLabel] = calculatedValue;

        delete groupedData[dateLabel]['_quantity'];
      } else {
        /* add 0 where's no data */
        for (let lineKey in this.props.lines) {
          if (this.props.lines.hasOwnProperty(lineKey)) {
            const missedLabel = this.props.lines[lineKey]['title'];

            if (!groupedData[dateLabel].hasOwnProperty(missedLabel)) {
              groupedData[dateLabel][missedLabel] = 0;
            }
          }
        }
      }

      /* find max value */
      for (let label in groupedData[dateLabel]) {
        if ('name' !== label &&
          groupedData[dateLabel].hasOwnProperty(label) &&
          groupedData[dateLabel][label] > this.maxValue &&
          !this.state.disabledLegends.includes(label)
        ) {
          this.maxValue = groupedData[dateLabel][label];
        }
      }
    }

    return Object.values(groupedData);
  }

  render() {
    let data = this.props.data ? JSON.parse(JSON.stringify(this.props.data)) : null;
    if (data) {
      data = this.groupData(data);
    }


    /* remove data if line(legend) is disabled */
    for (const i in data) {
      if (data.hasOwnProperty(i)) {
        this.state.disabledLegends.map(legend => {
          delete data[i][legend];
        })
      }
    }

    /* render customized lines */
    const Lines = [];
    this.props.lines.map(line => {
      Lines.push(
        <Line
          key={line['title']}
          dataKey={line['title']}
          stroke={line['color']}
          isAnimationActive={false}
          type="monotone"
          strokeWidth={3}
        />
      );
    });

    /* render customized clickable legends */
    const renderLegend = (props) => {
      const quantity = props.payload.length;

      return (
        <div className={"recharts-custom-legends" + (quantity === 1 ? ' full-width ' : '')}>
          {
            props.payload.map((entry, index) => (
              <span
                style={{color: entry['color']}}
                key={`item-${index}`}
                onClick={() => this.onLegendClick(entry)}
                className={this.state.disabledLegends.includes(entry['dataKey']) ? 'disabled' : null}
              >
                <i className={this.state.disabledLegends.includes(entry['dataKey']) ? "fa fa-circle-o" : "fa fa-dot-circle-o"} aria-hidden="true" /> {entry.value}
              </span>
            ))
          }
        </div>
      );
    }

    /* calculate height and width based on parent box size and if chart is expanded */
    let width = null;
    let minBoxSize = 1030;
    if (this.props.boxWidth) {
      if (!this.state.expanded && this.props.boxWidth > minBoxSize) {
        width = (this.props.boxWidth / 2) - 27;
      } else {
        width = this.props.boxWidth - 27;
      }
    }

    let height = 350;
    if (this.state.expanded && this.props.boxWidth > minBoxSize) {
      height = window.innerHeight - 185;
    }

    const titleBellow = this.props.boxWidth < 640 || (this.props.boxWidth > minBoxSize && this.props.boxWidth < (minBoxSize + 225));
    const horizontalLinesQuantity = this.maxValue < 8 ? (Math.floor(this.maxValue) + 1) : 8;

    return (
      <div className={'chart-container' + (titleBellow ? ' title-bellow' : '')} style={{width: width + 15}} ref={this.ref}>
        <h4 className={'text-center'}>{this.props.title}</h4>
        <div className={'chart-top-x-axis'}>
          <span className={this.state.axisValue === AXIS_DAY ? 'active' : null} onClick={() => this.onAxisClick(AXIS_DAY)}>Day</span> |&nbsp;
          <span className={this.state.axisValue === AXIS_WEEK ? 'active' : null} onClick={() => this.onAxisClick(AXIS_WEEK)}>Week</span> |&nbsp;
          <span className={this.state.axisValue === AXIS_MONTH ? 'active' : null} onClick={() => this.onAxisClick(AXIS_MONTH)}>Month</span> |&nbsp;
          <span className={this.state.axisValue === AXIS_QUARTER ? 'active' : null} onClick={() => this.onAxisClick(AXIS_QUARTER)}>Quarter</span> |&nbsp;
          <span className={this.state.axisValue === AXIS_YEAR ? 'active' : null} onClick={() => this.onAxisClick(AXIS_YEAR)}>Year</span>
        </div>
        {this.props.boxWidth > minBoxSize && (
          <div className={'chart-top-buttons'}>
            {/*<CustomButton size={'sm'} color="primary">*/}
            {/*  Export*/}
            {/*</CustomButton>*/}
            <CustomButton
              size={'sm'}
              color="white"
              className={'expand-chart'}
              title={this.state.expanded ? 'Collapse' : 'Expand'}
              onClick={this.toggleExpand}
            >
              <i className={this.state.expanded ? 'fa fa-compress' : 'fa fa-expand'} aria-hidden="true" />
            </CustomButton>
          </div>
        )}

        <LineChart
          width={width}
          height={height}
          data={data}
          margin={{
            top: 15,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="0 0 0" />
          <XAxis tickLine={false} tickSize={10} tick={{fontSize: 14}} dataKey="name"  />
          <YAxis tickLine={false} tickSize={10} tick={{fontSize: 14}} axisLine={false} tickCount={horizontalLinesQuantity} />
          <Tooltip formatter={(value, name, props) => {
            if (typeof value === 'number' && !Number.isInteger(value)) {
              value = value.toFixed(1);
            }

            return value;
          }} />
          {Lines}
          <Legend
            onClick={this.onLegendClick}
            wrapperStyle={{bottom: -25}}
            content={renderLegend}
          />
        </LineChart>
      </div>
    );
  }
}
