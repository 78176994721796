import {SubmissionError} from "redux-form";
import {fetch, normalizeAttributes, normalizeExcept} from "../../utils/dataAccess";
import { cloneDeep } from "lodash";

export function retrieve(normalizeProfessionalMappings = true) {
    return dispatch => {
        dispatch(loading(true));

        return fetch('/users/current')
            .then(response => response.data)
            .then((retrieved) => {
                retrieved = normalizeExcept(retrieved, ['professionMappings', 'resume']);
                if (normalizeProfessionalMappings) {
                  if (retrieved.professionMappings && retrieved.professionMappings.length > 0) {
                    retrieved.professionMappings.forEach((item) => {
                      normalizeAttributes(item, ['industry', 'occupation', 'specialty']);
                    });
                  }
                }

                dispatch(loading(false));
                dispatch(retrieveSuccess(retrieved));
            })
            .catch(e => {
                dispatch(loading(false));
                dispatch(error(e.message));
            });
    };
}

export function update(values, successCallback) {
    return dispatch => {
        dispatch(error(null));
        dispatch(updateSuccess(null));
        dispatch(resetAuth());
        dispatch(loading(true));
        const data = normalizeAttributes(cloneDeep(values), ['resume']);

        return fetch('/users/current', {
            method: 'PUT',
            data: data,
        })
        .then(response => response.data)
        .then((updated) => {
            dispatch(loading(false));
            if (updated['@auth']) {
                dispatch(authSuccess(updated['@auth']));
                delete updated['@auth'];
            } else {
                dispatch(resetAuth());
            }
            dispatch(updateSuccess(updated));

            if (typeof successCallback === 'function') {
              successCallback()
            }
        })
        .catch(e => {
            dispatch(loading(false));

            if (e instanceof SubmissionError) {
                dispatch(error(e.errors._error));
                throw e;
            }

            dispatch(error(e.message));
        });
    };
}

export function reset() {
    return dispatch => {
        dispatch({ type: 'USER_PROFILE_UPDATE_RESET' });
    };
}

function retrieveSuccess(retrieved) {
    return { type: 'USER_PROFILE_UPDATE_RETRIEVE_SUCCESS', retrieved };
}

function updateSuccess(updated) {
    return { type: 'USER_PROFILE_UPDATE_SUCCESS', updated };
}

function authSuccess(auth) {
    return { type: 'USER_PROFILE_UPDATE_AUTH_SUCCESS', auth };
}

function resetAuth() {
    return { type: 'USER_PROFILE_UPDATE_AUTH_RESET'};
}

function error(error) {
    return { type: 'USER_PROFILE_UPDATE_ERROR', error };
}

function loading(loading) {
    return { type: 'USER_PROFILE_UPDATE_LOADING', loading };
}
