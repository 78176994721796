import React, {Component} from 'react';
import {renderTextField} from "../../utils/form";
import {Field, reduxForm} from "redux-form";
import {Link} from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
import FormControl from "@material-ui/core/FormControl";
import LockOutlined from "@material-ui/icons/LockOutlined";
import withStyles from "@material-ui/core/styles/withStyles";

const style = {
  formControl: {
    marginBottom: '1em',
  },
  forgotPasswordLink: {
    marginBottom: '1em',
  },
  loginButton: {
    display: 'block',
    minWidth: '80%',
    margin: '0 auto',
  }
}

class InvitationLoginForm extends Component {
  render() {
    const {classes, loading} = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <FormControl fullWidth={true} className={classes.formControl}>
          <Field
            component={renderTextField}
            name="email"
            type="email"
            placeholder="Email"
            required={true}
            label={false}
            InputProps={{startAdornment: (<InputAdornment position="start"><Email /></InputAdornment>)}}
          />
        </FormControl>
        <FormControl fullWidth={true}>
          <Field
            component={renderTextField}
            name="password"
            type="password"
            placeholder="Password"
            required={true}
            label={false}
            InputProps={{startAdornment: (<InputAdornment position="start"><LockOutlined /></InputAdornment>),}}
          />
        </FormControl>
        <div className={classes.forgotPasswordLink}>
          <Link to={`/password-recovery`}>Forgot Password</Link>
        </div>
        <button type="submit" className={"btn btn-info " + classes.loginButton} disabled={loading}>
          {!loading ? ('Login') : ('Logging In...')}
        </button>
      </form>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Please enter an email';
  }
  if (!values.password) {
    errors.password = 'Please enter a password';
  }

  return errors;
};

InvitationLoginForm = reduxForm({
  form: 'invitation_login_form',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(InvitationLoginForm);

InvitationLoginForm = withStyles(style)(InvitationLoginForm);

export default InvitationLoginForm;
