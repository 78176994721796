import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {loadSiteDetails} from "actions/SiteDetails/loadSiteDetails";
import SiteDetailsLoadingPage from "./views/Pages/SiteDetailsLoadingPage";
import {history} from "./store";
import AdminLayout from "./layouts/Admin";
import AuthLayout from "./layouts/Auth";
import LiveInterviewLayout from "./layouts/LiveInterviewLayout";
import OnDemandInterviewLayout from "./layouts/OnDemandInterviewLayout";
import IframeLayout from "./layouts/Iframe";
import {Route, Switch} from 'react-router-dom';
import {ConnectedRouter,} from 'connected-react-router';
import {Snackbar} from "react-redux-snackbar";
import SiteDetailsLoadingErrorPage from "./views/Pages/SiteDetailsLoadingErrorPage";
import {Helmet} from "react-helmet";
import PageFavicons from "./components/Icons/PageFavicons";
import ScreenSize from "./components/ScreenSize";

class App extends React.Component {
  static propTypes = {
    loadSiteDetails: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.loadSiteDetails();
  }

  componentDidUpdate(prevProps) {
    const site = this.props.site;
    if (!prevProps.site && site) {
      if (site.headerHtml && site.headerHtml.trim().length > 0) {
        this.addCustomHeaderHtml(site.headerHtml);
      }
    }
  }

  addCustomHeaderHtml = (html) => {
    //We can't use Helmet for raw html data, also react-html-parser lib doesn't support script tags
    //so we have to use direct dom manipulations
    const divNode = document.createElement("div");
    divNode.innerHTML = html;
    const childNodes = divNode.childNodes;
    for (let i = 0; i < childNodes.length; i++) {
      const childNode = childNodes[i];
      const headTag = document.createElement(childNode.tagName);

      const attributes = childNode.attributes;
      if (attributes) {
        for (let j = 0; j < attributes.length; j++) {
          const attribute = childNode.attributes[j];
          headTag.setAttribute(attribute.name, attribute.value);
        }
      }

      headTag.innerHTML = childNode.innerHTML;
      document.head.appendChild(headTag);
    }
  };

  render() {
    if (this.props.siteLoading)
      return <SiteDetailsLoadingPage />;

    if (this.props.siteLoadingError)
      return <SiteDetailsLoadingErrorPage error={this.props.siteLoadingError} />;

    const site = this.props.site;
    return site && (
      <>
        <Helmet
          defaultTitle={site.name}
          titleTemplate={`${site.name} - %s`}
        />
        <PageFavicons site={site} />
        <ScreenSize />
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/super-admin" component={AdminLayout} />
            <Route path="/partner-admin" component={AdminLayout} />
            <Route path="/company" component={AdminLayout} />
            <Route path="/candidate" component={AdminLayout} />
            <Route path="/live-interview" component={LiveInterviewLayout} />
            <Route path="/on-demand-interview" component={OnDemandInterviewLayout} />
            <Route path="/embed" component={IframeLayout} />
            <Route path="/share" component={IframeLayout} />
            <Route path="" component={AuthLayout} />
            <Route render={() => <h1>Not Found</h1>}/>
          </Switch>
        </ConnectedRouter>
        <Snackbar />
      </>
    )
  }
}

const mapStateToProps = state => ({
  site: state.siteDetails.siteDetails.retrieved,
  siteLoading: state.siteDetails.siteDetails.loading,
  siteLoadingError: state.siteDetails.siteDetails.error,
});

const mapDispatchToProps = dispatch => ({
  loadSiteDetails: () => dispatch(loadSiteDetails()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
