import React, {Component} from 'react';
import TimeElapsedDisplay from "../TimeElapsedDisplay/TimeElapsedDisplay";
import PropTypes from "prop-types";
import {
  getQuestionAnswerTime,
  isQuestionHasRecordAttemptLimit,
  isQuestionHasRecordTimeLimit, thinkTimeIsEnabledForQuestion
} from "../../utils/onDemandInterview";
import classNames from 'classnames';

class DesktopRecorderLimitations extends Component {
  static propTypes = {
    question: PropTypes.object.isRequired,
    stopRecording: PropTypes.func.isRequired,
    recordingStartTime: PropTypes.object,
    recordingAttempts: PropTypes.number,
  };

  renderRecordingTime = () => {
    const {question, recordingStartTime, stopRecording} = this.props;
    const hasTimeLimitation = isQuestionHasRecordTimeLimit(question);
    const thinkTimeIsEnabled = thinkTimeIsEnabledForQuestion(question)

    if (hasTimeLimitation && (!thinkTimeIsEnabled || recordingStartTime)) {
      return (
        <>
          <strong>Time Limit (min:sec):</strong>&nbsp;{recordingStartTime ? (
          <TimeElapsedDisplay
            startTime={recordingStartTime}
            running={true}
            containerClass={''}
            withHours={false}
            onCountedDown={stopRecording}
            isNegative={true}
            indicator={null}
            recordingText={null}
          />
        ) : (
          <span>{getQuestionAnswerTime(question)}</span>
        ) }
        </>
      )
    } else if (recordingStartTime) {
      return (
        <TimeElapsedDisplay
          startTime={recordingStartTime}
          running={true}
          onCountedDown={stopRecording}
          withHours={!hasTimeLimitation}
          isNegative={hasTimeLimitation}
          indicator={null}
          recordingText={null}
          containerClass={'answer-desktop-time-elapsed-display'}
        />
      );
    } else {
      return null;
    }
  }

  renderRecordingAttempts = () => {
    const {question, recordingAttempts} = this.props;
    if (isQuestionHasRecordAttemptLimit(question)) {
      return (
        <>
          <strong>Recording Attempts: </strong>{recordingAttempts}/{question.maxAnswerRecordingRetries}
        </>
      );
    } else {
      return null;
    }
  }

  render() {
    const {question} = this.props;
    return (
      <div className={classNames('answer-recorder-desktop-info-block', {
        'answer-recorder-desktop-info-block-with-time-limitation': isQuestionHasRecordTimeLimit(question),
        'answer-recorder-desktop-info-block-with-attempt-limitation': isQuestionHasRecordAttemptLimit(question),
      })}>
        <div className={'answer-recorder-recording-time'}>
          {this.renderRecordingTime()}
        </div>
        <div className={'answer-recorder-recording-attempts'}>
          {this.renderRecordingAttempts()}
        </div>
      </div>
    )
  }
}

export default DesktopRecorderLimitations;
