import React, {Component} from "react";
import {FieldArray} from "redux-form";
import GridItem from "../Grid/GridItem";
import KnockoutQuestion from "./KnockoutQuestion";
import Button from "../CustomButtons/Button";
import KnockoutQuestionBankSearch from "./KnockoutQuestionBankSearch";
import Clear from '@material-ui/icons/Clear';
import LibraryAdd from '@material-ui/icons/LibraryAdd';
import QuestionNumber from "./QuestionNumber";
import './RequestKnockoutQuestion.scss';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const getDroppableQuestionsStyle = () => ({
    position: "relative"
});

const getDraggedDom = (draggableId) => {
    const queryAttr = "data-rbd-drag-handle-draggable-id";
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
};

const isEmpty = (obj) => {
    for (let key in obj) {
        return false;
    }

    return true;
};

export default class RequestKnockoutQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            placeholderProps: {}
        };
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragUpdate = this.onDragUpdate.bind(this);
    }

    static defaultProps = {
        questionsFieldName: "knockoutQuestions",
    };

    addQuestion = (questions) => {
        return () => {
            questions.push({knockoutQuestionChoices: [{}, {}], type: 'single', required: true});
        }
    };

    removeQuestion = (questions, index) => {
        return () => {
            questions.remove(index)
        }
    };

    onDragStart = (e) => {
        const draggedDOM = getDraggedDom(e.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = e.source.index;
        let clientY = parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) + [...draggedDOM.parentNode.children]
                .slice(0, sourceIndex)
                .reduce((total, curr) => {
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    return total + curr.clientHeight + marginBottom;
                }, 0);

        const placeholderProps = {
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            )
        }

        this.setState({
            placeholderProps
        });
    };

    onDragUpdate = (e) => {
        if (!e.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(e.draggableId);

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = e.destination.index;
        const sourceIndex = e.source.index;

        const childrenArray = [...draggedDOM.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1)
        ];

        let clientY = parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) + updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr.clientHeight + marginBottom;
            }, 0);

        const placeholderProps = {
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(
                window.getComputedStyle(draggedDOM.parentNode).paddingLeft
            )
        };

        this.setState({
            placeholderProps
        });
    };

    onDragEnd = (result) => {
        this.setState({placeholderProps : {}});
        if (!result.destination || result.source.index === result.destination.index) {
            return;
        }

        this.props.swapQuestions(result.source.index, result.destination.index);
    };

    renderKnockoutQuestionsDraggable = ({
        fields: questions,
        meta: {error},
        provided: provided,
        snapshot: snapshot}) => (
            <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getDroppableQuestionsStyle()}
            >
                {questions.map((fieldName, index) => {
                    const question = this.props.knockoutQuestions[index];
                    if (!question) {
                        return null;
                    }
                    return (
                        <Draggable
                            draggableId={'ko-question-' + index}
                            index={index}
                            key={'ko-question-' + index}
                        >
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                >
                                    <GridItem xs={12} key={'ko-question-' + index}>
                                        <KnockoutQuestion
                                            name={fieldName}
                                            currentValue={question}
                                            prefix={(<QuestionNumber>{index + 1}.</QuestionNumber>)}
                                            isDroppable={(questions.length > 1) ? true : false}
                                            onExpand={this.props.onExpand}
                                            index={index}
                                        >
                                            <Button size="sm" color="danger" onClick={this.removeQuestion(questions, index)}>
                                                <Clear>Remove Question</Clear>
                                            </Button>
                                        </KnockoutQuestion>
                                    </GridItem>
                                </div>
                            )}
                        </Draggable>
                    );
                })}

                {provided.placeholder}
                {!isEmpty(this.state.placeholderProps) && snapshot.isDraggingOver && (
                    <div className="ghost-placeholder"
                        style={{
                            top: this.state.placeholderProps.clientY,
                            left: this.state.placeholderProps.clientX,
                            height: this.state.placeholderProps.clientHeight,
                            width: this.state.placeholderProps.clientWidth
                        }}
                    />
                )}

                <GridItem xs={12} style={{ marginTop: "20px" }}>
                    <div className={"add-screening-question"}>
                        {error && <div className="error">{error}</div>}
                        <Button color="success" onClick={this.addQuestion(questions)}>
                            <LibraryAdd />
                            Add New Question
                        </Button>
                    </div>
                </GridItem>
            </div>
    );

    renderKnockoutQuestions = ({
        fields: questions,
        meta: { error }}) => (
            <>
                {
                    questions.map((fieldName, index) => {
                        const question = this.props.knockoutQuestions[index];
                        if (!question) {
                            return null;
                        }
                        return (
                            <GridItem xs={12} key={'ko-question-' + index}>
                                <KnockoutQuestion
                                    name={fieldName}
                                    currentValue={question}
                                    prefix={(<QuestionNumber>{index + 1}.</QuestionNumber>)}
                                    isDroppable={(questions.length > 1) ? true : false}
                                    onExpand={this.props.onExpand}
                                    index={index}
                                >
                                    <Button size="sm" color="danger" onClick={this.removeQuestion(questions, index)}>
                                        <Clear>Remove Question</Clear>
                                    </Button>
                                </KnockoutQuestion>
                            </GridItem>
                        );
                    })
                }

                <GridItem xs={12} style={{ marginTop: "20px" }}>
                    <div className={"add-screening-question"}>
                        {error && <div className="error">{error}</div>}
                        <Button color="success" onClick={this.addQuestion(questions)}>
                            <LibraryAdd />
                            Add New Question
                        </Button>
                    </div>
                </GridItem>
            </>
    );

    render() {
       return (
           <div className='request-knockout-questions'>
               <div className='question-bank-search column'>
                   <KnockoutQuestionBankSearch onQuestionAdd={this.props.addQuestionFromBank} />
               </div>
               <div className='knockout-questions column'>
                   <h2>Screening Questions</h2>
                   {(this.props.knockoutQuestions.length > 1) && (
                       <div className="tips">
                           Tips: You can rearrange questions orders by drag-n-drop them
                       </div>
                   )}
                   {this.props.knockoutQuestions.length > 1 ? (
                       <DragDropContext
                           onDragEnd={this.onDragEnd}
                           onDragStart={this.onDragStart}
                           onDragUpdate={this.onDragUpdate}
                       >
                           <Droppable droppableId="ko-questions-droppable">
                               {(provided, snapshot) => (
                                   <FieldArray name={this.props.questionsFieldName}
                                       component={this.renderKnockoutQuestionsDraggable}
                                       provided={provided}
                                       snapshot={snapshot}
                                   />
                               )}
                           </Droppable>
                       </DragDropContext>
                   ) : (
                           <FieldArray name={this.props.questionsFieldName}
                               component={this.renderKnockoutQuestions}
                           />
                       )}
               </div>
           </div>
        );
    }
}
