import {
  infoColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const companyHomePageStyle = theme => ({
  textCenter: {
    textAlign: 'center'
  },
  image: {
    maxWidth: '100%',
    marginTop: '10px'
  },
  imageLink: {
    display: 'block',
    textAlign: 'center'
  },
  link: {
    textAlign: 'center',
    display: 'block',
    paddingBottom: '10px'
  },
  header: {
    margin: '0 0 20px',
    textAlign: 'center'
  },
  avatar: {
    width: '100px',
    height: '100px',
    backgroundColor: infoColor[0],
    color: '#ffffff',
    fontSize: '25px',
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      height: 'auto',
      borderRadius: '3px',
      fontSize: '18px'
    }
  },
  justufyCenter: {
    justify: 'center'
  }
});

export default companyHomePageStyle;
