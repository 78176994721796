import { SubmissionError } from 'redux-form';
import {
  fetch,
  normalize,
} from '../../utils/dataAccess';

export function error(error) {
  return { type: 'COMPANY_CANDIDATE_TAGS_UPDATE_ERROR', error };
}

export function loading(loading) {
  return { type: 'COMPANY_CANDIDATE_TAGS_UPDATE_LOADING', loading };
}

export function updateSuccess(updated) {
  return { type: 'COMPANY_CANDIDATE_TAGS_UPDATE_SUCCESS', updated };
}

export function updateTags(item, values) {
  return dispatch => {
    dispatch(error(null));
    dispatch(loading(true));

    return fetch(item['@id'], {
      method: 'PUT',
      data: values
    })
      .then(response => response.data)
      .then(retrieved => {
        retrieved = normalize(retrieved);
        dispatch(loading(false));
        dispatch(updateSuccess(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));
        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw e;
        }
        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return { type: 'COMPANY_CANDIDATE_TAGS_UPDATE_RESET' };
}
