import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Form from './Form';
import { retrieve, update, reset } from '../../actions/Country/update';
import { del } from '../../actions/Country/delete';
import IsGranted from "../IsGranted";

class CountryUpdate extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    retrieveLoading: PropTypes.bool.isRequired,
    retrieveError: PropTypes.string,
    updateLoading: PropTypes.bool.isRequired,
    updateError: PropTypes.string,
    deleteLoading: PropTypes.bool.isRequired,
    deleteError: PropTypes.string,
    updated: PropTypes.object,
    deleted: PropTypes.object,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    del: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  del = () => {
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.del(this.props.retrieved);
  };

  render() {
    if (this.props.deleted)
      this.props.history.push({
        pathname: `..`,
        state: {successMessage: 'Country successfully deleted.'}
      });

    if (this.props.updated)
      this.props.history.push({
        pathname: `../show/${encodeURIComponent(this.props.updated['@id'])}`,
        state: {successMessage: 'Country successfully updated.'}
      });


    const item = this.props.retrieved;

    return (
      <div>
        <h1>Edit &quot;{item && item['name']}&quot;</h1>

        {(this.props.retrieveLoading ||
          this.props.updateLoading ||
          this.props.deleteLoading) && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.retrieveError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.retrieveError}
          </div>
        )}
        {this.props.updateError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.updateError}
          </div>
        )}
        {this.props.deleteError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.deleteError}
          </div>
        )}

        {item && (
          <Form
            onSubmit={values => this.props.update(item, values)}
            initialValues={item}
          />
        )}
        <Link to=".." className="btn btn-primary float-left mr-3">
          Back to list
        </Link>
        {item && (
          <IsGranted action="delete" object={item}>
            <button onClick={this.del} className="btn btn-danger float-left mr-3">
              Delete
            </button>
          </IsGranted>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.country.update.retrieved,
  retrieveError: state.country.update.retrieveError,
  retrieveLoading: state.country.update.retrieveLoading,
  updateError: state.country.update.updateError,
  updateLoading: state.country.update.updateLoading,
  deleteError: state.country.del.error,
  deleteLoading: state.country.del.loading,
  eventSource: state.country.update.eventSource,
  created: state.country.create.created,
  deleted: state.country.del.deleted,
  updated: state.country.update.updated
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  update: (item, values) => dispatch(update(item, values)),
  del: item => dispatch(del(item)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountryUpdate);
