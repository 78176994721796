import 'assets/scss/main.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import Moment from 'react-moment';
import App from "./App";
import axios from "axios";

import {store} from './store';
import {applyAxiosAuth} from "./utils/auth";
import {applyAxiosRedirectListener} from "./utils/axiosRedirect";
import {applySiteDetailsVersionListener} from "./utils/axiosSiteDetailsVersion";
import storage from "./utils/storage";
import AppErrorBoundary from "./views/Components/AppErrorBoundary";

applyAxiosAuth(axios);
applyAxiosRedirectListener(axios);
applySiteDetailsVersionListener(axios, store);

Moment.globalFormat = storage.get('site') ? storage.get('site').dateFormat : 'MMM D YYYY';
Moment.timeOnlyFormat = 'h:mm a';
Moment.timeFormat = Moment.globalFormat + ' ' + Moment.timeOnlyFormat;

ReactDOM.render(
  <Provider store={store}>
    <AppErrorBoundary>
      <App />
    </AppErrorBoundary>
  </Provider>,
  document.getElementById('root')
);
