import { combineReducers } from 'redux';

export function updateError(siteSettings = null, action) {
  switch (action.type) {
    case 'SITE_SETTINGS_UPDATE_ERROR':
      return action.updateError;

    case 'SITE_SETTINGS_UPDATE_RESET':
      return null;

    default:
      return siteSettings;
  }
}

export function updateLoading(siteSettings = false, action) {
  switch (action.type) {
    case 'SITE_SETTINGS_UPDATE_LOADING':
      return action.updateLoading;

    case 'SITE_SETTINGS_UPDATE_RESET':
      return false;

    default:
      return siteSettings;
  }
}

export function updated(siteSettings = null, action) {
  switch (action.type) {
    case 'SITE_SETTINGS_UPDATE_SUCCESS':
      return action.updated;

    case 'SITE_SETTINGS_UPDATE_RESET':
      return null;

    default:
      return siteSettings;
  }
}

export default combineReducers({
  updateError,
  updateLoading,
  updated,
});
