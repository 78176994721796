import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { list, reset, filter, sort } from '../../actions/CompanyUser/list';
import Moment from 'react-moment';
import {handleListUpdate, pagination, renderLinks} from "../../utils/entityList";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {handleSwitchUser} from "../SwitchUser/SwitchUser";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import filtersStorage from '../../utils/filtersStorage';
import FilterForm from "./FilterForm";
import {SortableTableCells} from "../../utils/filterForm";
import IsGranted from "../IsGranted";
import TableFooter from "@material-ui/core/TableFooter";
import {getRoutePage} from "../../utils/routes";
import Switch from '@material-ui/core/Switch';
import {activate, reset as activateReset} from "../../actions/CompanyUser/activate";
import {deactivate, reset as deactivateReset} from "../../actions/CompanyUser/deactivate";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import ActivateDeactivateUserDialog from "./ActivateDeactivateUserDialog";
import FlashMessages from "../../views/Components/FlashMessages";
import PhoneNumber from "../../views/Components/PhoneNumber";

class CompanyUserList extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    filter: PropTypes.func.isRequired,
    filtersData: PropTypes.object,
    sort: PropTypes.func.isRequired,
    sortData: PropTypes.object,
  };

  state = {
    changeStatusUserModal: null,
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const role = urlParams.get('role');
    if (role) {
      this.props.filter({role: role});
    }

    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    handleListUpdate('company_users', this.props, prevProps);
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
    this.props.activateReset();
    this.props.deactivateReset();
  }

  updateUserStatus = (companyUser) => {
    let changeStatusCallback = () => {
      this.fetchData();
    }
    companyUser['status'] = companyUser['status'] === 'active' ? 'inactive' : 'active';
    this.toggleModal();

    this.props.activateReset();
    this.props.deactivateReset();

    companyUser['status'] === 'active' ?
      this.props.activate(companyUser, changeStatusCallback) :
      this.props.deactivate(companyUser, changeStatusCallback);
  }

  fetchData = () => {
    const storageName = 'company_user';
    const filters = filtersStorage.get(storageName, null);
    const sort = filtersStorage.getSort(storageName);
    if (filters !== null || sort !== null) {
      this.props.filter(filters);
      this.props.sort(sort);
    } else {
      handleListUpdate('company_users', this.props);
    }
  }

  toggleModal = (user = null) => {
    this.setState({changeStatusUserModal: user});
  }

  render() {
    const isActive = this.state.changeStatusUserModal && this.state.changeStatusUserModal['status'] === 'active';
    return (
      <div>
        <FlashMessages location={this.props.location} />
        {this.props.activated && (
          <div className="alert alert-success" role="status">
            {this.props.activated['firstName']} {this.props.activated['lastName']} has been activated.
          </div>
        )}
        {this.props.deactivated && (
          <div className="alert alert-success" role="status">
            {this.props.deactivated['firstName']} {this.props.deactivated['lastName']} has been deactivated.
          </div>
        )}
        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}

        <IsGranted action="create" object={this.props.retrieved}>
        <p>
          <Link to="create" className="btn btn-primary">
            Create
          </Link>
        </p>
        </IsGranted>

        <FilterForm
          initialValues={this.props.filtersData}
          onSubmit={this.props.filter}
          resetFilter={this.props.filterReset}
        />

        <ActivateDeactivateUserDialog
          open={this.state.changeStatusUserModal !== null}
          onClose={() => this.toggleModal()}
          onConfirm={() => this.updateUserStatus(this.state.changeStatusUserModal)}
          activate={!isActive}
        />

        <div style={{'overflowX': 'auto'}}>
        <Table className="table table-responsive table-striped table-hover">
          <TableHead>
            <TableRow>
              <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                'id': 'ID',
                'firstName': 'First Name',
                'lastName': 'Last Name',
                'email': 'Email',
                'phoneNumber': 'Phone Number',
                'status': 'Status',
                'role': 'Role',
                'createdAt': 'Created At',
                'updatedAt': 'Updated At',
              }} />
              <TableCell colSpan="4" />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.retrieved &&
              this.props.retrieved['hydra:member'].map(item => (
                <TableRow key={item['@id']}>
                  <TableCell scope="row" className="align-middle">
                    {renderLinks('users', item, (companyUser) => companyUser['id'], true)}
                  </TableCell>
                  <TableCell className="align-middle">
                    {renderLinks('users', item, (companyUser) => companyUser['firstName'], true)}
                  </TableCell>
                  <TableCell className="align-middle">
                    {renderLinks('users', item, (companyUser) => companyUser['lastName'], true)}
                  </TableCell>
                  <TableCell className="align-middle">{item['email']}</TableCell>
                  <TableCell className="align-middle">
                    <PhoneNumber phone={item['phoneNumber']} formattedPhone={item['formattedPhoneNumber']} />
                  </TableCell>
                  <TableCell className="align-middle">{item['status']}</TableCell>
                  <TableCell className="align-middle">{item['mainRole']}</TableCell>
                  <TableCell className="align-middle">{item['createdAt'] && (<Moment>{item['createdAt']}</Moment>)}</TableCell>
                  <TableCell className="align-middle">{item['updatedAt'] && (<Moment>{item['updatedAt']}</Moment>)}</TableCell>
                  <TableCell className="align-middle text-center">
                    <IsGranted action="show" object={item}>
                    <Link to={`show/${encodeURIComponent(item['@id'])}`}>
                      <span className="fa fa-eye" aria-hidden="true" />
                      <span className="sr-only">Show</span>
                    </Link>
                    </IsGranted>
                  </TableCell>
                  <TableCell className="align-middle">
                    <IsGranted action="edit" object={item}>
                    <Link to={`edit/${encodeURIComponent(item['@id'])}`}>
                      <span className="fa fa-pencil" aria-hidden="true" />
                      <span className="sr-only">Edit</span>
                    </Link>
                    </IsGranted>
                  </TableCell>
                  <TableCell className="align-middle">
                    <IsGranted action="impersonate" object={item}>
                      <a href='javascript:'>
                          <SupervisorAccountIcon onClick={() => handleSwitchUser(item['id'])} />
                      </a>
                    </IsGranted>
                  </TableCell>
                  <TableCell className="align-middle">
                    <Tooltip
                      title={item['status'] === 'active' ? 'Deactivate' : 'Activate'}
                      placement={"left"}
                      disableTouchListener={true}
                      disableFocusListener={true}
                      classes={{ tooltip: this.props.classes.tooltip }}
                    >
                      <Switch
                        checked={item['status'] === 'active'}
                        onClick={() => this.toggleModal(item)}
                        color="primary"
                        disabled={this.props.activateLoading || this.props.loading || !isAllowedToChangeStatus(item)}
                        classes={item['status'] === 'active' && !this.props.activateLoading && !this.props.loading && isAllowedToChangeStatus(item) ?
                          {bar: 'switch-icon-bar', checked: 'switch-icon-checked'} :
                          {}
                        }
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        </div>

        <Table className="table table-responsive no-border">
          <TableFooter>
            <TableRow>
              {pagination(this.props.retrieved, getRoutePage(this.props.match))}
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const retrieved = state.companyUser.list.retrieved;
  const eventSource = state.companyUser.list.eventSource;
  const filtersData = state.companyUser.list.filtersData;
  const sortData = state.companyUser.list.sortData;
  const loading = state.companyUser.list.loading || state.companyUser.activate.activateLoading || state.companyUser.deactivate.deactivateLoading;
  const error = state.companyUser.list.error || state.companyUser.activate.activateError || state.companyUser.deactivate.deactivateError;

  const activated = state.companyUser.activate.activated;
  const deactivated = state.companyUser.deactivate.deactivated;

  return { retrieved, loading, error, eventSource, filtersData, sortData, activated, deactivated };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource)),
  filter: (filtersData) => dispatch(filter(filtersData)),
  filterReset: () => dispatch(filter(null)),
  sort: (sortData) => dispatch(sort(sortData)),
  activate: (companyUser, callback) => dispatch(activate(companyUser, callback)),
  deactivate: (companyUser, callback) => dispatch(deactivate(companyUser, callback)),
  activateReset: () => dispatch(activateReset()),
  deactivateReset: () => dispatch(deactivateReset()),
});

export function isAllowedToChangeStatus(item) {
  return item['@permissions'].indexOf('activate_company_user') > -1 || item['@permissions'].indexOf('deactivate_company_user') > -1;
}

CompanyUserList = withStyles(dashboardStyle)(CompanyUserList);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyUserList);
