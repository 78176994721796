import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import {renderTextField} from "../../utils/form";
import {currentUserHasRole} from "../../utils/auth";
import {loadTeamsForSelect} from "../../actions/Team/forSelect";
import {connect} from "react-redux";
import SearchableSelect from "../../views/Components/SearchableSelect";

class QuestionBankCategoryForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  componentWillMount() {
    if (currentUserHasRole('ROLE_COMPANY_USER')) {
      const teamFilters = {};
      const initialValues = this.props.initialValues;
      if (initialValues && initialValues.team) {
        teamFilters.withId = initialValues.team;
      }
      this.props.loadTeamsForSelect(teamFilters);
    }
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={renderTextField}
          name="name"
          type="text"
          placeholder="Name"
          required={true}
        />
        {this.props.teams && (
        <Field
          component={SearchableSelect}
          name="team"
          label="Team"
          placeholder="Team"
          options={this.props.teams}
        />
        )}
        <button type="submit" className="btn btn-success float-left mr-3">
          Submit
        </button>
      </form>
    );
  }
}

const mapStateToProps = state => {
    let teams = state.team.forSelect.retrieved;
    return { teams };
};

const mapDispatchToProps = dispatch => ({
    loadTeamsForSelect: (filters) => dispatch(loadTeamsForSelect('@id', filters)),
});

QuestionBankCategoryForm = reduxForm({
  form: 'questionbankcategory',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(QuestionBankCategoryForm);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuestionBankCategoryForm);
