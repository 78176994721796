import React from "react";
import {Field} from "redux-form";
import {renderCheckbox, renderTextField} from "../../utils/form";
import './KnockoutQuestionChoiceField.scss';

export default class KnockoutQuestionChoiceField extends React.Component {
    render() {
        return (
            <div className="knockout-question-choice-field">
                <div className="choice-statement">
                    <Field
                        name={`${this.props.name}.content`}
                        component={renderTextField}
                        label=""
                        placeholder='Choice'
                        fullWidth
                    />
                </div>
                <div className="choice-actions">
                    <div className="correct-box">
                        <Field
                            name={`${this.props.name}.correct`}
                            label="Correct"
                            component={renderCheckbox}
                        />
                    </div>
                </div>
                <div className="choice-children">
                    {this.props.children}
                </div>
            </div>
        );

    }
}
