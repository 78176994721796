import SvgIcon from "@material-ui/core/SvgIcon";
import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
    root: {
        width: '26.999px',
        height: '27.011px',
        marginRight: '0',
        transition: 'fill 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94)',
        fill: '#3399FF',
        '&:hover': {
            fill: '#3399FF',
        },
    },
};

class SendMessageChat extends Component {

    render() {
        return (
            <SvgIcon {...this.props}>
                <defs>
                    <path id="a" opacity="1" fillRule="evenodd" d="M4.447 21.656s-.552 2.179 1.328 1.327C9.888 21.119 21.437 14.46 22.137 14c.37-.249.793-.42.786-.815-.007-.394-.14-.523-1.034-1-.793-.38-12.228-6.38-16.174-8.096-2.18-.947-1.556 1.013-1.556 1.013l.903 6.687 14.09 1.43-14.032 1.92-.673 6.517z"/>
                </defs>
                <use xlinkHref="#a" transform="rotate(-44 13.5 13.506)"/>
            </SvgIcon>
        );
    }
}

export default withStyles(styles)(SendMessageChat);
