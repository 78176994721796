import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {fetchJSON} from "../../utils/dataAccess";
import axios from "axios";
import {jobLocation} from "../../utils/jobLocation";

export default class JobBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: !this.props.job,
            job: this.props.job,
        };
        if (this.props.company && this.state.job) {
            this.state.job.company = this.props.company;
        }
        this.signal = axios.CancelToken.source();
    }

    componentDidMount() {
        if (this.props.jobIRI !== undefined) {
            this.fetchData();
        } else if (this.state.job) {
            this.props.onLoad !== undefined && this.props.onLoad();
        } else {
            throw new Error('No job or job IRI supplied for job bar');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.jobIRI !== undefined && this.props.jobIRI !== prevProps.jobIRI) {
            this.fetchData();
        }
    }

    componentWillUnmount() {
        this.signal.cancel('unmount JobBar');
    }

    fetchData() {
        fetchJSON(this.props.jobIRI, {
            cancelToken: this.signal.token,
          }).then((job) => {
              if (!(job instanceof Error)) {
                this.setState({ job: job });

                this.setState({ loading: false }, () => {
                    this.props.onLoad !== undefined && this.props.onLoad();
                });
            }
        });
    }

    render() {
        if (this.state.loading || !this.state.job) return '';
        return (
            <GridContainer className="jobbar">
                <GridItem xs={4} style={{marginBottom: '20px'}}>
                    <Card style={{height: '100%', margin: 0}}>
                        <CardBody style={{textAlign: "center"}}>
                            Job<br/>
                            <b>{this.state.job.title}</b>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={4} style={{marginBottom: '20px'}}>
                    <Card style={{height: '100%', margin: 0}}>
                        <CardBody style={{textAlign: "center"}}>
                            Company<br/>
                            <b>{this.state.job.company.name}</b>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={4} style={{marginBottom: '20px'}}>
                    <Card style={{height: '100%', margin: 0}}>
                        <CardBody style={{textAlign: "center"}}>
                            Job Location<br/>
                            <b>{jobLocation(this.state.job)}</b>
                        </CardBody>
                    </Card>
                </GridItem>
                {this.props.withDescription && (
                    <GridItem xs={12}>
                        <Card style={{'marginTop': 0}}>
                            <CardBody>
                                <p className={"ql-editor"} dangerouslySetInnerHTML={{__html: this.state.job.description}}/>
                            </CardBody>
                        </Card>
                    </GridItem>
                )}
            </GridContainer>);
    }
}
