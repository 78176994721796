import React from "react";
import InterviewAccessForm from "./InterviewAccessForm";
import {loadCompanyUsers, reset} from "../../actions/Access/companyUsers";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {submit} from "redux-form";
import Dialog from "../../views/Components/Dialog/Dialog";
import OneWayInterviewShareLink from "../OneWayInterview/OneWayInterviewShareLink";
import LiveInterviewShareLink from "../LiveInterview/LiveInterviewShareLink";

const style = {
  dialogContent: {
    minHeight: '380px',
  }
};

class ShareInterviewDialog extends React.Component {
  componentDidMount() {
    this.props.loadCompanyUsers();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.success && !prevProps.success) {
      if (typeof this.props.onSuccess === 'function') {
        this.props.onSuccess();
      }
    }
  }

  renderDialogContent = () => {
    const {error, loading, handleSubmit, companyUsers, values, oneWayInterview, classes} = this.props;

    return (
      <>
        {error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" /> {error}
          </div>
        )}
        {loading && (
          <div className="alert alert-info" role="alert">Loading...</div>
        )}
        <InterviewAccessForm
          onSubmit={(values) => {
            const permissions = [];
            for (let key in values.permissions) {
              if (values.permissions.hasOwnProperty(key) && values.permissions[key]) {
                permissions.push(key);
              }
            }
            const result = Object.assign({}, values, {
              permissions: permissions
            });
            return handleSubmit(result);
          }}
          users={companyUsers || []}
          initialValues={Object.assign({
            permissions: {
              view_interview: true,
              view_candidate: true,
              view_job: true,
            }
          }, values)}
        />
      </>
    );
  }

  render() {
    const { loading, onClose, open, classes } = this.props;

    return (
      <div>
        <Dialog
          open={open}
          onClose={onClose}
          title={"Share Interview"}
          contentClass={classes.dialogContent}
          closeButtonText={'Cancel'}
          confirmButtonText={loading ? 'Sharing...' : 'Share'}
          confirmButtonDisabled={loading}
          onConfirm={this.props.submitForm}
        >
          {this.renderDialogContent()}
          {this.props.oneWayInterview && (
            <OneWayInterviewShareLink
              onClose={this.props.onClose}
              oneWayInterview={this.props.oneWayInterview}
            />
          )}
          {this.props.liveInterview && (
            <LiveInterviewShareLink
              onClose={this.props.onClose}
              liveInterview={this.props.liveInterview}
            />
          )}
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  companyUsers: state.access.companyUsers.retrieved,
  loading: state.onewayinterviewsharelink.list.loading,
});

const mapDispatchToProps = dispatch => ({
  loadCompanyUsers: () => dispatch(loadCompanyUsers()),
  reset: () => dispatch(reset()),
  submitForm: () => dispatch(submit('interview_access_form')),
});

ShareInterviewDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareInterviewDialog);

export default withStyles(style)(ShareInterviewDialog);
