import React, {Component} from 'react';
import {connect} from "react-redux";
import {startInterview} from "../../actions/OnDemandInterviewPage/interview";
import AudioVideoTest from "./AudioVideoTest";
import AudioVideoTestMobile from "./AudioVideoTestMobile";
import {createVideoPublisher} from "../../utils/onDemandInterview";
import {getOpenViduSingleton} from "../../utils/OpenViduInstance";
import {filterAudioDevices, filterVideoDevices} from "../../utils/webRtc";
import classNames from "classnames";
import Button from "../CustomButtons/Button";
import {closeStreamManager} from "../../utils/streamManager";

class OnDemandInterviewTestContent extends Component {
  state = {
    streamManager: null,
    audioDevices: {},
    videoDevices: {},
    testingStarted: false,
  };

  componentDidMount() {
    createVideoPublisher().then(publisher => {
      const OV = getOpenViduSingleton();
      OV.getDevices().then(devices => {
        this.setState({
          streamManager: publisher,
          audioDevices: filterAudioDevices(devices),
          videoDevices: filterVideoDevices(devices),
        });
      });
    }).catch(e => {
      console.log('Cant init publisher', e)
    });
  }

  componentWillUnmount() {
    const {streamManager} = this.state;
    closeStreamManager(streamManager);
  }

  onTestingStart = () => {
    this.setState({testingStarted: true});
  }

  onTestingEnd = () => {
    this.setState({testingStarted: false});
  }

  render() {
    const {starting, screenSize} = this.props;
    const {streamManager, audioDevices, videoDevices, testingStarted} = this.state;
    const smallScreen = !screenSize.md;
    return (
      <div className={classNames('on-demand-interview-page-test-content', {
        'on-demand-interview-page-test-content-testing-started': testingStarted,
      })}>
        {smallScreen ?
          <AudioVideoTestMobile
            streamManager={streamManager}
            audioDevices={audioDevices}
            videoDevices={videoDevices}
            onTestingStart={this.onTestingStart}
            onTestingEnd={this.onTestingEnd}
            testingStarted={testingStarted}
          /> :
          <AudioVideoTest
            streamManager={streamManager}
            audioDevices={audioDevices}
            videoDevices={videoDevices}
            onTestingStart={this.onTestingStart}
            onTestingEnd={this.onTestingEnd}
            testingStarted={testingStarted}
          />
        }
        <div className='on-demand-interview-page-test-actions'>
          <Button
            color={'success'}
            className='on-demand-interview-page-start-button'
            onClick={() => this.props.startInterview(this.props.interview)}
            disabled={starting}
          >
            {starting ? 'Starting...' : 'Start Interview'}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  interview: state.onDemandInterviewPage.interview,
  starting: state.onDemandInterviewPage.startingInterview,
  screenSize: state.screen.size,
});

const mapDispatchToProps = dispatch => ({
  startInterview: (interview) => dispatch(startInterview(interview)),
});

OnDemandInterviewTestContent = connect(
  mapStateToProps, mapDispatchToProps
)(OnDemandInterviewTestContent);

export default OnDemandInterviewTestContent;
