import moment from "moment";
import Moment from 'react-moment';
import urlParse from 'url-parse';
import qs from 'qs';

export function getRequestUrlByRoomCode(roomCode, absoluteUrl = true) {
  let location = window.location;
  const uri = '/on-demand-interview/' + encodeURIComponent(roomCode);
  return (absoluteUrl) ? (location.protocol + '//' + location.host + uri) : uri;
}

export function getInvitationUrlByCode(code, absoluteUrl = true) {
  let location = window.location;
  const uri = '/on-demand-interview/invitation/' + encodeURIComponent(code);
  return (absoluteUrl) ? (location.protocol + '//' + location.host + uri) : uri;
}

export function getLiveInterviewUrlByRoomCode(roomCode, absoluteUrl = true) {
  let location = window.location;
  const uri = '/live-interview/' + encodeURIComponent(roomCode);
  return (absoluteUrl) ? (location.protocol + '//' + location.host + uri) : uri;
}

export function getLiveInterviewInvitationUrlByCode(code, absoluteUrl = true) {
  let location = window.location;
  const uri = '/live-interview/invitation/' + encodeURIComponent(code);
  return (absoluteUrl) ? (location.protocol + '//' + location.host + uri) : uri;
}

export function getLiveInterviewCalendarUrl(liveInterviewId) {
  return '/live_interviews/' + liveInterviewId + '/calendar';
}

export function objectToUrlParams(object)
{
  let urlParams = '';

  for (let key in object) {
    if (object.hasOwnProperty(key)) {
      if (urlParams !== '') {
        urlParams += '&';
      }
      let value = object[key];

      if (value === undefined) {
        continue;
      }

      /* do not add this filter with value __all__, it uses to store empty values where uses default filters */
      if ('__all__' === value) {
        continue;
      }

      // filter with multiple values (4 underscore chars in name)
      if (key.indexOf('____') > -1) {
        let values = value;
        key = key.replace('____', '');

        for (let i = 0; i < values.length; i++) {
          if (urlParams !== '&') {
            urlParams += '&';
          }
          urlParams += key + "[]=" + encodeURIComponent(values[i]);
        }

        continue;
      }

      let parsedKey = key.replace(/(__after__)/g, '[after]'); /* for datetime filters */
      parsedKey = parsedKey.replace(/(__before__)/g, '[before]'); /* for datetime filters */
      parsedKey = parsedKey.replace(/__exists__/g, '[exists]'); /* for exists filters */
      parsedKey = parsedKey.replace(/__gte__/g, '[gte]'); /* greater or equal */
      parsedKey = parsedKey.replace(/__lte__/g, '[lte]'); /* lower or equal */
      parsedKey = parsedKey.replace(/(___)/g, '.'); /* for relational filters (3 underscore chars in name) */

      if (typeof value === 'object' && '_isAMomentObject' in value) {
        value = moment(value).format(Moment.globalFormat);
      }
      if (parsedKey.indexOf('[before]') > -1) {
        value += ' 23:59:59';
      }

      urlParams += parsedKey + "=" + encodeURIComponent(value);
    }
  }

  return urlParams;
}

export function applyToUrl (url, filters) {
  if (null === filters || 0 === Object.keys(filters).length) {
    return url;
  }

  if (url.indexOf('?') === -1) {
    url += '?';
  } else {
    url += '&';
  }
  url += objectToUrlParams(filters);

  return url;
}

export function removeUrlParams(url, exceptParams = []) {
  if (!url) {
    return null;
  }

  let params = {};
  let a = document.createElement('a'); /* convert to absolute url if it's relative */
  a.href = url;
  url = new URL(a.href);

  exceptParams.map(paramName => {
    let param = url.searchParams.get(paramName);
    if (null !== param) {
      params[paramName] = param;
    }
  });

  return applyToUrl(url.pathname, params);
}

export function getUrlQueryParams(url) {
  const urlParts = urlParse(url, {});
  return qs.parse(urlParts.query, {ignoreQueryPrefix: true});
}

export function getUrlQueryParam(url, param, defaultValue = null) {
  const queryParams =  getUrlQueryParams(url);
  return (queryParams[param] !== undefined) ? queryParams[param] : defaultValue;
}

export function changeUrlParam(url, param, newValue) {
  let queryParams = getUrlQueryParams(url);
  queryParams[param] = newValue;

  const urlParts = urlParse(url, {});
  urlParts.set('query', qs.stringify(queryParams, { encode: false }));
  return urlParts.toString();
}
