import {setQuestionAndChoicePositions} from "../actions/Request/util";
import {deleteAttributes} from "./dataAccess";
import { cloneDeep } from "lodash";

function prepareVideos(request) {
  if (request.questions) {
    request.questions = request.questions.map(question => {
      if (question.questionVideo && question.questionVideo['@id']) {
        question.questionVideo['videoOrigin'] = question.questionVideo['@id'];
      }
      deleteAttributes(question.questionVideo, ['@id', 'url']);
      return question;
    });
  }

  ['requestIntroVideo','requestOutroVideo'].forEach(fieldName => {
    const value = request[fieldName];
    if (value && value['@id']) {
      request[fieldName] = {'videoOrigin': value['@id']};
    }
  });
}

function cleanRequestTemplate(values) {
  deleteAttributes(values, [
    'id', '@type', '@context', '@id', '@permissions', 'submitType', 'expiresAt', 'companyUser', 'job', 'roomCode'
  ]);
  let recursiveRemoveAttributes = function(item, attributes) {
    deleteAttributes(item, attributes);
    for (let key in item) {
      if (!item.hasOwnProperty(key)) {
        continue;
      }

      if (Array.isArray(item[key])) {
        item[key] = item[key].map((element) => recursiveRemoveAttributes(element, attributes));
      } else if (item[key] instanceof Object) {
        item[key] = recursiveRemoveAttributes(item[key], attributes);
      }
    }

    return item;
  };
  recursiveRemoveAttributes(values, ['@type', '@id', '@context', 'id', 'createdAt', 'updatedAt', 'videoUrl', 'localUrl']);
  return values;
}

export function prepareRequestTemplateForSaving(values) {
  let template = cloneDeep(values);

  let request = template.request ?? {};
  setQuestionAndChoicePositions(request);
  prepareVideos(request);
  cleanRequestTemplate(request);

  return template;
}
