import {fetch} from 'utils/dataAccess';
import {getAuthToken, getRefreshToken, saveAuthData, removeAuthData} from 'utils/auth';
import {history} from './../store';
import storage from "./storage";
import filtersStorage from "./filtersStorage";

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

export default function refreshTokenAndReattemptRequest(error, axios) {
    try {
        const { response: errorResponse } = error;

        let url = errorResponse.config.url;
        if (typeof url === 'string') {
            url = new URL(url);
        }

        if (!getRefreshToken() ||
          url.pathname === '/login' ||
          url.pathname === '/login_check' ||
          url.pathname.startsWith('/one_way_interview_share_link/by_code/') ||
          url.pathname.startsWith('/live_interview_share_link/by_code/')
        ) {
            return Promise.reject(error);
        }

        if (url.pathname === '/token/refresh' && errorResponse.status >= 400) {
            //We should redirect user to page when he was after login
            storage.set('afterLoginUrl', window.location.pathname);
            return Promise.reject(error);
        }

        const retryOriginalRequest = new Promise(resolve => {
            addSubscriber(access_token => {
                errorResponse.config.headers.Authorization = 'Bearer ' + access_token;
                resolve(axios(errorResponse.config));
            });
        });

        if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            let switchedRoles = null;
            if (null !== storage.get('X-Switch-User')) {
              switchedRoles = storage.get('roles');
            }
            refreshToken().then(() => {
              isAlreadyFetchingAccessToken = false;
              onAccessTokenFetched(getAuthToken());
              if (null !== switchedRoles) {
                storage.set('roles', switchedRoles);
              }
            }).catch(e => {
              isAlreadyFetchingAccessToken = false;
              history.push('/login')
            });
        }
        return retryOriginalRequest;
    } catch (err) {
        return Promise.reject(err);
    }
}

function fetchFreshToken() {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    return new Promise.reject("You don't have refresh token");
  }

  return fetch('/token/refresh', {
    method: 'POST',
    data: {refreshToken: refreshToken}
  }).then(tokenRefreshResponse => tokenRefreshResponse.data);
}

export function refreshToken() {
  return fetchFreshToken()
  .then(tokenData => saveAuthData(tokenData))
  .catch((error) => {
    removeAuthData();
    filtersStorage.clear();
    throw new Error(error);
  });
}

function onAccessTokenFetched(access_token) {
    // When the refresh is successful, we start retrying the requests one by one and empty the queue
    subscribers.forEach(callback => callback(access_token));
    subscribers = [];
}

function addSubscriber(callback) {
    subscribers.push(callback);
}
