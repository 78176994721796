import React from "react";
import VideoElement from "./VideoElement";
import './LiveInterviewVideoGrid.scss';
import {connect} from "react-redux";
import classNames from 'classnames';
import {IconButton, withStyles} from "@material-ui/core";
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import LeftIcon from '@material-ui/icons/ChevronLeft';
import RightIcon from '@material-ui/icons/ChevronRight';
import RecordingIndicator from "../VideoRecorder/RecordingIndicator";
import {setFocusedParticipantId} from "../../actions/LiveInterviewPage/interview";

const style = {
  hideVideosButton: {
    color: 'white',
    background: 'rgba(111,111,111,.5)',
    zIndex: 100,
    padding: '6px',
    borderRadius: 0,
    height: 36,
    width: 36,
    "&:hover": {
      backgroundColor: 'rgba(111,111,111, .8)',
    }
  },
  recordingIndicatorWrapper: {
    position: 'absolute',
    top: '1.2rem',
    left: '1rem',
    zIndex: 100,
  }
}

class VideoGrid extends React.Component {
  static defaultProps = {
    participants: [],
  };

  state = {
    mainVideoIndex: null,
    hideFullscreenVideoList: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    //Update Main Video Index
    if (this.props.fullscreenEnabled && this.state.mainVideoIndex === null) {
      this.initMainVideoIndex();
      return;
    }

    if (
      this.state.mainVideoIndex !== null &&
      this.props.participants.length < (this.state.mainVideoIndex + 1)
    ) {
      this.initMainVideoIndex();
      return;
    }

    if (this.props.fullscreenEnabled && (
      prevProps.focusedParticipantId !== this.props.focusedParticipantId ||
      prevProps.participants.length !== this.props.participants.length
    )) {
      this.initMainVideoIndex();
      return;
    }
  }

  initMainVideoIndex = () => {
    this.setState({mainVideoIndex: this.defineMainVideoIndex(this.props.participants)})
  };

  defineMainVideoIndex = (participants) => {
    const { focusedParticipantId } = this.props;
    const speakingParticipant = this.props.participants.findIndex(participant => participant.speaking);
    if (focusedParticipantId !== null && participants.length > 0) {
      const focusedParticipantIndex = participants.findIndex(participant => participant.id === focusedParticipantId);
      if (focusedParticipantIndex !== -1) {
        return focusedParticipantIndex;
      }
    }

    if (speakingParticipant >= 0 && this.state.mainVideoIndex !== speakingParticipant) {
      return speakingParticipant;
    }

    if (participants.length > 1) {
      return 1;
    } else if (participants.length === 1) {
      return 0;
    } else {
      return null;
    }
  };

  getGridClasses = () => {
    const {interviewJoined, fullscreenEnabled, screenSize} = this.props;

    let classes = {
      'videos-container-joined': interviewJoined,
      'fullscreen-video-grid': fullscreenEnabled,
      'videos-container-hidden': fullscreenEnabled && this.state.hideFullscreenVideoList,
      'participants-overflow': this.shouldLeftRightArrowsBeShown(),
    };

    const numberOfColumns = this.getNumberOfColumns();
    if (!fullscreenEnabled && numberOfColumns > 0) {
      const columnsClass = 'video-grid-' + numberOfColumns + (numberOfColumns > 1 ? '-columns' : '-column');
      classes[columnsClass] = true;
    }

    return classes;
  };

  getNumberOfColumns = () => {
    const {participants, screenSize} = this.props;
    const smallScreen = !screenSize.md;
    const elementsCount = participants.length;

    if (elementsCount === 1 || (elementsCount === 2 && smallScreen)) {
      return 1;
    } else if (elementsCount > 0) {
      return Math.ceil(Math.sqrt(elementsCount));
    } else {
      return 0;
    }
  };

  getVideoElementClasses = (elementNumber) => {
    const elementsCount = this.props.participants.length;
    const {mainVideoIndex} = this.state;
    const columns = this.getNumberOfColumns();

    const classes =  {
      'main-video-element': mainVideoIndex === elementNumber,
      'participants-list-hidden': mainVideoIndex === elementNumber && this.state.hideFullscreenVideoList,
    }

    if (elementsCount > 2) {
      const elementToShift = Math.floor(elementsCount/columns) * columns;
      const needToShift = elementToShift <= elementsCount;
      if (needToShift && elementNumber === elementToShift) {
        const shiftNumber = (columns*columns - elementsCount) % columns + 1;
        classes['element-shift-' + shiftNumber] = true;
      }
    }

    return classes;
  };

  setFocusedParticipant = (id) => {
    const {fullscreenEnabled} = this.props;
    if (!fullscreenEnabled) {
      return false;
    }

    this.props.setFocusedParticipantId(id);
  }

  pinUnpinVideo = (isVideoPinned, id) => {
    const {isMobile} = this.props;
    if (isMobile) {
      return false;
    }
    if (isVideoPinned) {
      this.props.setFocusedParticipantId(null);
    } else {
      this.props.setFocusedParticipantId(id);
    }
  }

  toggleHideVideo = () => {
    this.setState((prevState) => (
      {hideFullscreenVideoList: !prevState.hideFullscreenVideoList}
    ));
  }

  shouldLeftRightArrowsBeShown = () => {
    const allowedParticipantsOnScreen = (window.screen.width - 265) / 177;
    return allowedParticipantsOnScreen < this.props.participants.length;
  }

  setPrevNextParticipantPosition = (value = 'prev') => {
    const participantsListContainer = document.getElementsByClassName('fullscreen-video-grid')[0];
    const currentScrollPosition = participantsListContainer.scrollLeft;
    const participantWidth = 177;
    let newPosition = value === 'prev' ?
      currentScrollPosition - participantWidth :
      currentScrollPosition + participantWidth;

    /* align scroll when press "previous" when user sees last participant */
    if (value === 'prev' && newPosition % participantWidth !== 0) {
      newPosition = Math.floor(newPosition / participantWidth) * participantWidth;
    }

    participantsListContainer.scrollTo({
      left: newPosition,
      behavior: "smooth",
    });
  }

  render() {
    const {participants, muteParticipants, fullscreenEnabled, screenSize, recording, classes, focusedParticipantId, isMobile} = this.props;
    const smallScreen = !screenSize.md;
    return (
      <>
        <div className={classNames('videos-container', this.getGridClasses())}>
          {smallScreen && recording && (
            <div className={classes.recordingIndicatorWrapper}>
              <RecordingIndicator />
            </div>
          )}
          {participants.map(({id, name, streamManager, sipAudio, userType, ownVideo}, i) => (
            <div
              key={id}
              onClick={() => this.setFocusedParticipant(id)}
              className={classNames('video-container', this.getVideoElementClasses(i))}
            >
              <VideoElement
                streamManager={streamManager}
                sipAudio={sipAudio}
                name={name}
                userType={userType}
                mainVideo={this.state.mainVideoIndex === i}
                ownVideo={ownVideo}
                muted={muteParticipants}
                fullscreen={fullscreenEnabled}
                hideFullscreenVideoList={this.state.hideFullscreenVideoList}
                pinUnPin={this.pinUnpinVideo}
                pinned={focusedParticipantId === id}
                id={id}
                isMobile={isMobile}
              />
            </div>
          ))}
        </div>
        {fullscreenEnabled && participants.length > 1 && (
          <div className='fullscreen-video-grid-hide-videos'>
            <IconButton
              onClick={this.toggleHideVideo}
              className={classes.hideVideosButton + ' hide-participants'}
              style={{outline: 'none'}}
              title={!this.state.hideFullscreenVideoList ? ('Hide videos') : ('Show videos') }
            >
              {smallScreen ?
                (!this.state.hideFullscreenVideoList ? <LeftIcon /> : <RightIcon />) :
                (!this.state.hideFullscreenVideoList ? <UpIcon /> : <DownIcon/>)
              }
            </IconButton>

            {!this.state.hideFullscreenVideoList && fullscreenEnabled && this.shouldLeftRightArrowsBeShown() && (
              <>
                <IconButton
                  onClick={() => this.setPrevNextParticipantPosition('prev')}
                  className={classes.hideVideosButton + ' prev-participant'}
                  style={{outline: 'none'}}
                  title={"Show previous participant."}
                >
                  <LeftIcon />
                </IconButton>
                <IconButton
                  onClick={() => this.setPrevNextParticipantPosition('next')}
                  className={classes.hideVideosButton + ' next-participant'}
                  style={{outline: 'none'}}
                  title={"Show next participant."}
                >
                  <RightIcon />
                </IconButton>
              </>
            )}
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  fullscreenEnabled: state.liveInterviewPage.fullscreen,
  screenSize: state.screen.size,
  recording: state.liveInterviewPage.recording,
  focusedParticipantId: state.liveInterviewPage.focusedParticipantId,
});

const mapDispatchToProps = dispatch => ({
  setFocusedParticipantId: (participantId) => dispatch(setFocusedParticipantId(participantId)),
});

VideoGrid = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoGrid);

VideoGrid = withStyles(style)(VideoGrid);

export default VideoGrid;
