import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { list, reset, filter, sort } from '../../actions/Candidate/list';
import {pagination, renderLinks, handleListUpdate} from "../../utils/entityList";
import Moment from 'react-moment';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {currentUserHasRole} from "../../utils/auth";
import {handleSwitchUser} from "../SwitchUser/SwitchUser";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import filtersStorage from '../../utils/filtersStorage';
import FilterForm from "./FilterForm";
import {SortableTableCells} from "../../utils/filterForm";
import IsGranted from "../IsGranted";
import TableFooter from "@material-ui/core/TableFooter";
import {getRoutePage} from "../../utils/routes";
import PhoneNumber from "../../views/Components/PhoneNumber";
import FlashMessages from "../../views/Components/FlashMessages";

class CandidateList extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    filter: PropTypes.func.isRequired,
    filtersData: PropTypes.object,
    sort: PropTypes.func.isRequired,
    sortData: PropTypes.object
  };

  componentDidMount() {
    const storageName = 'candidates';
    const filters = filtersStorage.get(storageName, null);
    const sort = filtersStorage.getSort(storageName);
    if (filters !== null || sort !== null) {
      this.props.filter(filters);
      this.props.sort(sort);
    } else {
      handleListUpdate('candidates', this.props);
    }
  }

  componentDidUpdate(prevProps) {
    handleListUpdate('candidates', this.props, prevProps);
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  render() {
    return (
      <div>
        <FlashMessages location={this.props.location} />
        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}

        <IsGranted action='create' object={this.props.retrieved}>
          <p>
            <Link to="create" className="btn btn-primary">
              Create
            </Link>
          </p>
        </IsGranted>

        <FilterForm
          initialValues={this.props.filtersData}
          onSubmit={this.props.filter}
          resetFilter={this.props.filterReset}
        />

        <div style={{'overflowX': 'auto'}}>
          <Table className="table table-responsive table-striped table-hover mb-0">
            <TableHead>
              <TableRow>
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                  'id': 'ID',
                }} />
                {currentUserHasRole('ROLE_SUPER_ADMIN') && (
                  <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{'partner___name': 'Partner'}} />
                )}
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                  'firstName': 'First Name',
                  'lastName': 'Last Name',
                  'email': 'Email Address',
                  'phoneNumber': 'Mobile Number',
                }} />
                <TableCell>Industry</TableCell>
                <TableCell>Occupation</TableCell>
                <TableCell>Specialty</TableCell>
                <SortableTableCells sort={this.props.sort} sortData={this.props.sortData} fields={{
                  'status': 'Status',
                  'createdAt': 'Created At',
                  'updatedAt': 'Updated At',
                }} />
                <TableCell colSpan={3} />
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.retrieved &&
                this.props.retrieved['hydra:member'].map(item => (
                  <TableRow key={item['@id']}>
                    <TableCell scope="row">
                      {renderLinks('candidates', item, (candidate) => candidate['id'], true)}
                    </TableCell>
                    {currentUserHasRole('ROLE_SUPER_ADMIN') && (
                      <TableCell>{renderLinks('partners', item['partner'])}</TableCell>
                    )}
                    <TableCell>
                      {renderLinks('candidates', item, (candidate) => candidate['firstName'], true)}
                    </TableCell>
                    <TableCell>
                      {renderLinks('candidates', item, (candidate) => candidate['lastName'], true)}
                    </TableCell>
                    <TableCell>{item['email']}</TableCell>
                    <TableCell>
                      <PhoneNumber phone={item['phoneNumber']} formattedPhone={item['formattedPhoneNumber']} />
                    </TableCell>
                    <TableCell>{item['industryNames'].join(', ')}</TableCell>
                    <TableCell>{item['occupationNames'].join(', ')}</TableCell>
                    <TableCell>{item['specialtyNames'].join(', ')}</TableCell>
                    <TableCell>{item['status']}</TableCell>
                    <TableCell>{item['createdAt'] && (<Moment>{item['createdAt']}</Moment>)}</TableCell>
                    <TableCell>{item['updatedAt'] && (<Moment>{item['updatedAt']}</Moment>)}</TableCell>
                    <TableCell>
                      <IsGranted action='show' object={item}>
                      <Link to={`show/${encodeURIComponent(item['@id'])}`}>
                        <span className="fa fa-eye" aria-hidden="true" />
                        <span className="sr-only">Show</span>
                      </Link>
                      </IsGranted>
                    </TableCell>
                    <TableCell>
                      <IsGranted action='edit' object={item}>
                      <Link to={`edit/${encodeURIComponent(item['@id'])}`}>
                        <span className="fa fa-pencil" aria-hidden="true" />
                        <span className="sr-only">Edit</span>
                      </Link>
                      </IsGranted>
                    </TableCell>
                    <TableCell>
                      <IsGranted action="impersonate" object={item}>
                        <a href='javascript:'>
                            <SupervisorAccountIcon onClick={() => handleSwitchUser(item['id'])} />
                        </a>
                      </IsGranted>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>

        <Table className="table table-responsive no-border">
          <TableFooter>
            <TableRow>
              {pagination(this.props.retrieved, getRoutePage(this.props.match))}
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    retrieved,
    loading,
    error,
    eventSource,
    filtersData,
    sortData,
  } = state.candidate.list;
  return { retrieved, loading, error, eventSource, filtersData, sortData };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource)),
  filter: (filtersData) => dispatch(filter(filtersData)),
  filterReset: () => dispatch(filter(null)),
  sort: (sortData) => dispatch(sort(sortData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateList);
