import React, { Component } from 'react';
import { PieChart, Pie as PieLib, Sector, Legend } from 'recharts';
import CustomButton from "../CustomButtons/Button";

export class CustomPie extends Component
{
  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  state = {
    expanded: false,
    activeIndex: null,
  };

  onPieEnter = (_, index) => {
    clearTimeout(this.leavePieTimeoutId);
    this.setState({
      activeIndex: index,
    });
  };

  onPieLeave = () => {
    clearTimeout(this.leavePieTimeoutId);
    this.leavePieTimeoutId = setTimeout(() => {
      this.onPieEnter(null, null);
    }, 2000);
  }

  toggleExpand = () => {
    this.setState({expanded: !this.state.expanded}, () => {
      document.querySelector('.dashboard-main-panel').scroll(0, this.ref.current.offsetTop - 82);
    });
  }

  render() {
    let total = 0;
    this.props.data.map(i => {
      total += i['value'];
    })

    /* calculate height and width based on parent box size and if chart is expanded */
    let width = null;
    let minBoxSize = 1030;
    if (this.props.boxWidth) {
      if (!this.state.expanded && this.props.boxWidth > minBoxSize) {
        width = (this.props.boxWidth / 2) - 27;
      } else {
        width = this.props.boxWidth - 27;
      }
    }

    let height = 380;
    if (this.state.expanded && this.props.boxWidth > minBoxSize) {
      height = window.innerHeight - 160;
    }

    const renderActiveShape = (props) => {
      const RADIAN = Math.PI / 180;
      const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
      const sin = Math.sin(-RADIAN * midAngle);
      const cos = Math.cos(-RADIAN * midAngle);
      const sx = cx + (outerRadius + 10) * cos;
      const sy = cy + (outerRadius + 10) * sin;
      const mx = cx + (outerRadius + 30) * cos;
      const my = cy + (outerRadius + 30) * sin;
      const ex = mx + (cos >= 0 ? 1 : -1) * 22;
      const ey = my;
      const textAnchor = cos >= 0 ? 'start' : 'end';

      return (
        <g>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          />
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${payload.name} - ${value}`}</text>
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
            {`${(percent * 100).toFixed(2)}%`}
          </text>
        </g>
      );
    };

    const formatter = (text, entry, index) => {
      const data = this.props.data;

      if (this.props.boxWidth < 630) {
        let value = 0;
        for (let n = 0; n < data.length; n++) {
          if (data[n]['name'] === text) {
            value = data[n]['value'];
            break;
          }
        }

        return text + ' (' + value + ')';
      }

      return text;
    };

    return (
      <div className={'chart-container pie'} style={{width: width + 15, height: height + 69}} ref={this.ref}>
        <h4 className={'text-center'}>{this.props.title}</h4>
        <div className={'chart-top-buttons'}>
          {this.props.boxWidth > minBoxSize && (
            <CustomButton
              size={'sm'}
              color="white"
              className={'expand-chart'}
              title={this.state.expanded ? 'Collapse' : 'Expand'}
              onClick={this.toggleExpand}
            >
              <i className={this.state.expanded ? 'fa fa-compress' : 'fa fa-expand'} aria-hidden="true" />
            </CustomButton>
          )}
        </div>
        <PieChart width={width} height={height}>
          <PieLib
            activeIndex={this.state.activeIndex}
            activeShape={renderActiveShape}
            data={this.props.data}
            cx="50%"
            cy="55%"
            innerRadius={this.state.expanded ? 114 : 80}
            outerRadius={this.state.expanded ? 185 : 130}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={this.onPieEnter}
            onMouseLeave={this.onPieLeave}
            startAngle={-270}
          />
          <Legend formatter={formatter} wrapperStyle={this.state.expanded ? {bottom: '55%', right: 75} : {bottom: 30}} />
          <text x={'50%'} y={'50%'} dy={8} fontSize={64} textAnchor="middle" fill={'#000'}>
            {total}
          </text>
          <text x={'50%'} y={this.state.expanded ? '55%' : '57%'} dy={8} fontSize={24} textAnchor="middle" fill={'#777'}>
            {this.props.circleText}
          </text>
        </PieChart>
      </div>
    );
  }
}
