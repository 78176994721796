import React from "react";
import {currentSiteSettings, reset} from "../../actions/SiteSettings/currentSiteSettings";
import {connect} from "react-redux";
import {TYPE_URL} from "../../components/SiteSettings/Form";

class SiteSettingsPreview extends React.Component
{
  componentDidMount() {
    this.props.currentSiteSettings();
  }

  render() {
    const item = this.props.retrieved;
    const pathname = this.props.location.pathname;
    const title = pathname === '/terms-of-service' ? 'Terms of Service' : 'Privacy Policy';

    let content = null;
    if (item) {
      if (pathname === '/terms-of-service') {
        if (item.termsOfServiceType === TYPE_URL) {
          document.location.href = item.termsOfServiceUrl;
          return;
        }

        content = item.termsOfServiceContent;
      } else {
        if (item.privacyPolicyType === TYPE_URL) {
          document.location.href = item.privacyPolicyUrl;
          return;
        }

        content = item.privacyPolicyContent;
      }
    }

    return (
      <div className={"site-settings-public-page col-md-7"}>
        <div className={"site-settings-public-page-title"}><h3>{title}</h3></div>
        {(this.props.loading) && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        <div className={"ql-editor"} dangerouslySetInnerHTML={{__html: content}} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  retrieved: state.siteSettings.currentSiteSettings.retrieved,
  loading: state.siteSettings.currentSiteSettings.loading,
  error: state.siteSettings.currentSiteSettings.error,
});

const mapDispatchToProps = dispatch => ({
  currentSiteSettings: () => dispatch(currentSiteSettings()),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteSettingsPreview);
