import {
  fetch,
  extractHubURL,
} from '../../utils/dataAccess';

export function error(error) {
  return { type: 'ONEWAYINTERVIEW_WATCH_TOKEN_SHOW_ERROR', error };
}

export function loading(loading) {
  return { type: 'ONEWAYINTERVIEW_WATCH_TOKEN_SHOW_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'ONEWAYINTERVIEW_WATCH_TOKEN_SHOW_SUCCESS', retrieved };
}

export function retrieve(token) {
  return dispatch => {
    dispatch(loading(true));

    const include_response_details = new URL(window.location.href).searchParams.get('include_response_details');
    let url = '/one_way_interview_watch_tokens/by_code/' + token;
    if (include_response_details !== null) {
      url += '?include_response_details=' + include_response_details;
    }

    return fetch(url)
      .then(response =>
        ({ retrieved: response.data, hubURL: extractHubURL(response) })
      )
      .then(({ retrieved, hubURL }) => {

        dispatch(loading(false));
        dispatch(success(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch({ type: 'ONEWAYINTERVIEW_WATCH_TOKEN_SHOW_RESET' });
    dispatch(error(null));
    dispatch(loading(false));
  };
}
