import {fetchJSON} from "../../utils/dataAccess";

export function loadCountriesForSelect(valueProperty = 'id', countryCode = null) {
  return dispatch => {
    dispatch(loading(true));
    fetchJSON('/countries?pagination=false').then((countries) => {
      countries = countries['hydra:member'].map(
        function (country) {
          return {
            value: country[valueProperty],
            text: country.name,
            code: country.code,
          }
      });

      if (countryCode) {
        countries.sort((a, b) => {
          return a.code === countryCode ? -1 : b.code === countryCode ? 1 : 0;
        });
      }

      dispatch(countriesLoaded(countries));
      dispatch(loading(false));
    }).catch(e => {
      dispatch(loading(false));
      dispatch(error(e.message));
    })
  };
}

export function loading(loading) {
  return {type: 'COUNTRIES_FOR_SELECT_LOADING', loading};
}

export function error(error) {
  return {type: 'COUNTRIES_FOR_SELECT_ERROR', error};
}

export function countriesLoaded(retrieved) {
  return {type: 'COUNTRIES_FOR_SELECT_RETRIEVED', retrieved};
}
