import React, {Component} from "react";
import MaterialUIRating from 'material-ui-rating'
import {renderFromHelper} from "../../utils/form";
import PropTypes from "prop-types";

class Rating extends Component {
  static propTypes = {
    max: PropTypes.number.isRequired,
    className: PropTypes.string,
    containerClass: PropTypes.string,
  };

  static defaultProps = {
    max: 5,
    className: '',
    containerClass: 'form-group',
  };

  render() {
    const {className, containerClass, max, input, meta: {touched, error}} = this.props;
    console.log(`Input name ${input.name} value: ${input.value}`);
    return (
      <div className={containerClass}>
        <MaterialUIRating
          defaultValue={0}
          max={max}
          className={className}
          value={input.value || 0}
          onChange={value => input.onChange(value)}
        />
        {renderFromHelper(touched, error)}
      </div>
    )
  }
}

export default Rating;
