import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RichTextEditor from "../../views/Components/RichTextEditor";
import {renderTextField} from "../../utils/form";
import RadioGroup from "@material-ui/core/RadioGroup";
import {stripTags} from "../../utils/string";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

export const TYPE_URL = 'url';
export const TYPE_CONTENT = 'content';

class Form extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
    keyword: PropTypes.string.isRequired,
    fieldPrefix: PropTypes.string.isRequired,
  };

  state = {
    selected: TYPE_CONTENT,
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.initialValues && this.props.initialValues) {
      this.changeType(this.props.initialValues[this.props.fieldPrefix + 'Type']);
    }
  }

  changeType = (type) => {
    this.setState({selected: type});
    this.props.change(this.props.fieldPrefix + 'Type', type);
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className={'mb-5'}>
          <RadioGroup
            name={this.props.fieldPrefix + 'Type'}
            value={this.state.selected}
            onChange={(e, v) => {this.changeType(v)}}
          >
            <FormControlLabel
              control={<Radio />}
              value={TYPE_URL}
              label={"Link to " + this.props.keyword}
            />
            <FormControlLabel
              control={<Radio />}
              value={TYPE_CONTENT}
              label={"Create " + this.props.keyword}
            />
          </RadioGroup>
        </div>
        {this.state.selected === TYPE_URL && (
          <Field
            component={renderTextField}
            name={this.props.fieldPrefix + 'Url'}
            type="text"
            label={'Link to ' + this.props.keyword}
            required={true}
            className={'col-md-6'}
          />
        )}
        {this.state.selected === TYPE_CONTENT && this.props.initialValues && (
          <>
            <Field
              component={RichTextEditor}
              name={this.props.fieldPrefix + 'Content'}
              type="textarea"
              required={true}
            />
            <Card className={"placeholders-list-card"}>
              <CardBody>
                <h4>Available Placeholders:</h4>
                <ul>
                  <li><span className={"placeholder"}>{'{{ site_name }}'}</span> - Site Name</li>
                  <li><span className={"placeholder"}>{'{{ site_domain }}'}</span> - Site Domain</li>
                  <li><span className={"placeholder"}>{'{{ site_country }}'}</span> - Default Site Country (if it's set)</li>
                  <li><span className={"placeholder"}>{'{{ site_state }}'}</span> - Default Site State (if it's set)</li>
                  <li><span className={"placeholder"}>{'{{ site_privacy_email }}'}</span> - Privacy Email</li>
                  <li><span className={"placeholder"}>{'{{ site_sales_email }}'}</span> - Sales Email</li>
                  <li><span className={"placeholder"}>{'{{ terms_of_service_url }}'}</span> - Terms of Service link which opens in new tab</li>
                  <li><span className={"placeholder"}>{'{{ privacy_policy_url }}'}</span> - Privacy Policy link which opens in new tab</li>
                </ul>
              </CardBody>
            </Card>
          </>
        )}
        <button type="submit" className="btn btn-success float-left mr-3">
          Submit
        </button>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (values.termsOfServiceType === TYPE_URL && !values.termsOfServiceUrl) {
    errors.termsOfServiceUrl = 'This field should not be blank.';
  }

  if (values.termsOfServiceType === TYPE_CONTENT && !stripTags(values.termsOfServiceContent)) {
    errors.termsOfServiceContent = 'This field should not be blank.';
  }

  if (values.privacyPolicyType === TYPE_URL && !values.privacyPolicyUrl) {
    errors.privacyPolicyUrl = 'This field should not be blank.';
  }

  if (values.privacyPolicyType === TYPE_CONTENT && !stripTags(values.privacyPolicyContent)) {
    errors.privacyPolicyContent = 'This field should not be blank.';
  }

  return errors;
}

export default reduxForm({
  form: 'site_settings',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: validate
})(Form);

