import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";
import {loadCompaniesForSelect} from "../../actions/Company/forSelect";
import {currentUserHasRole} from "../../utils/auth";
import {loadPartnersForSelect} from "../../actions/Partner/forSelect";

class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  componentDidMount() {
    if (currentUserHasRole('ROLE_PARTNER_ADMIN') || currentUserHasRole('ROLE_SUPER_ADMIN')) {
      this.props.loadCompaniesForSelect();
    }
    if (currentUserHasRole('ROLE_SUPER_ADMIN')) {
      this.props.loadPartnersForSelect();
    }
  }

  render() {
    const fields = [
      {'name': 'createdAt', 'label': 'Created Date', 'placeholder': 'Created Date', 'type': 'datesRange', 'past': true},
    ];

    if (currentUserHasRole('ROLE_COMPANY_ADMIN') || currentUserHasRole('ROLE_PARTNER_ADMIN') || currentUserHasRole('ROLE_SUPER_ADMIN')) {
      fields.unshift(
        {'name': 'user___id', 'label': 'User ID', 'placeholder': 'User ID', 'type': 'number'},
        {'name': 'user___email', 'label': 'User Email', 'placeholder': 'User Email', 'type': 'text'}
      );
    }

    if (currentUserHasRole('ROLE_PARTNER_ADMIN') || currentUserHasRole('ROLE_SUPER_ADMIN')) {
      fields.push({'name': 'company', 'label': 'Company', 'placeholder': 'Company', 'type': 'selectSearch', 'options': this.props.companies});
    }

    if (currentUserHasRole('ROLE_SUPER_ADMIN')) {
      fields.push({'name': 'user___partner', 'label': 'Partner', 'placeholder': 'Partner', 'type': 'selectSearch', 'options': this.props.partners});
    }

    return (
      <RenderFilterForm {...this.props} fields={fields} />
    );
  }
}

const mapStateToProps = state => {
  let companies = state.company.forSelect.retrieved;
  let partners = state.partner.forSelect.retrieved;

  return { companies,  partners };
};

const mapDispatchToProps = dispatch => ({
  loadCompaniesForSelect: () => dispatch(loadCompaniesForSelect('@id')),
  loadPartnersForSelect: () => dispatch(loadPartnersForSelect('@id')),
});

FilterForm = reduxForm({
  form: 'filter_login_history',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterForm);
