import React, {Component} from "react";
import {Fields} from "redux-form";
import IntroOutroVideoFields, {INTRO_VIDEO_FIELD, OUTRO_VIDEO_FIELD} from "../Request/IntroOutroVideoFields";

export default class RequestTemplateBasicInfo extends Component {
  render() {
    return (
      <div>
        <Fields
          label={"Intro Video"}
          names={['request[requestIntroVideo]', 'request[requestIntroVideoUrl]']}
          component={IntroOutroVideoFields}
          type={INTRO_VIDEO_FIELD}
        />
        <Fields
          label={"Outro Video"}
          names={['request[requestOutroVideo]', 'request[requestOutroVideoUrl]']}
          component={IntroOutroVideoFields}
          type={OUTRO_VIDEO_FIELD}
        />
      </div>
    );
  }
}
