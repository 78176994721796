import {fetch} from "../../utils/dataAccess";

export function getReviewScales() {
  return dispatch => {
    dispatch(loading(true));

    return fetch('review_scales')
      .then((response) => {
        dispatch(loading(false));
        const reviewScales = response.data;
        dispatch(retrieved(reviewScales ? reviewScales.criteria : {}));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

function error(error) {
  return { type: 'REVIEW_SCALES_SHOW_ERROR', error };
}

function loading(loading) {
  return { type: 'REVIEW_SCALES_SHOW_LOADING', loading };
}

function retrieved(criteria) {
  return { type: 'REVIEW_SCALES_SHOW_RETRIEVED', criteria };
}

export function reset() {
  return { type: 'REVIEW_SCALES_SHOW_RESET' };
}
