import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import SearchableSelect from "react-select";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import PropTypes from "prop-types";


export default class InviteCandidatesDialog extends React.Component
{
  static propTypes = {
    toggleInviteCandidateDialog: PropTypes.func.isRequired,
    inviteCandidatesDialogOpen: PropTypes.bool.isRequired,
    requestsLoading: PropTypes.bool.isRequired,
  }

  state = {
    selectedRequest: null,
  }

  onClose = (e) => {
    e.preventDefault();
    this.props.toggleInviteCandidateDialog();
    this.setState({
      selectedRequest: null,
    });
  }

  onSelectRequest = (option) => {
    this.setState({selectedRequest: option})
  }

  onClickNext = () => {
    if (this.state.selectedRequest) {
      this.props.history.push(`send/${encodeURIComponent(this.state.selectedRequest.value)}`);
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.inviteCandidatesDialogOpen}
        onClose={this.onClose}
        aria-labelledby="invite-candidates-modal-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
        fullWidth={true}
        classes={{paper: 'invite-candidates-modal'}}
      >
        <DialogTitle id="invite-candidates-modal-title">
          <i className="fa fa-close float-right" onClick={this.onClose} />
          Select Interview
        </DialogTitle>
        <DialogContent className={""} id={"invite-candidates-modal-body"}>
          <SearchableSelect
            id={'dashboard-period'}
            label="Load Template"
            placeholder={this.props.requestsLoading ? 'Loading...' : 'Select Interview'}
            options={!this.props.requests ? [] : this.props.requests.map((v, i) => {
              return {
                label: v.text,
                value: v.value,
              }
            })}
            onChange={this.onSelectRequest}
            value={this.state.selectedRequest}
            open={true}
            styles={{
              menu: (provided) => ({
                ...provided,
                ...{zIndex: '1000 !important'}
              })
            }}
          />
          <div className={"text-center"}>
            <button className={'btn btn-success mt-3 mr-3'} onClick={this.onClickNext} disabled={!this.state.selectedRequest}>Next</button>
            <button className={'btn btn-outline-secondary mt-3'} onClick={this.onClose}>Cancel</button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
