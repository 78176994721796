import React, {Component} from "react";
import PropTypes from "prop-types";
import DismissibleComponent from "../../views/Components/DismissibleComponent";
import {
  getStreamManagerAudioSourceName,
  getStreamManagerVideoSourceName,
} from "../../utils/streamManager";

class ChangeDeviceNotification extends Component {
  deviceChangeListening = false;

  static propTypes = {
    streamManager: PropTypes.object,
    dismissTime: PropTypes.number,
    notificationContainerClass: PropTypes.string,
    defaultAudioDeviceChangeText: PropTypes.string,
    defaultVideoDeviceChangeText: PropTypes.string,
  };

  static defaultProps = {
    dismissTime: 5,
    defaultAudioDeviceChangeText: 'Microphone changed',
    defaultVideoDeviceChangeText: 'Camera changed',
  }

  constructor(props) {
    super(props);
    this.state = {
      changedDeviceText: null,
    };
  }

  componentDidMount() {
    if (this.props.streamManager && !this.deviceChangeListening) {
      this.listenDeviceChange(this.props.streamManager);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.streamManager && !this.deviceChangeListening) {
      this.listenDeviceChange(this.props.streamManager);
    }
    if (this.deviceChangeListening && this.props.streamManager !== prevProps.streamManager) {
      if (prevProps.streamManager) {
        this.finishDeviceChangeListening(prevProps.streamManager);
      }
      this.deviceChangeListening = false;
      this.listenDeviceChange(this.props.streamManager);
    }
  }

  listenDeviceChange = (streamManager) => {
    if (!this.deviceChangeListening) {
      this.deviceChangeListening = true;
      streamManager.on('streamPropertyChanged', this.onStreamPropertyChanged);
    }
  }

  onStreamPropertyChanged = (event) => {
    const {streamManager, defaultAudioDeviceChangeText, defaultVideoDeviceChangeText} = this.props;
    if (event.changedProperty === 'videoDevice') {
      const videoDeviceName = getStreamManagerVideoSourceName(streamManager);
      const changedDeviceText = videoDeviceName ? videoDeviceName : defaultVideoDeviceChangeText;
      this.setState({changedDeviceText});
    } else if (event.changedProperty === 'audioDevice') {
      const audioDeviceName = getStreamManagerAudioSourceName(streamManager);
      const changedDeviceText = audioDeviceName ? audioDeviceName : defaultAudioDeviceChangeText;
      this.setState({changedDeviceText});
    }
  }

  finishDeviceChangeListening = (streamManager) => {
    if (streamManager) {
      streamManager.off('streamPropertyChanged', this.onStreamPropertyChanged);
    }
  }

  componentWillUnmount() {
    if (this.props.streamManager && this.deviceChangeListening) {
      this.finishDeviceChangeListening(this.props.streamManager);
    }
  }

  render() {
    const {changedDeviceText} = this.state;
    const {dismissTime, notificationContainerClass} = this.props;
    return (
      changedDeviceText ? (
        <div className={notificationContainerClass}>
          <DismissibleComponent dismissTime={dismissTime} onDismiss={() => this.setState({changedDeviceText: null})}>
            {changedDeviceText}
          </DismissibleComponent>
        </div>
      ) : null
    );
  }
}

export default ChangeDeviceNotification;
