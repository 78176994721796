import React from "react";
import {applyToUrl} from "../../utils/urls";
import Tooltip from '@material-ui/core/Tooltip';
import {tooltip} from "assets/jss/material-dashboard-pro-react.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import {list as retrieveShareLinks, reset as resetShareLink} from "../../actions/LiveInterviewShareLink/list";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import LiveInterviewShareLinkDialog, {MAX_ALLOWED_VIEWS} from "./LiveInterviewShareLinkDialog";

const style = {
  shareLink: {
    margin: '25px 0 0 0',
    '& > button': {
      padding: 0,
      fontSize: '16px',
    },
    '& > .fa:hover': {
      opacity: '0.8',
      cursor: 'pointer',
    }
  },
  loadingShareLink: {
    color: 'gray',
  },
  shareLinkViewsInfo: {
    color: 'gray',
    fontSize: '14px',
    margin: '1px',
  },
  tooltip
};


class LiveInterviewShareLink extends React.Component
{
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    liveInterview: PropTypes.object.isRequired,
  };

  state = {
    openShareLinkDialog: false,
  };

  componentDidMount() {
    if (this.props.liveInterview) {
      const url = applyToUrl('live_interview_share_links', {'liveInterview': this.props.liveInterview['@id']});
      this.props.retrieveShareLinks(url);
    }
  }

  componentWillUnmount() {
    this.props.resetShareLink();
  }

  render() {
    const {classes, liveInterview, onClose, loading} = this.props;
    const shareLink = this.props.shareLinkRetrieved && this.props.shareLinkRetrieved['hydra:member'][0];

    return (
      <>
        {liveInterview && (
          <div className={classes.shareLink}>
            {loading ? (
              <p className={classes.loadingShareLink}>Loading share link...</p>
            ) : (
              <>
                <button className={"btn btn-link"} onClick={() => {this.setState({openShareLinkDialog: true});}}>
                  {shareLink ? 'View share link' : 'Generate share link instead'}
                </button>
                <Tooltip
                  placement={"right"}
                  classes={{tooltip: classes.tooltip}}
                  enterTouchDelay={0}
                  title={`For users who don’t have an account. Link will expire after ${MAX_ALLOWED_VIEWS} views. Users can view the interview but will not be able to add reviews, comments or tags.`}>
                  <i className="fa fa-question-circle-o text-gray ml-2" aria-hidden="true" />
                </Tooltip>
                <br />
                {shareLink && (
                  <span className={classes.shareLinkViewsInfo}>
                    {`${shareLink['views']} out of ${MAX_ALLOWED_VIEWS} views.`}
                  </span>
                )}
              </>
            )}
          </div>
        )}
        {this.state.openShareLinkDialog && this.props.liveInterview && (
          <LiveInterviewShareLinkDialog
            onClose={() => {
              onClose();
              this.setState({openShareLinkDialog: false});
            }}
            liveInterview={this.props.liveInterview}
          />
        )}
      </>
    );
  }

}

const mapStateToProps = (state) => ({
  loading: state.liveinterviewsharelink.list.loading,
  shareLinkRetrieved: state.liveinterviewsharelink.list.retrieved,
});

const mapDispatchToProps = dispatch => ({
  retrieveShareLinks: (page) => dispatch(retrieveShareLinks(page)),
  resetShareLink: () => dispatch(resetShareLink()),
});

LiveInterviewShareLink = connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveInterviewShareLink);

export default withStyles(style)(LiveInterviewShareLink);
