import { combineReducers } from 'redux';

export function error(state = null, action) {
  switch (action.type) {
    case 'PASSWORD_RECOVERY_ERROR':
      return action.error;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'PASSWORD_RECOVERY_LOADING':
      return action.loading;

    default:
      return state;
  }
}

export function sent(state = null, action) {
  switch (action.type) {
    case 'PASSWORD_RECOVERY_SUCCESS':
      return action.sent;

    default:
      return state;
  }
}

export default combineReducers({ error, sent, loading });
