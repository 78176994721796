import React from "react";
import VideoInterviewSettings from "./VideoInterviewSettings";
import withStyles from "@material-ui/core/styles/withStyles";
import {whiteColor} from "../../assets/jss/material-dashboard-pro-react";
import LeaveIcon from "@material-ui/icons/CallEnd";
import {IconButton, Menu, MenuItem} from "@material-ui/core";
import {currentUserRoles} from "../../utils/auth";
import {history} from "../../store";
import {resolveDashboardUrl} from "../../utils/dashboardUrlResolver";
import {connect} from "react-redux";
import EndInterviewDialog from "./EndInterviewDialog";

const style = {
  buttonClass: {
    color: whiteColor,
    fontSize: 'medium',
    padding: 0,
    marginTop: '.5rem',
    borderRadius: 0,
    outline: 'none !important',
    '& > span': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
};

class VideoInterviewTopPanel extends React.Component {
  state = {
    menuAnchor: null,
    endInterviewDialogShow: false,
  };

  showLeaveMenu = (event) => {
    this.setState({menuAnchor: event.currentTarget})
  };

  closeLeaveMenu = () => {
    this.setState({menuAnchor: null})
  }

  leaveInterview = () => {
    const roles = currentUserRoles();
    history.push(resolveDashboardUrl(roles));
  };

  render() {
    const {classes, isHost} = this.props;
    const {menuAnchor} = this.state;
    return (
      <div className={'live-interview-video-top-panel'}>
        <VideoInterviewSettings
          buttonClass={classes.buttonClass}
          showScreenShareButton={false}
        />
        <IconButton
          onClick={(event) => isHost ? this.showLeaveMenu(event) : this.leaveInterview()}
          className={classes.buttonClass}
          aria-controls={isHost ? 'leave-menu' : null}
          aria-haspopup={isHost}
        >
          <LeaveIcon />
          <span className='video-control-label'>Leave</span>
        </IconButton>
        {isHost && (
          <>
            <Menu
              id="leave-menu"
              anchorEl={menuAnchor}
              keepMounted
              open={!!menuAnchor}
              onClose={this.closeLeaveMenu}
            >
              <MenuItem onClick={this.leaveInterview}>Leave Interview</MenuItem>
              <MenuItem onClick={() => this.setState({endInterviewDialogShow: true})}>End Interview</MenuItem>
            </Menu>
            <EndInterviewDialog
              showDialog={this.state.endInterviewDialogShow}
              endInterview={this.endInterview}
              closeDialog={() => this.setState({endInterviewDialogShow: false})}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isHost: state.liveInterviewPage.isHost,
  session: state.liveInterviewPage.session,
});

const mapDispatchToProps = dispatch => ({
});

VideoInterviewTopPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoInterviewTopPanel);


VideoInterviewTopPanel = withStyles(style)(VideoInterviewTopPanel);

export default VideoInterviewTopPanel;
