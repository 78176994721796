import React, {Component} from "react";
import moment from "moment";
import Moment from "react-moment";
import PropTypes from "prop-types";
import {getTimezoneDataByName, formatTimezoneWithOffset} from "../../utils/timezone";

export default class DateTimeUserTimeZoneWithTimezone extends Component { 
  static propTypes = {
    time: PropTypes.string.isRequired,
  };

  render() {
    const {time} = this.props;
    const dt = moment.tz(time, moment.tz.guess());
    const timeZoneName = dt.tz();
    const timezoneData = getTimezoneDataByName(timeZoneName);
    return (
      <>
        {dt.format(Moment.timeFormat)}
        <br/>
        {timezoneData && formatTimezoneWithOffset(dt.format('Z'))}
      </>
    )
  }
}
