import React, { Component } from 'react';
import {Field, FieldArray, formValueSelector, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {renderTextField, renderCheckbox} from "../../utils/form";
import {loadTeamsForSelect} from "../../actions/Team/forSelect";
import {loadStatesForSelect} from "../../actions/State/forSelect";
import {connect} from "react-redux";
import {loadCompanyHiringManagersForSelect} from "../../actions/CompanyHiringManager/forSelect";
import RichTextEditor from "../../views/Components/RichTextEditor";
import ProfessionMapping from "../../views/Components/ProfessionMapping";
import SearchableSelect from "../../views/Components/SearchableSelect";
import {loadCountriesForSelect} from "../../actions/Country/forSelect";

class JobForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      country: props && props.initialValues && ('country' in props.initialValues) ? props.initialValues['country'] : null,
    };
  }

  componentWillMount() {
    const teamFilters = {};
    const initialValues = this.props.initialValues;
    if (initialValues && initialValues.team) {
      teamFilters.withId = initialValues.team;
    }
    this.props.loadTeamsForSelect(teamFilters);
    this.props.loadCountriesForSelect(this.props.site.country && this.props.site.country.code);
    this.props.loadStatesForSelect();
    this.props.loadHiringManagersForSelect();
  }

  getManagersForSelect = (team) => {
      return (this.props.hiringManagers || []).filter(manager =>
          manager.teams.reduce((accum, managerTeam) =>
              accum || team === managerTeam['@id']
          , false)
      );
  };

  handleTeamChange = (event, newValue, prevValue) => {
      if (newValue !== prevValue) {
          this.props.change('hiringManagers', []);
      }
  };

  handleCountrySelect = (e, value, oldValue) => {
    if (value !== oldValue) {
      this.props.change('state', null);

      this.setState({country: value});
    }
  };

  render() {
    let stateOptions = [];
    if (this.state.country !== null) {
      if (this.props.states) {
        this.props.states.map(state => {
          if (state.country === this.state.country) {
            stateOptions.push(state);
          }
        });
      }
    }

    let team = this.props.team;
    let availableHiringManagers = (team) ? this.getManagersForSelect(team): [];
    const managers = this.getManagersForSelect(team);
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={renderTextField}
          name="title"
          type="text"
          placeholder="Title"
          required={true}
        />
        <Field
          component={RichTextEditor}
          name="description"
          placeholder="Description"
          type="text"
          required={true}
        />
        <Field
          component={renderTextField}
          name="notes"
          type="text"
          placeholder="Notes"
        />
        <Field
          component={renderTextField}
          name="address"
          type="text"
          placeholder="Address"
        />
        <Field
          component={renderTextField}
          name="address2"
          type="text"
          placeholder="Address2"
        />
        <Field
          component={renderTextField}
          name="city"
          type="text"
          placeholder="City"
        />
        <Field
          component={SearchableSelect}
          name="country"
          label="Country"
          placeholder={this.props.countries ? 'Select Country' : 'Loading...'}
          options={this.props.countries ? this.props.countries : []}
          required={true}
          onChange={this.handleCountrySelect}
        />
        {stateOptions.length > 0 && (
          <Field
            component={SearchableSelect}
            name="state"
            label="State"
            placeholder="Select State"
            options={stateOptions}
            required={true}
            isDisabled={!this.state.country}
          />
        )}
        <Field
          component={renderTextField}
          name="zipCode"
          type="text"
          placeholder="ZipCode"
        />
        <Field
          component={renderTextField}
          name="minSalary"
          type="number"
          placeholder="Minimum Salary"
          normalize={val => val === "" ? null : val}
        />
        <Field
          component={renderTextField}
          name="maxSalary"
          type="number"
          placeholder="Maximum Salary"
          normalize={val => val === "" ? null : val}
        />
        <Field
          component={renderTextField}
          name="salaryType"
          type="text"
          placeholder="Salary Type"
        />
        <Field
          component={renderCheckbox}
          name="displaySalary"
          type="checkbox"
          label="Should we display the Salary to Candidates?"
        />
        <Field
          component={renderTextField}
          name="employmentType"
          type="text"
          placeholder="Employment Type"
        />
        <Field
          component={renderCheckbox}
          name="visaSponsorship"
          type="checkbox"
          label="Will you provide Visa Sponsorship for this job?"
        />
        <Field
          component={renderCheckbox}
          name="relocation"
          type="checkbox"
          label="Will you provide Relocation for this job?"
        />
        <Field
          component={SearchableSelect}
          name="team"
          label="Team"
          placeholder={this.props.teams ? 'Select Team' : 'Loading...'}
          onChange={this.handleTeamChange}
          required={true}
          options={this.props.teams ? this.props.teams : []}
        />
        <Field
          component={SearchableSelect}
          name="hiringManagers"
          label="Hiring Managers"
          placeholder={!team ? 'Select Team First' : team && availableHiringManagers.length === 0 ? 'No hiring managers in this team' : 'Select Hiring Manager'}
          isMulti={true}
          disabled={team && availableHiringManagers.length > 0 ? false : true}
          options={managers}
          helpText="If a hiring manager is assigned to this job, he/she will be able to view all interviews associated with this job. If you'd prefer to choose which interviews the hiring manager has access to, please leave this field blank."
        />
        <FieldArray
          label="Profession Mappings"
          name="professionMappings"
          component={ProfessionMapping}
          required={false}
        />
        <button type="submit" className="btn btn-success float-left mr-3">
          Submit
        </button>
      </form>
    );
  }
}

const selector = formValueSelector('job');
const mapStateToProps = state => ({
    teams: state.team.forSelect.retrieved,
    team: selector(state, 'team'),
    countries: state.country.forSelect.retrieved,
    states: state.state.forSelect.retrieved,
    hiringManagers: state.companyHiringManager.forSelect.retrieved,
    selectedHiringManagers: selector(state, 'hiringManagers'),
    site: state.siteDetails.siteDetails.retrieved,
});

const mapDispatchToProps = dispatch => ({
    loadTeamsForSelect: (filters) => dispatch(loadTeamsForSelect('@id', filters)),
    loadCountriesForSelect: (countryCode) => dispatch(loadCountriesForSelect('@id', countryCode)),
    loadStatesForSelect: () => dispatch(loadStatesForSelect('@id')),
    loadHiringManagersForSelect: () => dispatch(loadCompanyHiringManagersForSelect('@id')),
});

JobForm = reduxForm({
  form: 'job',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(JobForm);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobForm);
