import OpenViduVideoComponent from "../video/OpenViduVideoComponent";
import React from "react";
import {withStyles} from "@material-ui/core";
import {streamManagerHasStream, streamManagerHasVideo} from "../../utils/streamManager";

const style = {
  videoElement: {
    width: '100%',
    height: '100%',
    display: 'flex',
    '& video': {
      width: '100%',
      height: '100%',
      backgroundColor: 'black',
    }
  },
  noVideo: {
    width: '100%',
    flexGrow: 1,
    height: 'auto',
    display: 'flex',
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  noVideoText: {
    fontSize: 'xx-large',
    color: 'white',
    padding: '1vw',
  }
}

class TestVideoElement extends React.Component{
  render() {
    const {streamManager, classes} = this.props;
    const hasVideoStream = streamManagerHasVideo(streamManager);
    return (
        hasVideoStream ? (
          <OpenViduVideoComponent
            streamManager={streamManager}
            videoLoaded={true}
            className={classes.videoElement}
          />
        ) : (
          <div className={'test-video-element-no-video-placeholder ' + classes.noVideo}>
            <div className={classes.noVideoText}>
              {streamManagerHasStream(streamManager) ? 'No video' : 'Please allow access to your camera and microphone to start your video.'}
            </div>
          </div>
        )
    );
  }
}

TestVideoElement = withStyles(style)(TestVideoElement);

export default TestVideoElement;
