import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import {renderTextField} from "../../utils/form";
import {loadOccupationsForSelect} from "../../actions/Occupation/forSelect";
import {connect} from "react-redux";
import SearchableSelect from "../../views/Components/SearchableSelect";

class SpecialtyForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  componentDidMount() {
    this.props.loadOccupationsForSelect();
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field
          component={renderTextField}
          name="name"
          type="text"
          placeholder="Name"
          required={true}
        />
        <Field
          component={SearchableSelect}
          name="occupation"
          label="Occupation"
          required={true}
          placeholder={this.props.occupations ? 'Select Occupation' : 'Loading...'}
          options={this.props.occupations ? this.props.occupations : []}
        />
        <button type="submit" className="btn btn-success float-left mr-3">
          Submit
        </button>
      </form>
    );
  }
}

SpecialtyForm = reduxForm({
  form: 'specialty',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(SpecialtyForm);


const mapStateToProps = state => {
  return {
    occupations: state.occupation.forSelect.retrieved,
  };
};

const mapDispatchToProps = dispatch => ({
  loadOccupationsForSelect: () => dispatch(loadOccupationsForSelect('@id')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecialtyForm);
