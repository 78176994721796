import {fetchJSON} from "../../utils/dataAccess";

export function loadSpecialtiesForSelect(valueProperty = 'id', isPublic = false) {
  return dispatch => {
    dispatch(loading(true));
    const url = isPublic ? '/specialties/select_public' : '/specialties/select';

    fetchJSON(url).then((specialties) => {
      dispatch(specialtiesLoaded(specialties['hydra:member'].map(
        function (specialty) {
          let item = {
            value: specialty[valueProperty],
            text: specialty.name,
            occupation: specialty.occupation,
          };

          return item;
        }
      )));
      dispatch(loading(false));
    }).catch(e => {
      dispatch(loading(false));
      dispatch(error(e.message));
    })
  };
}

export function loading(loading) {
  return {type: 'SPECIALTIES_FOR_SELECT_LOADING', loading};
}

export function error(error) {
  return {type: 'SPECIALTIES_FOR_SELECT_ERROR', error};
}

export function specialtiesLoaded(retrieved) {
  return {type: 'SPECIALTIES_FOR_SELECT_RETRIEVED', retrieved};
}
