import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import errorPageStyles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles.jsx";

class ErrorPage extends React.Component {

    render() {
        const {classes, className} = this.props;
        return (
            <div className={classes.contentCenter + ' ' + classes.black + ' ' + className}>
                <GridContainer>
                    <GridItem md={12}>
                        <h1 className={classes.title + ' ' + classes.black}>{this.props.errorTitle}</h1>
                        {this.props.errorSubTitle && (
                            <h2 className={classes.subTitle}>{this.props.errorSubTitle}</h2>
                        )}
                        {this.props.errorDescription && (
                            <h4 className={classes.description}>
                                {this.props.errorDescription}
                            </h4>
                        )}
                    </GridItem>
                </GridContainer>
            </div>
        );
    }

    static defaultProps = {
        classes: {},
    };
}

ErrorPage.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    errorTitle: PropTypes.string.isRequired,
    errorSubTitle: PropTypes.string,
    errorDescription: PropTypes.string,
};

export default withStyles(errorPageStyles)(ErrorPage);
