import React from "react";
import DashboardErrorPage from "./DashboardErrorPage";

export default class SiteDetailsLoadingPage extends React.Component {
    render() {
        return (
            <div className="container">
                <DashboardErrorPage
                    errorTitle="Loading..."
                    errorDescription="Loading, please wait a second..."
                />
            </div>
        );
    }
}

