import React from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

import companyRegisterPageStyle from "assets/jss/material-dashboard-pro-react/views/companyRegisterPageStyle";
import RegistrationForm from "components/Company/RegistrationForm.jsx";
import {register, reset} from "../../actions/Company/register";
import {connect} from "react-redux";
import {reset as resetForm} from "redux-form";
import {currentUserRoles, handleAuthentication, isAuthenticated} from "../../utils/auth";
import {resolveDashboardUrl} from "../../utils/dashboardUrlResolver";
import storage from "../../utils/storage";
import NotFound from "./NotFound";

class CompanyRegisterPage extends React.Component {
  componentWillMount() {
    if (isAuthenticated()) {
      this.props.history.push(resolveDashboardUrl(currentUserRoles()));
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  handleSubmit = (values) => {
    return this.props.register(values);
  }

  handleSuccessSubmit = (result, dispatch) => {
    dispatch(resetForm('registration'));
    if (this.props.registered && this.props.registered.token) {
      storage.set('afterLoginUrl', '/company/profile');
      handleAuthentication(this.props.registered);
    }
  };

  render() {
    const { classes, site } = this.props;
    if (site.type === 'company') {
      return <NotFound />;
    }
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>Companies - Start Your Free Trial</h2>
              <br />
              <div align="center">
                <Card style={{maxWidth: '50%', margin: 0, padding: 0}}>
                  <CardBody>
                    <p align="center">
                      <br />
                      <strong>Important:</strong> Are you a Candidate that has been invited to an interview?
                      <br /><br />
                      <Link to={`/register/candidate`}>
                        <Button round color="rose">
                          Register Here
                        </Button>
                      </Link>
                    </p>
                  </CardBody>
                </Card>
              </div>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={8} md={5}>
                    {this.props.loading && (
                      <div className="alert alert-info" role="status">
                        Loading...
                      </div>
                    )}
                    <RegistrationForm
                        onSubmit={this.handleSubmit}
                        onSubmitSuccess={this.handleSuccessSubmit}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

CompanyRegisterPage.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    const { registered, loading } = state.company.register;
    const site = state.siteDetails.siteDetails.retrieved;
    return { registered, loading, site };
};

const mapDispatchToProps = dispatch => ({
    register: values => dispatch(register(values)),
    reset: () => dispatch(reset())
});

CompanyRegisterPage = withStyles(companyRegisterPageStyle)(CompanyRegisterPage);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyRegisterPage);
