import React, {Component} from "react";
import PropTypes from "prop-types";

import {ENTRYPOINT} from "../../config/entrypoint";

export default class LiveInterviewPlayer extends Component {
    static propTypes = {
        interview: PropTypes.object.isRequired,
        iframeStyle: PropTypes.object,
    };

    componentDidMount() {
        let videos = this.props.interview.liveInterviewVideos;
        if (videos.length === 0) {
            return;
        }
        for (let i = 0; i < videos.length; i++) {
            videos[i].src = videos[i].videoUrl;
        }
        this.iframeRef.onload = () => {
            this.iframeRef.contentWindow.postMessage(
                {
                    call: 'setLiveInterviewVideos',
                    value: videos
                },
                '*')
        }
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.heightStyle !== nextProps.heightStyle) {
            return true;
        }

        return false; //no need to rerender iframe
    }


    render() {
        let iframeStyle = {
            width:'100%',
            height: '100vh',
            border: '1px solid #CCC',
            borderTop: '0',
            minHeight: '350px',
        };

        if (null !== this.props.iframeStyle && typeof this.props.iframeStyle === 'object') {
            iframeStyle = {...iframeStyle, ...this.props.iframeStyle};
        }

        return this.props.interview.liveInterviewVideos.length > 0 && (
            <iframe
                style={iframeStyle}
                src={ENTRYPOINT + '/live_interview/playback'}
                ref={(iframe) => this.iframeRef = iframe}
                allow="autoplay"
            />)

    }
}
