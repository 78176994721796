import React, {Component} from "react";
import { ControlBar, PlaybackRateMenuButton } from "video-react";
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import {PLAYBACK_RATES} from "../../config/player";
import VideoPlayer from "./VideoPlayer";

const style = {
  playbackRate: {
    textShadow: 'none !important',
    '& .video-react-menu-content': {
      textShadow: 'none',
      maxHeight: '30em !important',
      '& .video-react-menu-item': {
        fontSize: '10px',
      },
    }
  }
};

class VideoPlayerWithPlaybackRate extends Component {
  static propTypes = {
    playbackRates: PropTypes.array,
  }

  static defaultProps = {
    playbackRates: PLAYBACK_RATES,
  };

  constructor(props) {
    super(props);

    this.playbackRate = React.createRef();
    this.state = {
      playbackRate: 1,
    }

    this.handleClickPlayer = this.handleClickPlayer.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.player.refs && prevProps.seek !== this.props.seek) {
      this.player.actions.seek(this.props.seek);
      this.player.actions.play();
    }
  }

  handleClickPlayer(e) {
    if (e.target.classList.contains('video-react-menu-item')) {
      const videoReactMenu = document.querySelector('.video-react-lock-showing');
      if (videoReactMenu) {
        videoReactMenu.click(); // fire a click event, which is handled in handleClick() in the Menu.js of the video-react component to close the PlaybackRateMenuButton
      }
    }
  }

  render() {
    const {classes, playbackRates, children, ...otherProps} = this.props;
    return (
      <div onClick={this.handleClickPlayer}>
        <VideoPlayer
          ref={player => {this.player = player}}
          {...otherProps}
        >
          <ControlBar>
            <PlaybackRateMenuButton rates={playbackRates} className={classes.playbackRate} order={7}/>
          </ControlBar>
        </VideoPlayer>
      </div>
    );
  }
}

VideoPlayerWithPlaybackRate = withStyles(style)(VideoPlayerWithPlaybackRate);

export default VideoPlayerWithPlaybackRate;
