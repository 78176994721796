import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { retrieve, reset } from '../../actions/Team/show';
import { activate } from '../../actions/Team/activate';
import { inactivate } from '../../actions/Team/inactivate';
import { del } from '../../actions/Team/delete';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Moment from 'react-moment';
import IsGranted from "../IsGranted";
import {manageDeleted, reset as manageDeletedReset} from "../../actions/TeamCompanyUser/manageDeleted";
import {isGranted} from "../../utils/permissions";
import {currentUserHasRole} from "../../utils/auth";
import filtersStorage from "../../utils/filtersStorage";
import FlashMessages from "../../views/Components/FlashMessages";

class TeamShow extends Component {
  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    deleteError: PropTypes.string,
    deleteLoading: PropTypes.bool.isRequired,
    deleted: PropTypes.object,
    del: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.loadTeam();
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
    this.props.manageDeletedReset();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.activated && !prevProps.activated) {
      this.loadTeam();
    }

    if (this.props.inactivated && !prevProps.inactivated) {
      const team = this.props.retrieved;
      const teamId = team && team['@id'] ? parseInt(team['@id'].replace('/teams/', '')) : undefined;
      filtersStorage.resetKeyForAllFilters('team', teamId);
      filtersStorage.resetKeyForAllFilters('job___team', teamId);
      filtersStorage.resetKeyForAllFilters('request___job___team', teamId);
    }
  }

  del = () => {
    if (window.confirm('Are you sure you want to delete this item?'))
      this.props.del(this.props.retrieved);
  };

  inactivate = () => {
    if (window.confirm('Are you sure you want to inactivate this team?')) {
      this.props.inactivate(this.props.retrieved);
    }
  };

  activate = () => {
    if (window.confirm('Are you sure you want to activate this team?')) {
      this.props.activate(this.props.retrieved);
    }
  };

  loadTeam = () => {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
  }

  render() {
    if (this.props.deleted)
      this.props.history.push({
        pathname: `..`,
        state: {successMessage: 'Team successfully deleted.'}
      });

    if (this.props.inactivated)
      this.props.history.push({
        pathname: `..`,
        state: {successMessage: 'Team successfully deactivated.'}
      });

    const item = this.props.retrieved;
    /* update deleted/restored team company user */
    if (this.props.teamCompanyUserSaved && item) {
      item['teamCompanyUsers'].map((teamCompanyUser) => {
        if (this.props.teamCompanyUserSaved['@id'] === teamCompanyUser['@id']) {
          teamCompanyUser.deletedAt = this.props.teamCompanyUserSaved.deletedAt;
        }
      });
    }

    return (
      <div>
        <h1>Show &quot;{item && item['name']}&quot;</h1>

        <FlashMessages location={this.props.location} />
        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}

        <div className="grid-buttons">
          <Link to=".." className="btn btn-primary">
            Back to list
          </Link>
          {item && (
          <>
            <IsGranted action="edit" object={item}>
            <Link to={`../edit/${encodeURIComponent(item['@id'])}`} className="btn btn-warning">Edit</Link>
            </IsGranted>
            <IsGranted action="inactivate" object={item}>
              <button onClick={this.inactivate} className="btn btn-secondary">
                Inactivate
              </button>
            </IsGranted>
            <IsGranted action="activate" object={item}>
              <button onClick={this.activate} className="btn btn-info">
                Activate
              </button>
            </IsGranted>
            <IsGranted action="delete" object={item}>
            <button onClick={this.del} className="btn btn-danger">
              Delete
            </button>
            </IsGranted>
          </>
          )}
        </div>

        {item && (
          <div style={{'overflowX': 'auto'}}>
            <Table className="table table-responsive table-striped">
              <TableHead>
                <TableRow>
                  <TableCell>Field</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell scope="row">Name</TableCell>
                  <TableCell>{item['name']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Status</TableCell>
                  <TableCell
                    className={item['status'] === 'active' ? 'text-success' : 'text-black-50'}
                    style={{textTransform: 'capitalize'}}
                  >{item['status']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Created At</TableCell>
                  <TableCell>{item['createdAt'] && (<Moment>{item['createdAt']}</Moment>)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Updated At</TableCell>
                  <TableCell>{item['updatedAt'] && (<Moment>{item['updatedAt']}</Moment>)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Users</TableCell>
                  <TableCell>
                    {this.props.teamCompanyUserLoading && (
                      <div className="alert alert-info" role="status">
                        Loading...
                      </div>
                    )}
                    <Table className="table table-bordered">
                      <TableHead>
                        <TableRow>
                          <TableCell>First Name</TableCell>
                          <TableCell>Last Name</TableCell>
                          <TableCell>Role</TableCell>
                          <TableCell className='text-center'>Manager</TableCell>
                          <TableCell className="text-center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {item['teamCompanyUsers'].map((teamCompanyUser, i) => (
                          <TableRow key={teamCompanyUser.id}>
                            <TableCell className={teamCompanyUser.deletedAt ? 'text-secondary' : ''}>
                              {currentUserHasRole('ROLE_COMPANY_ADMIN') && !teamCompanyUser.deletedAt ? (
                                <a href={'/company/users/show/' + encodeURIComponent(teamCompanyUser.companyUser['@id'])}>{teamCompanyUser.companyUser.firstName}</a>
                              ) :
                                teamCompanyUser.companyUser.firstName
                              }
                            </TableCell>
                            <TableCell className={teamCompanyUser.deletedAt ? 'text-secondary' : ''}>
                              {currentUserHasRole('ROLE_COMPANY_ADMIN') && !teamCompanyUser.deletedAt ? (
                                  <a href={'/company/users/show/' + encodeURIComponent(teamCompanyUser.companyUser['@id'])}>{teamCompanyUser.companyUser.lastName}</a>
                                ) :
                                teamCompanyUser.companyUser.lastName
                              }
                            </TableCell>
                            <TableCell className={teamCompanyUser.deletedAt ? 'text-secondary' : ''}>{teamCompanyUser.companyUser['@type'].replace(/([a-z0-9])([A-Z])/g, '$1 $2')}</TableCell>
                            <TableCell className={teamCompanyUser.deletedAt ? 'text-secondary text-center' : 'text-center'}>{teamCompanyUser.isManager ? 'Yes' : 'No'}</TableCell>
                            <TableCell className="text-center">
                              {isGranted('edit', item) && 0 !== i && (
                                <>
                                  {teamCompanyUser.deletedAt ? (
                                    <button className="btn btn-info btn-sm" onClick={() => this.props.manageDeleted(teamCompanyUser)}>DELETED - Restore</button>
                                  ) : (
                                    <button className="btn btn-danger btn-sm" onClick={() => this.props.manageDeleted(teamCompanyUser)}>Delete</button>
                                  )}
                                </>
                              )}
                              {!isGranted('edit', item) && teamCompanyUser.deletedAt && (
                                <p className="text-secondary">Deleted</p>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        )}

      </div>
    );
  }

  renderLinks = (type, items) => {
    if (Array.isArray(items)) {
      return items.map((item, i) => (
        <div key={i}>{this.renderLinks(type, item)}</div>
      ));
    }

    return (
      <Link to={`../${type}/show/${encodeURIComponent(items)}`}>{items}</Link>
    );
  };
}

const mapStateToProps = state => ({
  retrieved: state.team.show.retrieved,
  error: state.team.show.error || state.team.del.error || state.teamCompanyUser.manageDeleted.error || state.team.activate.error || state.team.inactivate.error,
  loading: state.team.show.loading || state.team.del.loading || state.team.activate.loading || state.team.inactivate.loading,
  eventSource: state.team.show.eventSource,
  deleted: state.team.del.deleted,
  activated: state.team.activate.activated,
  inactivated: state.team.inactivate.inactivated,
  teamCompanyUserLoading: state.teamCompanyUser.manageDeleted.loading,
  teamCompanyUserSaved: state.teamCompanyUser.manageDeleted.saved
});

const mapDispatchToProps = dispatch => ({
  retrieve: id => dispatch(retrieve(id)),
  del: item => dispatch(del(item)),
  reset: eventSource => dispatch(reset(eventSource)),
  activate: (item) => dispatch(activate(item)),
  inactivate: (item) => dispatch(inactivate(item)),
  manageDeleted: teamCompanyUser => dispatch(manageDeleted(teamCompanyUser)),
  manageDeletedReset: teamCompanyUser => dispatch(manageDeletedReset(teamCompanyUser)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamShow);
