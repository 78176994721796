import {combineReducers} from "redux";
import {
  loading, error, request, invitation, step, thinkTimeStarted
} from "./page";
import {interview, creatingInterview, startingInterview} from "./interview";
import {savingAnswer, answerError} from "./answer";
import auth from "./auth";

import invitationState from "./invitation";

export default combineReducers({
  loading, error, request, step, thinkTimeStarted,
  interview, creatingInterview, startingInterview,
  savingAnswer, answerError,
  auth,
  invitation, invitationState,
});
