import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RenderFilterForm from "../../utils/filterForm";
import {loadJobsForSelect} from "../../actions/Job/forSelect";
import {loadTeamsForSelect} from "../../actions/Team/forSelect";
import {loadRequestsForSelect} from "../../actions/Request/forSelect";
import {loadCompanyCandidatesForSelect} from "../../actions/CompanyCandidate/forSelect";
import {loadCompanyUsersForSelect} from "../../actions/CompanyUser/forSelect";

class FilterForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      jobsFilterIsModalOpen: false,
    }
  }

  onFirstExpand = () => {
    this.props.loadJobsForSelect();
  }


  jobsFilterToggleModal = (opened) => {
    this.setState({jobsFilterIsModalOpen: opened});
  }

  selectJob = (job, fieldName = '@id') => {
    this.props.change('liveInterview___job', job[fieldName]);
    this.jobsFilterToggleModal(false);
  }

  render() {
    const jobsPlaceholder = this.props.loading ? 'Loading...' : 'Job';
    const statuses = [
      {value: 'pending', text: 'Pending'},
      {value: 'opened', text: 'Opened'},
      {value: 'accepted', text: 'Accepted'},
      {value: 'declined', text: 'Declined'},
      {value: 'complete', text: 'Complete'},
      {value: 'incomplete', text: 'Incomplete'},
      {value: 'expired', text: 'Expired'},
    ];

    return (
      <>
        {!this.props.parentLoading && this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}
        <RenderFilterForm {...this.props} onFirstExpand={this.onFirstExpand} fields={[
          {'name': 'liveInterview___job', 'label': 'Job', 'placeholder': jobsPlaceholder, 'type': 'jobSelectSearch', 'options': this.props.jobs,
            'jobsFilterSelectJob': this.selectJob,
            'jobsFilterToggleModal': this.jobsFilterToggleModal,
            'jobsFilterIsModalOpen': this.state.jobsFilterIsModalOpen,
          },
          {'name': 'firstName', 'label': 'Candidate First Name', 'placeholder': 'Candidate First Name', 'type': 'text'},
          {'name': 'lastName', 'label': 'Candidate Last Name', 'placeholder': 'Candidate Last Name', 'type': 'text'},
          {'name': 'email', 'label': 'Candidate Email', 'placeholder': 'Candidate Email', 'type': 'text'},
          {'name': 'status', 'label': 'Status', 'placeholder': 'Status', 'type': 'selectSearch', 'options': statuses},
          {'name': 'liveInterview___scheduledStartTime', 'label': 'Scheduled Date', 'placeholder': 'Scheduled Date', 'type': 'datesRange'},
        ]} />
      </>
    );
  }
}

const mapStateToProps = state => ({
  jobs: state.job.forSelect.retrieved,
  loading: state.job.forSelect.loading,
});

const mapDispatchToProps = dispatch => ({
  loadJobsForSelect: () => dispatch(loadJobsForSelect()),
});

FilterForm = reduxForm({
  form: 'live_interview_invitation',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(FilterForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterForm);
