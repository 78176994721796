import React, {Component} from "react";
import {history} from "../../store";
import {connect} from "react-redux";
import {
  getVideoQuestionsCount,
  isInterviewStarted,
  isInvitationAccepted,
  isInvitationDeclined
} from "../../utils/onDemandInterview";
import {setStep} from "../../actions/OnDemandInterviewPage/page";
import {STEP_ABOUT, STEP_INFO, STEP_QUESTIONS, STEP_TEST} from "./OnDemandInterviewSteps";
import {generatePath, matchPath, withRouter} from "react-router";
import {compose} from "redux";
import {currentUserHasRole, isAuthenticated} from "../../utils/auth";

class OnDemandInterviewStepsController extends Component {
  stopHistoryListen;

  historyChanged = false;

  constructor(props) {
    super(props);

    this.props.setStep(this.defineCurrentStep(this.props.match));
  }

  componentDidMount() {
    this.stopHistoryListen = history.listen((location) => {
      const match = matchPath(location.pathname, {
        path: this.props.match.path,
        exact: this.props.match.path.isExact,
      });
      if (match) {
        const newStep = this.defineCurrentStep(match);
        if (this.props.step !== newStep) {
          this.props.setStep(newStep);
        } else {
          this.updateUrlForCurrentStep(match);
        }
      }
    });
  }

  componentWillUnmount() {
    if (this.stopHistoryListen) {
      this.stopHistoryListen();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.step !== this.props.step) {
      this.historyChanged = false;
      this.updateUrlForCurrentStep(this.props.match);
    }

    if (
      prevProps.invitation && !isInvitationAccepted(prevProps.invitation) &&
      this.props.invitation && isInvitationAccepted(this.props.invitation)
    ) {
      this.props.setStep(STEP_ABOUT);
    }

    if (!prevProps.interview && this.props.interview) {
      this.props.setStep(this.getStepBasedOnInterviewState());
    }

    if (
      prevProps.interview && !prevProps.interview.startedAt &&
      this.props.interview && this.props.interview.startedAt
    ) {
      this.props.setStep(this.getStepBasedOnInterviewState());
    }

    if (!prevProps.loggedIn && this.props.loggedIn && !currentUserHasRole('ROLE_CANDIDATE')) {
      this.props.setStep(STEP_INFO);
    }
  }

  defineCurrentStep = (match) => {
    const stepFromUrl = match.params && match.params.step ? match.params.step : STEP_INFO;
    if (this.isStepAllowed(stepFromUrl)) {
      return stepFromUrl;
    }
    return this.getStepBasedOnInterviewState();
  }

  updateUrlForCurrentStep = (match) => {
    const newUrl = this.getUrlByStep(this.props.step);
    if (match.url !== newUrl && !this.historyChanged) {
      this.historyChanged = true;
      history.push(newUrl);
      this.forceUpdate();
    }
  }

  getUrlByStep = (step) => {
    const {match} = this.props;
    const path = match && match.path ? match.path : '/';
    const params = Object.assign({},match.params, {
      step: (step !== STEP_INFO) ? step : undefined,
    });

    return generatePath(path, params);
  }

  isStepAllowed = (step) => {
    const {request, invitation, interview, loggedIn} = this.props;

    const isAuth = loggedIn || isAuthenticated();
    const isCandidate = currentUserHasRole('ROLE_CANDIDATE');

    if (isAuth && !isCandidate) {
      return STEP_INFO === step;
    }

    if (interview && isInterviewStarted(interview)) {
      return [STEP_QUESTIONS].includes(step);
    }

    if (interview) {
      return [STEP_TEST].includes(step);
    }

    if (isInvitationAccepted(invitation)) {
      return [STEP_ABOUT].includes(step);
    }

    if (isInvitationDeclined(invitation)) {
      return [STEP_INFO].includes(step);
    }

    return [STEP_INFO, STEP_ABOUT].includes(step);
  }

  getStepBasedOnInterviewState = () => {
    const {request, invitation, interview, loggedIn} = this.props;

    const isAuth = loggedIn || isAuthenticated();
    const isCandidate = currentUserHasRole('ROLE_CANDIDATE');

    if (isAuth && !isCandidate) {
      return STEP_INFO;
    }

    if (interview && (isInterviewStarted(interview) || getVideoQuestionsCount(request) === 0)) {
      return STEP_QUESTIONS;
    }

    if (interview && !isInterviewStarted(interview) && getVideoQuestionsCount(request) > 0) {
      return STEP_TEST;
    }

    if (isInvitationAccepted(invitation)) {
      return STEP_ABOUT;
    }

    if (isInvitationDeclined(invitation)) {
      return STEP_INFO;
    }

    return STEP_INFO;
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  request: state.onDemandInterviewPage.request,
  invitation: state.onDemandInterviewPage.invitation,
  interview: state.onDemandInterviewPage.interview,
  step: state.onDemandInterviewPage.step,
  loggedIn: state.onDemandInterviewPage.auth.loggedIn,
});

const mapDispatchToProps = dispatch => ({
  setStep: (step) => dispatch(setStep(step)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OnDemandInterviewStepsController);
