import React, {Component} from "react";
import PropTypes from "prop-types";
import {TableCell, TableRow} from "@material-ui/core";
import JobSalary from "./JobSalary";
import ProfessionMappingView from "../../views/Components/ProfessionMappingView";

export default class CandidateJobInfo extends Component {
  static propTypes = {
    job: PropTypes.object.isRequired,
  };

  render() {
    const {job} = this.props;
    return (
      <>
        <TableRow>
          <TableCell scope="row">Job Title</TableCell>
          <TableCell>{job.title}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row">Description</TableCell>
          <TableCell className={"ql-editor"} dangerouslySetInnerHTML={{__html: job.description }}/>
        </TableRow>
        {job.salaryType && (
          <TableRow>
            <TableCell scope="row">Salary</TableCell>
            <TableCell><JobSalary job={job}/></TableCell>
          </TableRow>
        )}
        {job.address && (
          <TableRow>
            <TableCell scope="row">Address</TableCell>
            <TableCell>{job.address}</TableCell>
          </TableRow>
        )}
        {job.address2 && (
          <TableRow>
            <TableCell scope="row">Address 2</TableCell>
            <TableCell>{job.address2}</TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell scope="row">City</TableCell>
          <TableCell>{job.city}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row">State</TableCell>
          <TableCell>{job.state && job.state.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row">ZIP Code</TableCell>
          <TableCell>{job.zipCode}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row">Employment Type</TableCell>
          <TableCell>{job.employmentType}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row">Visa Sponsorship</TableCell>
          <TableCell>
            {job.visaSponsorship ? "Yes" : "No" }
          </TableCell>
        </TableRow>
        <TableRow>
        <TableCell scope="row">Relocation</TableCell>
          <TableCell>
            {job.relocation ? "Yes" : "No" }
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row">Profession Mapping</TableCell>
          <TableCell>
            {job.professionMappings && job.professionMappings.length > 0 && job.professionMappings.map(
              (mapping, index) => <ProfessionMappingView key={index} mapping={mapping}/>
            )}
          </TableCell>
        </TableRow>
      </>
    );
  }
}
