import React, {Component} from 'react';
import {STEP_ABOUT, STEP_INFO, STEP_QUESTIONS, STEP_TEST} from "./OnDemandInterviewSteps";
import OnDemandInterviewInfoContent from "./OnDemandInterviewInfoContent";
import classNames from 'classnames';
import {connect} from "react-redux";
import OnDemandInterviewAboutContent from "./OnDemandInterviewAboutContent";
import OnDemandInterviewTestContent from "./OnDemandInterviewTestContent";
import OnDemandInterviewQuestionsContent from "./OnDemandInterviewQuestionsContent";
import {getCandidateProfile} from "../../actions/OnDemandInterviewPage/auth";
import {isAuthenticated} from "../../utils/auth";

class OnDemandInterviewContent extends Component {

  componentDidMount() {
    this.checkAndRetreiveCandidateProfile();
  }

  componentDidUpdate() {
    this.checkAndRetreiveCandidateProfile();
  }

  checkAndRetreiveCandidateProfile = () => {
    const {loggedIn, interview, candidateProfile, profileLoading} = this.props;
    if ((isAuthenticated() || loggedIn) && !interview && !candidateProfile && !profileLoading) {      
      this.props.getCandidateProfile();
    }
  }

  getContentByStep = (step) => {
    switch (step) {
      case STEP_ABOUT:
        return <OnDemandInterviewAboutContent />
      case STEP_TEST:
        return <OnDemandInterviewTestContent />
      case STEP_QUESTIONS:
        return <OnDemandInterviewQuestionsContent />
      case STEP_INFO:
      default:
        return <OnDemandInterviewInfoContent />
    }
  }

  render() {
    const {step} = this.props;
    return (
      <div className={classNames('on-demand-interview-page-content', 'interview-page-content')}>
        {this.getContentByStep(step)}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  step: state.onDemandInterviewPage.step,
  loggedIn: state.onDemandInterviewPage.auth.loggedIn,
  candidateProfile: state.onDemandInterviewPage.auth.candidateProfile,
  profileLoading: state.onDemandInterviewPage.auth.candidateProfileLoading,
});

const mapDispatchToProps = dispatch => ({
  getCandidateProfile: () => dispatch(getCandidateProfile()),
})

OnDemandInterviewContent = connect(
  mapStateToProps, mapDispatchToProps
)(OnDemandInterviewContent);

export default OnDemandInterviewContent;
