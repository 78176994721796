import React, {Component} from 'react';
import {history} from "../../store";
import {fetch} from "../../utils/dataAccess";
import LoginFirstStep from "./LoginFirstStep";
import LoginSecondStep from "./LoginSecondStep";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailExists: undefined,
      emailChecking: false,
    };
  }

  checkEmail = (values) => {
    this.setState({emailChecking: true});
    let emailExists = false;
    fetch('/check-email', {
      data: {email: values.email},
      method: 'POST',
    }).then((resp) => {
      const data = resp.data || {};
      emailExists = !!data.exists;
    }).catch((e) => {
      emailExists = false;
    }).finally(() => {
      this.setState({
        emailExists: emailExists,
        emailChecking: false,
      });
      if (!emailExists) {
        history.push('/register/candidate')
      }
    });
  };

  render() {
    const { onSubmit, loggingIn } = this.props;
    const { emailExists, emailChecking } = this.state;
    return (
      <div>
        {!emailExists ? (
          <LoginFirstStep
            onSubmit={this.checkEmail}
            loading={emailChecking}
          />
        ) : (
          <LoginSecondStep
            onSubmit={onSubmit}
            loading={loggingIn}
          />
        )}
      </div>
    );
  }
}

export default LoginForm;
