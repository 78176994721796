import React from "react";
import PropTypes from "prop-types";
import VideoQuestionPlayer from "../../components/VideoQuestionPlayer/VideoQuestionPlayer";
import Button from "components/CustomButtons/Button.jsx";
import YoutubeVideo from "../../views/Components/YoutubeVideo";

export class IntroOutroPlayer extends React.Component
{
    static propTypes = {
        video: PropTypes.string,
        videoUrl: PropTypes.string,
        onSkip: PropTypes.func.isRequired,
        buttonLabel: PropTypes.string.isRequired,
        showPlayButtonOnVideo: PropTypes.bool,
    };

    render() {
        return (
            <div className="on-demand-interview-intro-outro-player">
                {this.props.video && (
                  <VideoQuestionPlayer
                    src={this.props.video}
                    showPlayButtonOnVideo={this.props.showPlayButtonOnVideo}
                  />
                )}
                {this.props.videoUrl && !this.props.video && (
                  <YoutubeVideo url={this.props.videoUrl} />
                )}
                <Button
                  className="on-demand-interview-intro-outro-player-skip-button"
                  onClick={this.props.onSkip}
                >
                  {this.props.buttonLabel}
                </Button>
            </div>
        );
    }
}
