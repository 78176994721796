export function savingAnswer (state = null, action) {
  switch (action.type) {
    case 'ON_DEMAND_INTERVIEW_PAGE_ANSWER_SAVING':
      return action.saving;
    case 'ON_DEMAND_INTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}

export function answerError (state = null, action) {
  switch (action.type) {
    case 'ON_DEMAND_INTERVIEW_PAGE_ANSWER_ERROR':
      return action.message;
    case 'ON_DEMAND_INTERVIEW_PAGE_RESET':
      return null;
    default:
      return state;
  }
}
