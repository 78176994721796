import {fetch} from "../../utils/dataAccess";

export function loadCompanyHiringManagersForSelect(valueProperty = 'id') {
  return dispatch => {
    dispatch(loading(true));
    fetch('/company_hiring_managers?pagination=false')
      .then(response => response.data)
      .then((data) => {
      dispatch(managersLoaded(data['hydra:member'].map(
        function (manager) {
          return {
            value: manager[valueProperty],
            text: `${manager.firstName} ${manager.lastName}`,
            teams: manager.teams,
          }
        }
      )));
      dispatch(loading(false));
    }).catch(e => {
      dispatch(loading(false));
      dispatch(error(e.message));
    })
  };
}

export function loading(loading) {
  return {type: 'COMPANY_HIRING_MANAGERS_FOR_SELECT_LOADING', loading};
}

export function error(error) {
  return {type: 'COMPANY_HIRING_MANAGERS_FOR_SELECT_ERROR', error};
}

export function managersLoaded(retrieved) {
  return {type: 'COMPANY_HIRING_MANAGERS_FOR_SELECT_RETRIEVED', retrieved};
}
