import { combineReducers } from 'redux';

export function error(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_SHARE_LINK_BY_CODE_ERROR':
      return action.error;

    default:
      return state;
  }
}

export function passwordError(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_SHARE_LINK_BY_CODE_PASSWORD_ERROR':
      return action.passwordError;

    default:
      return state;
  }
}

export function passwordRequired(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_SHARE_LINK_BY_CODE_PASSWORD_REQUIRED':
      return action.passwordRequired;

    default:
      return state;
  }
}

export function noLongerAvailable(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_SHARE_LINK_BY_CODE_NO_LONGER_AVAILABLE':
      return action.noLongerAvailable;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_SHARE_LINK_BY_CODE_LOADING':
      return action.loading;

    default:
      return state;
  }
}

export function retrieved(state = null, action) {
  switch (action.type) {
    case 'LIVEINTERVIEW_SHARE_LINK_BY_CODE_SUCCESS':
      return action.retrieved;

    default:
      return state;
  }
}

export default combineReducers({ error, passwordError, passwordRequired, noLongerAvailable, loading, retrieved });
