import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import NotFound from "views/Pages/NotFound.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

import getRoutes from "routes.js";

import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/authStyle.jsx";

import register from "assets/img/inorc_light_bg.jpg";
import login from "assets/img/inorc_light_bg.jpg";
import lock from "assets/img/inorc_light_bg.jpg";
import error from "assets/img/inorc_light_bg.jpg";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {getActiveRouteName} from "../utils/routes";
import {currentUserHasRole, currentUserRoles} from "../utils/auth";
import storage from "../utils/storage";
import {resolveDashboardUrl} from "../utils/dashboardUrlResolver";
import {Redirect} from "react-router";
import {getCurrentSiteDetailsFromStorage} from "../utils/currentSiteDetails";

class Pages extends React.Component {
  componentDidMount() {
    document.body.style.overflow = "unset";
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      } else {
        return null;
      }
    });
  };

  getRedirects = () => {
      //TODO: place redirects config to routes file?
      return (
          <Redirect from={"/request/:roomid"} to={"/on-demand-interview/:roomid"}/>
      )
  }

  getBgImage = () => {
    if (window.location.pathname.indexOf("/register/company") !== -1) {
      return register;
    } else if (window.location.pathname.indexOf("/register/candidate") !== -1) {
      return register;
    } else if (window.location.pathname.indexOf("/login") !== -1) {
      return login;
    } else if (
      window.location.pathname.indexOf("/lock-screen") !== -1
    ) {
      return lock;
    } else if (window.location.pathname.indexOf("/error") !== -1) {
      return error;
    } else {
      return login;
    }
  };

    static shouldRedirectToTermsOfServiceAcceptance(currentProps) {
        if (!storage.get('X-Switch-User') && (currentUserHasRole('ROLE_COMPANY_USER') || currentUserHasRole('ROLE_CANDIDATE'))) {
            const siteDetails = getCurrentSiteDetailsFromStorage();
            const siteSettingsAcceptedAt = storage.get('site-settings-accepted-at');
            if (siteDetails && siteDetails['site-settings-version'] > siteSettingsAcceptedAt) {
                let dashboardUrl = resolveDashboardUrl(currentUserRoles());
                let excludedPaths = [
                    dashboardUrl,
                    '/terms-of-service',
                    '/privacy-policy'
                ];

                if (excludedPaths.indexOf(currentProps.location.pathname) === -1) {
                    storage.set('redirect-after-terms-accept-url', currentProps.location.pathname);
                    currentProps.history.push(dashboardUrl);
                }
            }
        }
    }

  render() {
    const { classes, site, ...rest } = this.props;
    const routes = getRoutes(site);
    const routeName = getActiveRouteName(routes, this.props.location);
    const logo = (site.logo) ? site.logo : null;

    if ('Home' !== routeName) {
        Pages.shouldRedirectToTermsOfServiceAcceptance(this.props);
    }

    return (
      <div>
        <Helmet>
          <title>{routeName}</title>
        </Helmet>
        <AuthNavbar brandText={routeName} logo={logo} {...rest} site={site}/>
        <div className={classes.wrapper} ref="wrapper">
          <div
            className={classes.fullPage + ' ' + classes.light}
            style={{ backgroundImage: "url(" + this.getBgImage() + ")" }}
          >
            <Switch>
              {this.getRoutes(routes)}
              {this.getRedirects()}
              <Route component={NotFound} />
            </Switch>
              {this.props.site && (
                <Footer siteDetails={this.props.site} />
              )}
          </div>
        </div>
      </div>
    );
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired
};

Pages = withStyles(pagesStyle)(Pages);

const mapStateToProps = state => ({
    site: state.siteDetails.siteDetails.retrieved,
});

export default connect(
    mapStateToProps
)(Pages);
