import { combineReducers } from 'redux';

export function error(state = null, action) {
  switch (action.type) {
    case 'INVITATION_PAGE_ERROR':
      return action.error;

    case 'INVITATION_PAGE_RESET':
      return null;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'INVITATION_PAGE_LOADING':
      return action.loading;

    case 'INVITATION_PAGE_RESET':
      return false;

    default:
      return state;
  }
}

export function received(state = null, action) {
  switch (action.type) {
    case 'INVITATION_PAGE_RECEIVED':
      return action.received;

    case 'INVITATION_PAGE_RESET':
      return null;

    default:
      return state;
  }
}

export default combineReducers({ error, loading, received});
